export function AttendanceOverview(groupID: number): string {
	return `/v1/report/events/dashboard/${groupID}`;
}

export function EventSummaryReport(): string {
	return `/v1/report/events/by-events`;
}

export function GroupReport(groupID: number): string {
	return `/v1/report/events/by-groups/${groupID}`;
}

export function UserDetailReport(userID: number): string {
	return `/v1/report/events/users/${userID}`;
}

export function UserSummaryReport(): string {
	return `/v1/report/events`;
}
