import { useState, ChangeEvent } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as actions from 'pkg/actions';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import * as formStyles from 'routes/public/styles/forms/styles.css';
import Field from 'routes/public/styles/inputs/field';
import { useOnboardingState } from 'routes/public/hooks';
import { useOnboardingContext } from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import Divider from 'routes/public/styles/Divider';
import CancelButton from 'routes/public/styles/CancelButton';
import Header from 'routes/public/no-memberships/styles/Header';

import Icon from 'components/icon';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

interface ErrorMessage {
	message: string;
	link?: string;
	linkText?: string;
}

export default function NewWardConnection(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const [isSaving, setIsSaving] = useState(false);
	const [childEmail, setChildEmail] = useState('');

	useComponentDidMount(() => {
		onboardingState.setGroup(null);
	});

	const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		setChildEmail(event.target.value);
	};

	const handleErrors = (requestStatus: number | null): ErrorMessage => {
		switch (requestStatus) {
			case 400:
				return {
					message: t(`You can't connect to yourself`),
				};
			case 404:
				return {
					message: t(
						'The account could not be found, please double-check the information and try again.'
					),
				};
			case 403:
				return {
					message: t(
						'The account you want to connect to is already a parent, connect to your child instead to continue.'
					),
					link: 'https://help.360player.com/article/208-connect-to-a-child-with-an-existing-parent',
					linkText: t('Read a full guide here'),
				};
			default:
				return {
					message: t(
						'There was an error processing your request. Please try again.'
					),
				};
		}
	};

	const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);

	const handleSendRequest = async () => {
		setIsSaving(true);
		setErrorMessage(null);

		const [request] = await actions.accountRelations.createRequest(
			'legalGuardian',
			childEmail
		);

		if (request.ok) {
			goTo('start');
		} else {
			const errorMessageObj = handleErrors(request.status);
			setErrorMessage(errorMessageObj);
			setIsSaving(false);
		}
	};

	const handleCreateChildAccount = async () => {
		setIsSaving(true);

		await onboardingState.set({ account: null, targetAccount: null });

		goTo('create-ward-group-code');
	};

	return (
		<Column spacing={styles.spacing._8}>
			<Header />

			<Column>
				<Column>
					<span className={formStyles.label}>
						{t('Connect to an existing child account')}
					</span>
					<Field
						name="email"
						type="email"
						value={childEmail}
						placeholder={t(`Your child's email`)}
						onChange={handleEmailChange}
					/>
					<Button
						primary
						label={t('Send connection request')}
						onClick={handleSendRequest}
						busy={isSaving}
					/>
				</Column>
				{errorMessage && (
					<InfoBox color="orange">
						{errorMessage.message}
						{errorMessage.link && errorMessage.linkText && (
							<Link href={errorMessage.link}>
								{errorMessage.linkText} <Icon name="new-window" size={1.3} />
							</Link>
						)}
					</InfoBox>
				)}
				<Divider />
				<Column>
					<Button
						transparent
						disabled={isSaving}
						label={t('Create a new child account')}
						onClick={handleCreateChildAccount}
						testid="no_memberships.create_child_account_button"
					/>
				</Column>
			</Column>

			<CancelButton disabled={isSaving} />
		</Column>
	);
}
