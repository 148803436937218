import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { FilterOperator } from 'pkg/filters';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentUser } from 'pkg/identity';

import * as Card from 'components/Card';
import Icon from 'components/icon';

import Button from 'design/button';

import * as css from './style.css';

const viewBilling = () => {
	pushState(routes.UserBilling.Home());
};

export default function PendingInvoices(): JSX.Element | null {
	const currentUser = useCurrentUser();

	const params = {
		customer_user_id: currentUser.id.toString(),
		group_id: currentUser.organizationId.toString(),
		filters: JSON.stringify([
			{
				property: 'status',
				operator: FilterOperator.Includes,
				values: ['open', 'past_due', 'uncollectible'],
			},
		]),
	};

	const { records: invoices } = useCollection<models.order.Order>(
		endpoints.Orders.Index(),
		{
			showAllResults: true,
			queryParams: new URLSearchParams(params),
		}
	);

	const openInvoices = invoices.filter((invoice) => {
		return (
			invoice.status === 'open' &&
			models.order.getStatus(invoice).name !== 'past_due'
		);
	});
	const pastDueInvoices = invoices.filter((invoice) => {
		return models.order.getStatus(invoice).name === 'past_due';
	});
	const uncollectibleInvoices = invoices.filter(
		(invoice) => invoice.status === 'uncollectible'
	);
	const unpaidAndUncollectibleInvoices = [
		...pastDueInvoices,
		...uncollectibleInvoices,
	];

	if (!invoices.length) {
		return null;
	}

	return (
		<Card.Base $clickable $noBorder className={css.card}>
			<Card.Body onClick={viewBilling} $narrowBody>
				<div className={css.content}>
					{unpaidAndUncollectibleInvoices.length > 0 ? (
						<div className={css.title}>
							<div className={css.warningBadge}>
								<Icon name="notification-outline" size={1.35} />
							</div>
							{t(`You have overdue invoices`)}
						</div>
					) : (
						openInvoices.length > 0 && (
							<div className={css.title}>
								<div className={css.badge}>
									<Icon name="notification-outline" size={1.35} />
								</div>
								{t(`You have unpaid invoices`)}
							</div>
						)
					)}
					<div>
						<Button icon="chevron_right" iconPosition="right" inline noPadding>
							{t('View')}
						</Button>
					</div>
				</div>
			</Card.Body>
		</Card.Base>
	);
}
