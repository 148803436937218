import { Children, Fragment, PointerEvent, ReactNode } from 'react';

import spacing from 'pkg/config/spacing';
import * as color from 'pkg/config/palette';

import { cssClasses } from 'pkg/css/utils';

import Label from 'components/label';
import Icon, { IconName } from 'components/icon';
import MaterialSymbol from 'components/material-symbols';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import * as Card from 'design/card';

import * as css from './styles.css';

type DataCardTrend = 'neutral' | 'positive' | 'negative';

interface BaseProps {
	title: string;
	titleSuffix?: string;
	titleIcon?: MaterialSymbolVariant;

	heading?: string;
	headingSuffix?: string;

	trend?: DataCardTrend;
	trendValue?: string;

	wrapContent?: boolean;
	noFooterDivider?: boolean;
	header?: ReactNode | ReactNode[];
	footer?: ReactNode | ReactNode[];
	children?: ReactNode | ReactNode[];
}

export function Base({
	title,
	titleSuffix,
	titleIcon,

	heading,
	headingSuffix,

	trend,
	trendValue,

	wrapContent = true,
	noFooterDivider,
	header,
	footer,
	children,
}: BaseProps): JSX.Element {
	const getColumnLayout = () => {
		let gridTemplateRows = !footer ? 'auto 1fr' : 'auto 1fr auto';

		if (!footer && !children) {
			gridTemplateRows = 'auto';
		}

		if (footer && !children) {
			gridTemplateRows = 'repeat(2, auto)';
		}

		return {
			height: '100%',
			gridTemplateRows,
		};
	};

	const hasChildren = Children.count(children) > 0;
	const hasFooter = Children.count(footer) > 0;

	const getTrendIconName = (trend: DataCardTrend): IconName => {
		switch (trend) {
			case 'neutral':
				return 'arrow-right';
			case 'positive':
				return 'arrow-up';
			case 'negative':
				return 'arrow-down';
		}
	};

	return (
		<Card.Base $noBorder>
			<Card.Body $flex $noSpacing className={css.wrapper}>
				<Column spacing={spacing._5} style={getColumnLayout()}>
					<Row
						columns="1fr auto"
						spacing={spacing._4}
						align="start"
						className={cssClasses(
							css.header,
							!hasChildren && !hasFooter ? css.no_children : null
						)}>
						<Column spacing={spacing._2}>
							<Row
								autoColumns="auto"
								spacing={spacing._3}
								align="center"
								justify="start"
								justifyContent="start">
								{titleIcon && (
									<MaterialSymbol
										variant={titleIcon}
										className={css.title__icon}
									/>
								)}
								<span className={css.title}>{title}</span>
								{titleSuffix && (
									<span className={css.title__suffix}>{titleSuffix}</span>
								)}
							</Row>
							{heading !== null && (
								<Row
									columns="auto 1fr"
									spacing={spacing._3}
									align="end"
									justify="start"
									justifyContent="start">
									<h1 className={css.heading}>{heading}</h1>
									{headingSuffix && (
										<span className={css.heading__suffix}>{headingSuffix}</span>
									)}
									{trend && (
										<span
											className={cssClasses(
												css.trend,
												trend === 'positive'
													? css.trend__positive
													: css.trend__negative
											)}>
											<Icon name={getTrendIconName(trend)} size={1.3} />
											{trendValue}
										</span>
									)}
								</Row>
							)}
						</Column>
						{header}
					</Row>
					{wrapContent && !!children ? (
						<div className={css.body}>{children}</div>
					) : (
						<Fragment>{children}</Fragment>
					)}
					{footer && (
						<div
							className={cssClasses(
								css.footer,
								noFooterDivider ? css.no_divider : null
							)}>
							{footer}
						</div>
					)}
				</Column>
			</Card.Body>
		</Card.Base>
	);
}

interface WithLargeIconProps extends BaseProps {
	icon: IconName;
}

export function WithLargeIcon({
	icon,

	children,

	...props
}: WithLargeIconProps): JSX.Element {
	return (
		<Base
			{...props}
			header={
				<div className={css.large__icon}>
					<Icon name={icon} />
				</div>
			}>
			{children}
		</Base>
	);
}

interface WithActionProps extends BaseProps {
	label: string;
	onClick: () => void;
}

export function WithAction({
	label,
	onClick,

	children,

	...props
}: WithActionProps): JSX.Element {
	return (
		<Base
			{...props}
			header={
				<span className={css.action} onClick={onClick}>
					{label} <Icon name="chevron" size={1.3} />
				</span>
			}>
			{children}
		</Base>
	);
}

export interface BreakdownItem {
	variant?: 'bullet' | 'label';
	color?: color.Color;
	title: string;
	value: string;
	diff?: string;

	onClick?: (event: PointerEvent<HTMLDivElement>) => void;
}

interface BreakdownProps {
	items: BreakdownItem[];
}

export function Breakdown({ items }: BreakdownProps): JSX.Element {
	const renderItem = (item: BreakdownItem): ReactNode => {
		const clickable = !!item.onClick;
		let label: ReactNode = <Fragment>{item.title}</Fragment>;

		if (clickable) {
			label = <strong>{item.title}</strong>;
		}

		if (item.variant !== 'label' && clickable) {
			label = <span className={css.action}>{label}</span>;
		}

		if (item.variant === 'label') {
			return <Label color={item.color}>{label}</Label>;
		}

		if (item.variant === 'bullet') {
			return (
				<Label variant="bullet" color={item.color}>
					{label}
				</Label>
			);
		}

		return label;
	};

	const layout = (item: BreakdownItem): string => {
		if (item.diff) {
			return 'auto 1fr repeat(2, auto)';
		}

		return 'auto 1fr auto';
	};

	return (
		<Column spacing={spacing._4}>
			{items.map((item: BreakdownItem) => (
				<Row
					key={item.title}
					columns={layout(item)}
					spacing={spacing._3}
					onClick={item.onClick || undefined}
					className={cssClasses(
						css.breakdown__item,
						!!item.onClick ? css.interactable : null
					)}>
					<div>
						<span className={cssClasses(css.breakdown__item)}>
							{renderItem(item)}
						</span>
					</div>
					<div className={css.breakdown__divider}>&nbsp;</div>
					<strong className={cssClasses(css.breakdown__item)}>
						{item.value}
					</strong>
					{item.diff && (
						<span className={cssClasses(css.breakdown__item, css.diff)}>
							{item.diff}
						</span>
					)}
				</Row>
			))}
		</Column>
	);
}
