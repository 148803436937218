import { Event, EventTypes } from 'pkg/api/models/event';
import { AttendanceStatuses, EventUserStatus } from 'pkg/api/models/event_user';
import { Group } from 'pkg/api/models/group';
import { Record } from 'pkg/api/models/record';

export interface GroupReport extends Record {
	groupId: number;

	attendanceRate: number;
	unhandledRate: number;
	injuredRate: number;
	injuredAmount: number;
	illnessRate: number;
	illnessAmount: number;
	otherAbsenceRate: number;

	otherAbsenceAmount: number;
	totalLokEvents: number;
	approvedLokEvents: number;
	totalEventUsers: number;

	matchEventsCount: number;
	practiceEventsCount: number;
	meetingEventsCount: number;
	campEventsCount: number;
	cupEventsCount: number;
	uncategorizedEventsCount: number;

	events: Event[];
	group: Group;
}

export interface UserDetailReport {
	eventId: number;
	eventTitle: string;
	eventStart: number;
	eventEnd: number;
	userComment: string;

	eventType: EventTypes;
	userStatus: EventUserStatus;
	userAttendanceStatus: AttendanceStatuses;
}

export enum AttendanceDataTypes {
	Attending = 'attending',
	NotAttending = 'not_attending',
	Unhandled = 'unhandled',
}

interface PeriodizedData {
	periodizedData: {
		datePeriods: { [key: string]: { currentPeriod: number } };
		showAs: string;
		total: { currentPeriodTotal: number };
	};
}

export interface PeriodizedEventUserData extends PeriodizedData {
	dataType: AttendanceDataTypes;
}

export enum AbsenceDataTypes {
	Sick = 'sick',
	Injured = 'injured',
	Other = 'other',
}

export interface PeriodizedAbsenceData extends PeriodizedData {
	dataType: AbsenceDataTypes;
}

export interface AttendanceDashboardData {
	eventCount: number;

	attendedAmount: number;
	injuredAmount: number;
	illnessAmount: number;
	otherReasonAmount: number;
	unhandledAmount: number;

	attendanceRate: number;
	didNotAttendRate: number;
	unhandledRate: number;

	absenceIllnessEventCount: number;
	absenceInjuredEventCount: number;
	absenceOtherEventCount: number;

	matchEventsCount: number;
	practiceEventsCount: number;
	meetingEventsCount: number;
	campEventsCount: number;
	cupEventsCount: number;
	uncategorizedEventsCount: number;

	matchEventsRate: number;
	practiceEventsRate: number;
	meetingEventsRate: number;
	campEventsRate: number;
	cupEventsRate: number;
	uncategorizedEventsRate: number;

	periodizedEventUserData: PeriodizedEventUserData[];
	periodizedAbsenceData: PeriodizedAbsenceData[];
}
