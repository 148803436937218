import { MouseEvent, useState } from 'react';
import { t } from '@transifex/native';

import FormCategory from 'pkg/models/form_category';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

import AddFormCategoryModal from 'routes/forms/list/active/AddFormCategoryModal';

import StepModal, { Step } from 'components/step-modal';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';
import * as Table from 'design/table';

interface CategoryModalProps {
	groupId: number;
	onClose: () => void;
}

const FormCategoryModal = ({
	groupId,
	onClose,
}: CategoryModalProps): JSX.Element => {
	const [showAddCategory, setAddCategory] = useState(false);
	const [editCategoryId, setEditCategoryId] = useState(0);
	const handleNew = () => {
		setAddCategory(true);
	};

	const handleEdit = async (event: MouseEvent) => {
		const id = Number.parseInt(
			(event.currentTarget as HTMLDivElement).dataset.itemId,
			10
		);
		setAddCategory(true);
		setEditCategoryId(id);
	};

	const {
		records: formCategories,
		removeRecord,
		refresh,
	} = useCollection<models.formCategory.FormCategory>(
		endpoints.FormCategories.Index(),
		{
			queryParams: new URLSearchParams({ group_id: groupId.toString() }),
		}
	);

	const hideAddCategoryModal = () => {
		setAddCategory(false);
	};
	return (
		<StepModal onClose={onClose} wide>
			<Step title={t('Categories')} nextLabel={t('Done')} skipBody>
				<Table.Table
					columns={[
						{ content: t('Name') },
						{ content: t('Description') },
						{
							content: <Button onClick={handleNew}>{t('Add category')}</Button>,
							align: 'right',
						},
					]}>
					{formCategories.map((item: FormCategory) => {
						const handleRemoveCategory = async () => {
							const response = await models.destroy(item);
							if (response) {
								removeRecord(item.id);
							}
						};

						return (
							<Table.Row key={item.id}>
								<Table.Cell>{item.title}</Table.Cell>
								<Table.Cell>{item.description}</Table.Cell>
								<Table.Cell>
									<ContextMenu.Menu>
										<ContextMenu.Item
											onClick={handleEdit}
											data-item-id={item.id}>
											<ContextMenu.ItemIcon name="edit" />
											{t('Edit')}
										</ContextMenu.Item>
										<ContextMenu.ConfirmItem
											message={t(
												'Are you sure you want to remove this category?'
											)}
											caution
											onConfirm={handleRemoveCategory}>
											<ContextMenu.ItemIcon name="delete" />
											{t('Delete')}
										</ContextMenu.ConfirmItem>
									</ContextMenu.Menu>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Table>
				{showAddCategory && (
					<AddFormCategoryModal
						onClose={hideAddCategoryModal}
						groupId={groupId}
						refresh={refresh}
						formCategory={formCategories.find(
							(category) => category.id === editCategoryId
						)}
					/>
				)}
			</Step>
		</StepModal>
	);
};

export default FormCategoryModal;
