import { t } from '@transifex/native';

import { NewContactsData } from 'pkg/api/dashboards/management';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { FilterOperator } from 'pkg/filters';
import DateTime, { Granularity } from 'pkg/datetime';
import { useCurrentOrganization } from 'pkg/identity';

import * as DataCard from 'design/data_card';
import * as Chart from 'design/chart';

interface NewContactsWidgetProps {
	data: NewContactsData;
}

export default function NewContactsWidget({ data }: NewContactsWidgetProps) {
	const now = new DateTime(new Date());
	const org = useCurrentOrganization();

	const makeFilterUrl = (range: string) => {
		let startOfRange: number = 0;

		switch (range) {
			case 'day':
				startOfRange = Math.round(
					now.prev(Granularity.day, 1).startOfDay / 1000
				);
				break;
			case 'week':
				startOfRange = Math.round(
					now.prev(Granularity.week, 1).startOfDay / 1000
				);
				break;
			case 'month':
				startOfRange = Math.round(
					now.prev(Granularity.day, 30).startOfDay / 1000
				);
				break;
		}

		return toFilterQueryUrl(routes.Management.Contacts.Home(org.id), {
			user_created: {
				operator: FilterOperator.Between,
				values: [startOfRange, now.getUnixTimestamp()],
			},
		});
	};

	const breakdownItems: DataCard.BreakdownItem[] = [
		{
			title: t('1 day'),
			value: data.dayCount.toString(),
			onClick: () => pushState(makeFilterUrl('day')),
		},
		{
			title: t('7 days'),
			value: data.weekCount.toString(),
			onClick: () => pushState(makeFilterUrl('week')),
		},
		{
			title: t('30 days'),
			value: data.monthCount.toString(),
			onClick: () => pushState(makeFilterUrl('month')),
		},
	];

	const chartItems: Chart.ChartItem[] = Object.entries(
		data.periodizedData.datePeriods
	).map(([key, value]) => {
		return {
			title: key,
			value: value.currentPeriod,
		};
	});

	return (
		<DataCard.Base
			title={t('New contacts this year')}
			heading={data.periodizedData.total.currentPeriodTotal.toString()}
			headingSuffix={t('Total amount')}
			titleIcon="person_add">
			<DataCard.Breakdown items={breakdownItems} />
			<Chart.Linear items={chartItems} />
		</DataCard.Base>
	);
}
