import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { Features } from 'pkg/models/group';
import { Capabilities } from 'pkg/models/membership';

import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';

import PendingInvoices from 'routes/dashboard/pending-invoices';
import GroupVideos from 'routes/dashboard/sections/GroupVideos';
import LastGamesResults from 'routes/dashboard/sections/LastGamesResults';
import PendingEvents from 'routes/dashboard/pending-events';
import TeamStats from 'routes/dashboard/sections/TeamStats';
import UpcomingEvents from 'routes/dashboard/sections/UpcomingEvents';
import QuickCreate from 'routes/dashboard/quick-create';
import Wall from 'routes/dashboard/wall';

import PaymentSetup from 'containers/dashboard/payments/Setup';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Adslot, { AdslotBoundary } from 'components/adslot';

import Column from 'components/layout/column';

import * as css from './styles.css';

export default function GroupWidgets(): JSX.Element {
	const activeMembership = useCurrentMembership();
	const isAdminOrStaff = models.membership.isAdminOrStaff(activeMembership);
	const isParent = models.membership.isParent(activeMembership);
	const group = useCurrentGroup();
	const groupSport = group.sport;

	const groupId = group.id;

	const canShowPayments =
		models.membership.hasCapability(
			activeMembership,
			Capabilities.PaymentAdmin
		) && models.group.isOrganization(group);

	return (
		<Fragment>
			<LargeScreen>
				<div className={css.wrapper}>
					<Column spacing={styles.spacing._7}>
						{canShowPayments && <PaymentSetup groupId={groupId} />}
						<PendingInvoices />
						<PendingEvents />
						{models.group.hasFeature(group, Features.Calendar) && (
							<UpcomingEvents groupId={groupId} />
						)}
						{models.group.hasFeature(group, Features.AggregatedStatistics) &&
							models.group.hasFeature(group, Features.Matches) &&
							models.sport.hasMatchesEnabled(groupSport) && (
								<LastGamesResults groupId={groupId} />
							)}
						{models.group.hasFeature(group, Features.Video) && (
							<GroupVideos
								isAdminOrStaff={isAdminOrStaff}
								isParent={isParent}
								groupId={groupId}
							/>
						)}
						{models.sport.hasMatchesEnabled(groupSport) &&
							models.group.hasFeature(group, Features.AggregatedStatistics) && (
								<TeamStats groupId={groupId} />
							)}
					</Column>
				</div>
			</LargeScreen>
			<SmallScreen>
				<Column spacing={styles.spacing._7}>
					<QuickCreate
						group={group}
						groupSport={groupSport}
						activeMembership={activeMembership}
					/>
					<PendingInvoices />
					{canShowPayments && <PaymentSetup groupId={groupId} />}
					<PendingEvents />
					{models.group.hasFeature(group, Features.Calendar) && (
						<UpcomingEvents groupId={groupId} />
					)}
					<AdslotBoundary>
						<Adslot />
					</AdslotBoundary>
					<Wall />
					{models.group.hasFeature(group, Features.AggregatedStatistics) &&
						models.group.hasFeature(group, Features.Matches) &&
						models.sport.hasMatchesEnabled(groupSport) && (
							<LastGamesResults groupId={groupId} />
						)}
					{models.group.hasFeature(group, Features.Video) && (
						<GroupVideos
							isAdminOrStaff={isAdminOrStaff}
							isParent={isParent}
							groupId={groupId}
						/>
					)}
					{models.sport.hasMatchesEnabled(groupSport) &&
						models.group.hasFeature(group, Features.AggregatedStatistics) && (
							<TeamStats groupId={groupId} />
						)}
					<AdslotBoundary>
						<Adslot dynamicHeight height={600} />
					</AdslotBoundary>
				</Column>
			</SmallScreen>
		</Fragment>
	);
}
