import { t } from '@transifex/native';

import Group from 'pkg/models/group';

import { Dateable } from 'pkg/api/models/dateable';
import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';

export enum UserFieldType {
	ShortText = 'string',
	Number = 'number',
	Choice = 'choice',
	MultiChoice = 'multi_choice',
	Date = 'date',
	Email = 'email',
	Phone = 'phone',
	LongText = 'long_text',
	Attachment = 'attachment',
	Reserved = 'reserved',
}

export enum ReservedUserField {
	FirstName = 'firstName',
	LastName = 'lastName',
	BirthDate = 'birthDate',
	Email = 'email',
	Sex = 'sex',
	PhoneNumber = 'phoneNumber',
	City = 'city',
	Country = 'country',
	PostalCode = 'postalCode',
	CompanyName = 'companyName',
	Address = 'address',
	Nationality = 'nationality',
	Region = 'region',
	LokSwedishPersonalId = 'lokSwedishPersonalId',
}

export interface UserFieldPayload {
	key?: string;
	required?: boolean;
	visibility?: Visibility[];
	type?: UserFieldType;
	label?: string;
	description?: string;
	values?: userFieldValue[];
	sortOrder?: number;
	active?: boolean;
	groupId: number;
}

interface userFieldValue extends Dateable {
	userId: number;
	userFieldId: number;
	type: UserFieldType;
	value: string;
}

export interface UserFieldOption {
	label: string;
	value: string;
}

export enum Visibility {
	ContactCard = 'contactCard',
	Profile = 'profile',
}

export interface UserField extends Record, Dateable, Linkable {
	id?: number;
	groupId?: number;
	key: string;
	type: UserFieldType;
	attachment?: models.attachment.Attachment;
	attachmentType?: models.attachment.AttachmentType;
	attachmentId?: number;
	label: string;
	description?: string;
	values?: UserFieldOption[];
	required?: boolean;
	sortOrder?: number;
	visibility?: Visibility[];
	active?: boolean;
}

export async function updateSortOrder(payload: {
	[key: number]: number;
}): Promise<[boolean, UserField[]]> {
	const request = await sdk.patch(
		endpoints.UserField.UpdateSortOrder(),
		{},
		payload
	);

	const response = await request.json();

	return [request.ok, response];
}

export function visibilityFromString(v: string): Visibility {
	switch (v) {
		case Visibility.ContactCard.toString():
			return Visibility.ContactCard;
		case Visibility.Profile.toString():
			return Visibility.Profile;
	}
}

export function getReservedFieldsToDisplay(group?: models.group.Group | Group) {
	const fields = [
		'firstName',
		'lastName',
		'email',
		'birthDate',
		'sex',
		'nationality',
		'phoneNumber',
		'address',
		'postalCode',
		'city',
		'region',
		'companyName',
		'country',
	];

	if (group?.country?.code === 'SE') {
		fields.push('lokSwedishPersonalId');
	}

	return fields;
}

export function getTypeTranslation(type: string) {
	switch (type) {
		case UserFieldType.ShortText:
			return t('Short text');

		case UserFieldType.LongText:
			return t('Long text');

		case UserFieldType.Number:
			return t('Number');

		case UserFieldType.Choice:
			return t('Single choice');

		case UserFieldType.MultiChoice:
			return t('Multiple choices');

		case UserFieldType.Date:
			return t('Date');

		case UserFieldType.Email:
			return t('Email');

		case UserFieldType.Phone:
			return t('Phone number');

		case UserFieldType.Attachment:
			return t('Attachment');
	}
}

export function getReservedTranslation(fieldKey: ReservedUserField) {
	switch (fieldKey) {
		case ReservedUserField.FirstName:
			return t('First name');
		case ReservedUserField.LastName:
			return t('Last name');
		case ReservedUserField.BirthDate:
			return t('Birth date');
		case ReservedUserField.Email:
			return t('Email');
		case ReservedUserField.Sex:
			return t('Sex');
		case ReservedUserField.PhoneNumber:
			return t('Phone number');
		case ReservedUserField.City:
			return t('City');
		case ReservedUserField.Country:
			return t('Country');
		case ReservedUserField.PostalCode:
			return t('Postal code');
		case ReservedUserField.CompanyName:
			return t('Company name');
		case ReservedUserField.Address:
			return t('Address');
		case ReservedUserField.Nationality:
			return t('Nationality');
		case ReservedUserField.Region:
			return t('Region');
		case ReservedUserField.LokSwedishPersonalId:
			return t('Personal ID Number');
	}
}
