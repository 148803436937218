import { t } from '@transifex/native';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import spacing from 'pkg/config/spacing';

import { Capabilities } from 'pkg/models/membership';

import { UserSex } from 'pkg/api/models/user';
import { MembershipRole } from 'pkg/api/models/membership';

import GroupConditionSelectModal from 'routes/group/settings/tabs/group_links/GroupConditionSelectModal';
import { ConditionType } from 'routes/group/settings/tabs/group_links/GroupLink';

import { useSmallScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import Button from 'design/button';

interface ConditionRulesInputProps {
	groupId: number;
	condition: string;
	handleValueChange: (name: string, value: any) => void;
	name: string;
	initialValue?: any;
}

export default function ConditionRulesInput({
	condition,
	groupId,
	handleValueChange,
	name,
	initialValue,
}: ConditionRulesInputProps): JSX.Element {
	const [groupModal, setGroupModal] = useState<boolean>(false);
	const [groupIds, setGroupIds] = useState<number[]>(
		condition === ConditionType.Groups ? initialValue : []
	);
	const [selectedCapabilities] = useState<Capabilities[]>(initialValue || []);
	const [selectedGenders] = useState<UserSex[]>(initialValue || []);
	const [selectedRoles] = useState<MembershipRole[]>(initialValue || []);
	const ctx = useFormContext();

	const handleGroupModal = async () => setGroupModal(true);
	const hideGroupModal = async () => setGroupModal(false);

	useEffect(() => {
		if (groupIds.length > 0) {
			ctx.setValue(`${name}.ids`, groupIds.join());
		} else {
			ctx.setValue(`${name}.ids`, '');
		}
	}, [groupIds]);
	const fromRef = useRef<HTMLInputElement>();
	const toRef = useRef<HTMLInputElement>();

	const handleAgeRange = () => {
		handleValueChange(name, [
			Number.parseInt(fromRef.current.value),
			Number.parseInt(toRef.current.value),
		]);
	};

	const handleGroupIdSelect = async (ids: number[]) => {
		setGroupIds(ids);
		handleValueChange(name, ids);
	};

	const handleClubLobbySelect = () => {
		handleValueChange(name, true);
	};
	const handleParentSelect = () => {
		handleValueChange(name, true);
	};

	const isSmallScreen = useSmallScreen();

	const groupModalSelect = () =>
		groupIds.length > 0 ? (
			<Fragment>
				<Input.Group>
					<Button small={isSmallScreen} onClick={handleGroupModal}>
						{groupIds.length > 1
							? t('{length} groups selected', { length: groupIds.length })
							: t('{length} group selected', { length: groupIds.length })}
					</Button>
				</Input.Group>

				<Input.Field
					type="hidden"
					value={groupIds.join()}
					name={`${name}.ids`}
				/>
			</Fragment>
		) : (
			<Input.Group>
				<Button small={isSmallScreen} onClick={handleGroupModal}>
					{t('Select Groups')}
				</Button>
			</Input.Group>
		);

	const ageSelect = () => (
		<Row columns="1fr 1fr">
			<Input.Group>
				<Input.Field
					small
					placeholder="0"
					ref={fromRef}
					name={`${name}.from`}
					onChange={handleAgeRange}
					defaultValue={initialValue && initialValue[0]}>
					<Input.Prefix>{t('From')}</Input.Prefix>
				</Input.Field>
			</Input.Group>
			<Input.Group>
				<Input.Field
					small
					placeholder="99"
					ref={toRef}
					name={`${name}.to`}
					onChange={handleAgeRange}
					defaultValue={initialValue && initialValue[1]}>
					<Input.Prefix>{t('To')}</Input.Prefix>
				</Input.Field>
			</Input.Group>
		</Row>
	);

	const capabilitySelect = () => (
		<Input.Group>
			<Column spacing={spacing._1}>
				<Input.Control
					type="checkbox"
					label={t('Payment Admin')}
					name={`${name}.value`}
					value={Capabilities.PaymentAdmin}
					defaultChecked={selectedCapabilities?.includes(
						Capabilities.PaymentAdmin
					)}
				/>
				<Input.Control
					type="checkbox"
					label={t('Forms')}
					name={`${name}.value`}
					value={Capabilities.Forms}
					defaultChecked={selectedCapabilities?.includes(Capabilities.Forms)}
				/>
				<Input.Control
					type="checkbox"
					label={t('Scheduler')}
					name={`${name}.value`}
					value={Capabilities.Scheduler}
					defaultChecked={selectedCapabilities?.includes(
						Capabilities.Scheduler
					)}
				/>
			</Column>
		</Input.Group>
	);

	const clubLobbySelect = () => (
		<Input.Group>
			<Input.Select
				small
				name={`${name}.value`}
				onChange={handleClubLobbySelect}
				defaultValue={initialValue && 'true'}>
				<option value="select">{t('Select')}</option>
				<option value="true">{t('Displayed in club lobby')}</option>
			</Input.Select>
		</Input.Group>
	);
	const parentSelect = () => (
		<Input.Group>
			<Input.Select
				small
				name={`${name}.value`}
				onChange={handleParentSelect}
				defaultValue={initialValue && 'true'}>
				<option value="select">{t('Select')}</option>
				<option value="true">{t('Displayed to parents')}</option>
			</Input.Select>
		</Input.Group>
	);

	const genderSelect = () => (
		<Input.Group>
			<Column spacing={spacing._1}>
				<Input.Control
					type="checkbox"
					label={t('Male')}
					name={`${name}.value`}
					value={UserSex.Male}
					defaultChecked={
						Array.isArray(selectedGenders) &&
						selectedGenders.includes(UserSex.Male)
					}
				/>
				<Input.Control
					type="checkbox"
					label={t('Female')}
					name={`${name}.value`}
					value={UserSex.Female}
					defaultChecked={
						Array.isArray(selectedGenders) &&
						selectedGenders.includes(UserSex.Female)
					}
				/>
				<Input.Control
					type="checkbox"
					label={t('Not applicable')}
					name={`${name}.value`}
					value={UserSex.NotApplicable}
					defaultChecked={
						Array.isArray(selectedGenders) &&
						selectedGenders.includes(UserSex.NotApplicable)
					}
				/>
			</Column>
		</Input.Group>
	);

	const roleSelect = () => (
		<Input.Group>
			<Column spacing={spacing._1}>
				<Input.Control
					type="checkbox"
					label={t('Coach')}
					name={`${name}.value`}
					value={MembershipRole.Admin}
					defaultChecked={selectedRoles.includes(MembershipRole.Admin)}
				/>
				<Input.Control
					type="checkbox"
					label={t('Staff')}
					name={`${name}.value`}
					value={MembershipRole.Staff}
					defaultChecked={selectedRoles.includes(MembershipRole.Staff)}
				/>
				<Input.Control
					type="checkbox"
					label={t('Player')}
					name={`${name}.value`}
					value={MembershipRole.User}
					defaultChecked={selectedRoles.includes(MembershipRole.User)}
				/>
			</Column>
		</Input.Group>
	);

	return (
		<Fragment>
			{condition === ConditionType.Groups && groupModalSelect()}
			{condition === ConditionType.Age && ageSelect()}
			{condition === ConditionType.Capability && capabilitySelect()}
			{condition === ConditionType.ClubLobby && clubLobbySelect()}
			{condition === ConditionType.Parent && parentSelect()}
			{condition === ConditionType.Gender && genderSelect()}
			{condition === ConditionType.Role && roleSelect()}

			{groupModal && (
				<GroupConditionSelectModal
					groupId={groupId}
					onClose={hideGroupModal}
					handleGroupIdSelect={handleGroupIdSelect}
					previousGroupIds={initialValue || groupIds}
				/>
			)}
		</Fragment>
	);
}
