import { days, isSameDay, shortDays } from 'pkg/date';
import * as models from 'pkg/api/models';
import { EventTypes } from 'pkg/api/models/event';
import DateTime from 'pkg/datetime';
import * as arrays from 'pkg/arrays';

import { BookingTypes, FilterState } from 'routes/scheduling/templates/filters';

import { SchedulingViews } from 'components/scheduling/SelectView';

// Correct indexed keys to send as startingDay to the backend when creating a template
// This is used when getting weekdays from the days function inside pkg/date
// Look in routes/scheduling/templates/modals/Form for example
export const startingDaysIndex: { [key: number]: number } = {
	0: 1,
	1: 2,
	2: 3,
	3: 4,
	4: 5,
	5: 6,
	6: 0,
};

export interface ConflictMap {
	[key: number]: Set<number>;
}

export interface ConflictTypes {
	resourceConflicts: number[];
	groupConflicts: number[];
	scheduleItems?: number[];
}

export interface ConflictingItems {
	[key: number]: ConflictTypes;
}

// resourceConflictMap builds a map of which resources should conflict with other resources.
export function resourceConflictMap(
	resources: models.resource.Resource[]
): ConflictMap {
	const result: { [key: number]: Set<number> } = {};

	const findParents = (resource: models.resource.Resource): number[] => {
		if (!resource || resource.parentResourceId === null) {
			return [];
		}

		const parents = resources.filter((r) => r.id === resource.parentResourceId);
		let parentsParents: number[] = [];

		parents.forEach(
			(p) => (parentsParents = [...parentsParents, ...findParents(p)])
		);

		return [...parents.map((r) => r.id), ...parentsParents];
	};

	resources.forEach((r) => {
		result[r.id] = new Set(findParents(r));
	});

	resources.forEach((r) => {
		Object.entries(result).forEach(([resourceId, parentIds]) => {
			if (parentIds.has(r.id)) {
				result[r.id].add(Number.parseInt(resourceId, 10));
			}
		});

		result[r.id].add(r.id);
	});

	return result;
}

export const getWeekDaysOrder = (
	startingDay: number,
	scheduleDays: number,
	short: boolean = false
) => {
	const weekDays = !short ? days() : shortDays();

	let dayIndex = startingDay;
	const arr: string[] = [];

	for (let i = 0; i < scheduleDays; i++) {
		const day = weekDays.find((item) => {
			const index = Object.entries(startingDaysIndex).find(
				(i) => i[1] === dayIndex
			);

			if (item[0] === Number.parseInt(index[0])) {
				return item;
			}
		});

		arr.push(day[1]);

		if (dayIndex >= weekDays.length - 1) {
			dayIndex = 0;
		} else {
			dayIndex++;
		}
	}

	return arr;
};

// Returns start/end pair as ints instead of clock time.
//
// For example 07:00 to 14:00 comes back as [700, 1400]
export function timesAsNumbers(times: [string, string]): [number, number] {
	if (!times[0] || !times[1]) {
		return null;
	}

	return [
		Number.parseInt(times[0].replace(':', ''), 10),
		Number.parseInt(times[1].replace(':', ''), 10),
	];
}

// Returns the latest time as a string
export const getLatestEndTime = (times: {
	[key: string]: models.schedule.slot;
}): models.schedule.slot => {
	const latestEndTime = Object.values(times)
		.map((t) => Number.parseInt(t[1].replace(':', ''), 10))
		.sort((a, b) => b - a)[0];

	const a = latestEndTime.toString();
	const b =
		latestEndTime >= 2300
			? (2359).toString()
			: (latestEndTime + 100).toString();

	const hours = a.substring(0, 2);
	const minutes = a.substring(2, 4);
	const bhours = b.substring(0, 2);
	const bminutes = b.substring(2, 4);

	return [hours + ':' + minutes, bhours + ':' + bminutes];
};

export function scheduleItemTimesAsNumbers(
	item: models.scheduleItem.ScheduleItem
): [number, number] {
	return timesAsNumbers([item.startsAt, item.endsAt]);
}

const filterByType = (
	items: models.scheduleItem.ScheduleItem[] | models.booking.Booking[],
	types: EventTypes[]
) => {
	const itemsTypes = arrays.unique(items.map((i) => i.type));

	return arrays.intersects(types, itemsTypes).length > 0;
};

const filterByConflicts = (
	items: models.scheduleItem.ScheduleItem[] | models.booking.Booking[],
	resourceIds: number[]
) => {
	let found = false;

	items.forEach((i) => {
		if (resourceIds.includes(i.id)) {
			found = true;
			return;
		}
	});

	return found;
};

const filterByResourceIds = (
	items: models.scheduleItem.ScheduleItem[] | models.booking.Booking[],
	resourceIds: number[]
) => {
	const itemsResourceIds = arrays.unique(
		items
			.map((item) => [
				item.resourceId,
				...(item.resources?.map((r) => r.resourceId) ?? []),
			])
			.flat()
	);

	return arrays.intersects(resourceIds, itemsResourceIds).length > 0;
};

const filterByLocationIds = (
	items: models.scheduleItem.ScheduleItem[] | models.booking.Booking[],
	locationsIds: number[]
) => {
	return items.some(
		(s) =>
			s.resources?.some((r) =>
				locationsIds.includes(r.resource?.resourceLocationId)
			) || locationsIds.includes(s.resource?.resourceLocationId)
	);
};

const filterByCategoryIds = (
	items: models.scheduleItem.ScheduleItem[] | models.booking.Booking[],
	categoryIds: number[]
) => {
	const itemsCategoryIds = arrays.unique(
		items
			.map((item) => [
				...(item.resources?.map((r) => r.resource?.resourceCategoryId) ?? []),
			])
			.flat()
	);

	return arrays.intersects(categoryIds, itemsCategoryIds).length > 0;
};

const filterByGroupIds = (
	items: models.scheduleItem.ScheduleItem[],
	groupIds: number[]
) => {
	let found = false;

	items.forEach((i) => {
		if (groupIds.includes(i.groupId)) {
			found = true;
			return;
		}
	});

	return found;
};

const filterBookingsByGroupIds = (
	items: models.booking.Booking[],
	groupIds: number[]
) => {
	let found = false;

	items.forEach((i) => {
		if (groupIds.includes(i.forGroupId)) {
			found = true;
			return;
		}
	});

	return found;
};

const filterByAmountOfBookings = (
	item: models.group.Group | models.resource.Resource,
	amountOfItems: number,
	type: BookingTypes,
	amount: string
) => {
	switch (type) {
		case BookingTypes.Less:
			{
				if (amountOfItems < Number.parseInt(amount, 10)) {
					return item;
				}
			}
			break;
		case BookingTypes.More:
			{
				if (amountOfItems > Number.parseInt(amount, 10)) {
					return item;
				}
			}
			break;
		case BookingTypes.Equals:
			{
				if (amountOfItems === Number.parseInt(amount, 10)) {
					return item;
				}
			}
			break;
	}
};

// Find only conflicts that has resources that is conflicting
const findResourceConflicts = (conflicts: ConflictingItems) => {
	return Object.fromEntries(
		Object.entries(conflicts).filter((i) => {
			if (i[1].resourceConflicts.length > 0) {
				return true;
			}
			return false;
		})
	);
};

export function findGroupsByFilterForBookings(
	groups: models.group.Group[],
	bookings: models.booking.Booking[],
	filters: FilterState,
	conflicts: ConflictingItems
) {
	if (!filters) {
		return [];
	}

	let filteredGroups = groups;
	const resourceConflicts = findResourceConflicts(conflicts);

	if (filters.conflicts) {
		filteredGroups = filteredGroups.filter((item) => {
			const resourceIds = Object.keys(resourceConflicts).map((i) =>
				Number.parseInt(i, 10)
			);
			const groupItems = bookings.filter((b) => b.forGroupId === item.id);

			return filterByConflicts(groupItems, resourceIds);
		});
	}

	if (filters.types.length > 0) {
		filteredGroups = filteredGroups.filter((item) => {
			const groupItems = bookings.filter((b) => b.forGroupId === item.id);

			return filterByType(groupItems, filters.types);
		});
	}

	if (filters.bookings.type !== BookingTypes.Empty) {
		filteredGroups = filteredGroups.filter((item) => {
			let groupItems = bookings.filter((b) => b.forGroupId === item.id);

			if (filters.types.length > 0) {
				groupItems = groupItems.filter((i) => filters.types.includes(i.type));
			}

			if (filters.resources.length > 0) {
				groupItems = groupItems.filter((i) =>
					filters.resources.some((r) =>
						i.resources.map((br) => br.resourceId).includes(r)
					)
				);
			}

			if (filters.conflicts) {
				const resourceIds = Object.keys(resourceConflicts).map((i) =>
					Number.parseInt(i, 10)
				);

				groupItems = groupItems.filter((i) =>
					resourceIds.some((id) => i.resources.map((br) => br.id).includes(id))
				);
			}

			return filterByAmountOfBookings(
				item,
				groupItems.length,
				filters.bookings.type,
				filters.bookings.amount
			);
		});
	}

	if (filters.resources.length) {
		filteredGroups = filteredGroups.filter((item) => {
			const groupItems = bookings.filter((b) => b.forGroupId === item.id);

			return filterByResourceIds(groupItems, filters.resources);
		});
	}

	if (filters.groups.length > 0) {
		filteredGroups = filteredGroups.filter((item) =>
			filters.groups.includes(item.id)
		);
	}

	if (filters.locations.length > 0) {
		filteredGroups = filteredGroups.filter((item) => {
			const groupItems = bookings.filter((b) => b.forGroupId === item.id);

			return filterByLocationIds(groupItems, filters.locations);
		});
	}

	if (filters.categories.length > 0) {
		filteredGroups = filteredGroups.filter((item) => {
			const groupItems = bookings.filter((b) => b.forGroupId === item.id);

			return filterByCategoryIds(groupItems, filters.categories);
		});
	}

	return filteredGroups;
}

export function findGroupsByFilterByScheduleItems(
	groups: models.group.Group[],
	scheduleItems: models.scheduleItem.ScheduleItem[],
	filters: FilterState,
	conflicts: ConflictingItems
) {
	if (!filters) {
		return [];
	}

	let filteredGroups = groups;

	const resourceConflicts = findResourceConflicts(conflicts);

	if (filters.conflicts) {
		filteredGroups = filteredGroups.filter((item) => {
			const scheduleItemIds = Object.keys(resourceConflicts).map((i) =>
				Number.parseInt(i, 10)
			);
			const groupItems = scheduleItems.filter((i) => i.groupId === item.id);

			return filterByConflicts(groupItems, scheduleItemIds);
		});
	}

	if (filters.types.length > 0) {
		filteredGroups = filteredGroups.filter((item) => {
			const groupItems = scheduleItems.filter((i) => i.groupId === item.id);

			return filterByType(groupItems, filters.types);
		});
	}

	if (filters.bookings.type !== BookingTypes.Empty) {
		filteredGroups = filteredGroups.filter((item) => {
			let groupItems = scheduleItems.filter((i) => i.groupId === item.id);

			if (filters.types.length > 0) {
				groupItems = groupItems.filter((i) => filters.types.includes(i.type));
			}

			if (filters.resources.length > 0) {
				groupItems = groupItems.filter((i) =>
					filters.resources.some((r) =>
						i.resources.map((br) => br.resourceId).includes(r)
					)
				);
			}

			if (filters.conflicts) {
				const resourceIds = Object.keys(resourceConflicts).map((i) =>
					Number.parseInt(i, 10)
				);

				groupItems = groupItems.filter((i) =>
					resourceIds.some((id) => i.resources.map((br) => br.id).includes(id))
				);
			}

			return filterByAmountOfBookings(
				item,
				groupItems.length,
				filters.bookings.type,
				filters.bookings.amount
			);
		});
	}

	if (filters.resources.length > 0) {
		filteredGroups = filteredGroups.filter((item) => {
			const groupItems = scheduleItems.filter((i) => i.groupId === item.id);

			return filterByResourceIds(groupItems, filters.resources);
		});
	}

	if (filters.groups.length > 0) {
		filteredGroups = filteredGroups.filter((item) =>
			filters.groups.includes(item.id)
		);
	}

	if (filters.locations.length > 0) {
		filteredGroups = filteredGroups.filter((item) => {
			const groupItems = scheduleItems.filter((i) => i.groupId === item.id);

			return filterByLocationIds(groupItems, filters.locations);
		});
	}

	if (filters.categories.length > 0) {
		filteredGroups = filteredGroups.filter((item) => {
			const groupItems = scheduleItems.filter((i) => i.groupId === item.id);

			return filterByCategoryIds(groupItems, filters.categories);
		});
	}

	return filteredGroups;
}

const findScheduleItemsByResource = (
	scheduleItems: models.scheduleItem.ScheduleItem[],
	resource: models.resource.Resource
) =>
	scheduleItems.filter(
		(i) =>
			i.resources?.map((r) => r.resourceId).includes(resource.id) ||
			i.resourceId === resource.id
	);

const findBookingsByResource = (
	bookings: models.booking.Booking[],
	resource: models.resource.Resource
) => {
	return bookings.filter(
		(b) =>
			b.resources?.map((r) => r.resourceId).includes(resource.id) ||
			b.resourceId === resource.id
	);
};

export const findResourcesByFilterByScheduleItems = (
	resources: models.resource.Resource[],
	scheduleItems: models.scheduleItem.ScheduleItem[],
	filters: FilterState,
	conflicts: ConflictingItems
) => {
	if (!filters) {
		return [];
	}

	let filteredResources = resources;

	const resourceConflicts = findResourceConflicts(conflicts);

	if (filters.conflicts) {
		const scheduleItemIds = Object.keys(resourceConflicts).map((i) =>
			Number.parseInt(i, 10)
		);
		filteredResources = filteredResources.filter((resource) => {
			const items = findScheduleItemsByResource(scheduleItems, resource);

			return filterByConflicts(items, scheduleItemIds);
		});
	}

	if (filters.types.length > 0) {
		filteredResources = filteredResources.filter((resource) => {
			const items = findScheduleItemsByResource(scheduleItems, resource);

			return filterByType(items, filters.types);
		});
	}

	if (filters.bookings.type !== BookingTypes.Empty) {
		filteredResources = filteredResources.filter((resource) => {
			let resourceItems = findScheduleItemsByResource(scheduleItems, resource);

			if (filters.types.length > 0) {
				resourceItems = resourceItems.filter((i) =>
					filters.types.includes(i.type)
				);
			}

			if (filters.resources.length > 0) {
				resourceItems = resourceItems.filter((resource) =>
					filters.resources.includes(resource.id)
				);
			}

			return filterByAmountOfBookings(
				resource,
				resourceItems.length,
				filters.bookings.type,
				filters.bookings.amount
			);
		});
	}

	if (filters.resources.length > 0) {
		filteredResources = filteredResources.filter((resource) =>
			filters.resources.includes(resource.id)
		);
	}

	if (filters.groups.length > 0) {
		filteredResources = filteredResources.filter((resource) => {
			const resourceItems = findScheduleItemsByResource(
				scheduleItems,
				resource
			);

			return filterByGroupIds(resourceItems, filters.groups);
		});
	}

	if (filters.locations.length > 0) {
		filteredResources = filteredResources.filter((resource) => {
			const resourceItems = findScheduleItemsByResource(
				scheduleItems,
				resource
			);

			return filterByLocationIds(resourceItems, filters.locations);
		});
	}

	if (filters.categories.length > 0) {
		filteredResources = filteredResources.filter((resource) => {
			const resourceItems = findScheduleItemsByResource(
				scheduleItems,
				resource
			);

			return filterByCategoryIds(resourceItems, filters.categories);
		});
	}

	return filteredResources;
};

export const findResourcesByFilterByBookings = (
	resources: models.resource.Resource[],
	bookings: models.booking.Booking[],
	filters: FilterState,
	conflicts: ConflictingItems
) => {
	if (!filters) {
		return [];
	}

	let filteredResources = resources;

	const resourceConflicts = findResourceConflicts(conflicts);

	if (filters.conflicts) {
		const scheduleItemIds = Object.keys(resourceConflicts).map((i) =>
			Number.parseInt(i, 10)
		);
		filteredResources = filteredResources.filter((resource) => {
			const items = findBookingsByResource(bookings, resource);

			return filterByConflicts(items, scheduleItemIds);
		});
	}

	if (filters.types.length > 0) {
		filteredResources = filteredResources.filter((resource) => {
			const items = findBookingsByResource(bookings, resource);

			return filterByType(items, filters.types);
		});
	}

	if (filters.bookings.type !== BookingTypes.Empty) {
		filteredResources = filteredResources.filter((resource) => {
			let resourceItems = findBookingsByResource(bookings, resource);

			if (filters.types.length > 0) {
				resourceItems = resourceItems.filter((i) =>
					filters.types.includes(i.type)
				);
			}

			if (filters.resources.length > 0) {
				resourceItems = resourceItems.filter((resource) =>
					filters.resources.includes(resource.id)
				);
			}

			return filterByAmountOfBookings(
				resource,
				resourceItems.length,
				filters.bookings.type,
				filters.bookings.amount
			);
		});
	}

	if (filters.resources.length > 0) {
		filteredResources = filteredResources.filter((resource) =>
			filters.resources.includes(resource.id)
		);
	}

	if (filters.groups.length > 0) {
		filteredResources = filteredResources.filter((resource) => {
			const resourceItems = findBookingsByResource(bookings, resource);

			return filterBookingsByGroupIds(resourceItems, filters.groups);
		});
	}

	if (filters.locations.length > 0) {
		filteredResources = filteredResources.filter((resource) => {
			const resourceItems = findBookingsByResource(bookings, resource);

			return filterByLocationIds(resourceItems, filters.locations);
		});
	}

	if (filters.categories.length > 0) {
		filteredResources = filteredResources.filter((resource) => {
			const resourceItems = findBookingsByResource(bookings, resource);

			return filterByCategoryIds(resourceItems, filters.categories);
		});
	}

	return filteredResources;
};

export const getAvailableSlots = (
	slots: models.schedule.SlotConfig,
	scheduleItems: models.scheduleItem.ScheduleItem[],
	dayNumber: number
) =>
	Object.values(
		slots.sameSlotsAllDays ? slots.times : slots.days[dayNumber - 1]
	).filter((slot) => {
		return !scheduleItems.some((item) => {
			const itemTimes = scheduleItemTimesAsNumbers(item);
			const slotTimes = timesAsNumbers(slot);
			itemTimes[0]++;
			itemTimes[1]--;

			if (itemTimes[0] >= slotTimes[0] && itemTimes[0] <= slotTimes[1]) {
				return true;
			}

			if (itemTimes[1] >= slotTimes[0] && itemTimes[1] <= slotTimes[1]) {
				return true;
			}

			return false;
		});
	});

export const conflictingTimes = (
	times: [number, number],
	itemTimes: [number, number]
) => {
	let hasConflict = false;
	if (
		(times[0] > itemTimes[0] && times[0] < itemTimes[1]) ||
		(times[1] > itemTimes[0] && times[1] < itemTimes[1]) ||
		times[0] === itemTimes[0] ||
		times[1] === itemTimes[1] ||
		(itemTimes[0] > times[0] && itemTimes[0] < times[1]) ||
		(itemTimes[1] > times[0] && itemTimes[1] < times[1])
	) {
		hasConflict = true;
	}

	return hasConflict;
};

export const findScheduleItemConflicts = (
	conflictMap: ConflictMap,
	scheduleItems: models.scheduleItem.ScheduleItem[],
	times: [number, number],
	days: number[]
) => {
	const conflicts: number[] = [];

	if (times && scheduleItems.length > 0) {
		scheduleItems.forEach((item) => {
			const itemTimes = timesAsNumbers([item.startsAt, item.endsAt]);
			// check for conlifcts
			let hasRelatedResource = false;
			const itemResourceIds = getItemResourceIds(item);

			if (itemResourceIds.length > 0) {
				itemResourceIds.forEach((id) => {
					if (conflictMap[id]?.has(id)) {
						hasRelatedResource = true;
					}
				});
			}

			if (!hasRelatedResource) {
				return;
			}

			if (!!!itemResourceIds.length) {
				return;
			}

			if (!days.includes(item.day)) {
				return;
			}

			const hasConflict = conflictingTimes(times, itemTimes);

			if (!hasConflict) {
				return;
			}

			itemResourceIds.forEach((i) =>
				conflictMap[i]?.forEach((inner) => conflicts.push(inner))
			);
		});
	}

	return conflicts.filter((el, i) => conflicts.indexOf(el) === i);
};

export const findBookingConflicts = (
	conflictMap: ConflictMap,
	bookings: models.booking.Booking[],
	times: [number, number]
) => {
	const conflicts: number[] = [];

	const dateTime = new DateTime(new Date(times[0] * 1000));

	bookings.forEach((item) => {
		let hasRelatedResource = false;

		if (item.resources?.length > 0) {
			item.resources.forEach((i) => {
				if (conflictMap[i.resourceId]?.has(i.resourceId)) {
					hasRelatedResource = true;
				}
			});
		}

		if (!hasRelatedResource) {
			return;
		}

		if (!!!item.resources) {
			return;
		}

		const date = new Date(item.startsAt * 1000);

		if (!isSameDay(date, dateTime)) {
			return;
		}

		const hasConflict = conflictingTimes(times, [item.startsAt, item.endsAt]);

		if (!hasConflict) {
			return;
		}

		item.resources.forEach((i) =>
			conflictMap[i.resourceId]?.forEach((inner) => conflicts.push(inner))
		);
	});

	return conflicts;
};

export interface ScheduleGridItem {
	title: string;
	id: number;

	group?: models.group.Group;

	resource?: models.resource.Resource;
	resources?: ItemResource[];
	resourceId?: number;
}

export const getTotalHeightOfDay = (
	gridItems: ScheduleGridItem[],
	conflicts: ConflictingItems,
	isSmallScreen: boolean,
	view: SchedulingViews,
	availableSlots?: models.schedule.slot[]
) => {
	const slotHeight = 23;
	const resourceHeight = isSmallScreen ? 12 : 13;
	const titleHeight = isSmallScreen ? 13 : 17;
	const itemHeight = isSmallScreen ? 21 : 35;
	const resourceWrapperHeight = isSmallScreen ? 9 : 17;
	const gapHeight = 2;
	const buttonHeight = 23;
	const conflictHeight = 20;
	const groupTitleHeight = isSmallScreen ? 13 : 17;
	const locationTitleHeight = 16;
	let totalHeight = 0;

	// Go through each available slot and add to the total height
	if (availableSlots) {
		availableSlots.forEach(() => {
			totalHeight += slotHeight + gapHeight;
		});
	}

	// Go through schedule items
	gridItems.forEach((g) => {
		// itemHeight is the default height of an item with only a time set
		totalHeight += itemHeight;

		const rIds = getItemResourceIds(g);
		// Check if we have any resources and add empty height of the wrapper
		if (!!rIds.length) {
			totalHeight += resourceWrapperHeight;
		}

		// Add resourceHeight for each resource we have on the item
		rIds.forEach(() => {
			totalHeight += resourceHeight + gapHeight;
		});

		// If item has a title add more height
		if (g.title.length > 0) {
			totalHeight += titleHeight;
		}

		// Check if item has conflicts
		if (conflicts[g.id]?.resourceConflicts || conflicts[g.id]?.groupConflicts) {
			totalHeight += conflictHeight;
		}

		// Check if we're in resource view and add group name
		if (view === SchedulingViews.Resource && g?.group?.name) {
			totalHeight += groupTitleHeight;
		}

		if (g.resource?.location?.title) {
			totalHeight += locationTitleHeight;
		}

		// Add gapHeight for each item
		totalHeight += gapHeight;
	});

	totalHeight += buttonHeight + gapHeight;

	return totalHeight;
};

interface ItemResource {
	resource: models.resource.Resource;
}

interface Item {
	resource?: models.resource.Resource;
	resourceId?: number;
	resources?: ItemResource[];
}

// Returns either bookings/scheduleItems resources ids
export function getItemResourceIds(
	item: Item,
	includeRemoved = false
): number[] {
	let ids: number[] = [];

	if (!!item?.resources?.length) {
		if (includeRemoved) {
			ids = item.resources.map((r) => r.resource.id);
		} else {
			ids = item.resources
				.filter((r) => !r.resource.deletedAt)
				.map((r) => r.resource.id);
		}
	}

	if (item?.resourceId) {
		ids = [item.resourceId, ...ids];
	}

	return ids;
}

// Returns either bookings/scheduleItems resources
export function getItemResources(
	item: Item,
	includeRemoved = false
): models.resource.Resource[] {
	let resources: models.resource.Resource[] = [];

	if (!!item?.resources?.length) {
		if (includeRemoved) {
			resources = item.resources.map((r) => r.resource);
		} else {
			resources = item.resources
				.filter((r) => !r.resource.deletedAt)
				.map((r) => r.resource);
		}
	}

	if (item?.resourceId) {
		resources = [item.resource, ...resources];
	}

	return resources;
}
