import { Fragment, useState, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import { pushState } from 'pkg/router/state';
import * as selectors from 'pkg/selectors';
import { isDev } from 'pkg/flags';
import DebugMenu from 'pkg/debug/menu';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';

import Icon, { IconName } from 'components/icon';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import ActionModal from 'components/navigation/header/large_screen/ActionModal';
import Back from 'components/navigation/Back';
import {
	Header,
	PageIcon,
	ActionTrigger,
	NavWrap,
	Actions,
	PageTitle,
	Wrap,
} from 'components/navigation/header/large_screen/Styles';
import { LargeScreenSubNav } from 'components/navigation/header/large_screen/SubNav';
import getActionMenuItems from 'components/navigation/menus/ActionMenu';
import { NotificationsItem } from 'components/navigation/header/large_screen/notifications-item';

import * as ContextMenu from 'design/context_menu';

interface LargeScreenHeaderProps {
	title: string;
	subTitle?: string;
	icon?: MaterialSymbolVariant;
	compact?: boolean;
	children?: ReactNode;
}

export default function LargeScreenHeader({
	title,
	subTitle,
	icon,
	children,
}: LargeScreenHeaderProps): JSX.Element {
	const [actionModal, setActionModal] = useState(null);

	const org = useCurrentOrganization();
	const activeMembership = useCurrentMembership();
	const group = useCurrentGroup();
	const groupSport = group.sport;
	const groups = useSelector((state) =>
		selectors.groups.getOrganizationTree(state, {
			groupId: activeMembership.groupId,
		})
	);

	const defaultGroup = groups.get(activeMembership.groupId);

	const goTo = (href: string) => pushState(href);

	const actionMenuItems = getActionMenuItems(
		org.id,
		activeMembership,
		groupSport,
		group
	);

	const handleActionClick = (item: any) => {
		setActionModal(item.slug);
	};

	return (
		<Fragment>
			<Wrap>
				<Header>
					<Back largeLayout />
					<PageIcon variant={icon} />
					<PageTitle context={!children && subTitle} data-testid="pagetitle">
						{title}
					</PageTitle>

					<Actions>
						{isDev() && <DebugMenu />}

						{actionMenuItems.length > 0 && (
							<ActionTrigger offsetHorizontal={2} offsetVertical={50}>
								{actionMenuItems.map((item, n) => (
									<ContextMenu.Item
										key={`action-menu-item-${n}`}
										testid={`create_menu.item.${item.properties.icon}`}
										onClick={
											item.properties.href
												? () => goTo(item.properties.href)
												: () => handleActionClick(item.properties)
										}>
										<Icon name={item.properties.icon as IconName} />
										<span>{item.name}</span>
									</ContextMenu.Item>
								))}
							</ActionTrigger>
						)}
						<NotificationsItem />
					</Actions>
				</Header>

				{children && (
					<NavWrap>
						<LargeScreenSubNav toggleText={t('More...')}>
							{children}
						</LargeScreenSubNav>
					</NavWrap>
				)}
			</Wrap>

			<ActionModal
				actionModal={actionModal}
				setActionModal={setActionModal}
				groups={groups}
				defaultGroup={defaultGroup}
			/>
		</Fragment>
	);
}
