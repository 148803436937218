// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._UoPw4Uz_cSV9m6kEuDE {
	  display: inline-block;
	  justify-items: center;
	  cursor: pointer;
	  text-align: center;
	  font-weight: var(--font-weight-semibold);
	  color: var(--palette-green-500);
}

.UJMSjUNH4Ntd2IcsQHsT {
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-3xl);
	margin-top: var(--spacing-6);
}

.JVny5XtbYtuoFdnvI1Sg {
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	padding-bottom: var(--spacing-7);
}

.iVEltZ69AMRSx4G90k8C {
	text-align: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	margin-bottom: var(--spacing-8);
}
`, "",{"version":3,"sources":["webpack://./components/group/settings/invite-link-modal/styles.css"],"names":[],"mappings":"AAAA;GACG,qBAAqB;GACrB,qBAAqB;GACrB,eAAe;GACf,kBAAkB;GAClB,wCAAwC;GACxC,+BAA+B;AAClC;;AAEA;CACC,wCAAwC;CACxC,+BAA+B;CAC/B,4BAA4B;AAC7B;;AAEA;CACC,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,gCAAgC;AACjC;;AAEA;CACC,kBAAkB;CAClB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,+BAA+B;AAChC","sourcesContent":[".inviteLinkCopyTrue {\n\t  display: inline-block;\n\t  justify-items: center;\n\t  cursor: pointer;\n\t  text-align: center;\n\t  font-weight: var(--font-weight-semibold);\n\t  color: var(--palette-green-500);\n}\n\n.large {\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-3xl);\n\tmargin-top: var(--spacing-6);\n}\n\n.normal {\n\tjustify-content: center;\n\tpadding-bottom: var(--spacing-7);\n}\n\n.base {\n\ttext-align: center;\n\tjustify-content: center;\n\tmargin-bottom: var(--spacing-8);\n}\n"],"sourceRoot":""}]);
// Exports
export var inviteLinkCopyTrue = `_UoPw4Uz_cSV9m6kEuDE`;
export var large = `UJMSjUNH4Ntd2IcsQHsT`;
export var normal = `JVny5XtbYtuoFdnvI1Sg`;
export var base = `iVEltZ69AMRSx4G90k8C`;
export default ___CSS_LOADER_EXPORT___;
