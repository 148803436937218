// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OfYSZkgAVtl24ChyOCDB {
	display: grid;
	grid-auto-flow: column;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: start;
	-webkit-box-align: var(--align, start);
	    -ms-flex-align: var(--align, start);
	        align-items: var(--align, start);
	justify-items: normal;
	justify-items: var(--justify, normal);
	-webkit-box-pack: normal;
	    -ms-flex-pack: normal;
	        justify-content: normal;
	-webkit-box-pack: var(--justify-content, normal);
	    -ms-flex-pack: var(--justify-content, normal);
	        justify-content: var(--justify-content, normal);
	grid-auto-columns: 1fr;
	grid-auto-columns: var(--auto-columns, 1fr);
	grid-template-columns: none;
	grid-template-columns: var(--columns, none);
	gap: var(--spacing-4);
	grid-gap: var(--spacing-4);
	grid-gap: var(--spacing, var(--spacing-4));
	gap: var(--spacing, var(--spacing-4));
}

@media (max-width: 768px) {

	.OfYSZkgAVtl24ChyOCDB {
		gap: var(--spacing-4);
		gap: var(--collapsed-spacing, var(--spacing, var(--spacing-4)));
}

		.OfYSZkgAVtl24ChyOCDB.U6ysj0CzEG5YhTsRWmdO {
			grid-auto-flow: row;
			grid-template-columns: initial;
		}
	}
`, "",{"version":3,"sources":["webpack://./components/layout/row/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,wBAAgC;KAAhC,qBAAgC;SAAhC,kBAAgC;CAAhC,sCAAgC;KAAhC,mCAAgC;SAAhC,gCAAgC;CAChC,qBAAqC;CAArC,qCAAqC;CACrC,wBAA+C;KAA/C,qBAA+C;SAA/C,uBAA+C;CAA/C,gDAA+C;KAA/C,6CAA+C;SAA/C,+CAA+C;CAC/C,sBAA2C;CAA3C,2CAA2C;CAC3C,2BAA2C;CAA3C,2CAA2C;CAC3C,qBAAqC;CAArC,0BAAqC;CAArC,0CAAqC;CAArC,qCAAqC;AAUtC;;AARC;;CAVD;EAWE,qBAA+D;EAA/D,+DAA+D;AAOjE;;EALE;GACC,mBAAmB;GACnB,8BAA4B;EAC7B;CACD","sourcesContent":[".row {\n\tdisplay: grid;\n\tgrid-auto-flow: column;\n\talign-items: var(--align, start);\n\tjustify-items: var(--justify, normal);\n\tjustify-content: var(--justify-content, normal);\n\tgrid-auto-columns: var(--auto-columns, 1fr);\n\tgrid-template-columns: var(--columns, none);\n\tgap: var(--spacing, var(--spacing-4));\n\n\t@media (--small-viewport) {\n\t\tgap: var(--collapsed-spacing, var(--spacing, var(--spacing-4)));\n\n\t\t&.collapse-on-small-screens {\n\t\t\tgrid-auto-flow: row;\n\t\t\tgrid-template-columns: unset;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var row = `OfYSZkgAVtl24ChyOCDB`;
var _1 = `U6ysj0CzEG5YhTsRWmdO`;
export { _1 as "collapse-on-small-screens" };
export default ___CSS_LOADER_EXPORT___;
