import { Fragment, useState } from 'react';

import Footer from 'components/context-switch-footer';

import OrganizationSwitcher from 'components/navigation/menus/organization_switcher';
import GroupSwitcher from 'components/navigation/menus/group_switcher';
import GroupSwitcherProvider from 'components/navigation/menus/group_switcher/Provider';
import JoinGroupModal from 'components/group/join-modal';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

export function useContextSwitchFooter() {
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const openModal = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);

	return {
		Footer: (
			<Fragment>
				<Context.Divider tight />
				<Footer openJoinModal={openModal} />
			</Fragment>
		),
		JoinModal: (
			<Fragment>
				{modalOpen && <JoinGroupModal onClose={closeModal} />}
			</Fragment>
		),
	};
}

export default function ContextSwitcher() {
	return (
		<div className={css.wrapper}>
			<div className={css.organizationSwitcher}>
				<OrganizationSwitcher />
			</div>
			<div className={css.groupSwitcher}>
				<GroupSwitcherProvider>
					<GroupSwitcher />
				</GroupSwitcherProvider>
			</div>
		</div>
	);
}
