import * as sdk from 'pkg/core/sdk';

export interface Payload {
	[key: string]: string | number | any[];
}

interface Options {
	params?: Payload;
	headers?: Payload;
	payload?: Payload;
}

let __headers: Payload = {};

function resetHeaders() {
	__headers = {};
}

function normalizeHeaders(headers: Payload): Payload {
	return { ...__headers, ...headers };
}

export async function get<T>(
	endpoint: string,
	options: Options = {}
): Promise<[Response, T]> {
	const { params, headers, payload } = options;

	let response: T;

	const request: Response = await sdk.get(
		endpoint,
		params,
		payload,
		normalizeHeaders(headers)
	);

	const upgradeRequired = request.status === 429;

	try {
		response = await request.json();
	} catch (e: unknown) {
		response = null;
	}

	if (!request.ok || upgradeRequired) {
		return [request, response];
	}

	resetHeaders();

	return [request, response];
}

type GetReturnType<T> = (
	endpoint: string,
	options?: Options
) => Promise<[Response, T]>;

export async function post<T, P = Payload>(
	endpoint: string,
	payload: P,
	options: Options = {}
): Promise<[Response, T]> {
	const { params, headers } = options;

	let response: T;

	const request: Response = await sdk.post(
		endpoint,
		params,
		payload,
		normalizeHeaders(headers)
	);

	try {
		response = await request.json();
	} catch (e: unknown) {
		response = null;
	}

	if (!request.ok) {
		return [request, response];
	}

	resetHeaders();

	return [request, response];
}

export async function patch<T, P = Payload>(
	endpoint: string,
	payload: P,
	options: Options = {}
): Promise<[Response, T]> {
	const { params, headers } = options;

	let response: T;

	const request: Response = await sdk.patch(
		endpoint,
		params,
		payload,
		normalizeHeaders(headers)
	);

	try {
		response = await request.json();
	} catch (e: unknown) {
		response = null;
	}

	if (!request.ok) {
		return [request, response];
	}

	resetHeaders();

	return [request, response];
}

type PostReturnType<T, P = Payload> = (
	endpoint: string,
	payload: P,
	options?: Options
) => Promise<[Response, T]>;

type PatchReturnType<T, P = Payload> = (
	endpoint: string,
	payload: P,
	options?: Options
) => Promise<[Response, T]>;

async function destroy<T>(
	endpoint: string,
	options: Options = {}
): Promise<[Response, T]> {
	const { params, headers } = options;

	let response: T;

	const request: Response = await sdk.destroy(
		endpoint,
		params,
		null,
		normalizeHeaders(headers)
	);

	const upgradeRequired = request.status === 429;

	try {
		response = await request.json();
	} catch (e: unknown) {
		response = null;
	}

	if (!request.ok || upgradeRequired) {
		return [request, response];
	}

	resetHeaders();

	return [request, response];
}

// @NOTE Export as "delete" for API consistency
export { destroy as delete };

type DeleteReturnType<T> = (
	endpoint: string,
	options?: Options
) => Promise<[Response, T]>;

/* @NOTE Below functions are helper functions, allows for chaining. */

interface WithHeadersReturnType<T, P> {
	get: GetReturnType<T>;
	post: PostReturnType<T, P>;
	patch: PatchReturnType<T, P>;
	delete: DeleteReturnType<T>;
}

export function withHeaders<T, P = Payload>(
	headers: Payload
): WithHeadersReturnType<T, P> {
	__headers = { ...__headers, ...headers };

	/** @NOTE Return functions needs to be proxied in order to be chainable without getting TS errors */

	const proxiedGet = <T>(endpoint: string, options: Options = {}) =>
		get<T>(endpoint, options);

	const proxiedPost = <T, P>(
		endpoint: string,
		payload: P,
		options: Options = {}
	) => post<T, P>(endpoint, payload, options);

	const proxiedPatch = <T, P>(
		endpoint: string,
		payload: P,
		options: Options = {}
	) => patch<T, P>(endpoint, payload, options);

	const proxiedDelete = <T>(endpoint: string, options: Options = {}) =>
		destroy<T>(endpoint, options);

	return {
		get: proxiedGet,
		post: proxiedPost,
		patch: proxiedPatch,
		delete: proxiedDelete,
	};
}

export function acceptLanguage<T, P = Payload>(
	acceptLangauge: string = 'en_US'
): WithHeadersReturnType<T, P> {
	return withHeaders<T, P>({ 'Accept-Language': acceptLangauge });
}

export function forAccount<T, P = Payload>(
	accountId: number
): WithHeadersReturnType<T, P> {
	return withHeaders<T, P>({ 'X-For-Account': accountId.toString() });
}

export function withAccountToken<T, P = Payload>(
	accountToken: string
): WithHeadersReturnType<T, P> {
	return withHeaders<T, P>({ 'X-Account-Token': accountToken });
}
