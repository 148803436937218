import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';
import spacing from 'pkg/config/spacing';

import PurchaseHistory from 'routes/user/billing/PurchaseHistory';
import InvoiceList from 'routes/user/billing/invoice_list';
import Subscriptions from 'routes/user/billing/subscription_list';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import { Inner } from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import ContactInformation from 'components/group/club-contact-information';

const Billing = () => {
	return (
		<Inner topSpacing={30} maxWidth={PageWidths.STANDARD}>
			<Column spacing={spacing._6}>
				<Column>
					<SectionTitle>{t('Current invoices')}</SectionTitle>
					<InvoiceList />
				</Column>

				<Column>
					<SectionTitle>{t('Subscriptions')}</SectionTitle>
					<Subscriptions />
				</Column>

				<Column>
					<SectionTitle>{t('Purchase history')}</SectionTitle>
					<Card.Base>
						<PurchaseHistory />
					</Card.Base>
				</Column>

				<ContactInformation heading={t('Questions about billing?')} />
			</Column>
		</Inner>
	);
};
export default Billing;
