import { t } from '@transifex/native';
import { ChangeEvent, SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { RecurringOptionStrings } from 'pkg/api/models/provider_settings';

import { PriceMixedState } from 'routes/payments/prices/modals/new';
import { getMaxOccurrenceNumber } from 'routes/payments/products/form/price_option';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import SelectBillingCycle from 'components/prices/billing-cycle-select';

import * as css from './styles.css';

interface CreatePriceFormProps {
	disableRecurringChange: boolean;

	group: models.group.Group;
	formState: PriceMixedState;
	setFormState: MixedStateSetter<PriceMixedState>;

	providerSettings: models.providerSettings.ProviderSettings;
}

export default function CreatePriceForm({
	disableRecurringChange = false,
	group,
	providerSettings,
	formState,
	setFormState,
}: CreatePriceFormProps) {
	const formCtx = useFormContext();
	const billingCycleInput = formCtx.watch('billingCycle');

	const customIntervals =
		models.providerSettings.getIntervals(providerSettings);
	const maxOccurrenceNumber = getMaxOccurrenceNumber(
		formState.recurringInterval
	);

	const setRecurring = () =>
		setFormState({
			recurring: !formState.recurring,
		});

	const handleChangeRecurringOption = (
		event: SyntheticEvent<HTMLSelectElement>
	) =>
		setFormState({
			recurringOption: event.currentTarget.value as RecurringOptionStrings,
		});

	const handleIntervalCountChange = (event: ChangeEvent<HTMLInputElement>) =>
		setFormState({
			recurringIntervalCount: Number.parseInt(event.target.value, 10),
		});

	const handleRecurringIntervalChange = (
		event: ChangeEvent<HTMLSelectElement>
	) =>
		setFormState({
			recurringInterval: event.target
				.value as models.providerSettings.RecurringInterval,
		});

	let showBillingError = false;

	if (formCtx.formState.submitCount > 0 && !!!billingCycleInput) {
		showBillingError = true;
	}

	return (
		<Column>
			<Input.Group label={t('Price type')}>
				<Input.Control
					type="radio"
					label={t('Recurring')}
					description={t('Charge members on a schedule')}
					checked={formState.recurring}
					onChange={setRecurring}
					disabled={disableRecurringChange}
				/>
				<Input.Control
					type="radio"
					label={t('One time payment')}
					description={t('Let members pay manually')}
					checked={!formState.recurring}
					onChange={setRecurring}
					disabled={disableRecurringChange}
				/>
			</Input.Group>
			<div className={css.inputWrapper}>
				<Input.Group
					label={t('Price title')}
					hint={t('Example: Yearly subscription')}>
					<Input.Field name="title" required />
				</Input.Group>
			</div>
			<div className={css.priceWrapper}>
				<Input.Group label={t('Price')}>
					<Input.Field name="cost" type="number" step="0.01" required>
						<Input.Prefix>{group.currency.toUpperCase()}</Input.Prefix>
					</Input.Field>
				</Input.Group>
			</div>

			{formState.recurring && (
				<div className={css.inputWrapper}>
					<Column>
						<Input.Group label={t('Billing cycle')} hasError={showBillingError}>
							<SelectBillingCycle
								value={formState.recurringOption}
								onChange={handleChangeRecurringOption}
								providerSettings={providerSettings}
							/>
						</Input.Group>
						{formState.recurringOption === 'custom' && (
							<Input.Group label={t('Custom can not be longer than a year')}>
								<Column spacing={styles.spacing._3}>
									<Input.Field
										type="number"
										value={formState.recurringIntervalCount.toString()}
										onChange={handleIntervalCountChange}
										max={maxOccurrenceNumber}
										hasError={
											formState.recurringIntervalCount > maxOccurrenceNumber
										}>
										<Input.Prefix>
											<span>{t('every')}</span>
										</Input.Prefix>
									</Input.Field>
									<Input.Select
										value={formState.recurringInterval}
										onChange={handleRecurringIntervalChange}>
										{customIntervals.map((interval, i) => (
											<option key={i} value={interval}>
												{models.providerSettings.getCustomIntervalTranslation(
													interval
												)}
											</option>
										))}
									</Input.Select>
								</Column>
							</Input.Group>
						)}
					</Column>
				</div>
			)}
		</Column>
	);
}
