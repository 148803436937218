import { t } from '@transifex/native';
import { FC, useEffect } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { AccountRelationTypes } from 'pkg/api/models/account_relation';
import {
	useCurrentAccount,
	useCurrentMembership,
	useCurrentUser,
} from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import ChildRelations from 'routes/account/settings/child-relations';
import ParentRelations from 'routes/account/settings/ParentRelations';
import DisplaySettings from 'routes/account/settings/Display';
import Login from 'routes/account/settings/login';
import PrivacySettings from 'routes/account/settings/privacy';
import NotificationSettings from 'routes/account/settings/Notifications';

import Column from 'components/layout/column';

interface SettingsProps {
	currentTab: string;
	setPageSubNavItems?: (items: any[]) => void;
}

const AccountSettings: FC<SettingsProps> = ({
	currentTab,
	setPageSubNavItems,
}: SettingsProps): JSX.Element => {
	const account = useCurrentAccount();
	const membership = useCurrentMembership();

	const { records: relations } =
		useCollection<models.accountRelation.AccountRelation>(
			endpoints.AccountRelations.Index()
		);
	const { records: requestRelations } =
		useCollection<models.accountRelation.AccountRelation>(
			endpoints.AccountRelations.Index(),
			{
				queryParams: new URLSearchParams({
					requests: '1',
				}),
			}
		);

	const showParentsTab = [
		...relations.filter((r) => r.type === AccountRelationTypes.Ward),
		...requestRelations.filter(
			(r) => r.type === AccountRelationTypes.LegalGuardian
		),
	];

	useEffect(() => {
		const items: any[] = [
			{
				href: routes.Account.Settings.Show('general'),
				title: t(`Account`),
				testid: 'subnav.account_settings.account',
			},
		];

		if (models.account.isAnAdult(account) || membership.isLegalGuardian) {
			items.push({
				href: routes.Account.Settings.Show('children'),
				title: t(`My children`),
				testid: 'subnav.account_settings.parent',
			});
		}

		if (
			showParentsTab.length > 0 &&
			models.hasLink(account, 'show:relations')
		) {
			items.push({
				href: routes.Account.Settings.Show('parents'),
				title: t(`My parents`),
				testid: 'subnav.account_settings.child',
			});
		}

		items.push({
			href: routes.Account.Settings.Show('privacy'),
			title: t(`Privacy`),
			testid: 'subnav.account_settings.privacy',
		});

		setPageSubNavItems(items);
	}, [account, showParentsTab.length]);

	const user = useCurrentUser();

	const renderTabViews = (currentTab: string) => {
		switch (currentTab) {
			case 'privacy':
				return <PrivacySettings account={account} />;
			case 'parents':
				return <ParentRelations />;
			case 'children':
				return <ChildRelations />;
			default:
				return (
					<Column spacing={styles.spacing._6}>
						<DisplaySettings account={account} />
						<NotificationSettings account={account} />
						<Login user={user} account={account} />
					</Column>
				);
		}
	};

	return renderTabViews(currentTab);
};

export default AccountSettings;
