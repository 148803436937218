import { t } from '@transifex/native';
import { createContext, useState, useEffect } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import { PricePayload } from 'pkg/actions/services/products';
import * as actions from 'pkg/actions/index';

import * as models from 'pkg/api/models';
import { pushState } from 'pkg/router/state';
import { decimalToCentsByCurrency } from 'pkg/utils';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { useQueryState } from 'pkg/hooks/query-state';
import { TaxRate } from 'pkg/api/models/tax_rate';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as routes from 'pkg/router/routes';
import { useCurrentGroup } from 'pkg/identity';

import AddForm from 'routes/payments/products/form';
import { validatePriceOptions } from 'routes/payments/products/form/price_option';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

import { FormActionFooter } from 'styles/Form';

export interface PriceOptionData {
	priceTitle: string;
	cost: number;
	currency: string;
	description?: string;
	taxRateId: string;
	taxRate: TaxRate;
	recurring: boolean;
	recurringIntervalCount: number;
	recurringInterval: models.providerSettings.RecurringInterval;
	recurringOption?: models.providerSettings.RecurringOptionStrings;
	id?: number;
}

export interface ProductMixedState {
	category: string;
	productTitle: string;
	description: string;
	isDefault: boolean;
	metadata: models.product.ProductMetadata[];
	priceOptions: PriceOptionData[];
}

const initialState: ProductMixedState = {
	category: '',
	productTitle: '',
	description: '',
	isDefault: false,
	metadata: [],
	priceOptions: [],
};

export function emptyPriceOption(
	currency: string,
	providerSettings: models.providerSettings.ProviderSettings
): PriceOptionData {
	const recurringInterval =
		models.providerSettings.getIntervals(providerSettings)[0];

	const priceOption: PriceOptionData = {
		priceTitle: '',
		cost: 0,
		currency,
		description: '',
		taxRateId: null,
		taxRate: {} as TaxRate,
		recurring: true,
		recurringIntervalCount: 0,
		recurringInterval,
	};

	return priceOption;
}

interface ProductContext {
	metadataKeys: string[];
	formState: ProductMixedState;
	setFormState: MixedStateSetter<ProductMixedState>;

	taxRates: models.taxRate.TaxRate[];
	providerSettings: models.providerSettings.ProviderSettings;
}

export const AddProductContext = createContext<ProductContext>({
	formState: initialState,
	taxRates: [],
	metadataKeys: [],
	providerSettings: {} as models.providerSettings.ProviderSettings,
	setFormState: () => {
		return;
	},
});

interface NewProductProps {
	organizationId: number;
}

const NewProduct = ({ organizationId }: NewProductProps): JSX.Element => {
	const qs = useQueryState();
	const categoryId = qs.get('categoryId');

	let category = '';

	if (categoryId !== null) {
		category = categoryId as string;
	}

	const [formState, setFormState] = useMixedState<ProductMixedState>({
		category,
		productTitle: '',
		description: '',
		isDefault: false,
		metadata: [],
		priceOptions: [],
	});

	const [saving, setSaving] = useState<boolean>(false);

	const {
		records: productCategoriesRecords,
		refresh: refreshProductCategories,
	} = useCollection<models.productCategory.ProductCategory>(
		endpoints.ProductCategory.List(),
		{
			queryParams: new URLSearchParams({
				group_id: organizationId.toString(),
			}),
		}
	);

	const { record: providerSettings, isLoading: isLoadingProviderSettings } =
		useEndpoint<models.providerSettings.ProviderSettings>(
			endpoints.Groups.PaymentProviderSettings(organizationId)
		);

	const { record: metadataKeys, isLoading: loadingProductMetadata } =
		useEndpoint<string[]>(endpoints.ProductMetadata.Index(), {
			queryParams: new URLSearchParams({
				group_id: organizationId.toString(),
			}),
		});

	const group = useCurrentGroup();

	const { records: allTaxRates } = useCollection<models.taxRate.TaxRate>(
		endpoints.TaxRates.Index1(group.id),
		{
			queryParams: new URLSearchParams({
				active: 'true',
			}),
		}
	);

	const taxRates = allTaxRates.filter((t) => t.active);

	useEffect(() => {
		if (!!group.currency && providerSettings?.availableRecurringOptions) {
			setFormState({
				...formState,
				priceOptions: [emptyPriceOption(group.currency, providerSettings)],
			});
		}
	}, [group.currency, providerSettings?.availableRecurringOptions]);

	const validProductInfo = !!formState.productTitle;

	const canSave =
		validatePriceOptions(formState.priceOptions).length ===
			formState.priceOptions.length &&
		validProductInfo &&
		!saving;

	const handleSave = async () => {
		setSaving(true);
		const productPayload: models.product.ProductCreatePayload = {
			name: formState.productTitle,
			groupId: organizationId,
			isDefault: formState.isDefault,
		};

		if (!!formState.category) {
			productPayload.productCategoryId = Number.parseInt(
				formState.category,
				10
			);
		}

		if (formState.description.length > 0) {
			productPayload.description = formState.description;
		}

		if (formState.metadata.length > 0) {
			productPayload.metadata = formState.metadata.map((m) => {
				return {
					key: m.key,
					value: m.value,
				};
			});
		}

		const [req, resp] = await models.create<
			models.product.ProductCreatePayload,
			models.product.Product
		>(endpoints.Products.Index(), productPayload);

		if (req.ok) {
			// Awaiting doesn't seem to work (Created deadlock)
			// Maybe create a bulk endpoint for creating multiple prices?
			await handlePriceSave(resp.id);

			pushState(routes.Product.Show(organizationId, resp.id, 'overview'));
		}
		setSaving(false);
	};

	const handlePriceSave = async (productId: number) => {
		for (const item of formState.priceOptions) {
			const payload: PricePayload = {
				productId,
				cost: decimalToCentsByCurrency(item.cost, item.currency),
				currency: item.currency.toLowerCase(),
				recurring: item.recurring,
				taxRateId: Number.parseInt(item.taxRateId, 10),
				title: item.priceTitle,
				description: '',
				recurringInterval: item.recurringInterval,
				recurringIntervalCount: item.recurringIntervalCount,
			};

			if (item.description.length > 0) {
				payload.description = item.description;
			}

			await actions.prices.create(
				payload,
				item.recurringOption,
				providerSettings
			);
		}
	};

	const isSmallScreen = useSmallScreen();

	const content = (
		<AddForm
			productCategories={productCategoriesRecords}
			refreshCategories={refreshProductCategories}
		/>
	);

	if (isLoadingProviderSettings || loadingProductMetadata) {
		return <Spinner />;
	}

	return (
		<AddProductContext.Provider
			value={{
				formState,
				setFormState,
				taxRates,
				providerSettings,
				metadataKeys,
			}}>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					href={routes.Products.Index(organizationId)}>
					{t('Cancel')}
				</Button>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					primary
					disabled={!canSave}
					isLoading={saving}
					onClick={handleSave}>
					{t('Save')}
				</Button>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD} spacious>
					{content}
					<FormActionFooter>
						<Button href={routes.Products.Index(organizationId)}>
							{t('Cancel')}
						</Button>
						<Button
							primary
							disabled={!canSave}
							testid="product.create_form.save"
							onClick={handleSave}
							isLoading={saving}>
							{t('Save')}
						</Button>
					</FormActionFooter>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
		</AddProductContext.Provider>
	);
};

export default NewProduct;
