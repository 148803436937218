import { ReactNode } from 'react';

import * as css from './styles.css';

interface TableInfoProps {
	children: ReactNode;
}

export default function TableInfo({ children }: TableInfoProps) {
	return <div className={css.wrapper}>{children}</div>;
}
