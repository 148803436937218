import { t } from '@transifex/native';
import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';
import Link from 'pkg/router/Link';

import Base, { Body } from 'components/Card';
import Heatmap from 'components/HeatMap';

import AvatarLink from 'components/user/AvatarLink';

const ListItemContainer = styled(Body)`
	display: grid;
	grid-column-gap: 13px;
	justify-items: start;
	align-items: center;
	padding: 13px 1rem 13px 1rem;

	@media ${breakpoints.small} {
		border-radius: 0;
		padding: 13px 1rem 13px 1rem;
	}
`;

export const UserActionListItemContainer = styled(ListItemContainer)`
	grid-template-areas: 'avatar user actions';
	grid-template-columns: 40px auto 35px;

	${(props) =>
		props.showHeatmap &&
		css`
			grid-template-areas: 'avatar heat-map user actions';
			grid-template-columns: 40px 48px auto 35px;

			@media ${breakpoints.small} {
				grid-template-areas:
					'avatar user actions'
					'avatar heat-map actions';
				grid-template-columns: 40px auto 35px;
			}
		`};
`;

export const ItemAvatar = styled.div`
	grid-area: avatar;
`;

export const ItemUser = styled.div`
	grid-area: user;
	align-content: start;
	display: block;
	width: 100%;
	min-width: 0;

	${(props) =>
		!props.to &&
		css`
			cursor: default;
		`};
`;

export const ItemActions = styled.div`
	width: 100%;
	grid-area: actions;
	justify-content: flex-end;
	align-items: center;
	display: flex;

	@media ${breakpoints.small} {
		justify-content: flex-start;
	}
`;

export const HeatmapWrapper = styled.div`
	grid-area: heat-map;
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
	align-items: start;
	width: 100%;
	height: 100%;

	@media ${breakpoints.small} {
		grid-template-columns: 20px 30px;
		padding-top: var(--spacing-3);
		padding-bottom: var(--spacing-4);
	}
`;

const HeatMapItem = styled.div`
	width: 20px;
	height: 100%;
	max-height: 28px;
	display: inline-block;
	position: relative;
`;

const HeatmapLabel = styled.div`
	font-size: 0.5rem;
	font-weight: var(--font-weight-normal);
	color: ${palette.black};
	line-height: 1;
	margin-bottom: 2px;
	text-align: center;
`;

const HeatMapPlaceholderWrapper = styled.div`
	max-height: 28px;
`;

const HeatmapPlaceholder = styled.div`
	width: 20px;
	height: 100%;
	height: 28px;
	display: block;
	background: ${palette.gray[300]};
`;

const UserName = styled.div`
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-base);
	color: ${rgba(palette.main.foregroundColor)};

	${(props) =>
		!props.missing &&
		css`
			@media (hover: hover) {
				&:hover {
					color: ${rgba(palette.main.foregroundColor, 0.7)};
				}
			}
		`};
`;

export const UserLabel = styled.span`
	font-size: var(--font-size-sm);
	display: block;
	color: ${({ missing }) => (missing ? palette.red[400] : palette.gray[500])};
`;

export const UserLabelNeedsAction = styled.span`
	color: ${rgba(palette.checklist.incompleteStatusColor)};
	font-weight: var(--font-weight-normal);

	&:after {
		margin: 0 5px;
		content: '•';
		color: ${rgba(palette.icon.idleFillColor)};
		display: inline-block;
	}
`;

export class UserActionListItem extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		needsActionLabel: PropTypes.string,
		primaryRating: PropTypes.object.isRequired,
	};

	get userAvatar() {
		return <AvatarLink noLink size="40px" user={this.props.user} />;
	}

	get userLink() {
		const { firstName, lastName } = this.props.user;

		return (
			<UserName missing={this.props.missing} href="#">
				{firstName} {lastName}
			</UserName>
		);
	}

	get userLabel() {
		if (this.props.userLabel) {
			return (
				<UserLabel missing={this.props.missing}>
					{this.props.needsActionLabel && (
						<UserLabelNeedsAction>
							{this.props.needsActionLabel}
						</UserLabelNeedsAction>
					)}
					{this.props.userLabel}
				</UserLabel>
			);
		}

		return null;
	}

	heatmapPlaceholder = (label) => {
		return (
			<HeatMapPlaceholderWrapper>
				<HeatmapLabel>{label}</HeatmapLabel>
				<HeatmapPlaceholder />
			</HeatMapPlaceholderWrapper>
		);
	};

	get primaryHeatmap() {
		const label = t(`C`);
		let heatmap = this.heatmapPlaceholder(label);
		if (this.props.primaryRating?.positionScores) {
			heatmap = (
				<HeatMapItem>
					<HeatmapLabel>{label}</HeatmapLabel>
					<Heatmap
						key={`HeatMap-${this.props.primaryRating.id}`}
						showScores={false}
						rating={this.props.primaryRating.positionScores}
						small={true}
					/>
				</HeatMapItem>
			);
		}

		return heatmap;
	}

	get selfRating() {
		const label = t(`P`);
		let heatmap = this.heatmapPlaceholder(label);
		if (this.props.lastSelfRating?.positionScores) {
			heatmap = (
				<HeatMapItem>
					<HeatmapLabel>{label}</HeatmapLabel>
					<Heatmap
						key={`HeatMap-${this.props.lastSelfRating?.id}`}
						showScores={false}
						rating={this.props.lastSelfRating?.positionScores}
						small={true}
					/>
				</HeatMapItem>
			);
		}
		return heatmap;
	}

	get heatMapItem() {
		return (
			<HeatmapWrapper>
				{this.primaryHeatmap}
				{this.selfRating}
			</HeatmapWrapper>
		);
	}

	get item() {
		const { to, params } = this.props;

		return (
			<Fragment>
				<ItemAvatar as={Link} to={to} params={params}>
					{this.userAvatar}
				</ItemAvatar>
				<ItemUser as={Link} to={to} params={params}>
					{this.userLink}
					{this.userLabel}
				</ItemUser>
				{this.props.showHeatmap && this.heatMapItem}

				<ItemActions>{this.props.children}</ItemActions>
			</Fragment>
		);
	}

	get renderNormalAction() {
		return (
			<UserActionListItemContainer showHeatmap={this.props.showHeatmap}>
				{this.item}
			</UserActionListItemContainer>
		);
	}

	render() {
		return <Base $noBorder>{this.renderNormalAction}</Base>;
	}
}
