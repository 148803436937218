// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F2ZmKBf8w3uKUQgZR2Nl {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-2);
}
`, "",{"version":3,"sources":["webpack://./routes/kitchen_sink/views/labels/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;AACtB","sourcesContent":[".labelWrapper {\n\tdisplay: flex;\n\tgap: var(--spacing-2);\n}\n"],"sourceRoot":""}]);
// Exports
export var labelWrapper = `F2ZmKBf8w3uKUQgZR2Nl`;
export default ___CSS_LOADER_EXPORT___;
