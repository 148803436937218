import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { FieldTypes } from 'pkg/api/models/form';

import CountrySelect from 'components/CountrySelect';

import { FormDataValue } from 'components/form/Form';
import * as Inputs from 'components/form/inputs';

interface Props {
	field: models.form.Field;

	account?: models.account.Account;
	user?: models.user.User;
}

const getChoiceInput = (
	field: models.form.Field,
	defaultValues: FormDataValue[]
) => {
	const type =
		field.type === models.form.FieldTypes.Choice ? 'radio' : 'checkbox';

	const havePreCheckedValues = defaultValues.length > 0;

	return field.values.map(({ label, value }) => {
		const checkedProp: { [key: string]: string | boolean } = {};

		// if defaultValues exist the user should not be able to change the selection
		if (havePreCheckedValues) {
			checkedProp['checked'] = defaultValues.includes(label);
		}

		const isRequired =
			(field.required && defaultValues.length < 1) ||
			(field.required && !value);

		return (
			<Inputs.Control
				key={value}
				name={field.key}
				required={isRequired}
				type={type}
				value={value}
				label={label}
				noRequiredText={true}
				{...checkedProp}
			/>
		);
	});
};

const getGenderInput = (
	field: models.form.Field,
	defaultValue: FormDataValue
) => {
	return field.values.map(({ value }) => {
		const checkedProp: { [key: string]: string | boolean } = {};

		if (defaultValue) {
			checkedProp['checked'] =
				Number.parseInt(value, 10) ===
				Number.parseInt(defaultValue as string, 10);
		}

		return (
			<Inputs.Control
				key={value}
				name={field.key}
				type="radio"
				value={value}
				label={models.user.translatedSexString(Number.parseInt(value, 10))}
				{...checkedProp}
			/>
		);
	});
};

const getFieldInput = (
	field: models.form.Field,
	defaultValue: FormDataValue
) => {
	const inputDefaultProps: { [key: string]: FormDataValue } = {
		name: field.key,
		type: field.type,
		required: field.required,
		placeholder: placeHolders[field.type] || '',
	};

	if (defaultValue) {
		inputDefaultProps['value'] = defaultValue;
	}

	if (field.type === models.form.FieldTypes.Phone) {
		inputDefaultProps['pattern'] = '([0-9-+ ]+)';
	}

	if (field.type === models.form.FieldTypes.Email) {
		inputDefaultProps['pattern'] = '[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+';
	}
	if (field.type === models.form.FieldTypes.Country) {
		return <CountrySelect name={field.key} countryValue={true} />;
	}

	if (field.type === models.form.FieldTypes.LongText) {
		return <Inputs.Area {...inputDefaultProps} minRows="2" />;
	}

	return <Inputs.Field {...inputDefaultProps} />;
};

const renderInput = (
	field: models.form.Field,
	defaultValues: FormDataValue[]
) => {
	// gender works diffrently to the other fields - therefore we will do some extra work to render it correctly
	if (field.key === 'user_sex') {
		return getGenderInput(field, defaultValues[0]);
	}

	switch (field.type) {
		case models.form.FieldTypes.Choice:
		case models.form.FieldTypes.MultiChoice:
			return getChoiceInput(field, defaultValues);
		default:
			return getFieldInput(field, defaultValues[0]);
	}
};

const placeHolders: { [key: string]: string } = {
	[models.form.FieldTypes.Email]: 'example@email.com',
	[models.form.FieldTypes.Phone]: '+4612345678',
};

const FormInputField = ({ field, user, account }: Props) => {
	let defaultValues: FormDataValue[] = [];
	const formContext = useFormContext();

	if (field.userFieldId) {
		defaultValues =
			user?.fields?.find((userField) => userField.key === field.key).values ||
			[];
	}

	// default keys (firstName, email etc) is named with the user_ prefix when added as a form field.
	if (field.key.includes('user_') && user) {
		defaultValues = [
			user[field.key.split('user_')[1] as keyof models.user.User],
		];
	} else if (field.key.includes('user_') && !user && account) {
		defaultValues = [
			account[field.key.split('user_')[1] as keyof models.account.Account],
		];
	} else if (field.key.includes('billing_') && user) {
		defaultValues = [
			user[field.key.split('billing_')[1] as keyof models.user.User],
		];
	}

	const isEditable =
		defaultValues[0] === '' ||
		(typeof defaultValues[0] === 'number' && defaultValues[0] === 0) ||
		(field.key == 'user_country' && !field.values);

	const multiSelectError = t('You must select at least one value');
	const singleSelectError = t('You must select a value');
	const defaultError = t('Please enter a value');

	const getErrorMessage = (field: models.form.Field): string => {
		if (formContext.formState?.errors[field.key]) {
			if (field.type === FieldTypes.MultiChoice) {
				return multiSelectError;
			} else if (field.type === FieldTypes.Choice) {
				return singleSelectError;
			} else {
				return defaultError;
			}
		}
		return null;
	};

	const errorMessage = getErrorMessage(field);
	return (
		<Inputs.Group
			key={field.key}
			label={field.label}
			required={field.required}
			errorMessage={errorMessage}
			description={field.description}
			readOnly={!isEditable && defaultValues.length > 0}>
			{renderInput(field, defaultValues)}
		</Inputs.Group>
	);
};

export default FormInputField;
