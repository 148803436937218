import { Fragment, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { useAccountIdentity } from 'pkg/identity/account';
import Router, { IndexRoute, Route } from 'pkg/router/Router';
import { replaceState } from 'pkg/router/state';

import NoMemberships from 'routes/public/no-memberships';

function getCurrentUrl() {
	let currentUrl = window.location.pathname;

	if (window.location.search.length > 0) {
		currentUrl += window.location.search;
	}

	return currentUrl;
}

interface NoOrganizationsGuardProps {
	children: ReactNode | ReactNode[];
}

/**
 * This component triggers its fallback if the authenticated account doesn't belong
 * to any organizations.
 */
export function NoOrganizationsGuard({
	children,
}: NoOrganizationsGuardProps): JSX.Element {
	const accountIdentity = useAccountIdentity();

	const redirectToHome = () => {
		replaceState('/');
	};

	if (
		accountIdentity.organizations?.length === 0 ||
		!accountIdentity.organizations ||
		accountIdentity.account.needsParentalConsent
	) {
		return (
			<Fragment>
				<ThemeProvider theme={{}}>
					<Router
						currentPath={getCurrentUrl()}
						key="no-memberships"
						onNotFound={redirectToHome}>
						<IndexRoute component={NoMemberships} name="no-memberships" />
						<Route
							path="join/:groupCode"
							name="join"
							component={NoMemberships}
						/>
						<Route
							path="claim/:inviteKey"
							name="claim"
							component={NoMemberships}
						/>
						<Route path=":view" name="view" component={NoMemberships} />
					</Router>
				</ThemeProvider>
			</Fragment>
		);
	}

	return <Fragment>{children}</Fragment>;
}
