// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nuKds9d5wVGqtYTJIYzA {
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	grid-template-columns: 80px 50px 80px;
	grid-gap: var(--spacing-6);
	gap: var(--spacing-6);
}

.b8PyCwhu9eQ7IFgVOmQq {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	background: var(--palette-blue-300);
}

.b8PyCwhu9eQ7IFgVOmQq svg {
		color: var(--palette-blue-500);
	}

.fFApkHXCGNyF4gS3Lzmw {
	width: 30px;
	height: 30px;
}

.WbtCX6bs2r_ZFDgB5F9Q {
	width: 80px;
	height: 80px;
}

.WbtCX6bs2r_ZFDgB5F9Q img {
		width: 100%;
	}
`, "",{"version":3,"sources":["webpack://./components/connection/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,qCAAqC;CACrC,0BAAqB;CAArB,qBAAqB;AACtB;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,mCAAmC;AAKpC;;AAHC;EACC,8BAA8B;CAC/B;;AAGD;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,WAAW;CACX,YAAY;AAKb;;AAHC;EACC,WAAW;CACZ","sourcesContent":[".wrapper {\n\tdisplay: grid;\n\talign-items: center;\n\tjustify-content: center;\n\tgrid-template-columns: 80px 50px 80px;\n\tgap: var(--spacing-6);\n}\n\n.iconWrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tborder-radius: 50%;\n\twidth: 50px;\n\theight: 50px;\n\tbackground: var(--palette-blue-300);\n\n\t& svg {\n\t\tcolor: var(--palette-blue-500);\n\t}\n}\n\n.icon {\n\twidth: 30px;\n\theight: 30px;\n}\n\n.item {\n\twidth: 80px;\n\theight: 80px;\n\n\t& img {\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `nuKds9d5wVGqtYTJIYzA`;
export var iconWrapper = `b8PyCwhu9eQ7IFgVOmQq`;
export var icon = `fFApkHXCGNyF4gS3Lzmw`;
export var item = `WbtCX6bs2r_ZFDgB5F9Q`;
export default ___CSS_LOADER_EXPORT___;
