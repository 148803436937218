import { t } from '@transifex/native';
import { useState } from 'react';

import * as models from 'pkg/api/models';
import { FilterModalProps } from 'pkg/filters/types/Modal';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { FilterOperator } from 'pkg/filters';
import { useCurrentGroup } from 'pkg/identity';

import Pagination from 'components/pagination';
import * as StepModal from 'components/step-modal';

import ProductsTable from 'components/products/table';
import * as ActionBar from 'components/layout/ActionBar';
import SearchInput from 'components/form/SearchInput';

export default function ProductsSelectModal({
	handleClose,
	handleNext,

	selected = [],
	handleSelectItem,
}: FilterModalProps) {
	const [search, setSearch] = useState('');
	const group = useCurrentGroup();

	const { records: products, pagination } =
		useCollection<models.product.Product>(endpoints.Products.Index(), {
			queryParams: new URLSearchParams({
				group_id: group.id.toString(),
				version: '2',
				filters: JSON.stringify([
					{
						property: 'name',
						operator: FilterOperator.Contains,
						values: [search],
					},
				]),
			}),
		});

	const handleSearch = (value: string) => setSearch(value);

	return (
		<StepModal.Base onClose={handleClose}>
			<StepModal.Step
				title={t('Select products')}
				nextLabel={t('Done')}
				onNext={handleNext}
				skipBody>
				<ActionBar.Bar>
					<ActionBar.PrimaryAction>
						<SearchInput
							onChange={handleSearch}
							placeholder={t('Search products...')}
						/>
					</ActionBar.PrimaryAction>
				</ActionBar.Bar>
				<ProductsTable
					products={products}
					selected={selected}
					select={handleSelectItem}
				/>
				<Pagination {...pagination} />
			</StepModal.Step>
		</StepModal.Base>
	);
}
