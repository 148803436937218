import { fillCount } from 'pkg/numbers';
import { tlog } from 'pkg/tlog';

const FALLBACK_TIMEZONE: string = 'UTC';

type TimeType = number;

type TimeObjectType = {
	hour: number;
	minute: number;
	second: number;
	milliSecond: number;
	meridiem?: string;
};

export enum Granularity {
	millisecond = 1,
	second = 1000,
	minute = 60000,
	hour = 3600000,
	day = 86400000,
	week = 604800000,
	month,
	year,
}

export enum Weekday {
	SUNDAY,
	MONDAY,
	TUESDAY,
	WEDNESDAY,
	THURSDAY,
	FRIDAY,
	SATURDAY,
}

type RoundDirectionType = 'up' | 'down';

type HourFormat = '12h' | '24h';

const selectedLocaleStore = window.sessionStorage;

const createTimestamp = (
	year: number,
	month: number,
	day: number,
	startOfDay: boolean
): TimeType => {
	const date = new Date();

	date.setFullYear(year);
	date.setMonth(month - 1, day);

	if (startOfDay) {
		date.setHours(0, 0, 0, 0);
	} else {
		date.setHours(23, 59, 59, 999);
	}

	return +date;
};

/**
 *	Date manipulation and presentation helper class.
 */
export default class DateTime {
	dateTime: Date;
	hourFormat: HourFormat;

	/**
	 *  Creates a new DateTime instance.
	 */
	constructor(date: Date = new Date()) {
		this.fromDate(date);
		this.hourFormat = DateTime.getHourFormat();
	}

	/**
	 *	Returns a new instance of DateTime set to current date.
	 */
	static now(): DateTime {
		return new DateTime(new Date());
	}

	/**
	 *	Sets timezone and locale based on current device resolved options.
	 */
	static resolveDateTimeOptions(): void {
		const { locale } = Intl.DateTimeFormat().resolvedOptions();

		DateTime.setLocale(locale);
	}

	/**
	 *
	 * @param timestamp UNIX timestamp in seconds
	 * @returns DateTime
	 */
	static fromTimestamp(timestamp: number): DateTime {
		return new DateTime(new Date(timestamp * 1000));
	}

	/**
	 *  Sets current date time object.
	 */
	fromDate(dateTime: Date): void {
		if (dateTime instanceof Date === false) {
			throw new Error('Must be instance of Date');
		}

		// @NOTE Make sure created date is a new date instance
		this.dateTime = new Date(+dateTime);
	}

	/**
	 *  Returns current date time object.
	 */
	toDate(): Date {
		return this.dateTime;
	}

	/**
	 *	Merges date (year, month and date), to merge time use {@see mergeTime} or to change full date use {@see setDate}.
	 */
	mergeDate(date: Date): void {
		const y: number = date.getFullYear();
		const m: number = date.getMonth() + 1;
		const d: number = date.getDate();

		this.setYear(y);
		this.setMonth(m);
		this.setDay(d);
	}

	/**
	 *  Sets current time zone.
	 */
	static setTimeZone(timeZone: string): void {
		selectedLocaleStore.setItem('__dateTime:timeZone', timeZone);
	}

	/**
	 *  Returns current time zone.
	 */
	static getTimeZone(): string {
		const timeZone = selectedLocaleStore.getItem('__dateTime:timeZone');
		if (!timeZone) {
			tlog.error('failed to read timezone from local storage');
			return FALLBACK_TIMEZONE;
		}

		return String(timeZone);
	}

	/**
	 *  Sets current locale.
	 */
	static setLocale(locale: string): void {
		selectedLocaleStore.setItem('__dateTime:locale', locale.replace('_', '-'));
	}

	/**
	 *  Returns current locale.
	 */
	static getLocale(): string {
		let locale = selectedLocaleStore.getItem('__dateTime:locale');

		if (!locale) {
			DateTime.resolveDateTimeOptions();
			locale = selectedLocaleStore.getItem('__dateTime:locale');
		}

		return String(locale);
	}

	/**
	 *  Sets hour format.
	 */
	static setHourFormat(format: HourFormat): void {
		selectedLocaleStore.setItem('__dateTime:hourFormat', format);
	}

	/**
	 *  Returns current locale.
	 */
	static getHourFormat(): HourFormat {
		let hourFormat = selectedLocaleStore.getItem('__dateTime:hourFormat');

		if (!hourFormat) {
			DateTime.setHourFormat('24h');
			hourFormat = selectedLocaleStore.getItem('__dateTime:hourFormat');
		}

		if (String(hourFormat) === '12h') {
			return '12h';
		}

		return '24h';
	}

	/**
	 */
	static isLeapYear(year: number): boolean {
		return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
	}

	/**
	 *  Returns number of days in current year
	 */
	static daysInYear(year: number): number {
		return DateTime.isLeapYear(year) ? 366 : 365;
	}

	/**
	 *  Returns the number of days in input month.
	 */
	static getDaysInMonth(year: number, month: number): number {
		const daysInMonths = [
			31,
			DateTime.isLeapYear(year) ? 29 : 28,
			31,
			30,
			31,
			30,
			31,
			31,
			30,
			31,
			30,
			31,
		];

		let monthOffset = month - 1;
		if (monthOffset > 11) monthOffset = 11;
		if (monthOffset < 0) monthOffset = 0;

		return daysInMonths[monthOffset];
	}

	get daysInMonth(): number {
		return DateTime.getDaysInMonth(this.getYear(), this.getMonth());
	}

	static isFirstQuarter(month: number): boolean {
		return month >= 1 && month <= 3;
	}

	static isSecondQuarter(month: number): boolean {
		return month >= 4 && month <= 6;
	}

	static isThirdQuarter(month: number): boolean {
		return month >= 7 && month <= 9;
	}

	static isFourthQuarter(month: number): boolean {
		return month >= 10 && month <= 12;
	}

	get startOfDay(): TimeType {
		return createTimestamp(
			this.getYear(),
			this.getMonth(),
			this.getDay(),
			true
		);
	}

	get endOfDay(): TimeType {
		return createTimestamp(
			this.getYear(),
			this.getMonth(),
			this.getDay(),
			false
		);
	}

	get startOfMonth(): TimeType {
		return createTimestamp(this.getYear(), this.getMonth(), 1, true);
	}

	get endOfMonth(): TimeType {
		return createTimestamp(
			this.getYear(),
			this.getMonth(),
			this.daysInMonth,
			false
		);
	}

	static getQuarterMonthRange(month: number): [number, number] {
		let startOfQuarterMonth: number = 1;
		let endOfQuarterMonth: number = 3;

		if (DateTime.isSecondQuarter(month)) {
			startOfQuarterMonth = 4;
			endOfQuarterMonth = 6;
		} else if (DateTime.isThirdQuarter(month)) {
			startOfQuarterMonth = 7;
			endOfQuarterMonth = 9;
		} else if (DateTime.isFourthQuarter(month)) {
			startOfQuarterMonth = 10;
			endOfQuarterMonth = 12;
		}

		return [startOfQuarterMonth, endOfQuarterMonth];
	}

	get startOfQuarter(): TimeType {
		const [firstMonthOfQuarter] = DateTime.getQuarterMonthRange(
			this.getMonth()
		);

		return createTimestamp(this.getYear(), firstMonthOfQuarter, 1, true);
	}

	get endOfQuarter(): TimeType {
		const [, lastMonthOfQuarter] = DateTime.getQuarterMonthRange(
			this.getMonth()
		);

		return createTimestamp(
			this.getYear(),
			lastMonthOfQuarter,
			this.daysInMonth,
			false
		);
	}

	get startOfYear(): TimeType {
		return createTimestamp(this.getYear(), 1, 1, true);
	}

	get endOfYear(): TimeType {
		return createTimestamp(this.getYear(), 12, 31, false);
	}

	/**
	 *  Returns the duration of a granularity of time, such as seconds, minutes up to weeks.
	 */
	static durationOf(value: number, granularity: Granularity): number | null {
		return granularity * Math.abs(value);
	}

	/**
	 *	Rounds date to nearest granularity.
	 */
	toNearestGranularity(
		value: number,
		granularity: Granularity,
		roundDirection: RoundDirectionType = 'up'
	): DateTime {
		const duration = DateTime.durationOf(value, granularity);

		if (!duration) {
			throw new Error('Not a valid time granularity.');
		}

		const timestamp = +this.dateTime;
		const roundingMethod = roundDirection === 'up' ? 'ceil' : 'floor';
		const roundBy = Math[roundingMethod];

		let adjustedTimestamp = roundBy(timestamp / duration) * duration;

		if (adjustedTimestamp > this.endOfDay) {
			adjustedTimestamp = this.endOfDay;
		}

		this.dateTime.setTime(adjustedTimestamp);

		return this;
	}

	/**
	 *	Sets full year.
	 */
	setYear(fullYear: number): DateTime {
		this.dateTime.setFullYear(fullYear);

		return this;
	}

	/**
	 *	Returns full year.
	 */
	getYear(): number {
		return this.dateTime.getFullYear();
	}

	/**
	 *	Sets month, does not decrement or increment year(s) for current date. Use {@see prev} and {@see next} methods instead.
	 */
	setMonth(month: number): DateTime {
		if (month > 12) month = 12;
		if (month < 1) month = 1;

		if (this.dateTime.getMonth() !== month) {
			this.dateTime.setMonth(month - 1);
		}

		return this;
	}

	/**
	 *	Returns month number, to get month index use {@see getMonthOffset}.
	 */
	getMonth(): number {
		return this.dateTime.getMonth() + 1;
	}

	/**
	 *	Returns month offset, to get month number, use {@see getMonth}.
	 */
	getMonthOffset(): number {
		return this.dateTime.getMonth();
	}

	/**
	 *	Sets day for current month. Just like {@see setMonth} it does not adjust date if outside of current month bounds.
	 *	To increment or decrement date, use {@see prev} or {@see next} instead.
	 */
	setDay(day: number): DateTime {
		const { daysInMonth } = this;
		day = Math.ceil(Math.abs(day));

		if (day > daysInMonth) day = daysInMonth;
		if (day < 1) day = 1;

		if (this.dateTime.getDate() !== day) {
			this.dateTime.setDate(day);
		}

		return this;
	}

	/**
	 *	Returns current day (date).
	 */
	getDay(): number {
		return this.dateTime.getDate();
	}

	/**
	 * Sets weekday to the weekday relative to instance date.
	 */
	setWeekday(weekday: Weekday): DateTime {
		const now = DateTime.now().toDate();
		const day = now.getDay();
		const diff = now.getDate() - day + (day === 0 ? -6 : weekday);

		return new DateTime(new Date(now.setDate(diff)));
	}

	/**
	 *	Returns weekday number.
	 */
	getWeekday(): Weekday {
		return this.dateTime.getDay() as Weekday;
	}

	/**
	 * Validates if current weekday is expected weekday.
	 * @param expectedWeekday Weekday
	 * @returns
	 */
	weekdayIs(expectedWeekday: Weekday): boolean {
		return this.getWeekday() === expectedWeekday;
	}

	static localeWeekdayStrings(format: 'short' | 'long' = 'long'): string[] {
		const weekdays: DateTime[] = fillCount(7, true).map((weekday) =>
			DateTime.now().setWeekday(weekday)
		);

		return weekdays.map((dt: DateTime) => {
			if (format === 'short') {
				return dt.toWeekdayString();
			} else {
				return dt.toLongWeekdayString();
			}
		});
	}

	/**
	 *	Sets timestamp from UNIX epoch time.
	 */
	setTimestamp(timestamp: number): DateTime {
		this.dateTime.setTime(timestamp);

		return this;
	}

	/**
	 *	Returns timestamp.
	 */
	getTimestamp(): number {
		return +this.dateTime;
	}

	/**
	 *	Sets time to current DateTime instance.
	 */
	setTime(
		hour: number,
		minute: number = -1,
		second: number = -1,
		milliSecond: number = -1
	): DateTime {
		const dateTime = this.dateTime;

		if (hour > 23 || hour < 0) {
			hour = dateTime.getHours();
		}

		if (minute > 59 || minute < 0) {
			minute = dateTime.getMinutes();
		}

		if (second > 59 || second < 0) {
			second = dateTime.getSeconds();
		}

		if (milliSecond > 999 || milliSecond < 0) {
			milliSecond = dateTime.getMilliseconds();
		}

		dateTime.setHours(hour, minute, second, milliSecond);

		this.dateTime = dateTime;

		return this;
	}

	/**
	 *	Returns {@see TimeObjectType} for current timestamp.
	 */
	getTime(): TimeObjectType {
		const hour: number = this.dateTime.getHours();
		const minute: number = this.dateTime.getMinutes();
		const second: number = this.dateTime.getSeconds();
		const milliSecond: number = this.dateTime.getMilliseconds();

		const timeObject: TimeObjectType = {
			hour,
			minute,
			second,
			milliSecond,
		};

		return timeObject;
	}

	/**
	 *	Merges time, to merge date use {@see mergeDate} or to change full date use {@see setDate}.
	 */
	mergeTime(date: Date): void {
		const h: number = date.getHours();
		const m: number = date.getMinutes();
		const s: number = date.getSeconds();
		const ms: number = date.getMilliseconds();

		this.setTime(h, m, s, ms);
	}

	/**
	 *	Sets date from UNIX timestamp.
	 */
	setUnixTimestamp(unixTimestamp: number): DateTime {
		return this.setTimestamp(unixTimestamp * 1000);
	}

	/**
	 *	Returns UNIX timestamp.
	 */
	getUnixTimestamp(): number {
		return Math.ceil(this.getTimestamp() / 1000);
	}

	/**
	 *	Validates if input date is before instance date.
	 */
	isBefore(timestamp: TimeType): boolean {
		return this.getTimestamp() <= timestamp;
	}

	/**
	 *	Validates if input date is after instance date.
	 */
	isAfter(timestamp: TimeType): boolean {
		return this.getTimestamp() >= timestamp;
	}

	/**
	 *	Validates if instance date is between start and end timestamp.
	 */
	isBetween(startTimestamp: TimeType, endTimestamp: TimeType): boolean {
		return this.isAfter(startTimestamp) && this.isBefore(endTimestamp);
	}

	/**
	 *	Validates if input timestamp is within todays time bounds.
	 */
	static isToday(timestamp: TimeType): boolean {
		const { startOfDay, endOfDay } = new DateTime(new Date());

		return timestamp >= startOfDay && timestamp <= endOfDay;
	}

	/**
	 *	Validates if input timestamp is within bounds of instance date.
	 */
	sameDay(timestamp: TimeType): boolean {
		return timestamp >= this.startOfDay && timestamp <= this.endOfDay;
	}

	/**
	 *  Decrements nth granularity from date time.
	 */
	prev(granularity: Granularity, decrementValue: number = 1): DateTime {
		const clone = new DateTime(this.dateTime);

		if (granularity === Granularity.year) {
			const prevFullYear: number =
				clone.dateTime.getFullYear() - Math.abs(Math.ceil(decrementValue));

			clone.dateTime.setFullYear(prevFullYear);
		} else if (granularity === Granularity.month) {
			const prevMonths: number =
				clone.dateTime.getMonth() - Math.abs(Math.ceil(decrementValue));

			clone.dateTime.setMonth(prevMonths);
		} else {
			const timestamp: number = this.dateTime.getTime();
			const duration: number = DateTime.durationOf(decrementValue, granularity);

			clone.dateTime = new Date(Math.abs(timestamp - duration));
		}

		return clone;
	}

	/**
	 *  Increments nth granularity to date time.
	 */
	next(granularity: Granularity, incrementValue: number = 1): DateTime {
		const clone = new DateTime(this.dateTime);

		if (granularity === Granularity.year) {
			const nextFullYear =
				clone.dateTime.getFullYear() + Math.abs(Math.ceil(incrementValue));

			clone.dateTime.setFullYear(nextFullYear);
		} else if (granularity === Granularity.month) {
			const nextMonths =
				clone.dateTime.getMonth() + Math.abs(Math.ceil(incrementValue));

			clone.dateTime.setMonth(nextMonths);
		} else {
			const timestamp: number = this.dateTime.getTime();
			const duration: number = DateTime.durationOf(incrementValue, granularity);

			clone.dateTime = new Date(timestamp + duration);
		}

		return clone;
	}

	/**
	 *	Returns localized representation of date, assumes instance locale variable.
	 */
	toLocaleDateString(formatOptions: Intl.DateTimeFormatOptions = {}): string {
		formatOptions.hourCycle = this.hourFormat === '12h' ? 'h12' : 'h23';

		return this.dateTime.toLocaleDateString(
			DateTime.getLocale(),
			formatOptions
		);
	}

	/**
	 *	Returns localized representation of time, assumes instance locale variable.
	 */
	toLocaleTimeString(formatOptions: Intl.DateTimeFormatOptions = {}): string {
		if (!formatOptions.hourCycle) {
			formatOptions.hourCycle = this.hourFormat === '12h' ? 'h12' : 'h23';
		}

		return this.dateTime.toLocaleTimeString(
			DateTime.getLocale(),
			formatOptions
		);
	}

	/**
	 *	Returns localized calendar date.
	 */
	toCalendarDateString(): string {
		return this.toLocaleDateString({ year: 'numeric', month: 'long' });
	}

	/**
	 *	Returns localized date (short).
	 */
	toDateString(): string {
		return this.toLocaleDateString({
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		});
	}

	/**
	 *	Returns localized long date.
	 */
	toLongDateString(): string {
		return this.toLocaleDateString({
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}

	/**
	 *	Returns localized time (short).
	 */
	toTimeString(): string {
		return this.toLocaleTimeString({
			hour: '2-digit',
			minute: '2-digit',
		});
	}

	/**
	 *	Returns localized time (short).
	 */
	toLongTimeString(): string {
		return this.toLocaleTimeString({});
	}

	/**
	 *	Returns localized weekday name (short).
	 */
	toWeekdayString(): string {
		return this.toLocaleDateString({ weekday: 'short' });
	}

	/**
	 *	Returns localized long weekday name.
	 */
	toLongWeekdayString(): string {
		return this.toLocaleDateString({ weekday: 'long' });
	}

	/**
	 *	Returns current date as string.
	 */
	toString(): string {
		return this.dateTime.toString();
	}

	/**
	 *	Convert date to UTC.
	 */
	toUTC(): DateTime {
		const date = this.toDate();
		return new DateTime(
			new Date(
				Date.UTC(
					date.getUTCFullYear(),
					date.getUTCMonth(),
					date.getUTCDate(),
					date.getUTCHours(),
					date.getUTCMinutes()
				)
			)
		);
	}

	/**
	 * 	Returns date in '2023-03-21' format
	 */
	toISODateString(): string {
		return [this.getYear(), this.getMonth(), this.getDay()]
			.map((n) => n.toString().padStart(2, '0'))
			.join('-');
	}

	/**
	 * Returns date in '2023-03-21 10:00:00' format
	 */
	toDateTimeString(): string {
		return `${this.toISODateString()} ${this.toLocaleTimeString({
			hourCycle: 'h23',
		})}`;
	}

	static isValidISODateString(isoDateString: string): boolean {
		const [y, m, d]: number[] = isoDateString
			.split('-')
			.map((part: string) => Number.parseInt(part, 10));

		if (m >= 1 && m <= 12) {
			const dim = DateTime.getDaysInMonth(y, m);

			if (d >= 1 && d <= dim) {
				return true;
			}
		}

		return false;
	}

	/**
	 * 	Returns how many days there's between current and passed DateTime
	 */
	getDaysBetween(endDateTime: DateTime): number {
		const difference = endDateTime.endOfDay - this.startOfDay;
		return Math.ceil(difference / (1000 * 3600 * 24));
	}
}

export type CalendarDayType = {
	isToday: boolean;
	isCurrentMonth: boolean;
	timestamp: number;
	day: number;
};

export type CalendarMonthType = CalendarDayType[];

export class Calendar {
	/**
	 *	@static number weekStartsAtIndex
	 */
	static weekStartsAtIndex: number = 1;

	/**
	 *	Generates a multi dimensional array representing a calendar month.
	 */
	static generate(year: number, month: number): CalendarMonthType {
		const date = new Date(year, month - 1, 1, 12, 0, 0, 0);

		const calendar: CalendarMonthType = [];
		const daysInMonth: number = DateTime.getDaysInMonth(year, month);
		const startOfMonthWeekday: number = date.getDay();
		const daysInPreviousMonth: number =
			(7 + startOfMonthWeekday - Calendar.weekStartsAtIndex) % 7;
		const calendarWeeksInMonth: number = Math.ceil(
			(daysInMonth + daysInPreviousMonth) / 7
		);
		let currentDay: number = 1 - daysInPreviousMonth;

		for (let week: number = 0; week < calendarWeeksInMonth; week++) {
			let dateIndex;

			for (let weekDay: number = 0; weekDay < 7; weekDay++) {
				dateIndex = weekDay + currentDay;

				const calendarDate: Date = new Date(
					year,
					month - 1,
					dateIndex,
					12,
					0,
					0,
					0
				);

				const calendarDay: CalendarDayType = {
					isToday: DateTime.isToday(+calendarDate),
					isCurrentMonth: calendarDate.getMonth() === month - 1,
					timestamp: +calendarDate,
					day: calendarDate.getDate(),
				};

				calendar.push(calendarDay);
			}

			currentDay += 7;
		}

		return calendar;
	}
}
