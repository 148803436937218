import { ReactNode, useEffect, useState } from 'react';

import * as ls from 'pkg/local_storage';
import {
	OrganizationsLocalStorageData,
	useAccountIdentity,
} from 'pkg/identity/account';
import * as sdk from 'pkg/core/sdk';

interface ApiTokenProps {
	children: ReactNode;
}

export default function ApiToken({ children }: ApiTokenProps) {
	const { organization } = useAccountIdentity();
	const [currentOrganizationId, setCurrentOrganizationId] = useState(0);

	useEffect(() => {
		const authToken = ls.getItem(ls.LocalStorageKeys.AuthToken);

		if (!organization) {
			sdk.setToken(authToken);
			return;
		}

		const lsOrganizations =
			ls.getJsonItem<OrganizationsLocalStorageData>(
				ls.LocalStorageKeys.Organizations
			) || {};
		const org = lsOrganizations[organization.id];

		if (org?.orgToken) {
			sdk.setToken(org.orgToken);
		} else {
			sdk.setToken(authToken);
		}

		setCurrentOrganizationId(organization.id);
	}, [organization?.id]);

	if (organization?.id !== currentOrganizationId) {
		return null;
	}

	return children;
}
