import { ChangeEvent, Fragment, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import * as breakpoints from 'pkg/config/breakpoints';

import DateTime, { Granularity } from 'pkg/datetime';
import { getGmtStrings, validateTimeZone } from 'pkg/timezone';
import { useGroup } from 'pkg/hooks/selectors';
import * as models from 'pkg/api/models';
import { EventFlags, RSVPInterval } from 'pkg/api/models/event';

import RecurringSettings from 'routes/event/components/RecurringSettings';
import UserList from 'routes/event/components/UserList';
import { IEditSeriesFormProps } from 'routes/event/interfaces';
import { DateSelectWrapper, TimeZoneText } from 'routes/event/components/Form';
import MeetingTime from 'routes/event/components/meeting-time';
import RSVPInputs from 'routes/event/components/rsvp/form-inputs';
import AutoInvite from 'routes/event/components/auto-invite';

import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import { useUserSelectModal } from 'components/user-select-modal';

import * as Input from 'components/form/inputs';
import Section, { Title } from 'components/form/Section';
import Column from 'components/layout/column';
import { ColumnDivider } from 'components/form/Divider';
import { UseAttachmentsResults } from 'components/attachment/hooks/useAttachments';

import Button from 'design/button';
import Divider from 'design/divider';

interface FormProps extends IEditSeriesFormProps {
	useAttachments: UseAttachmentsResults;
	eventSeries: models.eventSeries.EventSeries;
}

const Participants = styled.div`
	@media ${breakpoints.small} {
		margin-top: var(--spacing-6);
	}
`;

const EditForm = ({
	formState,
	setFormState,
	useAttachments,
	eventSeries,
}: FormProps): JSX.Element => {
	const [showTimeZone, setShowTimeZone] = useState(false);
	const startsAtDate = new Date(formState.startsAt * 1000);
	const startsAtDateTime = new DateTime(startsAtDate);
	const timeZoneStrings = useMemo(() => getGmtStrings(), []);
	const timeZoneRef = useRef(null);

	const group = useGroup(eventSeries.groupId);

	const { selectedUsers } = formState;

	const ignoredUserIds = selectedUsers.map((user: models.user.User) => user.id);

	const organizerUserIds = Object.keys(formState.organizer).map((id) =>
		Number.parseInt(id, 10)
	);

	const organizers = selectedUsers.filter((user) =>
		organizerUserIds.includes(user.id)
	);

	const participants = selectedUsers.filter(
		(user) => !organizerUserIds.includes(user.id)
	);

	const numOrganizers = Object.keys(formState.organizer).length;
	const numParticipants = selectedUsers.length - numOrganizers;

	const setSelectedOrganizers = (
		users: models.user.User[],
		mergePrevious: boolean = true
	) => {
		const next = mergePrevious ? [...selectedUsers, ...users] : users;
		const nextOrganizerUserIds = users.map((user) => user.id);

		const organizerIds: number[] = [
			...new Set([
				...nextOrganizerUserIds,
				...Object.keys(formState.organizer).map((id) =>
					Number.parseInt(id, 10)
				),
			]),
		];

		const organizer = organizerIds.reduce(
			(memo, userId) => ({
				...memo,
				[userId]: true,
			}),
			{}
		);

		setFormState({
			selectedUsers: next,
			organizer,
		});
	};

	const removeOrganizer = (userId: number) => {
		const next = selectedUsers.filter((user) => user.id !== userId);
		const nextOrganizer = { ...formState.organizer };
		delete nextOrganizer[userId];

		setFormState({
			selectedUsers: next,
			organizer: nextOrganizer,
		});
	};

	const setSelectedParticipants = (
		users: models.user.User[],
		mergePrevious: boolean = true
	) => {
		const next = mergePrevious ? [...selectedUsers, ...users] : users;

		setFormState({
			selectedUsers: next,
		});
	};

	const removeParticipant = (userId: number) => {
		const next = selectedUsers.filter((user) => user.id !== userId);

		setFormState({
			selectedUsers: next,
		});
	};

	const organizersSelect = useUserSelectModal({
		title: t('Add organizers'),
		saveLabel: t('Add organizers'),
		ignoredUserIds,
		defaultActiveTabId: 'admin',
		onSave: (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedOrganizers(users);
			}
		},
	});

	const participantsSelect = useUserSelectModal({
		title: t('Add participants'),
		saveLabel: t('Add participants'),
		ignoredUserIds,
		defaultActiveTabId: 'user',
		onSave: (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedParticipants(users);
			}
		},
	});

	const makeOrganizer = (userId: number) => {
		const nextOrganizer = {
			...formState.organizer,
			[userId]: true,
		};

		setFormState({
			organizer: nextOrganizer,
		});
	};

	const makeParticipant = (userId: number) => {
		const nextOrganizer = { ...formState.organizer };
		delete nextOrganizer[userId];

		setFormState({
			organizer: nextOrganizer,
		});
	};

	const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setFormState({ [name]: value });
	};

	const handleStartTimeChange = (date: Date) => {
		const startsAt = new DateTime(date).getUnixTimestamp();
		const eventDuration = formState.endsAt - formState.startsAt;

		setFormState({
			startsAt,
			endsAt: Math.ceil(startsAt + eventDuration),
		});
	};

	const handleStartDateChange = (date: Date) => {
		const startsAt = new DateTime(date).getUnixTimestamp();
		const eventDuration = formState.endsAt - formState.startsAt;

		setFormState({
			startsAt,
			endsAt: Math.ceil(startsAt + eventDuration),
		});
	};

	const handleEndTimeChange = (date: Date) => {
		const endsAt = new DateTime(date).getUnixTimestamp();

		setFormState({
			endsAt,
		});
	};

	const handleEndTimeBlur = () => {
		if (formState.endsAt < formState.startsAt) {
			setFormState({
				endsAt: new DateTime(new Date(formState.startsAt * 1000))
					.next(Granularity.hour, 1)
					.getUnixTimestamp(),
			});
		}
	};

	const handleEndDateChange = (date: Date) =>
		setFormState({
			endsAt: new DateTime(date).getUnixTimestamp(),
		});

	const handleShowTimeZone = async () => {
		await setShowTimeZone(true);
		timeZoneRef.current.select();
	};

	const handleTimeZoneClick = () => timeZoneRef.current.select();

	const handleTimeZoneChange = (timeZone: string) =>
		setFormState({
			timezone: timeZone,
		});

	const handleTimeZoneBlur = (timeZone: string) => {
		if (timeZone.length > 0 && !validateTimeZone(timeZone)) {
			setFormState({
				timezoneError: true,
			});

			return;
		}

		setShowTimeZone(false);
	};

	const setRuleString = (rRuleString: string) => {
		setFormState({
			rRuleString,
		});
	};

	const setOnDay = (onDay: boolean) =>
		setFormState({
			onDay,
		});

	const handleRSVPChange = () =>
		setFormState({ rsvpBefore: !formState.rsvpBefore });

	const handleIntervalChange = (e: ChangeEvent<HTMLSelectElement>) =>
		setFormState({ rsvpInterval: e.target.value as RSVPInterval });

	const handleIntervalCountChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFormState({
			[e.target.name]: Number.parseInt(e.target.value, 10),
		});
	};

	const handleAutoInviteChange = (name: EventFlags) => {
		const flags = formState.flags;

		if (flags.includes(name)) {
			setFormState({ flags: flags.filter((f) => f !== name) });
		} else {
			setFormState({
				flags: [...formState.flags, name],
			});
		}
	};

	return (
		<Fragment>
			{organizersSelect.Component}
			{participantsSelect.Component}
			<div>
				<LargeScreen>
					<Title>{t(`Event information`)}</Title>
				</LargeScreen>
				<Column spacing={styles.spacing._7}>
					<Input.Group required label={t(`Event title`)}>
						<Input.Field
							name="title"
							defaultValue={formState.title}
							placeholder={t(`Event title`)}
							onChange={handleFormChange}
							changeDelay={100}
							required
						/>
					</Input.Group>
					<Input.Group required label={t(`Location`)}>
						<Input.Field
							name="location"
							placeholder={t(`Location`)}
							defaultValue={formState.location}
							onChange={handleFormChange}
							autoComplete="off"
							changeDelay={100}
							required>
							<Input.Prefix>
								<Icon name="location" />
							</Input.Prefix>
						</Input.Field>
					</Input.Group>
					<Column spacing={styles.spacing._3}>
						<DateSelectWrapper>
							<Input.Group label={t(`Start time`)}>
								<div>
									<Input.DateTimePicker
										date={startsAtDate}
										onTimeChange={handleStartTimeChange}
										onDateChange={handleStartDateChange}
									/>
								</div>
							</Input.Group>
							<Icon name="arrow-right" />
							<Input.Group label={t(`End time`)}>
								<div>
									<Input.DateTimePicker
										date={new Date(formState.endsAt * 1000)}
										onTimeChange={handleEndTimeChange}
										onTimeBlur={handleEndTimeBlur}
										onDateChange={handleEndDateChange}
										disableDatesBefore={startsAtDate}
									/>
								</div>
							</Input.Group>
						</DateSelectWrapper>
						{!showTimeZone && (
							<TimeZoneText onClick={handleShowTimeZone}>
								{formState.timezone || t(`Select time zone`)}
							</TimeZoneText>
						)}
					</Column>

					{showTimeZone && (
						<Input.Group
							label={t(`Time zone`)}
							errorMessage={
								formState.timezoneError && t(`Incorrect time zone`)
							}>
							<Input.SearchableList
								items={timeZoneStrings}
								value={formState.timezone}
								onChange={handleTimeZoneChange}
								onBlur={handleTimeZoneBlur}
								onClick={handleTimeZoneClick}
								ref={timeZoneRef}
							/>
						</Input.Group>
					)}
					<MeetingTime
						starts={startsAtDateTime}
						meetBeforeMinutes={formState.meetBeforeMinutes}
						handleFormChange={handleFormChange}
					/>
					<RSVPInputs
						rsvpBefore={formState.rsvpBefore}
						interval={formState.rsvpInterval}
						intervalCount={formState.rsvpIntervalCount}
						starts={formState.startsAt}
						handleIntervalChange={handleIntervalChange}
						handleIntervalCountChange={handleIntervalCountChange}
						handleRSVPChange={handleRSVPChange}
					/>
					<RecurringSettings
						startsAt={formState.startsAt}
						rruleString={formState.rRuleString}
						onDay={formState.onDay}
						setOnDay={setOnDay}
						setRuleString={setRuleString}
					/>

					<AutoInvite
						flags={formState.flags}
						handleChange={handleAutoInviteChange}
					/>

					<Input.Group optional label={t('Description')}>
						<Input.Area
							name="description"
							defaultValue={formState.description}
							onChange={handleFormChange}
							minRows={3}
							changeDelay={100}
						/>
					</Input.Group>
					<Column>
						<div>{useAttachments.FileUploader}</div>
						{useAttachments.AttachmentItems}
					</Column>
					<Section title={t(`Visibility`)} hideDivider>
						<Column>
							<Input.Group>
								<Input.Control
									type="radio"
									label={t(`Everyone in {group}`, {
										group: group.name,
									})}
									description={t(
										`Event is only visible to everyone in the group.`
									)}
									checked={!formState.isPrivate}
									onChange={() => {
										setFormState({ isPrivate: false });
									}}
								/>
								<Input.Control
									type="radio"
									label={t(`Only participants`)}
									description={t(
										`Event is only visible to participants and organizers.`
									)}
									checked={formState.isPrivate}
									onChange={() => {
										setFormState({ isPrivate: true });
									}}
								/>
							</Input.Group>
							<Divider />
							<Input.Group>
								<Input.Control
									type="checkbox"
									label={t(`Public`)}
									description={t(
										`If {group} is displayed on your organization's website the event will be shown there after publishing.`,
										{
											group: group.name,
										}
									)}
									checked={formState.isPublic}
									onChange={() => {
										setFormState({ isPublic: !formState.isPublic });
									}}
								/>
							</Input.Group>
						</Column>
					</Section>
				</Column>
			</div>
			<LargeScreen>
				<ColumnDivider />
			</LargeScreen>
			<Participants>
				<Column spacing={styles.spacing._8}>
					<Section
						title={t('Organizers ({num})', {
							num: numOrganizers,
						})}
						hideDivider>
						<Column spacing={styles.spacing._6}>
							{numOrganizers > 0 && (
								<UserList
									isEditing
									selectedUsers={organizers}
									onRemove={removeOrganizer}
									onMakeParticipant={makeParticipant}
									eventUserId={eventSeries.userId}
								/>
							)}

							<Column spacing={styles.spacing._3}>
								<Button
									label={t('Add organizers')}
									secondary
									icon="add"
									onClick={organizersSelect.open}
									testid="events.invite.add_organizers"
								/>
							</Column>
						</Column>
					</Section>

					<Section
						title={t('Participants ({num})', {
							num: numParticipants,
						})}
						hideDivider>
						<Column spacing={styles.spacing._6}>
							{numParticipants > 0 && (
								<UserList
									isEditing
									eventUserId={eventSeries.userId}
									selectedUsers={participants}
									onRemove={removeParticipant}
									onMakeOrganizer={makeOrganizer}
								/>
							)}

							<Column spacing={styles.spacing._3}>
								<Button
									label={t('Add participants')}
									secondary
									icon="add"
									onClick={participantsSelect.open}
									testid="events.invite.add_participants"
								/>
							</Column>
						</Column>
					</Section>
				</Column>
			</Participants>
		</Fragment>
	);
};

export default EditForm;
