import styled, { css } from 'styled-components';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import { Group } from 'pkg/api/models/onboarding_info';
import { useCurrentAccount } from 'pkg/identity';

import ThreeSixtyLogo from 'routes/public/styles/logo';
import TeamBadge from 'routes/public/styles/TeamBadge';
import { useOnboardingState } from 'routes/public/hooks';
import AccountDeleteModal from 'routes/account/settings/privacy/AccountDeleteModal';
import { useOnboardingContext } from 'routes/public/onboarding';

import Icon from 'components/icon';

import * as Context from 'design/context_menu';

const SettingsWrapper = styled.div`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
`;

function Settings(): JSX.Element {
	const dispatch = useDispatch();

	const { resetStore } = useOnboardingContext();

	const [showModal, setShowModal] = useState(false);
	const account = useCurrentAccount();

	const accountUnderDeletion = account.willBeDeletedAt;

	const handleToggleModal = () => {
		setShowModal(!showModal);
	};

	const handleLogout = () => {
		resetStore();

		dispatch(actions.auth.logout());

		window.history.pushState({}, '', '/');
		window.location.reload();
	};

	return (
		<Fragment>
			<SettingsWrapper>
				<Context.Menu
					toggleWith={
						<Icon
							name="nav-settings"
							fill={styles.palette.white}
							size={2}
							data-testid="no_memberships.settings_button"
						/>
					}>
					<Context.Item onClick={handleLogout} testid="no_memberships.logout">
						<Context.ItemIcon name="logout" />
						{t('Log out')}
					</Context.Item>
					{!accountUnderDeletion && (
						<Fragment>
							<Context.Divider />
							<Context.Item
								onClick={handleToggleModal}
								caution
								testid="no_memberships.delete_account">
								<Context.ItemIcon name="close" />
								{t('Delete account')}
							</Context.Item>
						</Fragment>
					)}
				</Context.Menu>
			</SettingsWrapper>
			{showModal && (
				<AccountDeleteModal account={account} onClose={handleToggleModal} />
			)}
		</Fragment>
	);
}

interface HeaderWrapperProps {
	branded?: boolean;
}

const HeaderWrapper = styled.div<HeaderWrapperProps>`
	display: flex;
	align-items: center;
	position: relative;

	${({ branded }) =>
		branded
			? css`
					justify-content: start;
				`
			: css`
					justify-content: center;
					padding: 0 var(--spacing-8);
				`};
`;

export default function Header(): JSX.Element {
	const onboardingState = useOnboardingState();
	const group = onboardingState.get<Group>('group');

	const isBranded = !!group;

	if (isBranded) {
		return (
			<HeaderWrapper branded>
				<TeamBadge group={group} />
				<Settings />
			</HeaderWrapper>
		);
	}

	return (
		<HeaderWrapper>
			<ThreeSixtyLogo />
			<Settings />
		</HeaderWrapper>
	);
}
