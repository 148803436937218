type SportSlugs = 'football';

export interface Sport {
	id: number;
	name?: string;
	slug?: SportSlugs;
	defaultMatchDuration: number;
	defaultMatchPeriods: number;
	hasMatchModel: boolean;
	hasRatingModel: boolean;
}

export function hasMatchesEnabled(sport: Sport): boolean {
	return sport?.hasMatchModel;
}

export function hasRatingsEnabled(sport: Sport): boolean {
	return sport?.hasRatingModel;
}

export function isFootball(sport: Sport): boolean {
	return sport.slug === 'football';
}
