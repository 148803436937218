import { t } from '@transifex/native';
import { useState } from 'react';

import { useCurrentGroupId } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { FilterOperator } from 'pkg/filters';

import DiscountsTable from 'routes/payments/discounts/table';
import { useDiscountFilters } from 'routes/payments/discounts/filters';
import CreateDiscountModal from 'routes/payments/discounts/create/modal';

import Pagination from 'components/pagination';
import * as StepModal from 'components/step-modal';

import * as ActionBar from 'components/layout/ActionBar';

interface SelectDiscountModalProps {
	onClose: (discount?: models.discount.Discount) => void;
}

export default function SelectDiscountModal({
	onClose,
}: SelectDiscountModalProps) {
	const groupId = useCurrentGroupId();
	const [create, setCreate] = useState(false);

	const filters = useDiscountFilters({ hideStatusFilter: true });

	filters.queryParam.set('group_id', groupId.toString());
	filters.queryParam.set(
		'filters',
		JSON.stringify([
			{
				property: 'inactivated_at',
				operator: 'not_set',
				values: ['active'],
			},
		])
	);

	const discountsCollection = useCollection<models.discount.Discount>(
		endpoints.Discount.Index(),
		{
			queryParams: filters.queryParam,
		}
	);

	const hideCreateDiscount = (discount?: models.discount.Discount) => {
		if (discount) {
			onClose(discount);
			filters.reset();
		} else {
			setCreate(false);
		}
	};

	const handleClose = () => {
		onClose();
		filters.reset();
	};

	const handleNext = async () => {
		onClose(discountsCollection.selectedRecords[0]);
		filters.reset();
	};

	const handleCreateNewClick = () => {
		setCreate(true);
	};

	const selectedDiscount = discountsCollection.selectedRecords[0];

	return (
		<StepModal.Base onClose={handleClose} wide>
			<StepModal.Step
				title={t('Select discount code')}
				nextLabel={t('Select')}
				skipBody
				canGoNext={
					selectedDiscount ? models.discount.isActive(selectedDiscount) : false
				}
				onNext={handleNext}>
				<ActionBar.IntegratedFilterBar
					searchFilter={{
						type: 'text',
						operator: FilterOperator.Contains,
						property: 'search',
					}}
					filters={filters}
					actions={[
						{
							label: t('Create new'),
							onClick: handleCreateNewClick,
							type: 'primary',
							icon: 'add',
						},
					]}
				/>
				<DiscountsTable
					sort={discountsCollection.sort}
					discountsCollection={discountsCollection}
					hideContextColumn={true}
					selectable={true}
				/>
				<Pagination {...discountsCollection.pagination} />
				{create && (
					<CreateDiscountModal
						discountsCollection={discountsCollection}
						onClose={hideCreateDiscount}
					/>
				)}
			</StepModal.Step>
		</StepModal.Base>
	);
}
