import { Fragment } from 'react/jsx-runtime';
import { useT } from '@transifex/react';
import { useEffect, useState } from 'react';

import * as models from 'pkg/api/models';
import { Filter, FilterReplacer, FilterRow } from 'pkg/filters/use_filters';
import { BaseFilterProps } from 'pkg/filters/types';
import { useCurrentGroupId } from 'pkg/identity';
import { FilterOperator, QueryFilter } from 'pkg/filters';
import * as arrays from 'pkg/arrays';

import * as StepModal from 'components/step-modal';

import useGroupSelectTable from 'components/group/use-select-table';
import * as Input from 'components/form/inputs';

import Button from 'design/button';

const UserGroupRoleFilter: Filter = {
	type: 'checkbox',
	property: 'user_group_roles',
	operator: FilterOperator.Includes,
};

interface GroupRoleFilterProps extends BaseFilterProps {
	appliedFilters: QueryFilter[];
	replaceFilter: FilterReplacer;
}

export function GroupRoleFilter({
	filter,

	replaceFilter,
	appliedFilters,
}: GroupRoleFilterProps): JSX.Element {
	const t = useT();
	const groupId = useCurrentGroupId();

	const getAppliedGroupIds = (): number[] => {
		const rolesInGroup: string[] =
			appliedFilters.find((qf) => qf.property === filter.property)?.values ??
			[];

		return arrays.unique(
			rolesInGroup.map((roleInGroup) => {
				const [, groupId] = roleInGroup.split(':');

				return Number.parseInt(groupId, 10);
			})
		);
	};

	const getAppliedRoles = (): string[] => {
		const ugr =
			appliedFilters.find((qf) => qf.property === UserGroupRoleFilter.property)
				?.values ?? [];

		const rig = (
			appliedFilters.find((qf) => qf.property === filter.property)?.values ?? []
		).map((v: string) => {
			const [r] = v.split(':');
			return r;
		});

		const appliedRoles: string[] = arrays.unique([...ugr, ...rig]);

		return appliedRoles;
	};

	const [groupSelectOpen, setGroupSelectOpen] = useState<boolean>(false);
	const [groupIds, setGroupIds] = useState<number[]>(getAppliedGroupIds());
	const [roles, setRoles] = useState<string[]>(getAppliedRoles());

	const [nextValues, setNextValues] = useState<string[]>([]);

	const numGroups: number = groupIds.length;

	const openGroupSelect = () => {
		setGroupSelectOpen(true);
	};

	const closeGroupSelect = () => {
		setGroupSelectOpen(false);
	};

	const handleRoleChange = (value: string, target: HTMLInputElement) => {
		let nextRoles: string[];

		if (target.checked) {
			nextRoles = [...roles, value];
		} else {
			nextRoles = roles.filter((r) => r !== value);
		}

		setRoles(nextRoles);
	};

	const handleGroupIdsChange = (groups: models.group.Group[]) => {
		setGroupIds(groups.map((g) => g.id));
	};

	// Set next values whenever groups or roles change
	useEffect(() => {
		if (groupIds.length > 0) {
			const values: string[] = [];

			groupIds.map((groupId) => {
				roles.map((role) => {
					values.push(`${role}:${groupId}`);
				});
			});

			setNextValues(values);
		} else if (roles.length > 0 && groupIds.length === 0) {
			setNextValues(roles);
		}
	}, [roles, groupIds]);

	// This fires when filter *both* values change, not either or
	useEffect(() => {
		const isRoleInGroupsFilter = nextValues.every((v) => v.includes(':'));

		if (isRoleInGroupsFilter) {
			replaceFilter(UserGroupRoleFilter, filter, nextValues);
		} else {
			replaceFilter(filter, UserGroupRoleFilter, nextValues);
		}
	}, [nextValues]);

	const selectTable = useGroupSelectTable({
		groupId,
		defaultSelectedGroupIds: groupIds,
		onChange: handleGroupIdsChange,
	});

	return (
		<Fragment>
			<FilterRow>
				{Object.entries(filter.values).map(([label, value]) => {
					const checked = roles.includes(value as string);

					return (
						<FilterRow>
							<Input.Control
								type="checkbox"
								label={label}
								value={value}
								defaultChecked={checked}
								onChange={handleRoleChange}
							/>
						</FilterRow>
					);
				})}
			</FilterRow>
			<FilterRow>
				<Button block disabled={roles.length === 0} onClick={openGroupSelect}>
					{numGroups > 0
						? t('{numGroups} groups selected', { numGroups })
						: t('In any group')}
				</Button>
			</FilterRow>
			{groupSelectOpen && (
				<StepModal.Base onClose={closeGroupSelect}>
					<StepModal.Step
						title={t('Select groups')}
						nextLabel={t('Done')}
						skipBody>
						{selectTable.Component}
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);
}
