import { t } from '@transifex/native';
import { useFormContext } from 'react-hook-form';

import * as models from 'pkg/api/models';

import * as Inputs from 'components/form/inputs';
import Section from 'components/form/Section';

import Button from 'design/button';

import * as styles from './styles.css';

interface Props {
	discount: models.discount.Discount;
	onApply: (discount: string) => void;
	onRemove: () => void;
}

const Discount = ({ discount, onApply, onRemove }: Props) => {
	const { getValues } = useFormContext();

	const handleApply = () => {
		const discountCode = getValues(['discount'])[0];
		onApply(discountCode);
	};

	const handleRemove = () => {
		onRemove();
	};

	return (
		<Section title={t('Discount code')}>
			{discount && (
				<div className={styles.discount_title_wrapper}>
					<div className={styles.discount_title}>
						{t('Applied discount: ')}
						{discount.title}
					</div>
					<Button icon="cancel" inline noPadding onClick={handleRemove}>
						{t('Remove')}
					</Button>
				</div>
			)}
			<div className={styles.field_wrapper}>
				<Inputs.Field name="discount" />
				<Button primary onClick={handleApply}>
					{t('Use code')}
				</Button>
			</div>
		</Section>
	);
};

export default Discount;
