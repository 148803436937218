import {
	ReactElement,
	ReactNode,
	Fragment,
	CSSProperties,
	createContext,
	useState,
	useContext,
	useEffect,
	Dispatch,
	SetStateAction,
} from 'react';
import styled, { keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import * as styles from 'pkg/config/styles';

import * as ls from 'pkg/local_storage';
import { Group } from 'pkg/api/models/onboarding_info';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRoute, useRouterState } from 'pkg/router/hooks';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as api from 'pkg/api';
import { ShowResponse } from 'pkg/api/endpoints/self';

import { OnboardingHeader } from 'routes/public/styles/OnboardingHeader';
import { useOnboardingState } from 'routes/public/hooks';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as onboardingStyles from './styles.css';

const unbrandedSlugs: string[] = [
	'start',
	'login',
	'signup',
	'login-email',
	'group-code',
	'reset-password-request',
	'reset-password',
	'claim',
	'sso-org-slug',
];

export type OnboardingSlug =
	| 'start'
	| 'login'
	| 'login-email'
	| 'reset-password-request'
	| 'reset-password'
	| 'group-code'
	| 'group-confirmation'
	| 'role-selector'
	| 'user-information'
	| 'confirm-user-and-group'
	| 'create-account'
	| 'account-details'
	| 'gdpr-request'
	| 'gdpr-request-pending'
	| 'parental-consent-request'
	| 'create-ward-account'
	| 'ward-connect'
	| 'ward-account-details'
	| 'ward-user-information'
	| 'ward-own-account'
	| 'claim'
	| 'account-invite-user-information'
	| 'account-invite-create-account'
	| 'account-invite-account-details'
	| 'account-invite-sso-select'
	| 'signup'
	| 'join'
	| 'join-group-confirmation'
	| 'join-group-role-selector'
	| 'join-group-user-information'
	| 'join-group-create-account'
	| 'join-group-account-details'
	| 'ward-finished'
	| 'parent-email-invite'
	| 'parent-email-sent'
	| 'organization-finished'
	| 'player-finished'
	| 'staff-finished'
	| 'account-invite-finished'
	| 'new-ward-connection'
	| 'create-ward-group-code'
	| 'create-ward-group-confirmation'
	| 'create-ward-user-information'
	| 'create-ward-own-account'
	| 'create-ward-account-details'
	| 'create-ward-account-created'
	| 'new-group-connection'
	| 'ward-group-confirmation'
	| 'join-group-choose-account'
	| 'join-group-child-confirmation'
	| 'join-group-ward-confirmation'
	| 'join-group-sso-select'
	| 'sso-org-slug'
	| 'sso-login'
	| 'sso-select'
	// Special case for the return url we get from a SSO login
	| '/sso/redirect'
	| '';

export enum OnboardingAnimationDirection {
	In,
	Out,
}

interface OnboardingContextProperties {
	currentSlug: OnboardingSlug;

	goTo: (
		slug: OnboardingSlug,
		direction?: OnboardingAnimationDirection
	) => void;
	back: () => void;
	overrideBackSlug: (string: OnboardingSlug) => void;
	reset: () => void;

	setStore: (key: string, value?: string) => void;
	getStore: (key: string) => string;
	resetStore: () => void;

	storePrefix: string;
	brandHsl: string[];
	backdropImage: string;
	account: models.account.Account;
	invitedEmail: string;

	setStorePrefix: (storePrefix: string) => void;
	setBrandHsl: (hsl: string[]) => void;
	setBackdropImage: (backdropImage: string) => void;

	setAccount: Dispatch<SetStateAction<models.account.Account>>;
	setInvitedEmail: Dispatch<SetStateAction<string>>;
}

const intitialContextProperties: OnboardingContextProperties = {
	currentSlug: '',

	goTo: () => null,
	back: () => null,
	overrideBackSlug: () => null,
	reset: () => null,

	setStore: () => null,
	getStore: () => null,
	resetStore: () => null,

	storePrefix: '',
	brandHsl: [],
	backdropImage: '',
	account: null,
	invitedEmail: '',

	setStorePrefix: () => null,
	setBrandHsl: () => null,
	setBackdropImage: () => null,
	setAccount: () => null,
	setInvitedEmail: () => null,
};

export const OnboardingContext = createContext<OnboardingContextProperties>(
	intitialContextProperties
);

export function useOnboardingContext(): OnboardingContextProperties {
	return useContext(OnboardingContext);
}

interface OnboardingStepProps {
	slug: OnboardingSlug;
	backSlug?: OnboardingSlug;
	progress?: number;
	requiredData?: string[];
	component: ReactNode | (() => JSX.Element);
}

type OnboardingStepChild = ReactElement<OnboardingStepProps>[];

interface OnboardingProps {
	initialSlug?: OnboardingSlug;
	storePrefix: string;
	allowedLocationSlugs?: string[];
	storage?: Storage; // localStorage or sessionStorage, defaults to sessionStorage
	children: OnboardingStepChild;
}

function normalizedBrandedCSSVariables(hsl: string[]): CSSProperties {
	const [h, s, l] = hsl;

	let hue: string = h || '247';
	let sat: string = '55%';

	// Gradient adjustments
	let lumStart: string = '22%';
	let lumStop: string = '13%';

	// Force increased saturation for *branded* color
	if (h) {
		sat = '95%';
	}

	// Force grayscale when pure white or black
	if (s === '0%' || l === '0%' || l === '100%') {
		hue = '0';
		sat = '0%';

		// Adjust grayscale to slightly darker to adjust for the lack of saturation
		lumStart = '18%';
		lumStop = '10%';
	}

	return {
		'--hue': hue,
		'--sat': sat,
		'--lum-start': lumStart,
		'--lum-stop': lumStop,
	} as CSSProperties;
}

function useBrandedCSSVariables(): CSSProperties {
	const { brandHsl } = useOnboardingContext();

	return normalizedBrandedCSSVariables(brandHsl);
}

const backdropAppearAnimation = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 0.07;
	}
`;

const AnimateWrapper = styled.div`
	background-color: hsl(var(--hue), var(--sat), var(--lum-start, 22%));
	width: 100%;
	height: 100%;
	position: relative;
	transition: background 250ms ease-in-out;
	padding: var(--spacing-7);
	padding-top: calc(env(safe-area-inset-top));
	padding-bottom: calc(env(safe-area-inset-top) + var(--spacing-7));
	overflow-y: auto;
	overflow-x: hidden;

	&[data-has-backdrop='false'] {
		background-image: url(${window.TS.assetUrl}/img/onboarding-backdrop.svg);
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: 100%;
	}

	@media ${styles.breakpoint.small} {
		background-image: linear-gradient(
			to bottom,
			hsl(var(--hue), var(--sat), var(--lum-start, 22%)),
			hsl(var(--hue), var(--sat), var(--lum-stop, 13%))
		);
	}
`;

const AnimateNode = styled(motion.div)`
	padding-top: 80px;
	width: 100%;
	min-height: calc(100% - 80px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
`;

const ContentWrapper = styled.div`
	min-height: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	@media ${styles.breakpoint.nolimit} {
		display: grid;
		grid-template-columns: 50% 50%;
	}
`;

const BackgroundWrapper = styled.div`
	background: linear-gradient(
		to bottom,
		hsl(var(--hue), var(--sat), var(--lum-start, 22%)),
		hsl(var(--hue), var(--sat), var(--lum-stop, 13%))
	);

	height: 100%;
	padding: var(--spacing-8);
	white-space: pre-wrap;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: -1;

	img.backdrop {
		user-select: none;
		pointer-events: none;
		position: absolute;
		width: 60vw;
		height: 60vw;
		z-index: -1;
		top: 50vh;
		right: -20%;
		opacity: 0;
		filter: blur(7px) grayscale(0.3);
		transform: translateY(-50%);
		animation: ${backdropAppearAnimation} 250ms linear 500ms;
		animation-fill-mode: forwards;
	}
`;

const BrandedSmallScreenBackdrop = styled.img`
	user-select: none;
	pointer-events: none;
	position: absolute;
	width: 150vw;
	height: 150vw;
	top: 80%;
	left: -45%;
	opacity: 0;
	filter: blur(7px) grayscale(0.3);
	transform: translateY(-50%);
	animation: ${backdropAppearAnimation} 250ms linear 500ms;
	animation-fill-mode: forwards;
`;

function BrandedLargeScreenBackground(): JSX.Element {
	const ctx = useOnboardingContext();
	const brand = useBrandedCSSVariables();

	const videoUrl = `${window.TS.assetUrl}/videos/video-portrait.mp4`;

	return (
		<LargeScreen>
			<BackgroundWrapper style={brand}>
				{ctx?.backdropImage && (
					<img src={ctx.backdropImage} className="backdrop" />
				)}
				<AnimatePresence>
					{unbrandedSlugs.includes(ctx?.currentSlug) && (
						<motion.div
							className={onboardingStyles['large-screen-backdrop']}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}>
							<div
								dangerouslySetInnerHTML={{
									__html: `<video loop playsinline muted autoplay src="${videoUrl}" />`,
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</BackgroundWrapper>
		</LargeScreen>
	);
}

export function Wrapper({
	initialSlug,
	storePrefix,
	storage = sessionStorage,
	children,
}: OnboardingProps): JSX.Element {
	const onboardingState = useOnboardingState();
	const route = useCurrentRoute();
	const { pushState, replaceState } = useRouterState();

	const [storagePrefix, setStorePrefix] = useState<string>(storePrefix);

	const currentSlug =
		route.path?.replace(/^\/|\/$/g, '')?.split('/')[0] || initialSlug;

	const [backSlug, setBackSlug] = useState<OnboardingSlug>('');

	const [account, setAccount] = useState<models.account.Account>(null);
	const [invitedEmail, setInvitedEmail] = useState<string>('');

	const [brandHsl, setBrandHsl] = useState<string[]>([]);
	const [backdropImage, setBackdropImage] = useState<string>('');

	const [direction, setDirection] = useState<OnboardingAnimationDirection>(
		OnboardingAnimationDirection.In
	);

	useEffect(() => {
		setStorePrefix(storePrefix);
	}, [storePrefix]);

	const currentChild = (
		children.length > 0
			? children.find((child) => child.props.slug === currentSlug) ||
				// Special case for the /sso/redirect step where we want to check
				// the path instead of the currentSlug
				children.find((child) => child.props.slug === route.path) ||
				children.find((child) => child.props.slug === initialSlug)
			: null
	) as ReactElement<OnboardingStepProps>;

	const goTo = (
		slug: OnboardingSlug,
		direction: OnboardingAnimationDirection = OnboardingAnimationDirection.In
	) => {
		const nextChild = children.find(
			(child) => (child as ReactElement).props?.slug === slug
		);

		const nextSlug = nextChild.props.slug;

		setBackSlug(nextChild.props.backSlug ?? '');

		setDirection(direction);

		pushState(`/${nextSlug}`);
	};

	const overrideBackSlug = (slug: OnboardingSlug) => {
		setBackSlug(slug);
	};

	const back = () => {
		if (backSlug) {
			setDirection(OnboardingAnimationDirection.Out);
			pushState(`/${backSlug}`);
		}
	};

	const reset = () => {
		onboardingState.flush();

		replaceState('/');
	};

	const setStore = (key: string, value?: string) => {
		if (!storagePrefix) return;

		key = `${storagePrefix}::${key}`;

		if (!!value) {
			storage.setItem(key, value);
		} else {
			storage.removeItem(key);
		}
	};

	const getStore = (key: string): string => {
		return storage.getItem(`${storagePrefix}::${key}`) || '';
	};

	const resetStore = () => {
		setStore('state', null);
	};

	useEffect(() => {
		setBackSlug(currentChild?.props.backSlug ?? '');
	}, [currentChild]);

	const contextValue: OnboardingContextProperties = {
		currentSlug,

		goTo,
		back,
		overrideBackSlug,
		reset,

		setStore,
		getStore,
		resetStore,

		brandHsl,
		setBrandHsl,

		backdropImage,
		setBackdropImage,

		storePrefix: storagePrefix,
		setStorePrefix,

		account,
		setAccount,

		invitedEmail,
		setInvitedEmail,
	};

	const variants = {
		enter: (direction: OnboardingAnimationDirection) => ({
			x: direction === OnboardingAnimationDirection.In ? '20vw' : '-20vw',
			opacity: 0,
		}),
		center: {
			x: 0,
			opacity: 1,
		},
		exit: (direction: OnboardingAnimationDirection) => ({
			x: direction === OnboardingAnimationDirection.In ? '-20vw' : '20vw',
			opacity: 0,
		}),
	};

	const handleTransitionDone = (dfn: string) => {
		if (dfn === 'center') {
			setDirection(OnboardingAnimationDirection.Out);
		}
	};

	const brand = normalizedBrandedCSSVariables(brandHsl);

	const showHeader =
		currentChild?.props?.backSlug || currentChild?.props?.progress;

	return (
		<OnboardingContext.Provider value={contextValue}>
			<ContentWrapper>
				<AnimateWrapper style={brand} data-has-backdrop={!!backdropImage}>
					<AnimatePresence mode="wait">
						{showHeader && (
							<OnboardingHeader
								key="onboarding::header"
								progress={currentChild?.props?.progress}
								backSlug={currentChild?.props?.backSlug}
							/>
						)}
					</AnimatePresence>
					<AnimatePresence mode="wait" initial={false}>
						<AnimateNode
							key={`${storePrefix}::${currentSlug}`}
							transition={{ duration: 0.25 }}
							custom={direction}
							variants={variants}
							initial="enter"
							animate="center"
							exit="exit"
							onAnimationComplete={handleTransitionDone}>
							{currentChild}
						</AnimateNode>
					</AnimatePresence>
				</AnimateWrapper>
				<SmallScreen>
					{backdropImage && <BrandedSmallScreenBackdrop src={backdropImage} />}
				</SmallScreen>
			</ContentWrapper>
			<BrandedLargeScreenBackground />
		</OnboardingContext.Provider>
	);
}

export function Step({
	slug,
	component,
	requiredData,
}: OnboardingStepProps): JSX.Element {
	const onboardingState = useOnboardingState();
	const { setBrandHsl, setBackdropImage, reset } = useOnboardingContext();
	const data: any = onboardingState.getAll() || {};

	const setBranding = () => {
		const group = onboardingState.get<Group>('group');

		if (group) {
			if (group?.primaryColor?.startsWith('hsl')) {
				const [h, s, l] = group.primaryColor.replace('hsl:', '').split(',');

				setBrandHsl([h, s, l]);
			}

			if (group?.profileImageUrl) {
				setBackdropImage(group.profileImageUrl);
			}
		}
	};

	const unsetBranding = () => {
		setBrandHsl([]);
		setBackdropImage('');
	};

	useComponentDidMount(
		unbrandedSlugs.includes(slug) ? unsetBranding : setBranding,
		unsetBranding
	);

	let validationFailed = false;

	requiredData?.map((field: string) => {
		if (field.includes('.')) {
			const [key, value] = field.split('.');

			if (!data[key] || !data[key][value]) {
				validationFailed = true;
				reset();
			}
		} else {
			if (!data[field]) {
				validationFailed = true;
				reset();
			}
		}
	});

	if (validationFailed) {
		return null;
	}

	// @NOTE React+TypeScript forgets that passed component is renderable, assumes litteral function, this is a workaround.
	const RenderableComponent: any = component;

	return (
		<Fragment key={slug}>
			<RenderableComponent />
		</Fragment>
	);
}

export async function fetchCurrentAccount(
	onError?: CallableFunction
): Promise<[boolean, models.account.Account]> {
	const authToken = ls.getItem(ls.LocalStorageKeys.AuthToken);

	if (!authToken) {
		return [false, null];
	}

	const [response, self] = await api.get<ShowResponse>(
		endpoints.Self.ShowSelf()
	);

	if (!response.ok) {
		if (onError) {
			onError();
		}

		throw new Error(`${response.status}`);
	}

	return [true, self.account];
}
