import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useCurrentAccount } from 'pkg/identity';

import * as formStyles from 'routes/public/styles/forms/styles.css';
import { useOnboardingState } from 'routes/public/hooks';
import AccountCard from 'routes/public/styles/AccountCard';
import Button from 'routes/public/styles/Button';
import Title from 'routes/public/styles/Title';
import Header from 'routes/public/no-memberships/styles/Header';
import { useOnboardingContext } from 'routes/public/onboarding';

import Column from 'components/layout/column';

export default function CreateChildAccountCreated(): JSX.Element {
	const onboardingState = useOnboardingState();
	const { resetStore } = useOnboardingContext();

	const account = useCurrentAccount();
	const targetAccount =
		onboardingState.get<models.account.Account>('targetAccount');
	const group = onboardingState.get<models.onboardingInfo.Group>('group');

	const save = async () => {
		resetStore();
		window.history.pushState({}, '', '/');
		window.location.reload();
	};

	const buttonLabel = group.isOrganization
		? t('Continue')
		: t('Take me to the team!');

	return (
		<Column spacing={styles.spacing._8}>
			<Header />

			<Title
				title={t('Success!')}
				description={t('You are now connected to:')}
			/>

			<Column spacing={styles.spacing._6}>
				<Column>
					<span className={formStyles.label}>{t('Your account')}</span>
					<AccountCard account={account} />
				</Column>

				<Column>
					<span className={formStyles.label}>{t('Child account')}</span>
					<AccountCard account={targetAccount} />
				</Column>

				<Button primary label={buttonLabel} onClick={save} />
			</Column>
		</Column>
	);
}
