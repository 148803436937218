import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import {
	EventTypes,
	eventTypeLabels,
	eventTypeToTranslatedString,
} from 'pkg/models/event';
import User from 'pkg/models/user';

import { EventReportPayload } from 'pkg/actions/event_reports';

import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { RootState } from 'pkg/reducers';
import { QueryObject, useQueryState } from 'pkg/hooks/query-state';
import { Dates } from 'pkg/hooks/use-date-filter';
import { useCurrentOrganization } from 'pkg/identity';
import DateTime from 'pkg/datetime';

import Filters, { getFilters } from 'routes/group/report/shared/Filters';
import ReportRow from 'routes/group/report/attendance/AttendanceReportRow';

import AssetImage from 'components/AssetImage';

import * as Table from 'design/table';

interface AttendanceReportProps {
	groupId: number;
	query: QueryObject;
	dateFilter: JSX.Element;
	dateRange: Dates;
}

export default function AttendanceReport({
	groupId,
	query = {},
	dateFilter,
	dateRange,
}: AttendanceReportProps): JSX.Element {
	const qs = useQueryState();
	const [eventReports, setEventReports] = useState([]);
	const [userIds, setUserIds] = useState([]);
	const [searchBy, setSearchBy] = useState('');
	const [sortedBy, setSortedBy] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');
	const [isLoading, setIsLoading] = useState(true);

	const users = useSelector((state: RootState) =>
		selectors.users.findAllByIds(state, userIds)
	);
	const organization = useCurrentOrganization();

	const filters = getFilters(query);
	const eventTypes = qs.getArray('eventTypes', []);

	useEffect(() => {
		const fetchReports = async () => {
			setIsLoading(true);

			const payload: EventReportPayload = {
				groupId,
				eventTypes,
				startsAt: dateRange.startOfRange,
				endsAt: dateRange.endOfRange,
			};

			const reports = await actions.eventReports.fetchGroupedByUser(payload);
			const ids = Object.values(reports).map((report: any) => report.userId);

			setUserIds(ids);
			setEventReports(reports);
			setIsLoading(false);
		};

		fetchReports();
	}, [query, groupId, dateRange.startOfRange, dateRange.endOfRange]);

	useEffect(() => {
		if (
			eventTypeLabels.toString().includes(sortedBy) &&
			!filters.eventTypes.includes(sortedBy)
		) {
			setSortOrder('asc');
			setSortedBy('name');
		}
	}, [query]);

	const handleSort = (value: string) => {
		if (value === sortedBy) {
			setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
			return;
		}

		setSortOrder('asc');
		setSortedBy(value);
	};

	const sortReports = (a: any, b: any) => {
		let aValue: string;
		let bValue: string;

		if (sortedBy === 'name') {
			const aUser = users.find((user: User) => user.id === a.userId);
			const bUser = users.find((user: User) => user.id === b.userId);

			aValue = aUser?.fullName || '';
			bValue = bUser?.fullName || '';
		}

		if (sortedBy === 'total') {
			aValue = `${a.total + a.absent + a.attendance.unset}`;
			bValue = `${b.total + b.absent + b.attendance.unset}`;
		}

		if (sortedBy === 'attended') {
			aValue = `${a.total}`;
			bValue = `${b.total}`;
		}

		if (sortedBy === 'injured') {
			aValue = `${a.attendance.injured}`;
			bValue = `${b.attendance.injured}`;
		}

		if (sortedBy === 'sick') {
			aValue = `${a.attendance.sick}`;
			bValue = `${b.attendance.sick}`;
		}

		if (sortedBy === 'other') {
			aValue = `${a.attendance.other}`;
			bValue = `${b.attendance.other}`;
		}

		if (sortedBy === 'absent') {
			aValue = `${a.absent}`;
			bValue = `${b.absent}`;
		}

		if (sortedBy === 'unset') {
			aValue = `${a.attendance.unset}`;
			bValue = `${b.attendance.unset}`;
		}

		if (eventTypeLabels.toString().includes(sortedBy)) {
			aValue = `${a.eventType[sortedBy]}`;
			bValue = `${b.eventType[sortedBy]}`;
		}

		if (sortOrder === 'asc') {
			return aValue.localeCompare(bValue, DateTime.getLocale(), {
				numeric: true,
				sensitivity: 'base',
			});
		}

		return bValue.localeCompare(aValue, DateTime.getLocale(), {
			numeric: true,
			sensitivity: 'base',
		});
	};

	const renderedReports = Object.values(eventReports)
		.filter((eventReport) => {
			if (searchBy === '') {
				return true;
			}

			const user = users.find((user: User) => user.id === eventReport.userId);

			return user?.fullName.toLowerCase().includes(searchBy.toLowerCase());
		})
		.sort(sortReports)
		.map((eventReport) => {
			const user = users.find((user: User) => user.id === eventReport.userId);

			if (!user?.id) return;

			return (
				<ReportRow
					user={user}
					key={eventReport.userId}
					eventTypes={filters.eventTypes}
					eventReport={eventReport}
					groupId={groupId}
					query={query}
					showLOK={models.group.isLOKActive(organization)}
				/>
			);
		});

	const eventTypeHeaders = eventTypeLabels
		.filter((eventType: string) => filters.eventTypes.includes(eventType))
		.map((eventType: string) => ({
			content: eventTypeToTranslatedString(eventType as EventTypes),
			align: 'right',
			sortOrder,
			sortedBy: sortedBy === eventType,
			onClick: () => handleSort(eventType),
		}));

	const columns = [
		{
			content: t(`Name`),
			width: '240px',
			sortOrder,
			sortedBy: sortedBy === 'name',
			onClick: () => handleSort('name'),
		},
		{
			content: t(`Total events`),
			align: 'right',
			sortOrder,
			sortedBy: sortedBy === 'total',
			onClick: () => handleSort('total'),
		},
		...eventTypeHeaders,
		{
			content: t(`Attended`),
			align: 'right',
			sortOrder,
			sortedBy: sortedBy === 'attended',
			onClick: () => handleSort('attended'),
		},
		{
			content: t(`Injured`),
			align: 'right',
			sortOrder,
			sortedBy: sortedBy === 'injured',
			onClick: () => handleSort('injured'),
		},
		{
			content: t(`Illness`),
			align: 'right',
			sortOrder,
			sortedBy: sortedBy === 'sick',
			onClick: () => handleSort('sick'),
		},
		{
			content: t(`Other`),
			align: 'right',
			sortOrder,
			sortedBy: sortedBy === 'other',
			onClick: () => handleSort('other'),
		},
		{
			content: t(`Absent`),
			align: 'right',
			sortOrder,
			sortedBy: sortedBy === 'absent',
			onClick: () => handleSort('absent'),
		},
		{
			content: t(`Unhandled`),
			align: 'right',
			sortOrder,
			sortedBy: sortedBy === 'unset',
			onClick: () => handleSort('unset'),
		},
		models.group.isLOKActive(organization)
			? {
					content: 'LOK',
					align: 'center',
					width: 'max-content',
				}
			: null,
	].filter((n) => n);

	const eventTypeFooters = eventTypeLabels
		.filter((eventType: string) => filters.eventTypes.includes(eventType))
		.map((eventType: string) => ({
			sum: eventType,
			align: 'right',
		}));

	const footerColumns = [
		{ content: t('Total') },
		{ align: 'right', sum: 'numEvents', pill: 'gray' },
		...eventTypeFooters,
		{ align: 'right', sum: 'attended', pill: 'green', percentOf: 'numEvents' },
		{ align: 'right', sum: 'injured' },
		{ align: 'right', sum: 'illness' },
		{ align: 'right', sum: 'other' },
		{ align: 'right', sum: 'absent', pill: 'red', percentOf: 'numEvents' },
		{
			align: 'right',
			sum: 'unhandled',
			pill: 'orange',
			percentOf: 'numEvents',
		},
		models.group.isLOKActive(organization) ? {} : null,
	].filter((n) => n);

	const emptyState = {
		title: t(`You don't have any available reports`),
		content: t(`You don't have any available reports`),
		image: <AssetImage src="img/missing-entities/post.svg" />,
	};

	return (
		<Fragment>
			<Filters
				searchBy={searchBy}
				handleSearch={setSearchBy}
				dateFilter={dateFilter}
			/>
			<Table.Table
				stickyHeader
				stickyFooter
				isLoading={isLoading}
				emptyState={emptyState}
				columns={columns}
				footerColumns={footerColumns}>
				{renderedReports}
			</Table.Table>
		</Fragment>
	);
}
