import { useEffect } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import spacing from 'pkg/config/spacing';

import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

import RelativeDateTime from 'components/RelativeDateTime';
import * as CardList from 'components/card-list';
import SectionTitle from 'components/SectionTitle';
import Label from 'components/label';
import { FormattedContent } from 'components/formatted-content';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import Section from 'components/form/Section';

import Button from 'design/button';

import * as css from './styles.css';

interface Props {
	guid: string;
	setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

interface ShowFormResponse {
	form: models.form.Form;
	paymentProviderSettings: models.providerSettings.ProviderSettings;
}

const statusLabels = {
	[models.form.Statuses.Draft]: t('Draft registration'),
	[models.form.Statuses.Open]: t('Open for registrations'),
	[models.form.Statuses.Closed]: t('Closed for registrations'),
};

const getStatusColor = (status: models.form.Statuses) => {
	switch (status) {
		case models.form.Statuses.Open:
			return 'green';
		case models.form.Statuses.Closed:
			return 'red';
		case models.form.Statuses.Draft:
			return 'gray';
	}
};

const PreviewForm = ({ guid, setPageTitle }: Props) => {
	const group = useCurrentGroup();
	const org = useCurrentOrganization();

	const { record: response, isLoading: isFormLoading } =
		useEndpoint<ShowFormResponse>(endpoints.Forms.PublicShow(guid));

	const { records: submissions, isLoading: isSubmissionsLoading } =
		useCollection<models.formSubmission.FormSubmission>(
			endpoints.Forms.UserSubmissions(guid)
		);

	useEffect(() => {
		if (response?.form?.title) {
			setPageTitle(
				t(`{formTitle} Preview`, { formTitle: response.form.title })
			);
		}
	}, [isFormLoading]);

	if (isFormLoading) {
		return <Spinner />;
	}

	const statusColor = getStatusColor(response.form.status);
	const isDisabled = response.form.status === models.form.Statuses.Closed;

	return (
		<div className={css.wrapper}>
			<Column spacing={spacing._8}>
				<Column>
					<Label color={statusColor}>
						{statusLabels[response.form.status]}
					</Label>
					<SectionTitle large>{response.form.title}</SectionTitle>
				</Column>
				{response.form.attachmentId && (
					<div className={css.image}>
						<img src={response.form.attachment.url} />
					</div>
				)}
				<div className={css.registrationDescription}>
					<FormattedContent raw={response.form.description} />
				</div>
				<div className={css.buttonWrapper}>
					<Button
						block
						large
						primary
						href={routes.Registration.Form(response.form.guid)}
						icon="app_registration"
						label={t('Register')}
						disabled={isDisabled}
					/>
				</div>
				{isSubmissionsLoading ? (
					<Spinner />
				) : (
					submissions &&
					submissions.length > 0 && (
						<Section title={t('Registered')} hideDivider>
							<Column>
								{submissions.map((submission) => (
									<CardList.Base
										key={submission.id}
										href={routes.User.Submission(org.id, submission.id)}>
										<CardList.Image>
											<Avatar user={submission.submittedForUser} />
										</CardList.Image>
										<CardList.Title>
											<Column spacing={styles.spacing._1}>
												{models.user.fullName(submission.submittedForUser)}
												<span className={css.subText}>{group.name}</span>
											</Column>
										</CardList.Title>
										<CardList.Actions>
											<CardList.TextContent align="center">
												<RelativeDateTime
													dateTime={submission.createdAt}
													className={css.subText}
												/>
											</CardList.TextContent>
										</CardList.Actions>
									</CardList.Base>
								))}
							</Column>
						</Section>
					)
				)}
			</Column>
		</div>
	);
};

export default PreviewForm;
