import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import * as actionTypes from 'pkg/actions/action-types';
import * as flashActions from 'pkg/actions/flashes';
import * as notificationActions from 'pkg/actions/notifications';
import * as authActions from 'pkg/actions/auth';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import asyncLocalStorage from 'pkg/asyncLocalStorage';
import * as helpScout from 'pkg/helpscout';
import { replaceState } from 'pkg/router/state';
import store from 'pkg/store/createStore';
import { authenticateWithToken } from 'pkg/forms_service';
import { crash } from 'pkg/errors/errors';
import { CustomHeaders } from 'pkg/api/headers';
import { RootState } from 'pkg/reducers';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as ls from 'pkg/local_storage';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { LoginTypes, storeLoginInfo } from 'pkg/local_storage/login-info';
import { OrganizationsLocalStorageData } from 'pkg/identity/account';

import { unregister } from 'components/analytics';

export const saveAppleSignInCredentials = async (
	payload: Record<string, string>
): Promise<boolean> => {
	const response = await sdk.post(
		endpoints.Auth.AppleSignInSave(),
		{},
		payload
	);
	return response.ok;
};

export function useCheckAuth(): [string, boolean] {
	const authToken: string =
		useSelector<RootState, string>((state) => state.auth.authToken) || '';
	const dispatch = useDispatch();
	const [checkedAuth, setCheckedAuth] = useState(false);

	useComponentDidMount(() => {
		const localStorageAuthToken = localStorage.getItem('authToken') || '';

		if (localStorageAuthToken) {
			sdk.setToken(localStorageAuthToken);
			dispatch({
				type: actionTypes.AUTH_ALREADY_AUTHORIZED,
				payload: localStorageAuthToken,
			});
		} else {
			notificationActions.deregisterPush();
		}

		setCheckedAuth(true);
	});

	return [authToken, checkedAuth];
}

export const login =
	(
		res: { account: models.account.Account; token: string },
		redirect: boolean = true
	) =>
	async (dispatch: Dispatch): Promise<void> => {
		updateAuthToken({ accountToken: res.token });

		if (!redirect) return;

		dispatch({
			type: actionTypes.AUTH_LOGIN_SUCCESS,
			payload: res.token,
		});

		const urlParams = new URLSearchParams(window.location.search);

		if (urlParams.get('return') === 'forms') {
			const token = await authActions.createOneTimeLoginToken(res.account.id);
			const returnUrl = await authenticateWithToken(token);
			window.location.href = returnUrl;
		} else if (redirect === true) {
			replaceState(routes.Home());
		}
	};

export const logout =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		await notificationActions.deregisterPush();

		ls.removeItems(
			ls.LocalStorageKeys.ActiveOrganizationId,
			ls.LocalStorageKeys.Organizations,
			ls.LocalStorageKeys.ActiveMembership,
			ls.LocalStorageKeys.CalendarFilters
		);

		asyncLocalStorage.removeItem('authToken');
		sessionStorage.removeItem('avatar-step');
		sdk.setToken('');
		sdk.setDefaultRequestHeader(CustomHeaders.CurrentUserId, '');

		dispatch({
			type: actionTypes.AUTH_LOGOUT_SUCCESS,
			payload: null,
		});

		helpScout.logout();
		unregister();

		window.history.pushState({}, '', '/');
	};

export async function passwordAuth(
	username: string,
	password: string,
	lang?: string,
	redirect?: boolean
): Promise<boolean> {
	const headers: { [key: string]: string } = {};

	if (lang) {
		headers['Accept-Language'] = lang;
	}

	const response = await sdk.post(
		endpoints.Auth.Login(),
		{},
		{
			username,
			password,
		},
		headers
	);

	const result = await response.json();

	if (response.ok) {
		storeLoginInfo(result.account, LoginTypes.Email);

		await login(result, redirect)(store.dispatch);

		return true;
	}

	return false;
}

export const authWithApple =
	(accessToken: string, lang: string, redirect?: boolean) =>
	async (dispatch: Dispatch): Promise<boolean> => {
		const response = await sdk.request(
			endpoints.Auth.Login() + '?medium=apple',
			'POST',
			{
				authCode: accessToken,
			},
			{
				'Accept-Language': lang,
			}
		);

		const result = await response.json();

		if (response.ok) {
			storeLoginInfo(result.account, LoginTypes.Apple);

			await login(result, redirect)(dispatch);
		} else {
			flashActions.show({
				title: crash().title,
				message: result.error,
			});

			return false;
		}

		return true;
	};

export async function createOneTimeLoginToken(
	accountId: number
): Promise<string> {
	const endpoint = '/accounts/:id/tokens/login';
	const request = await sdk.post(endpoint, { id: accountId });
	if (!request.ok) {
		return '';
	}

	const response = await request.json();
	if (response.token) {
		return response.token;
	}

	return '';
}

export interface AuthOptions {
	organizationName: string;
	profileImageUrl: string;
	primaryColor: string;
	organizationId: number;
	organizationSlug: string;
	identityProviders: models.identityProvider.IdentityProvider[];
}

export async function fetchAuthOptions(
	slug: string
): Promise<[boolean, AuthOptions]> {
	const request = await sdk.get(endpoints.Auth.Options(slug), {}, { slug });

	if (request.ok) {
		const json = await request.json();

		return [true, json];
	}

	return [false, null];
}

export function updateAuthToken(
	tokens: {
		accountToken: string;
		orgToken?: string;
	},
	organizationId?: number
) {
	const lsOrganizations =
		ls.getJsonItem<OrganizationsLocalStorageData>(
			ls.LocalStorageKeys.Organizations
		) || {};

	let token = '';

	if (tokens.orgToken) {
		token = tokens.orgToken;

		if (organizationId) {
			if (Object.keys(lsOrganizations).length === 0) {
				lsOrganizations[organizationId] = {
					orgToken: tokens.orgToken,
					activeMembership: null,
				};
			} else {
				lsOrganizations[organizationId].orgToken = tokens.orgToken;
			}
		}
	}

	if (tokens.accountToken && !tokens.orgToken) {
		token = tokens.accountToken;
	}

	ls.setJsonItem(ls.LocalStorageKeys.Organizations, lsOrganizations);
	ls.setItem(ls.LocalStorageKeys.AuthToken, tokens.accountToken);
	sdk.setToken(token);
}
