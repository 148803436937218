import { useState } from 'react';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentGroup } from 'pkg/identity';

import Registration from 'routes/user/registrations/open/registration';

import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import SearchInput from 'components/form/SearchInput';
import { Spinner } from 'components/loaders/spinner';

import MissingEntities from 'design/placeholders/block';

interface OpenRegistrationsProps {
	showTitle?: boolean;
}

export default function OpenRegistrations({
	showTitle,
}: OpenRegistrationsProps): JSX.Element {
	const t = useT();

	const [search, setSearch] = useState('');
	const group = useCurrentGroup();

	const { records, isLoading } = useCollection<models.form.Form>(
		endpoints.Organizations.ListForms(group.organizationId || group.id)
	);

	if (isLoading) {
		return <Spinner />;
	}

	const renderRegistrations = () => {
		const categories: string[] = [];

		records.map((form: models.form.Form) => {
			if (
				form.formCategory &&
				categories.indexOf(form.formCategory.title) === -1
			) {
				return categories.push(form.formCategory.title);
			}
		});

		categories.sort();

		const rendered: JSX.Element[] = [];

		categories.map((categoryName: string) => {
			const registrations = records
				.filter(
					(form: models.form.Form) =>
						form.formCategory && categoryName === form.formCategory.title
				)
				.sort((a: models.form.Form, b: models.form.Form) =>
					a.title.localeCompare(b.title)
				)
				.map((form: models.form.Form) => {
					return <Registration key={form.id} form={form} group={group} />;
				});

			rendered.push(
				<Column spacing={styles.spacing._3} key={categoryName}>
					<SectionTitle thin>{categoryName}</SectionTitle>
					{registrations}
				</Column>
			);
		});

		const registrationsWiwhoutCategory = records.filter(
			(form: models.form.Form) => !form.formCategory
		);

		if (registrationsWiwhoutCategory.length) {
			rendered.push(
				<Column spacing={styles.spacing._3} key="no-category">
					<SectionTitle thin>{t('No Category')}</SectionTitle>
					{registrationsWiwhoutCategory
						.sort((a: models.form.Form, b: models.form.Form) =>
							a.title.localeCompare(b.title)
						)
						.map((form: models.form.Form) => {
							return <Registration key={form.id} form={form} group={group} />;
						})}
				</Column>
			);
		}

		return rendered;
	};

	let searchRegistrations: JSX.Element[] = [];

	if (search) {
		const searchBy = search.toLowerCase();

		searchRegistrations = records
			.filter((form: models.form.Form) => {
				let searchable = `${form.title.toLowerCase()} ${form.description.toLowerCase()}`;

				if (form.submissions) {
					form.submissions
						.filter((submission) => !!submission.submittedForUser)
						.map((submission: models.formSubmission.FormSubmission) => {
							searchable += submission.submittedForUser.firstName.toLowerCase();
							searchable += submission.submittedForUser.lastName.toLowerCase();
						});
				}

				if (searchable.includes(searchBy)) {
					return true;
				}
			})
			.sort((a: models.form.Form, b: models.form.Form) =>
				a.title.localeCompare(b.title)
			)
			.map((form: models.form.Form) => (
				<Registration key={form.id} form={form} group={group} />
			));

		if (searchRegistrations.length === 0) {
			searchRegistrations = [
				<MissingEntities
					noBorder
					title={t('No results')}
					description={t('No search results.')}
				/>,
			];
		}
	}

	const handleSearch = (value: string) => {
		setSearch(value);
	};

	const hasRegistrations = records.length > 0;

	return (
		<Column spacing={styles.spacing._5}>
			{showTitle && <SectionTitle>{t('Registrations')}</SectionTitle>}
			{hasRegistrations && (
				<SearchInput onChange={handleSearch} placeholder={t('Search')} />
			)}
			{hasRegistrations ? (
				<Column spacing={styles.spacing._7}>
					{search ? (
						<Column spacing={styles.spacing._3}>{searchRegistrations}</Column>
					) : (
						renderRegistrations()
					)}
				</Column>
			) : (
				<MissingEntities
					noBorder
					title={t('No open registrations')}
					description={t(
						'There are no open registrations published by the club.'
					)}
					icon="registration"
				/>
			)}
		</Column>
	);
}
