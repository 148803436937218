// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n7VV1Bgm3DlsGb0Te4w_ {
	font-family: var(--font-family-emoji);
}
`, "",{"version":3,"sources":["webpack://./components/emoji/styles.css"],"names":[],"mappings":"AAAA;CACC,qCAAqC;AACtC","sourcesContent":[".emoji {\n\tfont-family: var(--font-family-emoji);\n}\n"],"sourceRoot":""}]);
// Exports
export var emoji = `n7VV1Bgm3DlsGb0Te4w_`;
export default ___CSS_LOADER_EXPORT___;
