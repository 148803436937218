import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import InvoiceDescription from 'routes/payments/orders/single/invoice_description';
import OrderSummary from 'routes/payments/orders/single/summary';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import * as CardList from 'components/card-list';
import Avatar from 'components/avatar';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

interface AssignedContactProps {
	user: models.user.User;
}

const AssignedContact: React.FC<
	React.PropsWithChildren<AssignedContactProps>
> = ({ user }) => {
	const org = useCurrentOrganization();

	return (
		<CardList.Base
			noBorder
			href={routes.Management.Contact.Show(org.id, user.id, 'overview')}>
			<CardList.Image>
				<Avatar user={user} />
			</CardList.Image>
			<CardList.Title>
				<div>{models.user.fullName(user)}</div>
				<CardList.SubTitle>{user.email}</CardList.SubTitle>
			</CardList.Title>
		</CardList.Base>
	);
};

interface ContentProps {
	order: models.order.Order;
}

const Content: React.FC<React.PropsWithChildren<ContentProps>> = ({
	order,
}) => {
	// unique array of assigned contacts
	const assignedContacts = [
		...new Map(
			order.userProducts
				?.map((userProduct) => userProduct.user)
				?.map((user) => [user['id'], user])
		).values(),
	];

	let totalRefundedAmount = 0;

	order.refunds?.forEach((r) => (totalRefundedAmount += r.amount));

	const partiallyRefunded =
		order.amountRefunded > 0 && order.amountRefunded < order.amountDue;

	return (
		<Fragment>
			<Column spacing={styles.spacing._6}>
				<Column>
					<Card.Base>
						<Card.Header>
							<Card.Heading>{t('Description')}</Card.Heading>
						</Card.Header>
						<Card.Divider />
						<Card.Body>
							<Column>
								{partiallyRefunded && (
									<InfoBox color="orange">
										{t('This invoice has been partially refunded')}
									</InfoBox>
								)}
								{order.description && (
									<InfoBox icon="note" text={t('Memo')} color="gray">
										<span>{order.description}</span>
									</InfoBox>
								)}
								<InvoiceDescription order={order} />
								<OrderSummary order={order} />
							</Column>
						</Card.Body>
					</Card.Base>
				</Column>
				<Column>
					<SectionTitle>{t('Assigned contacts')}</SectionTitle>
					<Column spacing={styles.spacing._3}>
						{assignedContacts.map((user) => (
							<AssignedContact key={user.id} user={user} />
						))}
					</Column>
				</Column>
			</Column>
		</Fragment>
	);
};

export default Content;
