import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { t } from '@transifex/native';

import { formatPriceIntervalToText } from 'pkg/utils';
import DateTime from 'pkg/datetime';
import { formatToCurrency } from 'pkg/i18n/formatters';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentGroup } from 'pkg/identity';

import { AssignedProductProps } from 'routes/payments/orders/create';
import CreatePriceModal from 'routes/payments/prices/modals/new';

import SectionTitle from 'components/SectionTitle';

import * as Input from 'components/form/inputs';
import DatePicker from 'components/form/date-picker';
import TaxSelect from 'components/taxes/TaxSelect';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as Table from 'design/table';
import Button from 'design/button';

interface ProductOptionsProps {
	productId: number;
	formState: AssignedProductProps;
	setFormState: (data: AssignedProductProps) => void;
}

const ProductOptions: React.FC<
	React.PropsWithChildren<ProductOptionsProps>
> = ({ productId, formState, setFormState }) => {
	const [validTo, setValidTo] = useState(formState.validTo > 0 ? 'custom' : '');
	const [modal, setModal] = useState('');

	const nowUnix = new DateTime(new Date()).getUnixTimestamp();

	const group = useCurrentGroup();

	const { records: taxRateRecords } = useCollection<models.taxRate.TaxRate>(
		endpoints.TaxRates.Index1(group.id)
	);
	const taxRates = models.taxRate.convertIdsToNumber(taxRateRecords);
	const {
		isLoading,
		record: product,
		refresh: refreshProduct,
	} = useEndpoint<models.product.Product>(endpoints.Products.Show(productId));

	const activePrices = models.product.getActiveProductPrices(product);

	const showPriceModal = () => setModal('newPrice');
	const hideModal = () => setModal('');

	useEffect(() => {
		if (!formState.productPrice.recurring && validTo === 'custom') {
			setFormState({
				...formState,
				validTo: nowUnix,
			});
		}
	}, [formState.productPrice.recurring]);

	const handleFormChange = (
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const { name, value } = e.target;

		if (name === 'quantity') {
			setFormState({
				...formState,
				['quantity']: Number.parseInt(value, 10),
			});
			return;
		}

		if (name === 'taxRateId') {
			setFormState({
				...formState,
				['taxRateId']: Number.parseInt(value, 10) || null,
				taxRate: taxRates.find((t) => t.id === Number.parseInt(value, 10)),
			});
			return;
		}

		setFormState({ ...formState, [name]: value });
	};

	const handlePriceChange = (priceId: number) => {
		const price = product.prices.find((price) => price.id === priceId);

		if (price.recurring && formState.validTo > 0) {
			setFormState({
				...formState,
				validTo: 0,
				['productPrice']: price,
			});

			return;
		}

		setFormState({
			...formState,
			['productPrice']: price,
		});
	};

	const setPrice = (price: models.productPrice.ProductPrice) =>
		setFormState({
			...formState,
			productPrice: price,
		});

	const handleValidToChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;

		if (value === '') {
			setValidTo('');
			setFormState({
				...formState,
				['validTo']: 0,
			});
		} else {
			setValidTo('custom');
			setFormState({
				...formState,
				['validTo']: nowUnix,
			});
		}
	};

	const handleDateChange = (dates: Date[]) => {
		setFormState({
			...formState,
			['validTo']: new DateTime(new Date(dates[0])).getUnixTimestamp(),
		});
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<Column>
				<SectionTitle>{product.name}</SectionTitle>
				<Table.Table
					columns={[
						{ content: ' ', width: 'max-content' },
						{ content: t('Price'), width: 'auto' },
						{ content: t('Title'), width: 'auto' },
					]}>
					{activePrices.map((price: models.productPrice.ProductPrice) => {
						return (
							<Table.Row
								key={price.id}
								onClick={() => handlePriceChange(price.id)}>
								<Table.Cell>
									<Input.Control
										type="radio"
										name="priceId"
										value={price.id}
										standalone
										checked={price.id === formState.productPrice.id}
									/>
								</Table.Cell>
								<Table.Cell emphasized>{`${formatToCurrency(
									price.cost / 100,
									price.currency
								)} / ${formatPriceIntervalToText(
									price.recurringInterval,
									price.recurringIntervalCount
								)}`}</Table.Cell>

								<Table.Cell>{price.title}</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Table>
				<div>
					<Button
						icon="add"
						label={t('New price')}
						onClick={showPriceModal}
						secondary
					/>
				</div>
				<Row>
					<Input.Group label={t('Quantity')}>
						<Input.Field
							type="number"
							name="quantity"
							onChange={handleFormChange}
							value={formState.quantity}
						/>
					</Input.Group>
					<Input.Group label={t('TAX')}>
						<TaxSelect
							name="taxRateId"
							value={formState.taxRateId ? formState.taxRateId : null}
							handleChange={handleFormChange}
						/>
					</Input.Group>
				</Row>

				{Object.keys(formState.productPrice).length > 0 &&
					!formState.productPrice.recurring && (
						<Column>
							<Input.Group label={t('Valid until')}></Input.Group>
							<Input.Select
								name="validTo"
								value={validTo}
								data-testid="order.form.valid_to"
								onChange={handleValidToChange}>
								<option value="">{t('Forever')}</option>
								<option value="custom">{t('Custom')}</option>
							</Input.Select>
							{validTo === 'custom' && (
								<DatePicker
									dates={[new Date(formState.validTo * 1000)]}
									disableDatesBefore={new Date()}
									handleChange={handleDateChange}
								/>
							)}
						</Column>
					)}
			</Column>

			{modal === 'newPrice' && (
				<CreatePriceModal
					productId={productId}
					refreshProduct={refreshProduct}
					setPrice={setPrice}
					hideModal={hideModal}
				/>
			)}
		</Fragment>
	);
};

export default ProductOptions;
