import { t } from '@transifex/native';
import { Dispatch, Fragment } from 'react';
import styled from 'styled-components';
import { List, Record } from 'immutable';

import { PageWidths } from 'pkg/config/sizes';

import MatchCompetition from 'pkg/models/match_competition';

import useDateFilter from 'pkg/hooks/use-date-filter';
import DateTime, { Granularity } from 'pkg/datetime';

import {
	IFilters,
	ActionTypes,
	Action,
} from 'containers/group/statistics/Match';

import Toggle from 'components/Toggle';
import { SmallScreen, LargeScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';

import * as ContextMenu from 'design/context_menu';
import Button, { FilterButton } from 'design/button';

const ContextToggle = styled(ContextMenu.Item)`
	justify-content: space-between;
`;

interface CompetitionItemProps {
	competition: MatchCompetition;
	filters: Record<IFilters>;
	changeFilter: Dispatch<Action>;
}

const CompetitionItem: React.FC<
	React.PropsWithChildren<CompetitionItemProps>
> = ({ competition, filters, changeFilter }) => {
	const matchCompetitions = filters.get('matchCompetitionIds');
	const isApplied = matchCompetitions.includes(competition.id);

	const handleChange = () => {
		const ids = [...matchCompetitions];

		isApplied
			? ids.splice(
					ids.findIndex((cid) => cid === competition.id),
					1
				)
			: ids.push(competition.id);

		changeFilter({ type: ActionTypes.ChangeCompetitions, payload: ids });
	};

	return (
		<ContextToggle onClick={handleChange}>
			{competition.title}
			<Toggle active={matchCompetitions.includes(competition.id)} />
		</ContextToggle>
	);
};

interface FiltersProps {
	changeFilter: Dispatch<Action>;
	currentSeason?: { [key: string]: number };
	lastSeason?: { [key: string]: number };
	competitions: List<MatchCompetition>;
	filters: Record<IFilters>;
}

const Filters: React.FC<React.PropsWithChildren<FiltersProps>> = ({
	changeFilter,
	currentSeason,
	lastSeason,
	competitions,
	filters,
}) => {
	const dateTimeNow = DateTime.now();

	const { DateFilter } = useDateFilter({
		beforeDateChange: (dates) =>
			changeFilter({
				type: ActionTypes.HandleDateChange,
				payload: { before: dates.endOfRange, after: dates.startOfRange },
			}),
		customDatePresets: [
			{
				name: t(`{days} days`, { days: 90 }),
				startOfRangeDate: dateTimeNow
					.prev(Granularity.day, 90)
					.getUnixTimestamp(),
			},
			{
				name: t(`{days} days`, { days: 180 }),
				startOfRangeDate: dateTimeNow
					.prev(Granularity.day, 180)
					.getUnixTimestamp(),
			},
			{
				name: t(`Season`),
				startOfRangeDate: currentSeason.start,
			},
			{
				name: t(`Last season`),
				startOfRangeDate: lastSeason.start,
				endOfRangeDate: lastSeason.end,
			},
		],
	});

	const handleFriendlyChange = () => {
		changeFilter({ type: ActionTypes.ToggleFriendly });
	};

	const handleHomeChange = () => {
		changeFilter({ type: ActionTypes.ToggleHomeChange });
	};

	const handleAwayChange = () => {
		changeFilter({ type: ActionTypes.ToggleAwayChange });
	};

	const filterMenu = (
		<ContextMenu.Menu
			toggleWith={
				<Fragment>
					<LargeScreen>
						<Button icon="tune">{t('Filter')}</Button>
					</LargeScreen>
					<SmallScreen>
						<FilterButton />
					</SmallScreen>
				</Fragment>
			}>
			{competitions.size > 0 && (
				<Fragment>
					<ContextMenu.Label>{t(`Competitions`)}</ContextMenu.Label>

					{competitions.map((comp: MatchCompetition) => (
						<CompetitionItem
							key={comp.id}
							competition={comp}
							changeFilter={changeFilter}
							filters={filters}
						/>
					))}

					<ContextMenu.Divider />
				</Fragment>
			)}

			<ContextMenu.Label>{t(`Other`)}</ContextMenu.Label>

			<ContextToggle closeOnClick={false} onClick={handleFriendlyChange}>
				{t(`Friendly`)}
				<Toggle active={!filters.get('types').includes('default')} />
			</ContextToggle>

			<ContextToggle closeOnClick={false} onClick={handleHomeChange}>
				{t(`Home`)}
				<Toggle active={filters.get('isHome') === true} />
			</ContextToggle>

			<ContextToggle closeOnClick={false} onClick={handleAwayChange}>
				{t(`Away`)}
				<Toggle active={filters.get('isHome') === false} />
			</ContextToggle>
		</ContextMenu.Menu>
	);

	return (
		<Fragment>
			<LargeScreen>
				<ActionBar.FilterBar maxWidth={PageWidths.EXTRA_WIDE}>
					<ActionBar.PrimaryAction>
						<Fragment>
							{DateFilter}
							{filterMenu}
						</Fragment>
					</ActionBar.PrimaryAction>
				</ActionBar.FilterBar>
			</LargeScreen>
			<SmallScreen>
				<ActionBar.FilterBar>
					<ActionBar.PrimaryAction>
						<Fragment>
							{DateFilter}
							{filterMenu}
						</Fragment>
					</ActionBar.PrimaryAction>
				</ActionBar.FilterBar>
			</SmallScreen>
		</Fragment>
	);
};

export default Filters;
