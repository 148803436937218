import { createContext, ReactNode, useContext, useEffect } from 'react';

import { Capabilities } from 'pkg/models/membership';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';
import { Features } from 'pkg/api/models/group';
import { DefaultProviderSettings } from 'pkg/api/models/provider_settings';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';

interface PaymentProviderContext {
	settings?: models.providerSettings.ProviderSettings;
}

const ProviderSettingsContext = createContext<PaymentProviderContext>({
	settings: DefaultProviderSettings,
});

export function usePaymentProviderContext() {
	const ctx = useContext(ProviderSettingsContext);
	return ctx;
}

export function PaymentProviderContextProvider({
	children,
}: {
	children: ReactNode;
}) {
	const [values, setValues] = useMixedState<PaymentProviderContext>({
		settings: DefaultProviderSettings,
	});

	const group = useCurrentGroup();
	const membership = useCurrentMembership();

	const shouldLoad =
		group?.paymentProvider != models.group.PaymentProvider.Unset &&
		models.group.hasFeature(group, Features.Payments) &&
		models.group.isOrganization(group) &&
		models.membership.hasCapability(membership, Capabilities.PaymentAdmin);

	const { record: providerSettings, isLoading } =
		useEndpoint<models.providerSettings.ProviderSettings>(
			shouldLoad && endpoints.Groups.PaymentProviderSettings(group.id)
		);

	useEffect(() => {
		if (
			models.group.hasFeature(group, Features.Payments) &&
			models.group.isOrganization(group) &&
			models.membership.hasCapability(membership, Capabilities.PaymentAdmin) &&
			isLoading === false
		) {
			setValues({
				settings: providerSettings,
			});
		} else {
			setValues({
				settings: DefaultProviderSettings,
			});
		}
	}, [group?.id, isLoading]);

	return (
		<ProviderSettingsContext.Provider value={{ settings: values.settings }}>
			{children}
		</ProviderSettingsContext.Provider>
	);
}
