import { Fragment, useState } from 'react';
import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import {
	useCurrentGroup,
	useCurrentUser,
	useCurrentAccountWards,
} from 'pkg/identity';
import { isAnAdult } from 'pkg/api/models/user';

import SectionTitle from 'components/SectionTitle';
import * as CardList from 'components/card-list';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import JoinGroupModal from 'components/group/join-modal';
import Section from 'components/form/Section';
import Column from 'components/layout/column';

import Button from 'design/button';

interface Props {
	form: models.form.Form;
	onSelectUser: (user: models.user.User) => void;
	onSelectAccount: (user: models.account.Account) => void;
}

const SelectUser = ({ form, onSelectUser, onSelectAccount }: Props) => {
	const group = useCurrentGroup();
	const user = useCurrentUser();
	const wards = useCurrentAccountWards();
	const t = useT();

	const [showAddChildModal, setShowChildModal] = useState<boolean>(false);

	const children = wards
		.filter(
			(ar) =>
				ar.type === models.accountRelation.AccountRelationTypes.LegalGuardian
		)
		.map((ar) => ar.targetAccount);

	const handleSelectChild = (account: models.account.Account) => {
		onSelectUser(
			account.users.find(
				(user) => user.organizationId === models.group.getOrganizationId(group)
			)
		);
		onSelectAccount(account);
	};

	const handleAddChild = () => {
		setShowChildModal(true);
	};

	const handleCloseChildModal = () => {
		setShowChildModal(false);
	};

	return (
		<Fragment>
			<Column spacing={spacing._8}>
				<SectionTitle large>
					{t('Who should register for {title}?', { title: form.title })}
				</SectionTitle>
				<Column spacing={spacing._1}>
					{children.length > 0 && (
						<Column>
							<Section title={t('Your children')}>
								<Column>
									{children.map((child) => (
										<CardList.Base
											key={child.id}
											onClick={() => handleSelectChild(child)}>
											<CardList.Image>
												<Avatar account={child} />
											</CardList.Image>
											<CardList.Title>
												{models.account.fullName(child)}
												<CardList.SubTitle>{group.name}</CardList.SubTitle>
											</CardList.Title>
											<CardList.Actions>
												<Icon name="chevron" size={1.8} />
											</CardList.Actions>
										</CardList.Base>
									))}
									{group.allowInvites && (
										<Button
											block
											icon="person_add"
											onClick={handleAddChild}
											label={t('Add a new child')}
										/>
									)}
								</Column>
							</Section>
						</Column>
					)}
					<Section title={t('Yourself')} hideDivider={children.length === 0}>
						<Column>
							<CardList.Base onClick={() => onSelectUser(user)}>
								<CardList.Image>
									<Avatar user={user} />
								</CardList.Image>
								<CardList.Title>
									{models.user.fullName(user)}
									<CardList.SubTitle>{group.name}</CardList.SubTitle>
								</CardList.Title>
								<CardList.Actions>
									<Icon name="chevron" size={1.8} />
								</CardList.Actions>
							</CardList.Base>
							{isAnAdult(user) && children.length == 0 && (
								<Button
									block
									icon="person_add"
									onClick={handleAddChild}
									label={t('Add a new child')}
								/>
							)}
						</Column>
					</Section>
				</Column>
			</Column>

			{showAddChildModal && (
				<JoinGroupModal
					allowAccountCreate
					filterWardsInCurrentGroup
					autoJoinWithGroupCode
					onlyAccountCreate
					group={group}
					ignoreRedirect={true}
					onClose={handleCloseChildModal}
				/>
			)}
		</Fragment>
	);
};

export default SelectUser;
