// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E12jr4gU4E7oVLCJtQpZ {
	position: relative;
}
`, "",{"version":3,"sources":["webpack://./components/training/add-modal/session/styles.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB","sourcesContent":[".trigger {\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
export var trigger = `E12jr4gU4E7oVLCJtQpZ`;
export default ___CSS_LOADER_EXPORT___;
