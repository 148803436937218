import { t } from '@transifex/native';
import { Fragment } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import DateTime from 'pkg/datetime';
import { FilterOperator } from 'pkg/filters';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import { Dates } from 'pkg/hooks/use-date-filter';
import { getAmountOfDaysBetweenDates } from 'pkg/date';

import AverageAttendanceWidget from 'routes/statistics/attendance/overview/data-cards/average';
import AttendanceBreakdownWidget from 'routes/statistics/attendance/overview/data-cards/breakdown';
import ActivitiesTypeWidget from 'routes/statistics/attendance/overview/data-cards/activities';
import AbsenceReasonWidget from 'routes/statistics/attendance/overview/data-cards/absence';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { GroupSelectModal } from 'components/group/select_modal';
import * as ActionBar from 'components/layout/ActionBar';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import { Spinner } from 'components/loaders/spinner';

import * as css from './styles.css';

interface AttendanceOverviewProps {
	groupId: number;
	dateFilter: JSX.Element;
	dateRange: Dates;
}

export default function AttendanceOverview({
	groupId,
	dateFilter,
	dateRange,
}: AttendanceOverviewProps) {
	const filterGroups: FilterGroups = {
		[t('Groups')]: {
			filters: {
				['Groups']: {
					type: 'modal',
					property: 'group_ids',
					operator: FilterOperator.Includes,
					settings: {
						modal: <GroupSelectModal />,
						buttonLabel: t('Select groups'),
						hideButton: false,
					},
				},
			},
		},
	};

	const filters = useFilters({
		groups: filterGroups,
	});

	const fromDate = new Date(dateRange.startOfRange * 1000);
	const toDate = new Date(dateRange.endOfRange * 1000);
	const daysBetween = getAmountOfDaysBetweenDates(fromDate, toDate);

	let localeOptions: Intl.DateTimeFormatOptions = {
		month: 'long',
	};

	if (daysBetween <= 8) {
		localeOptions = { weekday: 'long' };
	} else if (daysBetween < 60) {
		localeOptions = { day: 'numeric', month: 'short' };
	}

	const fromDateTime = DateTime.fromTimestamp(dateRange.startOfRange);
	const toDateTime = DateTime.fromTimestamp(dateRange.endOfRange);

	filters.queryParam.set('from', dateRange.startOfRange.toString());
	filters.queryParam.set('to', dateRange.endOfRange.toString());

	const { record: dashboardData, isLoading } =
		useEndpoint<models.eventReport.AttendanceDashboardData>(
			endpoints.EventReport.AttendanceOverview(groupId),
			{
				queryParams: filters.queryParam,
			}
		);

	const averageAttendanceWidget = (
		<AverageAttendanceWidget
			dashboardData={dashboardData}
			fromDateTime={fromDateTime}
			toDateTime={toDateTime}
			localeOptions={localeOptions}
		/>
	);

	const attendanceBreakdownWidget = (
		<AttendanceBreakdownWidget dashboardData={dashboardData} />
	);

	const activitiesTypeWidget = (
		<ActivitiesTypeWidget dashboardData={dashboardData} />
	);

	const absenceReasonWidget = (
		<AbsenceReasonWidget
			dashboardData={dashboardData}
			fromDateTime={fromDateTime}
			toDateTime={toDateTime}
			localeOptions={localeOptions}
		/>
	);

	const content = (
		<Column>
			<LargeScreen>
				<Row align="stretch">
					{averageAttendanceWidget}
					{attendanceBreakdownWidget}
				</Row>
				<Row align="stretch">
					{activitiesTypeWidget}
					{absenceReasonWidget}
				</Row>
			</LargeScreen>
			<SmallScreen>
				{averageAttendanceWidget}
				{attendanceBreakdownWidget}
				{activitiesTypeWidget}
				{absenceReasonWidget}
			</SmallScreen>
		</Column>
	);

	if (isLoading) {
		return (
			<div className={css.wrapper}>
				<Column>
					<div className={css.textWrapper}>
						<div>{t('Fetching attendance data')}</div>
						<span>
							{t('Loading your report, this might take a few seconds...')}
						</span>
					</div>
					<Spinner />
				</Column>
			</div>
		);
	}

	return (
		<Fragment>
			<ActionBar.FilterBar>
				{dateFilter}
				{filters.Component}
			</ActionBar.FilterBar>

			<LargeScreenContent.Inner maxWidth={PageWidths.EXTRA_WIDE}>
				{content}
			</LargeScreenContent.Inner>
		</Fragment>
	);
}
