interface SourceUrlInterface {
	isValid(): boolean;

	getSourceUrl(): string;
}

export enum SourceType {
	YouTube = 'youtube',
	Vimeo = 'vimeo',
	Video = 'video',
	Veo = 'veo',
}

abstract class SourceUrl implements SourceUrlInterface {
	protected abstract regexMatchSourceUrl: RegExp;
	protected sourceUrl: string;

	constructor(sourceUrl: string) {
		this.sourceUrl = sourceUrl;
	}

	public abstract get type(): string;

	public isValid(): boolean {
		const regex: RegExp = new RegExp(this.regexMatchSourceUrl);

		return regex.test(this.sourceUrl);
	}

	public getSourceUrl(): string {
		return this.sourceUrl;
	}
}

export class YouTubeUrl extends SourceUrl {
	protected regexMatchSourceUrl: RegExp =
		/^((?:https?:)?\/{2})?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

	public get type(): string {
		return 'youtube';
	}

	public getSourceId(): string {
		const matches: string[] = this.sourceUrl.match(this.regexMatchSourceUrl);
		const videoId: string = matches[5];

		return videoId;
	}
}

export function isValidYouTubeSource(sourceUrl: string): boolean {
	const source = new YouTubeUrl(sourceUrl);

	return source.isValid();
}

export class VimeoUrl extends SourceUrl {
	protected regexMatchSourceUrl: RegExp =
		/^((?:https?:)?\/{2})?((?:www|m)\.)?vimeo.com\/([0-9]{9,})(?:(\/\w+|\?h=\w+))?$/;

	public get type(): string {
		return 'vimeo';
	}

	public getSourceId(): number {
		const matches: string[] = this.sourceUrl.match(this.regexMatchSourceUrl);
		const videoId: number = Number.parseInt(matches[3], 10);

		return videoId;
	}
}

export function isValidVimeoSource(sourceUrl: string): boolean {
	const source = new VimeoUrl(sourceUrl);

	return source.isValid();
}

export class VideoUrl extends SourceUrl {
	protected regexMatchSourceUrl: RegExp =
		/^(?:https?:\/{2})?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+\%,;=.]+(\?(?:&?[^=&]*=[^=&]*)*)?$/;

	public get type(): string {
		return 'video';
	}

	public isValid(): boolean {
		let valid: boolean = false;

		const regex: RegExp = new RegExp(this.regexMatchSourceUrl);
		const maybeValidUrl: boolean = regex.test(this.sourceUrl);

		if (maybeValidUrl) {
			try {
				const url: URL = new URL(this.sourceUrl);

				const regexWithoutPossibleQueryString: RegExp = new RegExp(
					this.regexMatchSourceUrl
				);

				valid = regexWithoutPossibleQueryString.test(
					`${url.origin}${url.pathname}`
				);
			} catch (error) {
				valid = false;
			}
		}

		return valid;
	}
}

export function isValidVeoSource(sourceUrl: string): boolean {
	return sourceUrl.startsWith('http') && sourceUrl.includes('app.veo.co');
}

export function isValidVideoSource(sourceUrl: string): boolean {
	const source = new VideoUrl(sourceUrl);

	return source.isValid();
}

export function typeOfSource(sourceUrl: string): SourceType {
	if (isValidYouTubeSource(sourceUrl)) {
		return SourceType.YouTube;
	} else if (isValidVimeoSource(sourceUrl)) {
		return SourceType.Vimeo;
	} else if (isValidVeoSource(sourceUrl)) {
		return SourceType.Veo;
	} else {
		return SourceType.Video;
	}
}

export function isValidSource(sourceUrl: string): boolean {
	const validityChecks: boolean[] = [
		isValidYouTubeSource(sourceUrl),
		isValidVimeoSource(sourceUrl),
		isValidVeoSource(sourceUrl),
		isValidVideoSource(sourceUrl),
	];

	return validityChecks.some((isValid: boolean) => isValid === true);
}
