// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tpycT_ajFrublUmPR4h7 {
	line-height: var(--font-line-height-lg);
	color: var(--palette-white);
}

.tpycT_ajFrublUmPR4h7 a {
		color: var(--palette-white);
		border-bottom: 1px solid var(--palette-white);
	}

.qFwgEhwycd8nSCEwT4sJ.FhokwL2uRb5nu91FEG7n {
		-webkit-backdrop-filter: blur(5px);
		        backdrop-filter: blur(5px);
		padding-bottom: var(--spacing-4);
		position: sticky;
		bottom: calc(-24px + env(safe-area-inset-top));
		left: 0;
		width: 100%;
	}

.qFwgEhwycd8nSCEwT4sJ._dDr1_buNvsLUjHZ1tsJ {
		line-height: var(--font-line-height-lg);
		color: var(--palette-yellow-500);
	}

.JudSCGAAWFGOdPsms5ct {
	margin-top: auto;
}

.jQq1krhVYHlHjKr_SNg5 {
	margin-top: auto;
	padding-top: var(--spacing-7);
}
`, "",{"version":3,"sources":["webpack://./routes/public/styles/styles.css"],"names":[],"mappings":"AAAA;CACC,uCAAuC;CACvC,2BAA2B;AAM5B;;AAJC;EACC,2BAA2B;EAC3B,6CAA6C;CAC9C;;AAIA;EACC,kCAA0B;UAA1B,0BAA0B;EAC1B,gCAAgC;EAChC,gBAAgB;EAChB,8CAA8C;EAC9C,OAAO;EACP,WAAW;CACZ;;AAEA;EACC,uCAAuC;EACvC,gCAAgC;CACjC;;AAGD;CACC,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;CAChB,6BAA6B;AAC9B","sourcesContent":[".text {\n\tline-height: var(--font-line-height-lg);\n\tcolor: var(--palette-white);\n\n\t& a {\n\t\tcolor: var(--palette-white);\n\t\tborder-bottom: 1px solid var(--palette-white);\n\t}\n}\n\n.gdpr {\n\t&.action {\n\t\tbackdrop-filter: blur(5px);\n\t\tpadding-bottom: var(--spacing-4);\n\t\tposition: sticky;\n\t\tbottom: calc(-24px + env(safe-area-inset-top));\n\t\tleft: 0;\n\t\twidth: 100%;\n\t}\n\n\t&.timestamp {\n\t\tline-height: var(--font-line-height-lg);\n\t\tcolor: var(--palette-yellow-500);\n\t}\n}\n\n.main {\n\tmargin-top: auto;\n}\n\n.footer {\n\tmargin-top: auto;\n\tpadding-top: var(--spacing-7);\n}\n"],"sourceRoot":""}]);
// Exports
export var text = `tpycT_ajFrublUmPR4h7`;
export var gdpr = `qFwgEhwycd8nSCEwT4sJ`;
export var action = `FhokwL2uRb5nu91FEG7n`;
export var timestamp = `_dDr1_buNvsLUjHZ1tsJ`;
export var main = `JudSCGAAWFGOdPsms5ct`;
export var footer = `jQq1krhVYHlHjKr_SNg5`;
export default ___CSS_LOADER_EXPORT___;
