import { Fragment } from 'react';
import { t } from '@transifex/native';

import { Capabilities } from 'pkg/models/membership';
import { Features } from 'pkg/models/group';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';

import UnreadChatMessagesBadge from 'containers/chat/UnreadChatMessagesBadge';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import GroupLinkFolders from 'components/navigation/menus/group_link_folders';
import { NavigationItemsProps } from 'components/navigation/menus/NavigationItems';

import * as Drawer from 'design/navigation/drawer';

const _context = 'navigation';

export default function OrganizationNavigation({
	displayChildren,
	handleDisplayChildren,
}: NavigationItemsProps): JSX.Element {
	const membership = useCurrentMembership();
	const group = useCurrentGroup();
	const paymentProvider = usePaymentProviderContext();
	const groupHasCustomLinksEnabled =
		group && models.group.hasFeature(group, models.group.Features.CustomLinks);
	const org = useCurrentOrganization();

	return (
		<Fragment>
			<LargeScreen>
				<Drawer.ItemGroup>
					<Drawer.Item
						href={routes.Organization.Home(org.id)}
						name={t('Home')}
						icon="home"
					/>
					<Drawer.Item
						href={routes.Calendar.Index(
							org.id,
							membership.groupId,
							sessionStorage.getItem('currentMode') || 'month'
						)}
						name={t('Calendar')}
						icon="calendar_today"
						testid="navigation.calendar"
						show={models.group.hasFeature(group, Features.Calendar)}
					/>
					<Drawer.Item
						href={routes.Chat.Index(org.id)}
						name={t('Chat')}
						icon="forum"
						testid="navigation.chat"
						show={models.group.hasFeature(group, Features.Chat)}>
						<UnreadChatMessagesBadge inDrawer />
					</Drawer.Item>
				</Drawer.ItemGroup>
			</LargeScreen>
			<Drawer.ItemGroup
				title={t('Organization management')}
				show={
					models.group.hasAnyFeature(group, Features.Chat, Features.Posts) ||
					models.group.hasFeature(group, Features.Forms)
				}>
				<SmallScreen>
					<Drawer.Item
						href={routes.Group.Wall(org.id, group.id)}
						name={t('Wall', { _context })}
						icon="feed"
						show={models.group.hasFeature(group, Features.Posts)}
					/>
				</SmallScreen>
				<LargeScreen>
					<Drawer.Item
						href={routes.Group.Show(org.id, membership.groupId)}
						name={t('Team')}
						icon="groups"
					/>
				</LargeScreen>
				<Drawer.ItemGroupCollapsible
					testid="navigation.management"
					title={t('Management')}
					slug="management"
					expand={displayChildren}
					onClick={handleDisplayChildren}
					icon="lan">
					<Drawer.Item
						href={routes.Management.Overview(org.id)}
						name={t('Overview')}
					/>
					<Drawer.Item
						href={routes.Management.Organization(org.id)}
						name={t('Organization')}
					/>
					<Drawer.Item
						testid="navigation.contacts"
						href={routes.Management.Contacts.Home(org.id)}
						name={t('Contacts')}
					/>
				</Drawer.ItemGroupCollapsible>
				<Drawer.ItemGroupCollapsible
					title={t('Payments')}
					slug="payments"
					expand={displayChildren}
					onClick={handleDisplayChildren}
					icon="monetization_on"
					show={
						models.membership.hasCapability(
							membership,
							Capabilities.PaymentAdmin
						) &&
						models.group.hasFeature(group, Features.Payments) &&
						models.group.hasActivePaymentProvider(group)
					}>
					<Drawer.Item
						href={routes.Payments.Overview(org.id)}
						name={t('Overview')}
					/>
					<Drawer.Item
						href={routes.Payments.Orders(org.id)}
						name={t('Invoices')}
					/>
					<Drawer.Item
						href={routes.Payments.Subscriptions(org.id)}
						name={t('Subscriptions')}
					/>
					<Drawer.Item
						href={routes.Products.Index(org.id)}
						name={t('Products')}
					/>
					<Drawer.Item
						href={routes.Payments.Payouts(org.id)}
						name={t('Payouts')}
					/>
					<Drawer.Item
						href={routes.Discounts.Index(org.id)}
						name={t('Discounts')}
						show={paymentProvider.settings.canHaveDiscountCode}
					/>
				</Drawer.ItemGroupCollapsible>
				<Drawer.Item
					href={routes.Registrations.Index(
						org.id,
						membership.groupId,
						'active'
					)}
					icon="contact_page"
					name={t('Registrations')}
					testid="navigation.forms"
					show={
						models.membership.hasCapability(membership, Capabilities.Forms) &&
						models.group.hasFeature(group, Features.Forms)
					}
				/>
				<Drawer.Item
					href={routes.Group.Post.Index(
						org.id,
						membership.groupId,
						'published'
					)}
					name={t('Posts')}
					icon="dynamic_feed"
					show={models.group.hasFeature(group, Features.Posts)}
				/>

				<Drawer.ItemGroupCollapsible
					title={t('Scheduling')}
					slug="scheduling"
					expand={displayChildren}
					onClick={handleDisplayChildren}
					icon="schedule"
					show={
						models.membership.hasCapability(
							membership,
							Capabilities.Scheduler
						) && models.group.hasFeature(group, Features.Scheduling)
					}>
					<Drawer.Item
						href={routes.Bookings.Index(org.id, membership.groupId)}
						name={t('Bookings')}
					/>
					<Drawer.Item
						href={routes.Templates.Index(org.id, membership.groupId)}
						name={t('Templates')}
					/>
					<Drawer.Item
						href={routes.Resources.Index(
							org.id,
							membership.groupId,
							'overview'
						)}
						name={t('Resources')}
					/>
				</Drawer.ItemGroupCollapsible>
				<Drawer.ItemGroupCollapsible
					title={t('Development')}
					slug="development"
					expand={displayChildren}
					onClick={handleDisplayChildren}
					icon="tactic"
					show={models.group.hasAnyFeature(
						group,
						Features.Video,
						Features.TrainingLibrary
					)}>
					<Drawer.Item
						href={routes.TrainingLibrary.Index(org.id, membership.groupId)}
						name={t('Training library')}
						show={models.group.hasFeature(group, Features.TrainingLibrary)}
					/>
					<Drawer.Item
						href={routes.VideoLibrary.Index(org.id, membership.groupId)}
						name={t('Videos')}
						testid="navigation.video_library"
						show={models.group.hasFeature(group, Features.Video)}
					/>
				</Drawer.ItemGroupCollapsible>
				<Drawer.ItemGroupCollapsible
					title={t('Statistics')}
					icon="bar_chart"
					slug="statistics"
					expand={displayChildren}
					onClick={handleDisplayChildren}>
					<Drawer.Item
						href={routes.Statistics.AttendanceReport.Show(
							org.id,
							membership.groupId,
							'overview'
						)}
						name={t('Attendance')}
					/>
				</Drawer.ItemGroupCollapsible>
				<Drawer.ItemGroupCollapsible
					title={t('Settings')}
					slug="settings"
					expand={displayChildren}
					onClick={handleDisplayChildren}
					icon="settings">
					<Drawer.Item
						href={routes.Settings.Index(org.id, membership.groupId, 'general')}
						name={t('General')}
					/>
					<Drawer.Item
						href={routes.Settings.Users(org.id, membership.groupId, 'all')}
						name={t('Users')}
					/>
					<Drawer.Item
						href={routes.Settings.Competitions(org.id, membership.groupId)}
						name={t('Competitions')}
					/>
					<Drawer.Item
						href={routes.Settings.Index(
							org.id,
							membership.groupId,
							'user-fields'
						)}
						name={t('User fields')}
					/>
					<Drawer.Item
						href={routes.Settings.Index(org.id, membership.groupId, 'lok')}
						name={t('LOK')}
						show={group.country?.code === 'SE'}
					/>
					<Drawer.Item
						href={routes.Settings.Index(
							org.id,
							membership.groupId,
							'custom-links'
						)}
						name={t('Custom links')}
						show={groupHasCustomLinksEnabled}
					/>
					<Drawer.Item
						href={routes.Organization.Settings.Sso(org.id)}
						name={t('Single sign-on')}
						show={models.group.hasFeature(
							group,
							models.group.Features.SingleSignOn
						)}
					/>
				</Drawer.ItemGroupCollapsible>
			</Drawer.ItemGroup>

			{groupHasCustomLinksEnabled && (
				<GroupLinkFolders
					displayChildren={displayChildren}
					handleDisplayChildren={handleDisplayChildren}
				/>
			)}
		</Fragment>
	);
}
