import React from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as helpScout from 'pkg/helpscout';
import * as models from 'pkg/api/models';
import {
	useCurrentMemberships,
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentAccount,
} from 'pkg/identity';

import CardBase, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import ClubContactInformation from 'components/group/club-contact-information';
import Column from 'components/layout/column';

import Button from 'design/button';

function Support() {
	const currentMembership = useCurrentMembership();
	const memberships = useCurrentMemberships();
	const organization = useCurrentOrganization();
	const account = useCurrentAccount();

	const organizationMembership = memberships.find(
		(m) => m.groupId === organization.id
	);

	let isAdminInOrg = false;

	if (organizationMembership) {
		isAdminInOrg = models.membership.isAdmin(organizationMembership);
	}

	const isAdminOrStaff = models.membership.isAdminOrStaff(currentMembership);

	const openHelp = () => {
		helpScout.openSupport({ account, membership: currentMembership });
	};

	const openBugReport = () => {
		helpScout.openBugReport({ account, membership: currentMembership });
	};

	const openPaymentSupport = () => {
		helpScout.openPaymentSupport({ account, membership: currentMembership });
	};

	return (
		<Column spacing={styles.spacing._7}>
			<Column>
				<SectionTitle icon="credit_card">
					{t(`Billing and payment questions`)}
				</SectionTitle>
				<CardBase $noBorder>
					<Card.Body>
						<Column>
							<Card.Heading>{t(`Navigating financial queries`)}</Card.Heading>
							{t(
								`Encounter an issue with billing or payments? Reach out to your club administrator for swift assistance on inquiries related to invoices, payment failures, and other financial aspects.`
							)}
							<ClubContactInformation skipHeader align="start" />
						</Column>
					</Card.Body>
				</CardBase>
			</Column>
			{isAdminInOrg && (
				<Column>
					<SectionTitle icon="contact_support">
						{t(`Club admin support`)}
					</SectionTitle>
					<CardBase $noBorder>
						<Card.Body>
							<Column>
								<Card.Heading>
									{t(`Specialized assistance for club coordinators`)}
								</Card.Heading>

								{t(
									`Are you overseeing financial transaction or member management within our platform? Our club admin support is designed for you, providing specialized help for anything you might need when managing your organization.`
								)}

								<Button secondary onClick={openPaymentSupport} icon="comment">
									{t(`Start a support chat`)}
								</Button>
							</Column>
						</Card.Body>
					</CardBase>
				</Column>
			)}
			<Column>
				<SectionTitle icon="info">{t(`Help articles`)}</SectionTitle>
				<CardBase $noBorder>
					<Card.Body>
						<Column>
							<Card.Heading>{t(`Your self-service support hub`)}</Card.Heading>

							{t(
								`Browse our comprehensive Help Center to find detailed articles, step-by-step guides, and FAQs that provide solutions and insights into using our platform effectively.`
							)}
							<Button
								secondary
								icon="new_window"
								href="https://help.360player.com/">
								{t(`Visit the Help Center`)}
							</Button>
						</Column>
					</Card.Body>
				</CardBase>
			</Column>
			{isAdminOrStaff && (
				<Column>
					<SectionTitle icon="play_circle">{t(`Video tutorials`)}</SectionTitle>
					<CardBase $noBorder>
						<Card.Body>
							<Column>
								<Card.Heading>
									{t(`Visual learning for effective usage`)}
								</Card.Heading>

								{t(
									`Dive into our collection of detailed video tutorials. Whether you're just starting out or seeking advanced tips, our visual guides are here to help you master the platform's features.`
								)}
								<Button
									secondary
									icon="new_window"
									href="https://learn.360player.com/">
									{t(`Browse video tutorials`)}
								</Button>
							</Column>
						</Card.Body>
					</CardBase>
				</Column>
			)}
			{!isAdminInOrg && (
				<Column>
					<SectionTitle icon="contact_support">
						{t(`Contact support`)}
					</SectionTitle>
					<CardBase $noBorder>
						<Card.Body>
							<Column>
								<Card.Heading>
									{t(`Resolving technical roadblocks`)}
								</Card.Heading>

								{t(
									`For questions about account management, login issues, or platform functionalities, our Technical Support is ready to assist. Get back on track by connecting with our tech experts.`
								)}

								<Button secondary onClick={openHelp} icon="comment">
									{t(`Start a support chat`)}
								</Button>
							</Column>
						</Card.Body>
					</CardBase>
				</Column>
			)}
			<Column data-testid="support_page.report_bug">
				<SectionTitle icon="bug_report">{t(`Report a bug`)}</SectionTitle>
				<CardBase $noBorder>
					<Card.Body>
						<Column>
							<Card.Heading>
								{t(`Your feedback improves our platform`)}
							</Card.Heading>

							{t(
								`Encountered a glitch or technical hiccup? Your experience matters, and we're here to make it right. Report any bugs or issues, and contribute to enhancing our platform's reliability.`
							)}
							<Button secondary onClick={openBugReport} icon="mail">
								{t(`Report a bug`)}
							</Button>
						</Column>
					</Card.Body>
				</CardBase>
			</Column>
		</Column>
	);
}

export default Support;
