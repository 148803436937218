import SubscriptionIndex from 'routes/payments/subscriptions/list';

import { TableContentWrapper } from 'components/layout/PageTemplates';

interface RegistrationSubscriptionsProps {
	formId: number;
	organizationId: number;
}

export default function RegistrationSubscriptions({
	organizationId,
	formId,
}: RegistrationSubscriptionsProps) {
	return (
		<TableContentWrapper>
			<SubscriptionIndex
				organizationId={organizationId}
				formId={formId}
				disableNewSubscription
			/>
		</TableContentWrapper>
	);
}
