// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ONMbNCwmxTiECgXyzWkH {
	margin-bottom: var(--spacing-3);
}
`, "",{"version":3,"sources":["webpack://./components/payment_platform/payments/orders_action_bar/styles.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;AAChC","sourcesContent":[".arrowIcon {\n\tmargin-bottom: var(--spacing-3);\n}\n"],"sourceRoot":""}]);
// Exports
export var arrowIcon = `ONMbNCwmxTiECgXyzWkH`;
export default ___CSS_LOADER_EXPORT___;
