export function AccountIndex(accountID: number): string {
	return `/v1/accounts/${accountID}/exercises`;
}

export function AddAttachment(ID: number, attachmentID: number): string {
	return `/v1/exercises/${ID}/attachments/${attachmentID}`;
}

export function AddTag(ID: number, tagID: number): string {
	return `/v1/exercises/${ID}/tags/${tagID}`;
}

export function Create(): string {
	return `/v1/exercises`;
}

export function CreateDrawing(): string {
	return `/v1/exercises/drawings`;
}

export function Delete(ID: number): string {
	return `/v1/exercises/${ID}`;
}

export function DeleteDrawing(ID: number): string {
	return `/v1/exercises/drawings/${ID}`;
}

export function Duplicate(ID: number): string {
	return `/v1/exercises/${ID}/duplicate`;
}

export function GroupIndex(groupID: number): string {
	return `/v1/groups/${groupID}/exercises`;
}

export function Index(): string {
	return `/v1/exercises`;
}

export function RemoveAttachment(ID: number, attachmentID: number): string {
	return `/v1/exercises/${ID}/attachments/${attachmentID}`;
}

export function RemoveTag(ID: number, tagID: number): string {
	return `/v1/exercises/${ID}/tags/${tagID}`;
}

export function Show(ID: number): string {
	return `/v1/exercises/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/exercises/${ID}`;
}

export function UpdateDrawing(ID: number): string {
	return `/v1/exercises/drawings/${ID}`;
}
