// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buk1FzU0U6W3tp9ABSEc {
	--bg: var(--palette-white);

	position: relative;
	background-color: var(--bg);
	background-image: linear-gradient(
			45deg,
			hsla(0, 0%, 0%, 0.3) 25%,
			transparent 25%
		),
		linear-gradient(-45deg, hsla(0, 0%, 0%, 0.3) 25%, transparent 25%),
		linear-gradient(45deg, transparent 75%, hsla(0, 0%, 0%, 0.3) 75%),
		linear-gradient(-45deg, transparent 75%, hsla(0, 0%, 0%, 0.3) 75%);
	background-size: 30px 30px;
	background-position:
		0 0,
		0 15px,
		15px -15px,
		-15px 0;
}

.fD5Bs1WQmScO5iki8mKz {
	background: transparent;
}
`, "",{"version":3,"sources":["webpack://./pkg/hooks/image-editor/style.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;;CAE1B,kBAAkB;CAClB,2BAA2B;CAC3B;;;;;;;oEAOoE;CACpE,0BAA0B;CAC1B;;;;SAIQ;AACT;;AAEA;CACC,uBAAuB;AACxB","sourcesContent":[".wrapper {\n\t--bg: var(--palette-white);\n\n\tposition: relative;\n\tbackground-color: var(--bg);\n\tbackground-image: linear-gradient(\n\t\t\t45deg,\n\t\t\thsl(0deg 0% 0% / 30%) 25%,\n\t\t\ttransparent 25%\n\t\t),\n\t\tlinear-gradient(-45deg, hsl(0deg 0% 0% / 30%) 25%, transparent 25%),\n\t\tlinear-gradient(45deg, transparent 75%, hsl(0deg 0% 0% / 30%) 75%),\n\t\tlinear-gradient(-45deg, transparent 75%, hsl(0deg 0% 0% / 30%) 75%);\n\tbackground-size: 30px 30px;\n\tbackground-position:\n\t\t0 0,\n\t\t0 15px,\n\t\t15px -15px,\n\t\t-15px 0;\n}\n\n.transparent {\n\tbackground: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `buk1FzU0U6W3tp9ABSEc`;
export var transparent = `fD5Bs1WQmScO5iki8mKz`;
export default ___CSS_LOADER_EXPORT___;
