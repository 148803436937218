import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { useCurrentRoute } from 'pkg/router/hooks';
import { UrlParser } from 'pkg/url';

import { LargeScreen } from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import { LargeSubNavItems } from 'components/layout/PageTemplates';

const url = new UrlParser();

interface AttendanceViewsNavigationProps {
	title: string;
	icon: MaterialSymbolVariant;
	groupId: number;
}

export function useAttendanceNavigation(groupId: number) {
	const org = useCurrentOrganization();
	const currentGroup = useCurrentGroup();
	const groupHasChildren = models.group.hasChildren(currentGroup);
	const route = useCurrentRoute();

	const query: { [key: string]: string } = {};

	if (route.query?.dateLabel) {
		query.dateLabel = route.query.dateLabel;
	}

	if (route.query?.startOfRange) {
		query.startOfRange = route.query.startOfRange;
	}

	if (route.query?.endOfRange) {
		query.endOfRange = route.query.endOfRange;
	}

	let pageSubNavItems = [];

	if (groupHasChildren) {
		pageSubNavItems.push({
			title: t('Group report'),
			href: url.transform(
				routes.Statistics.AttendanceReport.Show(org.id, groupId, 'group'),
				query
			),
		});
	}

	pageSubNavItems = [
		{
			title: t('Overview'),
			href: url.transform(
				routes.Statistics.AttendanceReport.Show(org.id, groupId, 'overview'),
				query
			),
		},
		...pageSubNavItems,
		{
			title: t('Individual report'),
			href: url.transform(
				routes.Statistics.AttendanceReport.Show(
					org.id,
					groupId,
					'individual-report'
				),
				query
			),
		},
		{
			title: t('Event report'),
			href: url.transform(
				routes.Statistics.AttendanceReport.Show(
					org.id,
					groupId,
					'event-report'
				),
				query
			),
		},
	];

	return pageSubNavItems;
}

export default function AttendanceLargeScreenNavigation({
	title,
	icon,
	groupId,
}: AttendanceViewsNavigationProps) {
	const pageSubNavItems = useAttendanceNavigation(groupId);

	return (
		<LargeScreen>
			<LargeScreenHeader title={title} icon={icon}>
				<LargeSubNavItems items={pageSubNavItems} />
			</LargeScreenHeader>
		</LargeScreen>
	);
}
