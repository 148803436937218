import { useState } from 'react';
import { t } from '@transifex/native';

import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { CreateFormPayload } from 'pkg/api/endpoints/forms';
import { FormProductPrice } from 'pkg/api/models/form';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import useFormEngine from 'routes/forms/hooks/useFormEngine';
import SuccessView from 'routes/forms/SuccessView';

import FormComponent, { FormPayload } from 'components/form/Form';

interface CreateFormProps {
	groupId: number;
}

const CreateForm: React.FC<React.PropsWithChildren<CreateFormProps>> = ({
	groupId,
}) => {
	const org = useCurrentOrganization();
	const [form, setForm] = useState<models.form.Form>({} as models.form.Form);
	const providerSettings = usePaymentProviderContext();

	const { formData, FormContent } = useFormEngine({});

	const handleSave = async (data: FormPayload) => {
		const payload: CreateFormPayload = {
			groupId,
			title: data.title as string,
			description: data.description as string,
			status: data.status as models.form.Statuses,
			fields: formData.fields,
			productPrices: [],
			visibility: data.visibility as string,
			submissionEmailEnabled: formData.submissionEmailEnabled,
			submissionEmailSubject: formData.submissionEmailSubject,
			submissionEmailContent: formData.submissionEmailContent,
			forceAuth: data.forceAuth as boolean,
		};

		if (data.maxSubmissions) {
			payload.maxSubmissions = Number.parseInt(
				data.maxSubmissions as string,
				10
			);
		}

		if (data.formCategoryId) {
			payload.formCategoryId = Number.parseInt(
				data.formCategoryId as string,
				10
			);
		}

		if (Object.keys(formData.attachment).length) {
			payload.attachmentId = formData.attachment.id;
		}

		formData.priceOptions.forEach((priceOption, index) => {
			const newProductPrices: FormProductPrice[] =
				priceOption.productPrices.map((productPrice) => {
					const productInfo = formData.selectedProductsInfo.find(
						(prodInfo) => prodInfo.product.id === productPrice.product.id
					);

					const formProductPrice: FormProductPrice = {
						productPriceId: productPrice.id,
						minQuantity: productInfo.minQuantity,
						maxQuantity: productInfo.maxQuantity,
						required: productInfo.required,
						validTo: productInfo.validTo,
						taxRateId: productInfo.taxRateId,

						bundleNumber: index + 1,
						installmentCount: priceOption.installmentCount,
					};

					if (providerSettings.settings.canHaveDelayedSubscriptionStart) {
						if (priceOption.subscriptionStartAt) {
							formProductPrice.subscriptionStartAt =
								priceOption.subscriptionStartAt;
							formProductPrice.payFirstOrderNow = true;
						}
					}

					return formProductPrice;
				});

			payload.productPrices = [...payload.productPrices, ...newProductPrices];
		});

		const [, form] = await models.create<CreateFormPayload, models.form.Form>(
			endpoints.Forms.Create(),
			payload
		);

		setForm(form);
	};

	const handlePublish = async () => {
		await actions.forms.update(form.id, {
			status: models.form.Statuses.Open,
			fields: form.fields,
			title: form.title,
		});

		actions.flashes.show(
			{
				title: t('Published!'),
				message: t('Registration published successfully'),
			},
			200,
			'check-circle'
		);

		replaceState(routes.Registrations.Index(org.id, groupId, 'active'));
	};

	if (form.id) {
		return (
			<SuccessView
				groupId={groupId}
				form={form}
				handlePublish={handlePublish}
			/>
		);
	}

	return <FormComponent onSubmit={handleSave}>{FormContent}</FormComponent>;
};

export default CreateForm;
