// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ari1D8c4pqLA8zd_U_qn {
	min-height: 0;
	position: relative;
	padding: var(--spacing-7) var(--spacing-5) var(--spacing-8);
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
`, "",{"version":3,"sources":["webpack://./routes/wall/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,kBAAkB;CAClB,2DAA2D;CAC3D,cAAc;CACd,iCAAiC;AAClC","sourcesContent":[".largeScreenWrapper {\n\tmin-height: 0;\n\tposition: relative;\n\tpadding: var(--spacing-7) var(--spacing-5) var(--spacing-8);\n\toverflow: auto;\n\t-webkit-overflow-scrolling: touch;\n}\n"],"sourceRoot":""}]);
// Exports
export var largeScreenWrapper = `Ari1D8c4pqLA8zd_U_qn`;
export default ___CSS_LOADER_EXPORT___;
