// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VwM9yy4bbvuA5In5LTUr {
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
}

.INTg_BbbGZvrhL_7vINs {
	background: var(--palette-blue-200);
	color: var(--palette-blue-500);
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
	border-radius: 100px;
	padding: var(--spacing-3);
}

.INTg_BbbGZvrhL_7vINs svg {
		width: 100%;
		height: 100%;
		-webkit-transform: translate(2px, -2px);
		        transform: translate(2px, -2px);
	}

.xh4SZxRYwhBM4f6WiHip {
	max-width: 220px;
	margin-left: auto;
	margin-right: auto;
}

.P3S88VnAlDWZ3VTstWY5 {
	width: 100%;
	height: 100%;
}

.NX4CRm11A25M0LkbP3qo {
	margin: 0 auto;
	max-width: 440px;
	width: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-flow: column;
	        flex-flow: column;
}

.S8lSymAijK5uvCScsG3s {
	font-size: var(--font-size-sm);
}
`, "",{"version":3,"sources":["webpack://./components/group/join-modal/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,mCAAmC;CACnC,8BAA8B;CAC9B,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;CACrB,oBAAoB;CACpB,yBAAyB;AAO1B;;AALC;EACC,WAAW;EACX,YAAY;EACZ,uCAA+B;UAA/B,+BAA+B;CAChC;;AAGD;CACC,gBAAgB;CAChB,iBAAmB;CAAnB,kBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,cAAc;CACd,gBAAgB;CAChB,WAAW;CACX,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,4BAAiB;CAAjB,6BAAiB;KAAjB,qBAAiB;SAAjB,iBAAiB;AAClB;;AAEA;CACC,8BAA8B;AAC/B","sourcesContent":[".subtitle {\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-500);\n}\n\n.symbol {\n\tbackground: var(--palette-blue-200);\n\tcolor: var(--palette-blue-500);\n\tdisplay: grid;\n\tplace-items: center;\n\tplace-content: center;\n\tborder-radius: 100px;\n\tpadding: var(--spacing-3);\n\n\t& svg {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\ttransform: translate(2px, -2px);\n\t}\n}\n\n.group__code {\n\tmax-width: 220px;\n\tmargin-inline: auto;\n}\n\n.image {\n\twidth: 100%;\n\theight: 100%;\n}\n\n.join {\n\tmargin: 0 auto;\n\tmax-width: 440px;\n\twidth: auto;\n\tdisplay: flex;\n\tplace-items: center;\n\tflex-flow: column;\n}\n\n.label {\n\tfont-size: var(--font-size-sm);\n}\n"],"sourceRoot":""}]);
// Exports
export var subtitle = `VwM9yy4bbvuA5In5LTUr`;
export var symbol = `INTg_BbbGZvrhL_7vINs`;
export var group__code = `xh4SZxRYwhBM4f6WiHip`;
export var image = `P3S88VnAlDWZ3VTstWY5`;
export var join = `NX4CRm11A25M0LkbP3qo`;
export var label = `S8lSymAijK5uvCScsG3s`;
export default ___CSS_LOADER_EXPORT___;
