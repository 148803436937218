import { t } from '@transifex/native';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as selectors from 'pkg/selectors';

import Heading from 'components/Heading';
import { FormattedContent } from 'components/formatted-content';
import Tag from 'components/Tag';
import { RenderPrint } from 'components/MediaQuery';
import Logo from 'components/Logo';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as iconStyles from 'components/icon/styles.css';
import { GalleryModal } from 'components/attachment/gallery';
import Attachment from 'components/attachment/View';
import Drawing from 'components/drawing/Drawing';

const Author = styled.div`
	display: grid;
	grid-auto-flow: row;
	justify-items: center;
	justify-content: center;
	grid-gap: var(--spacing-6);

	margin-top: var(--spacing-6);
	color: var(--palette-gray-500);

	@media print {
		display: none;
	}

	${Logo} {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		padding: var(--spacing-3);
		background: var(--palette-black);
	}
`;

const AttachmentList = styled.div`
	display: grid;
	grid-gap: var(--spacing-6);
	justify-items: stretch;

	img {
		max-width: 100%;
		justify-self: center;
		cursor: pointer;
	}

	@media print {
		overflow: hidden;
		margin-bottom: 5mm;
	}
`;

const PrintLogo = styled.img`
	position: fixed;
	top: 1cm;
	right: 1cm;
	height: 1.5cm;
`;

const PrintNotes = styled.div`
	position: fixed;
	top: 4cm;
	right: 0;

	width: 30%;
	height: calc(100vh - 4cm);

	padding: 5mm;
	padding-bottom: 0;
	padding-right: 0;

	border: 1px solid var(--palette-gray-400);
	border-right: none;
	border-bottom: none;
	border-top-left-radius: 10px;

	color: var(--palette-gray-400);
	font-size: 9.5pt;
	font-weight: var(--font-weight-semibold);
`;

const Wrapper = styled.div`
	margin: var(--spacing-5) 0;
	max-width: 100%;
	display: grid;
	min-width: 0;
	grid-template-columns: 1fr;
	grid-auto-flow: row;
	grid-gap: var(--spacing-8);
	line-height: var(--font-line-height-xl);

	@media ${styles.breakpoint.small} {
		margin: var(--spacing-6) 0;
	}

	@media print {
		display: block;
		overflow: visible;
		width: 70%;
		padding: 1cm;
		margin: 0;
	}
`;

const Header = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-gap: var(--spacing-6);
`;

const Title = styled.h1`
	line-height: var(--font-line-height-4xl);

	@media ${styles.breakpoint.large} {
		padding-right: var(--spacing-9);
	}

	@media print {
		margin-bottom: 2mm;
	}
`;

const SubTitle = styled(Heading)`
	margin-bottom: var(--spacing-3);
	line-height: var(--font-line-height-xl);

	@media print {
		maring-bottom: 5mm;
	}
`;

const Tags = styled.div`
	margin-top: var(--spacing-3);

	& > div {
		margin-right: var(--spacing-3);
		margin-bottom: var(--spacing-3);
	}

	@media print {
		display: none;
	}
`;

const Data = styled.div`
	width: 100%;
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
	grid-gap: var(--spacing-8);
	background: var(--palette-gray-100);
	border: 1px solid var(--palette-gray-400);
	padding: var(--spacing-5);
	border-radius: var(--radius-3);

	@media ${styles.breakpoint.small} {
		grid-auto-flow: dense;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-gap: var(--spacing-6);
	}

	@media print {
		grid-row: 1;
		grid-auto-flow: column;
		grid-template-columns: repeat(4, auto);
		justify-content: center;
	}
`;

const DataItem = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
	font-weight: var(--font-weight-semibold);
	grid-gap: var(--spacing-3);

	.${iconStyles.icon} {
		transform: scale(1.3);
	}

	@media print {
		font-weight: var(--font-weight-semibold);

		.${iconStyles.icon} {
			transform: scale(1);
		}
	}
`;

const DataLabel = styled.span`
	@media print {
		font-size: 10pt;
	}
`;

const Introduction = styled.p`
	width: 100%;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-lg);
	line-height: var(--font-line-height-2xl);
	margin-bottom: var(--spacing-4);

	@media print {
		margin-bottom: 5mm;
		font-size: 12pt;
		line-height: var(--font-line-height-lg);
	}
`;

const MaterialRequirements = styled.div`
	width: 100%;

	@media print {
		margin-bottom: 5mm;
		font-size: 9.5pt;
		line-height: var(--font-line-height-sm);
	}
`;

const CoachingPoints = styled.div`
	background: var(--palette-blue-200);
	border-left: 5px solid var(--palette-blue-500);
	padding: var(--spacing-6);
	border-radius: var(--radius-3);

	${SubTitle} {
		color: var(--palette-blue-500);
	}
`;

const Group = styled.div`
	width: 100%;
`;

const Description = styled(Group)`
	@media print {
		margin-bottom: 5mm;
	}
`;

class View extends Component {
	state = {
		showGalleryModal: false,
		activeId: 0,
	};

	get tags() {
		if (this.props.tags.size === 0) {
			return;
		}

		return (
			<Tags>
				{this.props.tags.map((tag) =>
					this.props.isModalItem ? (
						<Tag small key={tag.id} name={tag.name} />
					) : (
						<Tag key={tag.id} small name={tag.name} />
					)
				)}
			</Tags>
		);
	}

	get duration() {
		const { exercise } = this.props;

		let duration = null;

		if (exercise.minTime && !exercise.maxTime) {
			duration = t(`from {time}`, {
				time: exercise.minTime,
			});
		} else if (!exercise.minTime && exercise.maxTime) {
			duration = t(`max {time}`, {
				time: exercise.maxTime,
			});
		} else if (exercise.minTime === exercise.maxTime) {
			duration = exercise.minTime;
		} else {
			duration = (
				<span>
					{exercise.minTime}–{exercise.maxTime}
				</span>
			);
		}

		return (
			<DataItem>
				<Icon name="nav-attendance" />
				<DataLabel>
					{duration} {t(`min`)}
				</DataLabel>
			</DataItem>
		);
	}

	get participants() {
		const { exercise } = this.props;

		let participants = null;

		if (exercise.minParticipants && !exercise.maxParticipants) {
			participants = t(`min {num}`, {
				num: exercise.minParticipants,
			});
		} else if (!exercise.minParticipants && exercise.maxParticipants) {
			participants = t(`max {num}`, {
				num: exercise.maxParticipants,
			});
		} else if (exercise.minParticipants === exercise.maxParticipants) {
			participants = exercise.minParticipants;
		} else {
			participants = (
				<span>
					{exercise.minParticipants}–{exercise.maxParticipants}
				</span>
			);
		}

		return (
			<DataItem>
				<Icon name="nav-members" />
				<DataLabel>
					{participants} {t(`p`)}
				</DataLabel>
			</DataItem>
		);
	}

	get ageSpan() {
		const { exercise } = this.props;

		let ageSpan = null;

		if (exercise.minAge && !exercise.maxAge) {
			ageSpan = t(`from {num}`, {
				num: exercise.minAge,
			});
		} else if (!exercise.minAge && exercise.maxAge) {
			ageSpan = t(`up to {num}`, {
				num: exercise.maxAge,
			});
		} else {
			ageSpan = (
				<span>
					{exercise.minAge}–{exercise.maxAge}
				</span>
			);
		}

		return (
			<DataItem>
				<Icon name="nav-events" />
				<DataLabel>
					{ageSpan} {t(`yrs`)}
				</DataLabel>
			</DataItem>
		);
	}

	get area() {
		const { exercise } = this.props;

		let area = null;

		if (exercise.areaWidth && !exercise.areaLength) {
			area = t(`W {num}`, {
				num: exercise.areaWidth,
			});
		} else if (!exercise.areaWidth && exercise.areaLength) {
			area = t(`L {num}`, {
				num: exercise.areaLength,
			});
		} else {
			area = (
				<span>
					{exercise.areaWidth}×{exercise.areaLength}
				</span>
			);
		}

		return (
			<DataItem>
				<Icon name="area" />
				<DataLabel>{area}</DataLabel>
			</DataItem>
		);
	}

	get video() {
		const video = this.props.exercise.meta.find((n) => n.key === 'youtube-url');

		if (video) {
			return (
				<Group>
					<FormattedContent raw={video.value} />
				</Group>
			);
		}

		return null;
	}

	get attachments() {
		if (this.props.exercise.attachments.length === 0) {
			return null;
		}

		return (
			<AttachmentList>
				{this.props.exercise.attachments.map((a) =>
					a.type === 'image' ? (
						<img
							key={a.id}
							src={a.url}
							onClick={this.handleToggleGallery}
							alt={a.title}
							data-id={a.id}
							loading="lazy"
						/>
					) : (
						<Attachment link key={a.id} attachment={a} data-id={a.id} />
					)
				)}
			</AttachmentList>
		);
	}

	handleOpenGallery = (event) =>
		this.setState({
			activeId: parseInt(event.currentTarget.dataset.id),
			showGalleryModal: true,
		});

	handleCloseGallery = () =>
		this.setState({ activeId: 0, showGalleryModal: false });

	handleToggleGallery = (event) => {
		this.state.showGalleryModal
			? this.handleCloseGallery()
			: this.handleOpenGallery(event);
	};

	render() {
		const { exercise } = this.props;

		return (
			<Fragment>
				<Wrapper>
					<Header>
						<Title>{exercise.title}</Title>
						{this.tags}

						<Data>
							{exercise.minTime > 0 && this.duration}
							{exercise.minParticipants > 0 && this.participants}
							{exercise.minAge > 0 && this.ageSpan}
							{exercise.areaWidth > 0 && this.area}
						</Data>
					</Header>
					{exercise.introduction && (
						<Introduction>{exercise.introduction}</Introduction>
					)}
					{exercise.materialRequirements && (
						<MaterialRequirements>
							<SubTitle>{t(`Material`)}</SubTitle>
							<div>{exercise.materialRequirements}</div>
						</MaterialRequirements>
					)}
					{this.video}
					{exercise.exerciseDrawings?.map((drawing) => (
						<Drawing
							key={drawing.id}
							drawingId={drawing.id}
							drawing={JSON.parse(drawing.data)}
						/>
					))}
					{this.attachments}
					{exercise.description && (
						<Description>
							<SubTitle>{t(`Description`)}</SubTitle>
							<FormattedContent raw={exercise.description} />
						</Description>
					)}
					{exercise.keyPoints && (
						<CoachingPoints>
							<Group>
								<SubTitle>{t(`Key coaching points`)}</SubTitle>
								<FormattedContent raw={exercise.keyPoints} />
							</Group>
						</CoachingPoints>
					)}
					{this.props.author && exercise.userCreated && (
						<Author>
							<Avatar size={42} account={this.props.author} />
							{t(`Created by {name}`, {
								name: `${this.props.author.firstName} ${this.props.author.lastName}`,
							})}
						</Author>
					)}
					{!exercise.userCreated && (
						<Author>
							<Logo />
							{t(`Created by {name}`, {
								name: '360Player',
							})}
						</Author>
					)}
				</Wrapper>

				<RenderPrint>
					<PrintLogo src={`${TS.assetUrl}/img/logo_black.svg`} />
					<PrintNotes>{t(`Notes`)}:</PrintNotes>
				</RenderPrint>

				{this.state.showGalleryModal && (
					<GalleryModal
						activeId={this.state.activeId}
						attachments={exercise.attachments}
						onClose={this.handleCloseGallery}
					/>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const exercise = selectors.exercises.find(state, props.exerciseId);

	return {
		exercise,
		author: selectors.accounts.find(state, exercise.authorId),
		tags: selectors.tags.findAllByIds(state, exercise.tags),
	};
};

export default connect(mapStateToProps, null)(View);
