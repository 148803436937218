import { MouseEvent } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const CreateStyles = styled.div`
	display: grid;
	grid-template-areas: 'icon name' 'icon text';
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto;
	align-items: center;
	gap: var(--spacing-1) var(--spacing-4);
	background: var(--palette-white);
	padding: var(--spacing-4);
	border-radius: var(--radius-5);
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			box-shadow: ${rgba(styles.palette.white, 0.5)} 0 0 0 4px;
		}
	}

	strong {
		grid-area: name;
		align-self: flex-end;
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		color: var(--palette-gray-900);
		@media ${styles.breakpoint.small} {
			font-size: var(--font-size-sm);
		}
	}

	span {
		grid-area: text;
		align-self: flex-start;
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		@media ${styles.breakpoint.small} {
			font-size: var(--font-size-xs);
		}
	}
`;

const IconWrapper = styled.div`
	grid-area: icon;
	display: grid;
	align-items: center;
	justify-items: center;
	width: 44px;
	height: 44px;
	border-radius: var(--radius-5);
	background: var(--palette-blue-200);
	color: var(--palette-blue-500);

	.${iconStyles.icon} {
		width: 25px;
		height: 25px;
	}
`;

interface CreateProps {
	label: string;
	description: string;
	onClick?: (event?: MouseEvent | TouchEvent | PointerEvent) => void;
	testid?: string;
}

export default function Create({
	label,
	description,
	onClick,
	testid,
}: CreateProps): JSX.Element {
	return (
		<CreateStyles onClick={onClick} data-testid={testid}>
			<IconWrapper>
				<Icon name="add" />
			</IconWrapper>
			<strong>{label}</strong>
			<span>{description}</span>
		</CreateStyles>
	);
}
