import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { LocationMissingEntities } from 'routes/scheduling/resources/MissingEntities';
import CreateLocationModal from 'routes/scheduling/locations/modals/Create';

import { LargeScreen } from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import { Spinner } from 'components/loaders/spinner';
import LocationsTable from 'components/resources/locations/table';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';

import Button from 'design/button';

interface LocationsProps {
	groupId: number;

	setPageActions: (elem: JSX.Element) => void;
}

const LocationsView = ({ groupId, setPageActions }: LocationsProps) => {
	const qs = useQueryState();
	const search = qs.get('search');

	const { records, isLoading, refresh } =
		useCollection<models.resourceLocation.ResourceLocation>(
			endpoints.ResourceLocation.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);
	const [showCreateModal, setShowCreateModal] = useState(false);

	const handleCreateModal = () => {
		setShowCreateModal(true);
	};
	const closeModal = () => setShowCreateModal(false);

	const handleCreate = async () => {
		refresh();
	};

	useComponentDidMount(() => {
		setPageActions(<ButtonTrigger icon="add" onClick={handleCreateModal} />);
	});

	if (isLoading) {
		return <Spinner />;
	}

	const content =
		records.length === 0 ? (
			<LargeScreenContent.Inner>
				<LocationMissingEntities handleOpenModal={handleCreateModal} />
			</LargeScreenContent.Inner>
		) : (
			<Fragment>
				<ActionBar.FilterBar>
					<ActionBar.PrimaryAction>
						<ActionBar.Search
							value={qs.get('search') as string}
							placeholder={t('Search')}
						/>
					</ActionBar.PrimaryAction>
					<LargeScreen>
						<Button
							label={t('Create new')}
							icon="add"
							primary
							onClick={handleCreateModal}
						/>
					</LargeScreen>
				</ActionBar.FilterBar>

				<LocationsTable
					locations={records}
					refresh={refresh}
					search={search?.toString()}
				/>
			</Fragment>
		);

	return (
		<Fragment>
			{content}
			{showCreateModal && (
				<CreateLocationModal
					groupId={groupId}
					onClose={closeModal}
					onCreate={handleCreate}
				/>
			)}
		</Fragment>
	);
};

export default LocationsView;
