// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wv9jcEOg7b0OIRC6nk2i {
	height: 18px !important;
}

.dCK6866wjLPDcWcz_HsX:last-child {
	padding-bottom: var(--spacing-2);
}
`, "",{"version":3,"sources":["webpack://./components/filters/styles.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;AACxB;;AAEA;CACC,gCAAgC;AACjC","sourcesContent":[".clearButton {\n\theight: 18px !important;\n}\n\n.filterGroup:last-child {\n\tpadding-bottom: var(--spacing-2);\n}\n"],"sourceRoot":""}]);
// Exports
export var clearButton = `wv9jcEOg7b0OIRC6nk2i`;
export var filterGroup = `dCK6866wjLPDcWcz_HsX`;
export default ___CSS_LOADER_EXPORT___;
