import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import DateTime from 'pkg/datetime';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import * as SideBarList from 'components/sidebar/List';
import * as SideBarLayout from 'components/layout/SideBarPage';
import * as SideBarInfo from 'components/sidebar/Info';
import SideBarIcon from 'components/sidebar/Icon';
import Column from 'components/layout/column';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';

interface SideBarProps {
	product: models.product.Product;
}

const SideBar: React.FC<React.PropsWithChildren<SideBarProps>> = ({
	product,
}) => {
	const org = useCurrentOrganization();
	const createdAt = new Date(product.createdAt * 1000);

	const createdAtDateTime = new DateTime(createdAt);

	const handleArchive = () => {
		const didConfirm = window.confirm(
			t('Are you sure you want to archive this product?')
		);

		if (didConfirm) {
			actions.products.update(product.id, { archived: true });
		}
	};
	return (
		<SideBarLayout.SideBar>
			<SideBarLayout.SideBarBody>
				<SideBarInfo.CenterColumn>
					<SideBarIcon name="tag" />
					<div>
						<SideBarInfo.Title>{product.name}</SideBarInfo.Title>
						<span>
							{t('Created: {date}', {
								date: createdAtDateTime.toLocaleDateString(),
							})}
						</span>
					</div>
					<ContextMenu.Menu
						toggleWith={
							<Button primary hasIcon iconPosition="right" block>
								{t('Actions')}
								<Icon name="chevron" rotate="90deg" />
							</Button>
						}>
						<ContextMenu.LinkItem
							href={routes.Product.Edit(org.id, product.id)}
							disabled={product.archivedAt > 0}>
							<ContextMenu.ItemIcon name="edit" />
							{t('Edit')}
						</ContextMenu.LinkItem>
						<ContextMenu.Item
							caution
							onClick={handleArchive}
							disabled={product.archivedAt > 0}>
							<ContextMenu.ItemIcon name="archive" />
							{t('Archive')}
						</ContextMenu.Item>
					</ContextMenu.Menu>
				</SideBarInfo.CenterColumn>
			</SideBarLayout.SideBarBody>
			<ContextMenu.Divider />
			<SideBarLayout.SideBarBody>
				<Column>
					<SideBarList.Title>{t('Overview')}</SideBarList.Title>
					<SideBarList.Row>
						<SideBarList.Name>{t('Category')}</SideBarList.Name>
						<span>{product?.category?.name || t('No category')}</span>
					</SideBarList.Row>
					<SideBarList.Row>
						<SideBarList.Name>{t('Description')}</SideBarList.Name>
						<span>{product.description}</span>
					</SideBarList.Row>
					{product.metadata?.map((meta) => (
						<SideBarList.Row key={meta.id}>
							<SideBarList.Name>{meta.key}</SideBarList.Name>
							<span>{meta.value}</span>
						</SideBarList.Row>
					))}
				</Column>
			</SideBarLayout.SideBarBody>
		</SideBarLayout.SideBar>
	);
};

export default SideBar;
