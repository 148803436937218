import { t } from '@transifex/native';

import { FilterOperator } from 'pkg/filters';
import { Filters, useFilters } from 'pkg/filters/use_filters';
import * as models from 'pkg/api/models';

import ProductsSelectModal from 'routes/payments/products/modals/select';

export function useInvoiceFilters(): Filters {
	return useFilters({
		filterBarMobileTrigger: true,
		groups: {
			[t('Has product')]: {
				filters: {
					HasProduct: {
						type: 'modal',
						property: 'product_id',
						operator: FilterOperator.Includes,
						settings: {
							modal: <ProductsSelectModal />,
							buttonLabel: t('Select product'),
							hideButton: false,
						},
					},
				},
			},
			[t('Date sent')]: {
				filters: {
					DueDate: {
						type: 'date',
						property: 'sent_at',
					},
				},
			},
			[t('Due date')]: {
				filters: {
					DueDate: {
						type: 'date',
						property: 'due_date',
					},
				},
			},
			[t('Paid date')]: {
				filters: {
					DueDate: {
						type: 'date',
						property: 'paid_at',
					},
				},
			},
			[t('Refund date')]: {
				filters: {
					DueDate: {
						type: 'date',
						property: 'refunded_at',
					},
				},
			},
			[t('Amount')]: {
				filters: {
					Amount: {
						type: 'number',
						property: 'amount',
						settings: { money: true },
					},
				},
			},
			[t('Status')]: {
				filters: {
					Status: {
						type: 'checkbox',
						property: 'status',
						operator: FilterOperator.Includes,
						values: {
							[t('Draft')]: models.order.Statuses.Draft,
							[t('Open')]: models.order.Statuses.Open,
							[t('Paid')]: models.order.Statuses.Paid,
							[t('Past due')]: models.order.Statuses.PastDue,
							[t('Void')]: models.order.Statuses.Void,
							[t('Uncollectible')]: models.order.Statuses.Uncollectible,
						},
					},
				},
			},
		},
	});
}

export function useSubscriptionFilters(): Filters {
	return useFilters({
		groups: {
			[t('Has product')]: {
				filters: {
					HasProduct: {
						type: 'modal',
						property: 'product_id',
						operator: FilterOperator.Includes,
						settings: {
							modal: <ProductsSelectModal />,
							buttonLabel: t('Select product'),
							hideButton: false,
						},
					},
				},
			},
			[t('Status')]: {
				filters: {
					Status: {
						type: 'checkbox',
						property: 'status',
						operator: FilterOperator.Includes,
						values: {
							[t('Active')]: 'active',
							[t('Scheduled')]: 'scheduled',
							[t('Pending')]: 'pending',
							[t('Completed')]: 'completed',
							[t('Past due')]: 'past_due',
							[t('Canceled')]: 'canceled',
							[t('Uncollectible')]: 'uncollectible',
						},
					},
				},
			},
			[t('Created')]: {
				filters: {
					DueDate: {
						type: 'date',
						property: 'created_at',
					},
				},
			},
			[t('Next invoice date')]: {
				filters: {
					TrialEndsAt: {
						type: 'date',
						property: 'current_period_end',
					},
				},
			},
			[t('Collection method')]: {
				filters: {
					CollectionMethod: {
						type: 'checkbox',
						property: 'collection_method',
						operator: FilterOperator.Includes,
						values: {
							[t('Auto charge')]: 'automatic',
							[t('Email invoice')]: 'manual',
						},
					},
				},
			},
		},
		filterBarMobileTrigger: true,
	});
}
