// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mxa0BKHnl6pDWG5KzeJA {
	display: grid;
	grid-auto-rows: auto;
	grid-gap: var(--spacing-2);
}

.mxa0BKHnl6pDWG5KzeJA strong {
		display: block;
		text-align: center;
		font-size: var(--font-size-sm);
		font-weight: var(--font-weight-semibold);
	}

@media (max-width: 768px) {

.mxa0BKHnl6pDWG5KzeJA strong {
			font-size: var(--font-size-xs);
	}
		}

.mxa0BKHnl6pDWG5KzeJA div {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		gap: var(--spacing-2);
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}

.mxa0BKHnl6pDWG5KzeJA div span {
			font-size: var(--font-size-base);
			font-weight: var(--font-weight-semibold);
		}

.fBj2Jzv4UTmFos_jK8W_ {
	display: grid;
	grid-gap: var(--spacing-4);
	grid-template-columns: repeat(3, minmax(0, 1fr));
	cursor: pointer;
}

.fBj2Jzv4UTmFos_jK8W_ .mxa0BKHnl6pDWG5KzeJA:nth-child(1) {
		color: var(--palette-green-500);
	}

.fBj2Jzv4UTmFos_jK8W_ .mxa0BKHnl6pDWG5KzeJA:nth-child(2) {
		color: var(--palette-red-500);
	}

.fBj2Jzv4UTmFos_jK8W_ .mxa0BKHnl6pDWG5KzeJA:nth-child(3) {
		color: var(--palette-orange-500);
	}
`, "",{"version":3,"sources":["webpack://./routes/event/single/event-users-summary/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,oBAAoB;CACpB,0BAA0B;AAwB3B;;AAtBC;EACC,cAAc;EACd,kBAAkB;EAClB,8BAA8B;EAC9B,wCAAwC;CAKzC;;AAHC;;AAND;GAOE,8BAA8B;CAEhC;EADC;;AAGD;EACC,oBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,qBAAqB;EACrB,yBAAmB;MAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,wBAAuB;MAAvB,qBAAuB;UAAvB,uBAAuB;CAMxB;;AAJC;GACC,gCAAgC;GAChC,wCAAwC;EACzC;;AAIF;CACC,aAAa;CACb,0BAA0B;CAC1B,gDAAgD;CAChD,eAAe;AAahB;;AAXC;EACC,+BAA+B;CAChC;;AAEA;EACC,6BAA6B;CAC9B;;AAEA;EACC,gCAAgC;CACjC","sourcesContent":[".summaryColumn {\n\tdisplay: grid;\n\tgrid-auto-rows: auto;\n\tgrid-gap: var(--spacing-2);\n\n\t& strong {\n\t\tdisplay: block;\n\t\ttext-align: center;\n\t\tfont-size: var(--font-size-sm);\n\t\tfont-weight: var(--font-weight-semibold);\n\n\t\t@media (--small-viewport) {\n\t\t\tfont-size: var(--font-size-xs);\n\t\t}\n\t}\n\n\t& div {\n\t\tdisplay: flex;\n\t\tgap: var(--spacing-2);\n\t\talign-items: center;\n\t\tjustify-content: center;\n\n\t\t& span {\n\t\t\tfont-size: var(--font-size-base);\n\t\t\tfont-weight: var(--font-weight-semibold);\n\t\t}\n\t}\n}\n\n.summaryLayout {\n\tdisplay: grid;\n\tgrid-gap: var(--spacing-4);\n\tgrid-template-columns: repeat(3, minmax(0, 1fr));\n\tcursor: pointer;\n\n\t& .summaryColumn:nth-child(1) {\n\t\tcolor: var(--palette-green-500);\n\t}\n\n\t& .summaryColumn:nth-child(2) {\n\t\tcolor: var(--palette-red-500);\n\t}\n\n\t& .summaryColumn:nth-child(3) {\n\t\tcolor: var(--palette-orange-500);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var summaryColumn = `mxa0BKHnl6pDWG5KzeJA`;
export var summaryLayout = `fBj2Jzv4UTmFos_jK8W_`;
export default ___CSS_LOADER_EXPORT___;
