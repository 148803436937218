// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sENtjD5v9AYaAYFqOH0N {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	color: var(--palette-blue-500);
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-xl);
	gap: var(--spacing-1);
	cursor: pointer;
}

.FeFbPlIJlFrwggyW0dsg {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	gap: var(--spacing-3) var(--spacing-7);
}
`, "",{"version":3,"sources":["webpack://./routes/scheduling/templates/schedule_items/form/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,8BAA8B;CAC9B,wCAAwC;CACxC,8BAA8B;CAC9B,qBAAqB;CACrB,eAAe;AAChB;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,8BAAmB;CAAnB,6BAAmB;KAAnB,uBAAmB;SAAnB,mBAAmB;CACnB,mBAAe;KAAf,eAAe;CACf,sCAAsC;AACvC","sourcesContent":[".group-name {\n\tdisplay: flex;\n\talign-items: center;\n\tcolor: var(--palette-blue-500);\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-xl);\n\tgap: var(--spacing-1);\n\tcursor: pointer;\n}\n\n.time-row {\n\tdisplay: flex;\n\tflex-direction: row;\n\tflex-wrap: wrap;\n\tgap: var(--spacing-3) var(--spacing-7);\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `sENtjD5v9AYaAYFqOH0N`;
export { _1 as "group-name" };
var _2 = `FeFbPlIJlFrwggyW0dsg`;
export { _2 as "time-row" };
export default ___CSS_LOADER_EXPORT___;
