import { Fragment, ReactNode, useEffect } from 'react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import { PageWidths } from 'pkg/config/sizes';
import spacing from 'pkg/config/spacing';

import {
	useCurrentAccount,
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';
import useToggleState from 'pkg/hooks/useToggleState';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { replaceState } from 'pkg/router/state';

import JoinModal from 'components/group/join-modal';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SlimHeader from 'components/navigation/slim-header';
import { FullScreenPageWithoutHeader } from 'components/layout/PageTemplates';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';
import ClubContactInformation from 'components/group/club-contact-information';

import Button from 'design/button';

import * as css from './styles.css';

export function isArchivedContact(
	user: models.user.User,
	membership: models.membership.Membership
): boolean {
	return user === null && membership?.id === null && membership.isLegalGuardian;
}

interface ArchivedContactGuardProps {
	children: ReactNode | ReactNode[];
}

export function ArchivedContactGuard({
	children,
}: ArchivedContactGuardProps): JSX.Element {
	const user = useCurrentUser();
	const account = useCurrentAccount();
	const membership = useCurrentMembership();
	const organization = useCurrentOrganization();

	const [modalOpen, showModal, hideModal] = useToggleState();

	const whitelistedUrls = [
		routes.Group.Change(),
		routes.Debug.KitchenSink(),
		routes.UserBilling.Home(),
		routes.Account.Settings.Show('general'),
		routes.Account.Settings.Show('privacy'),
		routes.Account.Settings.Show('children'),
		routes.Support(),
	];

	const isWhitelisted = whitelistedUrls.some((path: string) =>
		window.location.pathname.startsWith(path)
	);

	const isArchived = isArchivedContact(user, membership);

	useEffect(() => {
		if (window.location.pathname.replace(/^\/|\/$/g, '') === 'group.change') {
			replaceState(routes.Organization.Home(organization.id));
		}
	}, [window.location.pathname]);

	if (isArchived && !isWhitelisted) {
		return (
			<Fragment>
				<FullScreenPageWithoutHeader>
					<SlimHeader />
					<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
						<Column spacing={spacing._6}>
							<Column>
								<h3 data-testid="inactive.membership">
									{t('Membership status: Inactive')}
								</h3>
								{account?.email && (
									<span>
										{t('Email')}: {account.email}
									</span>
								)}
							</Column>

							<InfoBox>
								<p>
									<T
										_str="You are currently not affiliated with any team at {organization}."
										organization={<strong>{organization.name}</strong>}
									/>
								</p>
								<p>
									{t(
										'If this information is incorrect or if you have any questions, please reach out to your club direcly.'
									)}
								</p>
								<p>
									{t(
										'Interested in joining another team? Simply use a team code to connect with a new club or team.'
									)}
								</p>

								<Button primary onClick={showModal} className={css.cta}>
									{t('Join a new team')}
								</Button>
							</InfoBox>
							<ClubContactInformation groupId={organization.id} />
						</Column>
					</LargeScreenContent.Inner>
				</FullScreenPageWithoutHeader>
				{modalOpen && <JoinModal onClose={hideModal} />}
			</Fragment>
		);
	}

	return <Fragment>{children}</Fragment>;
}
