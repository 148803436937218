import * as React from 'react';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Indicator, {
	EmptyIndicator,
} from 'components/physical-strain/Indicator';
import { Outer, Inner } from 'components/physical-strain/Fill';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-gap: var(--spacing-4);
	align-items: center;

	span {
		color: var(--palette-gray-500);
		font-size: var(--font-size-sm);
	}
`;

const DefaultBar = styled(Outer)<{
	isEmpty?: boolean;
	noBorderRadius?: boolean;
}>`
	height: 7px;
	border-radius: ${(props) => (props.noBorderRadius ? '0' : '22px')};

	${(props) =>
		props.isEmpty &&
		css`
			background: var(--palette-gray-200);
		`}

	${Inner} {
		background-color: var(--palette-gray-200);

		${(props) =>
			props.isEmpty &&
			css`
				display: none;
			`}
	}
`;

type StrainStatusProps = {
	level: number;
	text: string;
};

const getStrainStatus = (strainLevel: number): StrainStatusProps => {
	const strainStatus: StrainStatusProps = {
		level: strainLevel,
		text: '',
	};

	if (strainLevel === 100) {
		strainStatus.text = t('Max');
	} else if (strainLevel > 90) {
		strainStatus.text = t('Very high');
	} else if (strainLevel > 70) {
		strainStatus.text = t('High');
	} else if (strainLevel > 40) {
		strainStatus.text = t('Moderate');
	} else {
		strainStatus.text = t('Low');
	}

	return strainStatus;
};

type PhysicalStrainProps = {
	userStrainLevel: number;
	teamStrainLevel: number;
	hideLabels?: boolean;
	parentBrColor?: string;
	className?: string;
};

const PhysicalStrain: React.FC<
	React.PropsWithChildren<PhysicalStrainProps>
> = ({
	userStrainLevel = 0,
	teamStrainLevel = 0,
	hideLabels = false,
	parentBrColor = styles.palette.white,
}) => {
	const userStrainStatus: StrainStatusProps = getStrainStatus(userStrainLevel);
	const teamStrainStatus: StrainStatusProps = getStrainStatus(teamStrainLevel);

	const [lowestStrainLevel, highestStrainLevel] = [
		userStrainLevel,
		teamStrainLevel,
	].sort((a, b) => a - b);

	const noBorderRadius: boolean =
		lowestStrainLevel < 2 || highestStrainLevel > 98;

	if (highestStrainLevel === 0) {
		return (
			<Wrapper>
				<span>{t('Low')}</span>
				<DefaultBar isEmpty={true}>
					<EmptyIndicator />
				</DefaultBar>
				<span>{t('Max')}</span>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<span>{t('Low')}</span>

			<div>
				{!hideLabels && (
					<Indicator
						strainLevel={userStrainLevel}
						brColor={parentBrColor}
						text={userStrainStatus.text}
					/>
				)}

				<DefaultBar noBorderRadius={noBorderRadius}>
					<Inner percentage={100 - highestStrainLevel} />
				</DefaultBar>

				{!hideLabels && (
					<Indicator
						strainLevel={teamStrainLevel}
						positionBelow={true}
						brColor={parentBrColor}
						text={`${teamStrainStatus.text} (${t(`team avg.`).toLowerCase()})`}
					/>
				)}
			</div>

			<span>{t('Max')}</span>
		</Wrapper>
	);
};

export default PhysicalStrain;
