import { Fragment } from 'react';
import { t } from '@transifex/native';

import { QueryObject, useQueryState } from 'pkg/hooks/query-state';
import useDateFilter, {
	DateQueryFilters,
	Dates,
} from 'pkg/hooks/use-date-filter';
import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';
import DateTime from 'pkg/datetime';

import GroupReport from 'routes/group/report/group';
import AttendanceReport from 'routes/group/report/attendance';
import ActivitiesReport from 'routes/group/report/activities';
import AttendanceLargeScreenNavigation, {
	useAttendanceNavigation,
} from 'routes/statistics/attendance/navigation';
import AttendanceOverview from 'routes/statistics/attendance/overview';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import {
	SmallSubNavItems,
	TableContentWrapper,
} from 'components/layout/PageTemplates';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SmallScreenContent from 'components/layout/SmallScreenContent';

interface AttendanceRouteProps {
	title: string;
	icon: MaterialSymbolVariant;
	currentTab: string;
	groupId: number;
	query: QueryObject;
}

export default function AttendanceRoute({
	title,
	icon,
	currentTab,
	groupId,
	query,
}: AttendanceRouteProps) {
	const navigationItems = useAttendanceNavigation(groupId);
	const qs = useQueryState();
	const currentGroup = useCurrentGroup();
	const currentSeason = models.group.getCurrentSeason(currentGroup);

	const now = new DateTime(new Date());

	const dateRange: Dates = {
		startOfRange: currentSeason.start,
		endOfRange: now.getUnixTimestamp(),
	};

	if (query?.startOfRange) {
		dateRange.startOfRange = Number.parseInt(query.startOfRange as string, 10);
	}

	if (query?.endOfRange) {
		dateRange.endOfRange = Number.parseInt(query.endOfRange as string, 10);
	}

	const setUrl = (params: DateQueryFilters) => {
		qs.setAll({
			dateLabel: params.dateLabel,
			endOfRange: params.endOfRange,
			startOfRange: params.startOfRange,
		});
		qs.commit();
	};

	const { DateFilter, dates } = useDateFilter({
		filters: {
			startOfRange: dateRange.startOfRange,
			endOfRange: dateRange.endOfRange,
			dateLabel: query?.dateLabel as string,
		},
		setUrl,
		customDatePresets: [
			{
				default: !query?.dateLabel ? true : false,
				name: t('Season'),
				startOfRangeDate: currentSeason.start,
			},
		],
	});

	let content = (
		<GroupReport groupId={groupId} dateFilter={DateFilter} dateRange={dates} />
	);

	switch (currentTab) {
		case 'individual-report':
			content = (
				<AttendanceReport
					groupId={groupId}
					query={query}
					dateFilter={DateFilter}
					dateRange={dates}
				/>
			);
			break;
		case 'event-report':
			content = (
				<ActivitiesReport
					groupId={groupId}
					query={query}
					dateFilter={DateFilter}
					dateRange={dates}
				/>
			);
			break;
		case 'overview':
			content = (
				<AttendanceOverview
					groupId={groupId}
					dateFilter={DateFilter}
					dateRange={dates}
				/>
			);
			break;
	}

	return (
		<Fragment>
			<LargeScreen>
				<AttendanceLargeScreenNavigation
					title={title}
					icon={icon}
					groupId={groupId}
				/>

				<LargeScreenContent.Wrapper
					key={currentTab}
					disableScroll={currentTab !== 'overview'}>
					{currentTab === 'overview' ? (
						content
					) : (
						<TableContentWrapper>{content}</TableContentWrapper>
					)}
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={title} />

				<SmallScreenContent noScroll={currentTab !== 'overview'}>
					<SmallLayout.SubNav>
						<SmallSubNavItems items={navigationItems} />
					</SmallLayout.SubNav>
					{currentTab === 'overview' ? (
						content
					) : (
						<TableContentWrapper>{content}</TableContentWrapper>
					)}
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
}
