// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VhcOHnlqIXkBPvzEshBD {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./routes/public/debug/styles.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,SAAS;CACT,WAAW;CACX,UAAU;AACX","sourcesContent":[".debugTrigger {\n\tposition: absolute;\n\ttop: 20px;\n\tright: 20px;\n\tz-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export var debugTrigger = `VhcOHnlqIXkBPvzEshBD`;
export default ___CSS_LOADER_EXPORT___;
