// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iyxhOhgBvJlQC3tYkkz4 {
	display: grid !important;
	padding: var(--spacing-5) !important;
}

@media (max-width: 768px) {

.iyxhOhgBvJlQC3tYkkz4 {
		padding: 0 !important;
		border-radius: 10px !important;
}
	}

._LoJMuag9uuI7scJi8QK {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

._LoJMuag9uuI7scJi8QK {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	gap: 0;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

.HIHsT2d9Z1ly44jGVugm {
	display: inline;
	font-weight: var(--font-weight-normal);
}

.WSHNPBiDw7Y9qPlDDFZW.mJOpIDrkeiy9_UHYkQLQ {
		color: var(--palette-red-600);
	}

.WSHNPBiDw7Y9qPlDDFZW::after {
		content: '·';
		padding: 0 var(--spacing-2);
		color: var(--palette-gray-500);
	}

.WSHNPBiDw7Y9qPlDDFZW:last-of-type::after {
			content: '';
			padding: 0 var(--spacing-2);
		}

.gJ4VvkGI_vekmubdbrN_ {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	position: relative;
}

.gJ4VvkGI_vekmubdbrN_::after {
		content: '·';
		padding: 0 var(--spacing-2);
	}
`, "",{"version":3,"sources":["webpack://./routes/scheduling/templates/schedule_items/card_list_item/styles.css"],"names":[],"mappings":"AAAA;CACC,wBAAwB;CACxB,oCAAoC;AAMrC;;AAJC;;AAJD;EAKE,qBAAqB;EACrB,8BAA8B;AAEhC;CADC;;AAGD;CACC,+BAAwB;CAAxB,+BAAwB;CAAxB,wBAAwB;AAKzB;;AANA;CAEC,8BAAmB;CAAnB,6BAAmB;KAAnB,uBAAmB;SAAnB,mBAAmB;CACnB,MAAM;CACN,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,mBAAe;KAAf,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,sCAAsC;AACvC;;AAGC;EACC,6BAA6B;CAC9B;;AAEA;EACC,YAAY;EACZ,2BAA2B;EAC3B,8BAA8B;CAC/B;;AAGC;GACC,WAAW;GACX,2BAA2B;EAC5B;;AAIF;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,kBAAkB;AAMnB;;AAJC;EACC,YAAY;EACZ,2BAA2B;CAC5B","sourcesContent":[".card-base {\n\tdisplay: grid !important;\n\tpadding: var(--spacing-5) !important;\n\n\t@media (--small-viewport) {\n\t\tpadding: 0 !important;\n\t\tborder-radius: 10px !important;\n\t}\n}\n\n.description {\n\tdisplay: flex !important;\n\tflex-direction: row;\n\tgap: 0;\n\talign-items: center;\n\tflex-wrap: wrap;\n}\n\n.title-thin {\n\tdisplay: inline;\n\tfont-weight: var(--font-weight-normal);\n}\n\n.resource {\n\t&.conflicting {\n\t\tcolor: var(--palette-red-600);\n\t}\n\n\t&::after {\n\t\tcontent: '·';\n\t\tpadding: 0 var(--spacing-2);\n\t\tcolor: var(--palette-gray-500);\n\t}\n\n\t&:last-of-type {\n\t\t&::after {\n\t\t\tcontent: '';\n\t\t\tpadding: 0 var(--spacing-2);\n\t\t}\n\t}\n}\n\n.location {\n\tdisplay: flex;\n\talign-items: center;\n\tposition: relative;\n\n\t&::after {\n\t\tcontent: '·';\n\t\tpadding: 0 var(--spacing-2);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `iyxhOhgBvJlQC3tYkkz4`;
export { _1 as "card-base" };
export var description = `_LoJMuag9uuI7scJi8QK`;
var _2 = `HIHsT2d9Z1ly44jGVugm`;
export { _2 as "title-thin" };
export var resource = `WSHNPBiDw7Y9qPlDDFZW`;
export var conflicting = `mJOpIDrkeiy9_UHYkQLQ`;
export var location = `gJ4VvkGI_vekmubdbrN_`;
export default ___CSS_LOADER_EXPORT___;
