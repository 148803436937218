import { Fragment } from 'react';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { useCurrentGroup } from 'pkg/identity';

import OpenRegistrations from 'routes/user/registrations/open';
import Children from 'routes/dashboard/organization/children';
import PendingRequests from 'routes/dashboard/organization/pending-requests';
import Wall from 'routes/dashboard/wall';
import PendingInvoices from 'routes/dashboard/pending-invoices';

import { SmallScreen } from 'components/MediaQuery';
import Adslot, { AdslotBoundary } from 'components/adslot';

import Column from 'components/layout/column';
import ContactInformation from 'components/group/club-contact-information';

import * as css from './styles.css';

export default function OrganizationWidgets(): JSX.Element {
	const group = useCurrentGroup();

	return (
		<div className={css.wrapper}>
			<Column spacing={styles.spacing._8}>
				<h1 className={css.title}>
					<T
						_str="Welcome to {organization_name}!"
						organization_name={
							<Fragment>
								<br />
								{group.name}
							</Fragment>
						}
					/>
				</h1>
				<PendingInvoices />
				<PendingRequests />
				<Children />
				<SmallScreen>
					<Wall />
				</SmallScreen>
				<OpenRegistrations showTitle />
				<ContactInformation />
				<SmallScreen>
					<AdslotBoundary>
						<Adslot dynamicHeight height={600} />
					</AdslotBoundary>
				</SmallScreen>
			</Column>
		</div>
	);
}
