// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rrCoUtbDwcF5hdDNlexc {
	padding: var(--spacing-4);
}

.eOC5d9Gyqgn5eribkyWl {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
	height: 100%;
	padding: var(--spacing-4);
}

.eOC5d9Gyqgn5eribkyWl img {
		max-width: 160px;
	}

.v24yOc8DYm2FIuiS6Pgc {
	font-weight: var(--font-weight-semibold);
}

.FRBKw2W7ERHijLdmJXSj {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

.IOq7K9iusmMD76DB3DWx {
	padding: var(--spacing-8) 0;
}

@media (max-width: 768px) {

.IOq7K9iusmMD76DB3DWx {
		padding: var(--spacing-6) 0;
}
	}

.k39QO8is1C4ohYjKnU2w {
display: grid;
	grid-row-gap: var(--spacing-4);
}

.jtqF2xlrqFa8lbnZ8ZHC {
	padding: 12px;
 }

.vepBB_IPJzsy1ePibPgM td {
		position: sticky;
		top: 0;
		z-index: 1;
	}

.p2CBgFRfYGa5mPNMR_Ip {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height: 235px;
	padding: var(--spacing-8) var(--spacing-4);
 }

.p2CBgFRfYGa5mPNMR_Ip img {
		max-width: 160px;
	}
`, "",{"version":3,"sources":["webpack://./containers/group/statistics/training-dashboard/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;CACrB,YAAY;CACZ,yBAAyB;AAK1B;;AAHC;EACC,gBAAgB;CACjB;;AAGD;CACC,wCAAwC;AACzC;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,4BAAsB;CAAtB,6BAAsB;KAAtB,0BAAsB;SAAtB,sBAAsB;AACvB;;AAEA;CACC,2BAA2B;AAK5B;;AAHC;;AAHD;EAIE,2BAA2B;AAE7B;CADC;;AAGD;AACA,aAAa;CACZ,8BAA8B;AAC/B;;AAEA;CACC,aAAa;CACb;;AAGA;EACC,gBAAgB;EAChB,MAAM;EACN,UAAU;CACX;;AAGA;CACA,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,aAAa;CACb,0CAA0C;CAK1C;;AAHA;EACC,gBAAgB;CACjB","sourcesContent":[".filter {\n\tpadding: var(--spacing-4);\n}\n\n.emptystate {\n\tplace-items: center;\n\tplace-content: center;\n\theight: 100%;\n\tpadding: var(--spacing-4);\n\n\t& img {\n\t\tmax-width: 160px;\n\t}\n}\n\n.emptystateTitle {\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.chartWrapper {\n\tpadding: var(--spacing-8) 0;\n\n\t@media (--small-viewport) {\n\t\tpadding: var(--spacing-6) 0;\n\t}\n}\n\n.activitygrid {\ndisplay: grid;\n\tgrid-row-gap: var(--spacing-4);\n}\n\n.search {\n\tpadding: 12px;\n }\n\n .stickyRow {\n\t& td {\n\t\tposition: sticky;\n\t\ttop: 0;\n\t\tz-index: 1;\n\t}\n }\n\n .activityEmptyState {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 235px;\n\tpadding: var(--spacing-8) var(--spacing-4);\n\n\t& img {\n\t\tmax-width: 160px;\n\t}\n }\n"],"sourceRoot":""}]);
// Exports
export var filter = `rrCoUtbDwcF5hdDNlexc`;
export var emptystate = `eOC5d9Gyqgn5eribkyWl`;
export var emptystateTitle = `v24yOc8DYm2FIuiS6Pgc`;
export var wrapper = `FRBKw2W7ERHijLdmJXSj`;
export var chartWrapper = `IOq7K9iusmMD76DB3DWx`;
export var activitygrid = `k39QO8is1C4ohYjKnU2w`;
export var search = `jtqF2xlrqFa8lbnZ8ZHC`;
export var stickyRow = `vepBB_IPJzsy1ePibPgM`;
export var activityEmptyState = `p2CBgFRfYGa5mPNMR_Ip`;
export default ___CSS_LOADER_EXPORT___;
