import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import { useForceUpdate } from 'pkg/hooks/component-lifecycle';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as ls from 'pkg/local_storage';
import { UserLogin } from 'pkg/local_storage/login-info';

import { useOnboardingContext } from 'routes/public/onboarding';
import BigTitle from 'routes/public/styles/BigTitle';
import Button from 'routes/public/styles/Button';
import Divider from 'routes/public/styles/Divider';
import FooterLinks from 'routes/public/styles/FooterLinks';
import FooterNote from 'routes/public/styles/FooterNote';
import LastLogin from 'routes/public/last-login';
import ThreeSixtyLogo from 'routes/public/styles/logo';
import { useOnboardingState } from 'routes/public/hooks';
import LanguageSelector from 'routes/public/styles/language-selector';
import * as publicStyles from 'routes/public/styles/styles.css';

import Column from 'components/layout/column';

const _context = 'public/first_page';

export default function Start(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const state = useOnboardingState();

	useComponentDidMount(() => {
		// Make sure we start with a clean state
		state.flush();
	});

	const forceUpdate = useForceUpdate();

	const parsedLogin = ls.getJsonItem<UserLogin>(ls.LocalStorageKeys.UserLogin);
	const signedInBefore = !!parsedLogin?.type;

	return (
		<Fragment>
			<Column
				spacing={signedInBefore ? styles.spacing._9 : styles.spacing._8}
				className={publicStyles.main}>
				<ThreeSixtyLogo />

				<Column spacing={styles.spacing._9}>
					{!signedInBefore && (
						<BigTitle
							firstTitle={t('Empowers', { _context })}
							secondTitle={t('clubs at all levels', { _context })}
							description={t(
								'The all-in-one platform for clubs, teams and players.',
								{ _context }
							)}
							testid="onboarding.start_title"
						/>
					)}

					{signedInBefore ? (
						<LastLogin userLogin={parsedLogin} />
					) : (
						<Fragment>
							<Column spacing={styles.spacing._5}>
								<Button
									primary
									label={t('Create account', { _context })}
									onClick={() => goTo('group-code')}
									testid="onboarding.signup_button"
								/>

								<Divider />

								<Button
									label={t('Sign in', { _context })}
									onClick={() => goTo('login')}
									testid="onboarding.login_button"
								/>
							</Column>

							<FooterNote>
								<Link href="https://www.360player.com/">
									{t('Is your club not using 360Player?')}
								</Link>
							</FooterNote>
						</Fragment>
					)}
				</Column>
			</Column>

			<div className={publicStyles.footer}>
				<Column spacing={styles.spacing._6}>
					<FooterLinks>
						<Link href="https://www.360player.com/terms-of-service">
							{t('Terms of Service', { _context })}
						</Link>
						<Link href="https://www.360player.com/privacy-policy">
							{t('Privacy policy', { _context })}
						</Link>
						<Link href="https://help.360player.com/article/150-faq">
							{t('FAQ', { _context })}
						</Link>
					</FooterLinks>
					<LanguageSelector onSelect={forceUpdate} />
				</Column>
			</div>
		</Fragment>
	);
}
