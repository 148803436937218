import { Record } from 'immutable';
import { schema } from 'normalizr';

import Tag from 'pkg/models/tag';
import Group from 'pkg/models/group';
import SessionBlock from 'pkg/models/session_block';
import Account from 'pkg/models/account';

const normalizrSchema = new schema.Entity('sessions', {
	tags: [Tag.normalizr()],
	account: Account.normalizr(),
	group: Group.normalizr(),
	blocks: [SessionBlock.normalizr()],
});

interface ISession {
	id: number;
	accountId: number;
	eventId: number;
	title: string;
	description: string;
	tags: number[];
	defaultLanguage: string;
	playerVisible: boolean;
	userCreated: boolean;
	links: { [key: string]: string };
	createdAt: number;
	updatedAt: number;
	deletedAt: number;
	blocks: SessionBlock[];
}

const sessionProps: ISession = {
	id: 0,
	accountId: 0,
	eventId: 0,
	title: '',
	description: '',
	tags: [],
	defaultLanguage: '',
	playerVisible: false,
	userCreated: false,
	links: {},
	createdAt: 0,
	updatedAt: 0,
	deletedAt: null,
	blocks: [],
};

class Session extends Record(sessionProps, 'Session') implements ISession {
	static normalizr(): schema.Entity {
		return normalizrSchema;
	}
}

export default Session;
