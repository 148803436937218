import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import { Statuses } from 'pkg/api/models/order';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import {
	SubscriptionStatus,
	SubscriptionTypes,
} from 'pkg/api/models/subscription';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import Link from 'pkg/router/Link';
import DateTime from 'pkg/datetime';

import SideBar from 'routes/payments/subscriptions/single/sidebar';
import OrderList from 'routes/payments/orders/list';
import InstallmentsData from 'routes/payments/subscriptions/single/installments_data';

import CancelSubscriptionModal from 'containers/payment_platform/subscriptions/CancelModal';
import UpcomingOrderRow from 'containers/payment_platform/subscriptions/UpcomingOrderRow';
import ProductRow from 'containers/payment_platform/subscriptions/ProductRow';

import SectionTitle from 'components/SectionTitle';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import * as CardList from 'components/card-list';
import Avatar from 'components/avatar';

import StatusLabel from 'components/payment_platform/status/label';
import * as SideBarLayout from 'components/layout/SideBarPage';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

interface SingleSubscriptionProps {
	organizationId: string;
	id: string;
	currentTab: string;
}

export default function SingleSubscription({
	organizationId,
	id,
	currentTab,
}: SingleSubscriptionProps) {
	const [subscriptionModal, setSubScriptionModal] = useState(false);

	const { record: subscription, isLoading: isLoadingSubscription } =
		useEndpoint<models.subscription.Subscription>(
			endpoints.Subscriptions.Show(Number.parseInt(id, 10))
		);

	const group = useCurrentGroup();
	const org = useCurrentOrganization();

	const subscriptionRows =
		models.subscription
			.findProductRows(subscription)
			?.filter(
				(elem, index) =>
					subscription.rows.findIndex(
						(obj) => obj.productPriceId === elem.productPriceId
					) === index
			) || [];

	const assignedContacts = [
		...new Map(
			subscription.rows
				?.filter((row) => row?.user)
				.map((item) => [item.user.id, item.user])
		).values(),
	];

	const hideUpcomingOrder = subscription.status !== SubscriptionStatus.Active;

	const showCancelSubscriptionModal = () => setSubScriptionModal(true);
	const hideCancelSubscriptionModal = () => setSubScriptionModal(false);

	const openInvoices =
		subscription.orders?.filter(
			(order) =>
				order.status !== Statuses.Void &&
				order.status !== Statuses.Paid &&
				!order.refundedAt
		) || [];

	let openInvoicesContent = (
		<MissingEntities
			noBorder
			title={t('No open invoices')}
			description={t('When open invoices exist they will show up here.')}
			image={
				<Icon
					name="order"
					actualSize
					size={4}
					fill={styles.palette.gray[500]}
				/>
			}
		/>
	);

	if (openInvoices.length) {
		openInvoicesContent = (
			<Fragment>
				{openInvoices.map((order) => {
					return (
						<CardList.Base noBorder key={order.id}>
							<CardList.IconWrapper>
								<Icon name="order" />
							</CardList.IconWrapper>
							<CardList.Title>
								<Link href={routes.Order.Show(org.id, order.id)}>
									{formatToCurrency(order.amountDue / 100, order.currency)}
								</Link>
								{order.invoiceNumber && (
									<CardList.SubTitle>{order.invoiceNumber}</CardList.SubTitle>
								)}
							</CardList.Title>
							<CardList.Actions>
								<CardList.TextContent align="center">
									<StatusLabel
										status={{
											name: models.order.getStatus(order).name,
										}}
									/>

									{!!order.dueDate && (
										<CardList.SmallText>
											{t('Due {date}', {
												date: DateTime.fromTimestamp(
													order.dueDate
												).toLocaleDateString({
													year: 'numeric',
													month: 'numeric',
													day: 'numeric',
												}),
											})}
										</CardList.SmallText>
									)}
								</CardList.TextContent>
							</CardList.Actions>
						</CardList.Base>
					);
				})}
			</Fragment>
		);
	}

	if (isLoadingSubscription) {
		return <Spinner />;
	}

	const content =
		currentTab === 'invoices' ? (
			<SideBarLayout.Table>
				<OrderList
					organizationId={Number.parseInt(organizationId, 10)}
					subscriptionId={Number.parseInt(id, 10)}
					hideNewInvoice
				/>
			</SideBarLayout.Table>
		) : (
			<SideBarLayout.Content>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					<Column spacing={styles.spacing._7}>
						{subscription.type === SubscriptionTypes.Installment && (
							<InstallmentsData subscription={subscription} />
						)}
						<Column>
							<SectionTitle>{t('Open invoices')}</SectionTitle>
							{openInvoicesContent}
						</Column>
						{!hideUpcomingOrder && (
							<Column>
								<SectionTitle>
									{t('Upcoming order')}
									<Button
										inline
										noPadding
										icon="chevron_right"
										iconPosition="right"
										href={routes.Subscription.Show(
											org.id,
											subscription.id,
											'invoices'
										)}>
										{t('View all')}
									</Button>
								</SectionTitle>
								<UpcomingOrderRow subscription={subscription} />
							</Column>
						)}
						<Column>
							<SectionTitle>
								{assignedContacts.length > 1
									? t('Products per contact')
									: t('Products')}
							</SectionTitle>

							{subscriptionRows.map((row) => (
								<ProductRow key={row.id} row={row} currency={group.currency} />
							))}
						</Column>
						{assignedContacts.length > 0 && (
							<Column>
								<SectionTitle>{t('Assigned contacts')}</SectionTitle>
								<Column>
									{assignedContacts.map((user, index: number) => (
										<CardList.Base
											noBorder
											key={index}
											href={routes.Management.Contact.Show(
												org.id,
												user.id,
												'overview'
											)}>
											<CardList.Image>
												<Avatar user={user} />
											</CardList.Image>
											<CardList.Title>
												<div>{models.user.fullName(user)}</div>
												<CardList.SubTitle>{user.email}</CardList.SubTitle>
											</CardList.Title>
										</CardList.Base>
									))}
								</Column>
							</Column>
						)}
					</Column>
				</LargeScreenContent.Inner>
			</SideBarLayout.Content>
		);

	return (
		<SideBarLayout.Layout>
			<LargeScreen>
				<SideBar
					subscription={subscription}
					showCancelSubscriptionModal={showCancelSubscriptionModal}
				/>
			</LargeScreen>
			<SmallScreen>
				{currentTab === 'overview' && (
					<SideBar
						subscription={subscription}
						showCancelSubscriptionModal={showCancelSubscriptionModal}
					/>
				)}
			</SmallScreen>
			{content}
			{subscriptionModal && (
				<CancelSubscriptionModal
					subscription={subscription}
					hideModal={hideCancelSubscriptionModal}
				/>
			)}
		</SideBarLayout.Layout>
	);
}
