import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { Group } from 'pkg/api/models/onboarding_info';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import TeamBadge from 'routes/public/styles/TeamBadge';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';

import Column from 'components/layout/column';

export default function CreateWardGroupConfirmation(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const group = onboardingState.get<Group>('group');

	const handleSave = () => {
		goTo('create-ward-user-information');
	};

	return (
		<Column spacing={styles.spacing._8}>
			<Title title={t('Your child is about to join:')} />

			<TeamBadge group={group} />

			<Column>
				<Button primary label={t('Continue')} onClick={handleSave} />
				<Button
					transparent
					label={t('Go back')}
					onClick={() => goTo('create-ward-group-code')}
				/>
			</Column>

			<CancelButton />
		</Column>
	);
}
