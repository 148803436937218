// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lnpUFm0kzZXydBRO_PsG {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
	padding: var(--spacing-5);
}
.lnpUFm0kzZXydBRO_PsG h2 {
		font-size: var(--font-size-2xl);
		color: var(--palette-gray-800);
	}
.lnpUFm0kzZXydBRO_PsG p {
		color: var(--palette-gray-600);
	}
.lnpUFm0kzZXydBRO_PsG .F3Qu55R9KTjYlOGIEwQ8 {
		color: var(--palette-red-500);
	}
`, "",{"version":3,"sources":["webpack://./components/crashes/view-crash/styles.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;CACrB,yBAAyB;AAc1B;AAZC;EACC,+BAA+B;EAC/B,8BAA8B;CAC/B;AAEA;EACC,8BAA8B;CAC/B;AAEA;EACC,6BAA6B;CAC9B","sourcesContent":[".wrapper {\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n\tplace-items: center;\n\tplace-content: center;\n\tpadding: var(--spacing-5);\n\n\t& h2 {\n\t\tfont-size: var(--font-size-2xl);\n\t\tcolor: var(--palette-gray-800);\n\t}\n\n\t& p {\n\t\tcolor: var(--palette-gray-600);\n\t}\n\n\t& .icon {\n\t\tcolor: var(--palette-red-500);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `lnpUFm0kzZXydBRO_PsG`;
export var icon = `F3Qu55R9KTjYlOGIEwQ8`;
export default ___CSS_LOADER_EXPORT___;
