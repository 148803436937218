// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xH3XC8ZsMQUsf1gyWaET {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-7);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	text-align: center;
	width: 100%;
	max-width: 360px;
	margin: 0 auto;
}

@media (max-width: 768px) {

.xH3XC8ZsMQUsf1gyWaET {
		max-width: 100%;
}
	}

.TAUPmC73yCLJySlfIyid {
	height: 100%;
	padding: var(--spacing-7) var(--spacing-7) var(--spacing-9) var(--spacing-7);
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.nEKPWEt2AtRn41cENKcF {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	gap: var(--spacing-3);
	width: 100%;
}

.i0KmCAm_M_I3t7YsbKhF {
	height: 150px;
}

.IeK3GXtQDpe3dYK_Oh_z {
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-3xl);
	line-height: var(--font-line-height-2xl);
}

.V3gdhVMPejJORlSCmjVr {
	color: var(--palette-gray-500);
}
`, "",{"version":3,"sources":["webpack://./components/status-screen/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;CACrB,4BAAsB;CAAtB,6BAAsB;KAAtB,0BAAsB;SAAtB,sBAAsB;CACtB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,kBAAkB;CAClB,WAAW;CACX,gBAAgB;CAChB,cAAc;AAKf;;AAHC;;AAVD;EAWE,eAAe;AAEjB;CADC;;AAGD;CACC,YAAY;CACZ,4EAA4E;CAC5E,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;AACxB;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,4BAAsB;CAAtB,6BAAsB;KAAtB,0BAAsB;SAAtB,sBAAsB;CACtB,qBAAqB;CACrB,WAAW;AACZ;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,wCAAwC;CACxC,+BAA+B;CAC/B,wCAAwC;AACzC;;AAEA;CACC,8BAA8B;AAC/B","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tgap: var(--spacing-7);\n\tflex-direction: column;\n\talign-items: center;\n\ttext-align: center;\n\twidth: 100%;\n\tmax-width: 360px;\n\tmargin: 0 auto;\n\n\t@media (--small-viewport) {\n\t\tmax-width: 100%;\n\t}\n}\n\n.fullscreen {\n\theight: 100%;\n\tpadding: var(--spacing-7) var(--spacing-7) var(--spacing-9) var(--spacing-7);\n\tjustify-content: center;\n}\n\n.childrenWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: var(--spacing-3);\n\twidth: 100%;\n}\n\n.animation {\n\theight: 150px;\n}\n\n.title {\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-3xl);\n\tline-height: var(--font-line-height-2xl);\n}\n\n.description {\n\tcolor: var(--palette-gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `xH3XC8ZsMQUsf1gyWaET`;
export var fullscreen = `TAUPmC73yCLJySlfIyid`;
export var childrenWrapper = `nEKPWEt2AtRn41cENKcF`;
export var animation = `i0KmCAm_M_I3t7YsbKhF`;
export var title = `IeK3GXtQDpe3dYK_Oh_z`;
export var description = `V3gdhVMPejJORlSCmjVr`;
export default ___CSS_LOADER_EXPORT___;
