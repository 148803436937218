// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bXQIFPWRO1A5L6LiFRN1 {
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-bold);
}

.WWzwSZtd41ZyJyMCC7T7 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-2);
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-size: var(--font-size-sm);
	color: var(--palette-gray-600);
}

.W7emQ8p_3RXgtEkDPZBA {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-right: var(--spacing-3);
}

@media (max-width: 768px) {

.W7emQ8p_3RXgtEkDPZBA {
		padding-top: var(--spacing-2);
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		gap: var(--spacing-2);
}
	}

.pGWDxqGouPadObB9AJfo {
	padding-left: var(--spacing-3);
	padding-right: var(--spacing-3);
	-ms-flex-item-align: center;
	    align-self: center;
	background-color: var(--palette-orange-100);
	height: 40px;
	width: 40px;
	border-radius: var(--radius-2);
}

.MLm99KwV7z3y5dyFB17p {
	font-weight: var(--font-weight-semibold);
	padding-left: var(--spacing-2);
}

.eCb_Kp6eTxdPdqGaHWn2 {
	-ms-flex-item-align: center;
	    align-self: center;
}

.JIaX8ePVHBQaES81OX86 {
	display: grid;
	grid-template-columns: 40px 1fr auto;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.VJn8CQsyhloeP0zaR0vQ {
	height: 100%;
	width: 100%;
}

.Fsismjd8F8QJt1mbDIAa {
	font-size: var(--font-size-xs);
	color: var(--palette-gray-500);
}

.Q9p6xLPol66DyVGyhKL_ {
	padding-top: var(--spacing-4);
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/tabs/overview/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,oCAAoC;AACrC;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,+BAA+B;AAOhC;;AALC;;AAJD;EAKE,6BAA6B;EAC7B,4BAAsB;EAAtB,6BAAsB;MAAtB,0BAAsB;UAAtB,sBAAsB;EACtB,qBAAqB;AAEvB;CADC;;AAGD;CACC,8BAA8B;CAC9B,+BAA+B;CAC/B,2BAAkB;KAAlB,kBAAkB;CAClB,2CAA2C;CAC3C,YAAY;CACZ,WAAW;CACX,8BAA8B;AAC/B;;AAEA;CACC,wCAAwC;CACxC,8BAA8B;AAC/B;;AAEA;CACC,2BAAkB;KAAlB,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,oCAAoC;CACpC,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB;;AAEA;CACC,YAAY;CACZ,WAAW;AACZ;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,6BAA6B;AAC9B","sourcesContent":[".title {\n\tfont-size: var(--font-size-lg);\n\tfont-weight: var(--font-weight-bold);\n}\n\n.info {\n\tdisplay: flex;\n\tgap: var(--spacing-2);\n\talign-items: center;\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-600);\n}\n\n.infoRow {\n\tdisplay: flex;\n\tpadding-right: var(--spacing-3);\n\n\t@media (--small-viewport) {\n\t\tpadding-top: var(--spacing-2);\n\t\tflex-direction: column;\n\t\tgap: var(--spacing-2);\n\t}\n}\n\n.largeIcon {\n\tpadding-left: var(--spacing-3);\n\tpadding-right: var(--spacing-3);\n\talign-self: center;\n\tbackground-color: var(--palette-orange-100);\n\theight: 40px;\n\twidth: 40px;\n\tborder-radius: var(--radius-2);\n}\n\n.sessionTitle {\n\tfont-weight: var(--font-weight-semibold);\n\tpadding-left: var(--spacing-2);\n}\n\n.context {\n\talign-self: center;\n}\n\n.emptyState {\n\tdisplay: grid;\n\tgrid-template-columns: 40px 1fr auto;\n\talign-items: center;\n}\n\n.icon {\n\theight: 100%;\n\twidth: 100%;\n}\n\n.strainDescription {\n\tfont-size: var(--font-size-xs);\n\tcolor: var(--palette-gray-500);\n}\n\n.smallScreenEmptyState {\n\tpadding-top: var(--spacing-4);\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `bXQIFPWRO1A5L6LiFRN1`;
export var info = `WWzwSZtd41ZyJyMCC7T7`;
export var infoRow = `W7emQ8p_3RXgtEkDPZBA`;
export var largeIcon = `pGWDxqGouPadObB9AJfo`;
export var sessionTitle = `MLm99KwV7z3y5dyFB17p`;
export var context = `eCb_Kp6eTxdPdqGaHWn2`;
export var emptyState = `JIaX8ePVHBQaES81OX86`;
export var icon = `VJn8CQsyhloeP0zaR0vQ`;
export var strainDescription = `Fsismjd8F8QJt1mbDIAa`;
export var smallScreenEmptyState = `Q9p6xLPol66DyVGyhKL_`;
export default ___CSS_LOADER_EXPORT___;
