import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { EventTypes } from 'pkg/api/models/event';

import * as formatting from 'routes/kitchen_sink/formatting.css';

import Label from 'components/label';
import Tag, { TagColors } from 'components/Tag';

import Column from 'components/layout/column';

import * as css from './styles.css';

export function LabelsView() {
	const labelColors = [
		'green',
		'gray',
		'orange',
		'red',
		'blue',
		'yellow',
		'purple',
	];

	return (
		<Fragment>
			<Column spacing={styles.spacing._7}>
				<h1 className={formatting.h1}>Labels</h1>

				<hr className={formatting.divider} />

				<h2 className={formatting.h2}>Label</h2>

				<Column>
					<Column spacing={styles.spacing._2}>
						<h3 className={formatting.h3}>Standard labels</h3>
						<div className={css.labelWrapper}>
							{labelColors.map((color, i) => (
								<Label key={i} color={color}>
									{color}
								</Label>
							))}
						</div>
					</Column>

					<Column spacing={styles.spacing._2}>
						<h3 className={formatting.h3}>Event type labels</h3>
						<div className={css.labelWrapper}>
							{Object.values(EventTypes).map((type, i) => (
								<Label key={i} eventType={type}>
									{type}
								</Label>
							))}
						</div>
					</Column>
				</Column>

				<h2 className={formatting.h2}>Tag</h2>

				<Column>
					<Column spacing={styles.spacing._2}>
						<h3 className={formatting.h3}>Standard</h3>
						<div className={css.labelWrapper}>
							{Object.keys(TagColors).map((tag, i) => (
								<Tag key={i} name={tag} type={tag} />
							))}
						</div>
					</Column>

					<Column spacing={styles.spacing._2}>
						<h3 className={formatting.h3}>Active</h3>
						<div className={css.labelWrapper}>
							{Object.keys(TagColors).map((tag, i) => (
								<Tag key={i} name={tag} type={tag} active />
							))}
						</div>
					</Column>
				</Column>
			</Column>
		</Fragment>
	);
}
