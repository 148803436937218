// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OM9zSwnBAoHxU2L8KdpO {
	display: grid;
	grid-template-columns: 2rem 1fr 1.5rem;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	grid-gap: 0.5rem;
}

.BYt9KaoC2qupgsG5vGaU {
	-webkit-transition: color 250ms ease-in-out;
	transition: color 250ms ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/comments/form/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sCAAsC;CACtC,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,gBAAgB;AACjB;;AAEA;CACC,2CAAmC;CAAnC,mCAAmC;AACpC","sourcesContent":[".commentFormWrapper {\n\tdisplay: grid;\n\tgrid-template-columns: 2rem 1fr 1.5rem;\n\talign-items: center;\n\tjustify-content: center;\n\tgrid-gap: 0.5rem;\n}\n\n.iconStyling {\n\ttransition: color 250ms ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
export var commentFormWrapper = `OM9zSwnBAoHxU2L8KdpO`;
export var iconStyling = `BYt9KaoC2qupgsG5vGaU`;
export default ___CSS_LOADER_EXPORT___;
