// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SmKSaiokqpXoaCKviwJ6 {
	display: grid;
	grid-auto-flow: column;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: start;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: start;
	grid-column-gap: 10px;
	width: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
`, "",{"version":3,"sources":["webpack://./components/attachment/hooks/useAttachments/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,wBAAkB;KAAlB,qBAAkB;SAAlB,kBAAkB;CAClB,uBAAsB;KAAtB,oBAAsB;SAAtB,sBAAsB;CACtB,qBAAqB;CACrB,WAAW;CACX,cAAc;CACd,iCAAiC;AAClC","sourcesContent":[".wrapper {\n\tdisplay: grid;\n\tgrid-auto-flow: column;\n\talign-items: start;\n\tjustify-content: start;\n\tgrid-column-gap: 10px;\n\twidth: 100%;\n\toverflow: auto;\n\t-webkit-overflow-scrolling: touch;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `SmKSaiokqpXoaCKviwJ6`;
export default ___CSS_LOADER_EXPORT___;
