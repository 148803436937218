// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lCakyUj4juaEFDXfwmYY {
	display: inline-block;
	width: 35px;
}

.oINZeCT9X8LZahz7MEye {
	width: 35px;
	height: 35px;
	margin: 0 auto;
	color: var(--palette-gray-500);
}

.SutSZEa2p0C_QRwu4MHA {
	height: 40px;
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/tabs/summary/table-header/styles.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,WAAW;AACZ;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,cAAc;CACd,8BAA8B;AAC/B;;AAEA;CACC,YAAY;AACb","sourcesContent":[".badgeWrapper {\n\tdisplay: inline-block;\n\twidth: 35px;\n}\n\n.placeHolderIcon {\n\twidth: 35px;\n\theight: 35px;\n\tmargin: 0 auto;\n\tcolor: var(--palette-gray-500);\n}\n\n.tableRow {\n\theight: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export var badgeWrapper = `lCakyUj4juaEFDXfwmYY`;
export var placeHolderIcon = `oINZeCT9X8LZahz7MEye`;
export var tableRow = `SutSZEa2p0C_QRwu4MHA`;
export default ___CSS_LOADER_EXPORT___;
