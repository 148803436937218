// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B7tykstqsSyMjEuNtST4 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-weight: var(--font-weight-semibold);
}

.BLtTZN0LGZThVv_88fQK {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.fLPjeS2XYmW5LVZeHG5M {
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	visibility: hidden;
}

.fLPjeS2XYmW5LVZeHG5M.JL2w5t8cZYYiGMNNa0NO {
		opacity: 1;
		visibility: visible;
	}
`, "",{"version":3,"sources":["webpack://./components/calendar/schedule-date-select/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wCAAwC;AACzC;;AAEA;CACC,mBAAmB;CACnB,uBAAuB;CACvB,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,wCAAgC;CAAhC,gCAAgC;CAChC,kBAAkB;AAMnB;;AAJC;EACC,UAAU;EACV,mBAAmB;CACpB","sourcesContent":[".dateContextToggle {\n\tdisplay: flex;\n\talign-items: center;\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.dateString {\n\twhite-space: nowrap;\n\ttext-overflow: ellipsis;\n\toverflow: hidden;\n}\n\n.todayWrapper {\n\topacity: 0;\n\ttransition: all 0.2s ease-in-out;\n\tvisibility: hidden;\n\n\t&.show {\n\t\topacity: 1;\n\t\tvisibility: visible;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var dateContextToggle = `B7tykstqsSyMjEuNtST4`;
export var dateString = `BLtTZN0LGZThVv_88fQK`;
export var todayWrapper = `fLPjeS2XYmW5LVZeHG5M`;
export var show = `JL2w5t8cZYYiGMNNa0NO`;
export default ___CSS_LOADER_EXPORT___;
