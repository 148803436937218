import { t } from '@transifex/native';
import { ChangeEvent, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import Link from 'pkg/router/Link';
import * as api from 'pkg/api';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Account } from 'pkg/api/models/onboarding_info';

import Field from 'routes/public/styles/inputs/field';
import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import FooterNote from 'routes/public/styles/FooterNote';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import Header from 'routes/public/no-memberships/styles/Header';
import Form from 'routes/public/styles/forms';

import Column from 'components/layout/column';

export default function GroupCode(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState<string>('');
	const [groupCode, setGroupCode] = useState<string>();

	const targetAccount = onboardingState.get<Account>('targetAccount');

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setError('');
		setGroupCode(event.target.value);
	};

	const handleSave = async () => {
		setIsSaving(true);

		const [request, group] = await api.get<models.onboardingInfo.Group>(
			endpoints.Accounts.OnboardingInfo() + `?invite_code=${groupCode}`
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Invalid group code'),
				message: t('The group code used is not valid.'),
			});

			setError(t('Invalid group code'));

			setIsSaving(false);
		} else {
			onboardingState.set({
				groupCode: groupCode.toUpperCase(),
				group,
			});

			if (targetAccount) {
				goTo('ward-group-confirmation');
			} else {
				goTo('group-confirmation');
			}
		}
	};

	return (
		<Column spacing={styles.spacing._8}>
			<Header />

			<Form onSubmit={handleSave}>
				<Column spacing={styles.spacing._8}>
					<Title title={t('Enter a group code to continue')} center />

					<Column>
						<Field
							name="group-code"
							variant="group-code"
							placeholder={t('Group code')}
							required
							value={groupCode}
							onChange={handleChange}
							errorMessage={error}
							persistentErrorMessage={!!error}
							validateOnChange
						/>
						<Button
							primary
							label={t('Continue')}
							type="submit"
							busy={isSaving}
						/>
					</Column>

					<FooterNote>
						{t('Join a group with a group code that you get from your club.')}
					</FooterNote>

					<FooterNote>
						<Link href="https://help.360player.com/article/149-i-dont-have-a-group-code">
							{t(`I don't have a group code`)}
						</Link>
					</FooterNote>
				</Column>
			</Form>

			<CancelButton disabled={isSaving} />
		</Column>
	);
}
