import { MouseEvent } from 'react';
import styled from 'styled-components';

import * as models from 'pkg/api/models';

import Avatar from 'components/avatar';

const AccountCardWrapper = styled.div`
	display: grid;
	grid-template-areas: 'avatar name ' 'avatar text ';
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto;
	align-items: center;
	gap: var(--spacing-1) var(--spacing-4);
	background: var(--palette-white);
	padding: var(--spacing-4);
	border-radius: var(--radius-5);
	cursor: pointer;

	strong {
		grid-area: name;
		align-self: flex-end;
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		color: var(--palette-gray-900);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	span {
		grid-area: text;
		align-self: flex-start;
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const AvatarWrapper = styled.div`
	grid-area: avatar;
	display: grid;
	align-items: center;
	justify-items: center;
	width: 44px;
	height: 44px;

	${Avatar} {
		width: 44px;
		height: 44px;
	}
`;

interface AccountCardProps {
	account: models.account.Account;
	onClick?: (event?: MouseEvent | TouchEvent | PointerEvent) => void;
}

export default function AccountCard({
	account,
	onClick,
}: AccountCardProps): JSX.Element {
	return (
		<AccountCardWrapper onClick={onClick}>
			<AvatarWrapper>
				<Avatar account={account} />
			</AvatarWrapper>
			<strong data-testid="no_memberships.account_card.name">
				{models.account.fullName(account)}
			</strong>
			<span data-testid="no_memberships.account_card.email">
				{account.email}
			</span>
		</AccountCardWrapper>
	);
}
