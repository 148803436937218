import * as json from 'pkg/json';

interface Message<T = string> {
	type: string;
	data: T;
}

type MessageListener<T = string> = (message: Message<T>) => void;

type MessageProcess<K, T> = (type: K, message: Message<T>) => void;

type NoOp = () => void;
const NoOpFn: NoOp = () => null;

export type WebSocketConnectionChange = 'opening' | 'retrying' | 'closing';

type StateChangeListener = (type: WebSocketConnectionChange) => void;

type SubscriptionChangeListener = (channels: string[]) => void;

export type ResumeConnectionListener = () => void;

interface WebSocketClientOptions {
	maxRetries: number;
	retryInterval: number;

	onOpen: NoOp;
	onClose: NoOp;
	onRetry: NoOp;
	onReconnect: NoOp;
	onUnstableConnection: NoOp;
	onError: (event: Event) => void;
}

const DefaultClientOptions: WebSocketClientOptions = {
	maxRetries: 10,
	retryInterval: 2000,

	onOpen: NoOpFn,
	onClose: NoOpFn,
	onRetry: NoOpFn,
	onReconnect: NoOpFn,
	onUnstableConnection: NoOpFn,
	onError: NoOpFn,
};

export class WebSocketClient<MessageType = string> {
	private client: WebSocket;
	private clientOptions: WebSocketClientOptions;

	public isConnected: boolean = false;
	private isRetrying: boolean = false;
	private numRetries: number = 0;

	private messageQueue: Message<unknown>[] = [];
	private messageListeners: Map<MessageType, MessageListener<unknown>>;
	private messageProcessCallback: MessageProcess<MessageType, unknown>;

	private connectionStateListener: StateChangeListener;
	private resumeConnectionListener: ResumeConnectionListener;

	private channels: Set<string> = new Set<string>(['global']);
	private subscriptionChangeListener: SubscriptionChangeListener;

	constructor(
		public webSocketUrl: string,
		clientOptions: Partial<WebSocketClientOptions> = {}
	) {
		this.clientOptions = {
			...DefaultClientOptions,
			...clientOptions,
		};

		this.connect();

		this.messageListeners = new Map<MessageType, MessageListener<unknown>>();
	}

	public get canSendData(): boolean {
		return this.client.readyState === WebSocket.OPEN;
	}

	public get isUnstable(): boolean {
		// Assume connection is unstable on the second retry attempt.
		// This is to prevent <UnstableWebSocketBoundary> from flashing for quick connection losses.
		return !this.isConnected && this.isRetrying && this.numRetries > 1;
	}

	private invoke(listenerName: keyof WebSocketClientOptions) {
		if (listenerName.startsWith('on')) {
			(this.clientOptions[listenerName] as NoOp)();
		}
	}

	public send<T = string>(...messages: Message<T>[]): void {
		this.messageQueue.push(...messages);

		this.deliver();
	}

	public deliver(): void {
		if (!this.client || !this.canSendData) return;

		while (this.messageQueue.length > 0) {
			const message = this.messageQueue.shift();

			const payload = json.stringify({
				type: message.type,
				data: json.stringify(message.data),
			});

			this.client.send(payload);

			this.deliver();
		}
	}

	public connect() {
		const socketOpen = () => {
			if (this.connectionStateListener) {
				this.connectionStateListener('opening');
			}

			if (this.isRetrying) {
				this.invoke('onReconnect');
			}

			this.isConnected = true;
			this.numRetries = 0;
			this.isRetrying = false;

			if (this.resumeConnectionListener) {
				this.resumeConnectionListener();
			}

			this.invoke('onOpen');
			this.deliver();
		};

		const socketClose = (event: CloseEvent) => {
			this.isConnected = false;

			if (!event.wasClean) {
				if (this.connectionStateListener) {
					this.connectionStateListener('retrying');
				}

				this.isRetrying = true;

				if (this.numRetries === this.clientOptions.maxRetries) {
					this.numRetries = 0;

					this.invoke('onUnstableConnection');
				}

				this.numRetries++;
				window.setTimeout(
					() => this.connect(),
					this.clientOptions.retryInterval
				);
			}

			if (event.wasClean) {
				if (this.connectionStateListener) {
					this.connectionStateListener('closing');
				}

				this.invoke('onClose');

				this.isConnected = false;
				this.isRetrying = false;
				this.numRetries = 0;
			}
		};

		const socketMessage = (event: MessageEvent) => {
			if (!event.data) return;

			const message: Message = json.parse(event.data);

			if (this.messageListeners.has(message.type as MessageType)) {
				this.messageListeners.get(message.type as MessageType)(message);
				return;
			}

			if (this.messageProcessCallback) {
				this.messageProcessCallback(message.type as MessageType, message);
			}
		};

		if (this.client) {
			this.client.removeEventListener('open', socketOpen);
			this.client.removeEventListener('close', socketClose);
			this.client.removeEventListener('message', socketMessage);
		}

		this.client = new WebSocket(this.webSocketUrl);

		this.client.addEventListener('open', socketOpen);
		this.client.addEventListener('close', socketClose);
		this.client.addEventListener('message', socketMessage);
	}

	public disconnect() {
		if (this.isConnected) {
			this.client.close();
		}
	}

	public subscribe(...channels: string[]): void {
		if (channels.length === 0) return;

		channels.forEach((channel: string) => {
			this.channels.add(channel);
		});

		if (this.subscriptionChangeListener) {
			this.subscriptionChangeListener(Array.from(this.channels));
		}
	}

	public unsubscribe(...channels: string[]): void {
		if (channels.length === 0) return;

		channels.forEach((channel: string) => {
			this.channels.delete(channel);
		});

		if (this.subscriptionChangeListener) {
			this.subscriptionChangeListener(Array.from(this.channels));
		}
	}

	public getSubscribedChannels(): string[] {
		return Array.from(this.channels);
	}

	public onSubscriptionChange(listener: SubscriptionChangeListener): void {
		this.subscriptionChangeListener = listener;
	}

	public onConnectionChange(listener: StateChangeListener): void {
		this.connectionStateListener = listener;
	}

	public onResume(listener: ResumeConnectionListener | null): void {
		this.resumeConnectionListener = listener;
	}

	public onMessage<T>(type: MessageType, listener: MessageListener<T>): void {
		this.messageListeners.set(type, listener);
	}

	public processMessage(callback: MessageProcess<MessageType, unknown>): void {
		this.messageProcessCallback = callback;
	}
}
