// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deCdiklC6qAnscmN0XVt {
	display: inline-block;
	cursor: pointer;
	color: var(--palette-blue-500);
	color: var(--color, var(--palette-blue-500));
	font-weight: var(--font-weight-semibold);
}
`, "",{"version":3,"sources":["webpack://./routes/payments/products/form/price_option/styles.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,eAAe;CACf,8BAA4C;CAA5C,4CAA4C;CAC5C,wCAAwC;AACzC","sourcesContent":[".priceLink {\n\tdisplay: inline-block;\n\tcursor: pointer;\n\tcolor: var(--color, var(--palette-blue-500));\n\tfont-weight: var(--font-weight-semibold);\n}\n"],"sourceRoot":""}]);
// Exports
export var priceLink = `deCdiklC6qAnscmN0XVt`;
export default ___CSS_LOADER_EXPORT___;
