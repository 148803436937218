import { memo, ReactElement } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { cssClasses, cssVarList } from 'pkg/css/utils';

import Avatar from 'components/avatar';

import * as css from './styles.css';

interface AvatarStackProps {
	size?: number;
	showRest?: boolean;
	prefixWith?: ReactElement<any, any>;
	renderTriggerWith?: ReactElement<any, any>;
	renderEmptyWith?: ReactElement<any, any>;
	renderRestWith?: string | JSX.Element;
	backdropColor?: string;

	onClick?: () => void;

	children?: JSX.Element;
	users?: models.user.User[];
	accounts?: models.account.Account[];
}

// This is the same as 'components/avatar/Stack' but this uses the new models.
function AvatarStack({
	users,
	accounts,
	size = 30,
	showRest = true,
	onClick = null,
	prefixWith = null,
	renderTriggerWith = null,
	renderEmptyWith = null,
	renderRestWith = null,
	backdropColor,
	children,
}: AvatarStackProps) {
	if ((!accounts || accounts.length === 0) && (!users || users.length === 0)) {
		return renderEmptyWith;
	}

	const numAccounts = accounts?.length;
	const numUsers = users?.length;

	let truncatedAccounts: models.account.Account[] = [];
	let truncatedUsers: models.user.User[] = [];

	let multiplier: number;
	let num: number;

	if (numAccounts && numAccounts > 0) {
		truncatedAccounts = accounts.slice(0, renderTriggerWith ? 2 : 3);
		multiplier = truncatedAccounts.length + (renderTriggerWith ? 1 : 0);
		num = truncatedAccounts.length;
	} else if (numUsers && numUsers > 0) {
		truncatedUsers = users.slice(0, renderTriggerWith ? 2 : 3);
		multiplier = truncatedUsers.length + (renderTriggerWith ? 1 : 0);
		num = truncatedUsers.length;
	}

	let wrapperSize = size * multiplier;

	if (num === 1) {
		wrapperSize = size;
	} else if (num === 2) {
		wrapperSize = wrapperSize - size * 0.3;
	} else {
		wrapperSize = wrapperSize - size * 0.3 * 2;
	}

	return (
		<div
			className={cssClasses(css.wrapper, onClick ? css.onClick : '')}
			onClick={onClick}>
			{prefixWith}
			<div
				className={css.stackedAvatars}
				style={cssVarList({
					size: `${wrapperSize}px`,
				})}>
				{truncatedAccounts?.map((account, n: number) => (
					<Avatar
						key={`stacked-account-${n}`}
						size={size}
						account={account}
						className={css.stackedAvatar}
						style={cssVarList({
							backdropColor,
						})}
					/>
				))}
				{truncatedUsers?.map((user, n: number) => (
					<Avatar
						key={`stacked-user-${n}`}
						size={size}
						user={user}
						className={css.stackedAvatar}
						style={cssVarList({
							backdropColor,
						})}
					/>
				))}
				{renderTriggerWith && (
					<div
						className={css.trigger}
						style={cssVarList({
							size: `${size}px`,
						})}>
						{renderTriggerWith}
					</div>
				)}
			</div>
			{showRest && !renderRestWith && num > 0 && (
				<div className={css.rest}>{t('+{num} more', { num })}</div>
			)}
			{renderRestWith && <div className={css.rest}>{renderRestWith}</div>}
			{children}
		</div>
	);
}

export default memo(AvatarStack);
