// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NkBvuSBnU_rY3ZwhVdaT {
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
}
`, "",{"version":3,"sources":["webpack://./routes/account/settings/privacy/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B","sourcesContent":[".hint {\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
export var hint = `NkBvuSBnU_rY3ZwhVdaT`;
export default ___CSS_LOADER_EXPORT___;
