// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g91azN7vzyXIzGjqomeP {
	display: grid;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
}

.RcmtBeSG30YLMks63Ph5 {
	width: 320px;
	height: 320px;
	position: relative;
	display: grid;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
}

.o_X7s6gXQrwAe7y8HQcb {
	--size: 30px;
	--offset: var(--spacing-3);

	background-color: var(--palette-white);
	width: var(--size);
	height: var(--size);
	display: grid;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	position: absolute;
	right: var(--offset);
	top: var(--offset);
	border-radius: 3px;
	-webkit-box-shadow: 0 0 0 3px hsla(0, 0%, 0%, 0.3);
	        box-shadow: 0 0 0 3px hsla(0, 0%, 0%, 0.3);
}

.L5zwn7hSl6jHzAO4XXCJ {
	width: 320px;
	height: 320px;
	display: grid;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	background-color: var(--palette-gray-300);
	border-radius: 10px;
}

.U1v8aUhf8uZzvqfBmIB6 {
	pointer-events: none;
}

.eBFdVULQYiu2PIvvuG1d {
	margin-top: var(--spacing-4);
	width: 100%;
}
`, "",{"version":3,"sources":["webpack://./routes/group/settings/badge/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;AACpB;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,kBAAkB;CAClB,aAAa;CACb,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;AACpB;;AAEA;CACC,YAAY;CACZ,0BAA0B;;CAE1B,sCAAsC;CACtC,kBAAkB;CAClB,mBAAmB;CACnB,aAAa;CACb,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,kBAAkB;CAClB,oBAAoB;CACpB,kBAAkB;CAClB,kBAAkB;CAClB,kDAA4C;SAA5C,0CAA4C;AAC7C;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,aAAa;CACb,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,yCAAyC;CACzC,mBAAmB;AACpB;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,4BAA4B;CAC5B,WAAW;AACZ","sourcesContent":[".badgeEditor {\n\tdisplay: grid;\n\tplace-content: center;\n\tplace-items: center;\n}\n\n.editor {\n\twidth: 320px;\n\theight: 320px;\n\tposition: relative;\n\tdisplay: grid;\n\tplace-content: center;\n\tplace-items: center;\n}\n\n.fileTrigger {\n\t--size: 30px;\n\t--offset: var(--spacing-3);\n\n\tbackground-color: var(--palette-white);\n\twidth: var(--size);\n\theight: var(--size);\n\tdisplay: grid;\n\tplace-content: center;\n\tplace-items: center;\n\tposition: absolute;\n\tright: var(--offset);\n\ttop: var(--offset);\n\tborder-radius: 3px;\n\tbox-shadow: 0 0 0 3px hsla(0deg 0% 0% / 30%);\n}\n\n.uploadTrigger {\n\twidth: 320px;\n\theight: 320px;\n\tdisplay: grid;\n\tplace-content: center;\n\tplace-items: center;\n\tbackground-color: var(--palette-gray-300);\n\tborder-radius: 10px;\n}\n\n.uploadButton {\n\tpointer-events: none;\n}\n\n.scale {\n\tmargin-top: var(--spacing-4);\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export var badgeEditor = `g91azN7vzyXIzGjqomeP`;
export var editor = `RcmtBeSG30YLMks63Ph5`;
export var fileTrigger = `o_X7s6gXQrwAe7y8HQcb`;
export var uploadTrigger = `L5zwn7hSl6jHzAO4XXCJ`;
export var uploadButton = `U1v8aUhf8uZzvqfBmIB6`;
export var scale = `eBFdVULQYiu2PIvvuG1d`;
export default ___CSS_LOADER_EXPORT___;
