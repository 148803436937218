import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';

import StatusScreen from 'components/status-screen';

import Button from 'design/button';

const Success = () => {
	return (
		<StatusScreen
			fullscreen
			status="success"
			title={t('Success!')}
			description={t('The registration was made successfully!')}>
			<Button primary large href={routes.Home()}>
				{t('Go to home')}
			</Button>
		</StatusScreen>
	);
};

export default Success;
