// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XdBBjiiU7VEsAY5w4ksb {
	background-color: var(--palette-gray-200);
	color: var(--palette-gray-800);
	padding: var(--spacing-3) var(--spacing-4);
	font-weight: var(--font-weight-semibold);
	border-bottom: 1px solid var(--palette-gray-300);
	font-size: var(--font-size-sm);
}
`, "",{"version":3,"sources":["webpack://./design/table/table-title/styles.css"],"names":[],"mappings":"AAAA;CACC,yCAAyC;CACzC,8BAA8B;CAC9B,0CAA0C;CAC1C,wCAAwC;CACxC,gDAAgD;CAChD,8BAA8B;AAC/B","sourcesContent":[".wrapper {\n\tbackground-color: var(--palette-gray-200);\n\tcolor: var(--palette-gray-800);\n\tpadding: var(--spacing-3) var(--spacing-4);\n\tfont-weight: var(--font-weight-semibold);\n\tborder-bottom: 1px solid var(--palette-gray-300);\n\tfont-size: var(--font-size-sm);\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `XdBBjiiU7VEsAY5w4ksb`;
export default ___CSS_LOADER_EXPORT___;
