import * as Onboarding from 'routes/public/onboarding';
import * as requiredData from 'routes/public/onboarding/requiredData';
import ChildGroupConfirmation from 'routes/public/no-memberships/steps/groups/WardGroupConfirmation';
import Claim from 'routes/public/no-memberships/steps/Claim';
import CreateChildAccountCreated from 'routes/public/no-memberships/steps/ward-connections/CreateWardAccountCreated';
import CreateChildAccountDetails from 'routes/public/no-memberships/steps/ward-connections/CreateWardAccountDetails';
import CreateWardGroupCode from 'routes/public/no-memberships/steps/ward-connections/CreateWardGroupCode';
import CreateWardGroupConfirmation from 'routes/public/no-memberships/steps/ward-connections/CreateWardGroupConfirmation';
import CreateWardOwnAccount from 'routes/public/no-memberships/steps/ward-connections/CreateWardOwnAccount';
import CreateWardUserInformation from 'routes/public/no-memberships/steps/ward-connections/CreateWardUserInformation';
import GroupCode from 'routes/public/no-memberships/steps/groups/GroupCode';
import GroupConfirmation from 'routes/public/no-memberships/steps/groups/GroupConfirmation';
import AutoJoin from 'routes/public/no-memberships/steps/join-group/AutoJoin';
import NewWardConnection from 'routes/public/no-memberships/steps/ward-connections/NewWardConnection';
import NewGroupConnection from 'routes/public/no-memberships/steps/groups/NewGroupConnection';
import OnboardingWrapper from 'routes/public/styles/OnboardingWrapper';
import Start from 'routes/public/no-memberships/steps/Start';
import JoinGroupChildConfirmation from 'routes/public/no-memberships/steps/join-group/WardConfirmation';
import JoinGroupConfirmation from 'routes/public/no-memberships/steps/join-group/Confirmation';
import JoinGroupChooseAccount from 'routes/public/no-memberships/steps/join-group/ChooseAccount';
import GdprRequest from 'routes/public/steps/gdpr/GdprRequest';
import GdprRequestPending from 'routes/public/steps/gdpr/GdprRequestPending';
import GdprConsent from 'routes/public/steps/gdpr/GdprConsent';

export default function NoMemberships(): JSX.Element {
	return (
		<OnboardingWrapper>
			<Onboarding.Wrapper initialSlug="start" storePrefix="no-memberships">
				<Onboarding.Step slug="start" component={Start} />

				{/* Ward connections */}
				<Onboarding.Step
					slug="new-ward-connection"
					backSlug="start"
					component={NewWardConnection}
				/>
				<Onboarding.Step
					slug="create-ward-group-code"
					backSlug="new-ward-connection"
					component={CreateWardGroupCode}
				/>
				<Onboarding.Step
					slug="create-ward-group-confirmation"
					backSlug="create-ward-group-code"
					component={CreateWardGroupConfirmation}
					requiredData={requiredData.createChildGroupConfirmation}
				/>
				<Onboarding.Step
					slug="create-ward-user-information"
					backSlug="create-ward-group-confirmation"
					component={CreateWardUserInformation}
					requiredData={requiredData.createChildUserInformation}
				/>
				<Onboarding.Step
					slug="create-ward-own-account"
					backSlug="create-ward-user-information"
					component={CreateWardOwnAccount}
					requiredData={requiredData.createChildOwnAccount}
				/>
				<Onboarding.Step
					slug="create-ward-account-details"
					backSlug="create-ward-own-account"
					component={CreateChildAccountDetails}
					requiredData={requiredData.createChildAccountDetails}
				/>
				<Onboarding.Step
					slug="create-ward-account-created"
					component={CreateChildAccountCreated}
				/>

				{/* Groups */}
				<Onboarding.Step
					slug="new-group-connection"
					backSlug="start"
					component={NewGroupConnection}
				/>
				<Onboarding.Step
					slug="group-code"
					backSlug="start"
					component={GroupCode}
				/>
				<Onboarding.Step
					slug="ward-group-confirmation"
					backSlug="group-code"
					component={ChildGroupConfirmation}
					requiredData={requiredData.childGroupConfirmation}
				/>
				<Onboarding.Step
					slug="group-confirmation"
					backSlug="group-code"
					component={GroupConfirmation}
					requiredData={requiredData.groupConfirmation}
				/>

				{/* Join group from link */}
				<Onboarding.Step slug="join" backSlug="start" component={AutoJoin} />
				<Onboarding.Step
					slug="join-group-choose-account"
					component={JoinGroupChooseAccount}
				/>
				<Onboarding.Step
					slug="join-group-confirmation"
					component={JoinGroupConfirmation}
				/>
				<Onboarding.Step
					slug="join-group-ward-confirmation"
					component={JoinGroupChildConfirmation}
				/>

				{/* GDPR (parental consent needed, same components as we use in the onboarding) */}
				<Onboarding.Step slug="gdpr-request" component={GdprRequest} />
				<Onboarding.Step
					slug="gdpr-request-pending"
					component={GdprRequestPending}
				/>
				<Onboarding.Step
					slug="parental-consent-request"
					component={GdprConsent}
				/>

				{/* Claim */}
				<Onboarding.Step slug="claim" backSlug="start" component={Claim} />
				{/* Used for old invite links: /signup=invite_key */}
				<Onboarding.Step slug="signup" backSlug="start" component={Claim} />
			</Onboarding.Wrapper>
		</OnboardingWrapper>
	);
}
