import React, {
	useState,
	useRef,
	useLayoutEffect,
	ReactNode,
	useCallback,
	useContext,
	Fragment,
	MouseEvent,
	createContext,
	PointerEvent,
	ElementType,
	CSSProperties,
} from 'react';
import DOM from 'react-dom';
import { ThemeProvider, DefaultTheme, useTheme } from 'styled-components';
import { t } from '@transifex/native';

import { FontWeights } from 'pkg/config/fonts';

import { omit } from 'pkg/objects';
import Link from 'pkg/router/Link';
import useTooltip from 'pkg/hooks/useTooltip';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import { openUGCReport } from 'pkg/helpscout';
import { useCurrentAccount, useCurrentMembership } from 'pkg/identity';
import { cssClasses } from 'pkg/css/utils';

import MaterialSymbol from 'components/material-symbols';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import { ScrollSpy } from 'components/ScrollSpy';
import Icon from 'components/icon';
import Backdrop from 'components/Backdrop';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import * as Input from 'components/form/inputs';

import Button, { ButtonComponentProps } from 'design/button';
import * as contextStyles from 'design/context_menu/styles';

import { JustifyContentValues } from 'styles/types';

import * as css from './styles.css';

interface MenuContextProps {
	close: (event?: PointerEvent<HTMLDivElement>) => void;
}

const DefaultContextMenu: MenuContextProps = {
	close: () => null,
};

export const MenuContext = createContext<MenuContextProps>(DefaultContextMenu);

export function ButtonTrigger(props: ButtonComponentProps) {
	return (
		<Button
			className={cssClasses(props.className, css.buttonTrigger)}
			secondary
			{...props}>
			{props.children}
		</Button>
	);
}

interface TableTriggerProps {
	children: ReactNode;
	className?: string;
}

export function TableTrigger({ children, className }: TableTriggerProps) {
	return (
		<span className={cssClasses(css.tableTrigger, className)}>{children}</span>
	);
}

interface DividerProps {
	// set to true of the divider should have no spacing
	tight?: boolean;
	// set to true to make the background transparent
	hide?: boolean;
	// set to true of the divider should have no spacing in the bottom
	tightBottom?: boolean;
	className?: string;
}

export function Divider({ tight, hide, tightBottom, className }: DividerProps) {
	return (
		<div
			className={cssClasses(
				css.divider,
				tight && css.tight,
				tightBottom && css.tightBottom,
				hide && css.hide,
				className
			)}
		/>
	);
}

interface ItemIconProps {
	name: MaterialSymbolVariant;
	fill?: string;
	style?: React.CSSProperties;
}

export function ItemIcon({ name, fill, style }: ItemIconProps) {
	const customStyle: React.CSSProperties = {
		...style,
		color: fill,
	};

	return <MaterialSymbol variant={name} scale={1.5} style={customStyle} />;
}

export function Label({ children }: { children: ReactNode }) {
	return <div className={cssClasses(css.label)}>{children}</div>;
}

interface PaneProps {
	caution?: boolean;
	padding?: string;
	onClick?: (event: MouseEvent) => void;
	children: ReactNode;
}

export function Pane({ caution, padding, children, onClick }: PaneProps) {
	const styles: CSSProperties = {};
	if (padding) {
		styles.padding = padding;
	}

	return (
		<div
			className={cssClasses(
				css.pane,
				caution && css.caution,
				onClick && css.hasClickAction
			)}
			onClick={onClick}
			style={styles}>
			{children}
		</div>
	);
}

interface HeadingProps {
	children: ReactNode;
	className?: string;
}

export function Heading({ children, className }: HeadingProps) {
	return <h4 className={cssClasses(css.heading, className)}>{children}</h4>;
}

export function SegmentHeading({ children }: { children: ReactNode }) {
	return <div className={css.segmentHeading}>{children}</div>;
}

interface ItemProps {
	onClick?: (event: MouseEvent) => void;
	onMouseEnter?: (event: MouseEvent) => void;

	centered?: boolean;
	justifyContent?: JustifyContentValues;
	noLink?: boolean;
	caution?: boolean;
	closeOnClick?: boolean;
	children?: ReactNode;
	className?: string;
	disabled?: boolean;
	tooltip?: string;
	testid?: string;
	icon?: MaterialSymbolVariant;
	tight?: boolean;
	columns?: string;
	as?: ElementType;
	fontWeight?: FontWeights;
	skipFirstItemTopSpacing?: boolean;
}

export function useContextMenu() {
	return useContext(MenuContext);
}

function attributesForItemFromProps(
	props: ItemProps
): [string[], CSSProperties] {
	const classes = [css.itemWrapper];
	if (props.tight) {
		classes.push(css.tight);
	}
	if (props.centered) {
		classes.push(css.centered);
	}
	if (props.skipFirstItemTopSpacing) {
		classes.push(css.skipFirstItemTopSpacing);
	}
	if (props.disabled) {
		classes.push(css.disabled);
	}
	if (props.caution) {
		classes.push(css.caution);
	}

	const styles: CSSProperties = {};
	if (props.fontWeight) {
		styles.fontWeight = props.fontWeight;
	}
	if (props.columns) {
		styles.gridTemplateColumns = props.columns;
	}
	if (props.justifyContent) {
		styles.justifyContent = props.justifyContent;
	}

	return [classes, styles];
}

interface ItemWrapperProps extends ItemProps {
	confirmOpen?: boolean;
}

function ItemWrapper(props: ItemWrapperProps) {
	const [classes, styles] = attributesForItemFromProps(props);

	return (
		<div
			{...props}
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			data-testid={props.testid}
			className={cssClasses(...classes, props.className)}
			style={styles}>
			{props.children}
		</div>
	);
}

export const Item = (props: ItemProps): JSX.Element => {
	const context = useContext(MenuContext);

	const { onClick, closeOnClick } = props;

	const handleOnClick = (event: MouseEvent): void => {
		if (props.disabled) {
			return;
		}

		if (onClick) {
			onClick(event);
		}

		if (closeOnClick) {
			context.close();
		}
	};

	const { onMouseEnter, tooltip } = useTooltip(props.tooltip);

	const forwardedProps = { ...props };

	return (
		<Fragment>
			<ItemWrapper
				{...forwardedProps}
				onClick={handleOnClick}
				onMouseEnter={onMouseEnter}
				data-testid={props.testid || 'context_menu.item'}>
				{props.icon && <MaterialSymbol scale={1.5} variant={props.icon} />}
				{props.children}
			</ItemWrapper>
			{tooltip}
		</Fragment>
	);
};

Item.defaultProps = {
	closeOnClick: true,
};

interface ControlItemProps extends ItemProps {
	type: 'radio' | 'checkbox';
	checked?: boolean;
}

/**
 * ControlItem is a context menu item that doesn't close by default on click, containing
 * a radio or checkbox control item at the far end of the item.
 */
export const ControlItem = (props: ControlItemProps): JSX.Element => {
	const context = useContext(MenuContext);

	const { onClick, closeOnClick } = props;

	const handleOnClick = (event: MouseEvent): void => {
		if (props.disabled) {
			return;
		}

		if (onClick) {
			onClick(event);
		}

		if (closeOnClick) {
			context.close();
		}
	};

	const { onMouseEnter, tooltip } = useTooltip(props.tooltip);

	return (
		<Fragment>
			<ItemWrapper
				{...props}
				onClick={handleOnClick}
				onMouseEnter={onMouseEnter}
				data-testid={props.testid || 'context_menu.item'}
				columns={props.icon ? 'auto 1fr auto' : '1fr auto'}>
				{props.icon && <MaterialSymbol scale={1.5} variant={props.icon} />}
				{props.children}
				<Input.Control
					type={props.type}
					disabled={props.disabled}
					checked={props.checked}
					standalone
				/>
			</ItemWrapper>
			{tooltip}
		</Fragment>
	);
};

ControlItem.defaultProps = {
	closeOnClick: false,
};

interface LinkItemProps extends ItemProps {
	href?: string;
}

export const LinkItem = (props: LinkItemProps): JSX.Element => {
	const context = useContext(MenuContext);

	const { onClick, closeOnClick } = props;

	const handleOnClick = (event: MouseEvent): void => {
		if (onClick) {
			onClick(event);
		}

		if (closeOnClick !== false) {
			context.close();
		}
	};

	const [classes, styles] = attributesForItemFromProps(props);

	const { onMouseEnter, tooltip } = useTooltip(props.tooltip);

	return (
		<Link
			{...props}
			style={styles}
			onMouseEnter={onMouseEnter}
			className={cssClasses(...classes, props.className)}
			onClick={handleOnClick}
			data-testid={props.testid || 'context_menu.item'}>
			{props.icon && <MaterialSymbol scale={1.5} variant={props.icon} />}
			{props.children}
			{tooltip}
		</Link>
	);
};

LinkItem.defaultProps = {
	closeOnClick: true,
};

interface ConfirmItemProps extends ItemProps {
	onConfirm: (event?: MouseEvent) => Promise<void>;
	onCancel?: (event?: MouseEvent) => Promise<void>;

	message?: string;
	cancelLabel?: string;
	confirmLabel?: string;
	testid?: string;
}

export const ConfirmItem = (props: ConfirmItemProps): JSX.Element => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const context = useContext(MenuContext);

	const { closeOnClick, onConfirm, onCancel } = props;

	const open = () => !props.disabled && setOpen(true);

	const close = async () => {
		if (onCancel) {
			await onCancel();
		}

		setOpen(false);

		if (closeOnClick) {
			context.close();
		}
	};

	const confirm = async () => {
		await onConfirm();
		setOpen(false);

		if (closeOnClick) {
			context.close();
		}
	};

	const wrapperProps: ItemProps = omit(
		props,
		'onCancel',
		'onConfirm',
		'message',
		'cancelLabel',
		'confirmLabel'
	);

	const { onMouseEnter, tooltip } = useTooltip(props.tooltip);

	if (props.tooltip) {
		wrapperProps.onMouseEnter = onMouseEnter;
	}

	if (!isOpen) {
		return (
			<Fragment>
				<ItemWrapper
					{...wrapperProps}
					onClick={open}
					data-testid={props.testid || 'context_menu.confirm_item'}>
					{' '}
					{props.icon && <MaterialSymbol scale={1.5} variant={props.icon} />}
					{props.children}
				</ItemWrapper>
				{tooltip}
			</Fragment>
		);
	} else {
		return (
			<Fragment>
				<ItemWrapper {...wrapperProps} confirmOpen>
					{props.message && (
						<div className={css.confirmMessage}>{props.message}</div>
					)}
					<div className={css.confirmActions}>
						<Button small block transparent onClick={close}>
							{props.cancelLabel || t('Cancel')}
						</Button>
						<Button
							small
							block
							transparent
							primary={!props.caution}
							caution={props.caution}
							testid="button.confirm"
							onClick={confirm}>
							{props.confirmLabel || t('Delete')}
						</Button>
					</div>
				</ItemWrapper>
				{tooltip}
			</Fragment>
		);
	}
};

interface ReportItemProps {
	contentType: string;
	contentId: number;
}
export const ReportItem = ({
	contentType,
	contentId,
}: ReportItemProps): JSX.Element => {
	const account = useCurrentAccount();
	const membership = useCurrentMembership();

	return (
		<ConfirmItem
			caution
			onConfirm={async () => {
				openUGCReport({ account, membership }, contentType, contentId);
			}}
			message={t(`Is this objectionable content? E.g. harassment or indecent`)}
			closeOnClick
			icon="report"
			confirmLabel={t('Yes')}>
			{t(`Report`)}
		</ConfirmItem>
	);
};

interface ActionSheetMenuProps {
	children: ReactNode;
}

function ActionSheetMenu({ children }: ActionSheetMenuProps) {
	const theme = useTheme();
	return (
		<nav
			className={cssClasses(
				css.actionSheetMenu,
				theme.animationDirection === 'in' ? css.animateIn : css.animateOut
			)}>
			{children}
		</nav>
	);
}

export enum AppearFrom {
	TopLeft = 'top-left',
	TopRight = 'top-right',
	BottomLeft = 'bottom-left',
	BottomRight = 'bottom-right',
}

interface MenuTogglePosition {
	top?: string;
	left?: string;
	bottom?: string;
	right?: string;
}

interface MenuProps {
	defaultOpen?: boolean;
	appearFrom?: AppearFrom;
	offsetHorizontal?: number;
	offsetVertical?: number;
	toggleWith?: React.ReactNode;
	maxWidth?: string;
	maxHeight?: string;
	width?: string;
	fullWidth?: boolean;
	className?: string;
	toggleClassName?: string;
	children?: React.ReactNode;
	scrollSpy?: boolean;
	togglePosition?: MenuTogglePosition;
	// set this to always have the action sheet at its max height, good to avoid jankiness

	onClose?: () => void;
	onOpen?: () => void;
}

export const Menu = ({
	defaultOpen = false,
	appearFrom,
	offsetHorizontal = 0,
	offsetVertical = 0,
	toggleWith,
	maxWidth,
	maxHeight,
	width,
	fullWidth,
	className,
	toggleClassName,
	children,
	scrollSpy = false,
	togglePosition,

	onClose,
	onOpen,
}: MenuProps): JSX.Element => {
	const [isOpen, setIsOpen] = useState(defaultOpen);
	const [animationDirection, setAnimationDirection] = useState('in');
	const theme: DefaultTheme = useTheme();

	const toggleNode: React.MutableRefObject<HTMLDivElement> =
		useRef<HTMLDivElement>();
	const menuRef: React.MutableRefObject<HTMLDivElement> =
		useRef<HTMLDivElement>();
	const currentIndex = useNewTopIndex();

	const open = () => {
		setIsOpen(true);
		setAnimationDirection('in');

		if (onOpen) {
			onOpen();
		}
	};

	const close = useCallback(
		(event: any) => {
			if (event) {
				event.stopPropagation();
			}

			setAnimationDirection('out');

			setTimeout(() => {
				setIsOpen(false);

				if (onClose) {
					onClose();
				}
			}, 300);
		},
		[onClose]
	);

	const toggleStateHandler = (event: MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();

		if (isOpen) {
			close(event);
		} else {
			open();
		}
	};

	const setMenuWrapperHeight = useCallback(
		(position: 'top' | 'bottom') => {
			const toggleRect = toggleNode.current.getBoundingClientRect();
			const windowHeight = window.innerHeight;
			const offset = offsetVertical || 0;

			let maxHeight = 0;

			if (position === 'top') {
				maxHeight = windowHeight - toggleRect.top - 20;

				if (offset > 0) {
					maxHeight = maxHeight - offset;
				} else if (offset < 0) {
					maxHeight = maxHeight + Math.abs(offset);
				} else {
					maxHeight = maxHeight - toggleRect.height - 4;
				}
			} else {
				maxHeight = windowHeight - (windowHeight - toggleRect.bottom + 20);

				if (offset > 0) {
					maxHeight = maxHeight - offset;
				} else if (offset < 0) {
					maxHeight = maxHeight + Math.abs(offset);
				} else {
					maxHeight = maxHeight - toggleRect.height - 4;
				}
			}

			menuRef.current.style.maxHeight = `${maxHeight}px`;
		},
		[offsetVertical]
	);

	const setAutoPosition = useCallback(() => {
		const toggleRect = toggleNode.current.getBoundingClientRect();
		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;

		let transformOriginX = 'left';
		let transformOriginY = 'top';

		if (windowWidth / 2 > toggleRect.x) {
			// Left side of the screen
			menuRef.current.style.left = `${toggleRect.x}px`;
		} else {
			// Right side of the screen
			transformOriginX = 'right';

			menuRef.current.style.right = `${
				windowWidth - toggleRect.x - toggleRect.width
			}px`;
		}

		if (windowHeight / 2 > toggleRect.y) {
			// Upper half of the screen
			setMenuWrapperHeight('top');
			menuRef.current.style.top = `${toggleRect.top + toggleRect.height + 4}px`;
		} else {
			// Bottom half of the screen
			setMenuWrapperHeight('bottom');
			transformOriginY = 'bottom';

			menuRef.current.style.bottom = `${windowHeight - toggleRect.y + 4}px`;
		}

		menuRef.current.style.transformOrigin = `${transformOriginY} ${transformOriginX}`;
	}, [setMenuWrapperHeight]);

	const setPosition = useCallback(() => {
		const toggleRect = toggleNode.current.getBoundingClientRect();

		switch (appearFrom) {
			case 'top-left':
				setMenuWrapperHeight('top');
				menuRef.current.style.top = `${toggleRect.top}px`;
				menuRef.current.style.left = `${toggleRect.left}px`;
				break;
			case 'top-right':
				setMenuWrapperHeight('top');
				menuRef.current.style.top = `${toggleRect.top}px`;
				menuRef.current.style.right = `${
					window.innerWidth - toggleRect.right
				}px`;
				break;
			case 'bottom-left':
				setMenuWrapperHeight('bottom');
				menuRef.current.style.bottom = `${toggleRect.bottom}px`;
				menuRef.current.style.left = `${toggleRect.left}px`;
				break;
			case 'bottom-right':
				setMenuWrapperHeight('bottom');
				menuRef.current.style.bottom = `${
					window.innerHeight - toggleRect.top
				}px`;
				menuRef.current.style.right = `${
					window.innerWidth - toggleRect.right
				}px`;
				break;
		}
	}, [appearFrom, setMenuWrapperHeight]);

	useLayoutEffect(() => {
		if (isOpen && menuRef.current) {
			appearFrom ? setPosition() : setAutoPosition();
			menuRef.current.style.width = width;
		}
	}, [appearFrom, isOpen, setPosition, setAutoPosition]);

	const componentProperties: contextStyles.MenuWrapperProps = {
		className,
		maxWidth,
		maxHeight,
		offsetHorizontal,
		offsetVertical,
		index: currentIndex,
	};

	let toggle;

	if (toggleWith || toggleWith === null) {
		const styles: CSSProperties = {};
		if (togglePosition) {
			styles.position = 'absolute';
			styles.top = togglePosition.top;
			styles.bottom = togglePosition.bottom;
			styles.left = togglePosition.left;
			styles.right = togglePosition.right;
		}

		toggle = (
			<div
				ref={toggleNode}
				onClick={toggleStateHandler}
				className={cssClasses(
					css.toggleWrapper,
					fullWidth && css.fullWidth,
					toggleClassName
				)}
				style={styles}>
				{toggleWith}
			</div>
		);
	} else {
		toggle = (
			<div
				ref={toggleNode}
				onClick={toggleStateHandler}
				className={cssClasses(css.defaultToggle, toggleClassName)}
				data-testid="context_menu.action_trigger_button">
				<Icon name="context-menu" />
			</div>
		);
	}

	const getPositionedWrapper = (position: string) => {
		switch (position) {
			case 'top-left':
				return contextStyles.MenuWrapperTopLeft;
			case 'top-right':
				return contextStyles.MenuWrapperTopRight;
			case 'bottom-left':
				return contextStyles.MenuWrapperBottomLeft;
			case 'bottom-right':
				return contextStyles.MenuWrapperBottomRight;
			default:
				return contextStyles.MenuWrapper;
		}
	};

	const Position = getPositionedWrapper(appearFrom);
	if (scrollSpy) {
		componentProperties.scrollSpy = true;
	}

	return (
		<Fragment>
			{toggle}
			{isOpen
				? DOM.createPortal(
						<ThemeProvider
							theme={{
								...theme,
								animationDirection,
							}}>
							<LargeScreen>
								<div
									className={css.transparentBackdrop}
									onClick={close}
									style={{ zIndex: currentIndex }}
								/>
								<MenuContext.Provider value={{ close }}>
									<Position
										{...componentProperties}
										forwardedRef={menuRef}
										id="context-menu-wrapper">
										{children}
									</Position>
								</MenuContext.Provider>
							</LargeScreen>

							<SmallScreen>
								<MenuContext.Provider value={{ close: close }}>
									<Backdrop style={{ zIndex: currentIndex }} />
									<div
										className={css.actionSheetWrapper}
										style={{ zIndex: currentIndex + 1 }}
										id="context-menu-wrapper">
										<div className={css.actionSheetLeftOver} onClick={close} />
										<ActionSheetMenu>
											<div className={css.actionSheetHandle} onClick={close} />
											<ScrollSpy className={css.actionSheetItems}>
												{children}
											</ScrollSpy>
										</ActionSheetMenu>
									</div>
								</MenuContext.Provider>
							</SmallScreen>
						</ThemeProvider>,
						document.getElementById('context-menu-portal-container')
					)
				: null}
		</Fragment>
	);
};
