// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YHxME2Cisb5zT0WWLiFq {
	width: 100%;
	margin: 0 auto;
	font-size: var(--font-size-xs);
	color: var(--palette-black);
	border-collapse: collapse;
	text-align: center;
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/tabs/summary/styles.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,cAAc;CACd,8BAA8B;CAC9B,2BAA2B;CAC3B,yBAAyB;CACzB,kBAAkB;AACnB","sourcesContent":[".table {\n\twidth: 100%;\n\tmargin: 0 auto;\n\tfont-size: var(--font-size-xs);\n\tcolor: var(--palette-black);\n\tborder-collapse: collapse;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var table = `YHxME2Cisb5zT0WWLiFq`;
export default ___CSS_LOADER_EXPORT___;
