export function Create(userID: number): string {
	return `/v1/users/${userID}/logs`;
}

export function Delete(userID: number, userLogID: number): string {
	return `/v1/users/${userID}/logs/${userLogID}`;
}

export function Index(userID: number): string {
	return `/v1/users/${userID}/logs`;
}
