import { Children, ReactNode, MouseEvent, Fragment } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import useTooltip from 'pkg/hooks/useTooltip';
import { cssClasses } from 'pkg/css/utils';
import Link from 'pkg/router/Link';

import MaterialSymbol from 'components/material-symbols';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import { EllipsisIcon } from 'components/loaders/spinner';

import * as css from './styles.css';

export type ButtonVariant =
	| 'base'
	| 'transparent'
	| 'primary'
	| 'secondary'
	| 'caution'
	| 'inline'
	| 'confirm';

type ButtonTypes = 'button' | 'submit' | 'reset';

interface RouterLinkParams {
	[key: string]: string | boolean | number;
}

export interface ButtonComponentProps {
	label?: string;
	className?: string;
	onClick?: (event?: MouseEvent | TouchEvent | PointerEvent) => void;
	busy?: boolean;
	disabled?: boolean;
	href?: string;
	active?: boolean;
	type?: ButtonTypes;
	noPadding?: boolean;

	transparent?: boolean;
	caution?: boolean;
	inline?: boolean;
	brand?: boolean;
	primary?: boolean;
	secondary?: boolean;
	confirm?: boolean;
	// used to override the variant bools
	variant?: ButtonVariant;

	submit?: boolean;
	children?: ReactNode | ReactNode[];
	tooltip?: string;
	testid?: string;
	isLoading?: boolean;

	onMouseEnter?: (e: MouseEvent) => void;

	block?: boolean;
	icon?: MaterialSymbolVariant;
	hasIcon?: boolean;
	iconSize?: number;
	iconPosition?: 'left' | 'right' | 'top';
	iconRotate?: string;
	large?: boolean;
	small?: boolean;
	'data-testid'?: string;
}

function Button(props: ButtonComponentProps): JSX.Element {
	let icon = null;
	let iconPosition = 'left';

	let defaultIconScale = 1.45; // Default scale
	if (props.small) {
		defaultIconScale = 1.35; // Smaller scale for small buttons
	} else if (props.large) {
		defaultIconScale = 1.5; // Larger scale for large buttons
	}

	const iconScale = props?.iconSize ?? defaultIconScale;

	if (props.icon?.length > 1) {
		icon = (
			<MaterialSymbol
				className={css.materialSymbol}
				variant={props.icon}
				scale={iconScale}
				rotate={props.iconRotate ? +props.iconRotate : undefined}
			/>
		);

		if (props.iconPosition) {
			iconPosition = props.iconPosition;
		}
	}

	let children: ReactNode[] = [];

	if (props.children) {
		children = Children.toArray(props.children);
	} else if (props.label) {
		children = [props.label];
	}

	let variant: ButtonVariant = 'base';

	if (props.transparent) {
		variant = 'transparent';
	}

	if (props.primary) {
		variant = 'primary';
	}

	if (props.secondary) {
		variant = 'secondary';
	}

	if (props.caution) {
		variant = 'caution';
	}

	if (props.confirm) {
		variant = 'confirm';
	}

	if (props.variant) {
		variant = props.variant;
	}

	const isIconOnly = !children.length && props.icon;

	const btnCssClasses = cssClasses(
		css.button,
		css[variant],
		props.className,
		isIconOnly ? css.iconOnly : '',
		props.isLoading ? css.loading : '',
		props.block ? css.block : '',
		props.small ? css.small : '',
		props.large ? css.large : '',
		props.inline ? css.inline : '',
		!!props.icon || props.hasIcon ? css['has-icon'] : '',
		iconPosition === 'right' ? css['icon-position-right'] : '',
		iconPosition === 'left' ? css['icon-position-left'] : '',
		iconPosition === 'top' ? css['icon-position-top'] : '',
		props.busy ? css.busy : '',
		props.active ? css.active : '',
		props.noPadding ? css.noPadding : '',
		children.length > 0 ? css['has-content'] : ''
	);
	const linkProps: {
		href?: string;
		params?: RouterLinkParams | Record<string, unknown>;
	} = {};

	if (props.href) {
		linkProps.href = props.href;
	}

	const { onMouseEnter, tooltip } = useTooltip(props.tooltip);

	const hasLinkProps = Object.keys(linkProps).length > 0;

	const content = (
		<Fragment>
			{props.isLoading && <EllipsisIcon className={css.ellipsis} />}
			{iconPosition === 'left' ? icon : null}
			{iconPosition === 'top' ? icon : null}
			{children.length > 0 ? <span>{children}</span> : null}
			{iconPosition === 'right' ? icon : null}
			{tooltip}
		</Fragment>
	);

	const sharedButtonProps = {
		onClick: props.onClick,
		className: btnCssClasses,
		disabled: props.isLoading || props.disabled,
		onMouseEnter: onMouseEnter,
		'data-testid': props.testid || props['data-testid'],
	};

	return hasLinkProps ? (
		<Link {...linkProps} {...sharedButtonProps}>
			{content}
		</Link>
	) : (
		<button {...sharedButtonProps} type={props.type || 'button'}>
			{content}
		</button>
	);
}

interface ButtinGroupProps {
	spacing?: styles.spacing;
	align?: 'start' | 'center' | 'end';
	justifyContent?: 'start' | 'center' | 'end' | 'stretch';
}

// Grid layout with spacing between each button, e.g. `[button] [button] [button]`
const ButtonGroup = styled.div<ButtinGroupProps>`
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: auto;
	justify-items: ${({ justifyContent }) =>
		justifyContent ? justifyContent : 'start'};
	justify-content: ${({ justifyContent }) =>
		justifyContent ? justifyContent : 'start'};
	grid-gap: ${({ spacing }) => (spacing ? spacing : styles.spacing._3)};
	align-items: ${({ align }) => (align ? align : 'start')};
`;

interface FilterButtonProps {
	active?: boolean;
	testid?: string;
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({
	active,
	onClick,
	testid,
}) => {
	return (
		<Button
			active={active}
			onClick={onClick}
			testid={testid}
			icon="tune"
			iconSize={1.8}
			className={css.filterButton}
		/>
	);
};

export { ButtonGroup, FilterButton };
export default Button;
