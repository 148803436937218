import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import * as actions from 'pkg/actions';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

import AssetImage from 'components/AssetImage';
import SectionTitle from 'components/SectionTitle';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import * as CardList from 'components/card-list';

import TaxRateModal from 'components/taxes/form';
import Column from 'components/layout/column';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { Spinner } from 'components/loaders/spinner';

import * as ContextMenu from 'design/context_menu';
import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

interface TaxesProps {
	organizationId: number;
}

interface TaxRateCardListItemProps {
	organizationId: number;
	taxRate: models.taxRate.TaxRate;
	refresh: () => void;
}

function TaxRateCardListItem({
	organizationId,
	taxRate,
	refresh,
}: TaxRateCardListItemProps) {
	const [showModal, setShowModal] = useState(false);

	const showEditModal = () => setShowModal(true);

	const hideEditModal = () => setShowModal(false);

	const handleDeleteTax = async (taxRateId: number) => {
		const ok = await actions.taxRates.removeTaxRate(
			organizationId,
			taxRateId as unknown as string
		);

		if (ok) {
			refresh();
		}
	};

	return (
		<Fragment key={taxRate.id}>
			<CardList.Base noBorder>
				<CardList.IconWrapper>
					<Icon name="tag" />
				</CardList.IconWrapper>
				<CardList.Title>
					{taxRate.display_name}
					<CardList.SubTitle>{`${taxRate.percentage}% - ${
						taxRate.inclusive ? t(`Inclusive`) : t(`Exclusive`)
					} ${
						taxRate.jurisdiction && `(${taxRate.jurisdiction})`
					}`}</CardList.SubTitle>
				</CardList.Title>
				<CardList.Actions>
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.ButtonTrigger>
								<Icon name="context-menu" />
							</ContextMenu.ButtonTrigger>
						}>
						<ContextMenu.Item onClick={showEditModal}>
							<ContextMenu.ItemIcon name="edit" />
							{t('Edit')}
						</ContextMenu.Item>
						<ContextMenu.Item
							caution
							onClick={() => handleDeleteTax(taxRate.id)}>
							<ContextMenu.ItemIcon name="delete" />
							{t('Delete')}
						</ContextMenu.Item>
					</ContextMenu.Menu>
				</CardList.Actions>
			</CardList.Base>
			{showModal && (
				<TaxRateModal
					groupId={organizationId}
					taxRate={taxRate}
					onClose={hideEditModal}
					handleSave={() => refresh()}
				/>
			)}
		</Fragment>
	);
}

export default function Taxes({ organizationId }: TaxesProps) {
	const [modal, setModal] = useState(false);

	const {
		records: taxRates,
		refresh,
		isLoading,
	} = useCollection<models.taxRate.TaxRate>(
		endpoints.TaxRates.Index1(organizationId),
		{
			queryParams: new URLSearchParams({
				active: 'true',
			}),
		}
	);

	const showTaxModal = () => setModal(true);

	const hideTaxModal = () => setModal(false);

	const content =
		taxRates.length === 0 ? (
			<MissingEntities
				noBorder
				title={t(`There are no tax rates yet`)}
				description={t(`Create a new tax rate to get started`)}
				image={<AssetImage src="img/missing-entities/Product.svg" />}
				actions={
					<Button
						secondary
						onClick={showTaxModal}
						icon="add"
						testid="tax_rates.create">
						{t(`New tax`)}
					</Button>
				}
			/>
		) : (
			<Column>
				<SectionTitle>
					{t(`Tax settings`)}
					<Button
						inline
						noPadding
						icon="add"
						onClick={showTaxModal}
						testid="tax_rates.create">
						{t(`Add tax`)}
					</Button>
				</SectionTitle>
				{taxRates.map((taxRate) => (
					<TaxRateCardListItem
						taxRate={taxRate}
						organizationId={organizationId}
						refresh={refresh}
					/>
				))}
			</Column>
		);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					{content}
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
			{modal && (
				<TaxRateModal
					groupId={organizationId}
					onClose={hideTaxModal}
					handleSave={() => refresh()}
				/>
			)}
		</Fragment>
	);
}
