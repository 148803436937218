// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iQfg51wc4L6TQeOV2ALg {
	gap: 0;
	width: 100%;
}

.cciStsZMDEkUbiDkT8cQ {
	padding: var(--spacing-5);
	width: 100%;
}

.wak6bHFMFtTF8RxV182K {
	padding-left: var(--spacing-6);
	padding-right: var(--spacing-6);
}

.VctJ60BGUDTKpgycJhzO {
	width: 100%;
	border-top: 1px solid var(--palette-gray-300);
}

.K7cLdLnoFnpzGz74r7Cb {
	width: 100%;
}
`, "",{"version":3,"sources":["webpack://./components/video-library/modals/add-to-collection/style.css"],"names":[],"mappings":"AAAA;CACC,MAAM;CACN,WAAW;AACZ;;AAEA;CACC,yBAAyB;CACzB,WAAW;AACZ;;AAEA;CACC,8BAAgC;CAAhC,+BAAgC;AACjC;;AAEA;CACC,WAAW;CACX,6CAA6C;AAC9C;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".wrapper {\n\tgap: 0;\n\twidth: 100%;\n}\n\n.header {\n\tpadding: var(--spacing-5);\n\twidth: 100%;\n}\n\n.navigation {\n\tpadding-inline: var(--spacing-6);\n}\n\n.view {\n\twidth: 100%;\n\tborder-top: 1px solid var(--palette-gray-300);\n}\n\n.row {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `iQfg51wc4L6TQeOV2ALg`;
export var header = `cciStsZMDEkUbiDkT8cQ`;
export var navigation = `wak6bHFMFtTF8RxV182K`;
export var view = `VctJ60BGUDTKpgycJhzO`;
export var row = `K7cLdLnoFnpzGz74r7Cb`;
export default ___CSS_LOADER_EXPORT___;
