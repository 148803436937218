import { Fragment } from 'react';
import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import StatusLabel from 'components/payment_platform/status/label';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface ItemProps {
	groupId: number;
	order: models.order.Order;
	currency: string;
	// The current user we're viewing, to determine paid by/paid on behalf of logic
	userId: number;
}

const InvoiceItem: React.FC<React.PropsWithChildren<ItemProps>> = ({
	userId,
	order,
	currency,
}) => {
	const dueDate = new DateTime(new Date(order.dueDate * 1000));

	const users: {
		[key: number]: models.user.User;
	} = {};
	order.userProducts?.forEach((userProduct) => {
		users[userProduct.user.id] = userProduct.user;
	});

	const hideUsers = Object.keys(users).length === 0 || !!users[userId];

	return (
		<Fragment>
			<Card.Base $noBorder className={css.whole}>
				<div className={css.base}>
					<div className={css.image}>
						<div className={css.iconwrapper}>
							<Icon name="file-generic" />
						</div>
					</div>
					<Link
						className={css.title}
						href={routes.UserBilling.Invoice(order.id)}>
						{formatToCurrency(order.amountDue / 100, currency)}
						{order.invoiceNumber && <span>{order.invoiceNumber}</span>}
					</Link>

					<div className={css.col}>
						<StatusLabel
							className={css.statusLabel}
							status={models.order.getStatus(order)}
						/>
						{!!order.dueDate && (
							<span className={css.date}>
								{t('Due')} {dueDate.toLocaleDateString()}
							</span>
						)}
					</div>
				</div>
				{!hideUsers && (
					<Fragment>
						<Card.Divider />
						<div className={css.assignedUsers}>
							<Column>
								<span>{t('This invoice was paid on behalf of')}</span>
								{Object.values(users).map((user) => (
									<Fragment key={userId}>
										<Row columns="40px 1fr auto" align="center">
											<Avatar user={user} size={40} />
											<strong className={css.black}>
												{models.user.fullName(user)}
											</strong>
										</Row>
									</Fragment>
								))}
							</Column>
						</div>
					</Fragment>
				)}
			</Card.Base>
		</Fragment>
	);
};

export default InvoiceItem;
