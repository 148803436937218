import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import store from 'pkg/store/createStore';
import useConfirm from 'pkg/hooks/useConfirm';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';

import Label from 'components/label';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import * as columnStyles from 'components/layout/column/styles.css';

import * as Context from 'design/context_menu';

const AccountName = styled.div`
	grid-area: name;
	align-self: flex-end;
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-900);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const AccountEmail = styled.div`
	grid-area: text;
	align-self: flex-start;
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ContextWrapper = styled.div`
	grid-area: context-menu;
`;

const ChildCardWrapper = styled.div`
	display: grid;
	grid-template-areas: 'avatar name context-menu' 'avatar text context-menu' 'column column column';
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto auto;
	align-items: center;
	gap: var(--spacing-1) var(--spacing-4);
	background: var(--palette-white);
	padding: var(--spacing-4);
	border-radius: var(--radius-5);

	.${columnStyles.column} {
		grid-area: column;
		margin-top: var(--spacing-3);
	}
`;

const AvatarWrapper = styled.div`
	grid-area: avatar;
	display: grid;
	align-items: center;
	justify-items: center;
	width: 44px;
	height: 44px;

	${Avatar} {
		width: 44px;
		height: 44px;
	}
`;

interface WardCardProps {
	relation: models.accountRelation.AccountRelation;
}

export default function WardCard({ relation }: WardCardProps): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const handleRemoveWard = useConfirm({
		message: t('Do you want to remove this child connection?'),
		onConfirm: async () => {
			await actions.accountRelations.remove(relation.id)(store.dispatch);
			await actions.accountRelations.fetchAccountRelations()(store.dispatch);
		},
	});

	const handleAddToGroup = async () => {
		await onboardingState.set({
			account: null,
			targetAccount: relation.targetAccount,
		});

		goTo('group-code');
	};

	return (
		<ChildCardWrapper>
			<AvatarWrapper>
				<Avatar account={relation.targetAccount} />
			</AvatarWrapper>
			<AccountName data-testid="no_memberships.child_card.name">
				{models.account.fullName(relation.targetAccount)}
			</AccountName>
			<AccountEmail data-testid="no_memberships.child_card.email">
				{relation.targetAccount.email}
			</AccountEmail>
			<ContextWrapper>
				<Context.Menu toggleWith={<Context.ButtonTrigger icon="more_horiz" />}>
					<Context.Item onClick={handleRemoveWard}>
						<Context.ItemIcon name="delete" />
						{t('Remove child')}
					</Context.Item>
				</Context.Menu>
			</ContextWrapper>
			<Column spacing={styles.spacing._3}>
				<Label color="orange">{t('No active memberships')}</Label>
				<Button
					secondary
					small
					label={t('Add to group')}
					onClick={handleAddToGroup}
					testid="no_memberships.child_card.add_to_group"
				/>
			</Column>
		</ChildCardWrapper>
	);
}
