import { t } from '@transifex/native';
import styled from 'styled-components';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import useConfirm from 'pkg/hooks/useConfirm';
import store from 'pkg/store/createStore';

import Label from 'components/label';
import Avatar from 'components/avatar';

import * as labelStyles from 'components/label/styles.css';

import * as Context from 'design/context_menu';

const ContextWrapper = styled.div`
	grid-area: context-menu;
`;

const PendingRequestCardWrapper = styled.div`
	display: grid;
	grid-template-areas: 'avatar name pending context-menu' 'avatar text pending context-menu';
	grid-template-columns: auto 1fr auto auto;
	grid-template-rows: auto auto;
	align-items: center;
	gap: var(--spacing-1) var(--spacing-4);
	background: var(--palette-white);
	padding: var(--spacing-4);
	border-radius: var(--radius-5);

	strong {
		grid-area: name;
		align-self: flex-end;
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		color: var(--palette-gray-900);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	span {
		grid-area: text;
		align-self: flex-start;
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.${labelStyles.label} {
		grid-area: pending;
	}
`;

const AvatarWrapper = styled.div`
	grid-area: avatar;
	display: grid;
	align-items: center;
	justify-items: center;
	width: 44px;
	height: 44px;

	${Avatar} {
		width: 44px;
		height: 44px;
	}
`;

interface PendingParentRequestCardProps {
	relation: models.accountRelation.AccountRelation;
}

export default function PendingParentRequestCard({
	relation,
}: PendingParentRequestCardProps): JSX.Element {
	const handleAccept = useConfirm({
		message: t('Do you want to accept this?'),
		onConfirm: async () => {
			await actions.accountRelations.approveRequest(relation.id)(
				store.dispatch
			);
			await actions.accountRelations.fetchAccountRelations(true)(
				store.dispatch
			);
		},
	});

	const handleDecline = useConfirm({
		message: t('Do you want to decline this?'),
		onConfirm: async () => {
			await actions.accountRelations.remove(relation.id)(store.dispatch);
			await actions.accountRelations.fetchAccountRelations(true)(
				store.dispatch
			);
		},
	});

	return (
		<PendingRequestCardWrapper>
			<AvatarWrapper>
				<Avatar account={relation.account} />
			</AvatarWrapper>
			<strong data-testid="no_memberships.pending_parent_card.parent_name">
				{models.account.fullName(relation.account)}
			</strong>
			<span>{relation.account.email}</span>
			<Label color="orange">{t('pending')}</Label>
			<ContextWrapper>
				<Context.Menu toggleWith={<Context.ButtonTrigger icon="more_horiz" />}>
					<Context.Item
						onClick={handleAccept}
						testid="no_memberships.pending_parent_card.accept">
						<Context.ItemIcon name="check" />
						{t('Accept')}
					</Context.Item>
					<Context.Item
						onClick={handleDecline}
						testid="no_memberships.pending_parent_card.decline"
						caution>
						<Context.ItemIcon name="close" />
						{t('Decline')}
					</Context.Item>
				</Context.Menu>
			</ContextWrapper>
		</PendingRequestCardWrapper>
	);
}
