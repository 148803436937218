import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';

import { IconName } from 'components/icon';

import Edit from './Edit';

interface PageProps {
	sessionId: number;
	setPageTitle: (title: string) => void;
	icon: IconName;
}

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({
	sessionId,
	setPageTitle,
}) => {
	const session = useSelector((state: RootState) =>
		selectors.sessions.find(state, { sessionId })
	);

	useEffect(() => {
		if (session?.title) {
			setPageTitle(session.title);
		}
	}, [session?.title]);

	return <Edit sessionId={sessionId} />;
};

export default Page;
