// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gEEoq8NiTpsbhVOxO2nA {
	padding-right: 0;
	padding-left: 0;
	width: 38px;
}
@media (max-width: 768px) {
	.gEEoq8NiTpsbhVOxO2nA {
		width: 42px;
}
	}
`, "",{"version":3,"sources":["webpack://./routes/group/show/user_item/user_actions/styles.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,eAAe;CACf,WAAW;AAKZ;AAHC;CALD;EAME,WAAW;AAEb;CADC","sourcesContent":[".chatButton {\n\tpadding-right: 0;\n\tpadding-left: 0;\n\twidth: 38px;\n\n\t@media (--small-viewport) {\n\t\twidth: 42px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var chatButton = `gEEoq8NiTpsbhVOxO2nA`;
export default ___CSS_LOADER_EXPORT___;
