// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xMilJ1G3hGyowsL0rsjA {
	padding: 0 var(--spacing-4) !important;
}
.xMilJ1G3hGyowsL0rsjA {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
`, "",{"version":3,"sources":["webpack://./routes/payments/orders/create/components/selectable_options/styles.css"],"names":[],"mappings":"AAAA;CAIC,sCAAsC;AACvC;AALA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,uBAA2B;KAA3B,oBAA2B;SAA3B,2BAA2B;CAC3B,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AAEpB","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\talign-items: center;\n\tpadding: 0 var(--spacing-4) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `xMilJ1G3hGyowsL0rsjA`;
export default ___CSS_LOADER_EXPORT___;
