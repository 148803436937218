export const drawingEnabled = [
	'football',
	'futsal',
	'tennis',
	'padel',
	'handball',
	'basketball',
	'volleyball',
	'field_hockey',
	'ice_hockey',
];

export interface SportConfig {
	backgrounds: Backgrounds;
	players: Players;
	icons: string[];
}

export interface Backgrounds {
	[key: string]: {
		title: string;
		url: string;
	};
}

export interface Players {
	directional?: boolean;
	jerseys?: boolean;
	icons?: string[];
}
