export function AddExercise(
	groupID: number,
	trainingCollectionID: number,
	exerciseID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/exercises/${exerciseID}`;
}

export function AddExercise1(
	trainingCollectionID: number,
	exerciseID: number
): string {
	return `/v1/training-collections/${trainingCollectionID}/exercises/${exerciseID}`;
}

export function AddSession(
	groupID: number,
	trainingCollectionID: number,
	sessionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/sessions/${sessionID}`;
}

export function AddSession1(
	trainingCollectionID: number,
	sessionID: number
): string {
	return `/v1/training-collections/${trainingCollectionID}/sessions/${sessionID}`;
}

export function Create(groupID: number): string {
	return `/v1/organizations/${groupID}/training-collections`;
}

export function Create1(): string {
	return `/v1/training-collections`;
}

export function Delete(groupID: number, trainingCollectionID: number): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}`;
}

export function Delete1(trainingCollectionID: number): string {
	return `/v1/training-collections/${trainingCollectionID}`;
}

export function Index(groupID: number): string {
	return `/v1/organizations/${groupID}/training-collections`;
}

export function Index1(): string {
	return `/v1/training-collections`;
}

export function RemoveExercise(
	groupID: number,
	trainingCollectionID: number,
	exerciseID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/exercises/${exerciseID}`;
}

export function RemoveExercise1(
	trainingCollectionID: number,
	exerciseID: number
): string {
	return `/v1/training-collections/${trainingCollectionID}/exercises/${exerciseID}`;
}

export function RemoveSession(
	groupID: number,
	trainingCollectionID: number,
	sessionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/sessions/${sessionID}`;
}

export function RemoveSession1(
	trainingCollectionID: number,
	sessionID: number
): string {
	return `/v1/training-collections/${trainingCollectionID}/sessions/${sessionID}`;
}

export function Show(groupID: number, trainingCollectionID: number): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}`;
}

export function Show1(trainingCollectionID: number): string {
	return `/v1/training-collections/${trainingCollectionID}`;
}

export function ShowExercises(
	groupID: number,
	trainingCollectionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/exercises`;
}

export function ShowExercises1(trainingCollectionID: number): string {
	return `/v1/training-collections/${trainingCollectionID}/exercises`;
}

export function ShowSessions(
	groupID: number,
	trainingCollectionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/sessions`;
}

export function ShowSessions1(trainingCollectionID: number): string {
	return `/v1/training-collections/${trainingCollectionID}/sessions`;
}

export function Update(groupID: number, trainingCollectionID: number): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}`;
}

export function Update1(trainingCollectionID: number): string {
	return `/v1/training-collections/${trainingCollectionID}`;
}
