import styled, { css } from 'styled-components';

interface FootCellProps {
	align?: string;
}

export default styled.td<FootCellProps>`
	font-size: var(--font-size-sm);
	flex: 1;
	text-align: left;
	justify-content: flex-start;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	${({ align }) =>
		align &&
		align === 'right' &&
		css`
			text-align: ${align};
			justify-content: flex-end;
		`};

	${({ align }) =>
		align &&
		align === 'center' &&
		css`
			text-align: ${align};
			justify-content: center;
		`};
`;
