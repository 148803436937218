import { t } from '@transifex/native';
import { createContext, Fragment, useContext, useEffect, useRef } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import { OrderPayload, OrderSubscriptionRow } from 'pkg/actions/services/order';

import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { AutoChargeOptions } from 'pkg/api/models/provider_settings';
import { SubscriptionTypes } from 'pkg/api/models/subscription';
import { useCollection } from 'pkg/api/use_collection';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import DateTime from 'pkg/datetime';
import { FilterOperator } from 'pkg/filters';
import { useQueryState } from 'pkg/hooks/query-state';
import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { replaceState } from 'pkg/router/state';
import { cssClasses } from 'pkg/css/utils';
import { OrderRowTypes } from 'pkg/api/models/order_row';
import { CreateSubscriptionPayload } from 'pkg/api/endpoints/subscriptions';
import { isSameDay } from 'pkg/date';

import Sections from 'routes/payments/orders/create/components/Sections';
import TotalAmount from 'routes/payments/orders/create/components/total_amount';
import { getOrderCostDetails } from 'routes/payments/orders/utils';

import StatusScreen from 'components/status-screen';
import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import LargeScreenHeader from 'components/navigation/header/large_screen';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import Form, {
	asNumber,
	asString,
	FormPayload,
	submitForm,
} from 'components/form/Form';
import * as pageWrapperStyling from 'components/payment_platform/form-page/styles.css';
import * as Input from 'components/form/inputs';
import { useAppState } from 'components/application/state';

import Button, { ButtonGroup } from 'design/button';
import { useDialog } from 'design/dialog';

import { FormActionFooter } from 'styles/Form';

interface OrderContext {
	isSubscription: boolean;

	formState: OrderMixedState;
	setFormState: MixedStateSetter<OrderMixedState>;
	newAssignedProduct: (data: AssignedProductProps) => void;

	order?: models.order.Order;
}

export interface AssignedProductProps {
	product: models.product.Product;
	productPrice: models.productPrice.ProductPrice;
	taxRate: models.taxRate.TaxRate;
	quantity: number;
	taxRateId: number;
	validTo?: number;
}

export const emptyState: OrderMixedState = {
	rows: [],
	assignedContacts: [],
	startAt: null,
	endAt: null,
	hasOrderId: false,
	validTo: 0,
	installmentCount: null,
	discount: null,
	isSaving: false,
	formSuccess: false,
	finalizeNow: false,
};

export const AddOrderContext = createContext<OrderContext>({
	formState: emptyState,
	newAssignedProduct: () => {
		return;
	},
	setFormState: () => {
		return;
	},
	order: {} as models.order.Order,
	isSubscription: false,
});

export interface OrderMixedState {
	rows: AssignedProductProps[];
	assignedContacts: models.user.User[];
	validTo: number;
	installmentCount: number;

	startAt?: number;
	endAt?: number;
	collectionMethod?: 'automatic' | 'manual';
	hasOrderId?: boolean;
	discount?: models.discount.Discount;
	isSaving?: boolean;
	formSuccess?: boolean;
	finalizeNow?: boolean;
}

interface CreateOrderWrapperProps {
	organizationId: number;
}

function CreateOrderWrapper({ organizationId }: CreateOrderWrapperProps) {
	const qs = useQueryState();
	const orderId = qs.get('orderId')
		? Number.parseInt(qs.get('orderId') as string)
		: null;
	const now = DateTime.now().getUnixTimestamp();

	const [formState, setFormState] = useMixedState<OrderMixedState>({
		...emptyState,
		startAt: now,
	});
	const isSubscription = formState.rows.some(
		(row) => row.productPrice.recurring === true
	);

	const handleAfterOrderFetch = (order: models.order.Order) => {
		// If there's a order Id in the url we set a bunch of default states from the order information
		// Set assigned contacts
		const orderData: OrderMixedState = {
			...formState,
			hasOrderId: true,
		};

		if (order.userProducts?.length > 0) {
			const users = [
				...new Map(
					order.userProducts
						.map((userProduct) => userProduct.user)
						.map((user) => [user['id'], user])
				).values(),
			] as models.user.User[];
			orderData.assignedContacts = users;
		} else {
			orderData.assignedContacts = [order.customerUser];
		}

		const recurringValidTo = order.rows.filter(
			(o) => o.productPrice?.recurring
		)[0]?.validTo;

		if (recurringValidTo) {
			orderData.validTo = recurringValidTo;
		}

		const rows: AssignedProductProps[] = [];

		models.orderRow.findProductRows(order.rows).forEach((row) => {
			if (!row.productPrice) {
				return;
			}

			const assignedProductRow: AssignedProductProps = {
				product: row.productPrice.product,
				productPrice: row.productPrice,
				quantity: row.quantity,
				taxRateId: null,
				taxRate: row.taxRate,
			};

			if (row?.taxRateId) {
				assignedProductRow.taxRateId = Number.parseInt(
					row.taxRateId as unknown as string,
					10
				);
			}

			// If the row is a one time price we set the rows valid to value
			if (!row.productPrice.recurring && row.validTo) {
				assignedProductRow.validTo = row.validTo;
			}

			rows.push(assignedProductRow);
		});

		orderData.rows = rows;

		// Set subscription specific values
		if (order.subscriptionId) {
			orderData.collectionMethod = order.subscription.collectionMethod;
			orderData.startAt = now;

			// If the subscriptions startAt is a future date we can set this value
			if (order.subscription.startAt >= now) {
				orderData.startAt = order.subscription.startAt;
			}

			if (order.subscription.endAt) {
				orderData.endAt = order.subscription.endAt;
			}

			if (order.subscription.installmentCount) {
				orderData.installmentCount = order.subscription.installmentCount;
			}
		}

		const discount = models.orderRow.findDiscount(order.rows);

		if (discount && !discount.inactivatedAt) {
			orderData.discount = discount;
		}

		setFormState({
			...orderData,
		});
	};

	const newAssignedProduct = (assignedProduct: AssignedProductProps) => {
		let newRows = formState.rows;
		const rowIndex = formState.rows.findIndex(
			(row) =>
				row.productPrice.id === assignedProduct.productPrice.id &&
				row.taxRateId === assignedProduct.taxRateId
		);

		if (rowIndex !== -1) {
			newRows[rowIndex].quantity += assignedProduct.quantity;
		} else {
			newRows = [...newRows, assignedProduct];
		}

		setFormState({
			rows: newRows,
		});
	};

	const { isLoading: orderIsLoading, record: order } =
		useEndpoint<models.order.Order>(
			orderId && endpoints.Orders.Show(orderId),
			{},
			handleAfterOrderFetch
		);

	if (orderIsLoading) {
		return <Spinner />;
	}

	return (
		<AddOrderContext.Provider
			value={{
				formState,
				setFormState,
				newAssignedProduct,
				isSubscription,
				order,
			}}>
			<CreateOrder organizationId={organizationId} />
		</AddOrderContext.Provider>
	);
}

interface CreateOrderProps {
	organizationId: number;
}

function CreateOrder({ organizationId }: CreateOrderProps) {
	const qs = useQueryState();
	const OrderContext = useContext(AddOrderContext);
	const paymentProviderContext = usePaymentProviderContext();
	const formRef = useRef(null);
	const group = useCurrentGroup();
	const org = useCurrentOrganization();
	const now = DateTime.now().getUnixTimestamp();

	const orderStartAtIsToday = isSameDay(
		new Date(now * 1000),
		new DateTime(new Date(OrderContext.formState.startAt * 1000))
	);

	const { keyboardOpen } = useAppState();

	let userIds: number[] = [];

	if (qs.has('userIds')) {
		userIds = qs.getArray<number>('userIds');
	}

	if (OrderContext.formState.assignedContacts.length) {
		userIds = OrderContext.formState.assignedContacts.map((u) => u.id);
	}

	const { records: users } = useCollection<models.user.User>(
		userIds.length && endpoints.Organizations.ListUsers(organizationId),
		{
			queryParams: new URLSearchParams({
				filters: JSON.stringify([
					{
						property: 'id',
						values: userIds,
						operator: FilterOperator.Includes,
					},
				]),
			}),
		}
	);

	// If we have userIds in the query we set the default assignedContacts
	useEffect(() => {
		if (!!users.length) {
			OrderContext.setFormState({
				assignedContacts: users,
			});
		}
	}, [users.length]);

	const handleSave = async (data: FormPayload) => {
		const daysUntilDue = asNumber(data.daysUntilDue);

		OrderContext.setFormState({ isSaving: true });
		const defaultPayload: OrderPayload | CreateSubscriptionPayload = {
			userIds: OrderContext.formState.assignedContacts.map((user) => user.id),
			daysUntilDue: daysUntilDue > 0 ? daysUntilDue : 1,
			description: asString(data.description),
			rows: [],
		};

		if (OrderContext.isSubscription) {
			defaultPayload.finalizeFirstOrderNow = OrderContext.formState.finalizeNow;

			if (now !== OrderContext.formState.startAt) {
				defaultPayload.finalizeFirstOrderNow = true;
			}
		} else {
			defaultPayload.finalizeNow = OrderContext.formState.finalizeNow;

			if (!orderStartAtIsToday) {
				defaultPayload.finalizeNow = true;
			}
		}

		OrderContext.formState.rows.forEach((item: AssignedProductProps) => {
			const payloadRow: OrderSubscriptionRow = {
				priceId: item.productPrice.id,
				quantity: item.quantity,
				taxRateId: item.taxRateId,
			};

			if (!item.productPrice.recurring && item.validTo) {
				payloadRow.validTo = item.validTo;
			} else if (
				item.productPrice.recurring &&
				OrderContext.formState.validTo !== null
			) {
				payloadRow.validTo = OrderContext.formState.validTo;
			}

			defaultPayload.rows.push(payloadRow);
		});

		if (OrderContext.formState.discount) {
			defaultPayload.discountId = OrderContext.formState.discount.id;
		}

		const req: Response = OrderContext.isSubscription
			? await createSubscription(defaultPayload)
			: await createOrder(defaultPayload);

		if (req.ok) {
			OrderContext.setFormState({
				formSuccess: true,
			});
		}
		OrderContext.setFormState({
			isSaving: false,
		});
	};

	const createSubscription = (
		defaultPayload: OrderPayload | CreateSubscriptionPayload
	): Promise<Response> => {
		const payload = {
			...defaultPayload,
		} as CreateSubscriptionPayload;

		if (OrderContext.formState.startAt) {
			payload.startAt = OrderContext.formState.startAt;
		}

		if (
			paymentProviderContext.settings.autoCharge === AutoChargeOptions.Always
		) {
			payload.collectionMethod = 'automatic';
		} else {
			payload.collectionMethod = OrderContext.formState.collectionMethod;
		}

		// If endAt is set to null we're creating a subscription
		if (OrderContext.formState.endAt === null) {
			payload.type = SubscriptionTypes.Subscription;
		}
		// If not we're creating a installment
		else {
			payload.type = SubscriptionTypes.Installment;
			payload.installmentCount = OrderContext.formState.installmentCount;
			payload.endAt = OrderContext.formState.endAt;
		}

		return actions.subscriptions.createBatch(payload);
	};

	const createOrder = (
		defaultPayload: OrderPayload | CreateSubscriptionPayload
	): Promise<Response> => {
		const payload: OrderPayload = {
			...(defaultPayload as OrderPayload),
			userIds: OrderContext.formState.assignedContacts.map((user) => user.id),
			finalizeNow: OrderContext.formState.finalizeNow,
		};

		return actions.order.createBatch(payload);
	};

	const handleNewInvoice = () => {
		OrderContext.setFormState({
			...emptyState,
		});
	};

	const content = OrderContext.formState.formSuccess ? (
		<StatusScreen
			status="success"
			title={t('Invoice(s) created successfully!')}
			description={t(
				`Your invoice(s) have been successfully created and are now in a draft state. They will be automatically sent to your customer's billing email in an hour.`
			)}>
			<LargeScreen>
				<Button large block primary href={routes.Payments.Orders(org.id)}>
					{t('Finish')}
				</Button>
			</LargeScreen>
			<Button large block onClick={handleNewInvoice}>
				{t('Create another invoice')}
			</Button>
		</StatusScreen>
	) : (
		<Sections groupId={organizationId} />
	);

	const filteredRows = OrderContext.formState.rows.filter(
		(item: AssignedProductProps) => item.productPrice.recurring === true
	);

	const noConflictingPriceCycles =
		filteredRows.length > 0
			? filteredRows.every(
					(obj: AssignedProductProps) =>
						obj.productPrice.recurringInterval ===
							filteredRows[0].productPrice.recurringInterval &&
						obj.productPrice.recurringIntervalCount ===
							filteredRows[0].productPrice.recurringIntervalCount
				)
			: true;

	const contactsAndRows =
		OrderContext.formState.assignedContacts.length > 0 &&
		OrderContext.formState.rows.length > 0;

	const missingBillingEmail = OrderContext.formState.assignedContacts.some(
		(user: models.user.User) => !models.user.getBillingEmail(user)
	);

	let hasValidInstallmentCount = true;

	if (OrderContext.formState.installmentCount !== null) {
		hasValidInstallmentCount = OrderContext.formState.installmentCount > 1;
	}

	const orderCostDetails = getOrderCostDetails({
		rows: OrderContext.formState.rows.map((r) => {
			return { ...r, type: OrderRowTypes.Product, amount: r.productPrice.cost };
		}),
		installmentCount: OrderContext.formState.installmentCount,
		groupServiceFee: group.serviceFeePercent,
		discount: OrderContext.formState.discount,
	});

	const canSend =
		contactsAndRows &&
		noConflictingPriceCycles &&
		!missingBillingEmail &&
		hasValidInstallmentCount &&
		orderCostDetails.validOrder;

	const disableNext = !canSend || OrderContext.formState.isSaving;

	const handleCancel = () => {
		replaceState(routes.Payments.Orders(org.id));
	};

	const isSmallScreen = useSmallScreen();
	const shouldShowFinalizeDialog = orderStartAtIsToday;

	const handleConfirmDialog = () => submitForm(formRef);

	const handleFinalizeChange = () =>
		OrderContext.setFormState({
			finalizeNow: !OrderContext.formState.finalizeNow,
		});

	const dialog = useDialog(
		{
			title: t('Send immediately?'),
			onConfirm: handleConfirmDialog,
			message: (
				<Column>
					{t(
						`Ready to send now? Check the box. Otherwise, they'll auto-send in an hour, giving time to catch any issues.`
					)}
					<Input.Control
						label={t('Yes, send immediately')}
						type="checkbox"
						checked={OrderContext.formState.finalizeNow}
						onChange={handleFinalizeChange}
					/>
				</Column>
			),
		},
		[OrderContext.formState.finalizeNow]
	);

	const pageContent = (
		<Form formRef={formRef} onSubmit={handleSave}>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				{OrderContext.formState.formSuccess ? (
					<Fragment>
						<SmallScreen>
							<Button large block primary href={routes.Payments.Orders(org.id)}>
								{t('Finish')}
							</Button>
						</SmallScreen>
					</Fragment>
				) : (
					<Fragment>
						<Button
							block={isSmallScreen}
							large={isSmallScreen}
							label={t('Cancel')}
							onClick={handleCancel}
						/>
						<Button
							primary
							block={isSmallScreen}
							large={isSmallScreen}
							type={shouldShowFinalizeDialog ? 'button' : 'submit'}
							onClick={shouldShowFinalizeDialog ? dialog : undefined}
							disabled={disableNext}
							isLoading={OrderContext.formState.isSaving}>
							{t('Send order')}
						</Button>
					</Fragment>
				)}
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD} spacious>
					{content}
					{!OrderContext.formState.formSuccess && (
						<FormActionFooter>
							<ButtonGroup>
								<Button label={t('Cancel')} onClick={handleCancel} />
								<Button
									primary
									type={shouldShowFinalizeDialog ? 'button' : 'submit'}
									onClick={shouldShowFinalizeDialog ? dialog : undefined}
									disabled={disableNext}
									isLoading={OrderContext.formState.isSaving}>
									{t('Send order')}
								</Button>
							</ButtonGroup>
						</FormActionFooter>
					)}
				</LargeScreenContent.Inner>
			</LargeScreen>

			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
		</Form>
	);

	const totalAmount = keyboardOpen ? null : (
		<TotalAmount
			installmentCount={OrderContext.formState.installmentCount}
			orderCostDetails={orderCostDetails}
			productPrices={OrderContext.formState.rows.map((row) => row.productPrice)}
			initialPaymentDate={OrderContext.formState.startAt}
			discount={OrderContext.formState.discount}
		/>
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={t('New order')} icon="request_quote" />

				<div className={pageWrapperStyling.wrapper}>
					<div className={pageWrapperStyling.contentWrapper}>{pageContent}</div>
					{!OrderContext.formState.formSuccess && totalAmount}
				</div>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenHeader title={t('New order')} />

				<div
					className={cssClasses(
						pageWrapperStyling.wrapper,
						keyboardOpen ? pageWrapperStyling.fullHeight : null
					)}>
					<div className={pageWrapperStyling.contentWrapper}>{pageContent}</div>
					{!OrderContext.formState.formSuccess && totalAmount}
				</div>
			</SmallScreen>
		</Fragment>
	);
}

export default CreateOrderWrapper;
