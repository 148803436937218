// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F2QeIt3hQ0h9p7G7_kAP {
	height: calc(100% - var(--action-bar-height-standard));
	width: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	background-color: var(--palette-white);
	scroll-behavior: smooth;
}

@media (max-width: 768px) {

.F2QeIt3hQ0h9p7G7_kAP {
		height: calc(100% - var(--action-bar-height-medium));
}
	}

.N6a8d3mcP2Mx3rihrdOl {
	height: auto;
	width: 100%;
}

.N6a8d3mcP2Mx3rihrdOl.sb1Tx4QRmM7YFHO2UrtK {
		height: 100%;
	}
`, "",{"version":3,"sources":["webpack://./routes/group/calendar/styles.css"],"names":[],"mappings":"AAAA;CACC,sDAAsD;CACtD,WAAW;CACX,cAAc;CACd,iCAAiC;CACjC,sCAAsC;CACtC,uBAAuB;AAKxB;;AAHC;;AARD;EASE,oDAAoD;AAEtD;CADC;;AAGD;CACC,YAAY;CACZ,WAAW;AAKZ;;AAHC;EACC,YAAY;CACb","sourcesContent":[".wrapper {\n\theight: calc(100% - var(--action-bar-height-standard));\n\twidth: 100%;\n\toverflow: auto;\n\t-webkit-overflow-scrolling: touch;\n\tbackground-color: var(--palette-white);\n\tscroll-behavior: smooth;\n\n\t@media (--small-viewport) {\n\t\theight: calc(100% - var(--action-bar-height-medium));\n\t}\n}\n\n.page-wrap {\n\theight: auto;\n\twidth: 100%;\n\n\t&.fill-height {\n\t\theight: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `F2QeIt3hQ0h9p7G7_kAP`;
var _1 = `N6a8d3mcP2Mx3rihrdOl`;
export { _1 as "page-wrap" };
var _2 = `sb1Tx4QRmM7YFHO2UrtK`;
export { _2 as "fill-height" };
export default ___CSS_LOADER_EXPORT___;
