import { Fragment, memo } from 'react';

import { useCurrentRouterState, useRouterState } from 'pkg/router/hooks';
import Router, { IndexRoute, Route } from 'pkg/router/Router';
import * as routes from 'pkg/router/routes';

import Onboarding from 'routes/public/index';
import PublicDebug from 'routes/public/debug';

const Routes = memo((props) => {
	const [currentState] = useCurrentRouterState(props.currentUrl || '/');
	const { replaceState } = useRouterState();

	const redirectToHome = () => {
		replaceState(routes.Home());
	};

	return (
		<Fragment>
			<Router currentPath={currentState.location} onNotFound={redirectToHome}>
				<IndexRoute component={Onboarding} name="home" />
				<Route path="sso-login/:slug" name="sso-login" component={Onboarding} />
				<Route path="join/:groupCode" name="join" component={Onboarding} />
				<Route path="claim/:inviteKey" name="claim" component={Onboarding} />
				<Route path=":view" name="view" component={Onboarding} />
				<Route path="sso/redirect" name="sso" component={Onboarding} />
			</Router>
			<PublicDebug />
		</Fragment>
	);
});

export default Routes;
