import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

export interface CollectionQueryParams {
	group_id?: number;
	curated?: number;
	inherited?: number;
	is_contributor?: number;
	content_type?: string;
	sports?: number[];
}

export interface BasicCollectionPayload {
	name: string;
	inherit?: boolean;
}

export interface CreateCollectionPayload extends BasicCollectionPayload {
	groupId?: number;
	sportId?: number[];
}

export const fetchCollections = (
	organizationId: number,
	queryParams: CollectionQueryParams
): Promise<Response> =>
	sdk.get(endpoints.TrainingCollections.Index(organizationId), queryParams);

export const createCollection = (
	organizationId: number,
	payload: CreateCollectionPayload
): Promise<Response> =>
	sdk.post(endpoints.TrainingCollections.Create(organizationId), {}, payload);

export const addExercise = (
	organizationId: number,
	trainingCollectionId: number,
	exerciseId: number
): Promise<Response> =>
	sdk.post(
		endpoints.TrainingCollections.AddExercise(
			organizationId,
			trainingCollectionId,
			exerciseId
		)
	);

export const addSession = (
	organizationId: number,
	trainingCollectionId: number,
	sessionId: number
): Promise<Response> =>
	sdk.post(
		endpoints.TrainingCollections.AddSession(
			organizationId,
			trainingCollectionId,
			sessionId
		)
	);
