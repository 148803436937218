import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { FilterOperator } from 'pkg/filters';
import * as routes from 'pkg/router/routes';
import { useCurrentUser } from 'pkg/identity';

import Pagination from 'components/pagination';

import StatusLabel from 'components/payment_platform/status/label';

import * as Table from 'design/table';

interface OrderProps {
	order: models.order.Order;
}

export const OrderRow = ({ order }: OrderProps) => {
	const sentAt = DateTime.fromTimestamp(order.sentAt);
	const dueDate = DateTime.fromTimestamp(order.dueDate);
	const amountDue = formatToCurrency(order.amountDue / 100, order.currency);
	return (
		<Table.Row>
			<Table.LinkCell href={routes.UserBilling.Invoice(order.id)}>
				{order.invoiceNumber}
			</Table.LinkCell>
			<Table.LinkCell href={routes.UserBilling.Invoice(order.id)}>
				{amountDue}
			</Table.LinkCell>
			<Table.LinkCell href={routes.UserBilling.Invoice(order.id)}>
				<StatusLabel status={models.order.getStatus(order)} />
			</Table.LinkCell>
			<Table.LinkCell href={routes.UserBilling.Invoice(order.id)}>
				{sentAt.toLocaleDateString({
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				})}
			</Table.LinkCell>
			<Table.LinkCell href={routes.UserBilling.Invoice(order.id)}>
				{dueDate.toLocaleDateString({
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				})}
			</Table.LinkCell>
		</Table.Row>
	);
};

const PurchaseHistory = () => {
	const [sortBy, setSortBy] = useState('sent_at');
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
	const handleSort = (value: string, sortOrder: 'asc' | 'desc') => {
		setSortBy(value);
		setSortOrder(sortOrder);
	};
	const currentUser = useCurrentUser();

	const params = {
		group_id: currentUser.organizationId.toString(),
		sort_by: sortBy,
		sort_by_order: sortOrder,

		filters: JSON.stringify([
			{
				property: 'status',
				operator: FilterOperator.Excludes,
				values: ['draft'],
			},
		]),
	};

	const {
		records: orders,
		isLoading,
		pagination,
	} = useCollection<models.order.Order>(
		endpoints.Orders.ListAccountAndWardsOrders(),
		{
			queryParams: new URLSearchParams(params),
		}
	);

	const purchaseHistoryColumns = [
		{ content: t('Invoice number'), align: 'left' },
		{ content: t('Amount due'), align: 'left', sortKey: 'amount_due' },
		{ content: t('Status'), align: 'left', sortKey: 'status' },
		{ content: t('Sent at'), align: 'left', sortKey: 'sent_at' },
		{ content: t('Due date'), align: 'left', sortKey: 'due_date' },
	];

	return (
		<Fragment>
			<Table.Table
				columns={purchaseHistoryColumns}
				isLoading={isLoading}
				sortBy={sortBy}
				sortOrder={sortOrder}
				onSort={handleSort}>
				{orders.map((order) => {
					return <OrderRow key={order.id} order={order} />;
				})}
			</Table.Table>
			<Pagination {...pagination} />
		</Fragment>
	);
};

export default PurchaseHistory;
