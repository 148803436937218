enum FieldName {
	clubLegalName = 'clubLegalName',
	taxID = 'taxID',
	addressStreetAddress = 'addressStreetAddress',
	addressPostalCode = 'addressPostalCode',
	addressRegion = 'addressRegion',
	addressCountry = 'addressCountry',
	contactEmail = 'contactEmail',
	contactPhone = 'contactPhone',
	website = 'website',
}

export type ContactFieldName = keyof typeof FieldName;

export type ContactFieldType = 'text' | 'email' | 'phone' | 'url';

export interface ContactItem {
	fieldName: ContactFieldName;
	fieldType: ContactFieldType;
	fieldValue: string;
}

export type GroupContactInformation = ContactItem[];

type GroupContactFieldsMap = {
	[key in ContactFieldName]: ContactFieldType;
};

export const GroupContactFields: GroupContactFieldsMap = {
	clubLegalName: 'text',
	taxID: 'text',
	addressStreetAddress: 'text',
	addressPostalCode: 'text',
	addressRegion: 'text',
	addressCountry: 'text',
	contactEmail: 'email',
	contactPhone: 'phone',
	website: 'url',
};
