// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G0J9MPHXrIO_HYVGiATn {
	padding: var(--spacing-9);
	padding-left: var(--spacing-8);
	padding-right: var(--spacing-8);
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	grid-gap: var(--spacing-5);
	gap: var(--spacing-5);
	text-align: center;
}

.G0J9MPHXrIO_HYVGiATn strong {
		font-size: var(--font-size-xl);
	}

.G0J9MPHXrIO_HYVGiATn.LCGql4IWqG5pZJTwIMXO {
		background:
			linear-gradient(
				hsla(180, 100%, 50%, 0.3),
				transparent
			),
			linear-gradient(
				-45deg,
				hsla(300, 100%, 50%, 0.3),
				transparent
			),
			linear-gradient(
				45deg,
				hsla(60, 100%, 50%, 0.3),
				transparent
			);
  	background-blend-mode: multiply;
		-webkit-box-shadow: inset 0 0 100px 50px white;
		        box-shadow: inset 0 0 100px 50px white;
	}

.sBG0nGEnwJYvocnSpFGJ {
	--size: 128px;

	width: var(--size);
	height: var(--size);
	border-radius: 1000px;
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	color: var(--palette-blue-500);
}

.sBG0nGEnwJYvocnSpFGJ > svg {
		width: 100%;
		height: 100%;
	}

.sBG0nGEnwJYvocnSpFGJ.LCGql4IWqG5pZJTwIMXO {
		color: var(--palette-purple-500);
	}
`, "",{"version":3,"sources":["webpack://./routes/kitchen_sink/views/feature_select/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,8BAAgC;CAAhC,+BAAgC;CAChC,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,0BAAqB;CAArB,qBAAqB;CACrB,kBAAkB;AAyBnB;;AAvBC;EACC,8BAA8B;CAC/B;;AAEA;EACC;;;;;;;;;;;;;;IAcE;GACD,+BAA+B;EAChC,8CAAsC;UAAtC,sCAAsC;CACvC;;AAGD;CACC,aAAa;;CAEb,kBAAkB;CAClB,mBAAmB;CACnB,qBAAqB;CACrB,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,8BAA8B;AAU/B;;AARC;EACC,WAAW;EACX,YAAY;CACb;;AAEA;EACC,gCAAgC;CACjC","sourcesContent":[".feature {\n\tpadding: var(--spacing-9);\n\tpadding-inline: var(--spacing-8);\n\tdisplay: grid;\n\tplace-items: center;\n\tgap: var(--spacing-5);\n\ttext-align: center;\n\n\t& strong {\n\t\tfont-size: var(--font-size-xl);\n\t}\n\n\t&.ai {\n\t\tbackground:\n\t\t\tlinear-gradient(\n\t\t\t\thsla(180 100% 50% / 0.3),\n\t\t\t\ttransparent\n\t\t\t),\n\t\t\tlinear-gradient(\n\t\t\t\t-45deg,\n\t\t\t\thsla(300 100% 50% / 0.3),\n\t\t\t\ttransparent\n\t\t\t),\n\t\t\tlinear-gradient(\n\t\t\t\t45deg,\n\t\t\t\thsla(60 100% 50% / 0.3),\n\t\t\t\ttransparent\n\t\t\t);\n  \tbackground-blend-mode: multiply;\n\t\tbox-shadow: inset 0 0 100px 50px white;\n\t}\n}\n\n.artwork {\n\t--size: 128px;\n\n\twidth: var(--size);\n\theight: var(--size);\n\tborder-radius: 1000px;\n\tdisplay: grid;\n\tplace-items: center;\n\tcolor: var(--palette-blue-500);\n\n\t& > svg {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t}\n\n\t&.ai {\n\t\tcolor: var(--palette-purple-500);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var feature = `G0J9MPHXrIO_HYVGiATn`;
export var ai = `LCGql4IWqG5pZJTwIMXO`;
export var artwork = `sBG0nGEnwJYvocnSpFGJ`;
export default ___CSS_LOADER_EXPORT___;
