const group = ['groupCode', 'group.groupName'];
const inviteKey = ['inviteKey'];
const role = ['account.role'];

const userInformationFields = [
	'account.firstName',
	'account.lastName',
	'account.countryId',
	'account.day',
	'account.month',
	'account.year',
];

const targetUserInformationFields = [
	'targetAccount.firstName',
	'targetAccount.lastName',
	'targetAccount.countryId',
	'targetAccount.day',
	'targetAccount.month',
	'targetAccount.year',
];

// Registration
export const groupConfirmation = [...group];
export const roleSelector = [...group];
export const userInformation = [...group, ...role];
export const confirmUserAndGroup = [
	...group,
	...role,
	...userInformationFields,
];
export const createAccount = [...group, ...role, ...userInformationFields];
export const accountDetails = [...group, ...role, ...userInformationFields];

// Account invite
export const accountInviteUserInformation = [...inviteKey];
export const accountInviteCreateAccount = [
	...inviteKey,
	...userInformationFields,
];
export const accountInviteAccountDetails = [
	...inviteKey,
	...userInformationFields,
];

// Join group
export const joinGroupConfirmation = [...group];
export const joinGroupRoleSelector = [...group];
export const joinGroupUserInformation = [...group, ...role];
export const joinGroupCreateAccount = [
	...group,
	...role,
	...userInformationFields,
];
export const joinGroupAccountDetails = [
	...group,
	...role,
	...userInformationFields,
];

// No memberships
export const createChildGroupConfirmation = [...group];
export const createChildUserInformation = [...group];
export const createChildOwnAccount = [...group, ...targetUserInformationFields];
export const createChildAccountDetails = [
	...group,
	...targetUserInformationFields,
];
export const childGroupConfirmation = [...group];
