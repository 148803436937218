import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentAccount } from 'pkg/identity';

import * as formStyles from 'routes/public/styles/forms/styles.css';
import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import AccountCard from 'routes/public/no-memberships/styles/AccountCard';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import Header from 'routes/public/no-memberships/styles/Header';
import { useFetchAccountRelations } from 'routes/public/no-memberships/steps/Start';

import Column from 'components/layout/column';

export default function NewGroupConnection(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const currentAccount = useCurrentAccount();
	const { wards } = useFetchAccountRelations();

	useComponentDidMount(() => {
		onboardingState.setGroup(null);
	});

	const handleTargetAccountClick = async (
		targetAccount: models.account.Account
	) => {
		await onboardingState.set({ account: null, targetAccount });

		goTo('group-code');
	};

	const handleAccountClick = async () => {
		await onboardingState.set({ account: currentAccount, targetAccount: null });

		goTo('group-code');
	};

	return (
		<Column spacing={styles.spacing._8}>
			<Header />

			<Title
				title={t('Who is joining?')}
				description={t(
					'Select who you want to join the group. If you are a parent you will automaticlly be added to the group when adding your child as a player.'
				)}
				thinDescription
			/>

			{wards.length && (
				<Column>
					<span className={formStyles.label}>{t('Your children')}</span>
					{wards.map((relation) => (
						<AccountCard
							key={relation.id}
							account={
								relation.targetAccount as unknown as models.account.Account
							}
							onClick={() =>
								handleTargetAccountClick(
									relation.targetAccount as unknown as models.account.Account
								)
							}
						/>
					))}
				</Column>
			)}

			<Column>
				<span className={formStyles.label}>{t('Your account')}</span>
				<AccountCard account={currentAccount} onClick={handleAccountClick} />
			</Column>

			<CancelButton />
		</Column>
	);
}
