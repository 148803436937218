import * as routes from 'pkg/router/routes';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import { pushState } from 'pkg/router/state';

import { Spinner } from 'components/loaders/spinner';

interface PaymentStatusResponse {
	organizationId: number;
	groupId: number;
	formId: number;

	cancelled?: boolean;
	success?: boolean;
}
interface FetchPaymentStatusProps {
	formSubmissionId: string;
	afterFetch: (resp: PaymentStatusResponse) => void;
}
function FetchPaymentStatus({
	formSubmissionId,
	afterFetch,
}: FetchPaymentStatusProps): JSX.Element {
	useEndpoint<PaymentStatusResponse>(
		endpoints.FormSubmissions.GetPaymentStatus(
			Number.parseInt(formSubmissionId, 10)
		),
		{},
		(resp) => {
			afterFetch(resp);
		}
	);

	return <Spinner />;
}

const PaymentStatus = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const formSubmissionId = searchParams.get('formSubmissionId');

	const afterFetch = (resp: PaymentStatusResponse) => {
		if (resp.success) {
			pushState(
				routes.Registrations.Register.Success(
					resp.organizationId,
					resp.groupId,
					resp.formId
				)
			);
		} else if (resp.cancelled) {
			pushState(
				routes.Registrations.Register.Error(
					resp.organizationId,
					resp.groupId,
					resp.formId,
					Number.parseInt(formSubmissionId, 10)
				)
			);
		}
	};

	return (
		<FetchPaymentStatus
			formSubmissionId={formSubmissionId}
			afterFetch={afterFetch}
		/>
	);
};

export default PaymentStatus;
