import { Record } from 'immutable';
import { schema } from 'normalizr';

import Exercise from 'pkg/models/exercise';
import TrainingSession from 'pkg/models/session';

export const trainingCollectionExerciseSchema = new schema.Entity(
	'trainingCollectionsExercises',
	{
		exercise: Exercise.normalizr(),
	},
	{
		idAttribute: (entry) =>
			`tc${entry.trainingCollectionId}te${entry.exerciseId}`,
	}
);

export const trainingCollectionSessionSchema = new schema.Entity(
	'trainingCollectionsSessions',
	{
		session: TrainingSession.normalizr(),
	},
	{
		idAttribute: (entry) =>
			`tc${entry.trainingCollectionId}ts${entry.trainingSessionId}`,
	}
);

export class TrainingCollectionExercise extends Record(
	{
		exerciseId: 0,
		trainingCollectionId: 0,
		createdAt: 0,
	},
	'TrainingCollectionExercise'
) {
	static normalizr(): schema.Entity {
		return trainingCollectionExerciseSchema;
	}
}

export class TrainingCollectionSession extends Record(
	{
		trainingSessionId: 0,
		trainingCollectionId: 0,
		createdAt: 0,
	},
	'TrainingCollectionSession'
) {
	static normalizr(): schema.Entity {
		return trainingCollectionSessionSchema;
	}
}

export const trainingCollectionSchema = new schema.Entity(
	'trainingCollections',
	{
		exercises: [TrainingCollectionExercise.normalizr()],
		sessions: [TrainingCollectionSession.normalizr()],
	}
);

class TrainingCollection extends Record(
	{
		id: 0,
		groupId: null,
		userId: 0,
		accountId: 0,
		name: '',
		links: {},
		userCreated: true,
		inherit: false,
		createdAt: 0,
		exerciseCount: 0,
		sessionCount: 0,
		exercises: [],
		sessions: [],
	},
	'TrainingCollection'
) {
	static normalizr(): schema.Entity {
		return trainingCollectionSchema;
	}
}

export default TrainingCollection;
