import { t } from '@transifex/native';
import { ChangeEvent, useState } from 'react';

import * as styles from 'pkg/config/styles';

import DateTime from 'pkg/datetime';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';
import { MixedStateSetter } from 'pkg/hooks/useMixedState';

import { SelectedProductInfo } from 'routes/forms/hooks/useFormEngine';

import FormComponent from 'components/form/Form';
import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';
import TaxSelect from 'components/taxes/TaxSelect';
import Column from 'components/layout/column';
import DatePicker from 'components/form/date-picker';

interface ProductDetailsProps {
	formState: SelectedProductInfo;
	setFormState: MixedStateSetter<SelectedProductInfo>;
}

function ProductDetails({ formState, setFormState }: ProductDetailsProps) {
	const group = useCurrentGroup();

	const defaultShowAdjust =
		formState.minQuantity &&
		formState.maxQuantity &&
		formState.minQuantity != formState.maxQuantity;

	const [showAdjust, setShowAdjust] = useState<boolean>(defaultShowAdjust);
	const [validTo, setValidTo] = useState(formState.validTo > 0 ? 'custom' : '');

	const { records: taxRateRecords } = useCollection<models.taxRate.TaxRate>(
		endpoints.TaxRates.Index1(group.id)
	);
	const taxRates = models.taxRate.convertIdsToNumber(taxRateRecords);

	const handleToggleAdjust = () => {
		setShowAdjust((prev) => {
			// if we are deselecting the option for users to change quantity - default the maxQuantity to match the minQuantity
			if (prev) {
				setFormState({ ...formState, maxQuantity: formState.minQuantity });
			}

			return !prev;
		});
	};

	const handleChangeTaxRateId = (event: ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		const taxRate = taxRates.find((t) => t.id === Number.parseInt(value, 10));

		if (taxRate) {
			setFormState({
				taxRate,
				taxRateId: taxRate.id,
			});
		} else {
			setFormState({
				taxRate: null,
				taxRateId: 0,
			});
		}
	};

	const handleChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(event.target.value, 10);
		setFormState({ minQuantity: value, maxQuantity: value });
	};

	const handleChangeMinQuantity = (event: ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(event.target.value, 10);
		if (formState.maxQuantity && value > formState.maxQuantity) {
			return;
		}

		setFormState({ minQuantity: value });
	};

	const handleChangeMaxQuantity = (event: ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(event.target.value, 10);
		if (value < formState.minQuantity) {
			setFormState({ maxQuantity: formState.minQuantity });
		} else {
			setFormState({ maxQuantity: value });
		}
	};

	const handleToggleRequired = () => {
		setFormState({ required: !formState.required });
	};

	const handleValidToChange = (e: ChangeEvent<HTMLSelectElement>) => {
		if (e.currentTarget.value === '') {
			setFormState({
				validTo: 0,
			});
		} else {
			setFormState({
				validTo: Math.floor(new DateTime(new Date()).endOfDay / 1000),
			});
		}
		setValidTo(e.currentTarget.value);
	};

	const initValidToDate =
		formState.validTo > 0 ? new Date(formState.validTo * 1000) : new Date();

	const handleDateChange = (dates: Date[]) =>
		setFormState({
			validTo: Math.floor(new DateTime(new Date(dates[0])).endOfDay / 1000),
		});

	return (
		<FormComponent>
			<Column spacing={styles.spacing._6}>
				<Column>
					<Input.Group label={t('Valid until')}>
						<Input.Select
							value={validTo}
							name="validTo"
							data-testid="order.form.valid_to"
							onChange={handleValidToChange}>
							<option value="">{t('Forever')}</option>
							<option value="custom">{t('Custom')} </option>
						</Input.Select>
						{validTo === 'custom' && (
							<DatePicker
								dates={[initValidToDate]}
								disableDatesBefore={new Date()}
								handleChange={handleDateChange}
							/>
						)}
					</Input.Group>
					<Row>
						<Input.Group label={t('Quantity')}>
							<Input.Field
								type="number"
								name="quantity"
								min="1"
								onChange={handleChangeQuantity}
								value={formState.minQuantity}
							/>
						</Input.Group>
					</Row>
					<Row>
						<Input.Group label={t('TAX')}>
							<TaxSelect
								name="taxRateId"
								value={formState.taxRate ? formState.taxRate.id : null}
								handleChange={handleChangeTaxRateId}
							/>
						</Input.Group>
					</Row>
				</Column>
				<Column>
					<Input.Control
						type="checkbox"
						name="customerAdjust"
						value=""
						label={t('Allow customers to adjust quantity')}
						description={t('Customers can adjust the quantity of the product')}
						checked={showAdjust}
						onChange={handleToggleAdjust}
					/>
					{showAdjust && (
						<Row>
							<Input.Group label={t('Min')}>
								<Input.Field
									type="number"
									name="minQuantity"
									min="1"
									onChange={handleChangeMinQuantity}
									value={formState.minQuantity}
								/>
							</Input.Group>
							<Input.Group label={t('Max')}>
								<Input.Field
									type="number"
									name="maxQuantity"
									min="0"
									onChange={handleChangeMaxQuantity}
									value={formState.maxQuantity || formState.minQuantity}
								/>
							</Input.Group>
						</Row>
					)}
					<Input.Control
						type="checkbox"
						name="required"
						value=""
						label={t('Required for submission')}
						description={t(
							'The product is required to submit the registration'
						)}
						checked={formState.required}
						onChange={handleToggleRequired}
					/>
				</Column>
			</Column>
		</FormComponent>
	);
}

export default ProductDetails;
