import { t } from '@transifex/native';

import { pushState } from 'pkg/router/state';
import { FilterOperator } from 'pkg/filters';
import { Statuses } from 'pkg/api/models/order';
import * as routes from 'pkg/router/routes';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import { useAccountIdentity } from 'pkg/identity/account';

import { Widget } from 'routes/payments/overview/widgets';
import {
	useLocalizedCurrencyFormatter,
	useOrderReport,
} from 'routes/payments/overview/hooks';

import * as DataCard from 'design/data_card';

export default function RequiresActionWidget(): JSX.Element {
	const { organization } = useAccountIdentity();
	const { requiresAction } = useOrderReport();
	const fmt = useLocalizedCurrencyFormatter();

	const { pastDueSum, pastDueOrders, uncollectibleSum, uncollectibleOrders } =
		requiresAction;

	const numRequiresAction = pastDueOrders + uncollectibleOrders;
	const requiresActionSum = pastDueSum + uncollectibleSum;

	return (
		<Widget>
			<DataCard.Base
				titleIcon="warning"
				title={t('Requires action')}
				heading={t('{num} invoices', { num: numRequiresAction })}
				headingSuffix={fmt(requiresActionSum)}>
				<DataCard.Breakdown
					items={[
						{
							variant: 'label',
							color: 'red',
							title: t('Past due'),
							value: pastDueOrders.toString(),
							diff: fmt(pastDueSum),
							onClick: () =>
								pushState(
									toFilterQueryUrl(routes.Payments.Orders(organization.id), {
										status: {
											operator: FilterOperator.Includes,
											values: [Statuses.PastDue],
										},
									})
								),
						},
						{
							variant: 'label',
							color: 'orange',
							title: t('Uncollectible'),
							value: uncollectibleOrders.toString(),
							diff: fmt(uncollectibleSum),
							onClick: () =>
								pushState(
									toFilterQueryUrl(routes.Payments.Orders(organization.id), {
										status: {
											operator: FilterOperator.Includes,
											values: [Statuses.Uncollectible],
										},
									})
								),
						},
					]}
				/>
			</DataCard.Base>
		</Widget>
	);
}
