import { useState, useEffect } from 'react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';

import RegistrationForm from 'routes/forms/registration/form';
import SelectUser from 'routes/forms/registration/form/select_user';

import { Spinner } from 'components/loaders/spinner';

interface Props {
	guid: string;
	setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

interface ShowFormResponse {
	form: models.form.Form;
	paymentProviderSettings: models.providerSettings.ProviderSettings;
}

const Registration = ({ guid, setPageTitle }: Props) => {
	const [selectedUser, setSelectedUser] = useState<models.user.User>(null);
	const [selectedAccount, setSelectedAccount] =
		useState<models.account.Account>(null);

	const { record: response, isLoading } = useEndpoint<ShowFormResponse>(
		endpoints.Forms.PublicShow(guid)
	);

	useEffect(() => {
		if (response?.form?.title) {
			setPageTitle(response.form.title);
		}
	}, [isLoading]);

	const handleSelectUser = (user: models.user.User) => {
		setSelectedUser(user);
	};
	const handleSelectAccount = (account: models.account.Account) => {
		setSelectedAccount(account);
	};

	if (isLoading) {
		return <Spinner />;
	}

	return selectedUser || selectedAccount ? (
		<RegistrationForm
			form={response.form}
			submittedForUser={selectedUser}
			submittedForAccount={selectedAccount}
			paymentProviderSettings={response.paymentProviderSettings}
		/>
	) : (
		<SelectUser
			form={response.form}
			onSelectUser={handleSelectUser}
			onSelectAccount={handleSelectAccount}
		/>
	);
};

export default Registration;
