export function Create(): string {
	return `/v1/discounts`;
}

export function Delete(ID: number): string {
	return `/v1/discounts/${ID}`;
}

export function GetDiscountByCode(): string {
	return `/v1/discounts/code`;
}

export function Index(): string {
	return `/v1/discounts`;
}

export function Show(ID: number): string {
	return `/v1/discounts/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/discounts/${ID}`;
}
