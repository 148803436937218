// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a4HJ8DUHtgTQ2PJhfDTO {
    margin-top: var(--spacing-3);  /* To align with right column on desktop dashboard */
}

.J3OhdfzJPQncRKHVJiXY {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-3);
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-weight: var(--font-weight-semibold);
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}

.uLeWsiBpOPrjsKRq2keY {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-4);
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	color: var(--palette-gray-800);
}

@media (max-width: 768px) {

.uLeWsiBpOPrjsKRq2keY {
		font-size: var(--font-size-sm);
}
	}

.oQd1llSaoGcPY5H5Ps_K {
	border-radius: var(--radius-circle);
	background-color: var(--palette-blue-100);
	color: var(--palette-blue-500);
	padding: var(--spacing-3);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.MBhbZWyrQKFWhVCQyqbd {
	border-radius: var(--radius-circle);
	background-color: var(--palette-red-100);
	color: var(--palette-red-500);
	padding: var(--spacing-3);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./routes/dashboard/pending-invoices/style.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B,GAAG,oDAAoD;AACvF;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wCAAwC;CACxC,yBAA8B;KAA9B,sBAA8B;SAA9B,8BAA8B;AAC/B;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,8BAA8B;AAK/B;;AAHC;;AAND;EAOE,8BAA8B;AAEhC;CADC;;AAGD;CACC,mCAAmC;CACnC,yCAAyC;CACzC,8BAA8B;CAC9B,yBAAyB;CACzB,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;AACxB;;AAEA;CACC,mCAAmC;CACnC,wCAAwC;CACxC,6BAA6B;CAC7B,yBAAyB;CACzB,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;AACxB","sourcesContent":[".card {\n    margin-top: var(--spacing-3);  /* To align with right column on desktop dashboard */\n}\n\n.content {\n\tdisplay: flex;\n\tgap: var(--spacing-3);\n\talign-items: center;\n\tfont-weight: var(--font-weight-semibold);\n\tjustify-content: space-between;\n}\n\n.title {\n\tdisplay: flex;\n\tgap: var(--spacing-4);\n\talign-items: center;\n\tcolor: var(--palette-gray-800);\n\n\t@media (--small-viewport) {\n\t\tfont-size: var(--font-size-sm);\n\t}\n}\n\n.badge {\n\tborder-radius: var(--radius-circle);\n\tbackground-color: var(--palette-blue-100);\n\tcolor: var(--palette-blue-500);\n\tpadding: var(--spacing-3);\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.warningBadge {\n\tborder-radius: var(--radius-circle);\n\tbackground-color: var(--palette-red-100);\n\tcolor: var(--palette-red-500);\n\tpadding: var(--spacing-3);\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var card = `a4HJ8DUHtgTQ2PJhfDTO`;
export var content = `J3OhdfzJPQncRKHVJiXY`;
export var title = `uLeWsiBpOPrjsKRq2keY`;
export var badge = `oQd1llSaoGcPY5H5Ps_K`;
export var warningBadge = `MBhbZWyrQKFWhVCQyqbd`;
export default ___CSS_LOADER_EXPORT___;
