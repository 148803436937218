// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DSnyBXy9lhyHGuWduWRh {
	grid-area: close;
	margin-right: var(--spacing-4);
}
@media (max-width: 768px) {
	.DSnyBXy9lhyHGuWduWRh {
		margin-right: var(--spacing-6);
}
	}
`, "",{"version":3,"sources":["webpack://./components/step-modal/styles.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,8BAA8B;AAK/B;AAHC;CAJD;EAKE,8BAA8B;AAEhC;CADC","sourcesContent":[".closeButton {\n\tgrid-area: close;\n\tmargin-right: var(--spacing-4);\n\n\t@media (--small-viewport) {\n\t\tmargin-right: var(--spacing-6);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var closeButton = `DSnyBXy9lhyHGuWduWRh`;
export default ___CSS_LOADER_EXPORT___;
