import { t } from '@transifex/native';
import { useRef } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import useMixedState from 'pkg/hooks/useMixedState';
import { useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { CollectionResponse } from 'pkg/api/use_collection';

import CreateDiscountForm from 'routes/payments/discounts/create/form';
import {
	DiscountFormState,
	DiscountType,
	makeDiscountPayload,
} from 'routes/payments/discounts/create';

import * as Card from 'components/Card';
import Modal from 'components/modal';

import Form, { FormPayload, submitForm } from 'components/form/Form';

import Button, { ButtonGroup } from 'design/button';

interface CreateDiscountModalProps {
	discountsCollection: CollectionResponse<models.discount.Discount>;

	onClose: (discount?: models.discount.Discount) => void;
}

export default function CreateDiscountModal({
	discountsCollection,
	onClose,
}: CreateDiscountModalProps) {
	const org = useCurrentOrganization();

	const formRef = useRef(null);
	const [formState, setFormState] = useMixedState<DiscountFormState>({
		type: DiscountType.Percentage,
		active: true,
		saving: false,
		splitAmount: false,
	});

	const handleSubmit = async (data: FormPayload) => {
		setFormState({ saving: true });

		const payload = makeDiscountPayload(org.id, formState, data);

		const [req, resp] = await models.create<
			models.discount.CreateDiscountPayload,
			models.discount.Discount
		>(endpoints.Discount.Create(), payload);

		if (req.ok) {
			flashActions.show({
				title: t('Successfully created discount'),
			});

			discountsCollection.addRecord(resp);
			onClose(resp);
		}

		setFormState({ saving: false });
	};

	const onSubmitClick = async () => {
		submitForm(formRef);
	};

	const handleClose = () => onClose();

	return (
		<Modal onClose={handleClose}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('Create discount')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body>
					<Form formRef={formRef} onSubmit={handleSubmit} stopPropagation>
						<CreateDiscountForm
							formState={formState}
							setFormState={setFormState}
						/>
					</Form>
				</Card.Body>
				<Card.Divider />
				<Card.Footer>
					<ButtonGroup>
						<Button
							label={t('Cancel')}
							onClick={handleClose}
							busy={formState.saving}
						/>
						<Button
							label={t('Create')}
							primary
							onClick={onSubmitClick}
							isLoading={formState.saving}
						/>
					</ButtonGroup>
				</Card.Footer>
			</Card.Base>
		</Modal>
	);
}
