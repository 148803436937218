import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { RecurringInterval } from 'pkg/api/models/provider_settings';
import { PaymentPeriodInfo } from 'pkg/api/models/payment_preview';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface BillingSummaryProps {
	initialPayment: PaymentPeriodInfo;
	currency: string;

	recurringDiscountedPayment?: PaymentPeriodInfo;
	recurringPayment?: PaymentPeriodInfo;
	monthlyPayment?: number;
	recurringInterval?: RecurringInterval;
	installmentCount?: number;
	discount?: models.discount.Discount;
	totalInstallmentAmount?: number;
}

export default function BillingSummary({
	initialPayment,
	currency,
	monthlyPayment,
	recurringInterval,
	installmentCount,
	discount,
	recurringDiscountedPayment,
	recurringPayment,
	totalInstallmentAmount,
}: BillingSummaryProps): JSX.Element {
	const intervalMapping: { [key in RecurringInterval]: string } = {
		[RecurringInterval.Day]: t('Daily payment: '),
		[RecurringInterval.Week]: t('Weekly payment: '),
		[RecurringInterval.Month]: t('Monthly payment: '),
		[RecurringInterval.Year]: t('Yearly payment: '),
	};

	const isSubscription = installmentCount != 0 && !totalInstallmentAmount;

	const discountedRecurringSummary = (recurringInterval: RecurringInterval) => (
		<Row align="stretch">
			<div>
				<div className={css.dueToday}>{intervalMapping[recurringInterval]}</div>
				<div className={css.infoGrey}>
					{t('For {installmentCount} payments', {
						installmentCount: recurringDiscountedPayment.recurringCount,
					})}
				</div>
			</div>
			<div>
				<div className={css.summaryAmountContainer}>
					<div className={css.currentPrice}>
						{formatToCurrency(
							recurringDiscountedPayment?.salePrice / 100,
							currency
						)}
					</div>
					<div className={css.originalPrice}>
						{formatToCurrency(
							recurringDiscountedPayment?.originalSalePrice / 100,
							currency
						)}
					</div>
				</div>
				<div className={css.taxInfo}>{t('incl. tax and service fee')}</div>
			</div>
		</Row>
	);

	const recurringSummary = (recurringInterval: RecurringInterval) => (
		<Fragment>
			<Row align="stretch">
				<div>
					<div className={css.dueToday}>
						{intervalMapping[recurringInterval]}
					</div>
					{!isSubscription && (
						<div className={css.infoGrey}>
							{t('For {installmentCount} payments', {
								installmentCount: recurringPayment.recurringCount,
							})}
						</div>
					)}
				</div>
				<div>
					<div className={css.summaryAmount}>
						{formatToCurrency(monthlyPayment / 100, currency)}
					</div>
					<div className={css.taxInfo}>{t('incl. tax and service fee')}</div>
				</div>
			</Row>
			{!isSubscription && (
				<Fragment>
					<hr className={css.divider} />
					<Row align="stretch">
						<div>
							<div className={css.dueToday}>{t('Plan total: ')}</div>
							<div className={css.infoGrey}>
								{t('For {installmentCount} payments', {
									installmentCount,
								})}
							</div>
						</div>
						<div>
							<div className={css.summaryAmount}>
								{formatToCurrency(totalInstallmentAmount / 100, currency)}
							</div>
							<div className={css.taxInfo}>
								{t('incl. tax and service fee')}
							</div>
						</div>
					</Row>
				</Fragment>
			)}
		</Fragment>
	);

	return (
		<Column>
			<Row align="stretch">
				<div className={css.dueToday}>{t('Due today:')}</div>
				<div>
					<div className={css.summaryAmountContainer}>
						<div className={css.currentPrice}>
							{formatToCurrency(initialPayment?.salePrice / 100, currency)}
						</div>
						{discount && (
							<div className={css.originalPrice}>
								{formatToCurrency(
									initialPayment?.originalSalePrice / 100,
									currency
								)}
							</div>
						)}
					</div>
					<div className={css.taxInfo}>{t('incl. tax and service fee')}</div>
				</div>
			</Row>
			{recurringDiscountedPayment?.originalSalePrice &&
				discountedRecurringSummary(recurringInterval)}

			{recurringInterval && recurringSummary(recurringInterval)}
		</Column>
	);
}
