import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import useTooltip from 'pkg/hooks/useTooltip';
import * as routes from 'pkg/router/routes';
import DateTime from 'pkg/datetime';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import OrderActions from 'routes/payments/orders/context_menu_actions';
import RefundModal from 'routes/payments/orders/single/refund_modal';

import Icon from 'components/icon';

import StatusLabel from 'components/payment_platform/status/label';
import * as Input from 'components/form/inputs';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

const _context = 'payments.invoices.list';

interface OrderRowProps {
	order: models.order.Order;
	isChecked: boolean;
	handleSelect: (order: models.order.Order) => void;
	replaceRecord: (
		record: models.order.Order,
		key?: keyof models.order.Order
	) => void;
	removeRecord: (recordId: number) => void;
}

export default function OrderRow({
	order,
	isChecked,
	handleSelect,
	replaceRecord,
	removeRecord,
}: OrderRowProps) {
	const [refundModal, setRefundModal] = useState(false);
	const org = useCurrentOrganization();
	const group = useCurrentGroup();

	// unique array of assigned contacts
	const assignedContacts = [
		...new Map(
			order.userProducts
				?.map((userProduct) => userProduct.user)
				?.map((user) => [user['id'], user])
		).values(),
	];

	const assignedContact =
		assignedContacts.length > 0 ? assignedContacts[0] : null;

	const { onMouseEnter, tooltip } = useTooltip(
		assignedContacts.map((contact) => models.user.fullName(contact)).join(', ')
	);

	const amountDue = formatToCurrency(order.amountDue / 100, group.currency);
	const createdAtDateTime = DateTime.fromTimestamp(order.createdAt);
	const sentAtDateTime = DateTime.fromTimestamp(order.sentAt);
	const paidAtDateTime = DateTime.fromTimestamp(order.paidAt);
	const dueDateTime = new DateTime(new Date(order.dueDate * 1000));
	const dueDateString = dueDateTime.toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	const terms = models.order.getPaymentTerms(order);

	const hideModal = () => setRefundModal(false);
	const showRefundModal = () => setRefundModal(true);

	const contextMenu = (
		<Table.ActionCell>
			<ContextMenu.Menu
				toggleWith={
					<ContextMenu.TableTrigger>
						<Icon name="context-menu" />
					</ContextMenu.TableTrigger>
				}>
				<ContextMenu.LinkItem href={routes.Order.Show(org.id, order.id)}>
					<ContextMenu.ItemIcon name="arrow_forward" />
					<span>{t('View details')}</span>
				</ContextMenu.LinkItem>
				<OrderActions
					order={order}
					showRefundModal={showRefundModal}
					replaceRecord={replaceRecord}
					removeRecord={removeRecord}
				/>
			</ContextMenu.Menu>
		</Table.ActionCell>
	);

	const orderRoute = routes.Order.Show(org.id, order.id);

	let assignedContactsContent = null;

	if (assignedContacts.length === 0) {
		assignedContactsContent = <span>-</span>;
	} else if (assignedContacts.length === 1) {
		assignedContactsContent = (
			<span>{models.user.fullName(assignedContact)}</span>
		);
	} else {
		assignedContactsContent = (
			<Fragment>
				{tooltip}
				<span onMouseEnter={onMouseEnter}>
					{t('{amount} contacts', {
						_context,
						amount: assignedContacts.length,
					})}
				</span>
			</Fragment>
		);
	}

	let productText = '-';

	if (order.userProducts?.length === 1) {
		productText = order.userProducts[0].product?.name;
	} else if (order.userProducts?.length > 1) {
		productText = t('{amount} products', { amount: order.userProducts.length });
	}

	return (
		<Fragment>
			<Table.Row>
				<Table.Cell>
					<Input.Control
						standalone
						type="checkbox"
						checked={isChecked}
						onChange={() => handleSelect(order)}
					/>
				</Table.Cell>
				<Table.LinkCell href={orderRoute}>
					{order.firstName} {order.lastName}
				</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>{order.email}</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>
					<StatusLabel
						status={models.order.getStatus(order)}
						jobStatus={order.jobStatus}
					/>
				</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>{amountDue}</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>
					{order.invoiceNumber || '-'}
				</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>
					{assignedContactsContent}
				</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>{productText}</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>
					{terms} {t('days')}
				</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>
					{createdAtDateTime.toLocaleDateString({
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
					})}
				</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>
					{!!order.sentAt &&
						sentAtDateTime.toLocaleDateString({
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
							hour: 'numeric',
							minute: 'numeric',
						})}
				</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>
					{!!order.dueDate && dueDateString}
				</Table.LinkCell>
				<Table.LinkCell>
					{!!order.paidAt &&
						paidAtDateTime.toLocaleDateString({
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
							hour: 'numeric',
							minute: 'numeric',
						})}
				</Table.LinkCell>
				<Table.LinkCell href={orderRoute}>
					{order.subscriptionId
						? t('Subscription')
						: models.order.getTypeString(order)}
				</Table.LinkCell>
				{contextMenu}
			</Table.Row>

			{refundModal && (
				<RefundModal
					order={order}
					hideModal={hideModal}
					currency={group.currency}
				/>
			)}
		</Fragment>
	);
}
