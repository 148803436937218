import { t } from '@transifex/native';
import { SyntheticEvent } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';

import * as Table from 'design/table';

interface ProductsTableProps {
	selected?: number[];

	products: models.product.Product[];

	select: (id: number) => void;
}

interface ProductRowProps {
	product: models.product.Product;
	selectedProducts: number[];

	select?: (id: number) => void;
}

function ProductRow({ product, select, selectedProducts }: ProductRowProps) {
	const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();

		select(product.id);
	};

	return (
		<Table.Row>
			<Table.Cell onClick={handleClick}>
				<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
					<div>
						<Input.Control
							type="checkbox"
							checked={selectedProducts.includes(product.id)}
						/>
					</div>
					<span>{product.name}</span>
				</Row>
			</Table.Cell>
		</Table.Row>
	);
}

export default function ProductsTable({
	products,
	select,
	selected,
}: ProductsTableProps) {
	const columns = [
		{
			content: t('Name'),
		},
	];

	return (
		<Table.Table columns={columns}>
			{products.map((product) => (
				<ProductRow
					key={product.id}
					product={product}
					select={select}
					selectedProducts={selected}
				/>
			))}
		</Table.Table>
	);
}
