import React, { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { Field } from 'pkg/models/form';

import * as flashActions from 'pkg/actions/flashes';

import { FilterGroups, FilterValue, useFilters } from 'pkg/filters/use_filters';
import { FilterOperator } from 'pkg/filters';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import { useCollection } from 'pkg/api/use_collection';
import { useEndpoint } from 'pkg/api/use_endpoint';
import useFields from 'pkg/hooks/useTableFields';
import { crash } from 'pkg/errors/errors';
import { FieldTypes } from 'pkg/api/models/form';
import { useCurrentAccount, useCurrentOrganization } from 'pkg/identity';
import { link } from 'pkg/router/utils';

import Row from 'routes/forms/single/Row';

import AssignProductModal from 'containers/payment_platform/contacts/AssignProductModal';
import AddToSingleGroupModal from 'containers/payment_platform/contacts/AddToSingleGroupModal';

import AssetImage from 'components/AssetImage';
import Pagination from 'components/pagination';
import { useExport } from 'components/export';

import * as Inputs from 'components/form/inputs';
import * as ActionBar from 'components/layout/ActionBar';
import { TableContentWrapper } from 'components/layout/PageTemplates';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

interface SingleFormProps {
	groupId: number;
	formId: number;
}

const useFormFieldFilter = (formFields: models.form.Field[]): FilterGroups => {
	const filterGroups: FilterGroups = {};

	for (const field of formFields || []) {
		switch (field.type) {
			case FieldTypes.Choice:
			case FieldTypes.MultiChoice:
				const values: { [key: string]: FilterValue } = {};

				for (const value of field.values) {
					values[value.label] = value.value;
				}

				filterGroups[field.label] = {
					filters: {
						[field.label]: {
							type: 'checkbox',
							property: `field.${field.key}`,
							operator: FilterOperator.Includes,
							values,
						},
					},
				};
				break;
			case FieldTypes.Date:
				filterGroups[field.label] = {
					filters: {
						[field.label]: {
							type: 'date',
							property: `field.${field.key}`,
						},
					},
				};
				break;
			// default will be all types that use "FilterOperator.Contains"
			default:
				filterGroups[field.label] = {
					filters: {
						[field.label]: {
							type: 'text',
							property: `field.${field.key}`,
							operator: FilterOperator.Contains,
						},
					},
				};
				break;
		}
	}

	return filterGroups;
};

const Submissions: React.FC<React.PropsWithChildren<SingleFormProps>> = ({
	groupId,
	formId,
}) => {
	const org = useCurrentOrganization();
	const account = useCurrentAccount();

	const { record: form, isLoading: isFormLoading } =
		useEndpoint<models.form.Form>(endpoints.Forms.Show(formId));

	const formFields = form?.fields ? form.fields : [];
	const formFilter = useFormFieldFilter(formFields);
	const filterGroups: FilterGroups = {
		[t('Data')]: {
			hidden: true,
			filters: {
				Data: {
					type: 'text',
					property: 'data',
					operator: FilterOperator.Contains,
				},
			},
		},
		[t('Created')]: {
			filters: {
				Created: {
					type: 'date',
					property: 'createdAt',
					operator: FilterOperator.GreaterThan,
				},
			},
		},
		...formFilter,
	};

	const [showOnlySelected, setShowOnlySelected] = useState(false);

	const filters = useFilters({
		groups: filterGroups,
		filterBarMobileTrigger: true,
	});

	filters.queryParam.set('group_id', groupId.toString());
	filters.queryParam.set('form_id', formId.toString());
	filters.queryParam.set('relations', 'Form.Fields');

	const {
		records: submissions,
		isLoading: isSubmissionsLoading,
		pagination,
		selection,
		selectedRecords,
		removeRecord,
		sort,
	} = useCollection<models.formSubmission.FormSubmission>(
		endpoints.FormSubmissions.Index(),
		{
			defaultSortBy: 'created_at',
			defaultSortByOrder: 'desc',
			queryParams: filters.queryParam,
			showOnlySelected,
		}
	);

	// the submissions comes with the form connected to it, but if no submissions is done we still need the form to display the form-fields.

	const handleCloseModal = (): void =>
		setModal({ show: false, name: '', userId: 0 });

	const [modal, setModal] = useState<{
		show: boolean;
		name: string;
		userId?: number;
	}>({
		show: false,
		name: '',
		userId: 0,
	});
	const handleOpenProductModal = (userId: number) => {
		setModal({ show: true, name: 'product', userId });
	};

	const handleAddUserToGroup = (userId: number): void =>
		setModal({ show: true, name: 'group', userId });
	const handleAddUsersToGroup = (): void =>
		setModal({ show: true, name: 'group' });
	const handleAssignProductMultiple = (): void =>
		setModal({ show: true, name: 'product', userId: 0 });

	const displayFields = useFields(
		formFields
			.sort((a, b) => a.sortOrder - b.sortOrder)
			.map((field) => field.label),
		formFields.filter((field) => field.deletedAt).map((field) => field.label)
	);

	const handleViewForm = async () => {
		const token = await actions.auth.createOneTimeLoginToken(account.id);
		if (token !== '') {
			window.open(form.publicUrl + '?_token=' + token);
		}
	};

	const handleCopyUrl = async () => {
		await navigator.clipboard.writeText(form.publicUrl);
		flashActions.show({ title: t('URL copied') });
	};

	const handleSelectAll = () => {
		selection.selectAll();

		if (showOnlySelected) {
			setShowOnlySelected(false);
		}
	};

	const emptyState = {
		title: t('There are no registrations yet'),
		content: t('No registrations available'),
		image: <AssetImage src="img/missing-entities/post.svg" />,
	};

	const handleCreateExport = async (fields: string[]) => {
		const [ok, csvData] = await models.formSubmission.exportSubmissions(
			{
				form_id: form.id,
				filters: filters.currentFilters
					? JSON.stringify(filters.currentFilters)
					: '',
			},
			fields
		);

		if (ok) {
			const fileName = `${form.title}-submissions.csv`;

			const url = URL.createObjectURL(new Blob([csvData]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	const handleAddToGroup = async (groupId: number, role: number) => {
		const [request] = await models.create(
			endpoints.UserGroups.AddUsersToGroup(groupId),
			modal.userId
				? [{ id: modal.userId, role }]
				: selectedRecords.map((record) => ({
						id: record.submittedForUserId,
						role,
					}))
		);

		if (request.ok) {
			actions.flashes.show({
				title: t('Added users to group'),
			});
		} else {
			actions.flashes.show({
				title: t('Unable to add users to group'),
			});
		}
	};
	const selectedContactsEmails = selectedRecords
		.filter(
			(submission: models.formSubmission.FormSubmission) =>
				submission.submittedForUser.email
		)
		.map(
			(submission: models.formSubmission.FormSubmission) =>
				submission.submittedForUser.email
		)
		.join(',');

	const selectedContactsSubmittedByEmails = selectedRecords
		.filter(
			(submission: models.formSubmission.FormSubmission) =>
				submission.submittedByUser.email
		)
		.map(
			(submission: models.formSubmission.FormSubmission) =>
				submission.submittedByUser.email
		)
		.join(',');

	const selectAllCheckBox = (
		<Inputs.Control
			testid="check-all"
			type="checkbox"
			standalone
			checked={selection.isAllSelected}
			disabled={submissions.length === 0}
			onChange={handleSelectAll}
		/>
	);

	const showStatusColumn = submissions.find((submission) => submission.orderId);
	const showSubmittedByColumn = submissions.find(
		(submission) => submission.submittedByUser
	);
	const showSubmittedForColumn = submissions.find(
		(submission) => submission.submittedForUser
	);
	let success;
	const handleDelete = async () => {
		selectedRecords.forEach(
			async (submission: models.formSubmission.FormSubmission) => {
				success = await models.destroy(submission);

				if (success) {
					removeRecord(submission.id);
				}
			}
		);
		selection.deselectAll();
	};

	const columns = [
		{
			content: selectAllCheckBox,
			width: '50px',
		},
		showSubmittedForColumn && {
			content: t('Submitted for'),
			width: 'min-content',
		},
		showSubmittedByColumn && {
			content: t('Submitted by'),
			width: 'min-content',
		},
		showStatusColumn && {
			content: t('Status'),
			width: 'min-content',
		},
		...formFields.map((field: Field) => ({
			content: field.label,
			hide: displayFields.disabledFields.includes(field.label),
			sortKey: field.key,
		})),
		{
			content: t('Submitted at'),
			sortKey: 'created_at',
		},
		{
			content: '',
			width: 'max-content',
		},
	];

	const exportAction = useExport(formFields, handleCreateExport);

	return (
		<Fragment>
			{exportAction.modal}
			<TableContentWrapper>
				<ActionBar.IntegratedFilterBar
					pageActionIcon="more_horiz"
					searchFilter={filterGroups[t('Data')].filters.Data}
					filters={filters}
					actions={[
						!isFormLoading && {
							label: t('Export'),
							icon: 'download',
							onClick: exportAction.open,
						},
						{
							label: t('Actions'),
							icon: 'settings',
							contextMenuItems: [
								<ContextMenu.Item
									key="view-form"
									icon="new_window"
									onClick={handleViewForm}>
									{t('View form')}
								</ContextMenu.Item>,
								<ContextMenu.Item
									key="copy-url"
									icon="link"
									onClick={handleCopyUrl}>
									{t('Copy url')}
								</ContextMenu.Item>,
								<ContextMenu.LinkItem
									key="edit"
									icon="edit"
									href={routes.Registrations.Edit(org.id, groupId, form.id)}>
									{t('Edit form')}
								</ContextMenu.LinkItem>,
							],
						},
						{
							label: t('Fields'),
							icon: 'view_week',
							contextMenuItems: displayFields.contextMenuItems,
						},
					]}
				/>

				<ActionBar.BulkActions
					numSelected={selection.selectedRecords.length}
					showSelected={showOnlySelected}
					setShowSelected={setShowOnlySelected}
					actions={[
						<ContextMenu.LinkItem
							href={link(routes.Invoice.New(org.id), {
								userIds: selectedRecords.map(
									(record) => record.submittedForUserId
								),
							})}>
							<ContextMenu.ItemIcon name="request_quote" />
							{t('New order')}
						</ContextMenu.LinkItem>,
						<ContextMenu.Item onClick={handleAssignProductMultiple}>
							<ContextMenu.ItemIcon name="tag" />
							{t('Assign product')}
						</ContextMenu.Item>,
						<Fragment>
							{selection.selectedRecords.length > 0 && (
								<ContextMenu.LinkItem
									tooltip={t(
										'Send an email to the user for whom the registration was submitted'
									)}
									href={`mailto:?bcc=${selectedContactsEmails}`}>
									<ContextMenu.ItemIcon name="mail" />
									{t('Send email to: Submitted for')}
								</ContextMenu.LinkItem>
							)}
						</Fragment>,
						<Fragment>
							{selection.selectedRecords.length > 0 && (
								<ContextMenu.LinkItem
									tooltip={t(
										'Send an email to the user who submitted the registration, e.g. a parent'
									)}
									href={`mailto:?bcc=${selectedContactsSubmittedByEmails}`}>
									<ContextMenu.ItemIcon name="mail" />
									{t('Send email to: Submitted by')}
								</ContextMenu.LinkItem>
							)}
						</Fragment>,
						<ContextMenu.LinkItem onClick={handleAddUsersToGroup}>
							<ContextMenu.ItemIcon name="groups" />
							{t('Add users to group')}
						</ContextMenu.LinkItem>,
						<ContextMenu.Divider />,
						<ContextMenu.ConfirmItem
							caution
							icon="delete"
							message={t('Are you sure you wish to delete these submissions?')}
							onConfirm={handleDelete}>
							{t('Delete')}
						</ContextMenu.ConfirmItem>,
					]}
				/>
				<Table.Table
					emptyState={emptyState}
					stickyHeader
					stickyFooter
					sortBy={sort.column}
					sortOrder={sort.order}
					onSort={sort.setSort}
					columns={columns.filter((n) => n)}
					isLoading={isSubmissionsLoading || isFormLoading}>
					{submissions.map(
						(submission: models.formSubmission.FormSubmission) => (
							<Row
								key={submission.id}
								groupId={groupId}
								submission={submission}
								formFields={formFields}
								isSelected={selection.isSelected(submission.id)}
								onSelect={selection.selectSingle}
								showSubmittedForColumn={showSubmittedForColumn}
								showSubmittedByColumn={showSubmittedByColumn}
								showStatusColumn={showStatusColumn}
								handleOpenProductModal={handleOpenProductModal}
								onAddUserToGroup={handleAddUserToGroup}
								removeRecord={removeRecord}
							/>
						)
					)}
				</Table.Table>

				{!showOnlySelected && <Pagination {...pagination} />}
			</TableContentWrapper>
			{modal.show && modal.name === 'group' && (
				<AddToSingleGroupModal
					groupId={groupId}
					onConfirm={handleAddToGroup}
					onClose={handleCloseModal}
				/>
			)}
			{modal.show && modal.name === 'product' && (
				<AssignProductModal
					organizationId={org.id}
					userIds={
						modal.userId
							? [modal.userId]
							: (selectedRecords.map(
									(record) => record.submittedForUserId
								) as number[])
					}
					onClose={handleCloseModal}
				/>
			)}
		</Fragment>
	);
};

export default Submissions;
