import { useEffect, useState } from 'react';
import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { useFilters } from 'pkg/filters/use_filters';
import { useEndpoint } from 'pkg/api/use_endpoint';

import ContactTableView from 'routes/organization/contacts/list/Table';
import SideBar from 'routes/organization/single/SideBar';
import {
	usersFilterConfig,
	getFilters,
} from 'routes/organization/contacts/utils';

import * as SideBarLayout from 'components/layout/SideBarPage';

interface SingleProps {
	organizationId: number;
	singleGroupId: number;
	setPageTitle: (title: string) => void;
}

const Single: React.FC<React.PropsWithChildren<SingleProps>> = ({
	organizationId,
	singleGroupId,
	setPageTitle,
}) => {
	const [refreshUsers, setRefreshUsers] = useState(false);

	const { record: group, isLoading } = useEndpoint<models.group.Group>(
		endpoints.Groups.Show(singleGroupId)
	);

	const filters = useFilters({
		groups: {
			...getFilters(
				!isLoading
					? [
							models.group.hasFeature(group, Features.Payments) &&
								usersFilterConfig().products,
							models.group.hasFeature(group, Features.Payments) &&
								usersFilterConfig().product_status,
							models.group.hasFeature(group, Features.Payments) &&
								usersFilterConfig().product_valid,
							models.group.hasFeature(group, Features.Payments) &&
								usersFilterConfig().product_created,
							usersFilterConfig().user_created,
							usersFilterConfig().birth_date,
							usersFilterConfig().archived,
						]
					: []
			),
		},
		filterBarMobileTrigger: true,
	});

	useEffect(() => {
		if (singleGroupId) {
			actions.groups.fetchGroup(singleGroupId);
		}
	}, [singleGroupId]);

	useEffect(() => {
		if (group.name) {
			setPageTitle(group.name);
		}
	}, [group]);

	const defaultExportFilters = {
		group_id: singleGroupId.toString(),
	};

	const handleRefreshUsers = () => setRefreshUsers((prev) => !prev);

	return (
		<SideBarLayout.Layout>
			<SideBar
				group={group}
				groupId={organizationId}
				refreshUsers={handleRefreshUsers}
			/>
			<SideBarLayout.Table>
				<ContactTableView
					filters={filters}
					queryParams={filters.queryParam}
					organizationId={organizationId}
					subGroupId={singleGroupId}
					extraFields={[{ field: 'role', title: `${t('Role')}` }]}
					defaultExportFilters={defaultExportFilters}
					refreshUsers={refreshUsers}
					refreshCallBack={handleRefreshUsers}
				/>
			</SideBarLayout.Table>
		</SideBarLayout.Layout>
	);
};

export default Single;
