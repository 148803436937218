import { t } from '@transifex/native';
import { useMemo } from 'react';

import * as models from 'pkg/api/models';
import * as Routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import DateTime from 'pkg/datetime';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';

interface ArchivedFormRowProps {
	groupId: number;
	form: models.form.Form;
	remove: (id: number) => void;
}

const ArchivedFormRow = ({ groupId, form, remove }: ArchivedFormRowProps) => {
	const org = useCurrentOrganization();

	const createdAt = useMemo(() => {
		return new DateTime(new Date(form.createdAt * 1000));
	}, [form.createdAt]);

	const handleRestore = () => {
		models.reactivate(endpoints.Forms.Reactivate(form.id));
		remove(form.id);
	};

	const contextMenu = (
		<Table.ActionCell>
			<ContextMenu.Menu
				toggleWith={
					<ContextMenu.TableTrigger>
						<Icon name="context-menu" />
					</ContextMenu.TableTrigger>
				}>
				{form.submissionCount > 0 && (
					<ContextMenu.LinkItem
						icon="list_alt"
						href={Routes.Registrations.Single(
							org.id,
							groupId,
							form.id,
							'dashboard'
						)}>
						{t(`View submissions`)}
					</ContextMenu.LinkItem>
				)}
				<ContextMenu.Divider />
				<ContextMenu.Item icon="add" onClick={handleRestore}>
					{t(`Reactivate`)}
				</ContextMenu.Item>
			</ContextMenu.Menu>
		</Table.ActionCell>
	);

	return (
		<Table.Row>
			<Table.LinkCell
				href={Routes.Registrations.Single(
					org.id,
					groupId,
					form.id,
					'dashboard'
				)}>
				{form.title}
			</Table.LinkCell>
			<Table.LinkCell
				href={Routes.Registrations.Single(
					org.id,
					groupId,
					form.id,
					'dashboard'
				)}>
				{form.formCategory ? form.formCategory.title : t(`Uncategorized`)}
			</Table.LinkCell>
			<Table.LinkCell
				href={Routes.Registrations.Single(
					org.id,
					groupId,
					form.id,
					'dashboard'
				)}>
				{form.submissionCount}
				{`${form.maxSubmissions ? '/' + form.maxSubmissions : ''}`}
			</Table.LinkCell>
			<Table.LinkCell>{createdAt.toLocaleDateString()}</Table.LinkCell>
			{contextMenu}
		</Table.Row>
	);
};

export default ArchivedFormRow;
