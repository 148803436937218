import { t } from '@transifex/native';
import { useRef, useCallback, useReducer, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import * as models from 'pkg/api/models';
import * as Apple from 'pkg/apple';
import * as routes from 'pkg/router/routes';
import { replaceState } from 'pkg/router/state';
import { useCurrentRoute } from 'pkg/router/hooks';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as actions from 'pkg/actions';
import { useFetchIdentity } from 'pkg/identity';

import OrganizationAuthOptions from 'routes/account/settings/login/auth-option-card';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import DelayedTextInput from 'components/form/DelayedTextInput';
import FormOption from 'components/form/Toggle';
import {
	SettingRowDivider,
	SettingDoubleColumn,
	FullWidthSettingRow,
} from 'components/settings/Settings';
import SettingItem, { Label } from 'components/settings/SettingItem';
import FormHint from 'components/form/Hint';
import Column from 'components/layout/column';

interface EmailProps {
	account: models.account.Account;
	hasPassword: boolean;
}

const Email: React.FC<React.PropsWithChildren<EmailProps>> = ({
	account,
	hasPassword,
}: EmailProps): JSX.Element => {
	const [editingEmail, setEditingEmail] = useState<boolean>(false);
	const ref = useRef<HTMLInputElement>();
	const fetchIdentity = useFetchIdentity();

	const toggleEditEmail = () => setEditingEmail(!editingEmail);

	const saveEmail = async (email: string, currentPassword: string) => {
		if (email !== account.email) {
			await (async (): Promise<boolean> => {
				const patchedAccount = await actions.accounts.patchAccount(account.id, {
					email,
				});

				return Promise.resolve(!!patchedAccount);
			})();

			await actions.auth.passwordAuth(email, currentPassword, null, false);

			await fetchIdentity();
		}

		toggleEditEmail();
	};

	const editComponent = (
		<Label>
			<DelayedTextInput ref={ref} type="email" value={account.email} />
			<FormHint>
				{t(
					`Make sure this email is your own, and that it is accessible. Changing your email will require you to log in again.`
				)}
			</FormHint>
		</Label>
	);

	const submit = (password: string) => saveEmail(ref.current?.value, password);

	return (
		<SettingItem
			label={t(`Email`)}
			isEditing={editingEmail}
			onEdit={toggleEditEmail}
			onSubmit={submit}
			onCancel={toggleEditEmail}
			staticComponent={<span>{account.email}</span>}
			editableComponent={editComponent}
			requirePassword={hasPassword}
		/>
	);
};

interface EditState {
	editingEmail: boolean;
	editingPassword: boolean;
	loginWithApple: boolean;
	appleConnection: any;
}

interface EditStatePayload {
	type: string;
	payload: boolean;
}

const initialState: EditState = {
	editingEmail: false,
	editingPassword: false,
	loginWithApple: false,
	appleConnection: null,
};

interface FieldProps {
	user: models.user.User;
	account?: models.account.Account;
	isEditing: boolean;
	onEdit: () => void;
	onSave: (obj: any) => void;
	onCancel: () => void;
}

const flashSuccess = (message: string = '') =>
	actions.flashes.show(
		{
			title: t(`Successfully saved!`),
			message,
		},
		200
	);

const flashFail = (message: string = '') =>
	actions.flashes.show(
		{
			title: t(`Could not save!`),
			message,
		},
		400
	);

const Help = styled.div`
	padding: 10px;
	margin-bottom: 10px;
	font-size: var(--font-size-sm);
	border-radius: var(--radius-3);
	background: ${palette.blue[200]};
	color: ${palette.blue[600]};
`;

interface PasswordProps extends FieldProps {
	hasPassword: boolean;
}

const Password = ({
	hasPassword,
	isEditing,
	onEdit,
	onSave,
	onCancel,
}: PasswordProps) => {
	const newRef = useRef<HTMLInputElement>();
	const repRef = useRef<HTMLInputElement>();

	const staticComponent = hasPassword ? <span>{'•'.repeat(5)}</span> : null;

	const editComponent = (
		<Label>
			{!hasPassword && (
				<Help>
					{t(
						`Your account does not have a password on record, create a new one to secure your account.`
					)}
				</Help>
			)}
			<SettingDoubleColumn>
				<div>
					<Label>
						<span>{t(`New password`)}</span>
						<DelayedTextInput ref={newRef} type="password" />
					</Label>
				</div>
				<div>
					<Label>
						<span>{t(`Repeat new password`)}</span>
						<DelayedTextInput ref={repRef} type="password" />
					</Label>
				</div>
			</SettingDoubleColumn>
		</Label>
	);

	const submit = (currentPassword: string) =>
		onSave({
			currentPassword,
			password: newRef.current?.value,
			passwordConfirm: repRef.current?.value,
		});

	return (
		<SettingItem
			label={t(`Password`)}
			isEditing={isEditing}
			onEdit={onEdit}
			onSubmit={submit}
			onCancel={onCancel}
			staticComponent={staticComponent}
			editableComponent={editComponent}
			requirePassword={hasPassword}
		/>
	);
};

interface LoginProps {
	user: models.user.User;
	account: models.account.Account;
}

interface PasswordPayload {
	currentPassword: string;
	password: string;
	passwordConfirm: string;
}

export default function Login({ user, account }: LoginProps): JSX.Element {
	const dispatch = useDispatch();
	const hasPassword = account.hasPassword;
	const loginWithApple = account.hasAppleAuth;
	const route = useCurrentRoute();
	const appleAuthCode = route.query?.code;
	const fetchIdentity = useFetchIdentity();

	const [state, emit] = useReducer(
		(state: EditState, { type, payload }: EditStatePayload) => ({
			...state,
			[type]: payload,
		}),
		{ ...initialState, loginWithApple }
	);

	useComponentDidMount(async () => {
		if (!state.appleConnection) {
			const appleConnection = await Apple.setup();

			emit({ type: 'appleConnection', payload: appleConnection });
		}
	});

	useEffect(() => {
		if (appleAuthCode?.length > 0) {
			const updateAppleAuthCode = async () => {
				await dispatch(
					actions.accounts.connectApple(account.id, appleAuthCode)
				);

				replaceState(routes.Account.Settings.Show('general'));
			};

			updateAppleAuthCode();
		}
	}, [appleAuthCode]);

	const editingPassword = state.editingPassword;

	const toggleEditPassword = useCallback(
		() => emit({ type: 'editingPassword', payload: !editingPassword }),
		[editingPassword]
	);

	const savePassword = useCallback(
		async ({ currentPassword, password, passwordConfirm }: PasswordPayload) => {
			if (password !== passwordConfirm) {
				flashFail(t(`Passwords must match!`));
			} else if (password === user?.email) {
				flashFail(t(`Your password can not be the same as your email adress.`));
			} else {
				let success = false;

				const savedAccount = await actions.accounts.patchAccount(account.id, {
					currentPassword,
					password,
					passwordConfirm,
				});

				success = !!savedAccount.id;

				if (success) {
					try {
						await actions.auth.passwordAuth(
							account.email,
							password,
							null,
							false
						);
						fetchIdentity();
					} catch (e: any) {
						flashFail();
						return;
					}

					flashSuccess();
				} else {
					flashFail();
				}
			}

			toggleEditPassword();
		},
		[dispatch, user, toggleEditPassword]
	);

	const appleConnectionChange = async (active: boolean) => {
		if (!active) {
			Apple.disconnect(async () => {
				await dispatch(actions.accounts.disconnectApple(account.id));

				emit({
					type: 'loginWithApple',
					payload: false,
				});
			});
		} else {
			Apple.connect(
				state.appleConnection,
				async (accessToken: string, response: any) => {
					if (response.email?.length > 0) {
						await actions.auth.saveAppleSignInCredentials({
							token: response.identityToken,
							firstname: response.fullName?.givenName,
							lastname: response.fullName?.familyName,
							email: response.email,
						});
					}

					await dispatch(
						actions.accounts.connectApple(account.id, accessToken)
					);

					emit({
						type: 'loginWithApple',
						payload: true,
					});
				}
			);
		}
	};

	return (
		<Column>
			<SectionTitle>{t(`Login options`)}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					<Email account={account} hasPassword={hasPassword} />
					<SettingRowDivider />
					<Password
						user={user}
						hasPassword={hasPassword}
						isEditing={editingPassword}
						onEdit={toggleEditPassword}
						onCancel={toggleEditPassword}
						onSave={savePassword}
					/>
					<SettingRowDivider />
					<FullWidthSettingRow>
						<FormOption
							standAlone
							icon="apple"
							label={t(`Sign in with Apple`)}
							description={t(
								`If active, you can use {media} to log in to 360Player.`,
								{
									media: 'Apple ID',
								}
							)}
							disabled={!state.appleConnection || !hasPassword}
							isActive={state.loginWithApple}
							onChange={appleConnectionChange}
						/>
					</FullWidthSettingRow>
				</Card.Body>
			</Card.Base>
			<OrganizationAuthOptions />
		</Column>
	);
}
