// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tFactaX5QqeRoNhGSbsg {
	background-color: var(--palette-gray-100);
	border: 1px solid var(--palette-gray-400);
	padding: var(--spacing-5);
		border-radius: var(--radius-3);
}

.AjpiX74IWuLoopAQOJRY {
	-ms-flex-item-align: end;
	    align-self: end;
	margin-bottom: var(--spacing-4);
}

.I9ge4VlF3uJfJJV5I3pe {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./routes/scheduling/templates/modals/publish/published-period/styles.css"],"names":[],"mappings":"AAAA;CACC,yCAAyC;CACzC,yCAAyC;CACzC,yBAAyB;EACxB,8BAA8B;AAChC;;AAEA;CACC,wBAAe;KAAf,eAAe;CACf,+BAA+B;AAChC;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAA8B;KAA9B,sBAA8B;SAA9B,8BAA8B;AAC/B","sourcesContent":[".date-card {\n\tbackground-color: var(--palette-gray-100);\n\tborder: 1px solid var(--palette-gray-400);\n\tpadding: var(--spacing-5);\n\t\tborder-radius: var(--radius-3);\n}\n\n.icon {\n\talign-self: end;\n\tmargin-bottom: var(--spacing-4);\n}\n\n.buttons {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `tFactaX5QqeRoNhGSbsg`;
export { _1 as "date-card" };
export var icon = `AjpiX74IWuLoopAQOJRY`;
export var buttons = `I9ge4VlF3uJfJJV5I3pe`;
export default ___CSS_LOADER_EXPORT___;
