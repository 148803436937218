import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'immutable';
import { t } from '@transifex/native';

import TrainingCollection from 'pkg/models/training_collection';

import * as selectors from 'pkg/selectors/index';

import * as actions from 'pkg/actions/index';
import { addExercise, addSession } from 'pkg/actions/training_collections';

import { RootState } from 'pkg/reducers';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import StepModal, { Step } from 'components/step-modal';

import SelectList from 'components/collections/SelectList';

interface AddToCollectionProps {
	onClose: () => any;
	id: number;
	currentTab?: string;
	type: string;
}

const AddToCollection = ({
	onClose,
	id,
	currentTab = 'my',
	type,
}: AddToCollectionProps) => {
	const dispatch = useDispatch();

	const [selectedCollections, setCollectionSelection] = useState([]);
	const [tab, setTab] = useState(currentTab === 'all' ? 'my' : currentTab);

	const group = useCurrentGroup();
	const org = useCurrentOrganization();

	let createProps = {};

	const collections = useSelector<RootState, List<TrainingCollection>>(
		(state) => {
			switch (tab) {
				case 'my': {
					return selectors.trainingCollections.findAllUnconnected(
						state,
						selectors.trainingCollections.findAllUserCollections(state),
						{ id, type }
					);
				}

				case 'team': {
					return selectors.trainingCollections.findAllUnconnected(
						state,
						selectors.trainingCollections.findAllInGroup(state, {
							groupId: group.id,
						}),
						{ id, type }
					);
				}

				case 'club': {
					return selectors.trainingCollections.findAllUnconnected(
						state,
						selectors.trainingCollections.findAllInGroup(state, {
							groupId: group.id,
							includeInheriting: true,
						}),
						{ id, type }
					);
				}
			}
		}
	);

	useEffect(() => {
		const fetchFilteredCollections = () => {
			switch (tab) {
				case 'team': {
					return actions.trainingCollections.fetchCollections(org.id, {
						sports: [group.sportId],
						group_id: group.id,
					})(dispatch);
				}
				case 'club': {
					return actions.trainingCollections.fetchCollections(org.id, {
						sports: [group.sportId],
						group_id: group.id,
						inherited: 1,
					})(dispatch);
				}
				case 'my': {
					return actions.trainingCollections.fetchCollections(org.id, {
						sports: [group.sportId],
					})(dispatch);
				}
			}
		};

		const fetchData = () => fetchFilteredCollections();

		fetchData();
	}, [dispatch, group.id, tab, org.id]);

	switch (tab) {
		case 'my':
			break;
		case 'team':
			createProps = { groupId: group.id };
			break;
		case 'club':
			createProps = { groupId: group.id, inherit: true };
			break;
	}

	const onTabChange = (key: string) => {
		setTab(key);
	};

	const onChange = (collectionID: number) => {
		let ids = [...selectedCollections];

		if (ids.indexOf(collectionID) === -1) {
			ids.push(collectionID);
		} else {
			ids = ids.filter((id) => id !== collectionID);
		}

		setCollectionSelection(ids);
	};

	const onSave = async () => {
		const requests: any[] = [];

		selectedCollections.forEach((collectionId) => {
			switch (type) {
				case 'exercises':
					requests.push(dispatch(addExercise(org.id, collectionId, id)));
					break;
				case 'sessions':
					requests.push(dispatch(addSession(org.id, collectionId, id)));
					break;
			}
		});

		await Promise.all(requests);
		onClose();
	};

	const handleCreateCollection = async (name: string) => {
		const response = await actions.trainingCollections.createCollection(
			org.id,
			{ name, ...createProps }
		)(dispatch);

		onChange(response.id);
	};

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t(`Add to collection`)}
				onNext={onSave}
				prevLabel={t('Close')}
				nextLabel={t('Save')}
				canGoNext={selectedCollections.length !== 0}
				skipBody>
				<SelectList
					tab={tab}
					onTabChange={onTabChange}
					handleCreateCollection={handleCreateCollection}
					selectedCollections={selectedCollections}
					onChange={onChange}
					collections={collections}
				/>
			</Step>
		</StepModal>
	);
};

export default AddToCollection;
