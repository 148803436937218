import { useContext, useEffect } from 'react';

import { CurrentRouteContext } from 'pkg/router/Router';
import { RouterState, RouterContext } from 'pkg/router/store';
import { setConfirmNavigation } from 'pkg/router/utils';

export const useCurrentRoute = () => {
	const ctx = useContext(CurrentRouteContext);

	if (ctx && ctx?.delegated) {
		return ctx.delegated;
	}

	return {};
};

export const useCurrentRouterState = (fallbackLocation = '/') => {
	const { store, stateType } = useContext(RouterContext);

	let currentState = new RouterState({
		location: fallbackLocation,
	});

	const history = store.get('history');

	if (history?.size >= 1) {
		currentState = history.last();
	}

	return [currentState, stateType];
};

export const useRouterState = () => {
	const { store, dispatch, stateType } = useContext(RouterContext);
	const history = store.get('history');

	const pushState = (location, state = {}) => {
		return dispatch({
			type: `${stateType}/PUSH`,
			payload: { location, state },
			direction: 1,
		});
	};

	const popState = () => {
		return dispatch({
			type: `${stateType}/POP`,
			payload: null,
			direction: -1,
		});
	};

	const replaceState = (location, state = {}) => {
		return dispatch({
			type: `${stateType}/REPLACE`,
			payload: { location, state },
			direction: 0,
		});
	};

	return { store: history, pushState, popState, replaceState };
};

export const useRouterDispatch = () => {
	const { stateType, dispatch } = useContext(RouterContext);

	return (type, payload = {}) =>
		dispatch({ type: `${stateType}/${type.toUpperCase()}`, payload });
};

export const useConfirmNavigation = (confirmNavigation = null) => {
	const [currentState] = useCurrentRouterState();

	useEffect(() => {
		setConfirmNavigation(confirmNavigation, currentState.location);

		return () => setConfirmNavigation(null);
	});
};
