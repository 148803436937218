// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WHSAdgUhh5FKvrotpa8q {
	height: var(--height);
	padding-left: calc(var(--spacing-2) + 3px);
}
`, "",{"version":3,"sources":["webpack://./components/event/calendar-styles/meet-time/styles.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,0CAA0C;AAC3C","sourcesContent":[".time {\n\theight: var(--height);\n\tpadding-left: calc(var(--spacing-2) + 3px);\n}\n"],"sourceRoot":""}]);
// Exports
export var time = `WHSAdgUhh5FKvrotpa8q`;
export default ___CSS_LOADER_EXPORT___;
