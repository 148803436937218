// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rLNL6OMJ78c9ByXIXXgi {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.rLNL6OMJ78c9ByXIXXgi h1 {
		color: red;
	}

.ymJ59qjzQxUb_MCBvVEw img {
		width: 100%;
		max-height: 50vh;
		-o-object-fit: contain;
		   object-fit: contain;
	}

.GyIa9zmQumEQgrcIkCxH {
	padding: var(--spacing-6) !important;
}

.GyIa9zmQumEQgrcIkCxH h1 {
		font-size: var(--font-size-3xl);
		color: var(--palette-gray-900);
		font-weight: var(--font-weight-semibold);
	}

.vrq788sy9zlIN4tlU2vw {
	font-size: var(--font-size-sm);
	color: var(--palette-gray-900);
}
`, "",{"version":3,"sources":["webpack://./components/forms/registration-modal/styles.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,iCAAiC;AAKlC;;AAHC;EACC,UAAU;CACX;;AAIA;EACC,WAAW;EACX,gBAAgB;EAChB,sBAAmB;KAAnB,mBAAmB;CACpB;;AAGD;CACC,oCAAoC;AAOrC;;AALC;EACC,+BAA+B;EAC/B,8BAA8B;EAC9B,wCAAwC;CACzC;;AAGD;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B","sourcesContent":[".wrapper {\n\toverflow: auto;\n\t-webkit-overflow-scrolling: touch;\n\n\t& h1 {\n\t\tcolor: red;\n\t}\n}\n\n.image {\n\t& img {\n\t\twidth: 100%;\n\t\tmax-height: 50vh;\n\t\tobject-fit: contain;\n\t}\n}\n\n.content {\n\tpadding: var(--spacing-6) !important;\n\n\t& h1 {\n\t\tfont-size: var(--font-size-3xl);\n\t\tcolor: var(--palette-gray-900);\n\t\tfont-weight: var(--font-weight-semibold);\n\t}\n}\n\n.submissions {\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-900);\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `rLNL6OMJ78c9ByXIXXgi`;
export var image = `ymJ59qjzQxUb_MCBvVEw`;
export var content = `GyIa9zmQumEQgrcIkCxH`;
export var submissions = `vrq788sy9zlIN4tlU2vw`;
export default ___CSS_LOADER_EXPORT___;
