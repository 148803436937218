import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { UserProductStatuses } from 'pkg/api/models/user_product';
import { useCurrentOrganization } from 'pkg/identity';
import { Statuses } from 'pkg/api/models/order';

import { UserActions } from 'routes/group/show/user_item/user_actions';

import Label from 'components/label';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Avatar from 'components/avatar';

import * as UserCard from 'components/user/UserCard';

import * as css from './styles.css';

interface UserItemProps {
	currentMembership: models.membership.Membership;
	membership: models.membership.Membership;
	removeRecord: (id: number) => void;
	replaceRecord: (membership: models.membership.Membership) => void;
	group: models.group.Group;
}

export default function UserItem({
	currentMembership,
	membership,
	removeRecord,
	replaceRecord,
	group,
}: UserItemProps): JSX.Element {
	const org = useCurrentOrganization();
	const isParentForMembership =
		currentMembership?.targetUserId === membership.userId;

	const viewProfile = () => {
		pushState(
			routes.User.Profile.Show(
				org.id,
				membership.groupId,
				membership.userId,
				'overview'
			)
		);
	};

	const canViewProfile = () => {
		// Admin and staff can view every profile
		if (models.membership.isAdminOrStaff(currentMembership)) {
			return true;
		}

		if (models.membership.isPlayer(currentMembership)) {
			if (
				models.membership.isPlayer(membership) || // Other players
				models.membership.isAdminOrStaff(membership) // Admin and staff
			) {
				return true;
			}
		}

		// Parent can view their own profile, and their child's profile
		if (models.membership.isParent(currentMembership)) {
			if (currentMembership.userId === membership.userId) {
				return true;
			} else if (isParentForMembership) {
				return true;
			}
		}

		return false;
	};

	const userProducts = membership.user.products?.filter(
		(up) => up.order?.status !== Statuses.Draft
	);
	const userHasOverduePayments = userProducts?.some(
		(userProduct) => userProduct.status === UserProductStatuses.PastDue
	);

	const showPaymentOverdue =
		models.membership.isAdmin(currentMembership) && userHasOverduePayments;

	return (
		<UserCard.Base clickable={canViewProfile()}>
			<UserCard.User onClick={canViewProfile() ? viewProfile : null}>
				<Avatar user={membership.user} size={40} />

				<div className={css.titleItemWrapper}>
					<div>
						<strong>{models.user.fullName(membership.user)}</strong>
						{membership.title && <span>{membership.title}</span>}
						{models.membership.isParent(membership) && (
							<span>
								{t(`Legal guardian for {name}`, {
									name: models.user.legalGuardianFor(membership.user),
								})}
							</span>
						)}
					</div>
					{showPaymentOverdue && (
						<SmallScreen>
							<Label small color="red">
								{t('Payment overdue')}
							</Label>
						</SmallScreen>
					)}
				</div>
			</UserCard.User>

			<UserCard.Actions>
				{showPaymentOverdue && (
					<LargeScreen>
						<Label color="red">{t('Payment overdue')}</Label>
					</LargeScreen>
				)}

				<UserActions
					currentMembership={currentMembership}
					membership={membership}
					removeRecord={removeRecord}
					replaceRecord={replaceRecord}
					group={group}
					userHasOverduePayments={userHasOverduePayments}
				/>
			</UserCard.Actions>
		</UserCard.Base>
	);
}
