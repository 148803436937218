import { useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Account from 'pkg/models/account';

import { MembershipRole } from 'pkg/api/models/membership';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Group } from 'pkg/api/models/onboarding_info';

import * as formStyles from 'routes/public/styles/forms/styles.css';
import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import TeamBadge from 'routes/public/styles/TeamBadge';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import RoleSelectorCard from 'routes/public/styles/RoleSelectorCard';

import Column from 'components/layout/column';

export default function Confirmation(): JSX.Element {
	const { resetStore } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const [selectedRole, setSelectedRole] = useState<MembershipRole>(null);
	const [isSaving, setIsSaving] = useState(false);

	const account = onboardingState.get<Account>('account');
	const groupCode = onboardingState.get<string>('groupCode');
	const group = onboardingState.get<Group>('group');

	const handleSelectRole = (role: MembershipRole) => {
		setSelectedRole(role);
	};

	const handleSave = async () => {
		setIsSaving(true);

		const role = selectedRole ? selectedRole : MembershipRole.User;

		const [joinRequest] = await api.post(
			endpoints.Groups.JoinFromInviteCode(),
			{
				code: groupCode,
				role,
			}
		);

		if (!joinRequest.ok) {
			setIsSaving(false);
		} else {
			resetStore();
			window.history.pushState({}, '', '/');
			window.location.reload();
		}
	};

	const showRole = !group.isOrganization;

	return (
		<Column spacing={styles.spacing._8}>
			<Title
				title={t('You ({name}) are about to join:', {
					name: `${account.firstName} ${account.lastName}`,
				})}
			/>

			<TeamBadge group={group} />

			{showRole && (
				<Column>
					<span className={formStyles.label}>
						{t('Please select your role')}
					</span>
					<RoleSelectorCard
						label={t('Coach or staff')}
						description={t(
							'You are a coach or staff member in the team or club.'
						)}
						userRole="staff"
						onClick={() => handleSelectRole(MembershipRole.Staff)}
						selected={selectedRole === MembershipRole.Staff}
					/>
					<RoleSelectorCard
						label={t('Player')}
						description={t('A player signing up on their own.')}
						userRole="player"
						onClick={() => handleSelectRole(MembershipRole.User)}
						selected={selectedRole === MembershipRole.User}
					/>
				</Column>
			)}

			<Button
				primary
				label={t('Send request')}
				onClick={handleSave}
				busy={isSaving}
				disabled={showRole && !selectedRole}
			/>

			<CancelButton disabled={isSaving} />
		</Column>
	);
}
