import { t } from '@transifex/native';

import * as actions from 'pkg/actions';
import * as api from 'pkg/api';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentRoute } from 'pkg/router/hooks';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentAccount } from 'pkg/identity';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import { useFetchAccountRelations } from 'routes/public/no-memberships/steps/Start';

import { Spinner } from 'components/loaders/spinner';

export default function AutoJoin() {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const { groupCode } = useCurrentRoute();

	const currentAccount = useCurrentAccount();
	const { wards } = useFetchAccountRelations();

	useComponentDidMount(async () => {
		if (!groupCode) {
			goTo('start');
			return;
		}

		const [request, group] = await api.get<models.onboardingInfo.Group>(
			endpoints.Accounts.OnboardingInfo() + `?invite_code=${groupCode}`
		);

		if (!request.ok) {
			actions.flashes.show(
				{
					title: t('Something went wrong'),
					message: t('This group code is not in use.'),
				},
				request.status
			);

			goTo('start');
		} else {
			if (wards.length) {
				await onboardingState.set({
					groupCode,
					group,
				});

				goTo('join-group-choose-account');
			} else {
				await onboardingState.set({
					groupCode,
					group,
					account: currentAccount,
				});

				goTo('join-group-confirmation');
			}
		}
	});

	return <Spinner />;
}
