import React, { Fragment, memo, useEffect, useState } from 'react';
import { t } from '@transifex/native';

import { useHapticFeedback, HapticFeedbackImpact } from 'pkg/haptics';
import Link from 'pkg/router/Link';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import * as actions from 'pkg/actions';
import useConfirm from 'pkg/hooks/useConfirm';
import {
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';
import * as models from 'pkg/api/models';
import { Collection } from 'pkg/api/models/collection';
import { useCollection } from 'pkg/api/use_collection';

import CommentForm from 'routes/wall/post/comment_form';
import Comment from 'routes/wall/post/Comment';

import PostForm from 'containers/group/post/Form';

import { Trigger } from 'components/ScrollSpy';
import * as Item from 'components/FeedItem';
import Badge from 'components/Badge';
import CardWrapper, * as Card from 'components/Card';
import {
	FormattedContent,
	FormattedContentMaxLength,
} from 'components/formatted-content';
import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import UsersModal from 'components/group/post/UsersModal';
import Impressions from 'components/group/post/Impressions';
import GalleryPreview from 'components/attachment/gallery/preview';
import { Spinner } from 'components/loaders/spinner';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface PostCommentsProps {
	post: models.groupPost.GroupPost;
	comments: Collection<models.groupPostComment.GroupPostComment>;
}

function PostComments({ post, comments }: PostCommentsProps) {
	const [isFirstFetch, setIsFirstFetch] = useState(true);
	const [editedComments, setEditedComments] = useState<
		{ commentId: number; content: string }[]
	>([]);
	const [deletedComments, setDeletedComments] = useState<number[]>([]);

	const {
		records: additionalComments,
		isLoading,
		pagination,
		addRecord,
	} = useCollection<models.groupPostComment.GroupPostComment>(
		!isFirstFetch && comments?.links?.['next'],
		{
			showAllResults: true,
		}
	);

	const hasMoreComments =
		(isFirstFetch && comments?.links?.['next']) || pagination.hasNext;

	const handleMoreComments = () => {
		if (isFirstFetch) {
			setIsFirstFetch(false);
		}
		if (pagination.hasNext) {
			pagination.fetchNext();
		}
	};

	const handleCommentEdit = async (commentId: number, comment: string) => {
		await actions.posts.updateComment(commentId, comment.trim());
		setEditedComments((curr) => {
			const i = curr.findIndex((comm) => comm.commentId === commentId);
			if (i !== -1) {
				curr[i].content = comment;
				return [...curr];
			}

			curr.push({
				commentId: commentId,
				content: comment,
			});
			return [...curr];
		});
	};

	const handleCommentDelete = async (commentId: number) => {
		await actions.posts.deleteComment(commentId);
		setDeletedComments((curr) => {
			curr.push(commentId);
			return [...curr];
		});
	};

	const onNewComment = (comment: models.groupPostComment.GroupPostComment) => {
		addRecord(comment);
	};

	const allComments = [
		...(additionalComments || []),
		...(comments?.records || []),
	]
		.filter((c) => !deletedComments.includes(c.id))
		.map((c) => {
			const i = editedComments.findIndex((comm) => comm.commentId === c.id);
			if (i === -1) {
				return c;
			}
			c.comment = editedComments[i].content;
			return c;
		})
		.sort((a, b) => a.id - b.id);

	return (
		<div className={css.comments}>
			{!isLoading && hasMoreComments && (
				<div className={css.moreComments} onClick={handleMoreComments}>
					{t('Show more')}
				</div>
			)}

			{isLoading && (
				<div className={css.commentsLoading}>
					<Spinner size="30px" />
				</div>
			)}

			{allComments.map((comment: models.groupPostComment.GroupPostComment) => (
				<Comment
					key={comment.id}
					commentId={comment.id}
					commentType="GroupPostComment"
					user={comment.user}
					content={comment.comment}
					createdAt={comment.createdAt}
					onDelete={comment.links?.['delete'] && handleCommentDelete}
					onEdit={comment.links?.['edit'] && handleCommentEdit}
				/>
			))}

			{!post.commentsDisabled && (
				<CommentForm onNewComment={onNewComment} id={post.id} />
			)}
		</div>
	);
}

interface GroupPostProps {
	post: models.groupPost.GroupPost;
	onEdit?: (post: models.groupPost.GroupPost) => void;

	hideComments?: boolean;
	showFullPost?: boolean;
}

const GroupPost = memo(
	({
		post: initialPost,
		hideComments,
		showFullPost = false,
	}: GroupPostProps) => {
		const org = useCurrentOrganization();
		const user = useCurrentUser();
		const activeMembership = useCurrentMembership();

		const [post, setPost] = useState(initialPost);
		const [likedPost, setLikedPost] = useState(initialPost.likedByCurrentUser);
		const [editedPost, setEditedPost] =
			useState<models.groupPost.GroupPost>(null);
		const [editing, setEditing] = useState(false);
		const [discussionIsVisible, setDiscussionIsVisible] = useState(
			initialPost.commentCount > 0
		);

		const postAuthor = post.author;
		const authorGroup = post.authorGroup;

		const likeUsers = post.likes?.sort((a, b) =>
			models.user.fullName(a).localeCompare(models.user.fullName(b))
		);

		const viewedUsers = post.usersViewed?.sort((a, b) =>
			models.user.fullName(a).localeCompare(models.user.fullName(b))
		);

		const [showLikesModal, setShowLikesModal] = useState(false);
		const [showSeenByModal, setSeenByModal] = useState(false);

		useEffect(() => {
			if (discussionIsVisible && post.commentCount === 0) {
				document
					.querySelector<HTMLElement>(`#post-comment-form-${post.id}`)
					.focus();
			}
		}, [discussionIsVisible]);

		useEffect(() => {
			setPost(initialPost);
		}, [initialPost?.id]);

		const toggleLikesModal = () => setShowLikesModal(!showLikesModal);
		const toggleSeenByModal = () => setSeenByModal(!showSeenByModal);
		const togglePostModal = () => setEditing(!editing);

		const onCommentTrigger = () => {
			if (!discussionIsVisible) {
				setDiscussionIsVisible(true);
			}
		};

		const handleDeleteClick = useConfirm({
			message: t('Do you want to delete this?', {
				_context: 'confirm messages',
			}),
			onConfirm: async () => {
				await actions.posts.deleteGroupPost(post.id);
				replaceState('/');
				return true;
			},
		});

		const emitHapticFeedback = useHapticFeedback();

		const handleToggleLike = async () => {
			setLikedPost(!likedPost);

			setPost((prevPost) => {
				const updatedLikes = likedPost
					? prevPost.likes.filter((like) => like.id !== user.id)
					: [
							...prevPost.likes,
							{
								id: user.id,
								firstName: user.firstName,
								lastName: user.lastName,
							},
						];

				return {
					...prevPost,
					likes: updatedLikes,
					likedByCurrentUser: !likedPost,
				} as models.groupPost.GroupPost;
			});

			emitHapticFeedback(HapticFeedbackImpact.Medium);

			await actions.posts.toggleLike(user.id, likedPost, post);
		};

		const markAsSeen = (event: IntersectionObserverEntry) => {
			if (post.viewedByCurrentUser) {
				return;
			}
			if (event.isIntersecting) {
				actions.posts.markAsSeen(user.id, post.id);
			}
		};

		const handleEdit = (post: models.groupPost.GroupPost) => {
			setEditedPost(post);
		};

		if (!post.id) {
			return (
				<Skeleton placeholderType={PlaceholderType.FeedItem} numItems={1} />
			);
		}

		let title = post.title;
		let content = post.content;
		let attachments = post.attachments;
		if (editedPost) {
			title = editedPost.title;
			content = editedPost.content;
			attachments = editedPost.attachments;
		}

		return (
			<Fragment>
				{!post.viewedByCurrentUser && <Trigger onTrigger={markAsSeen} />}

				<CardWrapper $noBorder>
					<ContextMenu.Menu toggleClassName={css.toggle}>
						{post.links?.edit && (
							<ContextMenu.Item onClick={togglePostModal}>
								<ContextMenu.ItemIcon name="edit" /> {t('Edit')}
							</ContextMenu.Item>
						)}

						{post.links?.delete && (
							<ContextMenu.Item caution onClick={handleDeleteClick}>
								<ContextMenu.ItemIcon name="delete" />
								{t('Delete')}
							</ContextMenu.Item>
						)}

						<ContextMenu.ReportItem
							contentType="GroupPost"
							contentId={post.id}
						/>
					</ContextMenu.Menu>
					<div className={css.groupName}>{post.group.name}</div>
					{post.isSticky && (
						<Card.Header $slim>
							<div className={css.cardInlineLabeledHeading}>
								<Icon name="pin" className={css.stickyLabelIcon} />
								{t('Sticky post')}
							</div>
						</Card.Header>
					)}
					<Card.Body className={css.itemBody}>
						<div className={css.authorWrapper}>
							{post.authorGroupId ? (
								<React.Fragment>
									<div className={css.avatarWrapper}>
										<Badge badgeUrl={authorGroup.profileImageUrl} />
									</div>
									<div className={css.authorName}>{authorGroup.name}</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									<div className={css.avatarWrapper}>
										<Avatar user={postAuthor} />
									</div>

									<div className={css.authorName}>
										{models.user.fullName(postAuthor)}
									</div>
								</React.Fragment>
							)}
							{models.groupPost.isDraft(post) ? (
								<span className={css.authorTimestamp}>
									{t('Not published yet')}
								</span>
							) : (
								<Link
									className={css.authorTimestamp}
									href={routes.Group.Post.Show(org.id, post.id)}>
									<RelativeDateTime dateTime={post.publishedAt} />
								</Link>
							)}
						</div>

						{title ? <Card.SubHeading>{title}</Card.SubHeading> : null}

						<FormattedContent
							key={`formated-content-${post.id}`}
							raw={content}
							maxLength={!showFullPost && FormattedContentMaxLength.Standard}
						/>
					</Card.Body>

					<GalleryPreview attachments={attachments} />
					<Card.Divider />
					<Impressions
						commentCount={post.commentCount}
						likeCount={post.likes?.length}
						likeUsers={likeUsers}
						showLikes={toggleLikesModal}
						showSeenBy={toggleSeenByModal}
						userViews={viewedUsers}
					/>
					<Item.MetaWrapper>
						<Item.MetaAction onClick={handleToggleLike} $active={likedPost}>
							<Icon name="thumbs-up" />
							{t('Like')}
						</Item.MetaAction>

						{!post.commentsDisabled && !hideComments && (
							<Item.MetaAction onClick={onCommentTrigger}>
								<Icon name="comment" />
								{t('Comment')}
							</Item.MetaAction>
						)}
					</Item.MetaWrapper>
					{(post.commentCount > 0 || discussionIsVisible) && !hideComments && (
						<PostComments post={post} comments={post.comments} />
					)}
				</CardWrapper>

				{editing && (
					<PostForm
						isAdminOrStaffForGroup={models.membership.isAdminOrStaff(
							activeMembership
						)}
						id={post.id}
						onClose={togglePostModal}
						onSave={handleEdit}
					/>
				)}

				{showLikesModal && (
					<UsersModal
						title={t('Likes')}
						users={likeUsers}
						onClose={toggleLikesModal}
					/>
				)}

				{showSeenByModal && (
					<UsersModal
						title={t('Seen by')}
						users={viewedUsers}
						onClose={toggleSeenByModal}
					/>
				)}
			</Fragment>
		);
	}
);

export default GroupPost;
