import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { findCountryByCode } from 'pkg/countries';
import Link from 'pkg/router/Link';
import copyString from 'pkg/copyString';
import { flashes } from 'pkg/actions';
import { EndpointResponse } from 'pkg/api/use_endpoint';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';

import RefundModal from 'routes/payments/orders/single/refund_modal';
import OrderActions from 'routes/payments/orders/context_menu_actions';

import Icon from 'components/icon';

import * as SideBarInfo from 'components/sidebar/Info';
import SideBarIcon from 'components/sidebar/Icon';
import * as SideBarLayout from 'components/layout/SideBarPage';
import Column from 'components/layout/column';
import StatusLabel from 'components/payment_platform/status/label';
import * as SideBarList from 'components/sidebar/List';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface SideBarProps {
	order: models.order.Order;
	group: models.group.Group;

	orderEndpoint: EndpointResponse<models.order.Order>;
}

const SideBar: React.FC<React.PropsWithChildren<SideBarProps>> = ({
	order,
	group,
	orderEndpoint,
}) => {
	const org = useCurrentOrganization();
	const [refundModal, setRefundModal] = useState(false);

	const price = formatToCurrency(order.amountDue / 100, group.currency);

	const dueDate = DateTime.fromTimestamp(order.dueDate);
	const createdAt = DateTime.fromTimestamp(order.createdAt);
	const sentAt = DateTime.fromTimestamp(order.sentAt);
	const terms = models.order.getPaymentTerms(order);

	const handleCopyPaymentUrl = () => {
		copyString(order.paymentUrl);

		flashes.show({
			title: t('Link copied to clipboard!'),
		});
	};

	const hideModal = () => setRefundModal(false);
	const showRefundModal = () => setRefundModal(true);

	const removeOrder = async () => {
		pushState(routes.Payments.Orders(org.id));
	};

	return (
		<Fragment>
			<SideBarLayout.SideBar>
				<SideBarLayout.SideBarBody>
					<SideBarInfo.CenterColumn>
						<SideBarIcon name="order" />
						<div className={css.orderId}>
							{order.subscriptionId && (
								<Icon className={css.subscriptionIcon} name="sync" />
							)}
							{order.invoiceNumber}
						</div>
						<div className={css.priceWrapper}>
							<span className={css.price}>{price}</span>
							<StatusLabel
								status={models.order.getStatus(order)}
								jobStatus={order.jobStatus}
							/>
						</div>
						{!!order.dueDate && (
							<span className={css.dateString}>
								{t('Due {date}', {
									date: dueDate.toLocaleDateString({}),
								})}
							</span>
						)}
						<ContextMenu.Menu
							toggleWith={
								<Button primary block hasIcon testid="order.single.actions">
									{t('Actions')}
									<Icon name="chevron" rotate="90deg" />
								</Button>
							}>
							<OrderActions
								order={order}
								showRefundModal={showRefundModal}
								replaceRecord={orderEndpoint.replaceRecord}
								removeRecord={removeOrder}
							/>
						</ContextMenu.Menu>
					</SideBarInfo.CenterColumn>
				</SideBarLayout.SideBarBody>
				<ContextMenu.Divider />
				<SideBarLayout.SideBarBody>
					<Column spacing={styles.spacing._8}>
						{order.subscriptionId && (
							<div>
								<Column spacing={styles.spacing._4}>
									<SideBarList.Header>
										<SideBarList.Title>{t('Subscription')}</SideBarList.Title>
									</SideBarList.Header>
									<Button
										secondary
										block
										icon="chevron_right"
										iconPosition="right"
										href={routes.Subscription.Show(
											org.id,
											order.subscriptionId,
											'overview'
										)}>
										{t('View subscription')}
									</Button>
								</Column>
							</div>
						)}
						<div>
							<Column spacing={styles.spacing._4}>
								<SideBarList.Header>
									<SideBarList.Title>{t('Invoice details')}</SideBarList.Title>
								</SideBarList.Header>
								{!!order.paymentUrl && (
									<SideBarList.Row>
										<SideBarList.Name>
											{t('Payment link')}{' '}
											<Link
												className={css.copyLink}
												onClick={handleCopyPaymentUrl}>
												<Icon name="collection" />
												<span>{t('Copy')}</span>
											</Link>
										</SideBarList.Name>

										<Link href={order.paymentUrl}>{order.paymentUrl}</Link>
									</SideBarList.Row>
								)}
								{!!order.sentAt && (
									<SideBarList.Row>
										<SideBarList.Name>{t('Sent')}</SideBarList.Name>
										<span>
											{sentAt.toLocaleDateString({
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
												hour: 'numeric',
												minute: 'numeric',
											})}
										</span>
									</SideBarList.Row>
								)}
								{!!order.createdAt && (
									<SideBarList.Row>
										<SideBarList.Name>{t('Created')}</SideBarList.Name>
										<span>
											{createdAt.toLocaleDateString({
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
												hour: 'numeric',
												minute: 'numeric',
											})}
										</span>
									</SideBarList.Row>
								)}
								{!!order.dueDate && (
									<SideBarList.Row>
										<SideBarList.Name>{t('Payments terms')}</SideBarList.Name>
										<span>
											{terms} {t('days')}
										</span>
									</SideBarList.Row>
								)}

								{!!order.paymentProviderId && (
									<SideBarList.Row>
										<SideBarList.Name>{t('Invoice ID')}</SideBarList.Name>
										<span>{order.paymentProviderId}</span>
									</SideBarList.Row>
								)}
								{order.subscription && (
									<SideBarList.Row>
										<SideBarList.Name>
											{t('Collection method')}
										</SideBarList.Name>
										<span>
											{models.subscription.getCollectionMethodString(
												order.subscription,
												order.id
											)}
										</span>
									</SideBarList.Row>
								)}
								{order.type && (
									<SideBarList.Row>
										<SideBarList.Name>{t('Order type')}</SideBarList.Name>
										<span>{models.order.getTypeString(order)}</span>
									</SideBarList.Row>
								)}
							</Column>
						</div>

						<div>
							<Column spacing={styles.spacing._4}>
								<SideBarList.Header>
									<SideBarList.Title>{t('Billing info')}</SideBarList.Title>
								</SideBarList.Header>
								<SideBarList.Row>
									<SideBarList.Name>{t('First name')}</SideBarList.Name>
									<span>{order.firstName}</span>
								</SideBarList.Row>
								<SideBarList.Row>
									<SideBarList.Name>{t('Last name')}</SideBarList.Name>
									<span>{order.lastName}</span>
								</SideBarList.Row>
								<SideBarList.Row>
									<SideBarList.Name>{t('Email')}</SideBarList.Name>
									<span>
										<a href={`mailto:${order.email}`}>{order.email}</a>
									</span>
								</SideBarList.Row>
								<SideBarList.Row>
									<SideBarList.Name>{t('Company name')}</SideBarList.Name>
									<span>{order.companyName}</span>
								</SideBarList.Row>
								<SideBarList.Row>
									<SideBarList.Name>{t('Phone number')}</SideBarList.Name>
									<span>
										<a href={`tel:${order.phoneNumber}`}>{order.phoneNumber}</a>
									</span>
								</SideBarList.Row>
								<SideBarList.Row>
									<SideBarList.Name>{t('Address')}</SideBarList.Name>
									<span>{order.address}</span>
								</SideBarList.Row>
								<SideBarList.Row>
									<SideBarList.Name>{t('City')}</SideBarList.Name>
									<span>{order.city}</span>
								</SideBarList.Row>
								<SideBarList.Row>
									<SideBarList.Name>{t('Country')}</SideBarList.Name>
									<span>
										{order.country?.length === 2
											? findCountryByCode(order.country).country
											: order.country}
									</span>
								</SideBarList.Row>
								<SideBarList.Row>
									<SideBarList.Name>{t('ZIP code')}</SideBarList.Name>
									<span>{order.postalCode}</span>
								</SideBarList.Row>
							</Column>
						</div>
					</Column>
				</SideBarLayout.SideBarBody>
			</SideBarLayout.SideBar>
			{refundModal && (
				<RefundModal
					order={order}
					hideModal={hideModal}
					currency={group.currency}
				/>
			)}
		</Fragment>
	);
};

export default SideBar;
