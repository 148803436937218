export function Create(): string {
	return `/v1/form-categories`;
}

export function Delete(ID: number): string {
	return `/v1/form-categories/${ID}`;
}

export function Index(): string {
	return `/v1/form-categories`;
}

export function Update(ID: number): string {
	return `/v1/form-categories/${ID}`;
}
