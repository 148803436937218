import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import { t } from '@transifex/native';

import Session from 'pkg/models/session';

import { Sessions, views } from 'pkg/actions/action-types';
import { triggerError } from 'pkg/actions/app';
import {
	deleteSessionBlocksBySessionId,
	actionSetSessionBlockMode,
} from 'pkg/actions/session_blocks';
import {
	deleteSessionItemsBySessionId,
	actionSetSessionItemMode,
} from 'pkg/actions/session_items';
import * as service from 'pkg/actions/services/sessions';
import { normalizedDispatch } from 'pkg/actions/utils';
import { show } from 'pkg/actions/flashes';

import { setConfirmNavigation } from 'pkg/router/utils';
import * as sdk from 'pkg/core/sdk';
import { RootState } from 'pkg/reducers';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

const actionDeleteSession = (sessionId: number) => ({
	type: Sessions.DELETE_ITEM,
	sessionId,
});

interface IActionUnsetSessionMode {
	type: string;
	sessionId: number;
}

interface IActionSetSessionMode extends IActionUnsetSessionMode {
	payload?: {
		isNew?: string;
	};
}

export const actionSetSessionMode = (
	sessionId: number,
	payload = {}
): IActionSetSessionMode => ({
	type: Sessions.SET_MODE,
	sessionId,
	payload,
});

const actionUnsetSessionMode = (sessionId: number) => ({
	type: Sessions.UNSET_MODE,
	sessionId,
});

/* Actions */
export const fetchSession =
	(organizationId: number, sessionId: number) =>
	async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
		const sessionFromState = getState().sessions.getIn(['entities', sessionId]);

		if (sessionFromState) {
			return;
		}

		const request = await service.fetchSession(organizationId, sessionId);

		if (!request.ok) {
			triggerError(request)(dispatch);
			return;
		}

		const result = await request.json();

		normalizedDispatch(result, Session.normalizr())(dispatch);
		dispatch(actionSetSessionMode(sessionId));
	};

export const createSession =
	(organizationId: number, payload: Session) =>
	async (dispatch: Dispatch): Promise<Session> => {
		let result = payload;
		let isNew = true;

		if (!payload.id) {
			const request = await service.createSession(organizationId, payload);

			if (!request.ok) {
				triggerError(request)(dispatch);
			} else {
				show({
					title: t(`Saved!`),
					message: !payload.eventId
						? t(`Session was saved, find it in your library.`)
						: t(`Session was saved, find it in the calendar.`),
				});

				result = await request.json();
			}
			isNew = false;
		}

		const normalizedData = normalizedDispatch(
			result,
			Session.normalizr()
		)(dispatch);

		batch(() => {
			setSessionModeAsNew(payload.id)(dispatch);
			dispatch({
				type: views.trainingLibrary.SET_SESSION_RESULT,
				payload: [normalizedData.result],
			});
			result.blocks?.forEach((b) => {
				dispatch(
					actionSetSessionBlockMode(b.id, {
						isNew,
						hasChanges: false,
					})
				);
				b.items?.forEach((i) => {
					dispatch(actionSetSessionItemMode(i.id, { isNew }));
				});
			});
		});

		return result;
	};

export const updateSession =
	(
		organizationId: number,
		sessionId: number,
		payload: service.UpdateSession,
		persist = false
	) =>
	async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
		const sessionExists =
			getState().sessions.getIn(['modes', sessionId, 'isNew']) === false;

		const session = getState().sessions.getIn(['entities', sessionId]);

		payload = Object.assign(
			{},
			{
				title: session.title,
				description: session.description,
				playerVisible: session.playerVisible,
			},
			payload,
			{
				id: sessionId,
			}
		);

		let result = payload;

		if (sessionExists && persist) {
			const request = await service.updateSession(
				organizationId,
				sessionId,
				payload
			);

			if (!request.ok) {
				triggerError(request)(dispatch);
				return;
			}

			show({
				title: t(`Saved!`),
				message: !session.eventId
					? t(`Session was saved, find it in your library.`)
					: t(`Session was saved, find it in the calendar.`),
			});

			result = await request.json();
		} else {
			result = {
				...session.toJS(),
				...payload,
			};
		}

		normalizedDispatch(result, Session.normalizr())(dispatch);
		setSessionModeHasChanges(sessionId)(dispatch);
	};

export const deleteSession =
	(organizationId: number, sessionId: number) =>
	async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
		const sessionExists =
			getState().sessions.getIn(['modes', sessionId, 'isNew']) === false;

		if (sessionExists) {
			const request = await service.deleteSession(organizationId, sessionId);

			if (!request.ok) {
				triggerError(request)(dispatch);
				return;
			}
		}

		flushSession(sessionId)(dispatch);
	};

export const deleteSessionTag =
	(organizationId: number, sessionId: number, tagId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const request = await service.deleteSessionTag(
			organizationId,
			sessionId,
			tagId
		);

		if (!request.ok) {
			triggerError(request)(dispatch);
			return;
		}
	};

export const setSessionModeAsNew =
	(sessionId: number) =>
	async (dispatch: Dispatch): Promise<IActionSetSessionMode> =>
		dispatch(
			actionSetSessionMode(sessionId, {
				isNew: true,
			})
		);

export const setSessionModeHasChanges =
	(sessionId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		setConfirmNavigation(
			t(
				`You are about to leave the Session Builder, unsaved changes will be discarded.`
			)
		);

		dispatch(
			actionSetSessionMode(sessionId, {
				hasChanges: true,
			})
		);
	};

export const flushSession =
	(sessionId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		batch(() => {
			deleteSessionItemsBySessionId(sessionId)(dispatch);
			deleteSessionBlocksBySessionId(sessionId)(dispatch);
			dispatch(actionUnsetSessionMode(sessionId));
			dispatch(actionDeleteSession(sessionId));
		});
	};

interface DuplicatePayload {
	title?: string;
}

export const duplicate = async (
	organizationId: number,
	sessionId: number,
	payload: DuplicatePayload = {}
): Promise<[boolean, models.session.Session]> => {
	const request = await sdk.post(
		endpoints.TrainingSessions.Duplicate(organizationId, sessionId),
		{},
		payload
	);

	if (!request.ok) {
		show({
			title: t('Something went wrong'),
			message: t(`There was an issue duplicating your session.`),
		});
		return [false, null];
	}

	const response = await request.json();

	show({
		title: t('Successfully duplicated your session'),
	});

	return [true, response];
};
