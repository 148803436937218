import { t } from '@transifex/native';

import { ContactsWithMissingInformationData } from 'pkg/api/dashboards/management';
import { pushState } from 'pkg/router/state';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import * as routes from 'pkg/router/routes';
import { FilterOperator } from 'pkg/filters';
import { useCurrentOrganization } from 'pkg/identity';

import * as DataCard from 'design/data_card';

interface ContactsWithMissingInformationWidgetProps {
	data: ContactsWithMissingInformationData;
}

export default function ContactsWithMissingInformationWidget({
	data,
}: ContactsWithMissingInformationWidgetProps) {
	const org = useCurrentOrganization();

	const handleActiveClick = () =>
		pushState(
			toFilterQueryUrl(routes.Management.Contacts.Home(org.id), {
				required_information: {
					operator: FilterOperator.Equals,
					values: ['true'],
				},
				user_group_roles: {
					operator: FilterOperator.Includes,
					values: ['user'],
				},
			})
		);

	const handleArchivedClick = () =>
		pushState(
			toFilterQueryUrl(routes.Management.Contacts.Home(org.id), {
				required_information: {
					operator: FilterOperator.Equals,
					values: ['true'],
				},
				archived: {
					operator: FilterOperator.Set,
					values: ['archived'],
				},
				user_group_roles: {
					operator: FilterOperator.Includes,
					values: ['user'],
				},
			})
		);

	const breakdownItems: DataCard.BreakdownItem[] = [
		{
			title: t('Active'),
			value: data.totalCount.toString(),
			onClick: handleActiveClick,
		},
		{
			title: t('Archived'),
			value: data.archivedCount.toString(),
			onClick: handleArchivedClick,
		},
	];

	return (
		<DataCard.Base
			title={t('Players with incomplete required information')}
			titleIcon="info"
			heading={(data.totalCount + data.archivedCount).toString()}
			headingSuffix={t('Total amount')}>
			<DataCard.Breakdown items={breakdownItems} />
		</DataCard.Base>
	);
}
