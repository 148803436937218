import { t } from '@transifex/native';

import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';
import { FormSubmission } from 'pkg/api/models/form_submission';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';

export enum Visibilities {
	Unlisted = 'unlisted',
	Public = 'public',
	Organization = 'organization',
}

export enum Statuses {
	Draft = 'draft',
	Open = 'open',
	Closed = 'closed',
}

export enum FieldTypes {
	ShortText = 'string',
	LongText = 'long_text',
	Number = 'number',
	Email = 'email',
	Phone = 'phone',
	Choice = 'choice',
	MultiChoice = 'multi_choice',
	Date = 'date',
	Country = 'country',
}

export interface FieldValue {
	value: string;
	label: string;
}

export interface Field extends Dateable {
	key: string;
	type: FieldTypes;
	label: string;
	description?: string;
	values?: FieldValue[];
	sortOrder?: number;
	required?: boolean;
	deletedAt?: number;
	userFieldId?: number;
}

export interface FormProductPrice {
	productPriceId: number;
	taxRateId?: number;

	productPrice?: models.productPrice.ProductPrice;
	taxRate?: models.taxRate.TaxRate;

	minQuantity: number;
	maxQuantity?: number;
	required?: boolean;
	validTo?: number;

	installmentCount?: number;
	bundleNumber?: number;

	subscriptionStartAt?: number;
	payFirstOrderNow?: boolean;
}

export interface Form extends Linkable, Record, Dateable {
	id: number;
	guid?: string;
	groupId: number;
	formCategoryId: number;
	title: string;
	description: string;
	maxSubmissions: number;
	attachmentId: number;
	publicUrl?: string;
	submissionCount: number;
	deletedAt: number;

	submissionEmailEnabled: boolean;
	submissionEmailSubject?: string;
	submissionEmailContent?: string;
	submissions: FormSubmission[];

	visibility: Visibilities;
	status: Statuses;
	attachment: models.attachment.Attachment;
	forceAuth?: boolean;

	fields: Field[];
	productPrices: FormProductPrice[];

	formCategory: models.formCategory.FormCategory;
	group?: models.group.Group;
}

export function getFormatedChoices(
	data: string,
	fieldValues: FieldValue[]
): string {
	const choosenValues = data.split(',');

	return choosenValues
		.map((choosenValue) => {
			const val = fieldValues.find(({ value }) => choosenValue === value);

			if (!val) {
				return '';
			}

			return val.label;
		})
		.join(', ');
}

export function getTranslatedType(type: string): string {
	switch (type) {
		case FieldTypes.ShortText:
			return t('Short answer');
		case FieldTypes.LongText:
			return t('Long answer');
		case FieldTypes.Email:
			return t('Email');
		case FieldTypes.Number:
			return t('Number');
		case FieldTypes.Phone:
			return t('Phone number');
		case FieldTypes.Choice:
			return t('Single choice');
		case FieldTypes.MultiChoice:
			return t('Multple choices');
		case FieldTypes.Date:
			return t('Date');
		case FieldTypes.Country:
			return t('Country');
		default:
			return type;
	}
}

export function getTranslatedStatus(status: Statuses): string {
	switch (status) {
		case Statuses.Open:
			return t('Open');
		case Statuses.Draft:
			return t('Draft');
		case Statuses.Closed:
			return t('Closed');
		default:
			return status;
	}
}

export const emptyForm: Form = {
	id: 0,
	groupId: 0,
	formCategoryId: 0,
	title: '',
	description: '',
	publicUrl: '',
	visibility: Visibilities.Unlisted,
	status: Statuses.Draft,
	maxSubmissions: 0,
	attachmentId: 0,
	fields: [],
	productPrices: [],
	attachment: {} as models.attachment.Attachment,
	submissionEmailEnabled: true,
	submissionEmailSubject: '',
	submissionEmailContent: '',
	submissionCount: 0,
	submissions: [],
	deletedAt: null,
	formCategory: null,
	forceAuth: true,
};
