import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { formatPriceIntervalToText } from 'pkg/utils';
import * as actions from 'pkg/actions';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';
import RelativeDateTime from 'components/RelativeDateTime';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

interface ListItemProps {
	product: models.product.Product;
	category?: string;
	refresh: () => Promise<void>;
}

const ListItem = ({ product, refresh }: ListItemProps): JSX.Element => {
	const org = useCurrentOrganization();
	const activePrices = models.product.getActiveProductPrices(product);

	const handleArchive = async () => {
		await actions.products.update(product.id, { archived: true });
		refresh();
	};

	const handleRestore = async () => {
		await actions.products.update(product.id, { archived: false });
		refresh();
	};
	const price: models.productPrice.ProductPrice =
		activePrices?.length > 0 && activePrices[0];

	let columnContent;

	if (activePrices && activePrices.length > 1) {
		columnContent = (
			<Fragment>
				{activePrices?.length} {t('prices')}
			</Fragment>
		);
	} else if (price) {
		const formattedPrice = formatToCurrency(price.cost / 100, price.currency);

		const interval = formatPriceIntervalToText(
			price.recurringInterval,
			price.recurringIntervalCount
		);

		columnContent = (
			<Fragment>
				<div>
					{formattedPrice}, {interval}
				</div>
			</Fragment>
		);
	} else {
		columnContent = <span>{t('No active prices')}</span>;
	}

	return (
		<Table.Row>
			<Table.LinkCell
				linkStyle
				href={routes.Product.Show(org.id, product.id, 'overview')}>
				{product.name}
			</Table.LinkCell>
			<Table.LinkCell
				href={routes.Product.Show(org.id, product.id, 'overview')}>
				{product.category ? product.category.name : 'None'}
			</Table.LinkCell>
			<Table.LinkCell
				href={routes.Product.Show(org.id, product.id, 'overview')}>
				{columnContent}
			</Table.LinkCell>
			<Table.LinkCell
				href={routes.Product.Show(org.id, product.id, 'overview')}>
				<RelativeDateTime dateTime={product.createdAt} />
			</Table.LinkCell>
			<Table.ActionCell>
				<ContextMenu.Menu
					toggleWith={
						<ContextMenu.TableTrigger>
							<Icon name="context-menu" />
						</ContextMenu.TableTrigger>
					}>
					<ContextMenu.LinkItem
						href={routes.Product.Show(org.id, product.id, 'overview')}
						icon="arrow_forward">
						{t('View')}
					</ContextMenu.LinkItem>

					{!product.archivedAt ? (
						<Fragment>
							<ContextMenu.LinkItem
								href={routes.Product.Edit(org.id, product.id)}
								icon="edit">
								{t('Edit')}
							</ContextMenu.LinkItem>
							<ContextMenu.ConfirmItem
								confirmLabel={t('Archive')}
								caution
								message={t('Are you sure you want to archive this product?')}
								onConfirm={handleArchive}
								icon="archive">
								{t('Archive')}
							</ContextMenu.ConfirmItem>
						</Fragment>
					) : (
						<ContextMenu.ConfirmItem
							message={t('Are you sure you want to restore this product?')}
							confirmLabel={t('Restore')}
							onConfirm={handleRestore}
							icon="add">
							{t('Restore product')}
						</ContextMenu.ConfirmItem>
					)}
				</ContextMenu.Menu>
			</Table.ActionCell>
		</Table.Row>
	);
};

export default ListItem;
