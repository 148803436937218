// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AwiuF4HdQJj1DX2RoZGk {
	display: grid;
	grid-auto-rows: auto;
	justify-items: center;
	grid-gap: var(--spacing-2);
	gap: var(--spacing-2);
}

.AwiuF4HdQJj1DX2RoZGk span {
		display: block;
		margin-top: var(--spacing-5);
		font-size: var(--font-size-base);
		color: var(--palette-gray-500);
	}

@media (max-width: 768px) {

	.AwiuF4HdQJj1DX2RoZGk span {
			font-size: var(--font-size-sm);
	}
		}

.AwiuF4HdQJj1DX2RoZGk strong {
		font-size: var(--font-size-lg);
		font-weight: var(--font-weight-semibold);
		color: var(--palette-white);
	}

@media (max-width: 768px) {

	.AwiuF4HdQJj1DX2RoZGk strong {
			font-size: var(--font-size-base);
	}
		}

.AwiuF4HdQJj1DX2RoZGk strong a {
			display: inline-block;
			margin-left: var(--spacing-2);
			color: var(--palette-white);
			font-size: var(--font-size-sm);
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			font-weight: var(--font-weight-normal);
		}

@media (max-width: 768px) {

		.AwiuF4HdQJj1DX2RoZGk strong a {
				font-size: var(--font-size-xs);
		}
			}

@media (hover: hover) {
				.AwiuF4HdQJj1DX2RoZGk strong a:hover {
					color: rgba(255, 255, 255, 0.5);
					border-bottom: 1px solid rgba(255, 255, 255, 0.3);
				}
			}

.AwiuF4HdQJj1DX2RoZGk em {
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
	}

@media (max-width: 768px) {

	.AwiuF4HdQJj1DX2RoZGk em {
			font-size: var(--font-size-xs);
	}
		}
`, "",{"version":3,"sources":["webpack://./routes/public/last-login/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,oBAAoB;CACpB,qBAAqB;CACrB,0BAAqB;CAArB,qBAAqB;AAmDtB;;AAjDC;EACC,cAAc;EACd,4BAA4B;EAC5B,gCAAgC;EAChC,8BAA8B;CAK/B;;AAHC;;CAND;GAOE,8BAA8B;CAEhC;EADC;;AAGD;EACC,8BAA8B;EAC9B,wCAAwC;EACxC,2BAA2B;CAyB5B;;AAvBC;;CALD;GAME,gCAAgC;CAsBlC;EArBC;;AAEA;GACC,qBAAqB;GACrB,6BAA6B;GAC7B,2BAA2B;GAC3B,8BAA8B;GAC9B,iDAA+C;GAC/C,sCAAsC;EAYvC;;AAVC;;EARD;IASE,8BAA8B;EAShC;GARC;;AAEA;IACC;KACC,+BAA6B;KAC7B,iDAA+C;IAChD;GACD;;AAIF;EACC,8BAA8B;EAC9B,8BAA8B;CAK/B;;AAHC;;CAJD;GAKE,8BAA8B;CAEhC;EADC","sourcesContent":[".accountWrapper {\n\tdisplay: grid;\n\tgrid-auto-rows: auto;\n\tjustify-items: center;\n\tgap: var(--spacing-2);\n\n\tspan {\n\t\tdisplay: block;\n\t\tmargin-top: var(--spacing-5);\n\t\tfont-size: var(--font-size-base);\n\t\tcolor: var(--palette-gray-500);\n\n\t\t@media (--small-viewport) {\n\t\t\tfont-size: var(--font-size-sm);\n\t\t}\n\t}\n\n\tstrong {\n\t\tfont-size: var(--font-size-lg);\n\t\tfont-weight: var(--font-weight-semibold);\n\t\tcolor: var(--palette-white);\n\n\t\t@media (--small-viewport) {\n\t\t\tfont-size: var(--font-size-base);\n\t\t}\n\n\t\ta {\n\t\t\tdisplay: inline-block;\n\t\t\tmargin-left: var(--spacing-2);\n\t\t\tcolor: var(--palette-white);\n\t\t\tfont-size: var(--font-size-sm);\n\t\t\tborder-bottom: 1px solid rgb(255 255 255 / 50%);\n\t\t\tfont-weight: var(--font-weight-normal);\n\n\t\t\t@media (--small-viewport) {\n\t\t\t\tfont-size: var(--font-size-xs);\n\t\t\t}\n\n\t\t\t@media (hover: hover) {\n\t\t\t\t&:hover {\n\t\t\t\t\tcolor: rgb(255 255 255 / 50%);\n\t\t\t\t\tborder-bottom: 1px solid rgb(255 255 255 / 30%);\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n\n\tem {\n\t\tfont-size: var(--font-size-sm);\n\t\tcolor: var(--palette-gray-500);\n\n\t\t@media (--small-viewport) {\n\t\t\tfont-size: var(--font-size-xs);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var accountWrapper = `AwiuF4HdQJj1DX2RoZGk`;
export default ___CSS_LOADER_EXPORT___;
