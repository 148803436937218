// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DqJtuZvA0lvu04kfCJiT {
	width: 100%;
	cursor: pointer;
}

.KQoN4qkh_6fa3v_lA5U6 {
	width: 100%;
	font-weight: var(--font-weight-semibold);
	text-align: center;
	display: block;
}

.QursHdQEBrnyP40djknk {
	padding: var(--spacing-4);
	border-bottom: 1px solid var(--palette-gray-300);
	text-align: center;
}

.AAVRHyK2mCZ9WqgXXxpd {
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-sm);
	color: var(--palette-blue-500);
	cursor: pointer;
}

@media (hover: hover) {
		.AAVRHyK2mCZ9WqgXXxpd:hover {
			color: var(--palette-blue-400);
		}
	}
`, "",{"version":3,"sources":["webpack://./components/group/use-select-table/styles.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,eAAe;AAChB;;AAEA;CACC,WAAW;CACX,wCAAwC;CACxC,kBAAkB;CAClB,cAAc;AACf;;AAEA;CACC,yBAAyB;CACzB,gDAAgD;CAChD,kBAAkB;AACnB;;AAEA;CACC,wCAAwC;CACxC,8BAA8B;CAC9B,8BAA8B;CAC9B,eAAe;AAOhB;;AALC;EACC;GACC,8BAA8B;EAC/B;CACD","sourcesContent":[".row {\n\twidth: 100%;\n\tcursor: pointer;\n}\n\n.cell {\n\twidth: 100%;\n\tfont-weight: var(--font-weight-semibold);\n\ttext-align: center;\n\tdisplay: block;\n}\n\n.header {\n\tpadding: var(--spacing-4);\n\tborder-bottom: 1px solid var(--palette-gray-300);\n\ttext-align: center;\n}\n\n.deselect {\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-blue-500);\n\tcursor: pointer;\n\n\t@media (hover: hover) {\n\t\t&:hover {\n\t\t\tcolor: var(--palette-blue-400);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var row = `DqJtuZvA0lvu04kfCJiT`;
export var cell = `KQoN4qkh_6fa3v_lA5U6`;
export var header = `QursHdQEBrnyP40djknk`;
export var deselect = `AAVRHyK2mCZ9WqgXXxpd`;
export default ___CSS_LOADER_EXPORT___;
