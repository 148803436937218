import { t } from '@transifex/native';
import { Fragment } from 'react';

import { palette } from 'pkg/config/styles';

import { ContactsCountData } from 'pkg/api/dashboards/management';
import { precise } from 'pkg/numbers';
import * as routes from 'pkg/router/routes';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import { FilterOperator } from 'pkg/filters';
import { pushState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';

import * as Chart from 'design/chart';
import * as DataCard from 'design/data_card';

interface ContactsCountWidgetProps {
	data: ContactsCountData;
}

export default function ContactsCountWidget({
	data,
}: ContactsCountWidgetProps): JSX.Element {
	const org = useCurrentOrganization();

	const makeRoleFilterUrl = (role: string) => {
		if (role === 'unknown') {
			return toFilterQueryUrl(routes.Management.Contacts.Home(org.id), {
				user_group_roles: {
					operator: FilterOperator.Excludes,
					values: ['admin', 'staff', 'user', 'parent'],
				},
			});
		}

		return toFilterQueryUrl(routes.Management.Contacts.Home(org.id), {
			user_group_roles: { operator: FilterOperator.Includes, values: [role] },
		});
	};

	const breakdownItems: DataCard.BreakdownItem[] = [
		{
			title: t('Players'),
			value: data.playerCount.toString(),
			variant: 'label',
			color: 'green',
			diff: `${precise(data.playerRate, 2)}%`,
			onClick: () => pushState(makeRoleFilterUrl('user')),
		},
		{
			title: t('Staff'),
			value: data.staffCount.toString(),
			variant: 'label',
			color: 'orange',
			diff: `${precise(data.staffRate, 2)}%`,
			onClick: () => pushState(makeRoleFilterUrl('staff')),
		},
		{
			title: t('Admins'),
			value: data.adminCount.toString(),
			variant: 'label',
			color: 'blue',
			diff: `${precise(data.adminRate, 2)}%`,
			onClick: () => pushState(makeRoleFilterUrl('admin')),
		},
		{
			title: t('Parents'),
			value: data.parentCount.toString(),
			variant: 'label',
			color: 'purple',
			diff: `${precise(data.parentRate, 2)}%`,
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Management.Contacts.Home(org.id), {
						child_connection: {
							operator: FilterOperator.Equals,
							values: ['true'],
						},
					})
				),
		},
		{
			title: t('Club lobby'),
			value: data.unassignedCount.toString(),
			variant: 'label',
			color: 'gray',
			diff: `${precise(data.unassignedRate, 2)}%`,
			onClick: () => pushState(makeRoleFilterUrl('unknown')),
		},
	];

	const chartItems: Chart.ChartItem[] = [
		{
			title: t('Players'),
			value: data.playerCount,
			fillColor: palette.green[200],
		},
		{
			title: t('Staff'),
			value: data.staffCount,
			fillColor: palette.orange[200],
		},
		{
			title: t('Admins'),
			value: data.adminCount,
			fillColor: palette.blue[300],
		},
		{
			title: t('Parents'),
			value: data.parentCount,
			fillColor: palette.purple[300],
		},
		{
			title: t('Club lobby'),
			value: data.unassignedCount,
			fillColor: palette.gray[300],
		},
	];

	const formatLabel = (item: Chart.ChartItem) => {
		return `${precise((item.value / data.totalCount) * 100, 2)}%`;
	};

	const formatTooltip = ({ payload }: { [key: string]: any }) => {
		const p = precise((payload.value / data.totalCount) * 100, 2);

		return (
			<Fragment>
				<strong>{payload.title}</strong>
				<span>{`${payload.value} / ${data.totalCount} (${p}%)`}</span>
			</Fragment>
		);
	};

	return (
		<DataCard.Base
			title={t('Total amount of contacts')}
			titleIcon="groups_2"
			heading={data.totalCount.toString()}>
			<DataCard.Breakdown items={breakdownItems} />
			<Chart.Donut
				items={chartItems}
				hideLegend
				formatLabel={formatLabel}
				formatTooltip={formatTooltip}
			/>
		</DataCard.Base>
	);
}
