// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kxSdSBZ8ny63lzzhwbgQ {
	max-width: 470px;
}
`, "",{"version":3,"sources":["webpack://./routes/payments/products/form/shared.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB","sourcesContent":[".fieldWrapper {\n\tmax-width: 470px;\n}\n"],"sourceRoot":""}]);
// Exports
export var fieldWrapper = `kxSdSBZ8ny63lzzhwbgQ`;
export default ___CSS_LOADER_EXPORT___;
