// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b3_YbUxuJ_IxhlP7Fmyw {
	font-weight: var(--font-weight-semibold);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./routes/dashboard/organization/pending-requests/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAAwC;CACxC,mBAAmB;CACnB,gBAAgB;CAChB,uBAAuB;AACxB","sourcesContent":[".name {\n\tfont-weight: var(--font-weight-semibold);\n\twhite-space: nowrap;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export var name = `b3_YbUxuJ_IxhlP7Fmyw`;
export default ___CSS_LOADER_EXPORT___;
