// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fFjiJRsNuHxAQS1eSEr7 {
	display: grid;
	grid-auto-flow: row;
	grid-auto-rows: -webkit-min-content;
	grid-auto-rows: min-content;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: start;
	-ms-flex-line-pack: start;
	    align-content: start;
	gap: var(--spacing-4);
	grid-gap: var(--spacing-4);
	grid-gap: var(--spacing, var(--spacing-4));
	gap: var(--spacing, var(--spacing-4));
	justify-items: stretch;
	justify-items: var(--justify, stretch);
	padding: 0;
	padding: var(--padding, 0);
}
`, "",{"version":3,"sources":["webpack://./components/layout/column/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,mCAA2B;CAA3B,2BAA2B;CAC3B,wBAAkB;KAAlB,qBAAkB;SAAlB,kBAAkB;CAClB,yBAAoB;KAApB,oBAAoB;CACpB,qBAAqC;CAArC,0BAAqC;CAArC,0CAAqC;CAArC,qCAAqC;CACrC,sBAAsC;CAAtC,sCAAsC;CACtC,UAA0B;CAA1B,0BAA0B;AAC3B","sourcesContent":[".column {\n\tdisplay: grid;\n\tgrid-auto-flow: row;\n\tgrid-auto-rows: min-content;\n\talign-items: start;\n\talign-content: start;\n\tgap: var(--spacing, var(--spacing-4));\n\tjustify-items: var(--justify, stretch);\n\tpadding: var(--padding, 0);\n}\n"],"sourceRoot":""}]);
// Exports
export var column = `fFjiJRsNuHxAQS1eSEr7`;
export default ___CSS_LOADER_EXPORT___;
