export enum FilterOperator {
	Equals = 'equals',
	Contains = 'contains',
	NotEquals = 'not_equals',
	Between = 'between',
	Includes = 'includes',
	Excludes = 'excludes',
	GreaterThan = 'greater_than',
	LessThan = 'less_than',
	HasProperty = 'has_property',
	NotHasProperty = 'not_has_property',
	Set = 'set',
	NotSet = 'not_set',
}

export interface QueryFilter {
	property: string;
	operator: FilterOperator;
	values?: any[];
}

export function string(filters: QueryFilter[]) {
	return JSON.stringify(filters);
}
