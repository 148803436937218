import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { formatPriceIntervalToText } from 'pkg/utils';
import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import Link from 'pkg/router/Link';
import {
	getSubscriptionRowsTotalCost,
	SubscriptionTypes,
} from 'pkg/api/models/subscription';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import StatusLabel from 'components/payment_platform/status/label';
import Row from 'components/layout/row';

import * as css from './styles.css';

interface InstallmentDataProps {
	subscription: models.subscription.Subscription;
}

function InstallmentData({ subscription }: InstallmentDataProps) {
	const installmentCount = subscription.installmentCount;
	const paidOrders = models.subscription.getPaidOrders(subscription);
	const paid = models.order.getOrdersTotalAmountDue(paidOrders);
	const subscriptionRowsAmount =
		getSubscriptionRowsTotalCost(subscription.rows) *
		(installmentCount - paidOrders.length);
	const oneTimeRows = models.orderRow
		.findProductRows(subscription.orders?.[0].rows)
		?.filter((r) => !r.productPrice.recurring);

	let totalAmount = 0;
	const oneTimeCosts = models.orderRow.getTotalOneTimePayments(oneTimeRows);

	if (subscription.orders?.[0].status === models.order.Statuses.Paid) {
		totalAmount = paid / 100 + subscriptionRowsAmount;
	} else {
		totalAmount = paid / 100 + subscriptionRowsAmount + oneTimeCosts / 100;
	}

	return (
		<div className={css.colsWrapper}>
			<div className={css.col}>
				{t('{paidOrders} of {installmentCount}', {
					paidOrders: paidOrders.length,
					installmentCount,
				})}
				<span className={css.installmentDescription}>{t('Installment')}</span>
			</div>
			<div className={css.col}>
				{formatToCurrency(paid / 100, subscription.currency)}
				<span className={css.installmentDescription}>{t('Paid')}</span>
			</div>
			<div className={css.col}>
				{formatToCurrency(totalAmount, subscription.currency)}
				<span className={css.installmentDescription}>{t('Total')}</span>
			</div>
		</div>
	);
}

interface SubscriptionItemProps {
	subscription: models.subscription.Subscription;
	groupId: number;
	// The current user we're viewing, to determine paid by/paid on behalf of logic
	userId: number;
}

function SubscriptionItem({
	subscription,
	userId,
}: SubscriptionItemProps): JSX.Element {
	const created = new DateTime(new Date(subscription.createdAt * 1000));

	const productRows = models.subscription.findProductRows(subscription);
	const products = productRows.map((r) => r.productPrice.product);

	const cost = models.subscription.getSubscriptionRowsTotalCost(
		subscription.rows
	);

	const amount = formatPriceIntervalToText(
		subscription.recurringInterval,
		subscription.recurringIntervalCount
	);
	const productTitle =
		products.length === 1
			? products[0].name
			: products.length > 1
				? t('{name} + {amount} more', {
						name: products[0].name,
						amount: subscription.rows.length - 1,
					})
				: '';

	const users: {
		[key: number]: models.user.User;
	} = {};
	subscription.rows?.forEach((row) => {
		if (row?.user) {
			users[row.user.id] = row.user;
		}
	});

	const hideUsers = Object.keys(users).length === 0 || !!users[userId];

	return (
		<Fragment>
			<Card.Base $noBorder>
				<div className={css.base}>
					<div className={css.image}>
						<div className={css.iconwrapper}>
							<Icon name="sync" fill={styles.palette.blue[500]} />
						</div>
					</div>
					<div className={css.title}>
						<div>{productTitle}</div>
						<span>
							{t('Created {date}', {
								date: created.toLocaleDateString({
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
								}),
							})}
						</span>
					</div>

					{subscription.type === SubscriptionTypes.Installment && (
						<LargeScreen>
							<InstallmentData subscription={subscription} />
						</LargeScreen>
					)}

					<div className={css.col}>
						<StatusLabel
							className={css.statusLabel}
							status={models.subscription.getStatus(subscription)}
						/>
						<span className={css.date}>
							{formatToCurrency(cost, subscription.currency)} {amount}
						</span>
					</div>
				</div>

				{!hideUsers && (
					<Fragment>
						<Card.Divider />
						<div className={css.assignedUsers}>
							<Column>
								<span>{t('This subscription was paid on behalf of')}</span>
								{Object.values(users).map((user) => (
									<Fragment key={user.id}>
										<Row columns="40px 1fr auto" align="center">
											<Avatar user={user} size={40} />
											<strong>{models.user.fullName(user)}</strong>
										</Row>
									</Fragment>
								))}
							</Column>
						</div>
					</Fragment>
				)}
				{models.hasLink(subscription, 'show:payment_method') && (
					<Fragment>
						<Card.Divider />
						{subscription.type === SubscriptionTypes.Installment && (
							<SmallScreen>
								<InstallmentData subscription={subscription} />
							</SmallScreen>
						)}
						<Link href={subscription.links['show:payment_method']}>
							<Row className={css.paymentMethod}>
								<Icon name="nav-payments" size={1.5} />
								<span>{t('Update payment method')}</span>
							</Row>
						</Link>
					</Fragment>
				)}
			</Card.Base>
		</Fragment>
	);
}

export default SubscriptionItem;
