import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';

import LineItemRow from 'routes/forms/registration/form/products/summary/LineItem';
import BillingSummary from 'routes/forms/registration/form/products/summary/BillingSummary';
import { useRegistrationFormContext } from 'routes/forms/registration/form';

import * as Card from 'components/Card';

import Column from 'components/layout/column';

interface Props {
	currency: string;
	discount: models.discount.Discount;
}

const Summary = ({ currency, discount }: Props) => {
	const registrationCtx = useRegistrationFormContext();

	const preview = registrationCtx.state.preview;

	return (
		<Column>
			<Card.Base $dark>
				<Card.Body>
					<Column spacing={spacing._5}>
						<Column spacing={spacing._3}>
							{preview.recurringPayment.lineItems &&
								preview.recurringPayment.lineItems.map((recurringLineItem) => {
									const existsInInitialPayment =
										preview.initialPayment.lineItems.some(
											(initialLineItems) =>
												initialLineItems?.productPrice?.id ===
												recurringLineItem?.productPrice?.id
										);
									if (!existsInInitialPayment) {
										return (
											<LineItemRow
												key={recurringLineItem?.productPrice?.id}
												lineItem={recurringLineItem}
												currency={currency}
												installmentCount={preview.installmentCount}
												subscriptionStartsAt={preview.subscriptionStartAt}
												discountedPayments={preview.discountedPayments}
											/>
										);
									}
								})}
							{preview.initialPayment.lineItems.map((lineItem) => {
								return (
									<LineItemRow
										key={lineItem.productPrice?.id}
										lineItem={lineItem}
										currency={currency}
										installmentCount={preview.installmentCount}
										discountedPayments={preview.discountedPayments}
									/>
								);
							})}
						</Column>
					</Column>
				</Card.Body>
			</Card.Base>

			<Card.Base $dark>
				<Card.Body>
					<Column spacing={spacing._5}>
						<Column spacing={spacing._4}>
							<BillingSummary
								initialPayment={preview.initialPayment}
								currency={currency}
								monthlyPayment={preview.recurringPayment?.salePrice}
								recurringInterval={preview.recurringInterval}
								installmentCount={preview.installmentCount}
								discount={discount}
								recurringDiscountedPayment={preview.recurringDiscountedPayment}
								recurringPayment={preview.recurringPayment}
								totalInstallmentAmount={preview.totalInstallmentAmount}
							/>
						</Column>
					</Column>
				</Card.Body>
			</Card.Base>
		</Column>
	);
};

export default Summary;
