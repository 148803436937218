// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IuYmAlbM8TbUF0CEeFRW {
	margin-top: var(--spacing-8);
	padding-top: var(--spacing-5);
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	border-top: 1px solid var(--palette-gray-300);
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/event-users/styles.css"],"names":[],"mappings":"AAAA;CACC,4BAA4B;CAC5B,6BAA6B;CAC7B,8BAA8B;CAC9B,8BAA8B;CAC9B,6CAA6C;AAC9C","sourcesContent":[".attendanceSaveMessage {\n\tmargin-top: var(--spacing-8);\n\tpadding-top: var(--spacing-5);\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-500);\n\tborder-top: 1px solid var(--palette-gray-300);\n}\n"],"sourceRoot":""}]);
// Exports
export var attendanceSaveMessage = `IuYmAlbM8TbUF0CEeFRW`;
export default ___CSS_LOADER_EXPORT___;
