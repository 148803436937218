import { Fragment, useState } from 'react';
import { Browser } from '@capacitor/browser';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import * as formsService from 'pkg/forms_service';
import { isApp } from 'pkg/platform';
import { useCurrentAccount } from 'pkg/identity';
import { inAppRegistrationsEnabled } from 'pkg/flags';
import { Statuses } from 'pkg/api/models/form';

import CardBase, * as Card from 'components/Card';
import { FormattedContent } from 'components/formatted-content';
import Modal from 'components/modal';

import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

import Button from 'design/button';

import * as css from './styles.css';

interface RegistrationModalProps {
	form: models.form.Form;
	onClose: () => void;
}

export default function RegistrationModal({
	form,
	onClose,
}: RegistrationModalProps): JSX.Element {
	const t = useT();

	const [isAuthing, setIsAuthing] = useState(false);
	const authenticate = formsService.useAuthenticator();

	const account = useCurrentAccount();

	const hasSubmittedUsers = !!form.submissions?.length;
	const hasImageAttachment =
		form.attachment?.type === models.attachment.AttachmentType.Image;

	const handleOpenRegistration = async () => {
		setIsAuthing(true);
		const url = await authenticate(account.id, form);

		if (isApp()) {
			Browser.open({ url: url });
		} else {
			window.location.href = url;
		}

		onClose();
		setIsAuthing(false);
	};

	const submittedUsers = () => {
		const users = form.submissions.map(
			(submission: models.formSubmission.FormSubmission) => {
				const user: models.user.User = submission.submittedForUser;

				const date = DateTime.fromTimestamp(
					submission.createdAt
				).toLocaleDateString();

				return (
					<div key={submission.id}>{`${models.user.fullName(
						user
					)} (${date})`}</div>
				);
			}
		);

		return (
			<Column spacing={styles.spacing._2}>
				<strong>{t('Registered')}</strong>
				{users}
			</Column>
		);
	};

	return (
		<Modal onClose={onClose}>
			<CardBase>
				<Card.Header>
					<Card.Heading>{t('Registration')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body $noSpacing $flex>
					<div className={css.wrapper}>
						{hasImageAttachment && (
							<div className={css.image}>
								<img src={form.attachment.url} />
							</div>
						)}
						<Column className={css.content}>
							{!!form.maxSubmissions && (
								<div className={css.submissions}>
									{t('{submissions} of {max_submissions} available', {
										submissions: form.maxSubmissions - form.submissionCount,
										max_submissions: form.maxSubmissions,
									})}
								</div>
							)}
							<h1>{form.title}</h1>
							{(hasSubmittedUsers || form.description) && (
								<Column spacing={styles.spacing._8}>
									{form.description && (
										<FormattedContent raw={form.description} />
									)}
									{hasSubmittedUsers && submittedUsers()}
								</Column>
							)}
						</Column>
					</div>
				</Card.Body>
				<Card.Divider />
				<Card.Footer $columns="1fr">
					{form.status === Statuses.Open && (
						<Fragment>
							{inAppRegistrationsEnabled() ? (
								<Button
									isLoading={isAuthing}
									primary
									block
									label={t('Register')}
									href={routes.Registration.Preview(form.guid)}
									icon="new_window"
									iconPosition="right"
								/>
							) : (
								<Button
									isLoading={isAuthing}
									primary
									block
									label={t('Register')}
									onClick={handleOpenRegistration}
									icon="new_window"
									iconPosition="right"
								/>
							)}
						</Fragment>
					)}

					{form.status === Statuses.Closed && (
						<InfoBox
							color="yellow"
							text={t('This registration is closed for submissions')}
						/>
					)}
				</Card.Footer>
			</CardBase>
		</Modal>
	);
}
