import { t } from '@transifex/native';

import { LineItem } from 'pkg/api/models/payment_preview';
import { formatToCurrency } from 'pkg/i18n/formatters';
import DateTime from 'pkg/datetime';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface LineItemProps {
	lineItem: LineItem;
	currency: string;
	installmentCount?: number;
	subscriptionStartsAt?: number;
	discountedPayments?: number;
}

interface ProductRowProps {
	lineItem: LineItem;
	currency: string;
	installmentCount?: number;
	subscriptionStartsAt?: number;
}

interface DiscountRowProps {
	lineItem: LineItem;
	discountedPayments?: number;
}

export default function LineItemRow({
	lineItem,
	currency,
	installmentCount,
	subscriptionStartsAt,
	discountedPayments,
}: LineItemProps): JSX.Element {
	return lineItem.discount ? (
		<DiscountRow lineItem={lineItem} discountedPayments={discountedPayments} />
	) : (
		<ProductRow
			lineItem={lineItem}
			installmentCount={installmentCount}
			subscriptionStartsAt={subscriptionStartsAt}
			currency={currency}
		/>
	);
}

const ProductRow = ({
	lineItem,
	installmentCount,
	subscriptionStartsAt,
	currency,
}: ProductRowProps) => (
	<Row columns="auto auto" align="stretch">
		<div className={css.item}>
			{lineItem.quantity}x {lineItem.name}
			{lineItem.productPrice.recurring && installmentCount && (
				<div className={css.infoGrey}>
					{t('{installmentCount} installments', { installmentCount })}
				</div>
			)}
			{subscriptionStartsAt && (
				<div className={css.infoGrey}>
					{t('Subscription starting {subscriptionStartsAt}', {
						subscriptionStartsAt:
							DateTime.fromTimestamp(subscriptionStartsAt).toLocaleDateString(),
					})}
				</div>
			)}
		</div>

		<div className={css.amountLarge}>
			{currency && formatToCurrency(lineItem.totalListPrice / 100, currency)}
			{lineItem.productPrice.recurring &&
				lineItem.productPrice.recurringInterval &&
				` / ${lineItem.productPrice.recurringInterval}`}
		</div>
	</Row>
);

const DiscountRow = ({ lineItem, discountedPayments }: DiscountRowProps) => (
	<Row columns="auto auto" align="stretch">
		<Column>
			<div>
				<div className={css.item}>{lineItem.discount.title}</div>
				<div className={css.infoGrey}>
					{discountedPayments &&
						(lineItem.discount.percentOff
							? t('For {payments} payments', {
									payments: discountedPayments,
								})
							: t('{amountOff} off for {payments} payments', {
									amountOff: lineItem.discount.amountOff
										? formatToCurrency(
												lineItem.discount.amountOff / 100,
												lineItem.discount.currency
											)
										: '',
									payments: discountedPayments,
								}))}
				</div>
			</div>
		</Column>
		<div className={css.amountLarge}>
			{lineItem.discount.percentOff
				? `-${lineItem.discount.percentOff}%`
				: lineItem.discount.currency &&
					formatToCurrency(
						lineItem.totalListPrice / 100,
						lineItem.discount.currency
					)}
		</div>
	</Row>
);
