// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GOqyX0JsQssiOG4QCHI4 {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 50px;
	height: 50px;
	border: 2px solid transparent;
	border-radius: var(--radius-7);
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.GOqyX0JsQssiOG4QCHI4:first-child {
		margin-top: auto;
	}

.GOqyX0JsQssiOG4QCHI4.o3sT2xnrGkx18a7iGQ5t {
		border: 2px solid var(--palette-gray-300);
	}

.bmGy6JifkWZOHaTRoSCP {
	width: 100%;
	aspect-ratio: 1 / 1;
	position: relative;
	border-radius: var(--radius-6);
	background-color: var(--palette-black);
	background-color: var(--primaryColor, var(--palette-black));
}

.yGMgGP2FvcbBsKXafPvY {
	padding: var(--spacing-2);
	width: 100%;
	aspect-ratio: 1 / 1;
	overflow: hidden;
	border-radius: var(--radius-6);
	position: absolute;
}

.yGMgGP2FvcbBsKXafPvY.DfBR64i3vNeXmGA9Phpd {
		-webkit-box-shadow:
			0 0 0 3px var(--palette-white),
			0 0 0 5px var(--palette-gray-300);
		        box-shadow:
			0 0 0 3px var(--palette-white),
			0 0 0 5px var(--palette-gray-300);
	}

.pTOM3xi5QpDc7BqcQt6x {
	border-radius: 50px;
}

.yNQDIpnNDPK5yCvrhPw5 {
	-webkit-box-ordinal-group: 2;
	    -ms-flex-order: 1;
	        order: 1;
	visibility: hidden;
}

.CX2J1ptZ85oJYQ_CqOhu {
	padding: 0;
}
`, "",{"version":3,"sources":["webpack://./components/navigation/menus/organization_switcher/list_item/styles.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,SAAS;CACT,QAAQ;CACR,WAAW;CACX,YAAY;CACZ,6BAA6B;CAC7B,8BAA8B;CAC9B,wCAAgC;SAAhC,gCAAgC;AASjC;;AAPC;EACC,gBAAgB;CACjB;;AAEA;EACC,yCAAyC;CAC1C;;AAGD;CACC,WAAW;CACX,mBAAmB;CACnB,kBAAkB;CAClB,8BAA8B;CAC9B,sCAA2D;CAA3D,2DAA2D;AAC5D;;AAEA;CACC,yBAAyB;CACzB,WAAW;CACX,mBAAmB;CACnB,gBAAgB;CAChB,8BAA8B;CAC9B,kBAAkB;AAOnB;;AALC;EACC;;oCAEkC;UAFlC;;oCAEkC;CACnC;;AAGD;CACC,mBAAmB;AACpB;;AAEA;CACC,4BAAQ;KAAR,iBAAQ;SAAR,QAAQ;CACR,kBAAkB;AACnB;;AAEA;CACC,UAAU;AACX","sourcesContent":[".activeContainer {\n\tposition: absolute;\n\tleft: 50%;\n\ttop: 50%;\n\twidth: 50px;\n\theight: 50px;\n\tborder: 2px solid transparent;\n\tborder-radius: var(--radius-7);\n\ttransform: translate(-50%, -50%);\n\n\t&:first-child {\n\t\tmargin-top: auto;\n\t}\n\n\t&.show {\n\t\tborder: 2px solid var(--palette-gray-300);\n\t}\n}\n\n.badgeWrapper {\n\twidth: 100%;\n\taspect-ratio: 1 / 1;\n\tposition: relative;\n\tborder-radius: var(--radius-6);\n\tbackground-color: var(--primaryColor, var(--palette-black));\n}\n\n.badgeInnerWrapper {\n\tpadding: var(--spacing-2);\n\twidth: 100%;\n\taspect-ratio: 1 / 1;\n\toverflow: hidden;\n\tborder-radius: var(--radius-6);\n\tposition: absolute;\n\n\t&.active {\n\t\tbox-shadow:\n\t\t\t0 0 0 3px var(--palette-white),\n\t\t\t0 0 0 5px var(--palette-gray-300);\n\t}\n}\n\n.badge {\n\tborder-radius: 50px;\n}\n\n.hideContent {\n\torder: 1;\n\tvisibility: hidden;\n}\n\n.contextMenuItem {\n\tpadding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var activeContainer = `GOqyX0JsQssiOG4QCHI4`;
export var show = `o3sT2xnrGkx18a7iGQ5t`;
export var badgeWrapper = `bmGy6JifkWZOHaTRoSCP`;
export var badgeInnerWrapper = `yGMgGP2FvcbBsKXafPvY`;
export var active = `DfBR64i3vNeXmGA9Phpd`;
export var badge = `pTOM3xi5QpDc7BqcQt6x`;
export var hideContent = `yNQDIpnNDPK5yCvrhPw5`;
export var contextMenuItem = `CX2J1ptZ85oJYQ_CqOhu`;
export default ___CSS_LOADER_EXPORT___;
