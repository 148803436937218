import React, { useEffect } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { Capabilities } from 'pkg/models/membership';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import FormsDashboard from 'routes/forms/single/Dashboard';
import EditForm from 'routes/forms/edit';
import Submissions from 'routes/forms/single/Submissions';
import RegistrationSubscriptions from 'routes/forms/single/subscriptions';
import RegistrationOrders from 'routes/forms/single/invoices';

import { useSmallScreen } from 'components/MediaQuery';

import { ViewSettings } from 'components/layout/PageTemplates';
import { Spinner } from 'components/loaders/spinner';

export enum SingleFormTabViews {
	Dashboard = 'dashboard',
	Submissions = 'submissions',
	Subscriptions = 'subscriptions',
	Invoices = 'invoices',
	Edit = 'edit',
}
interface SingleFormProps extends ViewSettings {
	groupId: number;
	formId: number;
}

const SingleForm: React.FC<React.PropsWithChildren<SingleFormProps>> = ({
	groupId,
	formId,
	setPageTitle,
	setPageBackgroundColor,
	currentTab,
	setPageSubNavItems,
}) => {
	const org = useCurrentOrganization();
	const membership = useCurrentMembership();
	const { record: form, isLoading: isFormLoading } =
		useEndpoint<models.form.Form>(endpoints.Forms.Show(formId));

	const isSmallScreen = useSmallScreen();

	const subNavItems = [
		{
			title: t('Dashboard'),
			testid: 'subnav.dashboard',
			href: routes.Registrations.Single(org.id, groupId, formId, 'dashboard'),
		},
		{
			title: t('Submissions'),
			testid: 'subnav.submissions',
			href: routes.Registrations.Single(org.id, groupId, formId, 'submissions'),
		},
	];

	if (
		models.membership.hasCapability(membership, Capabilities.PaymentAdmin) &&
		models.group.hasActivePaymentProvider(org)
	) {
		subNavItems.push(
			{
				title: t('Invoices'),
				testid: 'subnav.invoices',
				href: routes.Registrations.Single(
					org.id,
					groupId,
					formId,
					SingleFormTabViews.Invoices
				),
			},
			{
				title: t('Subscriptions'),
				testid: 'subnav.subscriptions',
				href: routes.Registrations.Single(
					org.id,
					groupId,
					formId,
					'subscriptions'
				),
			}
		);
	}

	if (!form.deletedAt && !isSmallScreen) {
		subNavItems.push({
			title: t('Edit'),
			testid: 'subnav.edit',
			href: routes.Registrations.Single(org.id, groupId, formId, 'edit'),
		});
	}

	useEffect(() => {
		if (form.id) {
			setPageTitle(form.title);
			setPageSubNavItems(subNavItems);
		}
	}, [form.id]);

	useEffect(() => {
		if (currentTab === SingleFormTabViews.Edit) {
			setPageBackgroundColor(styles.palette.white);
		} else {
			setPageBackgroundColor(undefined);
		}
	}, [currentTab]);

	if (isFormLoading) {
		return <Spinner />;
	}
	switch (currentTab) {
		case SingleFormTabViews.Dashboard:
			return <FormsDashboard groupId={groupId} formId={formId} />;
		case SingleFormTabViews.Submissions:
			return <Submissions groupId={groupId} formId={formId} />;
		case SingleFormTabViews.Edit:
			return <EditForm groupId={groupId} formId={formId} />;
		case SingleFormTabViews.Subscriptions:
			return (
				<RegistrationSubscriptions organizationId={groupId} formId={formId} />
			);
		case SingleFormTabViews.Invoices:
			return <RegistrationOrders formId={formId} />;
	}
	return null;
};

export default SingleForm;
