import { t } from '@transifex/native';

import Country from 'pkg/models/country';
import { Capabilities } from 'pkg/models/membership';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';

export interface ExportUsersPayload {
	firstName?: boolean;
	lastName?: boolean;
	gender?: boolean;
	email?: boolean;
	phoneNumber?: boolean;
	address?: boolean;
	city?: boolean;
	region?: boolean;
	postalCode?: boolean;
	country?: boolean;
	nationality?: boolean;
	billingEmail?: boolean;
	billingAddress?: boolean;
	personalId?: boolean;
	dateOfBirth?: boolean;
	activeGroups?: boolean;
	products?: boolean;
	companyName?: boolean;
	taxId?: boolean;
	taxIdType?: boolean;
	connectedParents?: boolean;
}

export async function exportUsers(
	id: number,
	options: URLSearchParams,
	payload: ExportUsersPayload
): Promise<[boolean, string]> {
	const req = await sdk.post(
		endpoints.Organizations.ExportUsers(id) + '?' + options.toString(),
		{},
		payload
	);

	if (req.ok) {
		const resp = await req.text();
		return [true, resp];
	}

	return [false, ''];
}

export function getExportUsersFields({
	country,
	customFields,
	membership,
}: {
	country: Country;
	customFields: models.userFields.UserField[];
	membership: models.membership.Membership;
}) {
	let fields = [
		{ key: 'firstName', label: t(`First name`) },
		{ key: 'lastName', label: t(`Last name`) },
		{ key: 'gender', label: t(`Gender`) },
		{ key: 'email', label: t(`Email`) },
		{ key: 'phoneNumber', label: t(`Phone number`) },
		{ key: 'address', label: t(`Address`) },
		{ key: 'city', label: t(`City`) },
		{ key: 'region', label: t(`Region`) },
		{ key: 'postalCode', label: t(`Post code`) },
		{ key: 'country', label: t(`Country`) },
		{ key: 'personalId', label: t(`Personal ID number`) },
		{ key: 'dateOfBirth', label: t(`Date of birth`) },
		{ key: 'nationality', label: t(`Nationality`) },
		{
			key: 'billingEmail',
			label: t(`Billing email`),
		},
		{
			key: 'billingAddress',
			label: t(`Billing address`),
		},
		{
			key: 'activeGroups',
			label: t(`Active groups`),
		},
		{
			key: 'products',
			label: t(`Products`),
			hide: !models.membership.hasCapability(
				membership,
				Capabilities.PaymentAdmin
			),
		},
		{ key: 'companyName', label: t(`Company`) },
		{ key: 'taxId', label: t(`Tax ID`) },
		{ key: 'taxIdType', label: t(`Tax ID type`) },
		{
			key: 'connectedParents',
			label: t(`Connected parents`),
		},
		...(customFields || []).map(({ key, label }) => ({
			key: `custom.${key}`,
			label,
		})),
	];

	if (country?.code !== 'SE' || !!!country?.code) {
		fields = fields.filter((i) => i.key !== 'personalId');
	}

	return fields.filter((f) => !f.hide);
}
