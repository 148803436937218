import { t } from '@transifex/native';

import {
	EventTypes,
	eventTypeLabels,
	eventTypeToTranslatedString,
} from 'pkg/models/event';

import Label from 'components/label';

import * as Table from 'design/table';

interface Report {
	eventEnd: number;
	eventId: number;
	eventStart: number;
	eventTitle: string;
	eventType: string;
	userAttendanceStatus: string;
	userComment: string;
	userStatus: string;
}

interface SummaryProps {
	reports: Report[];
	filters: any;
}

export default function Summary({
	reports,
	filters,
}: SummaryProps): JSX.Element {
	const eventTypeHeaders = eventTypeLabels
		.filter((eventType: string) => filters.eventTypes.includes(eventType))
		.map((eventType: string) => ({
			content: eventTypeToTranslatedString(eventType as EventTypes),
			align: 'right',
		}));

	const columns = [
		{
			content: t(`Total events`),
		},
		...eventTypeHeaders,
		{
			content: t(`Attended`),
			align: 'right',
		},
		{
			content: t(`Injured`),
			align: 'right',
		},
		{
			content: t(`Illness`),
			align: 'right',
		},
		{
			content: t(`Other`),
			align: 'right',
		},
		{
			content: t(`Absent`),
			align: 'right',
		},
		{
			content: t(`Unhandled`),
			align: 'right',
		},
	];

	const summary: { [key: string]: number } = {
		absent: 0,
		attended: 0,
		camp: 0,
		cup: 0,
		sick: 0,
		injured: 0,
		match: 0,
		meeting: 0,
		other: 0,
		practice: 0,
		uncategorized: 0,
		unset: 0,
	};

	reports.map((report: any) => {
		summary[report.userAttendanceStatus]++;

		if (report.userAttendanceStatus === 'attended') {
			summary[report.eventType]++;
		} else if (report.userAttendanceStatus !== 'unset') {
			summary.absent++;
		}
	});

	const numEvents = reports.length;

	const renderedEventTypes = eventTypeLabels
		.filter((eventType: string) => filters.eventTypes.includes(eventType))
		.map((eventType: string) => (
			<Table.Cell align="right" key={eventType}>
				{summary[eventType]}
			</Table.Cell>
		));

	return (
		<Table.Table columns={columns}>
			<Table.Row>
				<Table.Cell>
					<Label color="gray">{numEvents}</Label>
				</Table.Cell>
				{renderedEventTypes}
				<Table.Cell align="right">
					<Label color="green">{summary.attended}</Label>
				</Table.Cell>
				<Table.Cell align="right">{summary.injured}</Table.Cell>
				<Table.Cell align="right">{summary.sick}</Table.Cell>
				<Table.Cell align="right">{summary.other}</Table.Cell>
				<Table.Cell align="right">
					<Label color="red">{summary.absent}</Label>
				</Table.Cell>
				<Table.Cell align="right">
					<Label color="orange">{summary.unset}</Label>
				</Table.Cell>
			</Table.Row>
		</Table.Table>
	);
}
