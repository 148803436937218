// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BiefCU0BphFw_Q9ZApI_ {
	text-align: right;
}

.C5srgd3lKO4BA_pV41xQ {
	font-weight: var(--font-weight-semibold);
}

.tqY3qJoqliMSWLGxuVYH {
	display: grid;
	grid-auto-flow: column;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: start;
	justify-items: normal;
	-webkit-box-pack: normal;
	    -ms-flex-pack: normal;
	        justify-content: normal;
	grid-template-columns: 2fr 1fr 1fr;
	line-height: var(--font-line-height-base);
}

@media (max-width: 1100px) {

.tqY3qJoqliMSWLGxuVYH {
		grid-template-columns: 0fr 1fr 1fr;
}
	}
`, "",{"version":3,"sources":["webpack://./routes/payments/orders/single/summary/styles.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;AAClB;;AAEA;CACC,wCAAwC;AACzC;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,wBAAkB;KAAlB,qBAAkB;SAAlB,kBAAkB;CAClB,qBAAqB;CACrB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,kCAAkC;CAClC,yCAAyC;AAK1C;;AAHC;;AATD;EAUE,kCAAkC;AAEpC;CADC","sourcesContent":[".alignRight {\n\ttext-align: right;\n}\n\n.bold {\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.row {\n\tdisplay: grid;\n\tgrid-auto-flow: column;\n\talign-items: start;\n\tjustify-items: normal;\n\tjustify-content: normal;\n\tgrid-template-columns: 2fr 1fr 1fr;\n\tline-height: var(--font-line-height-base);\n\n\t@media (--to-large-viewport) {\n\t\tgrid-template-columns: 0fr 1fr 1fr;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var alignRight = `BiefCU0BphFw_Q9ZApI_`;
export var bold = `C5srgd3lKO4BA_pV41xQ`;
export var row = `tqY3qJoqliMSWLGxuVYH`;
export default ___CSS_LOADER_EXPORT___;
