import { t } from '@transifex/native';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as selectors from 'pkg/selectors';
import { pushState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';

import { ScrollableWrapper } from 'components/scrollable-wrapper';
import SectionTitle from 'components/SectionTitle';
import Icon, { IconName } from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import ActionModal from 'components/navigation/header/large_screen/ActionModal';
import Column from 'components/layout/column';
import getActionMenuItems from 'components/navigation/menus/ActionMenu';

const QuickCreateItem = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	gap: var(--spacing-5);
	border-radius: var(--radius-5);
	background: white;
	color: var(--palette-gray-900);
	padding: var(--spacing-2);
	padding-right: var(--spacing-5);
	margin-right: var(--spacing-3);

	.${iconStyles.icon} {
		font-size: var(--font-size-5xl);
		background-color: ${(props) =>
			props.theme.primaryColorLight || styles.palette.gray[200]};
		color: ${(props) =>
			props.theme.primaryColorDark || styles.palette.gray[900]};
		padding: var(--spacing-3);
		border-radius: var(--radius-4);
	}

	span {
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		white-space: nowrap;
	}
`;

interface QuickCreateProps {
	activeMembership: models.membership.Membership;
	group: models.group.Group;
	groupSport: models.sport.Sport;
}

export default function QuickCreate({
	activeMembership,
	groupSport,
	group,
}: QuickCreateProps): JSX.Element {
	const [actionModal, setActionModal] = useState(null);

	const org = useCurrentOrganization();

	const groups = useSelector((state) =>
		selectors.groups.getOrganizationTree(state, {
			groupId: activeMembership.groupId,
		})
	);

	const defaultGroup = groups.get(activeMembership.groupId);

	const actionMenuItems = getActionMenuItems(
		org.id,
		activeMembership,
		groupSport,
		group
	);

	const goTo = (href: string) => pushState(href);

	const handleActionClick = (item: any) => {
		setActionModal(item.slug);
	};

	if (actionMenuItems.length === 0) {
		return null;
	}

	return (
		<Fragment>
			<Column>
				<SectionTitle icon="add_circle">{t(`Create`)}</SectionTitle>

				<ScrollableWrapper chevrons>
					{actionMenuItems.map((item, n) => (
						<QuickCreateItem
							key={`quick-create-item-${n}`}
							onClick={
								item.properties.href
									? () => goTo(item.properties.href)
									: () => handleActionClick(item.properties)
							}>
							<Icon name={item.properties.icon as IconName} />
							<span>{item.name}</span>
						</QuickCreateItem>
					))}
				</ScrollableWrapper>
			</Column>

			<ActionModal
				actionModal={actionModal}
				setActionModal={setActionModal}
				groups={groups}
				defaultGroup={defaultGroup}
			/>
		</Fragment>
	);
}
