import { Fragment, ChangeEvent, useContext } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { cssVarList } from 'pkg/css/utils';
import * as models from 'pkg/api/models';

import {
	AddProductContext,
	PriceOptionData,
} from 'routes/payments/products/create';
import * as productFormSharedStyles from 'routes/payments/products/form/shared.css';
import TaxSelect from 'routes/payments/tax_rates/select';

import SectionTitle from 'components/SectionTitle';

import * as Input from 'components/form/inputs';
import SelectBillingCycle from 'components/prices/billing-cycle-select';
import Column from 'components/layout/column';

import * as css from './styles.css';

function validatePriceCost(cost: number) {
	return Math.round(cost * 100) > 0;
}

export function validatePriceOptions(items: PriceOptionData[]) {
	return items.filter((item) => {
		// Already created prices has been validated when created, nothing that can be edited is mandatory so always return true
		if (item.id) {
			return true;
		}

		if (!item.recurring) {
			const values = validatePriceCost(item.cost) && item.currency;

			return values;
		} else {
			const maxOccurrenceNumber = getMaxOccurrenceNumber(
				item.recurringInterval
			);

			const values =
				validatePriceCost(item.cost) &&
				item.currency &&
				item.recurring !== null &&
				item.recurringOption;

			if (item.recurringOption === 'custom') {
				return (
					values &&
					item.recurringIntervalCount <= maxOccurrenceNumber &&
					item.recurringIntervalCount > 0
				);
			}

			return values;
		}
	});
}

export const getPriceOptions = () => [
	{
		key: 'recurring',
		title: t('Subscription'),
		description: t('Charge members automatically'),
	},
	{
		key: 'single',
		title: t('One time payment'),
		description: t('Let members pay manually'),
	},
];

export const getMaxOccurrenceNumber = (
	interval: models.providerSettings.RecurringInterval
): number => {
	switch (interval) {
		case models.providerSettings.RecurringInterval.Year:
			return 1;
		case models.providerSettings.RecurringInterval.Month:
			return 12;
		case models.providerSettings.RecurringInterval.Week:
			return 52;
		case models.providerSettings.RecurringInterval.Day:
			return 365;
	}
};

interface PriceOptionProps {
	index: number;
	currency: string;
	singlePriceOption: boolean;

	addPriceOption: (index: number) => void;
	removePriceOption: (index: number) => void;
}

const PriceOption = ({
	index,
	currency,
	singlePriceOption,
	addPriceOption,
	removePriceOption,
}: PriceOptionProps): JSX.Element => {
	const addProductContext = useContext(AddProductContext);

	const priceOptions = addProductContext.formState.priceOptions;
	const priceOption = priceOptions[index];
	const editPrice = !!priceOption.id;
	const maxOccurrenceNumber = getMaxOccurrenceNumber(
		priceOption.recurringInterval
	);

	const customIntervals = models.providerSettings.getIntervals(
		addProductContext.providerSettings
	);

	const updatePriceOptions = () => {
		addProductContext.setFormState({
			priceOptions: [...priceOptions],
		});
	};

	const handlePriceTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		priceOption.priceTitle = event.target.value;

		updatePriceOptions();
	};

	const handleCostChange = (event: ChangeEvent<HTMLInputElement>) => {
		priceOption.cost = Number.parseFloat(event.target.value);

		updatePriceOptions();
	};

	const handleTaxRateChange = (event: ChangeEvent<HTMLSelectElement>) => {
		if (event.target.value !== '') {
			priceOption.taxRateId = event.target.value;
		} else {
			priceOption.taxRateId = '';
		}

		updatePriceOptions();
	};

	const handleChangeBillingCycle = (event: ChangeEvent<HTMLSelectElement>) => {
		const value = event.target
			.value as models.providerSettings.RecurringOptionStrings;

		priceOption.recurringOption = value;

		updatePriceOptions();
	};

	const handleIntervalCountChange = (event: ChangeEvent<HTMLInputElement>) => {
		priceOption.recurringIntervalCount = Number.parseInt(
			event.target.value,
			10
		);

		updatePriceOptions();
	};

	const handleRecurringIntervalChange = (
		event: ChangeEvent<HTMLSelectElement>
	) => {
		priceOption.recurringInterval = event.target
			.value as models.providerSettings.RecurringInterval;

		updatePriceOptions();
	};

	const handleAddPrice = () => {
		addPriceOption(index);
	};

	const handleRemovePrice = () => {
		removePriceOption(index);
	};

	const setRecurring = () => {
		priceOption.recurring = !priceOption.recurring;

		updatePriceOptions();
	};

	let cost = priceOption.cost;
	if (editPrice) {
		cost = cost / 100;
	}

	return (
		<Fragment>
			<Column>
				{!singlePriceOption && (
					<SectionTitle>
						{t('Option #{number}', {
							number: index + 1,
						})}
					</SectionTitle>
				)}
				<Input.Group label={t('Type')}>
					<Input.Control
						type="radio"
						label={t('Recurring')}
						description={t('Charge members on a schedule')}
						checked={priceOption.recurring}
						onChange={setRecurring}
						disabled={editPrice}
					/>
					<Input.Control
						type="radio"
						label={t('One time payment')}
						description={t('Let members pay manually')}
						checked={!priceOption.recurring}
						onChange={setRecurring}
						disabled={editPrice}
					/>
				</Input.Group>
				<div className={productFormSharedStyles.fieldWrapper}>
					<Column>
						{priceOption.recurring !== null && (
							<Column>
								<Input.Group
									label={t('Title')}
									hint={t('Example: Yearly subscription')}>
									<Input.Field
										type="text"
										value={priceOption.priceTitle}
										testid="priceTitle"
										onChange={handlePriceTitleChange}
									/>
								</Input.Group>

								<Input.Group label={t('Price')}>
									<Input.Field
										type="number"
										onChange={handleCostChange}
										min="0.01"
										step="0.01"
										value={cost > 0 ? cost.toString() : ''}
										disabled={editPrice}>
										<Input.Prefix>{currency.toUpperCase()}</Input.Prefix>
									</Input.Field>
								</Input.Group>

								<Input.Group label={t('TAX')}>
									<TaxSelect
										taxRates={addProductContext.taxRates}
										value={priceOption.taxRateId}
										handleChange={handleTaxRateChange}
									/>
								</Input.Group>
							</Column>
						)}
						{priceOption.recurring && (
							<Column>
								<Input.Group label={t('Billing cycle')}>
									<SelectBillingCycle
										value={priceOption.recurringOption}
										onChange={handleChangeBillingCycle}
										disabled={editPrice}
										providerSettings={addProductContext.providerSettings}
									/>
								</Input.Group>
								{priceOption.recurringOption === 'custom' && (
									<Input.Group
										label={t('Custom can not be longer than a year')}>
										<Column spacing={styles.spacing._3}>
											<Input.Field
												type="number"
												value={priceOption.recurringIntervalCount.toString()}
												onChange={handleIntervalCountChange}
												max={maxOccurrenceNumber}
												hasError={
													priceOption.recurringIntervalCount >
													maxOccurrenceNumber
												}>
												<Input.Prefix>
													<span>{t('every')}</span>
												</Input.Prefix>
											</Input.Field>
											<Input.Select
												value={priceOption.recurringInterval}
												onChange={handleRecurringIntervalChange}>
												{customIntervals.map((interval, i) => (
													<option key={i} value={interval}>
														{models.providerSettings.getCustomIntervalTranslation(
															interval
														)}
													</option>
												))}
											</Input.Select>
										</Column>
									</Input.Group>
								)}
							</Column>
						)}
					</Column>
				</div>
				{!singlePriceOption && (
					<Column spacing={styles.spacing._1}>
						{addProductContext.formState.priceOptions.length > 1 &&
							!editPrice && (
								<div
									style={cssVarList({
										color: styles.palette.red[500],
									})}
									className={css.priceLink}
									onClick={handleRemovePrice}>
									{t('Delete price option')}
								</div>
							)}
						{index + 1 === addProductContext.formState.priceOptions.length && (
							<div
								style={cssVarList({
									color: styles.palette.blue[500],
								})}
								className={css.priceLink}
								onClick={handleAddPrice}>
								{t('+ Add another price option')}
							</div>
						)}
					</Column>
				)}
			</Column>
		</Fragment>
	);
};

export default PriceOption;
