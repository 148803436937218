// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BvQwHYwKB4MLrdQR_ghw {
	width: 100%;
	height: 100%;
}

.V8j7RlYCWP5P7doRMCgy {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.V8j7RlYCWP5P7doRMCgy:last-of-type {
		opacity: 0.65;
	}

.cS41C5sdWGqnN7wz9keQ {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-1);
}

.XnMVaT44hScvd7X8RJC5 {
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./routes/group/calendar/components/events/styles.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,cAAc;CACd,gBAAgB;CAChB,mBAAmB;CACnB,uBAAuB;AAKxB;;AAHC;EACC,aAAa;CACd;;AAGD;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;AACtB;;AAEA;CACC,YAAY;CACZ,gBAAgB;CAChB,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".contentWrapper {\n\twidth: 100%;\n\theight: 100%;\n}\n\n.contentWrapperChild {\n\tdisplay: block;\n\toverflow: hidden;\n\twhite-space: nowrap;\n\ttext-overflow: ellipsis;\n\n\t&:last-of-type {\n\t\topacity: 0.65;\n\t}\n}\n\n.titleWrapper {\n\tdisplay: flex;\n\tgap: var(--spacing-1);\n}\n\n.title {\n\tmin-width: 0;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\twhite-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export var contentWrapper = `BvQwHYwKB4MLrdQR_ghw`;
export var contentWrapperChild = `V8j7RlYCWP5P7doRMCgy`;
export var titleWrapper = `cS41C5sdWGqnN7wz9keQ`;
export var title = `XnMVaT44hScvd7X8RJC5`;
export default ___CSS_LOADER_EXPORT___;
