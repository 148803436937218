// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J84OJYNMOFSlrfX6hZkC {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding-bottom: var(--spacing-8);
}

.gpi0RJ8rrJ0oJghycsgW {
	text-align: center;
	word-break: break-word;
}
`, "",{"version":3,"sources":["webpack://./components/navigation/menus/organization_switcher/empty_state/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,gCAAgC;AACjC;;AAEA;CACC,kBAAkB;CAClB,sBAAsB;AACvB","sourcesContent":[".emptyStateWrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding-bottom: var(--spacing-8);\n}\n\n.text {\n\ttext-align: center;\n\tword-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
export var emptyStateWrapper = `J84OJYNMOFSlrfX6hZkC`;
export var text = `gpi0RJ8rrJ0oJghycsgW`;
export default ___CSS_LOADER_EXPORT___;
