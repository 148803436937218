// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._6SvyqiojpGCbrJRrIG2 {
	position: relative;
	margin: 0 auto;
}

.KoZkpeiHmvPjPkVWvopH {
	display: inline-grid;
	grid-template-columns: 1fr auto;
	padding: var(--spacing-2) var(--spacing-4);
	padding-right: var(--spacing-3);
	background: hsla(0, 0%, 100%, 0.15);
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	gap: var(--spacing-3);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-semibold);
	-webkit-transition: -webkit-box-shadow 120ms ease-in-out;
	transition: -webkit-box-shadow 120ms ease-in-out;
	transition: box-shadow 120ms ease-in-out;
	transition: box-shadow 120ms ease-in-out, -webkit-box-shadow 120ms ease-in-out;
	border-radius: 100px;
	color: var(--palette-white);
}

@media (max-width: 768px) {

.KoZkpeiHmvPjPkVWvopH {
		font-size: var(--font-size-2xs);
}
	}

@media (hover: hover) {
		.KoZkpeiHmvPjPkVWvopH:hover:not([disabled]) {
			-webkit-box-shadow: hsla(0, 0%, 100%, 0.5) 0 0 0 4px;
			        box-shadow: hsla(0, 0%, 100%, 0.5) 0 0 0 4px;
		}
	}
`, "",{"version":3,"sources":["webpack://./routes/public/styles/language-selector/styles.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,cAAc;AACf;;AAEA;CACC,oBAAoB;CACpB,+BAA+B;CAC/B,0CAA0C;CAC1C,+BAA+B;CAC/B,mCAAoC;CACpC,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,qBAAqB;CACrB,8BAA8B;CAC9B,wCAAwC;CACxC,wDAAwC;CAAxC,gDAAwC;CAAxC,wCAAwC;CAAxC,8EAAwC;CACxC,oBAAoB;CACpB,2BAA2B;AAW5B;;AATC;;AAdD;EAeE,+BAA+B;AAQjC;CAPC;;AAEA;EACC;GACC,oDAA8C;WAA9C,4CAA8C;EAC/C;CACD","sourcesContent":[".wrapper {\n\tposition: relative;\n\tmargin: 0 auto;\n}\n\n.toggle {\n\tdisplay: inline-grid;\n\tgrid-template-columns: 1fr auto;\n\tpadding: var(--spacing-2) var(--spacing-4);\n\tpadding-right: var(--spacing-3);\n\tbackground: hsla(0deg 0% 100% / 15%);\n\tplace-items: center;\n\tgap: var(--spacing-3);\n\tfont-size: var(--font-size-xs);\n\tfont-weight: var(--font-weight-semibold);\n\ttransition: box-shadow 120ms ease-in-out;\n\tborder-radius: 100px;\n\tcolor: var(--palette-white);\n\n\t@media (--small-viewport) {\n\t\tfont-size: var(--font-size-2xs);\n\t}\n\n\t@media (hover: hover) {\n\t\t&:hover:not([disabled]) {\n\t\t\tbox-shadow: hsla(0deg 0% 100% / 50%) 0 0 0 4px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `_6SvyqiojpGCbrJRrIG2`;
export var toggle = `KoZkpeiHmvPjPkVWvopH`;
export default ___CSS_LOADER_EXPORT___;
