import { Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import Icon from 'components/icon';
import * as Card from 'components/Card';
import StepModal, { Step } from 'components/step-modal';

import Column from 'components/layout/column';
import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';

import * as css from './styles.css';

interface FieldModalProps {
	group: models.group.Group;
	existingFields: models.userFields.UserField[];
	prevData: models.userFields.UserField;
	onDone: (data: models.userFields.UserField, isEdit: boolean) => void;
	onClose: () => void;
}

const FieldModal: React.FC<FieldModalProps> = ({
	group,
	existingFields,
	prevData,
	onDone,
	onClose,
}) => {
	const getOptionName = (index: number): string => `option_${index}`;
	const getOptionDefaultValue = (index: number) =>
		t('Option {number}', { number: index + 1 });

	const { getValues, setValue, watch, reset } = useFormContext();
	const [isRequired, setIsRequired] = useState<boolean>(!!prevData?.required);
	const [visibility, setVisibility] = useState<models.userFields.Visibility[]>(
		prevData?.visibility || []
	);
	const [options, setOptions] = useState<number[]>(
		prevData?.values ? [...Array(prevData.values.length).keys()] : [0]
	);
	const [optionInEdit, setOptionInEdit] = useState<number>(null);
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

	const typeSelect = watch('field_type');
	const attachmentType = watch('attachment_type');
	const fieldLabel = watch('field_label');

	const hasChoices =
		typeSelect === models.userFields.UserFieldType.Choice ||
		typeSelect === models.userFields.UserFieldType.MultiChoice;

	const isEdit = !!prevData;

	const takenFieldName = existingFields
		.filter((field) => {
			if (isEdit) {
				return prevData.label !== field.label;
			}

			return true;
		})
		.map((field) => field.label.toLowerCase())
		.includes(fieldLabel?.toLowerCase());

	// useeffect will ignore to set the values when the component rerenders. we only want to update the fields the first render.
	useComponentDidMount(() => {
		if (isEdit) {
			setValue('field_type', prevData.type);
			setValue('field_label', prevData.label);
			setValue('field_description', prevData.description);
			setValue('field_required', prevData.required);
			setValue('field_values', prevData.values);
			if (prevData.type === models.userFields.UserFieldType.Attachment) {
				setValue('attachment_type', prevData.attachmentType);
			}

			if (prevData?.values?.length > 0) {
				for (const [index, value] of prevData.values.entries()) {
					setValue(getOptionName(index), value.label);
				}
			}
		} else {
			setValue('attachment_type', models.attachment.AttachmentType.Image);
		}
	});

	const handleSaveField = async () => {
		const isAttachment =
			typeSelect === models.userFields.UserFieldType.Attachment;

		if (
			!typeSelect ||
			!fieldLabel ||
			takenFieldName ||
			(isAttachment && !attachmentType)
		) {
			setShowErrorMessage(true);
			return false;
		}

		const customFieldData: models.userFields.UserField = {
			type: typeSelect,
			key: getValues('field_label').toLowerCase().split(' ').join('_').trim(),
			label: fieldLabel,
			description: getValues('field_description'),
			required: getValues('field_required') || false,
			visibility,
		};

		if (!isEdit) {
			customFieldData.sortOrder =
				existingFields.length > 0
					? existingFields[existingFields.length - 1].sortOrder + 1
					: 1;
		}

		if (isAttachment) {
			customFieldData.attachmentType = attachmentType;
		}

		if (hasChoices) {
			customFieldData.values = options.map((option) => ({
				label:
					getValues(getOptionName(option)) || getOptionDefaultValue(option),
				value: getOptionName(option),
			}));
		}

		onDone(customFieldData, isEdit);
		reset({ field_label: '', field_description: '' });
	};

	const handleClose = () => {
		onClose();
		reset({ field_label: '', field_description: '' });
	};

	const handleAttachmentType = (type: models.attachment.AttachmentType) => {
		setValue('attachment_type', type);
	};

	const handleToggleRequired = () => {
		const next = isRequired ? false : true;

		setIsRequired(next);
		setValue('field_required', next);
	};

	const handleToggleVisibility = (val: string) => {
		// visiblity takes strings of where the field should be visible.
		// atm visibility can only take "sidebar" - meaning this will act as a bool for now
		const isChecked = visibility.length > 0 ? true : false;

		setVisibility((current) => {
			const currentIndex = current.indexOf(
				models.userFields.visibilityFromString(val)
			);

			if (currentIndex === -1) {
				current.push(models.userFields.visibilityFromString(val));
			} else {
				current.splice(currentIndex, 1);
			}
			return [...current];
		});

		setValue('field_visible', isChecked);
	};

	const handleAddOption = () => {
		setOptions((prev) => [...prev, options.at(-1) + 1]);
	};

	const handleRemoveOption = (index: number) => {
		setOptions((prev) => prev.filter((option) => option != index));
		setOptionInEdit(null);
	};

	const handleOptionInEdit = (index: number) => {
		setOptionInEdit(index);
	};

	const handleResetEditOption = () => {
		setOptionInEdit(null);
	};

	return (
		<StepModal onClose={handleClose}>
			<Step
				title={isEdit ? t('Edit') : t('Create')}
				skipBody
				nextLabel={isEdit ? t('Save') : t('Create')}
				closeOnNext={false}
				onNext={handleSaveField}>
				<Card.Body>
					<Column spacing={styles.spacing._5}>
						<Input.Group
							label={t('Type')}
							errorMessage={
								showErrorMessage && !typeSelect ? t('Required') : ''
							}>
							<Input.Select name="field_type">
								<option value="">{t('Select type')}</option>
								{Object.values(models.userFields.UserFieldType)
									.filter((t) => t !== models.userFields.UserFieldType.Reserved)
									.map((type) => (
										<option key={type} value={type}>
											{models.userFields.getTypeTranslation(type)}
										</option>
									))}
							</Input.Select>
						</Input.Group>
						{typeSelect === models.userFields.UserFieldType.Attachment && (
							<Input.Group
								label={t('Attachment type')}
								required
								errorMessage={
									showErrorMessage && !attachmentType ? t('Required') : ''
								}>
								{Object.values(models.attachment.AttachmentType).map((type) => (
									<Input.Control
										key={type}
										type="radio"
										label={models.attachment.getTypeTranslation(type)}
										value={type}
										checked={type === attachmentType}
										onChange={handleAttachmentType}
									/>
								))}
							</Input.Group>
						)}
						<Input.Group
							label={t('Title')}
							errorMessage={
								showErrorMessage
									? takenFieldName
										? t('A field with this label already exists')
										: !fieldLabel
											? t('Required')
											: ''
									: ''
							}>
							<Input.Field name="field_label" />
						</Input.Group>
						<Input.Group label={t('Description')} optional>
							<Input.Area name="field_description" />
						</Input.Group>

						<Input.Group label={t('Visibility')}>
							<Input.Control
								name="field_visible"
								label={t('Contact card')}
								description={t(
									'Visible to organization admins on the contact card'
								)}
								type="checkbox"
								value={models.userFields.Visibility.ContactCard}
								checked={visibility.includes(
									models.userFields.Visibility.ContactCard
								)}
								onChange={handleToggleVisibility}
							/>
							<Input.Control
								name="field_visible"
								label={t('Profile')}
								description={t(
									'Visible on the profile for admin and staff in groups'
								)}
								type="checkbox"
								value={models.userFields.Visibility.Profile}
								checked={visibility.includes(
									models.userFields.Visibility.Profile
								)}
								onChange={handleToggleVisibility}
							/>
						</Input.Group>

						<Card.Divider />
						<Input.Control
							name="field_required"
							label={t('Required')}
							description={t(
								'This field has to be filled for users in {organization} to access 360Player',
								{
									organization: group.name,
								}
							)}
							type="checkbox"
							checked={isRequired}
							onChange={handleToggleRequired}
						/>

						{hasChoices && (
							<Fragment>
								<Card.Divider />
								{options.map((option) => (
									<Row
										key={option}
										align="center"
										onClick={() => handleOptionInEdit(option)}
										columns={`1fr${optionInEdit === option ? '' : ' auto'}`}>
										<div className={css['edit_option']}>
											<Icon
												name={
													typeSelect === models.userFields.UserFieldType.Choice
														? 'radio-unchecked'
														: 'checkbox-unchecked'
												}
												size={1.4}
											/>
											{optionInEdit === option ? (
												<Input.Field
													name={getOptionName(option)}
													defaultValue={getOptionDefaultValue(option)}
													onBlur={handleResetEditOption}
												/>
											) : (
												<Fragment>
													<p>
														{getValues(getOptionName(option)) ||
															getOptionDefaultValue(option)}
													</p>
													<Icon name="edit" size={1.4} />
												</Fragment>
											)}
										</div>
										{option > 0 && optionInEdit !== option && (
											<Icon
												name="close"
												onClick={() => handleRemoveOption(option)}
												size={1.4}
											/>
										)}
									</Row>
								))}
								<Link>
									<Row
										columns="auto 1fr"
										align="center"
										onClick={handleAddOption}>
										<Icon name="add" />
										{t('Add option')}
									</Row>
								</Link>
							</Fragment>
						)}
					</Column>
				</Card.Body>
			</Step>
		</StepModal>
	);
};

export default FieldModal;
