import { t } from '@transifex/native';

import { FilterOperator } from 'pkg/filters';
import { Filters, useFilters } from 'pkg/filters/use_filters';

interface DiscountFiltersProps {
	hideStatusFilter?: boolean;
}

export function useDiscountFilters({
	hideStatusFilter = false,
}: DiscountFiltersProps): Filters {
	return useFilters({
		filterBarMobileTrigger: true,
		groups: {
			[t('Search')]: {
				filters: {
					Search: {
						type: 'text',
						operator: FilterOperator.Contains,
						property: 'search',
					},
				},
				hidden: true,
			},
			[t('Status')]: {
				filters: {
					Active: {
						type: 'checkbox',
						property: 'inactivated_at',
						operator: FilterOperator.NotSet,
						values: {
							[t('Active')]: 'active',
						},
					},
					InactivatedAt: {
						type: 'checkbox',
						property: 'inactivated_at',
						operator: FilterOperator.Set,
						values: {
							[t('Inactive')]: 'inactive',
						},
					},
				},
				hidden: hideStatusFilter,
			},
			[t('Created')]: {
				filters: {
					CreatedAt: {
						type: 'date',
						property: 'created_at',
					},
				},
			},
			[t('Type')]: {
				filters: {
					Type: {
						type: 'checkbox',
						property: 'type',
						operator: FilterOperator.Includes,
						values: {
							[t('Amount')]: 'amount',
							[t('Percent')]: 'percent',
						},
					},
				},
			},
		},
	});
}
