import { ReactNode } from 'react';

import spacing from 'pkg/config/spacing';

import Lottie from 'components/Lottie';

import Column from 'components/layout/column';

import * as css from './styles.css';

interface StatusScreenProps {
	title: string;
	description?: string;
	children?: ReactNode;
	status: 'success' | 'error';
	fullscreen?: boolean;
}

export default function StatusScreen({
	title,
	description,
	children,
	status,
	fullscreen = false,
}: StatusScreenProps): JSX.Element {
	const animationName = status === 'success' ? 'done' : 'error';

	return (
		<div className={`${css.wrapper} ${fullscreen ? css.fullscreen : ''}`}>
			<Column spacing={spacing._1}>
				<Lottie
					name={animationName}
					key={animationName}
					autoplay
					className={css.animation}
				/>
				<Column spacing={spacing._3}>
					{title && <div className={css.title}>{title}</div>}
					{description && <div className={css.description}>{description}</div>}
				</Column>
			</Column>
			<div className={css.childrenWrapper}>{children}</div>
		</div>
	);
}
