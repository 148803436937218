import { Fragment, useState } from 'react';
import { t } from '@transifex/native';
import { FieldValues, useFormContext, UseFormSetValue } from 'react-hook-form';

import spacing from 'pkg/config/spacing';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import * as models from 'pkg/api/models';

import SelectableBundle from 'routes/forms/registration/form/products/bundle';
import Discount from 'routes/forms/registration/form/products/discount';
import Summary from 'routes/forms/registration/form/products/summary';
import {
	Bundle,
	getProductQtyName,
	ProductWithQty,
	useRegistrationFormContext,
} from 'routes/forms/registration/form';

import { useSmallScreen } from 'components/MediaQuery';
import * as CardList from 'components/card-list';

import * as Inputs from 'components/form/inputs';
import Section from 'components/form/Section';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button, { ButtonGroup } from 'design/button';

interface Props {
	form: models.form.Form;
	paymentProviderSettings: models.providerSettings.ProviderSettings;
	bundles: { [key: number]: Bundle };
	requiredProducts: { [key: number]: ProductWithQty };
	optionalProducts: { [key: number]: ProductWithQty };
	productQtys: { [key: number]: number };
	setValue: UseFormSetValue<FieldValues>;
}

const FormProducts = ({
	form,
	paymentProviderSettings,
	requiredProducts,
	optionalProducts,
	setValue,
}: Props) => {
	const isSmallScreen = useSmallScreen();

	const registrationCtx = useRegistrationFormContext();

	const [optionalProductsInCart, setOptionalProductsInCart] = useState<
		number[]
	>([]);

	const previews = registrationCtx.state.bundlePreviews;

	const handleBundleClick = (bundleNumber: number) => {
		registrationCtx.setState({ activeBundleNumber: bundleNumber });
		setValue('selected_bundle', bundleNumber);
	};

	const handleApplyDiscount = async (discountCode: string) => {
		const req = await sdk.get(endpoints.Discount.GetDiscountByCode(), {
			group_id: form.group.id,
			code: discountCode,
		});

		if (req.ok) {
			const res = await req.json();

			registrationCtx.setState({ discount: res });
		}
	};

	const handleRemoveDiscount = () => {
		registrationCtx.setState({ discount: null });
	};

	const handleAddOptionalProduct = (id: number) => {
		setOptionalProductsInCart([...optionalProductsInCart, id]);
	};

	const handleRemoveOptionalProduct = (id: number) => {
		setOptionalProductsInCart(
			optionalProductsInCart.filter((productId) => productId !== id)
		);
	};

	return (
		<Row columns="3fr 1.5fr" spacing={spacing._7} collapseOnSmallScreens>
			<Column>
				{Object.keys(requiredProducts).length > 0 && (
					<Section title={t('Products')}>
						<Column>
							{Object.entries(requiredProducts).map(([key, value]) => (
								<Product key={key} data={value} />
							))}
						</Column>
					</Section>
				)}
				{Object.keys(optionalProducts).length > 0 && (
					<Section title={t('Optional add-ons')}>
						<Column>
							{Object.entries(optionalProducts).map(([key, value]) => (
								<Product
									key={key}
									data={value}
									isAddedToCart={optionalProductsInCart.includes(value.id)}
									onAddToCart={handleAddOptionalProduct}
									onRemoveFromCart={handleRemoveOptionalProduct}
								/>
							))}
						</Column>
					</Section>
				)}
				<Section title={t('How would you like to pay?')}>
					<Column>
						{Object.values(previews).map((bundle) => (
							<SelectableBundle
								key={bundle.bundleNumber}
								bundle={bundle}
								currency={form.group.currency}
								isActive={
									registrationCtx.state.activeBundleNumber ===
									bundle.bundleNumber
								}
								onClick={handleBundleClick}
							/>
						))}
					</Column>
					<Inputs.Field
						type="number"
						name="selected_bundle"
						readOnly
						value={registrationCtx.state.activeBundleNumber}
						hidden
					/>
				</Section>
				{paymentProviderSettings &&
					paymentProviderSettings.canHaveDiscountCode && (
						<Discount
							discount={registrationCtx.state.discount}
							onApply={handleApplyDiscount}
							onRemove={handleRemoveDiscount}
						/>
					)}
			</Column>
			<Section title={t('Your cart')} hideDivider={!isSmallScreen}>
				<Summary
					currency={form.group.currency}
					discount={registrationCtx.state.discount}
				/>
			</Section>
		</Row>
	);
};

const Product = ({
	data,
	isAddedToCart = true,
	onAddToCart,
	onRemoveFromCart,
}: {
	data: ProductWithQty;
	isAddedToCart?: boolean;
	onAddToCart?: (id: number) => void;
	onRemoveFromCart?: (id: number) => void;
}) => {
	const { setValue, watch } = useFormContext();

	const isSmallScreen = useSmallScreen();

	// use the productid as the input name to keep the state of the quantity
	const productQtyName = getProductQtyName(data.id);

	const productQty = Number.parseInt(
		watch(productQtyName, data.minQty) || data.minQty,
		10
	);

	const handleAddQty = () => {
		setValue(productQtyName, productQty + 1);
	};

	const handleRemoveQty = () => {
		setValue(productQtyName, productQty - 1);
	};

	const handleAddToCart = () => {
		onAddToCart(data.id);
		setValue(productQtyName, data.minQty);
	};

	const handleRemoveFromCart = () => {
		onRemoveFromCart(data.id);
		setValue(productQtyName, 0);
	};

	return (
		<CardList.Base>
			<CardList.Title>{data.name}</CardList.Title>
			<CardList.Actions>
				<Row autoColumns="max-content" align="center">
					{isAddedToCart ? (
						<Fragment>
							<ButtonGroup spacing={spacing._2}>
								<Button
									secondary
									disabled={productQty <= data.minQty}
									onClick={handleRemoveQty}
									small={isSmallScreen}
									icon="remove"
								/>
								<Button
									secondary
									disabled={
										data.maxQty ? productQty >= data.maxQty : productQty >= 1
									}
									onClick={handleAddQty}
									small={isSmallScreen}
									icon="add"
								/>
							</ButtonGroup>
							<CardList.TextContent align="center">
								{productQty} <CardList.SmallText>{t('Qty')}</CardList.SmallText>
							</CardList.TextContent>
							{onRemoveFromCart && (
								<Button icon="delete" caution onClick={handleRemoveFromCart} />
							)}
						</Fragment>
					) : (
						<Button secondary onClick={handleAddToCart}>
							{t('Add to cart')}
						</Button>
					)}
					<Inputs.Field
						type="hidden"
						name={productQtyName}
						value={isAddedToCart ? productQty : 0}
					/>
				</Row>
			</CardList.Actions>
		</CardList.Base>
	);
};

export default FormProducts;
