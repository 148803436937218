import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import { FilterOperator } from 'pkg/filters';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Row from 'routes/forms/list/active/Row';
import FormCategoryModal from 'routes/forms/list/active/FormCategoryModal';

import AssetImage from 'components/AssetImage';
import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';

import * as Table from 'design/table';
import Button from 'design/button';

interface ActiveFormsProps {
	groupId: number;
}

const ActiveForms = ({ groupId }: ActiveFormsProps) => {
	const org = useCurrentOrganization();

	const filterGroups: FilterGroups = {
		[t('Title')]: {
			hidden: true,
			filters: {
				Title: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'title',
				},
			},
		},
		[t('Created')]: {
			filters: {
				Created: {
					type: 'date',
					property: 'createdAt',
					operator: FilterOperator.GreaterThan,
				},
			},
		},
		[t('Status')]: {
			filters: {
				Status: {
					type: 'checkbox',
					operator: FilterOperator.Includes,
					property: 'status',
					values: {
						[t('Draft')]: models.form.Statuses.Draft,
						[t('Open')]: models.form.Statuses.Open,
						[t('Closed')]: models.form.Statuses.Closed,
					},
				},
			},
		},
		[t('Visibility')]: {
			filters: {
				Visibility: {
					type: 'radio',
					operator: FilterOperator.Equals,
					property: 'visibility',
					values: {
						[t('Public')]: models.form.Visibilities.Public,
						[t('Unlisted')]: models.form.Visibilities.Unlisted,
					},
				},
			},
		},
		[t('Submissions')]: {
			filters: {
				Submissions: {
					type: 'number',
					operator: FilterOperator.Equals,
					property: 'submissionCount',
				},
			},
		},
	};

	const filters = useFilters({
		groups: filterGroups,
		filterBarMobileTrigger: true,
	});

	filters.queryParam.set('group_id', groupId.toString());

	const { records, isLoading, pagination, replaceRecord, removeRecord, sort } =
		useCollection<models.form.Form>(endpoints.Forms.Index(), {
			queryParams: filters.queryParam,
			useLocationPagination: true,
			defaultSortBy: 'createdAt',
			defaultSortByOrder: 'desc',
		});

	const [showCategory, setShowCategory] = useState(false);

	const handleCloseCategories = () => setShowCategory(false);
	const handleShowCategories = () => setShowCategory(true);

	const tableColumns = [
		{
			content: t('Name'),
			sortKey: 'title',
		},
		{
			content: t('Category'),
			sortKey: 'formCategoryTitle',
		},
		{
			content: t('Visibility'),
		},
		{
			content: t('Status'),
		},
		{
			content: t('Registrations'),
			sortKey: 'submissionCount',
		},
		{
			content: t('Created'),
			sortKey: 'createdAt',
		},
		{
			content: '',
			width: 'max-content',
		},
	];

	const emptyState = {
		title: t('No registrations'),
		content: t('No registrations found'),
		image: <AssetImage src="img/missing-entities/registration.svg" />,
		button: (
			<Button
				icon="add"
				primary
				href={routes.Registrations.Create(org.id, groupId)}>
				{t('Create Registration')}
			</Button>
		),
	};

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				searchFilter={filterGroups[t('Title')].filters.Title}
				filters={filters}
				pageActionIcon="more_horiz"
				actions={[
					{
						label: t('Categories'),
						onClick: handleShowCategories,
					},
					{
						label: t('Create'),
						href: routes.Registrations.Create(org.id, groupId),
						type: 'primary',
						testid: 'forms.create_new',
						icon: 'add',
					},
				]}
			/>

			<Table.Table
				isLoading={isLoading}
				sortBy={sort.column}
				sortOrder={sort.order}
				onSort={sort.setSort}
				emptyState={emptyState}
				columns={tableColumns}>
				{records.map((form) => (
					<Row
						key={form.id}
						groupId={groupId}
						form={form}
						replace={replaceRecord}
						remove={removeRecord}
					/>
				))}
			</Table.Table>

			<Pagination {...pagination} />
			{showCategory && (
				<FormCategoryModal groupId={groupId} onClose={handleCloseCategories} />
			)}
		</Fragment>
	);
};

export default ActiveForms;
