// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yFzAM5Ju5_0Y7VcT2DAj {
	text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/event-users/user-row/styles.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;AAC3B","sourcesContent":[".capitalized {\n\ttext-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
export var capitalized = `yFzAM5Ju5_0Y7VcT2DAj`;
export default ___CSS_LOADER_EXPORT___;
