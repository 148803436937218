import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';

export function formsServiceUrl(path: string = ''): string {
	const base = window.TS.config.formsServiceUrl.replace(/\/+$/, '');

	path = path.replace(/\/+/g, '/').replace(/^\/+|\/+$/g, '');

	return `${base}/${path}`;
}

/**
 * Send an api access token to the Forms service, and if successful,
 * redirect the user as well.
 *
 * @param token api access token string
 */
export async function authenticateWithToken(
	token: string,
	form?: models.form.Form
): Promise<string> {
	const parts = new URLSearchParams();
	parts.append('token', token);

	if (form) {
		parts.append('form', form.guid);
	}

	return `${formsServiceUrl('/login-callback')}?${parts.toString()}`;
}

type Authenticator = (
	accountId?: number,
	form?: Partial<models.form.Form>
) => Promise<string>;

export function useAuthenticator(): Authenticator {
	return async (accountId?: number, form?: models.form.Form) => {
		const authToken = await actions.auth.createOneTimeLoginToken(accountId);

		if (authToken) {
			const authedUrl = await authenticateWithToken(authToken, form);
			return authedUrl !== '' ? authedUrl : '/';
		}

		return '/';
	};
}
