import { t } from '@transifex/native';

import { PlayersWithoutConnectedParentsData } from 'pkg/api/dashboards/management';
import { pushState } from 'pkg/router/state';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import * as routes from 'pkg/router/routes';
import { FilterOperator } from 'pkg/filters';
import DateTime, { Granularity } from 'pkg/datetime';
import { useCurrentOrganization } from 'pkg/identity';

import * as DataCard from 'design/data_card';

interface ConnectedContactsProps {
	data: PlayersWithoutConnectedParentsData;
}

export default function ConnectedContacts({ data }: ConnectedContactsProps) {
	const now = new Date();
	const nowDateTime = new DateTime(now);
	const beforeDate = nowDateTime.prev(Granularity.year, 18);
	const org = useCurrentOrganization();

	const breakdownItems: DataCard.BreakdownItem[] = [
		{
			title: t('Under 18 years'),
			value: data.underAgedCount.toString(),
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Management.Contacts.Home(org.id), {
						user_group_roles: {
							operator: FilterOperator.Includes,
							values: ['user'],
						},
						parent_connection: {
							operator: FilterOperator.Equals,
							values: ['false'],
						},
						birth_date: {
							operator: FilterOperator.Between,
							values: [
								beforeDate.getUnixTimestamp(),
								nowDateTime.getUnixTimestamp(),
							],
						},
					})
				),
		},
	];

	return (
		<DataCard.Base
			title={t('Players without connected parents')}
			titleIcon="supervisor_account"
			heading={data.totalCount.toString()}
			headingSuffix={t('Total amount')}>
			<DataCard.Breakdown items={breakdownItems} />
		</DataCard.Base>
	);
}
