import { t } from '@transifex/native';
import styled from 'styled-components';
import { PointerEvent } from 'react';

import * as styles from 'pkg/config/styles';

import { useCurrentRoute } from 'pkg/router/hooks';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import GroupItem, {
	CoverTextContainer,
	CoverText,
	CoverType,
	GroupItemAction,
	GroupItemType,
} from 'components/library/GroupItem';

const TrainingCountContainer = styled.div`
	background: hsla(0, 0%, 0%, 0.25);
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 30%;
	display: grid;
	place-items: center;
	place-content: center;
	gap: var(--spacing-1);
	color: currentColor;
	z-index: 40;
`;

const TrainingCountValue = styled.span`
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
	}
`;

const TrainingCountLabel = styled.span`
	font-size: var(--font-size-sm);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}

	&:not(:last-child) {
		margin-bottom: var(--spacing-5);
	}
`;

interface TrainingCollectionGroupItemProps {
	href?: string;
	embedded?: boolean;
	onClick?: (
		item: models.trainingCollection.TrainingCollection,
		event?: PointerEvent<HTMLDivElement>
	) => void;
	item: models.trainingCollection.TrainingCollection;
	actions?: GroupItemAction<models.trainingCollection.TrainingCollection>[];
}

export default function TrainingCollectionGroupItem({
	href,
	embedded,
	item,
	actions,
	onClick,
}: TrainingCollectionGroupItemProps): JSX.Element {
	const org = useCurrentOrganization();
	const { groupId } = useCurrentRoute();
	const hasGroup = !!item.group;

	const handleClick = (event: PointerEvent<HTMLDivElement>) => {
		if (onClick) {
			onClick(item, event);
		}
	};

	let visibilityLabel = null;

	if (item.groupId) {
		visibilityLabel = t(`Team`);
	}

	if (item.groupId && item.inherit) {
		visibilityLabel = t(`Club`);
	}

	const brandColor = models.group.getPrimaryColorStylesheetString(
		item?.group as models.group.Group
	);

	const backdropColor = hasGroup ? brandColor : styles.palette.gray[500];
	const backdropImage = item.group?.profileImageUrl || null;

	if (!href) {
		href = routes.TrainingLibrary.Collection.Show(org.id, groupId, item.id);
	}

	let account = item.account;

	if (item.userCreated === false) {
		account = models.account.getCuratorAccount();
	}

	return (
		<GroupItem
			item={item}
			itemType={GroupItemType.TrainingCollection}
			actions={actions}
			accountAsByline
			account={account}
			title={item.name}
			createdAt={item.createdAt}
			isNew={models.trainingCollection.isNew(item)}
			isBranded={hasGroup}
			href={onClick ? undefined : href}
			visibilityLabel={visibilityLabel}
			backdropColor={backdropColor}
			embedded={embedded}
			onClick={handleClick}>
			{backdropImage && <img src={backdropImage} loading="lazy" />}
			<CoverTextContainer>
				<CoverType>
					<Icon name="folder-list" size={1.6} />
					{t(`Collection`)}
				</CoverType>
				<CoverText>{item.name}</CoverText>
			</CoverTextContainer>
			<TrainingCountContainer>
				<TrainingCountValue>{item.sessionCount || 0}</TrainingCountValue>
				<TrainingCountLabel>{t(`sessions`)}</TrainingCountLabel>

				<TrainingCountValue>{item.exerciseCount || 0}</TrainingCountValue>
				<TrainingCountLabel>{t(`exercises`)}</TrainingCountLabel>
			</TrainingCountContainer>
		</GroupItem>
	);
}
