import {
	applyMiddleware,
	createStore,
	compose,
	CombinedState,
	Store,
} from 'redux';

import rootReducer, { RootState } from 'pkg/reducers';

import thunk from './thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middlewares = applyMiddleware(thunk);

if (process.env.NODE_ENV !== 'production') {
	middlewares = composeEnhancers(middlewares);
}

const store: Store<CombinedState<RootState>> = ((preloadedState?: any) => {
	return createStore(rootReducer, preloadedState, middlewares);
})();

export default store;
