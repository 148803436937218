import { t } from '@transifex/native';

import { formatPriceIntervalToText } from 'pkg/utils';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { PaymentPreview } from 'pkg/api/models/payment_preview';
import { RecurringInterval } from 'pkg/api/models/provider_settings';

import SelectableCard from 'components/form/selectablecard';

interface Props {
	bundle: PaymentPreview;
	currency: string;
	isActive: boolean;
	onClick: (bundleNumber: number) => void;
}

const Bundle = ({ bundle, currency, isActive, onClick }: Props) => {
	const title = formatPriceIntervalToText(
		bundle.recurringInterval,
		bundle.recurringIntervalCount,
		true
	);

	const intervalMapping: { [key in RecurringInterval]: string } = {
		[RecurringInterval.Day]: t('Daily'),
		[RecurringInterval.Week]: t('Weekly'),
		[RecurringInterval.Month]: t('Monthly'),
		[RecurringInterval.Year]: t('Yearly'),
	};

	const intervalText = t('Then {interval} {recurringAmount}', {
		interval: intervalMapping[bundle.recurringInterval],
		recurringAmount: formatToCurrency(
			bundle.recurringPayment.salePrice / 100,
			currency
		),
	});

	const handleBundleClick = () => onClick(bundle.bundleNumber);

	const isSubscription =
		bundle.recurringIntervalCount != 0 && !bundle.totalInstallmentAmount;

	return (
		<SelectableCard
			key={bundle.bundleNumber}
			onClick={handleBundleClick}
			active={isActive}
			title={title}
			description={
				bundle.installmentCount
					? t('{nr} Installments', {
							nr: bundle.installmentCount,
						})
					: ''
			}
			infoTitle={t('Due today {cost}', {
				cost: formatToCurrency(bundle.initialPayment.salePrice / 100, currency),
			})}
			infoDescription={
				!isSubscription && bundle.recurringIntervalCount != 0 && intervalText
			}
			summaryTitle={
				isSubscription
					? `${intervalMapping[bundle.recurringInterval]} ${formatToCurrency(
							bundle.recurringPayment.salePrice / 100,
							currency
						)}`
					: bundle.recurringIntervalCount
						? `${formatToCurrency(bundle.totalInstallmentAmount / 100, currency)}`
						: `${formatToCurrency(
								bundle.initialPayment.salePrice / 100,
								currency
							)}`
			}
			summaryDescription={t('Total')}
		/>
	);
};

export default Bundle;
