import { useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { cssVarList } from 'pkg/css/utils';
import useMixedState from 'pkg/hooks/useMixedState';

import * as StepModal from 'components/step-modal';

import InfoBox from 'components/form/info-box';
import ResourcesTable from 'components/resources/table';
import * as ActionBar from 'components/layout/ActionBar';
import SearchInput from 'components/form/SearchInput';
import Row from 'components/layout/row';
import * as Inputs from 'components/form/inputs';

import { ExtraColumn } from 'design/table';

import * as css from './styles.css';

const _context = 'scheduling/resources';

interface ConflictItemProps {
	conflicts: number[];

	resource?: models.resource.Resource;
}

export const ConflictItem = ({ conflicts, resource }: ConflictItemProps) => {
	const isConflicting = conflicts.includes(resource.id);
	const cssVariables = cssVarList({
		color: isConflicting ? styles.palette.red[400] : styles.palette.green[400],
	});

	return (
		<Row align="center" spacing={styles.spacing._3} className={css.row}>
			<div style={cssVariables} className={css['status-circle']} />
			<div style={cssVariables} className={css['status-text']}>
				{isConflicting ? t('Unavailable') : t('Available')}
			</div>
		</Row>
	);
};

interface MainResourceRadioProps {
	primaryResourceId: number;

	resource?: models.resource.Resource;
}

export const MainResourceRadio = ({
	primaryResourceId,
	resource,
}: MainResourceRadioProps) => {
	return (
		<Inputs.Control
			checked={resource.id === primaryResourceId}
			type="radio"
			standalone
		/>
	);
};

export enum SelectableOptions {
	single = 'single',
	multi = 'multi',
}

interface SelectResourceProps {
	groupId: number;
	// Determines whether the modal should allow multi selection or single
	selectable: SelectableOptions;
	// Populate with resource ids you want to be pre selected
	preSelectedResources?: number[];
	// Populate primaryResourceId
	primaryResourceId?: number;

	// Resources that will be listed
	resources: models.resource.Resource[];

	// resource ids that is booked
	extraColumns?: ExtraColumn[];

	// Option to show set primary resource radio
	setPrimaryResource?: boolean;

	// Function that runs on Step modals onClose
	hideModal: () => void;
}

interface State {
	selectedResources: number[];
	primaryResourceId: number;
}

const useSelectResource = ({
	groupId,
	resources,
	preSelectedResources = [],
	selectable,
	hideModal,
	primaryResourceId = null,
	setPrimaryResource = false,
	extraColumns = [],
}: SelectResourceProps) => {
	const { records: categories, refresh: refreshCategories } =
		useCollection<models.resourceCategory.ResourceCategory>(
			endpoints.ResourceCategory.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);

	const [state, setState] = useMixedState<State>({
		selectedResources: preSelectedResources,
		primaryResourceId,
	});
	const [search, setSearch] = useState('');

	const handleSearch = (value: string) => {
		setSearch(value);
	};

	const select = (resourceId: number) => {
		if (selectable === SelectableOptions.single) {
			setState({
				selectedResources: state.selectedResources.includes(resourceId)
					? []
					: [resourceId],
			});
		} else {
			const stateIncludesResource =
				state.selectedResources.includes(resourceId);

			let primaryResourceId = state.primaryResourceId;

			if (!!!state.selectedResources.length) {
				primaryResourceId = resourceId;
			}

			if (state.primaryResourceId === resourceId) {
				primaryResourceId =
					state.selectedResources.length === 1
						? null
						: state.selectedResources.filter((id) => id !== resourceId)[0];
			}

			setState({
				selectedResources: stateIncludesResource
					? state.selectedResources.filter((id) => id !== resourceId)
					: [...state.selectedResources, resourceId],
				primaryResourceId,
			});
		}
	};

	const selectPrimaryResource = (resource: models.resource.Resource) => {
		if (!state.selectedResources.includes(resource.id)) {
			setState({
				selectedResources: [...state.selectedResources, resource.id],
				primaryResourceId: resource.id,
			});
		} else {
			setState({
				primaryResourceId: resource.id,
			});
		}
	};

	if (setPrimaryResource) {
		extraColumns.push({
			content: t('Main resource', { _context }),
			onClick: selectPrimaryResource,
			component: (
				<MainResourceRadio primaryResourceId={state.primaryResourceId} />
			),
		});
	}

	return {
		selectedResources: state.selectedResources,
		primaryResource: state.primaryResourceId,
		Modal: (
			<StepModal.Base onClose={hideModal} wide>
				<StepModal.Step
					skipBody
					title={t('Select resource', { _context })}
					nextLabel={t('Done')}>
					<ActionBar.Bar>
						<ActionBar.PrimaryAction>
							<SearchInput
								small
								placeholder={t('Search resources', { _context })}
								onChange={handleSearch}
							/>
						</ActionBar.PrimaryAction>
					</ActionBar.Bar>
					{setPrimaryResource && (
						<div className={css['info-wrapper']}>
							<InfoBox
								text={t(
									'Setting the main resource determines which location to use for the booking',
									{ _context }
								)}
							/>
						</div>
					)}
					<ResourcesTable
						resources={resources}
						selectable={selectable}
						select={select}
						selected={state.selectedResources}
						search={search}
						hideContextColumn={true}
						extraColumns={extraColumns}
						categories={categories}
						refreshCategories={refreshCategories}
					/>
				</StepModal.Step>
			</StepModal.Base>
		),
		select,
		selectPrimaryResource,
	};
};

export default useSelectResource;
