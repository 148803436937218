import { useEffect } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';
import DateTime from 'pkg/datetime';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import useConfirm from 'pkg/hooks/useConfirm';
import { useCurrentAccount, useFetchIdentity } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import { AccountRelationTypes } from 'pkg/api/models/account_relation';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import WardCard from 'routes/public/no-memberships/styles/WardCard';
import Create from 'routes/public/no-memberships/styles/Create';
import PendingChildRequestCard from 'routes/public/no-memberships/styles/PendingChildRequestCard';
import Title from 'routes/public/styles/Title';
import PendingParentRequestCard from 'routes/public/no-memberships/styles/PendingParentRequestCard';
import Header from 'routes/public/no-memberships/styles/Header';
import * as formStyles from 'routes/public/styles/forms/styles.css';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

interface FetchAccountRelationsResponse {
	pendingChildren?: models.accountRelation.AccountRelation[];
	pendingParents?: models.accountRelation.AccountRelation[];
	wards?: models.accountRelation.AccountRelation[];
}

export function useFetchAccountRelations(): FetchAccountRelationsResponse {
	const { records: accountRelations } =
		useCollection<models.accountRelation.AccountRelation>(
			endpoints.AccountRelations.Index()
		);

	const pendingChildren = accountRelations?.filter(
		(accountRelation: models.accountRelation.AccountRelation) =>
			accountRelation.type === AccountRelationTypes.LegalGuardian &&
			!accountRelation.approvedAt
	);

	const pendingParents = accountRelations?.filter(
		(accountRelation: models.accountRelation.AccountRelation) =>
			accountRelation.type === AccountRelationTypes.Ward &&
			!accountRelation.approvedAt
	);

	const wards = accountRelations?.filter(
		(accountRelation: models.accountRelation.AccountRelation) =>
			accountRelation.type === AccountRelationTypes.LegalGuardian &&
			accountRelation.approvedAt
	);

	return { pendingChildren, pendingParents, wards };
}

export default function Start(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const fetchIdentity = useFetchIdentity();

	useComponentDidMount(async () => {
		// Make sure we start with a clean state
		onboardingState.flush();
		await fetchIdentity();
	});

	const { pendingChildren, pendingParents, wards } = useFetchAccountRelations();

	const currentAccount = useCurrentAccount();

	useEffect(() => {
		if (currentAccount.needsParentalConsent) {
			goTo('gdpr-request-pending');
		}
	}, [currentAccount.id]);

	const accountUnderDeletion = currentAccount.willBeDeletedAt;

	const willBeDeletedAt = new DateTime(
		new Date(currentAccount.willBeDeletedAt * 1000)
	).toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	const handleJoinGroup = async () => {
		if (!!wards.length) {
			goTo('new-group-connection');
		} else {
			await onboardingState.set({ account: currentAccount });

			goTo('group-code');
		}
	};

	const handleCancelDeletion = useConfirm({
		message: t('Do you want to cancel your account deletion?'),
		onConfirm: async () => {
			await models.account.cancelDelete(
				currentAccount as unknown as models.account.Account
			);
		},
	});

	let title: string;
	let description: string;

	if (pendingChildren?.length) {
		// Pending children or group (staff) requests
		title = t('Request sent, time to wait!');
		description = t(
			'Your request has been sent and is awaiting approval. As soon as it is approved you will automatically be redirected to the app.'
		);
	} else {
		// No pending requests
		title = t('Welcome back, {name}!', {
			name: models.account.fullName(currentAccount),
		});
		if (models.account.isAnAdult(currentAccount)) {
			description = t(
				'You are not a part of any team or group. Connect to a child or join a new group using a group code to continue.'
			);
		} else {
			description = t(
				'You are not a part of any team or group. Join a group with a group code to continue.'
			);
		}
	}

	return (
		<Column spacing={styles.spacing._8}>
			<Header />

			{accountUnderDeletion && (
				<InfoBox color="orange">
					<p>
						{t('Your account will be deleted at {date}.', {
							date: willBeDeletedAt,
						})}
					</p>

					<p>
						<Link onClick={handleCancelDeletion}>{t('Cancel deletion')}</Link>
					</p>
				</InfoBox>
			)}

			<Title title={title} description={description} thinDescription />

			{!!pendingParents.length && (
				<Column>
					<span className={formStyles.label}>{t('Parent connections')}</span>

					{pendingParents.map(
						(relation: models.accountRelation.AccountRelation) => (
							<PendingParentRequestCard relation={relation} key={relation.id} />
						)
					)}
				</Column>
			)}

			{models.account.isAnAdult(
				currentAccount as unknown as models.account.Account
			) && (
				<Column>
					<span className={formStyles.label}>{t('Child connections')}</span>

					{pendingChildren.map(
						(relation: models.accountRelation.AccountRelation) => (
							<PendingChildRequestCard relation={relation} key={relation.id} />
						)
					)}

					{wards.map((relation: models.accountRelation.AccountRelation) => (
						<WardCard relation={relation} key={relation.id} />
					))}

					<Create
						label={t('New child connection')}
						description={t(
							'If you are a coach or a player in a group you can join here'
						)}
						onClick={() => goTo('new-ward-connection')}
						testid="no_memberships.create_child_connection_button"
					/>
				</Column>
			)}

			<Column>
				<span className={formStyles.label}>{t('Groups')}</span>

				<Create
					label={t('Join as a coach or player')}
					description={t(
						'If you are a coach or a player in a group you can join here'
					)}
					onClick={handleJoinGroup}
					testid="no_memberships.join_group_button"
				/>
			</Column>
		</Column>
	);
}
