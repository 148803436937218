import { useState, useEffect, Fragment } from 'react';
import { List } from 'immutable';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import UserGoal from 'pkg/models/user_goal';

import { RootState } from 'pkg/reducers';
import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import { useCheckRelationToUser, useUser } from 'pkg/hooks/selectors';

import GoalList from 'containers/user/profile/development/goals/List';

import TabSwitcher from 'components/TabSwitcher';
import AssetImage from 'components/AssetImage';
import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import { UserGoalForm } from 'components/user/profile/development/goals/Form';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';
import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

const Buttons = styled.div`
	justify-self: end;
`;

type Props = {
	groupId: number;
	userId: number;
	setPageActions: (trigger: JSX.Element) => void;
};

const UserGoals = ({ groupId, userId, setPageActions }: Props): JSX.Element => {
	const ACTIVE: string = 'active';
	const COMPLETED: string = 'completed';

	const [loading, setLoading] = useState(true);
	const [showGoalForm, setShowGoalForm] = useState(false);
	const [activeTab, setActiveTab] = useState(ACTIVE);

	const dispatch = useDispatch();

	const user = useUser(userId);

	const activeGoals: List<UserGoal> = useSelector((state: RootState) =>
		activeTab === ACTIVE
			? selectors.userGoals.findAllUserActiveGoals(state, userId)
			: selectors.userGoals.findAllUserCompletedGoals(state, userId)
	);

	const { isParentToUser } = useCheckRelationToUser(userId);
	const forAccount = isParentToUser ? user.accountId : null;

	useEffect(() => {
		setPageActions(
			<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
				<ContextMenu.Item onClick={openForm}>
					{t('Add goal', {
						_context: 'user profile development',
					})}
				</ContextMenu.Item>
			</ContextMenu.Menu>
		);

		return () => {
			setPageActions(null);
		};
	}, []);

	useEffect(() => {
		if (activeGoals.size === 0 && activeTab === ACTIVE) {
			setActiveTab(COMPLETED);
		}
	}, [activeGoals]);

	useEffect(() => {
		(async () => {
			await dispatch(actions.userGoals.fetchUserGoals(userId, 1, forAccount));
			setLoading(false);
		})();
	}, [dispatch, groupId, userId]);

	const handleToggleTab = (): void => {
		if (activeTab === ACTIVE) {
			setActiveTab(COMPLETED);
		} else setActiveTab(ACTIVE);
	};

	const saveGoal = async (goal: UserGoal): Promise<void> => {
		await dispatch(actions.userGoals.createGoal(userId, goal, forAccount));
		setShowGoalForm(false);

		if (activeTab === COMPLETED) {
			setActiveTab(ACTIVE);
		}
	};

	const openForm = (): void => setShowGoalForm(true);

	const closeForm = (): void => setShowGoalForm(false);

	const showMissingEntities: boolean = activeGoals.size === 0;

	return (
		<Column>
			<Row columns="1fr auto 1fr">
				<span></span>
				<TabSwitcher
					dark
					active={activeTab}
					onChange={handleToggleTab}
					tabs={{
						active: t('Active'),
						completed: t('Completed'),
					}}
				/>
				<Buttons>
					<LargeScreen>
						<Button
							inline
							noPadding
							icon="add"
							label={t('Add goal', {
								_context: 'user profile development',
							})}
							onClick={openForm}
							testid="goals.add_goal_button"
						/>
					</LargeScreen>
				</Buttons>
			</Row>
			{!loading ? (
				<Fragment>
					{showMissingEntities && (
						<MissingEntities
							title={
								activeTab === ACTIVE
									? t('Set up goals to track individual progress!')
									: t('You have not completed any goals yet!')
							}
							description={t(
								'With individual goals you can track progress in an easy and structured way.'
							)}
							actions={
								<Button
									icon="add"
									secondary
									label={t('Create new goal', {
										_context: 'user profile development',
									})}
									onClick={openForm}
								/>
							}
							image={<AssetImage src="img/missing-entities/goals.svg" />}
						/>
					)}
					<GoalList goals={activeGoals} />
				</Fragment>
			) : (
				<Spinner />
			)}
			{showGoalForm && <UserGoalForm saveGoal={saveGoal} close={closeForm} />}
		</Column>
	);
};

export default UserGoals;
