import { t } from '@transifex/native';
import { ChangeEvent, useEffect, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Account, Group } from 'pkg/api/models/onboarding_info';
import { useEndpoint } from 'pkg/api/use_endpoint';
import useMixedState from 'pkg/hooks/useMixedState';
import { yearsSince } from 'pkg/date';
import { findCountryByCode } from 'pkg/countries';

import Select from 'routes/public/styles/inputs/Select';
import { OnboardingSlug, useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Field from 'routes/public/styles/inputs/field';
import Form from 'routes/public/styles/forms';
import Label from 'routes/public/styles/Label';
import Button from 'routes/public/styles/Button';
import AvatarEditor from 'routes/public/styles/AvatarEditor';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

interface UserInformationFormProps {
	goToSlug: OnboardingSlug;
}

export default function UserInformationForm({
	goToSlug = '',
}: UserInformationFormProps): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const [isSaving, setIsSaving] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [parentalConsentEmail, setParentalConsentEmail] = useState<string>('');

	const accountFromState = onboardingState.get<Account>('account');
	const group = onboardingState.get<Group>('group');

	const { isLoading: isLoadingCountries, record: countries } = useEndpoint<
		models.country.Country[]
	>(endpoints.Country.Index());

	const sortedCountries = Array.from(countries || []).sort(
		(a: models.country.Country, b: models.country.Country) =>
			a.name.localeCompare(b.name)
	);

	const defaultCountry = `${
		accountFromState?.countryId || group?.country?.id || countries[0]?.id
	}`;

	const [payload, setPayload] = useMixedState<Account>({
		...accountFromState,
	});

	useEffect(() => {
		if (!isLoadingCountries && !payload.countryId) {
			setPayload({ countryId: Number(defaultCountry) });
		}
	}, [isLoadingCountries]);

	const handleAvatarChange = (image: string) => {
		setAvatar(image);
		setPayload({ profileImage: image });
	};

	const country = sortedCountries.find(
		(country: models.country.Country) =>
			payload.countryId?.toString() === country.id.toString()
	);

	const needsGDPRConsent = () => {
		const consentAgeLimit = country?.consentAgeLimit;

		if (!consentAgeLimit) {
			return false;
		}

		const birthDate = new Date(payload.year, payload.month, payload.day);

		return yearsSince(birthDate) < consentAgeLimit;
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPayload({ [event.target.name]: event.target.value });
	};

	const handleInputNumberChange = (
		event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
	) => {
		setPayload({ [event.target.name]: Number(event.target.value) });
	};

	const handleParentalConsentEmailChange = (
		event: ChangeEvent<HTMLInputElement>
	) => {
		setParentalConsentEmail(event.target.value);
	};

	const handleSave = async () => {
		setIsSaving(true);

		if (avatar) {
			payload.profileImage = avatar;
		}

		onboardingState.set({ account: payload, parentalConsentEmail });

		goTo(goToSlug);
	};

	const avatarUrl = avatar
		? `data:image/png;base64, ${avatar}`
		: payload?.profileImage
			? `data:image/png;base64, ${payload.profileImage}`
			: '';

	return (
		<Form onSubmit={handleSave}>
			<Column spacing={styles.spacing._7}>
				<Column>
					<AvatarEditor avatar={avatarUrl} onSave={handleAvatarChange} />

					<Field
						required
						name="firstName"
						placeholder={t('Your first name')}
						label={t('First name')}
						errorMessage={t('First name is required')}
						value={payload.firstName}
						onChange={handleInputChange}
						tabIndex={1}
					/>

					<Field
						required
						name="lastName"
						placeholder={t('Your last name')}
						label={t('Last name')}
						errorMessage={t('Last name is required')}
						minLength={0}
						value={payload.lastName}
						onChange={handleInputChange}
						tabIndex={2}
					/>

					{sortedCountries.length > 0 && (
						<Select
							name="countryId"
							value={payload.countryId?.toString()}
							onChange={handleInputNumberChange}
							tabIndex={3}>
							<option disabled value="">
								{t('Select country')}
							</option>
							{sortedCountries.map((item: models.country.Country) => (
								<option key={item.id} value={item.id.toString()}>
									{findCountryByCode(item.code).country}
								</option>
							))}
						</Select>
					)}

					<Column>
						<Label>{t('Your date of birth')}</Label>
						<Row autoColumns="1fr 1fr 1fr">
							<Field
								required
								name="day"
								type="number"
								placeholder={t('DD')}
								label={t('Day')}
								min={1}
								max={31}
								validateOnChange
								pattern="^([1-9]|[12][0-9]|3[01])$"
								inputMode="numeric"
								value={payload.day?.toString()}
								onChange={handleInputNumberChange}
								tabIndex={4}
							/>
							<Field
								required
								name="month"
								type="number"
								placeholder={t('MM')}
								label={t('Month')}
								min={1}
								max={12}
								validateOnChange
								pattern="^([1-9]|1[0-2])$"
								inputMode="numeric"
								value={payload.month?.toString()}
								onChange={handleInputNumberChange}
								tabIndex={5}
							/>
							<Field
								required
								name="year"
								type="number"
								placeholder={t('YYYY')}
								label={t('Year')}
								min={1900}
								validateOnChange
								inputMode="numeric"
								max={new Date().getFullYear()}
								value={payload.year?.toString()}
								onChange={handleInputNumberChange}
								tabIndex={6}
							/>
						</Row>
					</Column>

					{needsGDPRConsent() && (
						<Column>
							<InfoBox>
								{t(
									`Since you are a minor we require your parent's consent to continue. Please enter your parent's email address:`
								)}
							</InfoBox>
							<Field
								required
								name="parentalConsentEmail"
								type="email"
								placeholder={t(`Your parent's email`)}
								value={parentalConsentEmail}
								onChange={handleParentalConsentEmailChange}
								tabIndex={7}
								inputMode="email"
							/>
						</Column>
					)}
				</Column>

				<Button primary type="submit" label={t('Continue')} busy={isSaving} />
			</Column>
		</Form>
	);
}
