// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swI7F8jiMlpveB6P9ag3 {
	padding: var(--spacing-3) var(--spacing-4);
	background: var(--palette-red-200);
	color: var(--palette-red-600);
	border-radius: var(--spacing-3);
	position: fixed;
	bottom: var(--spacing-8);
	right: var(--spacing-8);
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
	grid-template-columns: auto 1fr;
	grid-gap: var(--spacing-3);
	gap: var(--spacing-3);
}
@media (max-width: 768px) {
	.swI7F8jiMlpveB6P9ag3 {
		font-size: var(--font-size-xs);
		bottom: auto;
		right: auto;
		left: 50%;
		min-width: -moz-max-content;
		min-width: -webkit-max-content;
		min-width: max-content;
		top: calc(var(--spacing-7) + var(--sat));
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
}
	}
`, "",{"version":3,"sources":["webpack://./pkg/websocket/styles.css"],"names":[],"mappings":"AAAA;CACC,0CAA0C;CAC1C,kCAAkC;CAClC,6BAA6B;CAC7B,+BAA+B;CAC/B,eAAe;CACf,wBAAwB;CACxB,uBAAuB;CACvB,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;CACrB,+BAA+B;CAC/B,0BAAqB;CAArB,qBAAqB;AAWtB;AATC;CAdD;EAeE,8BAA8B;EAC9B,YAAY;EACZ,WAAW;EACX,SAAS;EACT,2BAAsB;EAAtB,8BAAsB;EAAtB,sBAAsB;EACtB,wCAAwC;EACxC,mCAA2B;UAA3B,2BAA2B;AAE7B;CADC","sourcesContent":[".errorMessage {\n\tpadding: var(--spacing-3) var(--spacing-4);\n\tbackground: var(--palette-red-200);\n\tcolor: var(--palette-red-600);\n\tborder-radius: var(--spacing-3);\n\tposition: fixed;\n\tbottom: var(--spacing-8);\n\tright: var(--spacing-8);\n\tdisplay: grid;\n\tplace-items: center;\n\tplace-content: center;\n\tgrid-template-columns: auto 1fr;\n\tgap: var(--spacing-3);\n\n\t@media (--small-viewport) {\n\t\tfont-size: var(--font-size-xs);\n\t\tbottom: auto;\n\t\tright: auto;\n\t\tleft: 50%;\n\t\tmin-width: max-content;\n\t\ttop: calc(var(--spacing-7) + var(--sat));\n\t\ttransform: translateX(-50%);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var errorMessage = `swI7F8jiMlpveB6P9ag3`;
export default ___CSS_LOADER_EXPORT___;
