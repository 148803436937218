import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as routes from 'pkg/router/routes';
import { isDev, availableFlags } from 'pkg/flags';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';
import store from 'pkg/store/createStore';
import { useCurrentAccount } from 'pkg/identity';
import { usePushNotificationSender } from 'pkg/notifications';

import { DebugModal } from 'containers/debug/Debug';

import MaterialSymbol from 'components/material-symbols';

import * as Input from 'components/form/inputs';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './style.css';

export function FlagToggle({ flagName }: { flagName: string }): JSX.Element {
	const flagKey = `ts:flag:${flagName}`;
	const flag = (localStorage.getItem(flagKey) ?? 'false') === 'true';

	let flagLabel = flagName
		.replace(/([A-Z]+)/g, ' $1')
		.replace(/([A-Z][a-z])/g, ' $1');
	flagLabel = flagLabel.charAt(0).toUpperCase() + flagLabel.slice(1);

	const [checked, set] = useState<boolean>(flag);

	const toggle = () => {
		const next = !checked;
		set(next);

		localStorage.setItem(flagKey, next.toString());
	};

	return (
		<Context.Item
			tight
			closeOnClick={false}
			onClick={toggle}
			className={css.itemWithToggle}>
			<Context.ItemIcon name="flag" />
			<span>{flagLabel}</span>
			<Input.Control standalone type="toggle" checked={checked} />
		</Context.Item>
	);
}

export default function Menu(): JSX.Element {
	const dispatch = useDispatch();
	const darkMode = useSelector((state: RootState) => state.app.darkMode);
	const currentAccount = useCurrentAccount();
	const sendPushNotification = usePushNotificationSender();

	const [showingDebugModal, setShowDebugModal] = useState<boolean>(false);

	const actAsAccounts: string[] = [
		'clubpres@360player.local',
		'coach@360player.local',
		'academy@360player.local',
		'staff@360player.local',
		'curator@360player.local',
		'parent@360player.local',
		'child@360player.local',
		'needs_consent@360player.local',
		'360admin@360player.local',
	];

	const showDebugModal = () => setShowDebugModal(true);
	const hideDebugModal = () => setShowDebugModal(false);

	const toggleDarkMode = () => dispatch(actions.app.setDarkMode(!darkMode));

	const logInAs = async (email: string) => {
		await actions.auth.logout()(store.dispatch);
		await actions.auth.passwordAuth(email, 'password', 'en-US', true);
	};

	/**
	 * @todo Set up logic to test PN dynamically
	 */
	const fakePushNotification = () => {
		sendPushNotification({
			id: 'pn:test',
			title: 'Fake Push Notification',
			body: "Hello there! I'm just a little test notification!",
			data: {
				'organization-id': 1,
				'resource-type': 'groupHome',
				'resource-id': 1,
				'trigger-id': 1,
				'target-id': 1,
			},
		});
	};

	if (!isDev()) return null;

	return (
		<Fragment>
			<Context.Menu
				className={css.developerMenu}
				toggleWith={
					<span className={css.developerMenuToggle}>
						<MaterialSymbol variant="settings" scale={1.6} />
					</span>
				}>
				<Context.Label>
					Flags{' '}
					<Button
						small
						inline
						className={css.inlineAction}
						onClick={() => window.location.reload()}>
						Apply
					</Button>
				</Context.Label>

				<Context.Item
					tight
					closeOnClick={false}
					onClick={toggleDarkMode}
					className={css.itemWithToggle}>
					<Context.ItemIcon name={darkMode ? 'light_mode' : 'dark_mode'} />
					<span>Dark Mode</span>
					<Input.Control standalone type="toggle" checked={darkMode} />
				</Context.Item>

				{availableFlags.map((flagName: string) => (
					<FlagToggle key={flagName} flagName={flagName} />
				))}

				<Context.Divider />

				<Context.Item icon="settings_b_roll" onClick={showDebugModal}>
					<span>
						Data Generator
						<span style={{ color: 'var(--palette-gray-500)' }}>(Debug)</span>
					</span>
				</Context.Item>

				<Context.Item
					icon="notifications_active"
					onClick={fakePushNotification}>
					<span>Send Fake Push Notification</span>
				</Context.Item>

				<Context.Divider />

				<Context.Label>Switch Account</Context.Label>

				{actAsAccounts.map((email: string) => (
					<Context.Item
						key={email}
						tight
						onClick={() => logInAs(email)}
						className={email === currentAccount.email ? css.activeItem : null}>
						{email}
					</Context.Item>
				))}

				<Context.Divider />

				<Context.Label>Documentation</Context.Label>
				<Context.LinkItem icon="faucet" href={routes.Debug.KitchenSink()}>
					Kitchen Sink
				</Context.LinkItem>
				<Context.LinkItem
					icon="webhook"
					href="https://ts-api-docs.azurewebsites.net">
					Threesixty API
				</Context.LinkItem>
			</Context.Menu>
			{showingDebugModal && <DebugModal onClose={hideDebugModal} />}
		</Fragment>
	);
}
