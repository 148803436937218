import { useT } from '@transifex/react';

import { CancelSubscriptionPayload } from 'pkg/actions/subscriptions';

import DateTime from 'pkg/datetime';
import { getIncrementedTimestamp, isToday } from 'pkg/date';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import { SubscriptionTypes } from 'pkg/api/models/subscription';
import { useCurrentGroup } from 'pkg/identity';

import StepModal, { Step } from 'components/step-modal';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import DatePicker from 'components/form/date-picker';
import InfoBox from 'components/form/info-box';

interface CancelSubscriptionModalProps {
	subscription?: models.subscription.Subscription;
	subscriptionIds?: number[];
	hideModal: () => void;
}

const CancelSubscriptionModal: React.FC<
	React.PropsWithChildren<CancelSubscriptionModalProps>
> = ({ subscriptionIds, subscription, hideModal }) => {
	const t = useT();
	const group = useCurrentGroup();
	const isPurspot =
		group.paymentProvider === models.group.PaymentProvider.Purspot;
	const isInstallment =
		subscription && subscription.type === SubscriptionTypes.Installment;

	const showImmediateCancellation = isPurspot || isInstallment;

	const [form, setForm] = useMixedState({
		cancelType: showImmediateCancellation ? 'immediately' : 'atPeriodEnd',
		customDate: new Date(),
	});

	const endAt = subscription.currentPeriodEnd
		? new DateTime(new Date(subscription.currentPeriodEnd * 1000))
		: DateTime.fromTimestamp(
				getIncrementedTimestamp(
					subscription.recurringInterval,
					subscription.startAt * 1000,
					subscription.recurringIntervalCount
				) / 1000
			);

	const now = new DateTime(new Date());
	const endAtCycle = endAt.toLocaleDateString({
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});

	const handleRadioChange = (value: string, target: HTMLInputElement) =>
		setForm({ [target.name]: value });

	const handleDateChange = (dates: Date[]) => setForm({ customDate: dates[0] });

	const handleNext = async () => {
		const cancelPayload: CancelSubscriptionPayload = {
			ids: !!subscription ? [subscription.id] : subscriptionIds,
		};

		if (form.cancelType === 'atPeriodEnd') {
			cancelPayload.date = endAt.getUnixTimestamp();
		} else if (form.cancelType === 'custom') {
			const d = new DateTime(new Date(form.customDate));

			if (!isToday(d)) {
				cancelPayload.date = new DateTime(
					new Date(form.customDate)
				).getUnixTimestamp();
			}
		}

		const req = actions.subscriptions.cancel(cancelPayload);

		if (req) {
			return true;
		}

		return false;
	};

	return (
		<StepModal onClose={hideModal}>
			<Step
				title={t('Cancel subscription')}
				onNext={handleNext}
				nextLabel={showImmediateCancellation ? t('Confirm') : t('Finish')}>
				{showImmediateCancellation ? (
					<Column>
						{t(
							'This action will cancel the subscription immediately and no more invoices will be generated. Do you want to continue?'
						)}
						{isInstallment && (
							<InfoBox color="gray">
								{t(
									'If you would like to adjust the amount of installments you can make an update to the subscription with the new amount of installments.'
								)}
							</InfoBox>
						)}
					</Column>
				) : (
					<Column>
						<Input.Control
							name="cancelType"
							type="radio"
							value="atPeriodEnd"
							onChange={handleRadioChange}
							label={t('End of current billing period ({endAtCycle})', {
								endAtCycle,
							})}
							checked={form.cancelType === 'atPeriodEnd'}
						/>
						<Input.Control
							name="cancelType"
							type="radio"
							value="immediately"
							onChange={handleRadioChange}
							label={t('Immediately ({date})', {
								date: now.toLocaleDateString({
									year: 'numeric',
									month: 'short',
									day: 'numeric',
								}),
							})}
							checked={form.cancelType === 'immediately'}
						/>
						<Input.Control
							name="cancelType"
							type="radio"
							value="custom"
							onChange={handleRadioChange}
							label={t('Custom date')}
							checked={form.cancelType === 'custom'}
						/>
						{form.cancelType === 'custom' && (
							<DatePicker
								dates={[form.customDate]}
								disableDatesBefore={new Date()}
								handleChange={handleDateChange}
							/>
						)}
					</Column>
				)}
			</Step>
		</StepModal>
	);
};

export default CancelSubscriptionModal;
