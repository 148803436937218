import { t } from '@transifex/native';

import { Capabilities } from 'pkg/models/membership';
import { Features } from 'pkg/models/group';

import { UrlParser } from 'pkg/url';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';

import { IconName } from 'components/icon';
const url = new UrlParser();

interface MenuItem {
	name: string;
	properties: {
		key: string;
		href: string;
		icon: string;
		slug: string;
	};
}

interface MenuItemProperties {
	href: string;
	name: string;
	icon: IconName;
	slug?: string;
	show?: boolean;
}

const actionMenuItems = (
	organizationId: number,
	activeMembership: models.membership.Membership,
	groupSport: models.sport.Sport,
	group: models.group.Group
): MenuItem[] => {
	const isPlayer =
		models.membership.isPlayer(activeMembership) || !activeMembership.groupId;
	const isAdminOrStaff = models.membership.isAdminOrStaff(activeMembership);
	const hasGroups = !!activeMembership.groupId;
	const menuItems: MenuItemProperties[] = [];

	if (isAdminOrStaff) {
		menuItems.push(
			{
				href: '',
				name: t(`Post`),
				icon: 'add-post',
				slug: 'action-post',
				show: models.group.hasFeature(group, Features.Posts),
			},
			{
				href: routes.Event.Create(organizationId),
				name: t(`Event`),
				icon: 'nav-events',
				show: models.group.hasFeature(group, Features.Calendar),
			},
			{
				href: routes.Organization.Development.Assessments(
					organizationId,
					group.id
				),
				name: t(`Assessment`),
				icon: 'nav-assess',
				slug: 'action-assessment',
				show:
					models.sport.hasRatingsEnabled(groupSport) &&
					models.group.hasFeature(group, Features.Assessments),
			},
			{
				href: routes.Organization.Development.PerformanceReviews(
					organizationId,
					group.id
				),
				name: t(`Dialogue`),
				icon: 'nav-dialogue',
				slug: 'action-dialogue',
				show: models.group.hasFeature(group, Features.IndividualDevelopment),
			},
			{
				href: '',
				name: t(`Video`),
				icon: 'videos',
				slug: 'action-video',
				show: models.group.hasFeature(group, Features.Video),
			},
			{
				href: routes.Exercise.Create(organizationId),
				name: t(`Exercise`),
				icon: 'library',
				slug: 'action-exercise',
				show: models.group.hasFeature(group, Features.TrainingLibrary),
			},
			{
				href: routes.TrainingSession.Create(organizationId),
				name: t(`Session`),
				icon: 'nav-attendance',
				show: models.group.hasFeature(group, Features.TrainingLibrary),
			}
		);
	}

	if (
		models.membership.hasCapability(
			activeMembership,
			Capabilities.PaymentAdmin
		) &&
		models.group.isOrganization(group)
	) {
		menuItems.push(
			{
				href: routes.Management.Contact.Create(organizationId),
				name: t(`Contact`),
				icon: 'nav-profile',
			},
			{
				href: routes.Product.Create(organizationId),
				name: t(`Product`),
				icon: 'tag',
				show: models.group.hasFeature(group, Features.Payments),
			},
			{
				href: routes.Invoice.New(organizationId),
				name: t(`Order`),
				icon: 'file-generic',
				show: models.group.hasFeature(group, Features.Payments),
			},
			{
				href: '',
				name: t(`Group`),
				icon: 'nav-organisation',
				slug: 'action-create-group',
				show:
					models.membership.hasCapability(
						activeMembership,
						Capabilities.Sport
					) && isAdminOrStaff,
			}
		);
	}

	if (isPlayer) {
		if (hasGroups) {
			menuItems.push({
				href: '',
				name: t(`Post`),
				icon: 'add-post',
				slug: 'action-post',
				show: models.group.hasFeature(group, Features.Posts),
			});
		} else {
			menuItems.push({
				href: '',
				name: t(`Join group`),
				icon: 'user-add',
				slug: 'group-code',
			});
		}

		menuItems.push(
			{
				href: '',
				name: t(`Training log`),
				icon: 'flash',
				slug: 'action-training',
				show: models.group.hasFeature(group, Features.TrainingLibrary),
			},
			{
				href: '',
				name: t(`Assessment`),
				icon: 'nav-assess',
				slug: 'action-assessment',
				show:
					models.sport.hasRatingsEnabled(groupSport) &&
					models.group.hasFeature(group, Features.Assessments),
			},
			{
				href: '',
				name: t(`Video`),
				icon: 'videos',
				slug: 'action-video',
				show: models.group.hasFeature(group, Features.Video),
			},
			{
				href: routes.Organization.Development.PerformanceReviews(
					organizationId,
					group.id
				),
				name: t(`Dialogue`),
				icon: 'nav-dialogue',
				slug: 'action-dialogue',
				show:
					hasGroups &&
					models.group.hasFeature(group, Features.IndividualDevelopment),
			}
		);
	}

	const items = menuItems
		.filter(({ show = true }) => show)
		.map(({ href, name, icon, slug }, n) => {
			if (href.indexOf(':groupId') !== -1) {
				href = url.transform(href, {
					groupId: activeMembership?.groupId.toString(),
				});
			}
			if (href.indexOf(':userId') !== -1) {
				href = url.transform(href, {
					userId: activeMembership.userId.toString(),
				});
			}

			const properties = {
				key: `mainItem--${n}`,
				href,
				icon,
				slug,
			};

			return { name, properties };
		});

	return items;
};

export default actionMenuItems;
