export function Create(groupID: number): string {
	return `/v1/groups/${groupID}/stripe-tax-rates`;
}

export function Create1(groupID: number): string {
	return `/v1/groups/${groupID}/tax-rates`;
}

export function Delete(groupID: number, taxRateID: string): string {
	return `/v1/groups/${groupID}/stripe-tax-rates/${taxRateID}`;
}

export function Delete1(groupID: number, taxRateID: string): string {
	return `/v1/groups/${groupID}/tax-rates/${taxRateID}`;
}

export function Index(groupID: number): string {
	return `/v1/groups/${groupID}/stripe-tax-rates`;
}

export function Index1(groupID: number): string {
	return `/v1/groups/${groupID}/tax-rates`;
}

export function Update(groupID: number, taxRateID: string): string {
	return `/v1/groups/${groupID}/stripe-tax-rates/${taxRateID}`;
}

export function Update1(groupID: number, taxRateID: string): string {
	return `/v1/groups/${groupID}/tax-rates/${taxRateID}`;
}
