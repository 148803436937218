import { t } from '@transifex/native';
import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { small } from 'pkg/config/breakpoints';

import * as sdk from 'pkg/core/sdk';
import DateTime, { Granularity } from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { IdentityContext } from 'pkg/identity';
import * as models from 'pkg/api/models';

import RelativeTime from 'components/RelativeDateTime';
import AddButton from 'components/add-button';
import Icon from 'components/icon';

import { Spinner } from 'components/loaders/spinner';
import { UserActionListItem } from 'components/group/UserActionList';
import Column from 'components/layout/column';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import UpdateDialogueTemplateModal from 'components/group/update-dialogue-template-modal';

import * as ContextMenu from 'design/context_menu';
import InlinePlaceholder from 'design/placeholders/inline';
import Button from 'design/button';

const MissingWrapper = styled.div`
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

const DesktopActions = styled.div`
	display: flex;
	justify-content: flex-end;

	@media ${small} {
		display: none;
	}
`;
class Dialogue extends Component {
	static contextType = IdentityContext;

	static propTypes = {
		user: PropTypes.object,
	};

	state = {
		isBusy: true,
		groupUsers: [],
		templateModalVisible: false,
	};

	componentDidMount() {
		this.fetchGroupUsers(1, []);
		this.props.setPageActions(this.getPageActions(true));
	}

	async fetchGroupUsers(page, records) {
		const groupId = this.props.groupId;
		const endpointUrl = '/groups/:groupId/users/performance-reviews';
		const response = await sdk.get(endpointUrl, { groupId, page });
		const result = await response.json();

		records.push(...result.records);

		if (result.pageCount > 1 && result.page !== result.pageCount) {
			this.fetchGroupUsers(page + 1, records);
		}

		if (result.page === result.pageCount) {
			this.setState({
				groupUsers: records,
				isBusy: false,
			});
		}
	}

	get users() {
		return this.state.groupUsers.filter(({ role }) => role === 1);
	}

	get groupUsers() {
		const orgId = this.props.organizationId;

		if (this.state.isBusy) return <Spinner center />;

		return this.users.map((groupUser) => {
			let now = Date.now();
			let lastUpdatedAtString = t(`Never updated`);
			let needsActionLabel = null;

			if (!groupUser?.lastPerformanceReviewAt) {
				needsActionLabel = t(`Dialogue missing`);
			} else {
				lastUpdatedAtString = (
					<RelativeTime dateTime={groupUser.lastPerformanceReviewAt} />
				);
			}

			const adjustedDate = DateTime.now()
				.setUnixTimestamp(groupUser.lastPerformanceReviewAt)
				.next(Granularity.day, 180);

			if (adjustedDate.isBefore(now)) {
				needsActionLabel = t(`Outdated`);
			}

			const props = {
				user: groupUser.user,
				userLabel: lastUpdatedAtString,
				needsActionLabel,
				key: `list-item-${groupUser.id}`,
				primaryRating: {},
				userId: groupUser.userId,
				href: routes.User.Profile.PerformanceReviews(
					orgId,
					groupUser.groupId,
					groupUser.userId
				),
			};

			return (
				<UserActionListItem {...props}>
					<ContextMenu.Menu
						title={`${groupUser?.user.firstName} ${groupUser?.user.lastName}`}
						toggleWith={<AddButton />}>
						<ContextMenu.LinkItem
							href={routes.User.Profile.PerformanceReviews(
								orgId,
								groupUser.groupId,
								groupUser.userId
							)}>
							<ContextMenu.ItemIcon name="visibility" />
							{t(`View`)}
						</ContextMenu.LinkItem>

						<ContextMenu.LinkItem
							href={routes.User.Profile.Goals(
								orgId,
								groupUser.groupId,
								groupUser.userId
							)}>
							<ContextMenu.ItemIcon name="flag" />
							{t(`View goals`)}
						</ContextMenu.LinkItem>
					</ContextMenu.Menu>
				</UserActionListItem>
			);
		});
	}

	openModal = () => {
		this.setState({ templateModalVisible: true });
	};

	closeModal = async (p) => {
		await p;
		this.setState({ templateModalVisible: false });
	};

	getPageActions(asNavAction = false) {
		if (!models.membership.isAdmin(this.context.membership)) return null;

		if (asNavAction) {
			return <ButtonTrigger icon="settings" onClick={this.openModal} />;
		}

		return (
			<DesktopActions>
				<Button
					icon="settings"
					inline
					noPadding
					onClick={this.openModal}
					testid="performance_review.update_template_button">
					{t(`Update template`)}
				</Button>
			</DesktopActions>
		);
	}

	render() {
		return (
			<Column>
				{this.getPageActions(false)}
				{this.state.templateModalVisible && (
					<UpdateDialogueTemplateModal
						closeModal={this.closeModal}
						groupId={this.props.groupId}
					/>
				)}
				{this.users.length === 0 && !this.state.isBusy ? (
					<MissingWrapper>
						<InlinePlaceholder>
							<Icon name="nav-dialogue" />
							{t(`No players in team`)}
						</InlinePlaceholder>
					</MissingWrapper>
				) : (
					this.groupUsers
				)}
			</Column>
		);
	}
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Dialogue);
