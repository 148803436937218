// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._iRI6nN9xESJdymCYrBD {
	margin: 0 auto;
	border: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	            -ms-user-select: none;
	        user-select: none;
	background: transparent;
	max-width: 100%;
	overflow: hidden;
}

@media print {

._iRI6nN9xESJdymCYrBD {
		display: none;
}
	}

._iRI6nN9xESJdymCYrBD > * {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-line-pack: center;
		    align-content: center;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		place-content: center;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		justify-items: center;
		place-items: center;
		height: 100%;
	}

.X05lSE37SKGcU0bOdi9g {
	-webkit-box-shadow: 0 0 0 1px red;
	        box-shadow: 0 0 0 1px red;
}
`, "",{"version":3,"sources":["webpack://./components/adslot/styles.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,YAAY;CACZ,yBAAiB;IAAjB,sBAAiB;aAAjB,qBAAiB;SAAjB,iBAAiB;CACjB,uBAAuB;CACvB,eAAe;CACf,gBAAgB;AAYjB;;AAVC;;AARD;EASE,aAAa;AASf;CARC;;AAEA;EACC,oBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,0BAAqB;MAArB,qBAAqB;EAArB,wBAAqB;MAArB,qBAAqB;UAArB,uBAAqB;EAArB,qBAAqB;EACrB,yBAAmB;MAAnB,sBAAmB;UAAnB,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EACnB,YAAY;CACb;;AAGD;CACC,iCAAyB;SAAzB,yBAAyB;AAC1B","sourcesContent":[".adslot {\n\tmargin: 0 auto;\n\tborder: none;\n\tuser-select: none;\n\tbackground: transparent;\n\tmax-width: 100%;\n\toverflow: hidden;\n\n\t@media print {\n\t\tdisplay: none;\n\t}\n\n\t& > * {\n\t\tdisplay: flex;\n\t\tplace-content: center;\n\t\tplace-items: center;\n\t\theight: 100%;\n\t}\n}\n\n.adslot--dev {\n\tbox-shadow: 0 0 0 1px red;\n}\n"],"sourceRoot":""}]);
// Exports
export var adslot = `_iRI6nN9xESJdymCYrBD`;
var _1 = `X05lSE37SKGcU0bOdi9g`;
export { _1 as "adslot--dev" };
export default ___CSS_LOADER_EXPORT___;
