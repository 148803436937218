import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';
import useMixedState from 'pkg/hooks/useMixedState';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import AddToCollectionModal from 'components/video-library/modals/add-to-collection';
import VideoModal from 'components/video-library/modals/Video';
import Group, { GroupWrapperProperties } from 'components/library/Group';
import { GroupItemAction } from 'components/library/GroupItem';
import GroupItemButton from 'components/library/GroupItemButton';
import VideoGroupItem from 'components/library/group-items/Video';

export default function Videos({
	title,
	items,
	hasNext,
	fetchNext,
	removeRecord,
	replaceRecord,
}: GroupWrapperProperties<models.video.Video>): JSX.Element {
	const org = useCurrentOrganization();
	const route = useCurrentRoute();
	const membership = useCurrentMembership();

	const [modals, setModal] = useMixedState<{
		video: number;
		collection: number;
	}>({
		video: 0,
		collection: 0,
	});

	const handleAnalyze = (video: models.video.Video) => {
		pushState(routes.Video.Analyze(org.id, video.id));
	};

	const handleDelete = async (video: models.video.Video) => {
		const success = await models.destroy(video);

		if (success) {
			removeRecord(video.id);
		}
	};

	const handleEditVideo = (video: models.video.Video) => {
		setModal({ video: video.id });
	};

	const handleAddToCollection = (video: models.video.Video) => {
		setModal({ collection: video.id });
	};

	const handleRemoveFromCollection = (video: models.video.Video) => {
		if (route?.collectionId && video.id) {
			models.videoCollection.removeVideo(route.collectionId, video.id);

			removeRecord(video.id);
		}
	};

	const closeModal = () => setModal({ video: 0, collection: 0 });

	const { record: collection } =
		useEndpoint<models.videoCollection.VideoCollection>(
			route?.collectionId
				? endpoints.VideoCollections.Show(route.collectionId)
				: null
		);

	const handleAfterSave = (video: models.video.Video) => {
		replaceRecord(video);

		actions.flashes.show({
			title: t('Video updated'),
			message: t('"Successfully updated video'),
		});
	};

	return (
		<Group title={title}>
			{items.map((item: models.video.Video, n: number) => {
				const actions: GroupItemAction<models.video.Video>[] = [];

				if (models.canEdit(item)) {
					actions.push({
						icon: 'smart_display',
						label: t('Analyze video'),
						onClick: handleAnalyze,
					});
				}

				if (!models.membership.isParent(membership)) {
					actions.push({
						icon: 'create_new_folder',
						label: t('Add to collection'),
						onClick: handleAddToCollection,
					});
				}

				if (collection?.id) {
					actions.push({
						icon: 'cancel',
						label: t('Remove from collection'),
						onClick: handleRemoveFromCollection,
					});
				}

				if (models.canEdit(item)) {
					actions.push({
						icon: 'edit',
						label: t('Edit'),
						onClick: handleEditVideo,
					});
				}

				if (models.canDelete(item)) {
					actions.push({
						icon: 'delete',
						label: t('Delete'),
						confirm: t(
							`Do you want to delete "{name}"? This action cannot be undone.`,
							{
								name: item.title,
							}
						),
						onClick: handleDelete,
					});
				}

				return (
					<VideoGroupItem
						key={`${item.id}:${n}`}
						item={item}
						actions={actions}
					/>
				);
			})}
			{hasNext && fetchNext && (
				<GroupItemButton label={t('Load more')} onClick={fetchNext} />
			)}
			{modals.video !== 0 && (
				<VideoModal
					videoId={modals.video}
					onClose={closeModal}
					afterSave={handleAfterSave}
				/>
			)}
			{modals.collection !== 0 && (
				<AddToCollectionModal
					videoId={modals.collection}
					onClose={closeModal}
				/>
			)}
		</Group>
	);
}
