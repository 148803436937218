import { memo, useRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import { ScrollSpy } from 'components/ScrollSpy';

import {
	FAUX_MODAL_CHILD,
	FAUX_MODAL_PARENT,
} from 'components/modals/FauxPageModal';

interface OuterProps {
	disableScroll: boolean;
	contentOffset?: number;
	backgroundColor?: string;
}

const Outer = styled(ScrollSpy)<OuterProps>`
	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	min-height: 0;
	min-width: 0;
	grid-area: content;
	transition: transform 200ms ease-out;

	${({ backgroundColor }) =>
		backgroundColor &&
		css`
			background-color: ${backgroundColor};
		`}

	${({ disableScroll }) =>
		disableScroll &&
		css`
			overflow: hidden;
			-webkit-overflow-scrolling: auto;

			${InnerWrapper} {
				height: 100%;
			}
		`};

	@media print {
		overflow: hidden;
	}

	&:has(.${FAUX_MODAL_CHILD}) {
		& > * {
			display: none;
		}
		& > .${FAUX_MODAL_CHILD} {
			display: initial;
		}
	}
`;

interface InnerWrapperProps {
	maxWidth?: PageWidths;
	noPadding?: boolean;
	spacious: boolean;
	columns?: number;
	columnSpacing?: styles.spacing | string;
	columnSizes?: string;
	topSpacing?: number;
}

export const InnerWrapper = styled.div<InnerWrapperProps>`
	width: 100%;

	${({ maxWidth }) =>
		Number.isInteger(maxWidth) &&
		css`
			max-width: ${maxWidth}px;
			margin: 0 auto;
		`};

	${({ spacious, noPadding }) =>
		spacious &&
		!noPadding &&
		css`
			@media ${breakpoints.large}, ${breakpoints.nolimit} {
				padding: var(--spacing-8) var(--spacing-5) var(--spacing-9)
					var(--spacing-5);
			}

			@media ${breakpoints.small} {
				padding: var(--spacing-7) var(--spacing-5);
			}
		`};

	${({ spacious, noPadding }) =>
		!spacious &&
		!noPadding &&
		css`
			@media ${breakpoints.large}, ${breakpoints.nolimit} {
				padding: var(--spacing-8) var(--spacing-7) var(--spacing-9)
					var(--spacing-7);
			}

			@media ${breakpoints.small} {
				padding: var(--spacing-5) var(--spacing-5) var(--spacing-7);
			}
		`};

	${({ columns, columnSpacing, columnSizes }) =>
		columns &&
		css`
			display: grid;
			grid-template-columns: ${columnSizes
				? columnSizes
				: `repeat(${columns}, 1fr)`};
			gap: ${columnSpacing ? columnSpacing : styles.spacing._5};
		`};
	${({ topSpacing }) =>
		(topSpacing || topSpacing === 0) &&
		css`
			padding-top: ${topSpacing}px !important;
		`};
`;

interface InnerProps {
	maxWidth?: PageWidths;
	noPadding?: boolean;
	children: ReactNode;
	spacious?: boolean;
	columns?: number;
	columnSpacing?: styles.spacing | string;
	columnSizes?: string;
	topSpacing?: number;
}

export const Inner = memo(
	({
		children,
		maxWidth,
		noPadding,
		columns,
		columnSpacing,
		columnSizes,
		spacious = false,
		topSpacing,
	}: InnerProps) => {
		return (
			<InnerWrapper
				maxWidth={maxWidth}
				noPadding={noPadding}
				spacious={spacious}
				columns={columns}
				columnSpacing={columnSpacing}
				topSpacing={topSpacing}
				columnSizes={columnSizes}>
				{children}
			</InnerWrapper>
		);
	}
);

interface WrapperProps {
	disableScroll?: boolean;
	children: ReactNode;
	backgroundColor?: string;
}

export const Wrapper = memo(
	({ children, disableScroll = false, backgroundColor }: WrapperProps) => {
		const outer = useRef(null);

		return (
			<Outer
				ref={outer}
				disableScroll={disableScroll}
				backgroundColor={backgroundColor}
				className={'page-scrollable ' + FAUX_MODAL_PARENT}>
				{children}
			</Outer>
		);
	}
);
