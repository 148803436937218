// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Introduction */

@media (max-width: 768px) {

.VZctFLqsFgbOGFuLExoD {
		padding-bottom: var(--spacing-10);
}
	}

.QcxEiVjvNUos_Ki2V9eS {
	line-height: 1.5;
}

.StOJilz5COtzzORqAm1b img {
		border-radius: var(--radius-6);
		width: 100%;
		max-height: 50vh;
		-o-object-fit: contain;
		   object-fit: contain;
	}

/* Summary */

.EDn4Dw_FnO6vKKMfnYxA {
	text-align: right;
	font-weight: var(--font-weight-semibold);
}

.wLVC0aFYFSdnbT7l2GIT {
	text-align: right;
}

.pHXpbR8PU4nRuq5472IO {
	font-size: var(--font-size-lg);
}

.cxxOt2eludzKwra3jHxv {
	text-align: right;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-lg);
}

.jnXjx7gTMnTgixeyEQS7 {
	border: none;
	border-top: 1px solid var(--palette-gray-300);
}

.i1HlslS0gXceB5v2dfPm {
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
}

@media (max-width: 768px) {

.al8azrB1wjNSkVVx_akN {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100px;
		-webkit-box-shadow: var(--box-shadow-card-hover);
		        box-shadow: var(--box-shadow-card-hover);
		background-color: var(--palette-white);
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		padding: var(--spacing-4) var(--spacing-4) var(--sab) var(--spacing-4);
		z-index: 1000;
}
	}
`, "",{"version":3,"sources":["webpack://./routes/forms/registration/preview/styles.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAGhB;;AADD;EAEE,iCAAiC;AAEnC;CADC;;AAGD;CACC,gBAAgB;AACjB;;AAGC;EACC,8BAA8B;EAC9B,WAAW;EACX,gBAAgB;EAChB,sBAAmB;KAAnB,mBAAmB;CACpB;;AAGD,YAAY;;AAEZ;CACC,iBAAiB;CACjB,wCAAwC;AACzC;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,iBAAiB;CACjB,wCAAwC;CACxC,8BAA8B;AAC/B;;AAEA;CACC,YAAY;CACZ,6CAA6C;AAC9C;;AAEA;CACC,sCAAsC;CACtC,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAGC;;AADD;EAEE,eAAe;EACf,OAAO;EACP,SAAS;EACT,WAAW;EACX,aAAa;EACb,gDAAwC;UAAxC,wCAAwC;EACxC,sCAAsC;EACtC,oBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,wBAAuB;MAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,sEAAsE;EACtE,aAAa;AAEf;CADC","sourcesContent":["/* Introduction */\n\n.wrapper {\n\t@media (--small-viewport) {\n\t\tpadding-bottom: var(--spacing-10);\n\t}\n}\n\n.registrationDescription {\n\tline-height: 1.5;\n}\n\n.image {\n\t& img {\n\t\tborder-radius: var(--radius-6);\n\t\twidth: 100%;\n\t\tmax-height: 50vh;\n\t\tobject-fit: contain;\n\t}\n}\n\n/* Summary */\n\n.item {\n\ttext-align: right;\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.amount {\n\ttext-align: right;\n}\n\n.labelLarge {\n\tfont-size: var(--font-size-lg);\n}\n\n.amountLarge {\n\ttext-align: right;\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-lg);\n}\n\n.divider {\n\tborder: none;\n\tborder-top: 1px solid var(--palette-gray-300);\n}\n\n.subText {\n\tfont-weight: var(--font-weight-normal);\n\tcolor: var(--palette-gray-500);\n\tfont-size: var(--font-size-sm);\n}\n\n.buttonWrapper {\n\t@media (--small-viewport) {\n\t\tposition: fixed;\n\t\tleft: 0;\n\t\tbottom: 0;\n\t\twidth: 100%;\n\t\theight: 100px;\n\t\tbox-shadow: var(--box-shadow-card-hover);\n\t\tbackground-color: var(--palette-white);\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\tpadding: var(--spacing-4) var(--spacing-4) var(--sab) var(--spacing-4);\n\t\tz-index: 1000;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `VZctFLqsFgbOGFuLExoD`;
export var registrationDescription = `QcxEiVjvNUos_Ki2V9eS`;
export var image = `StOJilz5COtzzORqAm1b`;
export var item = `EDn4Dw_FnO6vKKMfnYxA`;
export var amount = `wLVC0aFYFSdnbT7l2GIT`;
export var labelLarge = `pHXpbR8PU4nRuq5472IO`;
export var amountLarge = `cxxOt2eludzKwra3jHxv`;
export var divider = `jnXjx7gTMnTgixeyEQS7`;
export var subText = `i1HlslS0gXceB5v2dfPm`;
export var buttonWrapper = `al8azrB1wjNSkVVx_akN`;
export default ___CSS_LOADER_EXPORT___;
