import { Fragment, ReactNode, Children } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import MaterialSymbol from 'components/material-symbols';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import * as iconStyles from 'components/icon/styles.css';

import * as contextCss from 'design/context_menu/styles.css';

interface TitleProps {
	medium?: boolean;
	large?: boolean;
	thin?: boolean;
	centered?: boolean;
	icon?: MaterialSymbolVariant | MaterialSymbolVariant[];
}

const IconText = styled.span``;

const Title = styled.h2<TitleProps>`
	display: flex;
	grid-auto-columns: auto;
	grid-gap: var(--spacing-4);
	justify-content: space-between;
	align-items: center;
	position: relative;
	padding: 0;
	min-height: 30px;
	color: var(--palette-black);
	font-weight: var(--font-weight-semibold);
	line-height: 1;

	${IconText} {
		display: flex;
		gap: var(--spacing-3);
		align-items: center;
	}

	font-size: var(--font-size-lg);

	${IconText} .${iconStyles.icon} {
		font-size: var(--font-size-xl);
	}

	${({ medium }) =>
		medium &&
		css`
			font-size: var(--font-size-xl);

			${IconText} .${iconStyles.icon} {
				font-size: var(--font-size-xl);
			}
		`}

	${({ large }) =>
		large &&
		css`
			font-size: var(--font-size-3xl);
			line-height: var(--font-line-height-3xl);

			@media ${styles.breakpoint.small} {
				font-size: var(--font-size-2xl);
				line-height: var(--font-line-height-2xl);
			}

			${IconText} .${iconStyles.icon} {
				font-size: var(--font-size-2xl);
			}
		`}

	${({ thin }) =>
		thin &&
		css`
			font-size: var(--font-size-base);
			font-weight: var(--font-weight-normal);
		`}

	${({ centered }) =>
		centered &&
		css`
			text-align: center;
			justify-content: center;
		`}

	.${contextCss.defaultToggle} {
		position: relative;
		top: auto;
		right: auto;
		padding: 0;
	}
`;

interface SectionTitleComponentProps extends TitleProps {
	children: ReactNode;
	className?: string;
}

function SectionTitleComponent({
	children,
	medium,
	large,
	thin,
	centered,
	icon,
	className,
}: SectionTitleComponentProps): JSX.Element {
	let title: ReactNode;
	const newChildren = Children.toArray(children);
	let icons: MaterialSymbolVariant[] = [];

	if (icon) {
		icons = Array.isArray(icon) ? icon : [icon];
		title = newChildren.shift();
	} else {
		title = children;
	}

	return (
		<Title
			medium={medium}
			large={large}
			thin={thin}
			centered={centered}
			className={className}>
			{icon ? (
				<Fragment>
					<IconText>
						{icons.map((iconVariant, index) => (
							<MaterialSymbol variant={iconVariant} key={index} />
						))}
						{title}
					</IconText>
					{newChildren}
				</Fragment>
			) : (
				children
			)}
		</Title>
	);
}

const SectionTitle = styled(
	SectionTitleComponent
)<SectionTitleComponentProps>``;

export default SectionTitle;
