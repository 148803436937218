export function ClearInviteCode(groupID: number): string {
	return `/v1/groups/${groupID}/invite-code`;
}

export function ConnectWithFogis(groupID: number): string {
	return `/v1/groups/${groupID}/fogis/connect`;
}

export function Create(): string {
	return `/v1/groups`;
}

export function Delete(groupID: number): string {
	return `/v1/groups/${groupID}`;
}

export function ExportTeamMembers(groupID: number): string {
	return `/v1/groups/${groupID}/users/export`;
}

export function GenerateInviteCode(groupID: number): string {
	return `/v1/groups/${groupID}/invite-code`;
}

export function GetFogisTeamNameAndCompetitions(groupID: number): string {
	return `/v1/groups/${groupID}/fogis/team-name-and-competitions`;
}

export function JoinFromInviteCode(): string {
	return `/v1/me/invite-code`;
}

export function ListUsersRecursively(groupID: number): string {
	return `/v1/groups/${groupID}/users/all`;
}

export function PaymentProviderSettings(groupID: number): string {
	return `/v1/groups/${groupID}/payment-provider-settings`;
}

export function SearchAll(groupID: number): string {
	return `/v1/groups/${groupID}/search`;
}

export function SearchForUsers(groupID: number): string {
	return `/v1/groups/${groupID}/users/search`;
}

export function SearchViewable(groupID: number): string {
	return `/v1/organizations/${groupID}/groups/search`;
}

export function SetPerformanceReviewTemplate(groupID: number): string {
	return `/v1/groups/${groupID}/performance-review-template`;
}

export function Show(groupID: number): string {
	return `/v1/groups/${groupID}`;
}

export function ShowAllGroupLinks(groupID: number): string {
	return `/v1/groups/${groupID}/all-links`;
}

export function ShowChildren(groupID: number): string {
	return `/v1/groups/${groupID}/children`;
}

export function ShowFeed(groupID: number): string {
	return `/v1/groups/${groupID}/feed`;
}

export function ShowGroupLinks(groupID: number): string {
	return `/v1/groups/${groupID}/links`;
}

export function ShowParent(groupID: number): string {
	return `/v1/groups/${groupID}/parent`;
}

export function ShowSquad(groupID: number): string {
	return `/v1/groups/${groupID}/squad`;
}

export function ShowUsers(groupID: number): string {
	return `/v1/groups/${groupID}/users`;
}

export function ShowUsersWithLatestPerformanceReview(groupID: number): string {
	return `/v1/groups/${groupID}/users/performance-reviews`;
}

export function TrainingSessionSummary(groupID: number): string {
	return `/v1/groups/${groupID}/training-session-summary`;
}

export function Update(groupID: number): string {
	return `/v1/groups/${groupID}`;
}

export function UpdateGroupOrder(groupID: number): string {
	return `/v1/groups/${groupID}/sort-order`;
}

export function UpdateUser(groupID: number, userID: number): string {
	return `/v1/groups/${groupID}/users/${userID}`;
}
