// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aRywwtVTbEcVE7YUjJmS {
	width: 40px;
	height: 40px;
	border-radius: var(--radius-6);
	background-color: var(--palette-black);
	background-color: var(--primaryColor, var(--palette-black));
	position: relative;

	/* Not using spacing because -2 is a bit to big and -3 to small */
	padding: 6px;
}
.aRywwtVTbEcVE7YUjJmS::before {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		height: 11px;
		width: 11px;
		background-color: var(--palette-red-500);
		border-radius: 100%;
		border: 2px solid var(--palette-white);
		-webkit-transform: translate(3px, -3px);
		        transform: translate(3px, -3px);
	}
`, "",{"version":3,"sources":["webpack://./components/notifications/unread-chats/list/styles.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,8BAA8B;CAC9B,sCAA2D;CAA3D,2DAA2D;CAC3D,kBAAkB;;CAElB,iEAAiE;CACjE,YAAY;AAcb;AAZC;EACC,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,YAAY;EACZ,WAAW;EACX,wCAAwC;EACxC,mBAAmB;EACnB,sCAAsC;EACtC,uCAA+B;UAA/B,+BAA+B;CAChC","sourcesContent":[".badgeWrapper {\n\twidth: 40px;\n\theight: 40px;\n\tborder-radius: var(--radius-6);\n\tbackground-color: var(--primaryColor, var(--palette-black));\n\tposition: relative;\n\n\t/* Not using spacing because -2 is a bit to big and -3 to small */\n\tpadding: 6px;\n\n\t&::before {\n\t\tcontent: '';\n\t\tposition: absolute;\n\t\tright: 0;\n\t\ttop: 0;\n\t\theight: 11px;\n\t\twidth: 11px;\n\t\tbackground-color: var(--palette-red-500);\n\t\tborder-radius: 100%;\n\t\tborder: 2px solid var(--palette-white);\n\t\ttransform: translate(3px, -3px);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var badgeWrapper = `aRywwtVTbEcVE7YUjJmS`;
export default ___CSS_LOADER_EXPORT___;
