import React, {
	ReactNode,
	MouseEvent,
	useContext,
	Children,
	Fragment,
} from 'react';
import styled from 'styled-components';

import useTooltip from 'pkg/hooks/useTooltip';

import { TableContext } from 'design/table';

interface RowProps {
	id?: string;
	onClick?: (event?: MouseEvent) => void;
	children: ReactNode;
	className?: string;
	tooltipText?: string;
	disabled?: boolean;
}

function Row({
	id,
	onClick,
	children,
	className,
	tooltipText,
	disabled,
}: RowProps): JSX.Element {
	const { hideIndexes } = useContext(TableContext);
	const cells = Children.toArray(children).flat();

	const { onMouseEnter, tooltip } = useTooltip(tooltipText);

	const content: JSX.Element | JSX.Element[] = cells.map(
		(cell: JSX.Element) => {
			if (hideIndexes.includes(cells.indexOf(cell))) {
				return null;
			}

			return cell;
		}
	);

	return (
		<Fragment>
			<tr
				id={id}
				onClick={onClick}
				className={className}
				data-interactable={!!onClick}
				data-disabled={disabled}
				onMouseEnter={onMouseEnter}>
				{content}
			</tr>
			{tooltip}
		</Fragment>
	);
}

export default styled(Row)<RowProps>``;
