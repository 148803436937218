// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bRcMKwGzb3RZVXH92uH7 {
	color: var(--palette-blue-500);
	font-weight: var(--font-weight-semibold);
	cursor: pointer;
}

.FZwy6zUCzJgYRHzNLlCd {
	/* This is needed to center the icon in the input field */
	margin-bottom: -25px;
}
`, "",{"version":3,"sources":["webpack://./routes/payments/products/form/meta_data/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,wCAAwC;CACxC,eAAe;AAChB;;AAEA;CACC,yDAAyD;CACzD,oBAAoB;AACrB","sourcesContent":[".newItem {\n\tcolor: var(--palette-blue-500);\n\tfont-weight: var(--font-weight-semibold);\n\tcursor: pointer;\n}\n\n.icon {\n\t/* This is needed to center the icon in the input field */\n\tmargin-bottom: -25px;\n}\n"],"sourceRoot":""}]);
// Exports
export var newItem = `bRcMKwGzb3RZVXH92uH7`;
export var icon = `FZwy6zUCzJgYRHzNLlCd`;
export default ___CSS_LOADER_EXPORT___;
