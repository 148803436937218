import { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Group, { Features } from 'pkg/models/group';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import {
	Membership,
	MembershipRole,
	roleToTranslatedString,
} from 'pkg/api/models/membership';
import { useCurrentMembership } from 'pkg/identity';
import { newChatParams } from 'pkg/chat';
import { link } from 'pkg/router/utils';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import CheckBox from 'components/form/CheckBox';
import StatusLabel from 'components/payment_platform/status/label';
import { RemoveUserItem, useRemoveUserDialog } from 'components/user/remove';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';
import { useDialog } from 'design/dialog';

const Row = styled(Table.Row)<{ isArchived: boolean }>`
	${({ isArchived }) =>
		isArchived &&
		css`
			td,
			${Name} {
				color: var(--palette-gray-500) !important;
			}
		`}
`;

const Name = styled.div`
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-900);
`;

const NameColumn = styled.div`
	display: flex;
	align-items: center;

	span {
		padding-left: var(--spacing-4);
	}

	${Name} {
		padding-left: var(--spacing-3);
	}
`;

const AccountIcon = styled(Icon)`
	font-size: var(--font-size-xl);
`;

interface ContactRowProps {
	organization: Group;
	contact: models.user.User;
	products: models.product.Product[];
	isSelected: boolean;
	onSelect: (contactId: number) => void;
	onAssignProduct: (contactId: number) => void;
	onArchiveContact: (contactId: number) => void;
	onRestoreContact: (contactId: number) => void;
	onAddUserToGroup: (contactId: number) => void;
	onRemoveUserFromGroup: (
		removeUserRecursively: boolean,
		removeFromFutureEvents: boolean,
		contactId: number
	) => void;
	displayRole: boolean;
	subGroupId?: number;
}

const ContactRow: React.FC<React.PropsWithChildren<ContactRowProps>> = ({
	organization,
	contact,
	products,
	isSelected,
	onSelect,
	onAssignProduct,
	onArchiveContact,
	onRestoreContact,
	onAddUserToGroup,
	onRemoveUserFromGroup,
	displayRole,
	subGroupId,
}) => {
	const handleSelect = () => onSelect(contact.id);

	const handleAssignProduct = () => onAssignProduct(contact.id);

	const handleArchive = async () => onArchiveContact(contact.id);

	const handleRestore = async () => onRestoreContact(contact.id);

	const handleAddUserToGroup = () => onAddUserToGroup(contact.id);

	const handleRemoveFromGroup = async (
		removeUserRecursively: boolean,
		removeFromFutureEvents: boolean
	) =>
		onRemoveUserFromGroup(
			removeUserRecursively,
			removeFromFutureEvents,
			contact.id
		);

	const removeUserDialog = useRemoveUserDialog({
		handleRemoveUsers: handleRemoveFromGroup,
	});

	const archiveDialog = useDialog({
		message: t(
			'Important: {user} has an active account. If archived {firstName} will lose access to the account in {organization}.',
			{
				user: models.user.fullName(contact),
				firstName: contact.firstName,
				organization: organization.name,
			}
		),
		onConfirm: handleArchive,
	});

	const createdDate = new DateTime(new Date(contact.createdAt * 1000));

	const currentMembership = useCurrentMembership();

	const getStatus = (product: models.product.Product) => {
		const userProduct = contact.products?.find(
			(userProduct: models.userProduct.UserProduct) =>
				product.id === userProduct.productId
		);

		if (!userProduct) return null;

		return <StatusLabel status={{ name: userProduct.status }} />;
	};

	const contactLink = routes.Management.Contact.Show(
		organization.id,
		contact.id,
		'overview'
	);

	const billingEmail = models.user.getBillingEmail(contact);

	const canChat =
		organization.hasFeature(Features.Chat) &&
		contact.accountId &&
		contact.accountId !== currentMembership.user?.accountId;

	let membership: Membership;
	if (contact.groups && subGroupId) {
		membership = contact.groups.find(
			(m: Membership) => m.groupId === subGroupId
		);
	}

	const contextMenu = (
		<Table.ActionCell>
			<ContextMenu.Menu
				toggleWith={
					<ContextMenu.TableTrigger>
						<Icon name="context-menu" />
					</ContextMenu.TableTrigger>
				}>
				<ContextMenu.LinkItem href={contactLink}>
					<ContextMenu.ItemIcon name="arrow_forward" />
					{t('View')}
				</ContextMenu.LinkItem>
				<ContextMenu.LinkItem
					href={routes.Management.Contact.Edit(organization.id, contact.id)}>
					<ContextMenu.ItemIcon name="edit" />
					{t('Edit')}
				</ContextMenu.LinkItem>
				{organization.hasFeature(Features.Payments) && (
					<Fragment>
						<ContextMenu.LinkItem
							href={link(routes.Invoice.New(organization.id), {
								userIds: [contact.id],
							})}>
							<ContextMenu.ItemIcon name="add" />
							{t(`New order`)}
						</ContextMenu.LinkItem>
						<ContextMenu.Item onClick={handleAssignProduct}>
							<ContextMenu.ItemIcon name="sell" />
							{t('Assign product')}
						</ContextMenu.Item>
					</Fragment>
				)}
				{canChat && (
					<ContextMenu.LinkItem
						href={link(
							routes.Chat.New(organization.id),
							newChatParams([contact])
						)}>
						<ContextMenu.ItemIcon name="comment" />
						{t('Chat')}
					</ContextMenu.LinkItem>
				)}
				{contact.email && (
					<ContextMenu.LinkItem href={`mailto:${contact.email}`}>
						<ContextMenu.ItemIcon name="mail" />
						{t(`Send email`)}
					</ContextMenu.LinkItem>
				)}
				{billingEmail && (
					<ContextMenu.LinkItem href={`mailto:${billingEmail}`}>
						<ContextMenu.ItemIcon name="mail" />
						{t(`Send email to billing address`)}
					</ContextMenu.LinkItem>
				)}
				<ContextMenu.LinkItem onClick={handleAddUserToGroup}>
					<ContextMenu.ItemIcon name="groups" />
					{t('Add to group')}
				</ContextMenu.LinkItem>
				{!!subGroupId && (
					<Fragment>
						<ContextMenu.LinkItem
							href={routes.User.Profile.Show(
								organization.id,
								subGroupId,
								contact.id,
								'overview'
							)}>
							<ContextMenu.ItemIcon name="person" />
							{t(`View sports profile`)}
						</ContextMenu.LinkItem>
						<RemoveUserItem dialog={removeUserDialog} />
					</Fragment>
				)}
				{contact.deletedAt ? (
					<ContextMenu.ConfirmItem
						onConfirm={handleRestore}
						message={t(`Are you sure you want to restore this contact?`)}
						confirmLabel={t('Restore')}>
						<ContextMenu.ItemIcon name="person_add" />
						{t(`Restore contact`)}
					</ContextMenu.ConfirmItem>
				) : (
					<Fragment>
						{contact.accountId ? (
							<ContextMenu.Item onClick={archiveDialog} caution>
								<ContextMenu.ItemIcon name="archive" />
								{t(`Archive contact`)}
							</ContextMenu.Item>
						) : (
							<ContextMenu.ConfirmItem
								onConfirm={handleArchive}
								caution
								message={t(`Are you sure you want to archive this contact?`)}>
								<ContextMenu.ItemIcon name="archive" />
								{t(`Archive contact`)}
							</ContextMenu.ConfirmItem>
						)}
					</Fragment>
				)}
			</ContextMenu.Menu>
		</Table.ActionCell>
	);

	const checkBox = (
		<Table.Cell>
			<CheckBox
				id={contact.id.toString()}
				selected={isSelected}
				onClick={handleSelect}
			/>
		</Table.Cell>
	);

	return (
		<Fragment>
			<Row isArchived={!!contact.deletedAt}>
				{checkBox}
				<Table.LinkCell href={contactLink}>
					<NameColumn>
						<Avatar user={contact} size={25} />
						<span>{contact.companyName || models.user.fullName(contact)}</span>
					</NameColumn>
				</Table.LinkCell>
				<Table.LinkCell href={contactLink}>
					{contact.deletedAt || contact.accountId ? (
						contact.deletedAt ? (
							<Icon name="archive" />
						) : (
							<AccountIcon
								name="key"
								tooltip={t(`This contact has a 360player account.`)}
							/>
						)
					) : null}
				</Table.LinkCell>
				<Table.LinkCell href={contactLink}>{contact.email}</Table.LinkCell>
				<Table.LinkCell href={contactLink}>{billingEmail}</Table.LinkCell>
				<Table.LinkCell href={contactLink}>{contact.birthDate}</Table.LinkCell>
				<Table.LinkCell href={contactLink}>
					{createdDate.toLocaleDateString({
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					})}
				</Table.LinkCell>
				{organization.isLOKActive() && (
					<Table.LinkCell href={contactLink} align="center">
						<Icon
							size={1.8}
							fill={
								contact.lokValid
									? styles.palette.green[500]
									: styles.palette.red[500]
							}
							name={contact.lokValid ? 'check-circle' : 'close-circle'}
						/>
					</Table.LinkCell>
				)}
				{displayRole && (
					<Table.Cell>
						{roleToTranslatedString(membership?.role as MembershipRole)}
					</Table.Cell>
				)}
				{products.map((product) => (
					<Table.LinkCell href={contactLink} key={product.id}>
						{getStatus(product)}
					</Table.LinkCell>
				))}
				{contextMenu}
			</Row>
		</Fragment>
	);
};

export default ContactRow;
