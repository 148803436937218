import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ChangeEvent, useState, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import User, { UserSex } from 'pkg/models/user';
import Country from 'pkg/models/country';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as actions from 'pkg/actions';
import { emailRegexPattern, phoneRegexPattern } from 'pkg/strings';
import {
	translatedSexString,
	validatePersonalNumber,
} from 'pkg/api/models/user';

import CustomFields from 'routes/payments/hooks/contact_form_sections/CustomFields';
import {
	FormFields,
	FormStateData,
} from 'routes/payments/hooks/useContactForm';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import CountrySelect from 'components/CountrySelect';
import Avatar from 'components/avatar';

import ImageEditor from 'components/image/Editor';
import * as DateOfBirth from 'components/form/DateOfBirth';
import * as Inputs from 'components/form/inputs';
import Row from 'components/layout/row';
import Section from 'components/form/Section';
import Column from 'components/layout/column';
import { checkIfShouldValidatePersonalNumber } from 'components/user/missing-information';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const taxIdTypes: string[] = [
	'ae_trn',
	'au_abn',
	'br_cnpj',
	'br_cpf',
	'ca_bn',
	'ca_qst',
	'ch_vat',
	'cl_tin',
	'es_cif',
	'eu_vat',
	'gb_vat',
	'hk_br',
	'id_npwp',
	'in_gst',
	'jp_cn',
	'jp_rn',
	'kr_brn',
	'li_uid',
	'mx_rfc',
	'my_frp',
	'my_itn',
	'my_sst',
	'no_vat',
	'nz_gst',
	'ru_inn',
	'ru_kpp',
	'sa_vat',
	'sg_gst',
	'sg_uen',
	'th_vat',
	'tw_vat',
	'us_ein',
	'za_vat',
];

function translatedTaxId(taxId: string): string {
	switch (taxId) {
		case 'ae_trn':
			return t('United Arab Emirates TRN', {
				_context: 'tax ID types',
			});
		case 'au_abn':
			return t('Australian Business Number', {
				_context: 'tax ID types',
			});
		case 'br_cnpj':
			return t('Brazilian CNPJ Number', {
				_context: 'tax ID types',
			});
		case 'br_cpf':
			return t('Brazilian CPF Number', {
				_context: 'tax ID types',
			});
		case 'ca_bn':
			return t('Canadian BN', {
				_context: 'tax ID types',
			});
		case 'ca_qst':
			return t('Canadian QST Number', {
				_context: 'tax ID types',
			});
		case 'ch_vat':
			return t('Switzerland VAT Number', {
				_context: 'tax ID types',
			});
		case 'cl_tin':
			return t('Chilean TIN', {
				_context: 'tax ID types',
			});
		case 'es_cif':
			return t('Spanish CIF Number', {
				_context: 'tax ID types',
			});
		case 'eu_vat':
			return t('European VAT Number', {
				_context: 'tax ID types',
			});
		case 'gb_vat':
			return t('United Kingdom Vat NUmber', {
				_context: 'tax ID types',
			});
		case 'hk_br':
			return t('Honk Kong BR Number', {
				_context: 'tax ID types',
			});
		case 'id_npwp':
			return t('Indonesian NPWP Number', {
				_context: 'tax ID types',
			});
		case 'in_gst':
			return t('Indian GST Number', {
				_context: 'tax ID types',
			});
		case 'jp_cn':
			return t('Japanese Corporate Number', {
				_context: 'tax ID types',
			});
		case 'jp_rn':
			return t('Japanese RN Number', {
				_context: 'tax ID types',
			});
		case 'kr_brn':
			return t('Korean BRN', {
				_context: 'tax ID types',
			});
		case 'li_uid':
			return t('Liechtensteinian UID number', {
				_context: 'tax ID types',
			});
		case 'mx_rfc':
			return t('Mexican RFC Number', {
				_context: 'tax ID types',
			});
		case 'my_frp':
			return t('Malaysian FRP Number', {
				_context: 'tax ID types',
			});
		case 'my_itn':
			return t('Malaysian ITN Number', {
				_context: 'tax ID types',
			});
		case 'my_sst':
			return t('Malaysian SST Number', {
				_context: 'tax ID types',
			});
		case 'no_vat':
			return t('Norwegian VAT Number', {
				_context: 'tax ID types',
			});
		case 'nz_gst':
			return t('New Zealand GST Number', {
				_context: 'tax ID types',
			});
		case 'ru_inn':
			return t('Russian INN', {
				_context: 'tax ID types',
			});
		case 'ru_kpp':
			return t('Russian KPP', {
				_context: 'tax ID types',
			});
		case 'sa_vat':
			return t('Saudi Arabia VAT', {
				_context: 'tax ID types',
			});
		case 'sg_gst':
			return t('Singaporean GST', {
				_context: 'tax ID types',
			});
		case 'sg_uen':
			return t('Singaporean UEN', {
				_context: 'tax ID types',
			});
		case 'th_vat':
			return t('Thai VAT', {
				_context: 'tax ID types',
			});
		case 'tw_vat':
			return t('Taiwanese VAT', {
				_context: 'tax ID types',
			});
		case 'us_ein':
			return t('United States EIN', {
				_context: 'tax ID types',
			});
		case 'za_vat':
			return t('South African VAT number', {
				_context: 'tax ID types',
			});
	}
}

const AvatarWrapper = styled.div`
	width: 100px;

	@media ${styles.breakpoint.small} {
		width: 100%;
	}
`;

const DetailsSection: React.FC<
	React.PropsWithChildren<{
		data: FormStateData;
		organizationId: number;
		isEdit: boolean;
		user: User;
		handleAvatarChange: (value: string) => void;
		emailRequired?: boolean;
		hideSectionTitle?: boolean;
	}>
> = ({
	data,
	organizationId,
	isEdit,
	user,
	handleAvatarChange,
	emailRequired = false,
	hideSectionTitle = false,
}) => {
	const formContext = useFormContext();
	const personalIdWatch = formContext.watch(
		FormFields.lokSwedishPersonalId,
		user.lokSwedishPersonalId
	);
	const taxIdType = formContext.watch(FormFields.taxIdType, '');
	const taxIdValue = formContext.watch(FormFields.taxIdValue, '');

	const avatarUrl = data.profileImage
		? `data:image/png;base64, ${data.profileImage}`
		: data.profileImageUrl;

	const country: Country = useSelector((state: RootState) =>
		selectors.groups.findGroupCountry(state, { groupId: organizationId })
	);

	const [userCountry, setCountry] = useState<string>(user.country);
	const [nationality, setNationality] = useState<string>(user.nationality);

	const handleCountryChange = (event: ChangeEvent<HTMLOptionElement>) => {
		setCountry(event.target.value);
	};

	const handleNationalityChange = (event: ChangeEvent<HTMLOptionElement>) => {
		setNationality(event.target.value);
	};

	const userBirthDate = user.birthDate;
	const splitBirthDate = user.birthDate.split('-');

	const birthYear = userBirthDate ? splitBirthDate[0] : '';
	// Convert to number because we can get the month or day as e.g. "02"
	const birthMonth = userBirthDate
		? Number.parseInt(splitBirthDate[1], 10).toString()
		: '';
	const birthDay = userBirthDate
		? Number.parseInt(splitBirthDate[2], 10).toString()
		: '';

	useComponentDidMount(() => {
		actions.countries.fetchCountries();
	});

	const shouldValidatePersonalId = checkIfShouldValidatePersonalNumber(
		nationality,
		personalIdWatch,
		true
	);

	// One of type or value has been set
	const taxValueHasBeenSet = taxIdType !== '' || taxIdValue !== '';

	const handleValidateTaxInputs = (value: string) => {
		if (taxValueHasBeenSet && value === '') {
			return false;
		}

		return true;
	};

	return (
		<Section
			icon="person"
			title={hideSectionTitle ? '' : t(`Contact details`)}
			description={
				hideSectionTitle
					? ''
					: t(`Fill in the personal information about the contact`)
			}>
			<Column spacing={styles.spacing._7}>
				<AvatarWrapper>
					<ContextMenu.Menu
						toggleWith={
							isEdit || avatarUrl ? (
								<Avatar customImageUrl={avatarUrl} size={100} isEditable />
							) : (
								<Fragment>
									<LargeScreen>
										<Button secondary icon="upload">
											{t(`Upload new avatar`)}
										</Button>
									</LargeScreen>
									<SmallScreen>
										<Button block secondary icon="upload">
											{t(`Upload new avatar`)}
										</Button>
									</SmallScreen>
								</Fragment>
							)
						}>
						<ContextMenu.MenuContext.Consumer>
							{({ close }) => (
								<ContextMenu.Pane>
									<ImageEditor
										image={avatarUrl}
										onSave={handleAvatarChange}
										afterSave={close}
									/>
								</ContextMenu.Pane>
							)}
						</ContextMenu.MenuContext.Consumer>
					</ContextMenu.Menu>
				</AvatarWrapper>
				<LargeScreen>
					<Row>
						<Inputs.Group label={t('First name')} required>
							<Inputs.Field
								name={FormFields.firstName}
								required
								defaultValue={user.firstName}
							/>
						</Inputs.Group>
						<Inputs.Group label={t('Last name')}>
							<Inputs.Field
								name={FormFields.lastName}
								defaultValue={user.lastName}
							/>
						</Inputs.Group>
					</Row>
					<Row>
						<Inputs.Group label={t('Email')} required={emailRequired}>
							<Inputs.Field
								name={FormFields.email}
								type="email"
								pattern={emailRegexPattern}
								required={emailRequired}
								defaultValue={user.email}
							/>
						</Inputs.Group>
						<Inputs.Group label={t('Phone number')}>
							<Inputs.Field
								name={FormFields.phoneNumber}
								type="tel"
								pattern={phoneRegexPattern}
								defaultValue={user.phoneNumber}
							/>
						</Inputs.Group>
					</Row>
					<Row>
						<Inputs.Group label={t('Company name')}>
							<Inputs.Field
								name={FormFields.companyName}
								defaultValue={user.companyName}
							/>
						</Inputs.Group>
						<Inputs.Group label={t(`Tax value`)}>
							<Inputs.Select
								name={FormFields.taxIdType}
								defaultValue={user.taxIdType}
								validate={handleValidateTaxInputs}>
								<option value="">{t(`Select ID-type`)}</option>
								{taxIdTypes.map((taxId) => (
									<option key={taxId} value={taxId}>
										{translatedTaxId(taxId)}
									</option>
								))}
							</Inputs.Select>
						</Inputs.Group>
						<Inputs.Group label={t(`Tax ID`)}>
							<Inputs.Field
								name={FormFields.taxIdValue}
								defaultValue={user.taxIdValue}
								validate={handleValidateTaxInputs}
							/>
						</Inputs.Group>
					</Row>
					<Inputs.Group label={t(`Date of birth`)}>
						<Row>
							<DateOfBirth.Year
								name={FormFields.birthYear}
								defaultValue={birthYear}
							/>
							<DateOfBirth.Month
								name={FormFields.birthMonth}
								defaultValue={birthMonth}
							/>
							<DateOfBirth.Day
								name={FormFields.birthDay}
								defaultValue={birthDay}
							/>
						</Row>
					</Inputs.Group>
					<Inputs.Group label={t(`Gender`)}>
						<Column spacing={styles.spacing._3}>
							<Inputs.Control
								name={FormFields.sex}
								value={UserSex.Unknown}
								type="radio"
								label={translatedSexString(UserSex.Unknown)}
								defaultChecked={user.sex === 0}
							/>
							<Inputs.Control
								name={FormFields.sex}
								value={UserSex.Male}
								type="radio"
								label={translatedSexString(UserSex.Male)}
								defaultChecked={user.sex === 1}
							/>
							<Inputs.Control
								name={FormFields.sex}
								value={UserSex.Female}
								type="radio"
								label={translatedSexString(UserSex.Female)}
								defaultChecked={user.sex === 2}
							/>
							<Inputs.Control
								name={FormFields.sex}
								value={UserSex.NotApplicable}
								type="radio"
								label={translatedSexString(UserSex.NotApplicable)}
								defaultChecked={user.sex === 9}
							/>
						</Column>
					</Inputs.Group>
					<Inputs.Group label={t('Street address')}>
						<Inputs.Field
							name={FormFields.address}
							defaultValue={user.address}
						/>
					</Inputs.Group>
					<Row>
						<Inputs.Group label={t('Region')}>
							<Inputs.Field
								name={FormFields.region}
								defaultValue={user.region}
							/>
						</Inputs.Group>
						<Inputs.Group label={t('City')}>
							<Inputs.Field name={FormFields.city} defaultValue={user.city} />
						</Inputs.Group>
						<Inputs.Group label={t('ZIP code')}>
							<Inputs.Field
								name={FormFields.postalCode}
								defaultValue={user.postalCode}
							/>
						</Inputs.Group>
					</Row>
					<Row>
						<Inputs.Group label={t('Country')} hint={t('Country of residence')}>
							<CountrySelect
								name={FormFields.country}
								value={userCountry}
								onChange={handleCountryChange}
							/>
						</Inputs.Group>
						<Inputs.Group label={t(`Nationality`)}>
							<CountrySelect
								role="nationality"
								name={FormFields.nationality}
								value={nationality}
								onChange={handleNationalityChange}
							/>
						</Inputs.Group>
						{country?.code === 'SE' && (
							<Inputs.Group
								label={t(`Personal ID Number`)}
								hint={t(`Same as in IdrottOnline for LOK. (YYYYMMDD-XXXX)`)}>
								<Inputs.Field
									name={FormFields.lokSwedishPersonalId}
									required={shouldValidatePersonalId}
									validate={shouldValidatePersonalId && validatePersonalNumber}
									placeholder="YYYYMMDD-XXXX"
									defaultValue={user.lokSwedishPersonalId}
								/>
							</Inputs.Group>
						)}
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Inputs.Group label={t('First name')} required>
						<Inputs.Field
							name={FormFields.firstName}
							required
							defaultValue={user.firstName}
						/>
					</Inputs.Group>
					<Inputs.Group label={t('Last name')}>
						<Inputs.Field
							name={FormFields.lastName}
							defaultValue={user.lastName}
						/>
					</Inputs.Group>
					<Inputs.Group label={t('Email')} required={emailRequired}>
						<Inputs.Field
							name={FormFields.email}
							type="email"
							pattern={emailRegexPattern}
							required={emailRequired}
							defaultValue={user.email}
						/>
					</Inputs.Group>
					<Inputs.Group label={t('Phone number')}>
						<Inputs.Field
							name={FormFields.phoneNumber}
							type="tel"
							defaultValue={user.phoneNumber}
						/>
					</Inputs.Group>
					<Inputs.Group label={t('Company name')}>
						<Inputs.Field
							name={FormFields.companyName}
							defaultValue={user.companyName}
						/>
					</Inputs.Group>
					<Inputs.Group label={t(`Tax value`)}>
						<Inputs.Select
							name={FormFields.taxIdType}
							defaultValue={user.taxIdType}>
							<option value="">{t(`Select ID-type`)}</option>
							{taxIdTypes.map((taxId) => (
								<option key={taxId} value={taxId}>
									{translatedTaxId(taxId)}
								</option>
							))}
						</Inputs.Select>
					</Inputs.Group>
					<Inputs.Group label={t(`Tax ID`)}>
						<Inputs.Field
							name={FormFields.taxIdValue}
							defaultValue={user.taxIdValue}
						/>
					</Inputs.Group>
					<Inputs.Group label={t(`Date of birth`)}>
						<Row>
							<DateOfBirth.Year
								name={FormFields.birthYear}
								defaultValue={birthYear}
							/>
							<DateOfBirth.Month
								name={FormFields.birthMonth}
								defaultValue={birthMonth}
							/>
							<DateOfBirth.Day
								name={FormFields.birthDay}
								defaultValue={birthDay}
							/>
						</Row>
					</Inputs.Group>
					<Inputs.Group label={t(`Gender`)}>
						<Column spacing={styles.spacing._3}>
							<Inputs.Control
								name={FormFields.sex}
								value={UserSex.Unknown}
								type="radio"
								label={translatedSexString(UserSex.Unknown)}
								defaultChecked={user.sex === 0}
							/>
							<Inputs.Control
								name={FormFields.sex}
								value={UserSex.Male}
								type="radio"
								label={translatedSexString(UserSex.Male)}
								defaultChecked={user.sex === 1}
							/>
							<Inputs.Control
								name={FormFields.sex}
								value={UserSex.Female}
								type="radio"
								label={translatedSexString(UserSex.Female)}
								defaultChecked={user.sex === 2}
							/>
							<Inputs.Control
								name={FormFields.sex}
								value={UserSex.NotApplicable}
								type="radio"
								label={translatedSexString(UserSex.NotApplicable)}
								defaultChecked={user.sex === 9}
							/>
						</Column>
					</Inputs.Group>
					<Inputs.Group label={t('Street address')}>
						<Inputs.Field
							name={FormFields.address}
							defaultValue={user.address}
						/>
					</Inputs.Group>
					<Inputs.Group label={t('Region')}>
						<Inputs.Field name={FormFields.region} defaultValue={user.region} />
					</Inputs.Group>
					<Inputs.Group label={t('City')}>
						<Inputs.Field name={FormFields.city} defaultValue={user.city} />
					</Inputs.Group>
					<Inputs.Group label={t('ZIP code')}>
						<Inputs.Field
							name={FormFields.postalCode}
							defaultValue={user.postalCode}
						/>
					</Inputs.Group>
					<Inputs.Group label={t('Country')} hint={t('Country of residence')}>
						<CountrySelect
							name={FormFields.country}
							value={userCountry}
							onChange={handleCountryChange}
						/>
					</Inputs.Group>

					<Inputs.Group label={t(`Nationality`)}>
						<CountrySelect
							role="nationality"
							name={FormFields.nationality}
							value={nationality}
							onChange={handleNationalityChange}
						/>
					</Inputs.Group>
					{country?.code === 'SE' && (
						<Inputs.Group
							label={t(`Personal ID Number`)}
							hint={t(`Same as in IdrottOnline for LOK. (YYYYMMDD-XXXX)`)}>
							<Inputs.Field
								name={FormFields.lokSwedishPersonalId}
								required={shouldValidatePersonalId}
								validate={shouldValidatePersonalId && validatePersonalNumber}
								placeholder="YYYYMMDD-XXXX"
								defaultValue={user.lokSwedishPersonalId}
							/>
						</Inputs.Group>
					)}
				</SmallScreen>

				<CustomFields
					organizationId={organizationId}
					prevData={user.fields}
					ignoreRequired
				/>
			</Column>
		</Section>
	);
};

export default DetailsSection;
