// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q9pbLAsruLrUqXG6e2yA {
	background-color: var(--palette-gray-300);
	margin-bottom: 8px;
	border-radius: var(--radius-2);
}
.Q9pbLAsruLrUqXG6e2yA:last-of-type {
		margin-bottom: 0;
	}
.Q9pbLAsruLrUqXG6e2yA.x3N5h3HKA4IAnImxjCLf {
		display: inline-block;
		margin-bottom: 0;
		margin-bottom: initial;
	}
`, "",{"version":3,"sources":["webpack://./components/loaders/skeleton/placeholders/styles.css"],"names":[],"mappings":"AAAA;CACC,yCAAyC;CACzC,kBAAkB;CAClB,8BAA8B;AAU/B;AARC;EACC,gBAAgB;CACjB;AAEA;EACC,qBAAqB;EACrB,gBAAoB;EAApB,sBAAoB;CACrB","sourcesContent":[".paragraphRow {\n\tbackground-color: var(--palette-gray-300);\n\tmargin-bottom: 8px;\n\tborder-radius: var(--radius-2);\n\n\t&:last-of-type {\n\t\tmargin-bottom: 0;\n\t}\n\n\t&.inline {\n\t\tdisplay: inline-block;\n\t\tmargin-bottom: unset;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var paragraphRow = `Q9pbLAsruLrUqXG6e2yA`;
export var inline = `x3N5h3HKA4IAnImxjCLf`;
export default ___CSS_LOADER_EXPORT___;
