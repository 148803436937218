import React, { Fragment } from 'react';
import { useT } from '@transifex/react';

import { FilterOperator } from 'pkg/filters';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentUser } from 'pkg/identity';
import { useWardsInCurrentGroup } from 'pkg/hooks/useWardsInCurrentGroup';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';

import { ListRow } from 'routes/user/registrations/submissions/ListRow';

import AssetImage from 'components/AssetImage';
import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';
import { Spinner } from 'components/loaders/spinner';

import * as Table from 'design/table';

export function Submissions(): JSX.Element {
	const t = useT();
	const user = useCurrentUser();
	const wards = useWardsInCurrentGroup();

	const userIds = [user.id];
	wards.map((ward) => userIds.push(ward.id));

	const filterGroups: FilterGroups = {
		[t('Data')]: {
			hidden: true,
			filters: {
				Data: {
					type: 'text',
					property: 'data',
					operator: FilterOperator.Contains,
				},
			},
		},
	};

	const filters = useFilters({
		groups: filterGroups,
	});

	filters.queryParam.set('submitted_by_user_ids', userIds.toString());
	filters.queryParam.set('submitted_for_user_ids', userIds.toString());

	const {
		records: formSubmissions,
		isLoading,
		pagination,
	} = useCollection<models.formSubmission.FormSubmission>(
		endpoints.FormSubmissions.Index(),
		{
			queryParams: filters.queryParam,
		}
	);

	if (isLoading) {
		return <Spinner />;
	}

	const columns = [
		{
			content: t('Form name'),
			width: 'min-content',
		},
		{
			content: t('Date submitted'),
			width: 'min-content',
		},
		{
			content: t('Submitted for'),
			width: '1fr',
		},
		{
			content: t('Submitted by'),
			width: '1fr',
		},
	];

	const emptyState = {
		title: t('There are no submissions'),
		content: t('No submissions available'),
		image: <AssetImage src="img/missing-entities/post.svg" />,
	};

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				pageActionIcon="more_horiz"
				filters={filters}
				searchFilter={filterGroups[t('Data')].filters.Data}
			/>
			<Table.Table
				emptyState={emptyState}
				stickyHeader
				stickyFooter
				columns={columns.filter((n) => n)}
				isLoading={isLoading}>
				{formSubmissions.map(
					(submission: models.formSubmission.FormSubmission) => (
						<ListRow key={submission.id} submission={submission} />
					)
				)}
			</Table.Table>
			<Pagination {...pagination} />
		</Fragment>
	);
}
