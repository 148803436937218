import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as arrays from 'pkg/arrays';
import { FilterOperator } from 'pkg/filters';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentOrganization } from 'pkg/identity';
import useUserFieldsFilter from 'pkg/hooks/useUserFieldsFilter';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';

import {
	getFilters,
	usersFilterConfig,
} from 'routes/organization/contacts/utils';

import Icon from 'components/icon';
import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';
import * as Input from 'components/form/inputs';
import { UserItem } from 'components/group/add-users-modal/UserItem';

import * as Table from 'design/table';

import * as css from './styles.css';

interface UsersStepProps {
	groupId: number;
	role: number;
	setSelectedUsers: (users: models.user.User[]) => void;
	selectedUsers: models.user.User[];
}

export const UsersStep = ({
	groupId,
	setSelectedUsers,
	selectedUsers,
}: UsersStepProps) => {
	const organization = useCurrentOrganization();
	const userFieldsFilter = useUserFieldsFilter();

	const filterGroups: FilterGroups = {
		...getFilters([
			usersFilterConfig().groups,
			usersFilterConfig().group_role,
			usersFilterConfig().sex,
			usersFilterConfig().account,
			usersFilterConfig().user_created,
			usersFilterConfig().birth_date,
			usersFilterConfig().parent_connection,
		]),
		...userFieldsFilter,
	};

	const filters = useFilters({
		groups: filterGroups,
		filterBarMobileTrigger: true,
		largeFilterButton: true,
		hideFiltersInUrl: true,
	});

	const {
		isLoading,
		records: users,
		pagination,
		sort,
	} = useCollection<models.user.User>(
		endpoints.Organizations.ListUsers(organization.id),
		{
			defaultSortBy: 'name',
			defaultSortByOrder: 'asc',
			queryParams: new URLSearchParams(filters.queryParam),
		}
	);

	const alreadyMember = (u: models.user.User) => {
		if (!u.groups) {
			return false;
		}

		return !!u.groups.filter((m) => m.groupId === groupId).length;
	};

	const hasSelectedUsers = selectedUsers?.length > 0;

	const userIds = users
		.filter((u) => !alreadyMember(u))
		.map((u: models.user.User) => u.id);

	const allSelected =
		hasSelectedUsers &&
		userIds.length ===
			selectedUsers.filter((u) => userIds.includes(u.id)).length;

	const handleToggleAll = () => {
		if (allSelected) {
			setSelectedUsers([]);
		} else {
			const uniqueMergedUsers = arrays.filterDuplicatesByKey<models.user.User>(
				[...selectedUsers, ...users.filter((u) => !alreadyMember(u))],
				'id'
			);

			setSelectedUsers(uniqueMergedUsers);
		}
	};

	const selectUser = (user: models.user.User) => {
		setSelectedUsers([...selectedUsers, user]);
	};

	const deselectUser = (user: models.user.User) => {
		const newSelectedUsers = selectedUsers.filter(
			(u: models.user.User) => u.id !== user.id
		);

		setSelectedUsers(newSelectedUsers);
	};

	const renderedUsers = users.map((user: models.user.User) => (
		<UserItem
			key={user.id}
			user={user}
			selectedUsers={selectedUsers}
			selectUser={selectUser}
			deselectUser={deselectUser}
			currentGroupId={groupId}
		/>
	));

	const emptyState: Table.EmptyStateProps = {
		image: (
			<Icon name="nav-members" size={1.6} className={css.emptyStateIcon} />
		),
		title: t('No users found'),
	};

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				filters={filters}
				fullWidth
				inModal
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				}}
			/>

			<Table.Table
				isLoading={isLoading}
				emptyState={emptyState}
				sortBy={sort.column}
				sortOrder={sort.order}
				onSort={sort.setSort}
				columns={[
					{
						content: (
							<Input.Control standalone checked={allSelected} type="checkbox" />
						),
						onClick: handleToggleAll,
						width: 'auto',
					},
					{ content: t('Name'), width: '1fr', sortKey: 'name' },
				]}>
				{renderedUsers}
			</Table.Table>

			<Pagination {...pagination} />
		</Fragment>
	);
};
