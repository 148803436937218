// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SsmnbA4m10w5GpSRJRQk {
	word-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	    -ms-hyphens: auto;
	        hyphens: auto;
}
`, "",{"version":3,"sources":["webpack://./routes/forms/single/styles.css"],"names":[],"mappings":"AAAA;CACC,qBAAyB;CACzB,qBAAqB;CACrB,sBAAsB;KACtB,iBAAa;SAAb,aAAa;AACd","sourcesContent":[".linkStyle {\n\toverflow-wrap: break-word;\n\tword-wrap: break-word;\n\tword-break: break-word;\n\thyphens: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export var linkStyle = `SsmnbA4m10w5GpSRJRQk`;
export default ___CSS_LOADER_EXPORT___;
