import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';

export interface CreateTaxRatePayload {
	display_name: string;
	inclusive: boolean;
	percentage: number;
	active?: boolean;
	description?: string;
	jurisdiction?: string;
}

export async function create(
	groupId: number,
	payload: CreateTaxRatePayload
): Promise<[boolean, models.taxRate.TaxRate]> {
	const [req, taxRate] = await models.create<
		CreateTaxRatePayload,
		models.taxRate.TaxRate
	>(endpoints.TaxRates.Create1(groupId), payload);

	if (!req.ok) {
		flashActions.show({
			title: t('Something went wrong'),
			message: t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			),
		});

		return [false, null];
	}

	flashActions.show({
		title: t('Successfully created tax rate!'),
	});

	return [true, taxRate];
}

export interface UpdateTaxRatePayload {
	display_name: string;
	active: boolean;
	description?: string;
	jurisdiction?: string;
}

export async function update(
	groupId: number,
	taxRateId: string,
	payload: UpdateTaxRatePayload
): Promise<[boolean, models.taxRate.TaxRate]> {
	const req = await sdk.patch(
		endpoints.TaxRates.Update1(groupId, taxRateId),
		{},
		payload
	);
	const resp = await req.json();

	if (!req.ok) {
		flashActions.show({
			title: t('Something went wrong'),
			message: t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			),
		});

		return [false, null];
	}

	flashActions.show({
		title: t('Successfully updated tax rate!'),
	});

	return [true, resp];
}

export async function removeTaxRate(
	groupId: number,
	taxRateId: string
): Promise<boolean> {
	const req = await sdk.destroy(endpoints.TaxRates.Delete1(groupId, taxRateId));

	if (!req.ok) {
		flashActions.show({
			title: t('Something went wrong'),
			message: t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			),
		});

		return false;
	}

	flashActions.show({
		title: t('Successfully removed tax rate'),
	});

	return true;
}
