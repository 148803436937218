import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';
import { Fragment, useEffect } from 'react';

import * as styles from 'pkg/config/styles';

import { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { useCurrentOrganization } from 'pkg/identity';
import { formatToCurrency } from 'pkg/i18n/formatters';

import {
	DiscountFormState,
	DiscountType,
	RecurringOptions,
} from 'routes/payments/discounts/create';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import Section from 'components/form/Section';
import Row from 'components/layout/row';
import AvailabilitySection from 'components/discount/form/availability';

interface CreateDiscountFormProps {
	formState: DiscountFormState;
	setFormState: MixedStateSetter<DiscountFormState>;
}

export default function CreateDiscountForm({
	formState,
	setFormState,
}: CreateDiscountFormProps) {
	const organization = useCurrentOrganization();
	const methods = useFormContext();
	const discountValue = methods.watch('discountValue');
	const duration = methods.watch('duration', RecurringOptions.Forever);

	useEffect(() => {
		if (duration === RecurringOptions.Forever) {
			methods.unregister('maxRecurringCount');
		}
	}, [duration]);

	const handleTypeChange = (type: DiscountType) => {
		setFormState({
			type,
		});
	};

	const handleActiveChange = () => {
		setFormState({
			active: !formState.active,
		});
	};

	const handleDiscountDivideChange = () => {
		setFormState({
			splitAmount: !formState.splitAmount,
		});
	};

	return (
		<Fragment>
			<Section
				title={t('Discount information')}
				description={t('Create a discount code for your customers!')}
				icon="info">
				<Column spacing={styles.spacing._8}>
					<Input.Group label={t('Title')} required>
						<Input.Field
							name="title"
							placeholder={t('Eg. My coupon code')}
							required
						/>
					</Input.Group>
					<Input.Group label={t('Description')}>
						<Input.Area name="description" minRows={3} />
					</Input.Group>
					<Input.Group
						label={t('Discount code')}
						required
						hint={t(
							'This will be the discount code the user fills in the checkout, it is case sensitive and use no blank spaces'
						)}>
						<Input.Field
							name="code"
							placeholder={t('Eg. MYCOUPON50')}
							required
						/>
					</Input.Group>
				</Column>
			</Section>
			<Section icon="settings" title={t('Type')}>
				<Column spacing={styles.spacing._8}>
					<Column spacing={styles.spacing._3}>
						<Input.Control
							type="radio"
							checked={formState.type === DiscountType.Percentage}
							label={t('Percentage')}
							value="percentage"
							onChange={handleTypeChange}
						/>
						<Input.Control
							type="radio"
							checked={formState.type === DiscountType.FixedAmount}
							label={t('Fixed amount')}
							value="fixedAmount"
							onChange={handleTypeChange}
						/>
					</Column>
					<Input.Group label={t('Discount value')} required>
						<Input.Field
							name="discountValue"
							required
							type="number"
							max={formState.type === 'percentage' ? '100' : 'false'}>
							<Input.Prefix>
								{formState.type === 'percentage'
									? '%'
									: organization.currency.toLocaleUpperCase()}
							</Input.Prefix>
						</Input.Field>
					</Input.Group>
				</Column>
			</Section>
			<Section title={t('Advanced settings')}>
				<Column spacing={styles.spacing._8}>
					<Row>
						<Input.Group label={t('Duration')}>
							<Input.Select name="duration">
								<option value={RecurringOptions.Forever}>{t('Forever')}</option>
								<option value={RecurringOptions.AmountOfPayments}>
									{t('Amount of payments')}
								</option>
							</Input.Select>
						</Input.Group>
						{duration !== RecurringOptions.Forever && (
							<Input.Group label={t('Amount')} required>
								<Input.Field
									name="maxRecurringCount"
									type="number"
									defaultValue={1}
									required>
									<Input.Suffix>{t('payments')}</Input.Suffix>
								</Input.Field>
							</Input.Group>
						)}
					</Row>
					{formState.type === DiscountType.FixedAmount &&
						duration !== RecurringOptions.Forever && (
							<Column spacing={styles.spacing._3}>
								<Input.Control
									type="radio"
									checked={!formState.splitAmount}
									label={t('Discount every invoice')}
									description={t(
										'Every invoice created by a subscription/installment will be discounted with {discount}.',
										{
											discount: formatToCurrency(
												Number.parseInt(discountValue || 0, 10),
												organization.currency
											),
										}
									)}
									onChange={handleDiscountDivideChange}
								/>
								<Input.Control
									type="radio"
									checked={formState.splitAmount}
									label={t('Divide total discount amount')}
									description={t(
										'Discount should be divided across the selected number of payments.'
									)}
									onChange={handleDiscountDivideChange}
								/>
							</Column>
						)}
				</Column>
			</Section>
			<AvailabilitySection
				active={formState.active}
				handleActiveChange={handleActiveChange}
			/>
		</Fragment>
	);
}
