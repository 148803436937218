import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import * as models from 'pkg/api/models';
import { Features } from 'pkg/api/models/group';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

import Orders from 'routes/organization/contacts/single/list/Orders';
import Groups from 'routes/organization/contacts/single/list/Groups';
import AssignedProducts from 'routes/organization/contacts/single/list/AssignedProducts';
import FormSubmissions from 'routes/organization/contacts/single/list/Submissions';
import Subscriptions from 'routes/organization/contacts/single/Subscriptions';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import RelativeView from 'components/user/profile/overview/RelatedUsers';

interface OverviewProps {
	group: models.group.Group;
	user: models.user.User;
	handleOpenModal: (name: string) => void;
}

const Overview: React.FC<React.PropsWithChildren<OverviewProps>> = ({
	group,
	user,
	handleOpenModal,
}) => {
	const { records: memberships, refresh } =
		useCollection<models.membership.Membership>(
			endpoints.UserGroups.GroupMemberships(group.id, user.id)
		);

	const userProducts = user?.products
		?.filter((userProduct) => userProduct.status !== 'expired')
		?.sort((a, b) => b.createdAt - a.createdAt);

	return (
		<LargeScreenContent.Inner maxWidth={PageWidths.WIDE} spacious>
			<Column spacing={styles.spacing._6}>
				<RelativeView userId={user.id} />
				<AssignedProducts
					userProducts={userProducts}
					contact={user}
					handleOpenModal={handleOpenModal}
				/>
				<Orders user={user} contact={user} />
				<Subscriptions user={user} />
				{models.group.hasFeature(group, Features.Forms) && (
					<FormSubmissions groupId={group.id} contactId={user.id} />
				)}
				<Groups
					user={user}
					memberships={memberships}
					refreshMemberships={refresh}
				/>
			</Column>
		</LargeScreenContent.Inner>
	);
};

export default Overview;
