import { t } from '@transifex/native';
import { useState } from 'react';

import spacing from 'pkg/config/spacing';

import Label from 'components/label';
import { FormattedContent } from 'components/formatted-content';
import StatusScreen from 'components/status-screen';
import * as Card from 'components/Card';
import {
	useSmallScreen,
	LargeScreen,
	SmallScreen,
} from 'components/MediaQuery';
import SectionTitle from 'components/SectionTitle';
import * as CardList from 'components/card-list';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import SelectableCard from 'components/form/selectablecard';
import InfoBox from 'components/form/info-box';
import Section from 'components/form/Section';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button, { ButtonGroup } from 'design/button';

import * as css from './styles.css';

const Registration = () => {
	const isSmallScreen = useSmallScreen();

	const mockUser = {
		id: 123,
		profileImageUrl:
			'https://assets-global.website-files.com/611b68bedd47db37c67ba1b3/611cb72b383be1e08952efaa_Alex-p-500.jpeg',
		organizationId: 123,
		firstName: 'Spike',
		lastName: 'Anderson',
		country: 'USA',
	};

	const mockUserChildOne = {
		id: 123,
		profileImageUrl:
			'https://assets-global.website-files.com/611b68bedd47db37c67ba1b3/6528e9f9264dedce358b6de7_isabella-p-500.png',
		organizationId: 123,
		firstName: 'Amanda',
		lastName: 'Anderson',
		country: 'USA',
	};

	const mockUserChildTwo = {
		id: 123,
		profileImageUrl:
			'https://assets-global.website-files.com/611b68bedd47db37c67ba1b3/611cb7a346c6bc66376d8211_Stefan-p-500.png',
		organizationId: 123,
		firstName: 'John',
		lastName: 'Anderson',
		country: 'USA',
	};

	const description =
		'CIK-hallen, Knivsta MAP Camp week 1: June 28-July 2, for goalies born 2013 & older Camp week 2: July 3-7, for goalies born 2013 & older The goalies are divided into different groups on the ice based on age. You find more information about this goalie camp HERE. To book the camp: – Choose the dates and which year the goalie is born at “Grupp Sommarcamp 2024 (född år)” below – Choose “Pay the full amount” or “Pay booking fee” – Click “Add to cart” If you choose to pay the booking fee separately, the final payment is due 2024-05-31. Log in to your player profile to make the final payment. We do not have any accommodation at this camp. Please don’t hesitate to contact us should you have any questions!';

	const summary = (
		<Column>
			<Section title={t('Summary')} hideDivider={!isSmallScreen}>
				<Card.Base $dark>
					<Card.Body>
						<Column spacing={spacing._5}>
							<Column spacing={spacing._2}>
								<Row columns="auto auto" align="stretch">
									<div>
										1x Product title with a long name that should span over
										multiple lines
									</div>
									<div className={css.amount}>100 €</div>
								</Row>
								<Row columns="auto auto" align="stretch">
									<div>1x Product title</div>
									<div className={css.amount}>100 €</div>
								</Row>
							</Column>
							<hr className={css.divider} />
							<Column spacing={spacing._3}>
								<Row columns="auto auto" align="stretch">
									<div className={css.labelLarge}>{t('Due today')}</div>
									<div className={css.amountLarge}>100 €</div>
								</Row>
								<Row columns="auto auto" align="stretch">
									<div className={css.labelLarge}>{t('Monthly') + ' x2 '}</div>
									<div className={css.amountLarge}>100 €</div>
								</Row>
								<Row columns="auto auto" align="stretch">
									<div className={css.labelLarge}>{t('Total')}</div>
									<div className={css.amountLarge}>300 €</div>
								</Row>
							</Column>
						</Column>
					</Card.Body>
				</Card.Base>
			</Section>
		</Column>
	);

	const [activeCard, setActiveCard] = useState(null);

	const handleCardClick = (cardId: string) => {
		setActiveCard(cardId);
	};

	return (
		<div>
			<Column spacing={spacing._10}>
				<Column spacing={spacing._8}>
					<Column>
						<Label color="green">{t('Open for registrations')}</Label>
						<SectionTitle large>
							{t('Summercamp 2024 - No image or registrants')}
						</SectionTitle>
					</Column>
					<Button
						block
						large
						primary
						icon="contact_page"
						label={t('Register')}
					/>
					<div className={css.registrationDescription}>
						<FormattedContent raw={description} />
					</div>
				</Column>
				<Column spacing={spacing._8}>
					<Column>
						<Label color="green">{t('Open for registrations')}</Label>
						<SectionTitle large>
							{t('Summercamp 2024 - Image and registrants')}
						</SectionTitle>
					</Column>
					<div className={css.image}>
						<img src="https://assets-global.website-files.com/611b68bedd47db37c67ba1b3/656da004cc6ba737f617af1f_Barca%20Academy%201.jpg" />
					</div>
					<Button
						block
						large
						primary
						icon="contact_page"
						label={t('Register')}
					/>
					<Column spacing={spacing._1}>
						<Column spacing={spacing._1}>
							<Column>
								<Section title={t('Registered')}>
									<Column>
										<CardList.Base>
											<CardList.Image>
												<Avatar user={mockUserChildOne} />
											</CardList.Image>
											<CardList.Title>
												Amanda Anderson
												<CardList.SubTitle>Farham FC</CardList.SubTitle>
											</CardList.Title>
											<CardList.Actions>
												<CardList.TextContent align="center">
													<CardList.SmallText>2023-02-23</CardList.SmallText>
												</CardList.TextContent>
											</CardList.Actions>
										</CardList.Base>
									</Column>
								</Section>
							</Column>
						</Column>
						<div className={css.registrationDescription}>
							<FormattedContent raw={description} />
						</div>
					</Column>
				</Column>
				<Column spacing={spacing._8}>
					<Column>
						<Label color="green">{t('Open for registrations')}</Label>
						<SectionTitle large>
							{t('Summercamp 2024 - Image no registrant')}
						</SectionTitle>
					</Column>
					<div className={css.image}>
						<img src="https://assets-global.website-files.com/611b68bedd47db37c67ba1b3/656da004cc6ba737f617af1f_Barca%20Academy%201.jpg" />
					</div>
					<Button
						block
						large
						primary
						icon="contact_page"
						label={t('Register')}
					/>
					<div className={css.registrationDescription}>
						<FormattedContent raw={description} />
					</div>
				</Column>
				<Column spacing={spacing._8}>
					<Column>
						<Label color="green">{t('Open for registrations')}</Label>
						<SectionTitle large>
							{t('Summercamp 2024 - Preseason tryouts rookies')}
						</SectionTitle>
					</Column>
					<div className={css.image}>
						<img src="https://assets-global.website-files.com/611b68bedd47db37c67ba1b3/656da004cc6ba737f617af1f_Barca%20Academy%201.jpg" />
					</div>
					<Button
						block
						large
						primary
						icon="contact_page"
						label={t('Register')}
					/>
					<Column spacing={spacing._1}>
						<Column spacing={spacing._1}>
							<Column>
								<Section title={t('Registered')}>
									<Column>
										<CardList.Base>
											<CardList.Image>
												<Avatar user={mockUserChildOne} />
											</CardList.Image>
											<CardList.Title>
												Amanda Anderson
												<CardList.SubTitle>Farham FC</CardList.SubTitle>
											</CardList.Title>
											<CardList.Actions>
												<CardList.TextContent align="center">
													<CardList.SmallText>2023-02-23</CardList.SmallText>
												</CardList.TextContent>
											</CardList.Actions>
										</CardList.Base>
									</Column>
								</Section>
							</Column>
						</Column>
						<div className={css.registrationDescription}>
							<FormattedContent raw={description} />
						</div>
					</Column>
				</Column>
				<Column spacing={spacing._8}>
					<Column>
						<Label color="gray">{t('Closed for registrations')}</Label>
						<SectionTitle large>
							{t('Summercamp 2024 - Closed for registrations example')}
						</SectionTitle>
					</Column>
					<div className={css.image}>
						<img src="https://assets-global.website-files.com/611b68bedd47db37c67ba1b3/656da004cc6ba737f617af1f_Barca%20Academy%201.jpg" />
					</div>
					<Column spacing={spacing._1}>
						<Column spacing={spacing._1}>
							<Column>
								<Section title={t('Registered')}>
									<Column>
										<CardList.Base>
											<CardList.Image>
												<Avatar user={mockUserChildOne} />
											</CardList.Image>
											<CardList.Title>
												Amanda Anderson
												<CardList.SubTitle>Farham FC</CardList.SubTitle>
											</CardList.Title>
											<CardList.Actions>
												<CardList.TextContent align="center">
													<CardList.SmallText>2023-02-23</CardList.SmallText>
												</CardList.TextContent>
											</CardList.Actions>
										</CardList.Base>
									</Column>
								</Section>
							</Column>
						</Column>
						<div className={css.registrationDescription}>
							<FormattedContent raw={description} />
						</div>
					</Column>
				</Column>
				<Column spacing={spacing._8}>
					<SectionTitle large>
						{t('Who should register for Summercamp 2024?')}
					</SectionTitle>
					<Column spacing={spacing._1}>
						<Column>
							<Section title={t('Your children')}>
								<Column>
									<CardList.Base>
										<CardList.Image>
											<Avatar user={mockUserChildOne} />
										</CardList.Image>
										<CardList.Title>
											Amanda Anderson
											<CardList.SubTitle>Farham FC</CardList.SubTitle>
										</CardList.Title>
										<CardList.Actions>
											<Icon name="chevron" size={1.8} />
										</CardList.Actions>
									</CardList.Base>
									<CardList.Base>
										<CardList.Image>
											<Avatar user={mockUserChildTwo} />
										</CardList.Image>
										<CardList.Title>
											John Anderson
											<CardList.SubTitle>Farham FC</CardList.SubTitle>
										</CardList.Title>
										<CardList.Actions>
											<Icon name="chevron" size={1.8} />
										</CardList.Actions>
									</CardList.Base>
									<Button
										block
										icon="person_add"
										label={t('Add a new child')}
									/>
								</Column>
							</Section>
						</Column>
						<Section title={t('Yourself')}>
							<CardList.Base>
								<CardList.Image>
									<Avatar user={mockUser} />
								</CardList.Image>
								<CardList.Title>
									Spike Anderson
									<CardList.SubTitle>Farham FC</CardList.SubTitle>
								</CardList.Title>
								<CardList.Actions>
									<Icon name="chevron" size={1.8} />
								</CardList.Actions>
							</CardList.Base>
						</Section>
					</Column>
				</Column>
				<Column>
					<Column spacing={spacing._8}>
						<SectionTitle large>{t('Summercamp 2024')}</SectionTitle>
						<InfoBox
							color="blue"
							text={t(
								'Some information regarding Amanda has been pre-filled. To edit that information, please do so by editing the user profile'
							)}
						/>

						<Section title={t('Registration for')}>
							<Column spacing={spacing._6}>
								<Column>
									<CardList.Base>
										<CardList.Image>
											<Avatar user={mockUserChildOne} />
										</CardList.Image>
										<CardList.Title>
											Amanda Anderson
											<CardList.SubTitle>Farham FC</CardList.SubTitle>
										</CardList.Title>
									</CardList.Base>
								</Column>
								<Input.Group label={t('First name')} required disabled>
									<Input.Field placeholder="Amanda" />
								</Input.Group>
								<Input.Group label={t('Last name')} required disabled>
									<Input.Field placeholder="Andersson" />
								</Input.Group>
								<Input.Group label={t('Email')} required disabled>
									<Input.Field placeholder="amanda@andersson.com" />
								</Input.Group>
								<Section
									inline
									title={t('Section title header')}
									description={t('Section title description')}
								/>
								<Input.Group label={t('Phone')}>
									<Input.Field placeholder="+4612345678" />
								</Input.Group>
							</Column>
						</Section>
					</Column>
					<ButtonGroup justifyContent={isSmallScreen ? 'stretch' : 'end'}>
						<Button large block={isSmallScreen} label={t('Back')} />
						<Button primary large block={isSmallScreen} label={t('Next')} />
					</ButtonGroup>
				</Column>
				<Column spacing={spacing._8}>
					<SectionTitle large>{t('Summercamp 2024')}</SectionTitle>
					<Row columns="3fr 1.5fr" spacing={spacing._7} collapseOnSmallScreens>
						<Column>
							<Column spacing={spacing._1}>
								<Section title={t('Products')}>
									<Column spacing={spacing._4}>
										<CardList.Base>
											<CardList.Title>
												Product title with a long name that should span over
												multiple lines
												<CardList.SubTitle>100 €</CardList.SubTitle>
											</CardList.Title>
											<CardList.Actions>
												<CardList.TextContent align="center">
													1 <CardList.SmallText>{t('Qty')}</CardList.SmallText>
												</CardList.TextContent>
											</CardList.Actions>
										</CardList.Base>
										<CardList.Base>
											<CardList.Title>
												Product title{' '}
												<CardList.SubTitle>100 €</CardList.SubTitle>
											</CardList.Title>
											<CardList.Actions>
												<Row autoColumns="max-content" align="center">
													<ButtonGroup spacing={spacing._2}>
														<Button
															secondary
															small={isSmallScreen}
															icon="remove"
														/>
														<Button
															secondary
															small={isSmallScreen}
															icon="add"
														/>
													</ButtonGroup>
													<CardList.TextContent align="center">
														1{' '}
														<CardList.SmallText>{t('Qty')}</CardList.SmallText>
													</CardList.TextContent>
												</Row>
											</CardList.Actions>
										</CardList.Base>
									</Column>
								</Section>
								<Section title={t('Optional addons')}>
									<Column spacing={spacing._4}>
										<CardList.Base>
											<CardList.Title>
												Product title{' '}
												<CardList.SubTitle>100 €</CardList.SubTitle>
											</CardList.Title>
											<CardList.Actions>
												<Row autoColumns="max-content" align="center">
													<ButtonGroup spacing={spacing._2}>
														<Button
															secondary
															small={isSmallScreen}
															icon="remove"
														/>
														<Button
															secondary
															small={isSmallScreen}
															icon="add"
														/>
													</ButtonGroup>
													<CardList.TextContent align="center">
														0{' '}
														<CardList.SmallText>{t('Qty')}</CardList.SmallText>
													</CardList.TextContent>
												</Row>
											</CardList.Actions>
										</CardList.Base>
									</Column>
								</Section>

								<Section title={t('How would you like to pay?')}>
									<Column spacing={spacing._4}>
										<SelectableCard
											onClick={() => handleCardClick('card1')}
											active={activeCard === 'card1'}
											title={t('Direct')}
											summaryTitle={t('Total') + ' 100 € '}
										/>
										<SelectableCard
											onClick={() => handleCardClick('card2')}
											active={activeCard === 'card2'}
											title={t('Monthly')}
											description={' 3 ' + t('Installments')}
											infoTitle={t('Due today') + ' 100 € '}
											infoDescription={t('Then monthly') + ' 100 € '}
											summaryTitle={t('Total') + ' 300 € '}
										/>
									</Column>
								</Section>
								<SmallScreen>{summary}</SmallScreen>
							</Column>
							<ButtonGroup justifyContent={isSmallScreen ? 'stretch' : 'end'}>
								<Button large block={isSmallScreen} label={t('Back')} />
								<Button primary large block={isSmallScreen} label={t('Next')} />
							</ButtonGroup>
						</Column>
						<LargeScreen>{summary}</LargeScreen>
					</Row>
				</Column>
				<Column spacing={spacing._8}>
					<SectionTitle large>{t('Summercamp 2024')}</SectionTitle>
					<Row columns="3fr 1.5fr" spacing={spacing._7} collapseOnSmallScreens>
						<Column spacing={spacing._1}>
							<Column spacing={spacing._7}>
								<Section title={t('Invoice information')}>
									<Column spacing={spacing._6}>
										<Input.Group label={t('First name')} required>
											<Input.Field />
										</Input.Group>
										<Input.Group label={t('Last name')} required>
											<Input.Field />
										</Input.Group>
										<Input.Group label={t('Email')} required>
											<Input.Field />
										</Input.Group>
										<Input.Group label={t('Phone')}>
											<Input.Field />
										</Input.Group>
									</Column>
								</Section>
							</Column>
							<SmallScreen>{summary}</SmallScreen>
							<ButtonGroup justifyContent={isSmallScreen ? 'stretch' : 'end'}>
								<Button large block={isSmallScreen} label={t('Back')} />
								<Button
									primary
									large
									block={isSmallScreen}
									label={t('Proceed to payment')}
								/>
							</ButtonGroup>
						</Column>
						<LargeScreen>{summary}</LargeScreen>
					</Row>
				</Column>
				<Column>
					<StatusScreen
						status="success"
						title={t('Success!')}
						description={t('The registration was made successfully!')}>
						<Button primary large>
							Go to home
						</Button>
					</StatusScreen>
					<StatusScreen
						status="error"
						title={t('Something went wrong!')}
						description={t(
							'There was a problem processing your registration or payment'
						)}>
						<Button primary large>
							Go to home
						</Button>
					</StatusScreen>
				</Column>
			</Column>
		</div>
	);
};

export default Registration;
