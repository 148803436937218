// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QOEejDnnrgbz6dJwj1YS {
	cursor: pointer;
	color: var(--palette-brand);
	color: var(--palette-group-accent-color, var(--palette-brand));
	background: rgb(var(--palette-gray-600) 0.5);
	background: var(
		--palette-group-accent-color-faded,
		rgb(var(--palette-gray-600) 0.5)
	);
	border-radius: var(--radius-6);
	width: 36px;
	height: 36px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.qwplW5iOUMZM_0GWG5mE {
	font-size: var(--font-size-lg);
	color: var(--palette-white);
	color: var(--palette-group-accent-color, var(--palette-white));
}
`, "",{"version":3,"sources":["webpack://./components/navigation/header/small_screen/page_actions/styles.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,2BAA8D;CAA9D,8DAA8D;CAC9D,4CAGC;CAHD;;;EAGC;CACD,8BAA8B;CAC9B,WAAW;CACX,YAAY;CACZ,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;AACxB;;AAEA;CACC,8BAA8B;CAC9B,2BAA8D;CAA9D,8DAA8D;AAC/D","sourcesContent":[".buttonWrap {\n\tcursor: pointer;\n\tcolor: var(--palette-group-accent-color, var(--palette-brand));\n\tbackground: var(\n\t\t--palette-group-accent-color-faded,\n\t\trgb(var(--palette-gray-600) 0.5)\n\t);\n\tborder-radius: var(--radius-6);\n\twidth: 36px;\n\theight: 36px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.icon {\n\tfont-size: var(--font-size-lg);\n\tcolor: var(--palette-group-accent-color, var(--palette-white));\n}\n"],"sourceRoot":""}]);
// Exports
export var buttonWrap = `QOEejDnnrgbz6dJwj1YS`;
export var icon = `qwplW5iOUMZM_0GWG5mE`;
export default ___CSS_LOADER_EXPORT___;
