import { Fragment } from 'react';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import * as flashActions from 'pkg/actions/flashes';

import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as routes from 'pkg/router/routes';
import { pushState, replaceState } from 'pkg/router/state';
import { isDev } from 'pkg/flags';
import { useCurrentOrganization } from 'pkg/identity';

import CreateDiscountForm from 'routes/payments/discounts/create/form';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import Form, { asNumber, asString, FormPayload } from 'components/form/Form';

import Button, { ButtonGroup } from 'design/button';

import { FormActionFooter } from 'styles/Form';

export enum DiscountType {
	Percentage = 'percentage',
	FixedAmount = 'fixedAmount',
}

export enum RecurringOptions {
	Forever = 'forever',
	AmountOfPayments = 'amountOfPayments',
}

export interface DiscountFormState {
	active: boolean;
	saving: boolean;
	splitAmount: boolean;
	type: DiscountType;
}

export function makeDiscountPayload(
	groupId: number,
	formState: DiscountFormState,
	formData: FormPayload
): models.discount.CreateDiscountPayload {
	const payload: models.discount.CreateDiscountPayload = {
		groupId,
		title: asString(formData.title),
		description: asString(formData.description),
		code: asString(formData.code),
	};

	if (formState.type === 'percentage') {
		payload.percentOff = asNumber(formData.discountValue);
	} else {
		payload.amountOff = asNumber(formData.discountValue) * 100;
	}

	if (formState.active) {
		payload.isActive = true;
	}

	if (formData.maxRecurringCount) {
		payload.maxRecurringCount = asNumber(formData.maxRecurringCount);
	}
	if (
		formState.type === DiscountType.FixedAmount &&
		formData.duration === RecurringOptions.AmountOfPayments
	) {
		payload.splitAmount = formState.splitAmount;
	}

	if (isDev()) {
		if (formData.maxUsageCount) {
			payload.maxUsageCount = asNumber(formData.maxUsageCount);
		}
	}

	return payload;
}

export default function CreateDiscount() {
	const org = useCurrentOrganization();
	const [formState, setFormState] = useMixedState<DiscountFormState>({
		type: DiscountType.Percentage,
		splitAmount: false,
		active: true,
		saving: false,
	});

	const handleSubmit = async (data: FormPayload) => {
		setFormState({ saving: true });

		const payload = makeDiscountPayload(org.id, formState, data);

		const [req] = await models.create<
			models.discount.CreateDiscountPayload,
			models.discount.Discount
		>(endpoints.Discount.Create(), payload);

		if (req.ok) {
			flashActions.show({
				title: t('Successfully created discount'),
			});

			pushState(routes.Discounts.Index(org.id));
		}

		setFormState({ saving: false });
	};

	return (
		<Form onSubmit={handleSubmit}>
			<View formState={formState} setFormState={setFormState} />
		</Form>
	);
}

interface ViewProps {
	formState: DiscountFormState;
	setFormState: MixedStateSetter<DiscountFormState>;
}

function View({ formState, setFormState }: ViewProps) {
	const org = useCurrentOrganization();
	const handleCancel = () => {
		replaceState(routes.Discounts.Index(org.id));
	};

	const content = (
		<CreateDiscountForm formState={formState} setFormState={setFormState} />
	);

	const isSmallScreen = useSmallScreen();

	return (
		<Fragment>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					label={t('Cancel')}
					onClick={handleCancel}
				/>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					label={t('Save')}
					primary
					type="submit"
					busy={formState.saving}
				/>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					{content}
					<FormActionFooter>
						<ButtonGroup>
							<Button label={t('Cancel')} onClick={handleCancel} />
							<Button
								label={t('Save')}
								primary
								type="submit"
								isLoading={formState.saving}
							/>
						</ButtonGroup>
					</FormActionFooter>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
}
