// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n4wQiFkbVZj5DBwFQf2S {
	font-size: var(--font-size-5xl);
	line-height: var(--font-line-height-4xl);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-black);
	position: relative;
	padding-left: var(--spacing-8);
}

@media (max-width: 768px) {

.n4wQiFkbVZj5DBwFQf2S {
		margin-top: var(--spacing-4);
		font-size: var(--font-size-2xl);
		line-height: var(--font-line-height-2xl);
		padding-left: var(--spacing-6);
}
	}

.n4wQiFkbVZj5DBwFQf2S::before {
		display: block;
		content: '';
		background: var(--palette-group-primary-color);
		width: 7px;
		border-radius: var(--radius-4);
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
	}

.L1oG9eZfJiccMsJHdJXA {
	min-width: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	padding: var(--spacing-8) var(--spacing-5) var(--spacing-8) var(--spacing-5);
}

@media (max-width: 768px) {

.L1oG9eZfJiccMsJHdJXA {
		padding: 0;
		overflow: visible;
}
	}
`, "",{"version":3,"sources":["webpack://./routes/dashboard/organization/styles.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,wCAAwC;CACxC,wCAAwC;CACxC,2BAA2B;CAC3B,kBAAkB;CAClB,8BAA8B;AAoB/B;;AAlBC;;AARD;EASE,4BAA4B;EAC5B,+BAA+B;EAC/B,wCAAwC;EACxC,8BAA8B;AAchC;CAbC;;AAEA;EACC,cAAc;EACd,WAAW;EACX,8CAA8C;EAC9C,UAAU;EACV,8BAA8B;EAC9B,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,SAAS;CACV;;AAGD;CACC,YAAY;CACZ,cAAc;CACd,iCAAiC;CACjC,4EAA4E;AAM7E;;AAJC;;AAND;EAOE,UAAU;EACV,iBAAiB;AAEnB;CADC","sourcesContent":[".title {\n\tfont-size: var(--font-size-5xl);\n\tline-height: var(--font-line-height-4xl);\n\tfont-weight: var(--font-weight-semibold);\n\tcolor: var(--palette-black);\n\tposition: relative;\n\tpadding-left: var(--spacing-8);\n\n\t@media (--small-viewport) {\n\t\tmargin-top: var(--spacing-4);\n\t\tfont-size: var(--font-size-2xl);\n\t\tline-height: var(--font-line-height-2xl);\n\t\tpadding-left: var(--spacing-6);\n\t}\n\n\t&::before {\n\t\tdisplay: block;\n\t\tcontent: '';\n\t\tbackground: var(--palette-group-primary-color);\n\t\twidth: 7px;\n\t\tborder-radius: var(--radius-4);\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\tbottom: 0;\n\t}\n}\n\n.wrapper {\n\tmin-width: 0;\n\toverflow: auto;\n\t-webkit-overflow-scrolling: touch;\n\tpadding: var(--spacing-8) var(--spacing-5) var(--spacing-8) var(--spacing-5);\n\n\t@media (--small-viewport) {\n\t\tpadding: 0;\n\t\toverflow: visible;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `n4wQiFkbVZj5DBwFQf2S`;
export var wrapper = `L1oG9eZfJiccMsJHdJXA`;
export default ___CSS_LOADER_EXPORT___;
