import { Linkable } from 'pkg/api/models/linkable';
import * as sdk from 'pkg/core/sdk';

export * as account from './account';
export * as accountNotification from './account_notification';
export * as accountInvite from './account_invite';
export * as accountRelation from './account_relation';
export * as attachment from './attachment';
export * as booking from './booking';
export * as collection from './collection';
export * as country from './country';
export * as discount from './discount';
export * as chat from './chat';
export * as chatUser from './chat_user';
export * as chatMessage from './chat_message';
export * as chatReaction from './chat_reaction';
export * as event from './event';
export * as eventComment from './event_comment';
export * as eventUser from './event_user';
export * as eventSeries from './event_series';
export * as eventSeriesUser from './event_series_user';
export * as eventLog from './event_log';
export * as eventReport from './event_report';
export * as exercise from './exercise';
export * as externalService from './external_service';
export * as form from './form';
export * as formCategory from './form_category';
export * as formSubmission from './form_submission';
export * as group from './group';
export * as groupPost from './group_post';
export * as groupPostComment from './group_post_comment';
export * as groupLink from './group_link';
export * as groupLinkFolder from './group_link_folder';
export * as groupContactInformation from './group_contact_information';
export * as identityProvider from './identity_provider';
export * as language from './language';
export * as match from './match';
export * as matchOpponent from './match_opponent';
export * as matchCompetition from './match_competition';
export * as matchVideo from './match_video';
export * as matchEvent from './match_event';
export * as membership from './membership';
export * as onboardingInfo from './onboarding_info';
export * as orderReport from './order_report';
export * as order from './order';
export * as orderRow from './order_row';
export * as orderRefund from './order_refund';
export * as organization from './organization';
export * as paymentPreview from './payment_preview';
export * as payout from './payout';
export * as payoutRow from './payout_row';
export * as position from './position';
export * as product from './product';
export * as productCategory from './product_category';
export * as productPrice from './product_price';
export * as providerSettings from './provider_settings';
export * as resource from './resource';
export * as resourceCategory from './resource_category';
export * as resourceLocation from './resource_location';
export * as schedule from './schedule';
export * as scheduleItem from './schedule_item';
export * as session from './session';
export * as sessionBlock from './session_block';
export * as sessionBlockItem from './session_block_item';
export * as subscription from './subscription';
export * as sport from './sport';
export * as tag from './tag';
export * as taxRate from './tax_rate';
export * as trainingCollection from './training_collection';
export * as user from './user';
export * as userActivity from './user_activity';
export * as userAddress from './user_address';
export * as userFields from './user_fields';
export * as userProduct from './user_product';
export * as video from './video';
export * as videoCollection from './video_collection';
export * as videoPlaylist from './video_playlist';
export * as videoSequence from './video_sequence';

export interface APIError {
	error: string;
}

/**
 *	Validates whether a resource is editable.
 *
 *	@param Linkable resource
 *
 *	@returns boolean
 */
export function canEdit(resource: Linkable): boolean {
	return !!resource.links?.edit;
}

/**
 *	Validates whether a resource is deleteable.
 *
 *	@param Linkable resource
 *
 *	@returns boolean
 */
export function canDelete(resource: Linkable): boolean {
	return !!resource.links?.delete;
}

/**
 *	Validates the existence of a link (permission) within a resource
 *
 *	@param Linkable resource
 *	@param string linkName
 *
 *	@returns boolean
 */
export function hasLink(resource: Linkable, linkName: string): boolean {
	return !!resource.links?.hasOwnProperty(linkName);
}

/**
 *	Destroys a (linkable) resource.
 *
 *	@param Linkable resource
 *
 *	@returns Promise<boolean>
 */
export async function destroy(resource: Linkable): Promise<boolean> {
	if (canDelete(resource)) {
		const request = await sdk.destroy(resource.links.delete);

		return request.ok;
	}

	return false;
}

/**
 *	Sends an update request for a single resource.
 *
 *	@param Linkable resource
 *	@param PayloadType payload
 *
 *	@returns Promise<[Response, ResponseType]>
 */
export async function update<PayloadType, ResponseType>(
	resource: Linkable,
	payload: PayloadType,
	uriParams = {}
): Promise<[Response, ResponseType, APIError?]> {
	if (Object.keys(payload).length === 0) {
		return [new Response(null, { status: 400 }), null, null];
	}

	if (!canEdit(resource)) {
		return [new Response(null, { status: 403 }), null, null];
	}

	const request = await sdk.patch(resource.links.edit, uriParams, payload);

	if (!request.ok) {
		const response: APIError = await request.json();
		return [request, null, response];
	}

	const response: ResponseType = await request.json();

	return [request, response, null];
}

/**
 *	Sends an create request for a single resource.
 *
 *	@param string endpoint
 *	@param PayloadType payload
 *
 *	@returns Promise<[Response, ResponseType]>
 */
export async function create<PayloadType, ResponseType>(
	endpoint: string,
	payload: PayloadType
): Promise<[Response, ResponseType, APIError?]> {
	if (payload !== null && Object.keys(payload).length === 0) {
		return [new Response(null, { status: 400 }), null, null];
	}

	const request = await sdk.post(endpoint, {}, payload);

	if (!request.ok) {
		const response: APIError = await request.json();
		return [request, null, response];
	}

	const response: ResponseType = await request.json();

	return [request, response, null];
}

/**
 *	Reactivates a single resource by id
 *
 *	@param string endpoint
 *
 *	@returns Promise<[Response, ResponseType]>
 */
export async function reactivate(
	endpoint: string
): Promise<[Response, ResponseType]> {
	const request = await sdk.post(endpoint, {}, {});
	const response: ResponseType = await request.json();

	return [request, response];
}

/**
 *	Sharing options for models
 */
export enum SharedWith {
	Account = 'only_you',
	Group = 'team',
	Club = 'club',
}

/**
 *	Verifies if a model is shared with team, club or it's owner/author.
 */
export function sharedWith<T extends Record<string, any>>(
	model: T
): SharedWith {
	// Validates existance of, and value of a property
	const validateProperty = <T = boolean>(
		property: string,
		value: T
	): boolean => {
		return property in model && model[property] === value;
	};

	const sharedWithGroup = validateProperty<number>('groupId', 0) === false;

	const sharedWithClub =
		sharedWithGroup &&
		(validateProperty('isClubCollection', true) ||
			validateProperty('inherit', true));

	if (sharedWithClub) {
		return SharedWith.Club;
	} else if (sharedWithGroup) {
		return SharedWith.Group;
	}

	return SharedWith.Account;
}

/**
 *	Sends an create request for CSV exports.
 *
 *	@param string endpoint
 *	@param PayloadType payload
 *
 *	@returns Promise<[boolean, string]>
 */
export async function createExport<UriPattern, PayloadType>(
	endpoint: string,
	payload: PayloadType,
	uriPattern?: UriPattern
): Promise<[boolean, string]> {
	if (payload !== null && Object.keys(payload).length === 0) {
		return [false, null];
	}

	const request = await sdk.post(endpoint, uriPattern || {}, payload);

	if (request.ok) {
		const text = await request.text();
		return [true, text];
	}

	return [false, ''];
}
