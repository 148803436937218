import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import { phoneRegexPattern } from 'pkg/strings';

import BirthDateInput from 'routes/user/settings/BirthDateInput';
import CustomFields from 'routes/payments/hooks/contact_form_sections/CustomFields';

import { useSmallScreen } from 'components/MediaQuery';
import CountrySelect from 'components/CountrySelect';
import Avatar from 'components/avatar';

import { checkIfShouldValidatePersonalNumber } from 'components/user/missing-information';
import ImageEditor from 'components/image/Editor';
import Row from 'components/layout/row';
import * as Inputs from 'components/form/inputs';
import Section from 'components/form/Section';
import Column from 'components/layout/column';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

interface SettingsFormProps {
	group: models.group.Group; //old model because of useCurrentGroup
	user: models.user.User;
	avatar: string;

	onChangeAvatar: (image: string) => void;
}

const SettingsForm = ({
	group,
	user,
	avatar,
	onChangeAvatar,
}: SettingsFormProps) => {
	const formContext = useFormContext();

	const personalIdWatch = formContext.watch(
		'lokSwedishPersonalId',
		user.lokSwedishPersonalId
	);
	const nationality = formContext.watch('nationality', user.nationality);
	const country = formContext.watch('country', user.country);

	const avatarUrl = avatar
		? `data:image/png;base64, ${avatar}`
		: user.profileImageUrl;

	const reservedFieldKeys = (user.organization.userFields || [])
		.filter((field) => field.type === models.userFields.UserFieldType.Reserved)
		.filter((field) => field.required)
		.map((field) => field.key);

	const shouldValidatePersonalId = checkIfShouldValidatePersonalNumber(
		nationality,
		personalIdWatch,
		false
	);

	const required = (key: string) => ({
		required: reservedFieldKeys.includes(`user.${key}`),
	});
	const isSmallScreen = useSmallScreen();

	return (
		<Fragment>
			<Section
				title={t('Base information')}
				testid="user_profile.base_information_title">
				<Column spacing={styles.spacing._7}>
					<Row>
						<Inputs.Group label={t('Avatar')}>
							<ContextMenu.Menu
								toggleWith={
									avatarUrl ? (
										<Avatar
											user={user}
											customImageUrl={avatarUrl}
											size={100}
											isEditable
											testid="user_profile.avatar_image_button"
										/>
									) : (
										<Button
											block={isSmallScreen}
											label={t('Upload new avatar')}
											secondary
											icon="upload"
											testid="user_profile.upload_avatar_button"
										/>
									)
								}>
								<ContextMenu.MenuContext.Consumer>
									{({ close }) => (
										<ContextMenu.Pane>
											<ImageEditor
												image={avatarUrl}
												onSave={onChangeAvatar}
												afterSave={close}
											/>
										</ContextMenu.Pane>
									)}
								</ContextMenu.MenuContext.Consumer>
							</ContextMenu.Menu>
						</Inputs.Group>
					</Row>

					<Row collapseOnSmallScreens collapsedSpacing={styles.spacing._7}>
						<Inputs.Group label={t('First name')} required>
							<Inputs.Field
								name="firstName"
								defaultValue={user.firstName}
								required
							/>
						</Inputs.Group>

						<Inputs.Group label={t('Last name')} {...required('lastName')}>
							<Inputs.Field
								name="lastName"
								defaultValue={user.lastName}
								{...required('lastName')}
							/>
						</Inputs.Group>
					</Row>

					<Row>
						<BirthDateInput user={user} {...required('birthDate')} />
					</Row>

					<Inputs.Group label={t('Gender')} {...required('gender')}>
						<Column spacing={styles.spacing._3}>
							<Inputs.Control
								value={models.user.UserSex.Unknown}
								defaultChecked={user.sex === models.user.UserSex.Unknown}
								type="radio"
								label={t('Not set')}
								name="sex"
								testid="genderNotSet"
							/>
							<Inputs.Control
								value={models.user.UserSex.Male}
								defaultChecked={user.sex === models.user.UserSex.Male}
								type="radio"
								label={t('Male')}
								name="sex"
								testid="genderMale"
							/>
							<Inputs.Control
								value={models.user.UserSex.Female}
								defaultChecked={user.sex === models.user.UserSex.Female}
								type="radio"
								label={t('Female')}
								name="sex"
								testid="genderFemale"
							/>
							<Inputs.Control
								value={models.user.UserSex.NotApplicable}
								defaultChecked={user.sex === models.user.UserSex.NotApplicable}
								type="radio"
								label={t('Not applicable')}
								name="sex"
								testid="genderNotApplicable"
							/>
						</Column>
					</Inputs.Group>

					<Inputs.Group label={t('Your height in centimeters (cm)')}>
						<Inputs.Field
							name="height"
							type="number"
							defaultValue={models.user.height(user)}
						/>
					</Inputs.Group>

					<Row collapseOnSmallScreens collapsedSpacing={styles.spacing._7}>
						<Inputs.Group label={t('Nationality')}>
							<CountrySelect
								role="nationality"
								name="nationality"
								value={nationality}
								suggestedCountry={user.country}
								{...required('nationality')}
							/>
						</Inputs.Group>
						{user.organization.country?.code === 'SE' && (
							<Inputs.Group label={t('Personal ID number')}>
								<Inputs.Field
									name="lokSwedishPersonalId"
									required={shouldValidatePersonalId}
									defaultValue={user.lokSwedishPersonalId}
									placeholder={t('YYYYMMDD-XXXX')}
									validate={
										shouldValidatePersonalId &&
										models.user.validatePersonalNumber
									}
								/>
							</Inputs.Group>
						)}
					</Row>

					{user.organization.country?.code === 'SE' && (
						<Inputs.Group label={t('Handicap')}>
							<Column spacing={styles.spacing._3}>
								<Inputs.Control
									value="!has_handicap"
									type="radio"
									label={t('Not set')}
									name="handicap"
								/>
								<Inputs.Control
									value="has_handicap"
									type="radio"
									label={t('Has handicap')}
									name="handicap"
								/>
							</Column>
						</Inputs.Group>
					)}
				</Column>
			</Section>

			{/* Height calculations / estimated height */}
			{!models.user.isAnAdult(user) && (
				<Section
					title={t('Height calculations')}
					description={t(
						'We use both your birth parents height to calculate your estimated height when your growth disminishes.'
					)}>
					<Column spacing={styles.spacing._7}>
						<Row>
							<Inputs.Group label={t("Mother's height")}>
								<Inputs.Field
									name="mother-height"
									defaultValue={Number.parseInt(
										models.user.getMeta(
											user,
											models.user.MetaField.MotherHeight
										),
										10
									)}
								/>
							</Inputs.Group>

							<Inputs.Group label={t("Father's height")}>
								<Inputs.Field
									name="father-height"
									defaultValue={Number.parseInt(
										models.user.getMeta(
											user,
											models.user.MetaField.FatherHeight
										),
										10
									)}
								/>
							</Inputs.Group>
						</Row>

						{Number.parseInt(
							models.user.getMeta(user, models.user.MetaField.MotherHeight),
							10
						) > 0 &&
						Number.parseInt(
							models.user.getMeta(user, models.user.MetaField.FatherHeight),
							10
						) > 0 &&
						(user.sex === 1 || user.sex === 2) ? (
							<Row>
								{`${t('Projected height')}: ${new Intl.NumberFormat(
									DateTime.getLocale()
								).format(models.user.getEstimatedHeight(user))}`}
							</Row>
						) : null}
					</Column>
				</Section>
			)}

			{/* Contact information */}
			<Section title={t('Contact information')} hideDivider>
				<Column spacing={styles.spacing._7}>
					<Row collapseOnSmallScreens collapsedSpacing={styles.spacing._7}>
						<Inputs.Group label={t('Email')} {...required('email')}>
							<Inputs.Field
								name="email"
								defaultValue={user.email}
								type="email"
								{...required('email')}
							/>
						</Inputs.Group>

						<Inputs.Group
							label={t('Cellphone number')}
							{...required('phoneNumber')}>
							<Inputs.Field
								name="phoneNumber"
								type="tel"
								pattern={phoneRegexPattern}
								defaultValue={user.phoneNumber}
								{...required('phoneNumber')}
							/>
						</Inputs.Group>
					</Row>

					<Inputs.Group label={t('Street address')} {...required('address')}>
						<Inputs.Field
							name="address"
							defaultValue={user.address}
							{...required('address')}
						/>
					</Inputs.Group>

					<Row>
						<Inputs.Group label={t('ZIP code')} {...required('postalCode')}>
							<Inputs.Field
								name="postalCode"
								defaultValue={user.postalCode}
								{...required('postalCode')}
							/>
						</Inputs.Group>

						<Inputs.Group label={t('City')} {...required('city')}>
							<Inputs.Field
								name="city"
								defaultValue={user.city}
								{...required('city')}
							/>
						</Inputs.Group>
					</Row>

					<Row collapseOnSmallScreens collapsedSpacing={styles.spacing._7}>
						<Inputs.Group label={t('State or region')}>
							<Inputs.Field name="region" defaultValue={user.address} />
						</Inputs.Group>

						<Inputs.Group label={t('Country')}>
							<CountrySelect
								name="country"
								value={country}
								suggestedCountry={user.nationality}
								{...required('country')}
							/>
						</Inputs.Group>
					</Row>
					<CustomFields
						organizationId={models.group.getOrganizationId(group)}
						prevData={user.fields}
					/>
				</Column>
			</Section>
		</Fragment>
	);
};

export default SettingsForm;
