// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HoyUykUvkHT8qc7qHuob {
	margin-right: var(--spacing-3);
}
`, "",{"version":3,"sources":["webpack://./routes/group/calendar/components/action-bar/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;AAC/B","sourcesContent":[".todayButton {\n\tmargin-right: var(--spacing-3);\n}\n"],"sourceRoot":""}]);
// Exports
export var todayButton = `HoyUykUvkHT8qc7qHuob`;
export default ___CSS_LOADER_EXPORT___;
