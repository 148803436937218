import { CollectionResponse, useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Session } from 'pkg/api/models/session';
import { TrainingCollection } from 'pkg/api/models/training_collection';
import { Exercise } from 'pkg/api/models/exercise';
import { useQueryState } from 'pkg/hooks/query-state';
import {
	useCurrentGroup,
	useCurrentSport,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';

/**
 *	Behaves like a CollectionPagination without page data.
 *
 *	@prop boolean hasPrev
 *	@prop boolean hasNext
 *	@prop callable fetchNext
 *	@prop callable fetchPrev
 */
export interface AggregatedCollectionPagination {
	hasPrev: boolean;
	hasNext: boolean;
	fetchPrev: () => void;
	fetchNext: () => void;
}

/**
 *	Behaves like a CollectionResponse without all the callbacks, used for aggregated results.
 *
 *	@prop boolean isLoading
 *	@prop T[] records
 *	@prop AggregatedCollectionPagination pagination
 *	@prop callable removeRecord
 *	@prop callable replaceRecord
 */
export interface AggregatedCollectionResponse<T> {
	isLoading: boolean;
	records: T[];
	pagination: AggregatedCollectionPagination;
	refresh?: () => Promise<void>;
	removeRecord?: (recordId: number) => void;
	replaceRecord?: (record: T) => void;
}

/**
 *	Fetches sessions for user
 *
 *	@param number organizationId
 *	@param number userId
 *	@param number limit
 *
 *	@returns CollectionResponse<Session>
 */
export function useUserSessions(
	organizationId: number,
	userId: number,
	limit: number = 10
): CollectionResponse<Session> {
	const sport = useCurrentSport();
	const filters = useTrainingLibraryFilters();

	return useCollection<Session>(
		endpoints.TrainingSessions.Index(organizationId),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				limit: limit.toString(),
				sports: sport.id.toString(),
				user_id: userId.toString(),
				...filters.getSessionFilterParams(),
			}),
		}
	);
}

/**
 *	Fetches execrises for user
 *
 *	@param number userId
 *	@param number limit
 *
 *	@todo Add support for additional filters, such as tags, min/max values etc.
 *
 *	@returns CollectionResponse<Session>
 */
export function useUserExercises(
	userId: number,
	limit: number = 10
): CollectionResponse<Exercise> {
	const sport = useCurrentSport();
	const filters = useTrainingLibraryFilters();

	const params: { [key: string]: string } = {
		user_id: userId.toString(),
		limit: limit.toString(),
		sports: sport.id.toString(),
		...filters.getExerciseFilterParams(),
	};

	return useCollection<Exercise>(endpoints.Exercises.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams(params),
	});
}

/**
 *	Fetches training collections for user.
 *
 *	@returns CollectionResponse<TrainingCollection>
 */
export function useUserCollections(): CollectionResponse<TrainingCollection> {
	const sport = useCurrentSport();
	const org = useCurrentOrganization();

	return useCollection<TrainingCollection>(
		endpoints.TrainingCollections.Index(org.id),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				sports: sport.id.toString(),
			}),
		}
	);
}

/**
 *	Fetches sessions for group
 *
 *	@param number limit
 *
 *	@returns CollectionResponse<Session>
 */
export function useGroupSessions(
	groupId: number,
	limit: number = 10
): CollectionResponse<Session> {
	const sport = useCurrentSport();
	const filters = useTrainingLibraryFilters();
	const org = useCurrentOrganization();

	return useCollection<Session>(endpoints.TrainingSessions.Index(org.id), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			limit: limit.toString(),
			group_id: groupId.toString(),
			sports: sport.id.toString(),
			...filters.getSessionFilterParams(),
		}),
	});
}

/**
 *	Fetches execrises for group
 *
 *	@param number limit
 *
 *	@todo Add support for additional filters, such as tags, min/max values etc.
 *
 *	@returns CollectionResponse<Session>
 */
export function useGroupExercises(
	groupId: number,
	limit: number = 10
): CollectionResponse<Exercise> {
	const sport = useCurrentSport();
	const filters = useTrainingLibraryFilters();

	const params: { [key: string]: string } = {
		group_id: groupId.toString(),
		limit: limit.toString(),
		sports: sport.id.toString(),
		...filters.getExerciseFilterParams(),
	};

	return useCollection<Exercise>(endpoints.Exercises.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams(params),
	});
}

/**
 *	Fetches training collections for group.
 *
 *	@returns CollectionResponse<TrainingCollection>
 */
export function useGroupCollections(
	groupId: number
): CollectionResponse<TrainingCollection> {
	const sport = useCurrentSport();
	const org = useCurrentOrganization();

	return useCollection<TrainingCollection>(
		endpoints.TrainingCollections.Index(org.id),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				sports: sport.id.toString(),
				group_id: groupId.toString(),
			}),
		}
	);
}

/**
 *	Fetches training collections for group.
 *
 *	@returns CollectionResponse<TrainingCollection>
 */
export function useClubCollections(
	groupId: number
): CollectionResponse<TrainingCollection> {
	const sport = useCurrentSport();
	const org = useCurrentOrganization();

	return useCollection<TrainingCollection>(
		endpoints.TrainingCollections.Index(org.id),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
				sports: sport.id.toString(),
				inherited: '1',
			}),
		}
	);
}

/**
 *	Fetches sessions for club
 *
 *	@param number groupId
 *	@param number limit
 *
 *	@returns CollectionResponse<Session>
 */
export function useClubSessions(
	groupId: number,
	limit: number = 10
): CollectionResponse<Session> {
	const sport = useCurrentSport();
	const filters = useTrainingLibraryFilters();
	const org = useCurrentOrganization();

	return useCollection<Session>(endpoints.TrainingSessions.Index(org.id), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			limit: limit.toString(),
			group_id: groupId.toString(),
			sports: sport.id.toString(),
			inherited: '1',
			...filters.getSessionFilterParams(),
		}),
	});
}

/**
 *	Fetches execrises for group
 *
 *	@param number limit
 *
 *	@todo Add support for additional filters, such as tags, min/max values etc.
 *
 *	@returns CollectionResponse<Session>
 */
export function useClubExercises(
	groupId: number,
	limit: number = 10
): CollectionResponse<Exercise> {
	const sport = useCurrentSport();
	const filters = useTrainingLibraryFilters();

	const params: { [key: string]: string } = {
		group_id: groupId.toString(),
		limit: limit.toString(),
		sports: sport.id.toString(),
		inherited: '1',
		...filters.getExerciseFilterParams(),
	};

	return useCollection<Exercise>(endpoints.Exercises.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams(params),
	});
}

/**
 *	@prop boolean isLoading
 *	@prop boolean hasRecords
 *	@prop CollectionResponse<Session> sessions
 *	@prop CollectionResponse<Exercise> exercises
 *	@prop CollectionResponse<TrainingCollection> collections
 */
export interface LibraryResponse {
	refresh: () => void;
	numLoadingStates: number;
	isLoading: boolean;
	hasRecords: boolean;
	sessions: CollectionResponse<Session>;
	exercises: CollectionResponse<Exercise>;
	collections: CollectionResponse<TrainingCollection>;
}

/**
 *	Fetches training library for user.
 *
 *	@param number organizationId
 *	@param number userId
 *	@param number limit
 *
 *	@returns LibraryResponse
 */
export function useUserLibrary(
	organizationId: number,
	userId: number,
	limit: number = 15
): LibraryResponse {
	const sessions = useUserSessions(organizationId, userId, limit);
	const exercises = useUserExercises(userId, limit);
	const collections = useUserCollections();

	const numLoadingStates = [
		sessions.isLoading,
		exercises.isLoading,
		collections.isLoading,
	].filter(Boolean).length;

	const isLoading = numLoadingStates > 0;

	const hasRecords = sessions.records.length + exercises.records.length > 0;

	const refresh = () => {
		sessions.refresh();
		exercises.refresh();
		collections.refresh();
	};

	return {
		refresh,
		numLoadingStates,
		isLoading,
		hasRecords,
		sessions,
		exercises,
		collections,
	};
}

/**
 *	Fetches training library for group.
 *
 *	@param number groupId
 *	@param number limit
 *
 *	@returns LibraryResponse
 */
export function useGroupLibrary(
	groupId: number,
	limit: number = 15
): LibraryResponse {
	const sessions = useGroupSessions(groupId, limit);
	const exercises = useGroupExercises(groupId, limit);
	const collections = useGroupCollections(groupId);

	const numLoadingStates = [
		sessions.isLoading,
		exercises.isLoading,
		collections.isLoading,
	].filter(Boolean).length;

	const isLoading = numLoadingStates > 0;

	const hasRecords =
		sessions.records.length +
			exercises.records.length +
			collections.records.length >
		0;

	const refresh = () => {
		sessions.refresh();
		exercises.refresh();
		collections.refresh();
	};

	return {
		refresh,
		numLoadingStates,
		isLoading,
		hasRecords,
		sessions,
		exercises,
		collections,
	};
}

/**
 *	Fetches training library for clubs.
 *
 *	@param number groupId
 *
 *	@returns LibraryResponse
 */
export function useClubLibrary(groupId: number): LibraryResponse {
	const sessions = useClubSessions(groupId);
	const exercises = useClubExercises(groupId);
	const collections = useClubCollections(groupId);

	const numLoadingStates = [
		sessions.isLoading,
		exercises.isLoading,
		collections.isLoading,
	].filter(Boolean).length;

	const isLoading = numLoadingStates > 0;

	const hasRecords =
		sessions.records.length +
			exercises.records.length +
			collections.records.length >
		0;

	const refresh = () => {
		sessions.refresh();
		exercises.refresh();
		collections.refresh();
	};

	return {
		refresh,
		numLoadingStates,
		isLoading,
		hasRecords,
		sessions,
		exercises,
		collections,
	};
}

/**
 *	Fetches inherited and curated sessions.
 *
 *	@param number limit
 *
 *	@returns AggregatedCollectionResponse<Session>
 */
export function useAggregatedSessions(
	limit: number = 10
): CollectionResponse<Session> {
	const group = useCurrentGroup();
	const sport = useCurrentSport();

	const filters = useTrainingLibraryFilters();
	const org = useCurrentOrganization();
	const user = useCurrentUser();

	return useCollection<Session>(endpoints.TrainingSessions.Index(org.id), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			group_id: group.id.toString(),
			user_id: user.id.toString(),
			limit: limit.toString(),
			sports: sport.id.toString(),
			curated: '1',
			inherit: '1',
			...filters.getSessionFilterParams(),
		}),
	});
}

/**
 *	Fetches inherited and curated exercises.
 *
 *	@param number limit
 *
 *	@returns AggregatedCollectionResponse<Exercise>
 */
export function useAggregatedExercises(
	limit: number = 10
): CollectionResponse<Exercise> {
	const group = useCurrentGroup();
	const sport = useCurrentSport();
	const userId = useCurrentUser().id;
	const filters = useTrainingLibraryFilters();

	return useCollection<Exercise>(endpoints.Exercises.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			group_id: group.id.toString(),
			user_id: userId.toString(),
			limit: limit.toString(),
			sports: sport.id.toString(),
			curated: '1',
			inherit: '1',
			...filters.getExerciseFilterParams(),
		}),
	});
}

/**
 *	Merges user, group and club collections into a single result.
 *
 *	@param number limit
 *
 *	@returns AggregatedCollectionResponse<VideoCollection>
 */
export function useAggregatedCollections(): AggregatedCollectionResponse<TrainingCollection> {
	const group = useCurrentGroup();
	const collections = useUserCollections();
	const groupCollections = useGroupCollections(group.id);
	const clubCollections = useClubCollections(group.id);

	const isLoading =
		collections.isLoading ||
		groupCollections.isLoading ||
		clubCollections.isLoading;

	const records: TrainingCollection[] = [
		...collections.records,
		...groupCollections.records,
		...clubCollections.records,
	]
		.filter(
			(
				collection: TrainingCollection,
				index: number,
				self: TrainingCollection[]
			) =>
				index ===
				self.findIndex((item: TrainingCollection) => item.id === collection.id)
		)
		.sort((a: TrainingCollection, b: TrainingCollection) => b.id - a.id);

	const refresh = async () => {
		collections.refresh();
		groupCollections.refresh();
		clubCollections.refresh();
	};

	const removeRecord = (recordId: number) => {
		collections.removeRecord(recordId);
		groupCollections.removeRecord(recordId);
		clubCollections.removeRecord(recordId);
	};

	const replaceRecord = (record: TrainingCollection) => {
		collections.replaceRecord(record);
		groupCollections.replaceRecord(record);
		clubCollections.replaceRecord(record);
	};

	const pagination: AggregatedCollectionPagination = {
		hasPrev:
			collections.pagination.hasPrev ||
			groupCollections.pagination.hasPrev ||
			clubCollections.pagination.hasPrev,
		hasNext:
			collections.pagination.hasNext ||
			groupCollections.pagination.hasNext ||
			clubCollections.pagination.hasNext,
		fetchPrev: () => {
			if (collections.pagination.hasPrev) {
				collections.pagination.fetchPrev();
			}

			if (groupCollections.pagination.hasPrev) {
				groupCollections.pagination.fetchPrev();
			}

			if (clubCollections.pagination.hasPrev) {
				clubCollections.pagination.fetchPrev();
			}
		},
		fetchNext: () => {
			if (collections.pagination.hasNext) {
				collections.pagination.fetchNext();
			}

			if (groupCollections.pagination.hasNext) {
				groupCollections.pagination.fetchNext();
			}

			if (clubCollections.pagination.hasNext) {
				clubCollections.pagination.fetchNext();
			}
		},
	};

	return {
		isLoading,
		records,
		pagination,
		refresh,
		removeRecord,
		replaceRecord,
	};
}

/**
 *	Fetches training collections for curated content.
 *
 *	@returns CollectionResponse<TrainingCollection>
 */
export function useCuratedCollections(): CollectionResponse<TrainingCollection> {
	const sport = useCurrentSport();
	const org = useCurrentOrganization();

	return useCollection<TrainingCollection>(
		endpoints.TrainingCollections.Index(org.id),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				sports: sport.id.toString(),
				curated: '1',
			}),
		}
	);
}

/**
 *	Fetches sessions for curated content.
 *
 *	@param number groupId
 *	@param number limit
 *
 *	@returns CollectionResponse<Session>
 */
export function useCuratedSessions(
	limit: number = 10
): CollectionResponse<Session> {
	const filters = useTrainingLibraryFilters();
	const sport = useCurrentSport();
	const org = useCurrentOrganization();

	return useCollection<Session>(endpoints.TrainingSessions.Index(org.id), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			limit: limit.toString(),
			sports: sport.id.toString(),
			curated: '1',
			...filters.getSessionFilterParams(),
		}),
	});
}

/**
 *	Fetches exercises for curated content.
 *
 *	@param number limit
 *
 *	@todo Add support for additional filters, such as tags, min/max values etc.
 *
 *	@returns CollectionResponse<Session>
 */
export function useCuratedExercises(
	limit: number = 10
): CollectionResponse<Exercise> {
	const filters = useTrainingLibraryFilters();
	const sport = useCurrentSport();

	const params: { [key: string]: string } = {
		limit: limit.toString(),
		sports: sport.id.toString(),
		curated: '1',
		...filters.getExerciseFilterParams(),
	};

	return useCollection<Exercise>(endpoints.Exercises.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams(params),
	});
}

/**
 *	Fetches training library for curated content.
 *
 *	@returns LibraryResponse
 */
export function useCuratedLibrary(): LibraryResponse {
	const sessions = useCuratedSessions();
	const exercises = useCuratedExercises();
	const collections = useCuratedCollections();

	const numLoadingStates = [
		sessions.isLoading,
		exercises.isLoading,
		collections.isLoading,
	].filter(Boolean).length;

	const isLoading = numLoadingStates > 0;

	const hasRecords =
		sessions.records.length +
			exercises.records.length +
			collections.records.length >
		0;

	const refresh = () => {
		sessions.refresh();
		exercises.refresh();
		collections.refresh();
	};

	return {
		refresh,
		numLoadingStates,
		isLoading,
		hasRecords,
		sessions,
		exercises,
		collections,
	};
}

interface QueryParams {
	[key: string]: string | string[];
}

function filterEmptyQueryParams(obj: QueryParams): QueryParams {
	return Object.keys(obj)
		.filter((k) => !!obj[k])
		.reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
}

interface TrainingLibraryFilters {
	title?: string;
	hasTitleFilter: boolean;
	setTitle: (title: string) => void;

	minTime?: number;
	maxTime?: number;
	hasTimeFilter: boolean;
	setMinTime: (time: number) => void;
	setMaxTime: (time: number) => void;

	minAge?: number;
	maxAge?: number;
	hasAgeFilter?: boolean;
	setMinAge: (time: number) => void;
	setMaxAge: (time: number) => void;

	minParticipants?: number;
	maxParticipants?: number;
	hasParticipantsFilter: boolean;
	setMinParticipants: (participants: number) => void;
	setMaxParticipants: (participants: number) => void;

	tags?: string[];
	hasTagsFilter: boolean;
	addTag: (tag: string) => void;
	removeTag: (tag: string) => void;
	getTags: () => string[];

	isFiltered: boolean;

	reset: () => void;
	refresh: () => void;

	getExerciseFilterParams: () => QueryParams;
	getSessionFilterParams: () => QueryParams;
}

export function useTrainingLibraryFilters(): TrainingLibraryFilters {
	const qs = useQueryState();

	const title = qs.get('title') as string;
	const hasTitleFilter = !!title;

	const setTitle = (title: string) => {
		qs.set('title', title);
		qs.commit();
	};

	const minTime = qs.get('minTime') as number;
	const maxTime = qs.get('maxTime') as number;
	const hasTimeFilter = !!minTime || !!maxTime;

	const setMinTime = (time: number) => {
		qs.set('minTime', time);
		qs.commit();
	};

	const setMaxTime = (time: number) => {
		qs.set('maxTime', time);
		qs.commit();
	};

	const minAge = qs.get('minAge') as number;
	const maxAge = qs.get('maxAge') as number;
	const hasAgeFilter = !!minAge || !!maxAge;

	const setMinAge = (time: number) => {
		qs.set('minAge', time);
		qs.commit();
	};

	const setMaxAge = (time: number) => {
		qs.set('maxAge', time);
		qs.commit();
	};

	const minParticipants = qs.get('minParticipants') as number;
	const maxParticipants = qs.get('maxParticipants') as number;
	const hasParticipantsFilter = !!minParticipants || !!maxParticipants;

	const setMinParticipants = (participants: number) => {
		qs.set('minParticipants', participants);
		qs.commit();
	};

	const setMaxParticipants = (participants: number) => {
		qs.set('maxParticipants', participants);
		qs.commit();
	};

	const tags = qs.getArray('tags', []);
	const hasTagsFilter = tags.length > 0;

	const getTags = () => {
		return qs.getArray('tags', []);
	};

	const addTag = (tag: string) => {
		const items = getTags();

		if (!items.includes(tag)) {
			items.push(tag);
		}

		qs.set('tags', items);
		qs.commit();
	};

	const removeTag = (tag: string) => {
		const items = getTags()
			.filter((n) => n)
			.filter((item: string) => item !== tag);

		qs.set('tags', items);
		qs.commit();
	};

	const isFiltered =
		hasTitleFilter ||
		hasTimeFilter ||
		hasAgeFilter ||
		hasParticipantsFilter ||
		hasTagsFilter;

	const reset = () => {
		qs.flush(false);
		qs.commit();
	};

	const getExerciseFilterParams = (): QueryParams => {
		return filterEmptyQueryParams({
			title,
			tags,
			minTime: minTime?.toString(),
			maxTime: maxTime?.toString(),
			minAge: minAge?.toString(),
			maxAge: maxAge?.toString(),
			minParticipants: minParticipants?.toString(),
			maxParticipants: maxParticipants?.toString(),
		});
	};

	const getSessionFilterParams = (): QueryParams => {
		return filterEmptyQueryParams({
			title,
			tags,
		});
	};

	return {
		title,
		hasTitleFilter,
		setTitle,

		minTime,
		maxTime,
		hasTimeFilter,
		setMinTime,
		setMaxTime,

		minAge,
		maxAge,
		hasAgeFilter,
		setMinAge,
		setMaxAge,

		minParticipants,
		maxParticipants,
		hasParticipantsFilter,
		setMinParticipants,
		setMaxParticipants,

		tags,
		hasTagsFilter,
		addTag,
		removeTag,
		getTags,

		isFiltered,
		reset,
		refresh: qs.refresh,

		getExerciseFilterParams,
		getSessionFilterParams,
	};
}
