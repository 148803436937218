import { t } from '@transifex/native';
import { useState, ChangeEvent } from 'react';

import * as styles from 'pkg/config/styles';

import useMixedState from 'pkg/hooks/useMixedState';
import { Account } from 'pkg/api/models/onboarding_info';
import { useCurrentAccount } from 'pkg/identity';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import AvatarEditor from 'routes/public/styles/AvatarEditor';
import Button from 'routes/public/styles/Button';
import Field from 'routes/public/styles/inputs/field';
import * as formStyles from 'routes/public/styles/forms/styles.css';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import Header from 'routes/public/no-memberships/styles/Header';
import Form from 'routes/public/styles/forms';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

export default function CreateWardUserInformation(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const parentAccount = useCurrentAccount();
	const targetAccountFromState = onboardingState.get<Account>('targetAccount');

	const [isSaving, setIsSaving] = useState(false);
	const [avatar, setAvatar] = useState('');

	const [payload, setPayload] = useMixedState<Account>({
		countryId: parentAccount.countryId,
		...targetAccountFromState,
	});

	const handleAvatarChange = (image: string) => {
		setAvatar(image);
		setPayload({ profileImage: image });
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPayload({ [event.target.name]: event.target.value });
	};

	const handleInputNumberChange = (
		event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
	) => {
		setPayload({ [event.target.name]: Number(event.target.value) });
	};

	const handleSave = async () => {
		setIsSaving(true);

		if (avatar) {
			payload.profileImage = avatar;
		}

		await onboardingState.setTargetAccount(payload);

		goTo('create-ward-own-account');
	};

	const avatarUrl = avatar
		? `data:image/png;base64, ${avatar}`
		: payload?.profileImage
			? `data:image/png;base64, ${payload.profileImage}`
			: '';

	return (
		<Column spacing={styles.spacing._8}>
			<Header />

			<Title title={t(`Please provide your child's details`)} />

			<Form onSubmit={handleSave}>
				<Column spacing={styles.spacing._7}>
					<Column>
						<AvatarEditor avatar={avatarUrl} onSave={handleAvatarChange} />

						<Field
							required
							name="firstName"
							placeholder={t(`Child's first name`)}
							errorMessage={t('First name is required')}
							value={payload.firstName}
							onChange={handleInputChange}
							tabIndex={1}
						/>

						<Field
							required
							name="lastName"
							placeholder={t(`Child's last name`)}
							errorMessage={t('Last name is required')}
							minLength={0}
							value={payload.lastName}
							onChange={handleInputChange}
							tabIndex={2}
						/>

						<Column>
							<span className={formStyles.label}>
								{t(`Child's date of birth`)}
							</span>
							<Row>
								<Field
									required
									name="day"
									type="number"
									placeholder={t('DD')}
									label={t('Day')}
									min={1}
									max={31}
									validateOnChange
									pattern="^([1-9]|[12][0-9]|3[01])$"
									inputMode="numeric"
									value={payload.day?.toString()}
									onChange={handleInputNumberChange}
									tabIndex={3}
								/>
								<Field
									required
									name="month"
									type="number"
									placeholder={t('MM')}
									label={t('Month')}
									min={1}
									max={12}
									validateOnChange
									pattern="^([1-9]|1[0-2])$"
									inputMode="numeric"
									value={payload.month?.toString()}
									onChange={handleInputNumberChange}
									tabIndex={4}
								/>
								<Field
									required
									name="year"
									type="number"
									placeholder={t('YYYY')}
									label={t('Year')}
									min={1900}
									validateOnChange
									inputMode="numeric"
									max={new Date().getFullYear()}
									value={payload.year?.toString()}
									onChange={handleInputNumberChange}
									tabIndex={5}
								/>
							</Row>
						</Column>
					</Column>
					<Button primary label={t('Continue')} type="submit" busy={isSaving} />
				</Column>
			</Form>

			<CancelButton disabled={isSaving} />
		</Column>
	);
}
