import { Fragment } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { FilterOperator } from 'pkg/filters';
import { useFilters } from 'pkg/filters/use_filters';
import useMixedState from 'pkg/hooks/useMixedState';

import RefundsTable from 'routes/payments/refunds/table';

import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';

interface RefundsIndexProps {
	organizationId: number;
}

export function RefundsIndex({ organizationId }: RefundsIndexProps) {
	const [sort, setSort] = useMixedState<{
		by: string;
		order: 'desc' | 'asc';
	}>({
		by: 'refunded_at',
		order: 'desc',
	});

	const filters = useFilters({
		filterBarMobileTrigger: true,
		groups: {
			[t('Search')]: {
				filters: {
					Search: {
						type: 'text',
						operator: FilterOperator.Contains,
						property: 'search',
					},
				},
				hidden: true,
			},
			[t('Created at')]: {
				filters: {
					Created: {
						type: 'date',
						property: 'created_at',
					},
				},
			},
			[t('Amount')]: {
				filters: {
					Amount: {
						type: 'number',
						property: 'amount',
					},
				},
			},
		},
	});

	filters.queryParam.set('group_id', organizationId.toString());
	filters.queryParam.set(
		'relations',
		['Order', 'Order.CustomerUser'].toString()
	);
	filters.queryParam.set('sort_by', sort.by);
	filters.queryParam.set('sort_by_order', sort.order);

	const refundsCollection = useCollection<models.orderRefund.OrderRefund>(
		endpoints.OrderRefund.Index(),
		{
			queryParams: filters.queryParam,
		}
	);

	const handleSortClick = (field: string, sortBy: 'asc' | 'desc') => {
		setSort({
			by: field,
			order: sortBy,
		});
	};

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'search',
				}}
				filters={filters}
			/>
			<RefundsTable
				refundsCollection={refundsCollection}
				sort={sort}
				onSortClick={handleSortClick}
			/>
			<Pagination {...refundsCollection.pagination} />
		</Fragment>
	);
}
