import { t } from '@transifex/native';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as routes from 'pkg/router/routes';
import { pushState, replaceState } from 'pkg/router/state';
import { EditDiscountPayload } from 'pkg/api/models/discount';
import { isDev } from 'pkg/flags';
import { useCurrentOrganization } from 'pkg/identity';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import Form, { asNumber, asString, FormPayload } from 'components/form/Form';
import * as ActionBar from 'components/layout/ActionBar';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import Section from 'components/form/Section';
import * as Input from 'components/form/inputs';
import { Spinner } from 'components/loaders/spinner';
import AvailabilitySection from 'components/discount/form/availability';

import Button, { ButtonGroup } from 'design/button';

import { FormActionFooter } from 'styles/Form';

interface EditDiscountProps {
	id: number;
}

export default function EditDiscount({ id }: EditDiscountProps) {
	const org = useCurrentOrganization();
	const { record: discount, isLoading } = useEndpoint<models.discount.Discount>(
		endpoints.Discount.Show(id)
	);
	const [active, setActive] = useState(models.discount.isActive(discount));
	const [saving, setSaving] = useState(false);

	const handleSubmit = async (data: FormPayload) => {
		setSaving(true);

		const payload: EditDiscountPayload = {
			title: asString(data.title),
			description: asString(data.description),
			isActive: active,
		};

		if (isDev()) {
			payload.maxUsageCount = asNumber(data.maxUsageCount);

			if (!data.maxUsage) {
				payload.maxUsageCount = 0;
			}
		}

		const [req] = await models.discount.update(discount, payload);
		if (req) {
			flashActions.show({
				title: t('Successfully updated discount'),
			});

			pushState(routes.Discounts.Index(org.id));
		}
		setSaving(false);
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Form onSubmit={handleSubmit}>
			<View
				active={active}
				setActive={setActive}
				discount={discount}
				saving={saving}
			/>
		</Form>
	);
}

interface ViewProps {
	active: boolean;
	saving: boolean;

	setActive: Dispatch<SetStateAction<boolean>>;
	discount: models.discount.Discount;
}

function View({ active, saving, setActive, discount }: ViewProps) {
	const org = useCurrentOrganization();
	const handleActiveChange = () => setActive((prev) => !prev);

	const handleCancel = () => replaceState(routes.Discounts.Index(org.id));

	const isSmallScreen = useSmallScreen();

	const content = (
		<Column spacing={styles.spacing._8}>
			<Section
				title={t('Discount information')}
				description={t('Discount applies to the entire order.')}
				hideDivider>
				<Column spacing={styles.spacing._7}>
					<Input.Group label={t('Title')} required>
						<Input.Field
							name="title"
							placeholder={t('Eg. My coupon code')}
							required
							defaultValue={discount.title}
						/>
					</Input.Group>
					<Input.Group label={t('Description')}>
						<Input.Area
							name="description"
							minRows={3}
							defaultValue={discount.description}
						/>
					</Input.Group>
				</Column>
			</Section>
			<AvailabilitySection
				active={active}
				handleActiveChange={handleActiveChange}
				discount={discount}
			/>
		</Column>
	);

	return (
		<Fragment>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					label={t('Cancel')}
					onClick={handleCancel}
				/>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					label={t('Save')}
					primary
					type="submit"
					isLoading={saving}
				/>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					{content}
					<FormActionFooter>
						<ButtonGroup>
							<Button label={t('Cancel')} onClick={handleCancel} />
							<Button
								label={t('Save')}
								primary
								type="submit"
								isLoading={saving}
							/>
						</ButtonGroup>
					</FormActionFooter>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
}
