// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wt17Gb0DNqrquQTry_HO {
	background: var(--palette-white);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
}
`, "",{"version":3,"sources":["webpack://./routes/login/redirect/styles.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;CAChC,eAAe;CACf,OAAO;CACP,MAAM;CACN,WAAW;CACX,YAAY;CACZ,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;AACtB","sourcesContent":[".wrapper {\n\tbackground: var(--palette-white);\n\tposition: fixed;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: grid;\n\tplace-items: center;\n\tplace-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `Wt17Gb0DNqrquQTry_HO`;
export default ___CSS_LOADER_EXPORT___;
