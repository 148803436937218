import { Fragment, useEffect, useState } from 'react';
import { t } from '@transifex/native';

import { EventReportPayload } from 'pkg/actions/event_reports';

import * as actions from 'pkg/actions';
import DateTime from 'pkg/datetime';
import { useCurrentGroup } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { QueryObject, useQueryState } from 'pkg/hooks/query-state';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import useDateFilter, {
	DateQueryFilters,
	Dates,
} from 'pkg/hooks/use-date-filter';

import Filters, { getFilters } from 'routes/group/report/shared/Filters';
import UserReportRow from 'routes/group/report/user/UserReportRow';
import Summary from 'routes/group/report/user/Summary';
import AttendanceLargeScreenNavigation, {
	useAttendanceNavigation,
} from 'routes/statistics/attendance/navigation';

import AssetImage from 'components/AssetImage';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import {
	SmallSubNavItems,
	TableContentWrapper,
} from 'components/layout/PageTemplates';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';

import * as Table from 'design/table';

interface UserReportTableProps {
	groupId: number;
	userId: number;
}

export function UserReportTable({ groupId, userId }: UserReportTableProps) {
	const group = useCurrentGroup();
	const qs = useQueryState();
	const query = qs.getAll();

	const { record: user } = useEndpoint<models.user.User>(
		endpoints.Users.Show(userId)
	);

	const [reports, setReports] = useState<models.eventReport.UserDetailReport[]>(
		[]
	);
	const [sortedBy, setSortedBy] = useState('date');
	const [sortOrder, setSortOrder] = useState('desc');
	const [isLoading, setIsLoading] = useState(true);

	const currentSeason = models.group.getCurrentSeason(group);

	const filters = getFilters(qs.getAll());
	const stringifiedFilters = JSON.stringify(filters);

	const dateTimeNow = DateTime.now();

	const dateRange: Dates = {
		startOfRange: currentSeason.start,
		endOfRange: dateTimeNow.getUnixTimestamp(),
	};

	if (query?.startOfRange) {
		dateRange.startOfRange = Number.parseInt(query.startOfRange as string, 10);
	}

	if (query?.endOfRange) {
		dateRange.endOfRange = Number.parseInt(query.endOfRange as string, 10);
	}

	const setUrl = (params: DateQueryFilters) => {
		qs.setAll({
			dateLabel: params.dateLabel,
			endOfRange: params.endOfRange,
			startOfRange: params.startOfRange,
		});
		qs.commit();
	};

	useEffect(() => {
		const fetchReports = async () => {
			setIsLoading(true);

			const payload: EventReportPayload = {
				groupId,
				userId,
				eventTypes: filters.eventTypes,
				startsAt: dateRange.startOfRange,
				endsAt: dateRange.endOfRange,
			};

			const userReports = await actions.eventReports.fetchByUser(payload);

			setReports(userReports);
			setIsLoading(false);
		};

		fetchReports();
	}, [groupId, userId, stringifiedFilters]);

	const { DateFilter } = useDateFilter({
		filters: {
			startOfRange: dateRange.startOfRange,
			endOfRange: dateRange.endOfRange,
			dateLabel: query?.dateLabel as string,
		},
		setUrl,
		customDatePresets: [
			{
				default: !query?.dateLabel ? true : false,
				name: t('Season'),
				startOfRangeDate: currentSeason.start,
			},
		],
	});

	const handleSort = (value: string) => {
		if (value === sortedBy) {
			setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
			return;
		}

		setSortOrder('asc');
		setSortedBy(value);
	};

	const sortReports = (a: any, b: any) => {
		let aValue: string;
		let bValue: string;

		if (sortedBy === 'title') {
			aValue = `${a.eventTitle}`;
			bValue = `${b.eventTitle}`;
		}

		if (sortedBy === 'type') {
			aValue = `${a.eventType}`;
			bValue = `${b.eventType}`;
		}

		if (sortedBy === 'date') {
			aValue = `${a.eventStart}`;
			bValue = `${b.eventStart}`;
		}

		if (sortedBy === 'attendance') {
			aValue = `${a.userAttendanceStatus}`;
			bValue = `${b.userAttendanceStatus}`;
		}

		if (sortedBy === 'comment') {
			aValue = `${a.userComment}`;
			bValue = `${b.userComment}`;
		}

		if (sortOrder === 'asc') {
			return aValue.localeCompare(bValue, DateTime.getLocale(), {
				numeric: true,
				sensitivity: 'base',
			});
		}

		return bValue.localeCompare(aValue, DateTime.getLocale(), {
			numeric: true,
			sensitivity: 'base',
		});
	};

	const renderedReports = reports
		.sort(sortReports)
		.map((report) => <UserReportRow key={report.eventId} report={report} />);

	const columns = [
		{
			content: t('Event title'),
			sortOrder,
			sortedBy: sortedBy === 'title',
			onClick: () => handleSort('title'),
		},
		{
			content: t('Event Type'),
			width: 'max-content',
			sortOrder,
			sortedBy: sortedBy === 'type',
			onClick: () => handleSort('type'),
		},
		{
			content: t('Date'),
			align: 'right',
			width: 'max-content',
			sortOrder,
			sortedBy: sortedBy === 'date',
			onClick: () => handleSort('date'),
		},
		{
			content: t('Time'),
			align: 'right',
			width: 'max-content',
		},
		{
			content: t('Status'),
			width: 'max-content',
			sortOrder,
			sortedBy: sortedBy === 'attendance',
			onClick: () => handleSort('attendance'),
		},
		{
			content: t('Comment'),
			sortOrder,
			sortedBy: sortedBy === 'comment',
			onClick: () => handleSort('comment'),
		},
	];
	const emptyState = {
		title: t('No reports found'),
		image: <AssetImage src="img/missing-entities/post.svg" />,
	};

	return (
		<Fragment>
			<Filters dateFilter={DateFilter} user={user} />
			{!isLoading && (
				<div>
					<Summary reports={reports} filters={filters} />
				</div>
			)}
			<Table.Table
				stickyHeader
				isLoading={isLoading}
				emptyState={emptyState}
				columns={columns}>
				{renderedReports}
			</Table.Table>
		</Fragment>
	);
}

interface UserReportProps {
	groupId: number;
	userId: number;
	title?: string;
	icon?: MaterialSymbolVariant;
	showNavigation?: boolean;
	query?: QueryObject;
}

export default function UserReport({
	groupId,
	userId,
	title,
	icon,
	showNavigation = true,
}: UserReportProps): JSX.Element {
	const navigationItems = useAttendanceNavigation(groupId);

	const content = <UserReportTable userId={userId} groupId={groupId} />;

	return (
		<Fragment>
			<Fragment>
				<LargeScreen>
					{showNavigation && (
						<AttendanceLargeScreenNavigation
							title={title}
							icon={icon}
							groupId={groupId}
						/>
					)}

					<LargeScreenContent.Wrapper disableScroll>
						<TableContentWrapper>{content}</TableContentWrapper>
					</LargeScreenContent.Wrapper>
				</LargeScreen>

				<SmallScreen>
					<SmallScreenHeader title={title} />

					<SmallScreenContent noScroll>
						{showNavigation && (
							<SmallLayout.SubNav>
								<SmallSubNavItems items={navigationItems} />
							</SmallLayout.SubNav>
						)}
						<TableContentWrapper>{content}</TableContentWrapper>
					</SmallScreenContent>
				</SmallScreen>
			</Fragment>
		</Fragment>
	);
}
