import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';
import { MembershipRole, MembershipStatus } from 'pkg/api/models/membership';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as routes from 'pkg/router/routes';

import TitleModal from 'routes/group/settings/user_management/TitleModal';

import Badge from 'components/Badge';
import Icon from 'components/icon';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

import * as css from './styles.css';

interface SingleUserRowProps {
	organizationId: number;
	membership: models.membership.Membership;
	refresh: () => void;
}

export default function SingleUserRow({
	organizationId,
	membership,
	refresh,
}: SingleUserRowProps): JSX.Element {
	const [titleModal, setTitleModal] = useState(false);

	const handleOpenTitleModal = () => {
		setTitleModal(true);
	};

	const handleCloseTitleModal = () => {
		setTitleModal(false);
		refresh();
	};

	const handleSetAsUser = async () => {
		const request = await sdk.patch(
			endpoints.Groups.UpdateUser(membership.groupId, membership.userId),
			{},
			{ role: MembershipRole.User }
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not set as user'),
			});
		}

		refresh();
	};
	const handleSetAsStaff = async () => {
		const request = await sdk.patch(
			endpoints.Groups.UpdateUser(membership.groupId, membership.userId),
			{},
			{ role: MembershipRole.Staff }
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not set as staff'),
			});
		}

		refresh();
	};
	const handleSetAsAdmin = async () => {
		const request = await sdk.patch(
			endpoints.Groups.UpdateUser(membership.groupId, membership.userId),
			{},
			{ role: MembershipRole.Admin }
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not set as admin'),
			});
		}

		refresh();
	};
	const handleRemoveUser = async () => {
		const request = await sdk.patch(
			endpoints.Groups.UpdateUser(membership.groupId, membership.userId),
			{},
			{ status: MembershipStatus.Inactive }
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not remove user'),
			});
		}

		refresh();
	};

	const handleBlockUser = async () => {
		const request = await sdk.patch(
			endpoints.Groups.UpdateUser(membership.groupId, membership.userId),
			{},
			{ status: MembershipStatus.Blocked }
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not block user'),
			});
		}

		refresh();
	};

	return (
		<Fragment>
			<Table.Row key={membership.id}>
				<Table.Cell>
					<div className={css.title}>
						<div className={css.badgeWrapper}>
							<Badge badgeUrl={membership.group.profileImageUrl} />
						</div>
						<span>{membership.group.name}</span>
					</div>
				</Table.Cell>
				<Table.Cell>
					{models.membership.translatedRoleStringFromMembership(membership)}
				</Table.Cell>
				<Table.Cell>{membership.title}</Table.Cell>
				<Table.Cell>
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.TableTrigger>
								<Icon name="context-menu" />
							</ContextMenu.TableTrigger>
						}>
						<ContextMenu.ControlItem
							onClick={handleSetAsUser}
							type="radio"
							icon="person_add"
							checked={models.membership.isPlayer(membership)}>
							{t('Player')}
						</ContextMenu.ControlItem>
						<ContextMenu.ControlItem
							onClick={handleSetAsStaff}
							type="radio"
							icon="person_add"
							checked={models.membership.isStaff(membership)}>
							{t('Staff')}
						</ContextMenu.ControlItem>
						<ContextMenu.ControlItem
							onClick={handleSetAsAdmin}
							type="radio"
							icon="person_add"
							checked={models.membership.isAdmin(membership)}>
							{t('Admin')}
						</ContextMenu.ControlItem>
						<ContextMenu.Divider />
						<ContextMenu.LinkItem
							key="view-profile-item"
							href={routes.User.Profile.Show(
								organizationId,
								membership.groupId,
								membership.userId,
								'overview'
							)}>
							<ContextMenu.ItemIcon name="person" />
							{t('View profile')}
						</ContextMenu.LinkItem>
						<ContextMenu.Item
							icon="arrow_forward"
							onClick={handleOpenTitleModal}>
							{t('Set title')}
						</ContextMenu.Item>
						<ContextMenu.LinkItem caution onClick={handleRemoveUser}>
							<ContextMenu.ItemIcon name="delete" />
							{t('Remove')}
						</ContextMenu.LinkItem>
						<ContextMenu.LinkItem caution onClick={handleBlockUser}>
							<ContextMenu.ItemIcon name="lock" />
							{t('Block')}
						</ContextMenu.LinkItem>
					</ContextMenu.Menu>
				</Table.Cell>
			</Table.Row>
			{titleModal && (
				<TitleModal membership={membership} onClose={handleCloseTitleModal} />
			)}
		</Fragment>
	);
}
