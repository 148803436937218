import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import { FilterOperator } from 'pkg/filters';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Row from 'routes/forms/list/archived/Row';

import AssetImage from 'components/AssetImage';
import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';

import * as Table from 'design/table';

interface ArchivedFormsProps {
	groupId: number;
}

const ArchivedForms = ({ groupId }: ArchivedFormsProps) => {
	const org = useCurrentOrganization();

	const filterGroups: FilterGroups = {
		[t(`Title`)]: {
			hidden: true,
			filters: {
				Title: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'title',
				},
			},
		},
		[t(`Created`)]: {
			filters: {
				Created: {
					type: 'date',
					property: 'createdAt',
					operator: FilterOperator.GreaterThan,
				},
			},
		},
	};

	const [sortBy, setSortBy] = useState('createdAt');
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

	const filters = useFilters({
		groups: filterGroups,
		filterBarMobileTrigger: true,
	});

	filters.queryParam.set('deleted', 'true');
	filters.queryParam.set('group_id', groupId.toString());
	filters.queryParam.set('sort_by', sortBy);
	filters.queryParam.set('sort_order', sortOrder);

	const { records, isLoading, pagination, removeRecord } =
		useCollection<models.form.Form>(endpoints.Forms.Index(), {
			queryParams: filters.queryParam,
		});

	const handleSort = (value: string, sortOrder: 'asc' | 'desc') => {
		setSortBy(value);
		setSortOrder(sortOrder);
	};

	const tableColumns = [
		{
			content: t(`Name`),
			sortKey: 'title',
		},
		{
			content: t(`Category`),
			sortKey: 'formCategoryTitle',
		},
		{
			content: t(`Registrations`),
			sortKey: 'submissionCount',
		},
		{
			content: t(`Created`),
			sortKey: 'createdAt',
		},
		{
			content: '',
			width: 'max-content',
		},
	];
	const emptyState = {
		title: t(`No archived registrations`),
		content: t(`No archived registrations found`),
		image: <AssetImage src="img/missing-entities/registration.svg" />,
	};

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				searchFilter={filterGroups[t(`Title`)].filters.Title}
				filters={filters}
				actions={[
					{
						label: t('Create'),
						href: routes.Registrations.Create(org.id, groupId),
						type: 'primary',
						testid: 'forms.create_new',
						icon: 'add',
					},
				]}
			/>
			<Table.Table
				isLoading={isLoading}
				sortBy={sortBy}
				sortOrder={sortOrder}
				onSort={handleSort}
				emptyState={emptyState}
				columns={tableColumns}>
				{records.map((form) => (
					<Row
						key={form.id}
						groupId={groupId}
						form={form}
						remove={removeRecord}
					/>
				))}
			</Table.Table>
			<Pagination {...pagination} />
		</Fragment>
	);
};

export default ArchivedForms;
