import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import { Features } from 'pkg/models/group';

import * as models from 'pkg/api/models';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as arrays from 'pkg/arrays';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';

import NoPosts from 'routes/wall/no-posts';
import GroupPost from 'routes/wall/post';

import PostForm from 'containers/group/post/Form';

import { Trigger } from 'components/ScrollSpy';
import SectionTitle from 'components/SectionTitle';
import { LargeScreen } from 'components/MediaQuery';
import Adslot, { AdslotBoundary } from 'components/adslot';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import Column from 'components/layout/column';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

const FeedAdAllocationPosition = 3;

interface WallProps {
	isDashboard?: boolean;
	setPageActions?: (trigger: JSX.Element) => void;
}

export default function Wall({
	isDashboard,
	setPageActions,
}: WallProps): JSX.Element {
	const [postModalActive, setPostModalActive] = useState(false);

	const activeMembership = useCurrentMembership();
	const group = useCurrentGroup();
	const org = useCurrentOrganization();

	const isAdminOrStaff = models.membership.isAdminOrStaff(activeMembership);
	const isOrganization = activeMembership.isOrganizationMembership;

	const showCreatePostButton =
		!isOrganization &&
		models.group.hasFeature(group, Features.Posts) &&
		!activeMembership.targetUserId;

	const openPostModal = () => {
		setPostModalActive(true);
	};

	const closePostModal = () => {
		setPostModalActive(false);
	};

	useComponentDidMount(() => {
		if (!setPageActions || !showCreatePostButton) {
			return;
		}

		setPageActions(
			<ContextMenu.Menu toggleWith={<ButtonTrigger icon="add" />}>
				<ContextMenu.Item onClick={openPostModal}>
					<ContextMenu.ItemIcon name="post_add" />
					{t('New post')}
				</ContextMenu.Item>
			</ContextMenu.Menu>
		);
	});

	const {
		records: posts,
		isLoading,
		pagination,
		replaceRecord,
	} = useCollection<models.groupPost.GroupPost>(
		activeMembership.isOrganizationMembership
			? endpoints.Organizations.ListGroupPosts(org.id)
			: endpoints.Groups.ShowFeed(group.id),
		{
			showAllResults: true,
		}
	);

	const hasPosts = posts.length > 0;

	const loadMorePosts = async (event: IntersectionObserverEntry) => {
		if (!pagination.hasNext || !event.isIntersecting || isLoading) {
			return;
		}

		pagination.fetchNext();
	};

	const handleEdit = (post: models.groupPost.GroupPost) => {
		replaceRecord(post);
	};
	const handleNewPost = (post: models.groupPost.GroupPost) => {
		pushState(routes.Group.Post.Show(org.id, post.id));
	};

	const adslot = (index: number) => {
		return (
			<AdslotBoundary key={`adslot-${index}`}>
				<Adslot />
			</AdslotBoundary>
		);
	};

	const renderedPosts = () => {
		if (isLoading && !hasPosts) {
			return <Skeleton placeholderType={PlaceholderType.FeedItem} />;
		}

		if (!hasPosts) {
			return (
				<Fragment>
					<NoPosts openPostModal={openPostModal} />
					<AdslotBoundary>
						<Adslot dynamicHeight height={600} />
					</AdslotBoundary>
				</Fragment>
			);
		}

		let items = posts.map((post: models.groupPost.GroupPost) => {
			return <GroupPost key={post.id} post={post} onEdit={handleEdit} />;
		});

		if (isLoading) {
			items.push(
				<Skeleton key="loading" placeholderType={PlaceholderType.FeedItem} />
			);
		}

		items = arrays.allocate(items.flat(), adslot, FeedAdAllocationPosition);

		if (pagination.hasNext && !isLoading) {
			items.push(
				<Trigger key="next-page" offsetY={-10} onTrigger={loadMorePosts} />
			);
		}

		if (items.length > FeedAdAllocationPosition) {
			items.splice(1, 0, adslot(items.length));
		}

		return items;
	};

	const content = isDashboard ? (
		<Fragment>
			<LargeScreen>
				<Column className={css.largeScreenWrapper}>
					<SectionTitle icon="feed">
						{isOrganization ? t('Club wall') : t('Wall')}
						{showCreatePostButton && (
							<Button
								inline
								noPadding
								icon="add"
								onClick={openPostModal}
								label={t('New post')}
							/>
						)}
					</SectionTitle>
					<Column>{renderedPosts()}</Column>
				</Column>
			</LargeScreen>
		</Fragment>
	) : (
		<Column>{renderedPosts()}</Column>
	);

	return (
		<Fragment>
			{content}
			{postModalActive && (
				<PostForm
					groupId={group.id}
					isAdminOrStaffForGroup={isAdminOrStaff}
					onClose={closePostModal}
					onSave={handleNewPost}
				/>
			)}
		</Fragment>
	);
}
