import { useEffect, useState } from 'react';

import * as sdk from 'pkg/core/sdk';

import {
	isValidVeoSource,
	isValidVimeoSource,
	isValidYouTubeSource,
} from 'components/video-analytics/source/SourceUrl';

export interface OEmbedResponse {
	type: string;
	version: string;
	title?: string;
	author_name?: string;
	author_url?: string;
	provider_name?: string;
	provider_url?: string;
	thumbnail_url?: string;
	thumbnail_width?: number;
	thumbnail_height?: number;
	html?: string;
	width?: string;
	height?: string;
}

async function fetchRemoteOEmbed(
	targetUrl: string,
	url: string
): Promise<OEmbedResponse> {
	targetUrl = `${targetUrl}?url=${encodeURIComponent(url)}`;

	try {
		const request = await fetch(targetUrl);

		if (request.ok) {
			const response = await request.json();

			return response;
		}
	} catch (e) {}

	return Promise.reject({});
}

async function fetchLocalOEmbed(
	targetUrl: string,
	url: string
): Promise<OEmbedResponse> {
	const request = await sdk.get(targetUrl, { url });

	if (request.ok) {
		const response = await request.json();

		if (response) {
			return response;
		}
	}

	return Promise.reject({});
}

export async function YouTube(url: string): Promise<OEmbedResponse> {
	return fetchRemoteOEmbed('https://www.youtube.com/oembed', url);
}

export async function Vimeo(url: string): Promise<OEmbedResponse> {
	return fetchRemoteOEmbed('https://vimeo.com/api/oembed.json', url);
}

export async function Veo(url: string): Promise<OEmbedResponse> {
	return fetchLocalOEmbed('/v1/video-oembed/veo', url);
}

export async function Hosted(url: string): Promise<OEmbedResponse> {
	return fetchLocalOEmbed('/v1/video-oembed', url);
}

type OEmbedSource = 'youtube' | 'vimeo' | 'veo' | 'hosted';

export function useOEmbed(
	url: string
): [boolean, OEmbedResponse, OEmbedSource] {
	const [isLoading, setLoading] = useState<boolean>(false);
	const [source, setSource] = useState<OEmbedSource>();
	const [data, setData] = useState<OEmbedResponse>();

	const emitOEmbedError = () => {
		setLoading(false);
		setData(null);
	};

	const fetchOEmbed = async () => {
		try {
			setLoading(true);

			let embed: OEmbedResponse;

			if (isValidYouTubeSource(url)) {
				embed = await YouTube(url);
				setSource('youtube');
			} else if (isValidVimeoSource(url)) {
				embed = await Vimeo(url);
				setSource('vimeo');
			} else if (isValidVeoSource(url)) {
				embed = await Veo(url);
				setSource('veo');
			} else {
				embed = await Hosted(url);
				setSource('hosted');
			}

			if (embed) {
				setData(embed);
			}

			setLoading(false);
		} catch (e) {
			emitOEmbedError();
		}
	};

	useEffect(() => {
		if (url) {
			if (url.startsWith('https')) {
				fetchOEmbed();
			}

			emitOEmbedError();
		}
	}, [url]);

	return [isLoading, data, source];
}
