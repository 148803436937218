import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Icon from 'components/icon';

import { MissingEntitiesWrapper } from 'components/missing-entities/Wrapper';

import Button from 'design/button';
import MissingEntities from 'design/placeholders/block';

interface MissingEntitiesProps {
	handleOpenModal: () => void;
}

export const ResourceMissingEntities = ({
	handleOpenModal,
}: MissingEntitiesProps) => {
	return (
		<MissingEntitiesWrapper>
			<MissingEntities
				noBorder
				title={t(`No resources`)}
				description={t(`Get started by creating a new resource`)}
				actions={
					<Button secondary icon="add" onClick={handleOpenModal}>
						{t('New resource')}
					</Button>
				}
				centerText
				imageMargin={styles.spacing._8}
				image={
					<Icon
						name="resource"
						actualSize
						size={4}
						fill={styles.palette.gray[500]}
					/>
				}
			/>
		</MissingEntitiesWrapper>
	);
};

export const LocationMissingEntities = ({
	handleOpenModal,
}: MissingEntitiesProps) => {
	return (
		<MissingEntitiesWrapper>
			<MissingEntities
				noBorder
				title={t(`No locations`)}
				description={t(`Get started by creating a new location`)}
				actions={
					<Button secondary icon="add" onClick={handleOpenModal}>
						{t(`New location`)}
					</Button>
				}
				centerText
				imageMargin={styles.spacing._8}
				image={
					<Icon
						name="location"
						actualSize
						size={4}
						fill={styles.palette.gray[500]}
					/>
				}
			/>
		</MissingEntitiesWrapper>
	);
};
