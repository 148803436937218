import { Fragment } from 'react';

import LocationsView from 'routes/scheduling/locations/list';
import ResourcesView from 'routes/scheduling/resources/list/Resources';

export enum ResourcesTabs {
	Resources = 'overview',
	Locations = 'locations',
}

interface ResourcesProps {
	groupId: number;
	currentTab: string;
	setPageActions: (elem: JSX.Element) => void;
}

const Resources = ({
	groupId,
	currentTab,
	setPageActions,
}: ResourcesProps): JSX.Element => {
	let viewContent;

	switch (currentTab) {
		case ResourcesTabs.Locations:
			viewContent = (
				<LocationsView groupId={groupId} setPageActions={setPageActions} />
			);
			break;
		default:
			viewContent = (
				<ResourcesView groupId={groupId} setPageActions={setPageActions} />
			);
	}

	return <Fragment>{viewContent}</Fragment>;
};

export default Resources;
