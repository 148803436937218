import { t } from '@transifex/native';
import { ChangeEvent, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';

import Form from 'routes/public/styles/forms';
import Title from 'routes/public/styles/Title';
import Button from 'routes/public/styles/Button';
import Field from 'routes/public/styles/inputs/field';
import { useOnboardingContext } from 'routes/public/onboarding';
import { useFetchAccountRelations } from 'routes/public/no-memberships/steps/Start';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

export default function ParentEmailInvite() {
	const { goTo, setInvitedEmail } = useOnboardingContext();

	const [email, setEmail] = useState<string>('');

	const { wards } = useFetchAccountRelations();

	const wardAccount = wards.find(
		(accountRelation) => accountRelation.approvedAt
	)?.targetAccount;

	const handleSendInvite = async () => {
		const [request] = await api.post(endpoints.AccountInvites.Index(), {
			email,
			targets: [
				{
					type: 'child',
					targetId: wardAccount.id,
				},
			],
		});

		if (request.ok) {
			setInvitedEmail(email);
			goTo('parent-email-sent');
		}
	};

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
		setEmail(e.target.value);

	if (!wardAccount) {
		return <Spinner />;
	}

	return (
		<Column spacing={styles.spacing._8}>
			<Title
				title={t('Invite a parent')}
				description={t(
					'Share this link with the parent that you want to connect to {wardName}. Note that a link can only be used once.',
					{
						wardName: wardAccount
							? models.account.fullName(wardAccount)
							: t('your child'),
					}
				)}
			/>
			<Form onSubmit={handleSendInvite}>
				<Column>
					<Field
						name="email"
						type="email"
						value={email}
						placeholder={t('Email')}
						onChange={handleEmailChange}
					/>
					<Button type="submit" primary label={t('Send')} />
				</Column>
			</Form>
		</Column>
	);
}
