import { fabric } from 'fabric';

// custom fabric tools

export const SquigglyLine = fabric.util.createClass(fabric.Line, {
	type: 'squigglyLine',

	initialize: function (element: any, options: any) {
		options || (options = {});
		this.callSuper('initialize', element, options);
	},

	_render: function (ctx: CanvasRenderingContext2D) {
		ctx.save();
		const xDiff = this.x2 - this.x1;
		const yDiff = this.y2 - this.y1;
		const angle = Math.atan2(yDiff, xDiff);
		ctx.translate(xDiff / 2, yDiff / 2);
		ctx.rotate(angle);
		ctx.beginPath();
		if (this.arrow) {
			// Move in front of line to start the arrow so it does not have the square line end showing in front (0,0)
			const size = 7;
			ctx.moveTo(size, 0);
			ctx.lineTo(-size, size);
			ctx.lineTo(-size, -size);
			ctx.closePath();
			ctx.fillStyle = this.stroke || '#000000';
			ctx.fill();
		}
		ctx.restore();
		const p = this.calcLinePoints();
		const point = this.pointOnLine(
			this.point(p.x2, p.y2),
			this.point(p.x1, p.y1),
			10
		);
		this.wavy(this.point(p.x1, p.y1), point, this.point(p.x2, p.y2), ctx);
		ctx.stroke();
	},

	point: function (x: any, y: any) {
		return {
			x: x,
			y: y,
		};
	},

	wavy: function (
		from: { x: number; y: number },
		to: { x: any; y: any },
		endPoint: { x: any; y: any },
		ctx: CanvasRenderingContext2D
	) {
		let cx = 0,
			cy = 0,
			i = 0,
			waveOffsetLength = 0;
		const fx = from.x,
			fy = from.y,
			tx = to.x,
			ty = to.y,
			step = 4,
			ang = Math.atan2(ty - fy, tx - fx),
			distance = Math.sqrt((fx - tx) * (fx - tx) + (fy - ty) * (fy - ty)),
			amplitude = -10,
			f = (Math.PI * distance) / 30;

		for (i; i <= distance; i += step) {
			waveOffsetLength = Math.sin((i / distance) * f) * amplitude;
			cx =
				from.x +
				Math.cos(ang) * i +
				Math.cos(ang - Math.PI / 2) * waveOffsetLength;
			cy =
				from.y +
				Math.sin(ang) * i +
				Math.sin(ang - Math.PI / 2) * waveOffsetLength;
			i > 0 ? ctx.lineTo(cx, cy) : ctx.moveTo(cx, cy);
		}
		ctx.lineWidth = this.strokeWidth;
		ctx.strokeStyle = this.stroke;
		if (this.arrow) {
			ctx.lineTo(to.x, to.y);
			ctx.lineTo(endPoint.x, endPoint.y);
		}
	},

	pointOnLine: function (
		point1: { x: number; y: number },
		point2: { x: number; y: number },
		dist: number
	) {
		const len = Math.sqrt(
			(point2.x - point1.x) * (point2.x - point1.x) +
				(point2.y - point1.y) * (point2.y - point1.y)
		);
		const t = dist / len;
		const x3 = (1 - t) * point1.x + t * point2.x,
			y3 = (1 - t) * point1.y + t * point2.y;

		return new fabric.Point(x3, y3);
	},

	toObject: function () {
		return fabric.util.object.extend(this.callSuper('toObject'), {
			arrow: this.arrow,
		});
	},
});
SquigglyLine.fromObject = function (
	object: { x1: any; y1: any; x2: any; y2: any; arrow: any },
	callback: (arg0: any) => any
) {
	function _callback(instance: { points: any }) {
		delete instance.points;
		callback && callback(instance);
	}
	const options = fabric.util.object.clone(object);
	options.points = [object.x1, object.y1, object.x2, object.y2];
	options.arrow = object.arrow;
	fabric.Object._fromObject('SquigglyLine', options, _callback, 'points');
};

// @ts-expect-error: fabric type wont allow extension, but is needed to allow cloning custom objects
fabric.SquigglyLine = SquigglyLine;
