import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { flashes } from 'pkg/actions';

export async function setFieldAsRequired(
	userField: models.userFields.UserField
): Promise<models.userFields.UserField> {
	let req, res;
	if (!userField.id) {
		// only for reserved fields that does not have a user field connected to it
		[req, res] = await models.create<
			models.userFields.UserFieldPayload,
			models.userFields.UserField
		>(endpoints.UserField.Index(), {
			key: userField.key,
			type: userField.type,
			label: userField.label,
			groupId: userField.groupId,
			required: true,
		});
	} else {
		[req, res] = await models.update<unknown, models.userFields.UserField>(
			userField,
			{
				required: true,
			}
		);
	}

	if (!req.ok) {
		flashes.show(
			{
				title: t('Something went wrong'),
				message: t('Please try again later'),
			},
			req.status
		);
		return userField;
	}

	return res;
}
