import { ReactNode, createContext, useContext } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';

import Account from 'pkg/models/account';

import { AUTH_GET_ME_SUCCESS } from 'pkg/actions/action-types';
import { normalizedDispatch } from 'pkg/actions/utils';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useAccountIdentity } from 'pkg/identity/account';
import { useEndpoint } from 'pkg/api/use_endpoint';
import store from 'pkg/store/createStore';
import * as actions from 'pkg/actions';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { resume } from 'pkg/events';
import Router, { IndexRoute, Route } from 'pkg/router/Router';
import { replaceState } from 'pkg/router/state';

import OrganizationAuthGuard from 'components/organization/auth_guard';
import { Spinner } from 'components/loaders/spinner';

function getCurrentUrl() {
	let currentUrl = window.location.pathname;

	if (window.location.search.length > 0) {
		currentUrl += window.location.search;
	}

	return currentUrl;
}

interface OrganizationIdentityProviderProps {
	children: ReactNode;
}

interface OrganizationIdentityState {
	organization: models.group.Group;
	user: models.user.User;

	users: models.user.User[];
	wards: models.user.User[];
	wardsAccountRelation: models.accountRelation.AccountRelation[];

	refreshOrganizationIdentity: () => void;
}

const DefaultOrganizationIdentityState: OrganizationIdentityState = {
	organization: null,
	user: null,
	users: [],
	wards: [],
	wardsAccountRelation: [],
	refreshOrganizationIdentity: () => null,
};

export const OrganizationIdentityContext =
	createContext<OrganizationIdentityState>(DefaultOrganizationIdentityState);

let __organizationIdentityContextValues: Readonly<
	Partial<OrganizationIdentityState>
>;

/**
 *	@warn This is is an "unsafe" way of getting current context values, and is *only* used in router middlewares.
 */
export function getOrganizationIdentityContextValues(): Readonly<
	Partial<OrganizationIdentityState>
> {
	return __organizationIdentityContextValues;
}

export const useOrganizationIdentity = () => {
	return useContext(OrganizationIdentityContext);
};

function AuthGuardRoutes({
	organization,
}: {
	organization: models.group.Group;
}) {
	const redirectToHome = () => {
		replaceState('/');
	};

	return (
		<ThemeProvider theme={{} as DefaultTheme}>
			<Router
				key="auth-guard"
				currentPath={getCurrentUrl()}
				onNotFound={redirectToHome}>
				<IndexRoute
					component={OrganizationAuthGuard}
					name="auth-guard"
					organization={organization}
				/>
				<Route
					path="sso/redirect"
					component={OrganizationAuthGuard}
					organization={organization}
				/>
			</Router>
		</ThemeProvider>
	);
}

export function OrganizationIdentityProvider({
	children,
}: OrganizationIdentityProviderProps): JSX.Element {
	const { organization } = useAccountIdentity();
	const dispatch = store.dispatch;

	const {
		record: account,
		isLoading,
		refresh,
		response,
	} = useEndpoint<models.account.Account>(
		organization?.id
			? endpoints.Self.ShowOrganizationSelf(organization.id)
			: undefined,
		{},
		(account, response) => {
			if (!response.ok) {
				return;
			}

			dispatch({
				type: AUTH_GET_ME_SUCCESS,
				payload: account,
			});

			normalizedDispatch(account, Account.normalizr(), false)(dispatch);
			actions.notifications.fetchNotifications(account.id);
		}
	);

	useComponentDidMount(
		() => {
			resume.on(refresh);
		},
		() => {
			resume.off(refresh);
		}
	);

	if (!organization?.id) {
		return null;
	}

	const users: models.user.User[] = account?.users ?? [];

	// @NOTE Only return ward's users, it'll be a single user per org
	const wards: models.user.User[] =
		account?.wards
			?.map(
				(relation: models.accountRelation.AccountRelation) =>
					relation.targetAccount?.users?.[0]
			)
			.flat()
			.filter((n) => n) || [];

	const providedState = {
		user: users?.[0] ?? null,
		wardsAccountRelation: account?.wards ?? [],
		users: users ?? [],
		wards: wards ?? [],
		organization,
		refreshOrganizationIdentity: refresh,
	};

	__organizationIdentityContextValues = Object.freeze(providedState);

	if (response?.status === 401) {
		children = <AuthGuardRoutes organization={organization} />;
	}

	if (isLoading) {
		return <Spinner center />;
	}

	return (
		<OrganizationIdentityContext.Provider value={providedState}>
			{children}
		</OrganizationIdentityContext.Provider>
	);
}
