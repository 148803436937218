import {
	PureComponent,
	useEffect,
	useState,
	useRef,
	MutableRefObject,
	createContext,
	useContext,
} from 'react';
import { useSelector } from 'react-redux';

import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as selectors from 'pkg/selectors';
import * as models from 'pkg/api/models';
import { useCollection, CollectionResponse } from 'pkg/api/use_collection';
import { MessageType, useWebSocketClient } from 'pkg/websocket';
import { useResumeListener } from 'pkg/events';

import ArchivedNotice from 'containers/chat/ArchivedNotice';
import TypingStatus from 'containers/chat/TypingStatus';
import { Messages } from 'containers/chat/Messages';

import { Trigger, ScrollSpy } from 'components/ScrollSpy';

import ReplyForm from 'components/chat/reply-form';
import { GalleryModal } from 'components/attachment/gallery';
import { EllipsisIcon } from 'components/loaders/spinner';

import * as css from './styles.css';

const supportsOverflowAnchor = CSS.supports('overflow-anchor: auto');

interface Snapshot {
	lastScrollTop: number;
	lastScrollHeight: number;
	lastHeight: number;
	isAtBottom: boolean;
}

interface ScrollPositionProps {
	scrollRef: MutableRefObject<any>;
	tail: boolean;
	children: any;
}

class ScrollPosition extends PureComponent<ScrollPositionProps> {
	getSnapshotBeforeUpdate() {
		const { scrollRef } = this.props;

		const snap: Snapshot = {
			lastScrollTop: 0,
			lastScrollHeight: 0,
			lastHeight: 0,
			isAtBottom: true,
		};

		if (!scrollRef.current) {
			return snap;
		}

		snap.lastScrollTop = scrollRef.current.scrollTop;
		snap.lastScrollHeight = scrollRef.current.scrollHeight;
		snap.lastHeight = scrollRef.current.getBoundingClientRect().height;

		if (snap.lastScrollTop <= 0) {
			snap.isAtBottom = -snap.lastScrollTop <= 50;
		} else {
			snap.isAtBottom =
				snap.lastScrollTop + snap.lastHeight + snap.lastScrollHeight < 50;
		}

		return snap;
	}

	componentDidUpdate(
		prevProps: ScrollPositionProps,
		_: any,
		snapshot: Snapshot
	) {
		if (!snapshot) {
			return;
		}

		const { scrollRef } = this.props;

		if (!scrollRef.current) {
			return {};
		}

		if (
			(!this.props.tail || !snapshot.isAtBottom) &&
			snapshot.lastScrollHeight !== scrollRef.current.scrollHeight &&
			prevProps.children[0]?.key !== this.props.children[0]?.key
		) {
			scrollRef.current.scrollTop =
				scrollRef.current.scrollTop -
				(scrollRef.current.scrollHeight - snapshot.lastScrollHeight);
		}
	}

	render() {
		return this.props.children;
	}
}

interface ThreadContextProps {
	chatMessageCollection: CollectionResponse<models.chatMessage.ChatMessage>;
}

const defaultContext: ThreadContextProps = {
	chatMessageCollection: null,
};

const ThreadContext = createContext(defaultContext);

export function useThreadContext() {
	return useContext(ThreadContext);
}

interface ThreadProps {
	autoFocus?: boolean;

	chat: models.chat.Chat;
}

function Thread({ chat, autoFocus = false }: ThreadProps) {
	const scrollRef = useRef(null);
	const chatId = chat.id;
	const [fetchAfterId, setFetchAfterId] = useState('');

	const queryParams: { after_id?: string } = {};

	if (fetchAfterId !== '') {
		queryParams.after_id = fetchAfterId;
	}

	const [showAttachment, setShowAttachment] =
		useState<models.attachment.Attachment>(null);
	const [goToMessageId, setGoToMessageId] = useState(0);
	const [tail, setTail] = useState(true);

	const chatMessageCollection = useCollection<models.chatMessage.ChatMessage>(
		endpoints.Chat.ListMessages(chatId),
		{
			showAllResults: true,
			queryParams: new URLSearchParams(queryParams),
		}
	);

	const activeUserIds = useSelector(selectors.app.activeAccountUserIds);
	const chatUsers = chat.users;

	const thread = chatMessageCollection.records.sort((a, b) => a.id - b.id);
	const selfChatUser = chatUsers.find((chatUser) =>
		activeUserIds.includes(chatUser.userId)
	);

	useEffect(() => {
		if (
			chatMessageCollection.isLoading ||
			thread.length === 0 ||
			goToMessageId
		) {
			return;
		}

		if (!chatMessageCollection.pagination.hasNext && !tail) {
			setTail(true);
		}
	}, [
		tail,
		chatMessageCollection.pagination.hasNext,
		chatMessageCollection.isLoading,
		thread.length,
		goToMessageId,
	]);

	useEffect(() => {
		if (!goToMessageId || chatMessageCollection.isLoading) return;

		const messageFound = thread.find((m) => m.id === goToMessageId);

		if (!messageFound) return;

		document
			.querySelector(`#chat-message-${goToMessageId}`)
			.scrollIntoView({ block: 'nearest' });

		setGoToMessageId(0);
	}, [thread, goToMessageId, chatMessageCollection.isLoading]);

	useEffect(() => {
		if (tail && selfChatUser?.lastReadMessageId < chat.lastMessageId) {
			models.chatUser.update(chat, selfChatUser, {
				lastReadMessageId: chat.lastMessageId,
			});
		}
	}, [selfChatUser?.lastReadMessageId, chat.lastMessageId, chat.id, tail]);

	useEffect(() => {
		const parent = scrollRef.current;

		if (!parent) {
			return;
		}

		parent.insertBefore(document.createElement('br'), parent.firstChild);
		parent.offsetTop;
		parent.removeChild(parent.firstChild);
	}, [chatMessageCollection.isLoading]);

	// This is a workaround for iOS Safari, where any DOM changes in the scrollable area makes the next scroll jump.
	// Toggling overflow seems to reset the positions, and the next scroll doesn't jump.
	const handleContentReflow = () => {
		if (supportsOverflowAnchor) {
			return;
		}

		requestAnimationFrame(() => {
			scrollRef.current.style.overflowY = 'hidden';

			requestAnimationFrame(() => {
				scrollRef.current.style.overflowY = 'scroll';
			});
		});
	};

	// Scroll to bottom when user sends new messages
	const handleMessageSent = async () => {
		if (scrollRef.current) {
			scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
			handleContentReflow();
		}
	};

	const fetchOlderMessages = async (e: IntersectionObserverEntry) => {
		if (e.isIntersecting && !chatMessageCollection.isLoading) {
			await chatMessageCollection.pagination.fetchPrev();
		}
	};

	const fetchNewerMessages = async (e: IntersectionObserverEntry) => {
		if (e.isIntersecting && !chatMessageCollection.isLoading) {
			await chatMessageCollection.pagination.fetchNext();
		}
	};

	const goToMessage = async (messageId: number) => {
		const messageFound = thread.find((cm) => cm.id === messageId);
		const id = messageId - 1;

		if (!messageFound) {
			setFetchAfterId(id.toString());
			setTail(false);
			setGoToMessageId(messageId);

			return;
		}

		document
			.querySelector(`#chat-message-${messageId}`)
			.scrollIntoView({ block: 'nearest' });
	};

	const handleOpenGallery = (attachment: models.attachment.Attachment) => {
		setShowAttachment(attachment);
	};

	const handleCloseGallery = () => {
		setShowAttachment(null);
	};

	const handleToggleGallery = (attachment: models.attachment.Attachment) => {
		showAttachment ? handleCloseGallery() : handleOpenGallery(attachment);
	};

	const ws = useWebSocketClient();

	// Fired when app resumes
	useResumeListener(chatMessageCollection.refresh);

	ws.onResume(chatMessageCollection.refresh);

	ws.onMessage<models.chatMessage.ChatMessage>(
		MessageType.ChatMessageCreated,
		(event) => {
			const currentChatMessageIds = chatMessageCollection.records.map(
				(c) => c.id
			);
			const currentChatClientIds = chatMessageCollection.records
				.filter((c) => c.clientMessageId)
				.map((c) => c.clientMessageId);
			if (
				!currentChatMessageIds.includes(event.data.id) &&
				!currentChatClientIds.includes(event.data.clientMessageId)
			) {
				chatMessageCollection.addRecord(event.data);
			} else {
				chatMessageCollection.replaceRecord(event.data, 'clientMessageId');
			}
		}
	);

	ws.onMessage<models.chatMessage.ChatMessage>(
		MessageType.ChatMessageUpdated,
		(event) => {
			chatMessageCollection.replaceRecord(event.data);
		}
	);

	ws.onMessage<models.chatReaction.ChatReaction>(
		MessageType.ChatReactionCreated,
		(event) => {
			const record = chatMessageCollection.records.find(
				(message: models.chatMessage.ChatMessage) =>
					message.id === event.data.chatMessageId
			);

			if (!record) return;

			const reactions = record.reactions || [];

			const hasReaction =
				reactions.findIndex(
					(item: models.chatReaction.ChatReaction) => item.id === event.data.id
				) !== -1;

			if (hasReaction) {
				return;
			}

			record.reactions = [...reactions, event.data];

			chatMessageCollection.replaceRecord(record);
		}
	);

	ws.onMessage<models.chatReaction.ChatReaction>(
		MessageType.ChatReactionDeleted,
		(event) => {
			const record = chatMessageCollection.records.find(
				(message: models.chatMessage.ChatMessage) =>
					message.id === event.data.chatMessageId
			);

			if (!record) return;

			record.reactions = record.reactions.filter(
				(reaction: models.chatReaction.ChatReaction) =>
					reaction.id !== event.data.id
			);

			chatMessageCollection.replaceRecord(record);
		}
	);

	let replyForm = null;
	const isArchived = chat.isArchived || selfChatUser?.hasArchived;

	if (selfChatUser) {
		replyForm = isArchived ? (
			<ArchivedNotice chat={chat} />
		) : (
			<ReplyForm
				autoFocus={autoFocus}
				chat={chat}
				handleMessageSent={handleMessageSent}
			/>
		);
	}

	return (
		<ThreadContext.Provider value={{ chatMessageCollection }}>
			<ScrollSpy className={css.wrapper}>
				<div className={css.inner} ref={scrollRef}>
					{!tail &&
						chatMessageCollection.pagination.hasNext &&
						!chatMessageCollection.isLoading && (
							<Trigger offsetY={-200} onTrigger={fetchNewerMessages} />
						)}

					<TypingStatus chat={chat} />

					{!tail || !supportsOverflowAnchor ? (
						<ScrollPosition tail={tail} scrollRef={scrollRef}>
							<Messages
								thread={thread}
								handleToggleGallery={handleToggleGallery}
								goToMessage={goToMessage}
								chat={chat}
								handleContentReflow={handleContentReflow}
							/>
						</ScrollPosition>
					) : (
						<Messages
							thread={thread}
							handleToggleGallery={handleToggleGallery}
							goToMessage={goToMessage}
							chat={chat}
							handleContentReflow={handleContentReflow}
						/>
					)}

					<div className={css.loadingSpinner}>
						{chatMessageCollection.isLoading && (
							<EllipsisIcon
								className={css.spinner}
								color={styles.palette.gray[500]}
							/>
						)}
					</div>

					{chatMessageCollection.pagination.hasPrev &&
						!chatMessageCollection.isLoading && (
							<Trigger offsetY={200} onTrigger={fetchOlderMessages} />
						)}
				</div>
			</ScrollSpy>

			{replyForm}

			{showAttachment && (
				<GalleryModal
					activeId={showAttachment.id}
					attachments={[showAttachment]}
					onClose={handleCloseGallery}
				/>
			)}
		</ThreadContext.Provider>
	);
}

export default Thread;
