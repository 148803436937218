import styled from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import useConfirm from 'pkg/hooks/useConfirm';
import store from 'pkg/store/createStore';
import { useFetchIdentity } from 'pkg/identity';

import Label from 'components/label';
import Avatar from 'components/avatar';

import * as labelStyles from 'components/label/styles.css';

import * as Context from 'design/context_menu';

const ContextWrapper = styled.div`
	grid-area: context-menu;
`;

const PendingRequestCardWrapper = styled.div`
	display: grid;
	grid-template-areas: 'avatar name pending context-menu' 'avatar text pending context-menu';
	grid-template-columns: auto 1fr auto auto;
	grid-template-rows: auto auto;
	align-items: center;
	gap: var(--spacing-1) var(--spacing-4);
	background: var(--palette-white);
	padding: var(--spacing-4);
	border-radius: var(--radius-5);

	strong {
		grid-area: name;
		align-self: flex-end;
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		color: var(--palette-gray-900);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.${labelStyles.label} {
		grid-area: pending;
	}
`;

const EmailWrapper = styled.span`
	grid-area: text;
	align-self: flex-start;
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const AvatarWrapper = styled.div`
	grid-area: avatar;
	display: grid;
	align-items: center;
	justify-items: center;
	width: 44px;
	height: 44px;

	${Avatar} {
		width: 44px;
		height: 44px;
	}
`;

interface PendingChildRequestCardProps {
	relation: models.accountRelation.AccountRelation;
}

export default function PendingChildRequestCard({
	relation,
}: PendingChildRequestCardProps): JSX.Element {
	const fetchIdentity = useFetchIdentity();

	const handleRevoke = useConfirm({
		message: t('Do you want to revoke this request?'),
		onConfirm: async () => {
			await actions.accountRelations.remove(relation.id)(store.dispatch);
			await fetchIdentity();
		},
	});

	const handleResend = useConfirm({
		message: t('Do you want to resend this request?'),
		onConfirm: async () => {
			const childEmail = relation.targetAccount.email;

			await actions.accountRelations.remove(relation.id)(store.dispatch);
			await actions.accountRelations.createRequest('legalGuardian', childEmail);
			await fetchIdentity();
		},
	});

	return (
		<PendingRequestCardWrapper>
			<AvatarWrapper>
				<Avatar account={relation.targetAccount} />
			</AvatarWrapper>
			<strong data-testid="no_memberships.pending_child_card.name">
				{models.account.fullName(relation.targetAccount)}
			</strong>
			<EmailWrapper data-testid="no_memberships.pending_child_card.email">
				{relation.targetAccount.email}
			</EmailWrapper>
			<Label color="orange">{t('pending')}</Label>
			<ContextWrapper>
				<Context.Menu
					toggleWith={
						<Context.ButtonTrigger
							icon="more_horiz"
							testid="no_memberships.pending_child_card_actions"
						/>
					}>
					<Context.Item
						onClick={handleRevoke}
						testid="no_memberships.pending_child_card_revoke">
						<Context.ItemIcon name="delete" />
						{t('Revoke')}
					</Context.Item>
					<Context.Item
						onClick={handleResend}
						testid="no_memberships.pending_child_card_resend">
						<Context.ItemIcon name="redo" />
						{t('Resend')}
					</Context.Item>
				</Context.Menu>
			</ContextWrapper>
		</PendingRequestCardWrapper>
	);
}
