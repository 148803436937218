// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.KyCO5uxSrf2k1KcYKpsz {
	width: 50%;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.KyCO5uxSrf2k1KcYKpsz video {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		   object-fit: cover;
		opacity: 0.5;
	}

.leO870PCEGV9MzqO8GI9 {
	color: var(--palette-white);
	opacity: 0.47;
}

.djrVBXWzVY7FEMA4Dnnm {
	color: var(--palette-white);
	text-align: center;
}

.KyCO5uxSrf2k1KcYKpsz {
	width: 50%;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.KyCO5uxSrf2k1KcYKpsz video {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		   object-fit: cover;
		opacity: 0.5;
	}

.leO870PCEGV9MzqO8GI9 {
	color: var(--palette-white);
	opacity: 0.47;
}

.djrVBXWzVY7FEMA4Dnnm {
	color: var(--palette-white);
	text-align: center;
}
`, "",{"version":3,"sources":["webpack://./routes/public/onboarding/styles.css"],"names":[],"mappings":";;AAEA;CACC,UAAU;CACV,YAAY;CACZ,kBAAkB;CAClB,QAAQ;CACR,MAAM;AAQP;;AANC;EACC,WAAW;EACX,YAAY;EACZ,oBAAiB;KAAjB,iBAAiB;EACjB,YAAY;CACb;;AAGD;CACC,2BAA2B;CAC3B,aAAa;AACd;;AAEA;CACC,2BAA2B;CAC3B,kBAAkB;AACnB;;AAvBA;CACC,UAAU;CACV,YAAY;CACZ,kBAAkB;CAClB,QAAQ;CACR,MAAM;AAQP;;AANC;EACC,WAAW;EACX,YAAY;EACZ,oBAAiB;KAAjB,iBAAiB;EACjB,YAAY;CACb;;AAGD;CACC,2BAA2B;CAC3B,aAAa;AACd;;AAEA;CACC,2BAA2B;CAC3B,kBAAkB;AACnB","sourcesContent":["@import 'styles.css';\n\n.large-screen-backdrop {\n\twidth: 50%;\n\theight: 100%;\n\tposition: absolute;\n\tright: 0;\n\ttop: 0;\n\n\t& video {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tobject-fit: cover;\n\t\topacity: 0.5;\n\t}\n}\n\n.faded {\n\tcolor: var(--palette-white);\n\topacity: 0.47;\n}\n\n.description {\n\tcolor: var(--palette-white);\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `KyCO5uxSrf2k1KcYKpsz`;
export { _1 as "large-screen-backdrop" };
export var faded = `leO870PCEGV9MzqO8GI9`;
export var description = `djrVBXWzVY7FEMA4Dnnm`;
export default ___CSS_LOADER_EXPORT___;
