import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { isDev } from 'pkg/flags';

import Section from 'components/form/Section';
import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

interface AvailabilitySectionProps {
	active: boolean;

	handleActiveChange: () => void;

	discount?: models.discount.Discount;
}

export default function AvailabilitySection({
	active,
	discount,
	handleActiveChange,
}: AvailabilitySectionProps) {
	return (
		<Section icon="visibility" title={t('Availability')} hideDivider>
			<Column>
				<Column spacing={styles.spacing._3}>
					<Input.Control
						type="radio"
						checked={active}
						label={t('Active')}
						onChange={handleActiveChange}
					/>
					<Input.Control
						type="radio"
						checked={!active}
						label={t('Not active')}
						onChange={handleActiveChange}
					/>
				</Column>
				{isDev() && (
					<Input.Group
						label={t('Limit uses')}
						hint={t(
							'Decide if the amount of uses should be limited for this discount code.'
						)}>
						<Input.Field
							name="maxUsageCount"
							type="text"
							defaultValue={discount?.maxUsageCount || ''}
						/>
					</Input.Group>
				)}
			</Column>
		</Section>
	);
}
