import { Fragment, useRef, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactAvatarEditor from 'react-avatar-editor';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import * as shadows from 'pkg/config/box-shadow';
import * as palette from 'pkg/config/palette';

import { show } from 'pkg/actions/flashes';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import { Spinner } from 'components/loaders/spinner';
import FileInput from 'components/form/File';
import RangeSlider from 'components/form/RangeSlider';

import Button from 'design/button';

const Wrapper = styled.div`
	margin: 0 auto;
	display: grid;
	grid-auto-flow: row;
	justify-content: center;
	align-items: center;
	row-gap: 0.5rem;
	position: relative;
`;

const BusyOverlay = styled.div`
	background: ${rgba(palette.white, 0.7)};
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: grid;
	align-items: center;
	justify-content: center;
	z-index: 10;

	svg {
		color: ${palette.blue[500]};
	}
`;

const Actions = styled.div`
	display: grid;
	grid-auto-flow: column;
	justify-content: center;
	justify-items: center;
	align-items: center;
`;

const EditorWrapper = styled.div`
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	position: relative;
	margin: 0 auto;
`;

const CanvasStyles = css`
	border-radius: var(--radius-5);
	background-image: linear-gradient(
			45deg,
			${palette.gray[500]} 25%,
			transparent 25%
		),
		linear-gradient(-45deg, ${palette.gray[500]} 25%, transparent 25%),
		linear-gradient(45deg, transparent 75%, ${palette.gray[500]} 75%),
		linear-gradient(-45deg, transparent 75%, ${palette.gray[500]} 75%);
	background-size: 30px 30px;
	background-position:
		0 0,
		0 15px,
		15px -15px,
		-15px 0px;
	box-shadow: ${shadows.flat};
`;

const Editor = styled(ReactAvatarEditor)`
	${CanvasStyles};
`;

const EmptyEditor = styled.div`
	${CanvasStyles};
	background-image: linear-gradient(
			45deg,
			${rgba(palette.gray[500], 0.4)} 25%,
			transparent 25%
		),
		linear-gradient(
			-45deg,
			${rgba(palette.gray[500], 0.4)} 25%,
			transparent 25%
		),
		linear-gradient(45deg, transparent 75%, ${rgba(palette.gray[500], 0.4)} 75%),
		linear-gradient(
			-45deg,
			transparent 75%,
			${rgba(palette.gray[500], 0.4)} 75%
		);
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	display: grid;
	grid-auto-flow: row;
	justify-content: center;
	align-items: center;
	grid-gap: 1rem;
	margin: 0 auto;
`;

const EmptyLabel = styled.div`
	margin: 0 30px;
	padding: 1rem;
	background: ${palette.blue[500]};
	color: ${palette.white};
	text-align: center;
	border-radius: var(--radius-3);
	pointer-events: none;
	box-shadow: ${shadows.cardHover};
	display: grid;
	align-items: center;
	justify-items: center;
	justify-content: center;
	grid-gap: 1rem;

	.${iconStyles.icon} {
		font-size: 3rem;
	}
`;

const Scale = styled.div`
	display: grid;
	grid-template-columns: 30px 1fr 30px;
	justify-content: center;
	align-items: center;
	grid-gap: 1rem;
`;

const Min = styled.span`
	text-align: center;
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-bold);
	color: ${palette.gray[500]};
	user-select: none;
`;

const Max = styled(Min)``;

const ChangeImageButton = styled(FileInput)`
	align-self: end;
	justify-self: end;
	font-size: var(--font-size-sm);
	color: ${palette.blue[500]};
	transform: translateY(5px);

	@media (hover: hover) {
		&:hover {
			color: ${rgba(palette.blue[500], 0.7)};
		}
	}
`;

const RotateButton = styled(Button)`
	padding: 5px 3px 5px 5px;
	position: absolute;
	left: 10px;
	top: 10px;

	svg {
		width: 20px;
		height: 20px;
	}
`;

const defaultSize = 320;
const defaultGutter = 0;

const NOOP = () => {};

/**
 * @type {React.Element<any>}
 */

const ImageEditor = ({
	image,
	icon = null,
	type = 'avatar',
	size = defaultSize,
	width = null,
	height = null,
	gutterSize = defaultGutter,
	borderRadius = 0,
	aspectRatio = null,
	saveAsBlob = false,
	onSave = null,
	afterSave = NOOP,
	allowRotate = true,
	allowScale = true,
	isBusy = false,
}) => {
	const dispatch = useDispatch();
	const ref = useRef(null);

	if (!width && !height) {
		width = size;
		height = size;
	}

	if (aspectRatio && aspectRatio.includes(':')) {
		const [x, y] = aspectRatio.split(':').map((n) => Number.parseInt(n, 10));

		width = size;
		height = Math.ceil((size / x) * y);
	}

	if (type === 'avatar') {
		if (!gutterSize) gutterSize = 30;

		borderRadius = height;
	}

	if (!icon) {
		switch (type) {
			case 'avatar':
				icon = 'nav-profile';
				break;
			case 'badge':
				icon = 'nav-group';
				break;
			default:
				icon = 'file-image';
		}
	}

	const initialState = {
		draggedFile: false,
		position: { x: 0.5, y: 0.5 },
		rotate: 0,
		scale: 1,
		image,
	};

	const [state, emit] = useReducer(
		(state, { type, payload }) => ({
			...state,
			[type]: payload,
		}),
		initialState
	);

	const imageChange = useCallback(
		(files) => {
			emit({ type: 'image', payload: files[0] });
			emit({ type: 'scale', payload: 1 });
			emit({ type: 'rotate', payload: 0 });
		},
		[emit]
	);

	const scaleChange = useCallback(
		(scale) =>
			emit({
				type: 'scale',
				payload: scale,
			}),
		[emit]
	);

	const rotateChange = useCallback(() => {
		let nextRotate = state.rotate - 90;

		if (nextRotate === -360) {
			nextRotate = 0;
		}

		emit({
			type: 'rotate',
			payload: nextRotate,
		});
	}, [emit, state]);

	const imageHasChanged = !!state.image && state.image !== image;
	const hasSaveFunction = typeof onSave === 'function';

	const canSave = imageHasChanged && hasSaveFunction;

	const handleSave = useCallback(async () => {
		const canvas = ref.current.getImageScaledToCanvas();

		let data;

		if (saveAsBlob) {
			data = await new Promise((resolve) => canvas.toBlob(resolve));
		} else {
			data = await Promise.resolve(
				canvas.toDataURL('image/png').replace('data:image/png;base64,', '')
			);
		}

		if (data) {
			onSave(data);
			afterSave();
		}
	}, [onSave, afterSave, saveAsBlob, ref]);

	const handleError = useCallback(() => {
		show(
			{
				title: t(`Editor Error`),
				message: t(`Could not load image.`),
			},
			500
		);

		emit({ type: 'image', payload: null });
		emit({ type: 'scale', payload: 0 });
		emit({ type: 'rotate', payload: 0 });
	}, [dispatch]);

	return (
		<Wrapper width={width}>
			{isBusy && (
				<BusyOverlay>
					<Spinner />
				</BusyOverlay>
			)}
			{state.image && (
				<Fragment>
					<ChangeImageButton accept="image/*" onChange={imageChange}>
						{t(`Change {type}`, {
							type: type === 'avatar' ? t('avatar') : t('team badge'),
						})}
					</ChangeImageButton>
					<EditorWrapper width={width} height={height}>
						{canSave && allowRotate && (
							<RotateButton icon="image-rotate" onClick={rotateChange} />
						)}
						<Editor
							ref={ref}
							image={state.image}
							width={width - gutterSize * 2}
							height={height - gutterSize * 2}
							border={gutterSize}
							scale={state.scale}
							rotate={state.rotate}
							color={[255, 255, 255, 0.6]}
							borderRadius={borderRadius}
							onLoadFailure={handleError}
						/>
					</EditorWrapper>
					{canSave && allowScale && (
						<Scale>
							<Min>¼×</Min>
							<RangeSlider
								min={0.25}
								max={3}
								step={0.01}
								value={state.scale}
								onChange={scaleChange}
								progressColor={palette.gray[300]}
							/>
							<Max>3×</Max>
						</Scale>
					)}
				</Fragment>
			)}
			{!state.image && (
				<FileInput accept="image/*" allowDrop onChange={imageChange}>
					<EmptyEditor width={width} height={height}>
						<EmptyLabel>
							<Icon name={icon} />
							{t(`Drag or click here to choose a new {type}`, {
								type: type === 'avatar' ? t('avatar') : t('team badge'),
							})}
						</EmptyLabel>
					</EmptyEditor>
				</FileInput>
			)}
			{canSave && (
				<Actions>
					<Button scondary onClick={handleSave} testid="avatar.save_button">
						{t('Save')}
					</Button>
				</Actions>
			)}
		</Wrapper>
	);
};

ImageEditor.propTypes = {
	type: PropTypes.oneOf(['avatar', 'badge', 'thumbnail']),
};

export default ImageEditor;
