// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fvcHtMQdykAXt7GtQfqr {
	height: 9px;
	width: 9px;
	border-radius: 100%;
	background-color: var(--color);
}

.EUeR8zVV4IU2iZ6ufu7_ {
	color: var(--color);
}

.CfHStSGaBQUWfyGmClfg {
	padding: var(--spacing-5) var(--spacing-6);
}

.nPdLVNLW_DODIv83AMVd {
	width: -moz-min-content;
	width: -webkit-min-content;
	width: min-content;
}
`, "",{"version":3,"sources":["webpack://./components/resources/select_modal/styles.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,UAAU;CACV,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,0CAA0C;AAC3C;;AAEA;CACC,uBAAkB;CAAlB,0BAAkB;CAAlB,kBAAkB;AACnB","sourcesContent":[".status-circle {\n\theight: 9px;\n\twidth: 9px;\n\tborder-radius: 100%;\n\tbackground-color: var(--color);\n}\n\n.status-text {\n\tcolor: var(--color);\n}\n\n.info-wrapper {\n\tpadding: var(--spacing-5) var(--spacing-6);\n}\n\n.row {\n\twidth: min-content;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `fvcHtMQdykAXt7GtQfqr`;
export { _1 as "status-circle" };
var _2 = `EUeR8zVV4IU2iZ6ufu7_`;
export { _2 as "status-text" };
var _3 = `CfHStSGaBQUWfyGmClfg`;
export { _3 as "info-wrapper" };
export var row = `nPdLVNLW_DODIv83AMVd`;
export default ___CSS_LOADER_EXPORT___;
