import { Fragment } from 'react';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

import Content from 'routes/form_submissions/single/Content';
import SideBar from 'routes/form_submissions/single/SideBar/SideBar';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import LargeScreenHeader from 'components/navigation/header/large_screen';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import { Spinner } from 'components/loaders/spinner';
import * as SideBarLayout from 'components/layout/SideBarPage';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SmallScreenContent from 'components/layout/SmallScreenContent';

interface SubmissionProps {
	submissionId: number;
}

const FormSubmissionSingle: React.FC<
	React.PropsWithChildren<SubmissionProps>
> = ({ submissionId }) => {
	const { record, isLoading } =
		useEndpoint<models.formSubmission.FormSubmission>(
			endpoints.FormSubmissions.Show(submissionId)
		);

	if (isLoading) {
		return <Spinner />;
	}

	const groupId = record.submittedForUser.organizationId;

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={record.form.title} icon="list" />
				<LargeScreenContent.Wrapper>
					<SideBarLayout.Layout>
						<SideBarLayout.SideBar>
							<SideBar groupId={groupId} submission={record} />
						</SideBarLayout.SideBar>

						<SideBarLayout.Content backgroundColor={styles.palette.white}>
							<LargeScreenContent.Inner maxWidth={PageWidths.WIDE}>
								<Content
									groupId={groupId}
									data={record.data}
									fields={record.form.fields}
									order={record.order}
								/>
							</LargeScreenContent.Inner>
						</SideBarLayout.Content>
					</SideBarLayout.Layout>
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenHeader title={record.form.title} />
				<SmallScreenContent>
					<SideBarLayout.Layout>
						<SideBarLayout.SideBar>
							<SideBar groupId={groupId} submission={record} />
						</SideBarLayout.SideBar>

						<SideBarLayout.Content backgroundColor={styles.palette.white}>
							<LargeScreenContent.Inner maxWidth={PageWidths.WIDE}>
								<Content
									groupId={groupId}
									data={record.data}
									fields={record.form.fields}
									order={record.order}
								/>
							</LargeScreenContent.Inner>
						</SideBarLayout.Content>
					</SideBarLayout.Layout>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

export default FormSubmissionSingle;
