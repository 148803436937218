// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mahNVhqDnE04BO6Vw9zu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.mj_JXywnkqRwiNmkNFFt {
	text-decoration: line-through;
}

.oyZr3vzbAcRklB98QlH3 {
	overflow: hidden;
	padding-right: var(--spacing-4);
}

.ozpXxqLeTpPBhmaqYRyf {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/event-heading/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAA8B;KAA9B,sBAA8B;SAA9B,8BAA8B;CAC9B,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,gBAAgB;CAChB,+BAA+B;AAChC;;AAEA;CACC,cAAc;CACd,mBAAmB;CACnB,gBAAgB;CAChB,uBAAuB;AACxB","sourcesContent":[".eventInfo {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.strikeThrough {\n\ttext-decoration: line-through;\n}\n\n.groupNameLabelWrapper {\n\toverflow: hidden;\n\tpadding-right: var(--spacing-4);\n}\n\n.groupNameLabel {\n\tdisplay: block;\n\twhite-space: nowrap;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export var eventInfo = `mahNVhqDnE04BO6Vw9zu`;
export var strikeThrough = `mj_JXywnkqRwiNmkNFFt`;
export var groupNameLabelWrapper = `oyZr3vzbAcRklB98QlH3`;
export var groupNameLabel = `ozpXxqLeTpPBhmaqYRyf`;
export default ___CSS_LOADER_EXPORT___;
