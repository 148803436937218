import { Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import * as models from 'pkg/api/models';
import DateTime, { Granularity } from 'pkg/datetime';
import { eventTypeLabels, EventTypes } from 'pkg/api/models/event';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { DateSelectWrapper, TimeZoneText } from 'routes/event/components/Form';
import { BookingFormState } from 'routes/scheduling/bookings/modals/CreateBooking';

import Icon from 'components/icon';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import TimeZoneSearch from 'components/form/TimeZoneSelect';
import { SelectEventType } from 'components/event/SelectType';
import ResourceCardItem from 'components/scheduling/resources';
import InfoBox from 'components/form/info-box';

import Button from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface BookingFormProps {
	formState: BookingFormState;

	groups: models.group.Group[];
	resources: models.resource.Resource[];
	booking?: models.booking.Booking;
	selectedGroup: number[];
	selectedResource: number[];
	primaryResource: number;

	showGroupSelectModal: () => void;
	showSelectResourceModal: () => void;
	selectResource: (id: number) => void;
	selectPrimaryResource: (resource: models.resource.Resource) => void;

	setFormState: (payload: Partial<BookingFormState>) => void;
}

const BookingForm = ({
	formState,
	groups,
	resources,
	booking,
	selectedGroup,
	showGroupSelectModal,
	setFormState,
	showSelectResourceModal,
	selectResource,
	selectedResource,
	selectPrimaryResource,
	primaryResource,
}: BookingFormProps) => {
	const [showTimeZone, setShowTimeZone] = useState(false);
	const formContext = useFormContext();
	const eventType = formContext.watch('eventType');

	const group = groups.find((g) => g.id === selectedGroup[0]);
	const parentGroup = groups.find((g) => g.id === group?.parentGroupId);

	const defaultValues = {
		title: booking?.title || '',
		description: booking?.description || '',
		eventType: booking?.type || EventTypes.Practice,
	};

	useComponentDidMount(() => {
		if (!formState.timeZone) {
			setShowTimeZone(true);
		}
	});

	const showTimeZoneInput = async () => setShowTimeZone(true);
	const hideTimeZoneInput = () => setShowTimeZone(false);
	const handleSelectTimeZone = (timeZone: string) => setFormState({ timeZone });

	const handleStartDateChange = (date: Date) => {
		const start = new DateTime(date).getUnixTimestamp();
		const duration = formState.end - formState.start;

		setFormState({
			start,
			end: Math.ceil(start + duration),
		});
	};

	const handleEndDateChange = (date: Date) => {
		setFormState({
			end: new DateTime(date).getUnixTimestamp(),
		});
	};

	const handleEndTimeBlur = () => {
		if (formState.end < formState.start) {
			setFormState({
				end: new DateTime(new Date(formState.start * 1000))
					.next(Granularity.hour, 1)
					.getUnixTimestamp(),
			});
		}
	};

	const setPrimary = (resource: models.resource.Resource) =>
		selectPrimaryResource(resource);

	const handleRemoveResource = (id: number) => {
		selectResource(id);
	};

	// We do this so we add all the potential deleted BookingResources resources
	// in the array that we map the selected resources from
	let allResources = resources;

	if (!!booking?.resources?.length) {
		booking.resources.forEach((br) => {
			if (br.resource?.deletedAt) {
				allResources = [...allResources, br.resource];
			}
		});
	}

	const bookingHasEvent = !!booking?.eventId;

	return (
		<Fragment>
			<Column spacing={styles.spacing._7}>
				{bookingHasEvent && (
					<InfoBox
						text={t(
							'Booking is connected to an event - any changes made here will be reflected on the event'
						)}
					/>
				)}
				<Column spacing={styles.spacing._3}>
					<DateSelectWrapper>
						<Input.Group label={t('Start time')}>
							<div>
								<Input.DateTimePicker
									date={new Date(formState.start * 1000)}
									onTimeChange={handleStartDateChange}
									onDateChange={handleStartDateChange}
								/>
							</div>
						</Input.Group>
						<Icon name="arrow-right" />
						<Input.Group label={t('End time')}>
							<div>
								<Input.DateTimePicker
									date={new Date(formState.end * 1000)}
									onTimeChange={handleEndDateChange}
									onDateChange={handleEndDateChange}
									onTimeBlur={handleEndTimeBlur}
									disableDatesBefore={new Date(formState.start * 1000)}
								/>
							</div>
						</Input.Group>
					</DateSelectWrapper>
					{!showTimeZone && (
						<TimeZoneText onClick={showTimeZoneInput}>
							{formState.timeZone || t('Select time zone')}
						</TimeZoneText>
					)}
				</Column>

				{showTimeZone && (
					<TimeZoneSearch
						timezone={formState.timeZone}
						onBlur={hideTimeZoneInput}
						onSelect={handleSelectTimeZone}
					/>
				)}

				<Input.Group label={t('Group')}>
					{group?.id ? (
						<div className={css.groupName} onClick={showGroupSelectModal}>
							{!!parentGroup
								? `${parentGroup?.name} - ${group.name}`
								: group.name}
							<Icon name="edit" />
						</div>
					) : (
						<Button
							icon="groups"
							secondary
							large
							label={t('Select group')}
							onClick={showGroupSelectModal}
						/>
					)}
				</Input.Group>

				<Input.Group label={t('Resource')}>
					{selectedResource.length > 0 && (
						<Column spacing={styles.spacing._3}>
							{allResources &&
								selectedResource.map((id) => (
									<ResourceCardItem
										key={id}
										resource={allResources.find((r) => r.id === id)}
										isPrimary={primaryResource === id}
										actions={
											<Context.Menu
												toggleWith={<Button icon="more_horiz" secondary />}>
												<Context.Item
													onClick={() =>
														setPrimary(allResources.find((r) => r.id === id))
													}>
													<Context.ItemIcon name="home_pin" />
													{t('Set as main resource')}
												</Context.Item>
												<Context.Item onClick={() => handleRemoveResource(id)}>
													<Context.ItemIcon name="cancel" />
													{t('Remove')}
												</Context.Item>
											</Context.Menu>
										}
									/>
								))}
						</Column>
					)}
					<Button
						icon="location_on"
						secondary
						large
						label={t('Select resource')}
						onClick={showSelectResourceModal}
					/>
				</Input.Group>

				{!bookingHasEvent && (
					<Input.Group label={t('Events')}>
						<Input.Control
							name="createEvents"
							type="checkbox"
							label={t('Create events')}
							description={t(
								'Do you want to create an event from this booking?'
							)}
						/>
					</Input.Group>
				)}

				{!booking && (
					<Input.Group label={t('Event type')}>
						<SelectEventType
							name="eventType"
							defaultValue={defaultValues.eventType}
							eventType={eventType || defaultValues.eventType}>
							{eventTypeLabels.map((eventType) => (
								<option key={eventType} value={eventType}>
									{eventTypeToTranslatedString(eventType)}
								</option>
							))}
						</SelectEventType>
					</Input.Group>
				)}

				<Input.Group label={t('Booking title')} required>
					<Input.Field
						name="title"
						defaultValue={defaultValues.title}
						required
					/>
				</Input.Group>

				<Input.Group label={t('Description')}>
					<Input.Area
						name="description"
						defaultValue={defaultValues.description}
						minrows={4}
					/>
				</Input.Group>
			</Column>
		</Fragment>
	);
};

export default BookingForm;
