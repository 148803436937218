// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addnKUuHbDGhnmjdHhtK {
	margin-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./routes/group/calendar/components/export/styles.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB","sourcesContent":[".icon {\n\tmargin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export var icon = `addnKUuHbDGhnmjdHhtK`;
export default ___CSS_LOADER_EXPORT___;
