// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rl6vXTknlBYaLzpGMUZk {
	background: var(--palette-white);

	/* PostCSS vscode plugin can't handle the CSSModules :global "selector", hence the error */
}
.dark-mode .Rl6vXTknlBYaLzpGMUZk {
		background: var(--palette-gray-700);
}
`, "",{"version":3,"sources":["webpack://./components/context-switch-footer/styles.css","webpack://./css/mixins.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;;CCAhC,0FAA0F;ADK3F;AANA;EAIE,mCAAmC;AAErC","sourcesContent":[".footer {\n\tbackground: var(--palette-white);\n\n\t@mixin dark-mode {\n\t\tbackground: var(--palette-gray-700);\n\t}\n}\n","@define-mixin dark-mode {\n\t/* PostCSS vscode plugin can't handle the CSSModules :global \"selector\", hence the error */\n\t@nest :global(.dark-mode) & {\n\t\t@mixin-content;\n\t}\n}\n\n@define-mixin device-dark-mode {\n\t@nest :global(.dark-mode) & {\n\t\t@mixin-content;\n\t}\n\n\t@media (prefers-color-scheme: dark) {\n\t\t@mixin-content;\n\t}\n}\n\n/* When the keyboard is open in the app this mixin applies. */\n@define-mixin keyboard-open {\n\t@nest :global(.keyboard-open) & {\n\t\t@mixin-content;\n\t}\n}\n\n@define-mixin collapsed-drawer {\n\t/* PostCSS vscode plugin can't handle the CSSModules :global \"selector\", hence the error */\n\n\t@media not (--small-viewport) {\n\t\t@nest :global(.collapsed-drawer) & {\n\t\t\t@mixin-content;\n\t\t}\n\t}\n}\n\n@define-mixin no-drawer {\n\t/* PostCSS vscode plugin can't handle the CSSModules :global \"selector\", hence the error */\n\t@nest :global(.no-drawer) & {\n\t\t@mixin-content;\n\t}\n}\n\n@define-mixin testo {\n\t@nest :global(.icon) & {\n\t\t@mixin-content;\n\t}\n}\n\n@define-mixin hover {\n\t@media (hover: hover) {\n\t\t&:hover {\n\t\t\t@mixin-content;\n\t\t}\n\t}\n}\n\n@define-mixin print {\n\t@media print {\n\t\t@mixin-content;\n\t}\n}\n\n@define-mixin flex-center {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n@define-mixin scheduling-columns {\n\tdisplay: grid;\n\tgrid-template-columns: minmax(180px, 1.2fr) repeat(\n\t\t\tvar(--cols, 7),\n\t\t\tminmax(180px, 1fr)\n\t\t);\n\n\t@media (--small-viewport) {\n\t\twidth: fit-content;\n\t\tgrid-template-columns: repeat(var(--cols, 7), minmax(150px, 1fr));\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var footer = `Rl6vXTknlBYaLzpGMUZk`;
var _1 = `dark-mode`;
export { _1 as "dark-mode" };
export default ___CSS_LOADER_EXPORT___;
