import { Fragment, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import { markAllAsRead } from 'pkg/actions/notifications';

import * as selectors from 'pkg/selectors';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentAccount } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { FilterQuery, toFilterQuery } from 'pkg/filters/use_filters';
import { FilterOperator } from 'pkg/filters';
import DateTime from 'pkg/datetime';
import { useAccountIdentity } from 'pkg/identity/account';
import { cssClasses } from 'pkg/css/utils';
import { getUnreadChatsForOtherOrganizations } from 'pkg/api/models/group';

import { Trigger } from 'components/ScrollSpy';
import { SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import { TabBar, TabView } from 'components/tab-bar';
import Adslot, { AdslotBoundary } from 'components/adslot';
import MaterialSymbol from 'components/material-symbols';

import Item from 'components/notifications/Item';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import UnreadChats from 'components/notifications/unread-chats';
import Row from 'components/layout/row';
import UnreadChatList from 'components/notifications/unread-chats/list';

import * as ContextMenu from 'design/context_menu';
import InlinePlaceholder from 'design/placeholders/inline';
import Button from 'design/button';

import * as css from './styles.css';

interface ListProps {
	standAlone?: boolean;
	newNotifications?: boolean;
	lastMarkedRead?: number;
	showUnreadChats?: () => void;
}

export const List = memo(
	({
		standAlone,
		newNotifications,
		lastMarkedRead,
		showUnreadChats,
	}: ListProps): JSX.Element => {
		const { organizations, organization } = useAccountIdentity();
		const unreadChats = getUnreadChatsForOtherOrganizations(
			organization,
			organizations
		);

		const account = useCurrentAccount();
		const hasUnread = useSelector(selectors.notifications.hasUnread);

		const filters: FilterQuery = {
			isRead: {
				operator: FilterOperator.Equals,
				values: [!newNotifications],
			},
		};

		const {
			records: notifications,
			isLoading,
			reset,
			pagination,
		} = useCollection<models.accountNotification.AccountNotificaton>(
			`/accounts/${account.id}/notifications`,
			{
				showAllResults: true,
				queryParams: new URLSearchParams({
					filters: toFilterQuery(filters),
				}),
			}
		);

		useEffect(() => {
			if (lastMarkedRead) {
				reset();
			}
		}, [lastMarkedRead]);

		const handleMarkAllAsRead = async () => {
			await markAllAsRead(account.id);
			reset();
		};

		const handleLoadMore = (e: IntersectionObserverEntry) => {
			if (e.isIntersecting) {
				pagination.fetchNext?.();
			}
		};

		const showEmptyState =
			!isLoading && notifications.length === 0 && unreadChats === 0;

		if (showEmptyState) {
			return (
				<ContextMenu.Pane>
					<Column>
						<InlinePlaceholder>
							<Icon name="notification" />
							{t('No notifications')}
						</InlinePlaceholder>
						<SmallScreen>
							<AdslotBoundary>
								<Adslot dynamicHeight height={600} />
							</AdslotBoundary>
						</SmallScreen>
					</Column>
				</ContextMenu.Pane>
			);
		}

		let loadMoreTrigger = null;
		if (pagination.hasNext) {
			loadMoreTrigger = <Trigger onTrigger={handleLoadMore} />;
		}

		return (
			<Fragment>
				{unreadChats > 0 && (
					<UnreadChats
						amount={unreadChats}
						showUnreadChats={showUnreadChats}
						showDivider={hasUnread}
					/>
				)}
				{notifications.length > 0 && (
					<Fragment>
						<SmallScreen>
							<div className={css.unreadWrapper}>
								{hasUnread && (
									<Button
										primary
										block
										icon="check"
										onClick={handleMarkAllAsRead}
										label={t('Mark all as read')}
									/>
								)}
							</div>
						</SmallScreen>

						{notifications.map((n) => (
							<Item standAlone={standAlone} notification={n} key={n.id} />
						))}
					</Fragment>
				)}

				{isLoading ? (
					<ContextMenu.Pane>
						<Spinner />
					</ContextMenu.Pane>
				) : (
					loadMoreTrigger
				)}
			</Fragment>
		);
	}
);

export default function View(): JSX.Element {
	const [showUnreadChats, setShowUnreadChats] = useState(false);
	const [lastMarked, setLastMarked] = useState(0);
	const hasUnread = useSelector(selectors.notifications.hasUnread);
	const account = useCurrentAccount();

	const handleShowUnreadChats = () => setShowUnreadChats(true);
	const handleHideUnreadChats = () => setShowUnreadChats(false);

	const handleMarkAllAsRead = async () => {
		await markAllAsRead(account.id);
		setLastMarked(DateTime.now().getUnixTimestamp());
	};

	let heading = t('Notifications');

	if (showUnreadChats) {
		heading = t('Unread chats');
	}

	return (
		<Fragment>
			<ContextMenu.Heading
				className={cssClasses(
					css.notificationsHeading,
					showUnreadChats ? css.showBorder : ''
				)}>
				<Row autoColumns="max-content" align="center" spacing={spacing._3}>
					{showUnreadChats && (
						<div onClick={handleHideUnreadChats}>
							<MaterialSymbol variant="arrow_back_ios_new" />
						</div>
					)}
					{heading}
				</Row>
				{hasUnread && !showUnreadChats && (
					<Button onClick={handleMarkAllAsRead} label={t('Mark all as read')} />
				)}
			</ContextMenu.Heading>
			{showUnreadChats ? (
				<UnreadChatList />
			) : (
				<TabBar
					className={css.tabBar}
					tabs={[
						{
							id: 'unread',
							label: t('New', {
								_context: 'notifications',
								_comment: 'Navigation item for unread notifications',
							}),
						},
						{
							id: 'read',
							label: t('History', {
								_context: 'notifications',
								_comment: 'Navigation item for old notifications',
							}),
						},
					]}>
					<TabView tabId="read">
						<List showUnreadChats={handleShowUnreadChats} />
					</TabView>
					<TabView tabId="unread">
						<List
							newNotifications
							lastMarkedRead={lastMarked}
							showUnreadChats={handleShowUnreadChats}
						/>
					</TabView>
				</TabBar>
			)}
		</Fragment>
	);
}
