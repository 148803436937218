export function AccountIndex(accountID: number): string {
	return `/v1/accounts/${accountID}/training-sessions`;
}

export function AccountIndex1(groupID: number): string {
	return `/v1/organizations/${groupID}/self/training-sessions`;
}

export function AddBlock(groupID: number, trainingSessionID: number): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}/blocks`;
}

export function AddBlock1(trainingSessionID: number): string {
	return `/v1/training-sessions/${trainingSessionID}/blocks`;
}

export function AddBlockItem(
	groupID: number,
	trainingSessionID: number
): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}/items`;
}

export function AddBlockItem1(trainingSessionID: number): string {
	return `/v1/training-sessions/${trainingSessionID}/items`;
}

export function AddTag(
	groupID: number,
	trainingSessionID: number,
	tagID: number
): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}/tags/${tagID}`;
}

export function AddTag1(trainingSessionID: number, tagID: number): string {
	return `/v1/training-sessions/${trainingSessionID}/tags/${tagID}`;
}

export function Create(groupID: number): string {
	return `/v1/organizations/${groupID}/training-sessions`;
}

export function Create1(): string {
	return `/v1/training-sessions`;
}

export function Delete(groupID: number, trainingSessionID: number): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}`;
}

export function Delete1(trainingSessionID: number): string {
	return `/v1/training-sessions/${trainingSessionID}`;
}

export function Duplicate(groupID: number, trainingSessionID: number): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}/duplicate`;
}

export function Duplicate1(trainingSessionID: number): string {
	return `/v1/training-sessions/${trainingSessionID}/duplicate`;
}

export function GroupIndex(groupID: number): string {
	return `/v1/groups/${groupID}/training-sessions`;
}

export function Index(groupID: number): string {
	return `/v1/organizations/${groupID}/training-sessions`;
}

export function Index1(): string {
	return `/v1/training-sessions`;
}

export function RemoveBlock(
	groupID: number,
	trainingSessionBlockID: number
): string {
	return `/v1/organizations/${groupID}/training-session-blocks/${trainingSessionBlockID}`;
}

export function RemoveBlock1(trainingSessionBlockID: number): string {
	return `/v1/training-session-blocks/${trainingSessionBlockID}`;
}

export function RemoveBlockItem(
	groupID: number,
	trainingSessionItemID: number
): string {
	return `/v1/organizations/${groupID}/training-session-items/${trainingSessionItemID}`;
}

export function RemoveBlockItem1(trainingSessionItemID: number): string {
	return `/v1/training-session-items/${trainingSessionItemID}`;
}

export function RemoveTag(
	groupID: number,
	trainingSessionID: number,
	tagID: number
): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}/tags/${tagID}`;
}

export function RemoveTag1(trainingSessionID: number, tagID: number): string {
	return `/v1/training-sessions/${trainingSessionID}/tags/${tagID}`;
}

export function SetItemUsers(
	groupID: number,
	trainingSessionItemID: number
): string {
	return `/v1/organizations/${groupID}/training-session-items/${trainingSessionItemID}/users`;
}

export function SetItemUsers1(trainingSessionItemID: number): string {
	return `/v1/training-session-items/${trainingSessionItemID}/users`;
}

export function Show(groupID: number, trainingSessionID: number): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}`;
}

export function Show1(trainingSessionID: number): string {
	return `/v1/training-sessions/${trainingSessionID}`;
}

export function Update(groupID: number, trainingSessionID: number): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}`;
}

export function Update1(trainingSessionID: number): string {
	return `/v1/training-sessions/${trainingSessionID}`;
}

export function UpdateBlock(
	groupID: number,
	trainingSessionBlockID: number
): string {
	return `/v1/organizations/${groupID}/training-session-blocks/${trainingSessionBlockID}`;
}

export function UpdateBlock1(trainingSessionBlockID: number): string {
	return `/v1/training-session-blocks/${trainingSessionBlockID}`;
}

export function UpdateItem(
	groupID: number,
	trainingSessionItemID: number
): string {
	return `/v1/organizations/${groupID}/training-session-items/${trainingSessionItemID}`;
}

export function UpdateItem1(trainingSessionItemID: number): string {
	return `/v1/training-session-items/${trainingSessionItemID}`;
}
