import { Fragment, useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { replaceState } from 'pkg/router/state';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';
import { newChatParams } from 'pkg/chat';

import NewChatModal from 'routes/chat/view/NewChatModal';
import NewGroupChatModal from 'routes/chat/view/NewGroupChatModal';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: start;
	grid-gap: var(--spacing-3);
	padding: 0 var(--spacing-5);
	grid-area: initiate;
	border-bottom: var(--palette-gray-300) 1px solid;
	border-right: var(--palette-gray-300) 1px solid;
	background: var(--palette-white);

	.${iconStyles.icon} {
		margin: 0 var(--spacing-2) 0 0;
	}
`;

interface InitiateProps {
	asPageActions?: boolean;
	setPageActions?: (s: ReactNode) => void;
}

function Initiate({ asPageActions, setPageActions }: InitiateProps) {
	const [showChatModal, setShowChatModal] = useState(false);
	const [showGroupModal, setShowGroupModal] = useState(false);
	const org = useCurrentOrganization();

	const openChatModal = () => {
		setShowChatModal(true);
	};

	const openGroupChatModal = () => {
		setShowGroupModal(true);
	};

	const closeChatModal = async (animation?: Promise<boolean>) => {
		if (animation) {
			await animation;
		}

		setShowChatModal(false);
	};

	const closeGroupChatModal = async (animation?: Promise<boolean>) => {
		if (animation) {
			await animation;
		}

		setShowGroupModal(false);
	};

	const handleAddChat = async (data: {
		title: string;
		users: models.user.User[];
		type: string;
	}) => {
		closeGroupChatModal();

		replaceState(routes.Chat.New(org.id), {
			...newChatParams(data.users),
			title: data.title,
		});
	};

	useEffect(() => {
		if (asPageActions && setPageActions) {
			setPageActions(
				<ContextMenu.Menu toggleWith={<ButtonTrigger icon="add" />}>
					<ContextMenu.Item
						onClick={openChatModal}
						testid="chat.new_chat_button">
						<ContextMenu.ItemIcon name="edit" />
						{t('New chat')}
					</ContextMenu.Item>
					<ContextMenu.Item
						onClick={openGroupChatModal}
						testid="chat.new_group_button">
						<ContextMenu.ItemIcon name="groups" />
						{t('New group')}
					</ContextMenu.Item>
				</ContextMenu.Menu>
			);
		}
	}, [asPageActions]);

	return (
		<Fragment>
			{!asPageActions && (
				<Wrapper>
					<Button onClick={openChatModal} testid="chat.new_chat_button">
						<Icon name="add-post" />
						{t('New chat')}
					</Button>
					<Button onClick={openGroupChatModal} testid="chat.new_group_button">
						<Icon name="nav-members" />
						{t('New group')}
					</Button>
				</Wrapper>
			)}

			{showChatModal && <NewChatModal onClose={closeChatModal} />}

			{showGroupModal && (
				<NewGroupChatModal
					onClose={closeGroupChatModal}
					onNext={handleAddChat}
				/>
			)}
		</Fragment>
	);
}

export default Initiate;
