import { t } from '@transifex/native';

import { useEventListener } from 'pkg/hooks/events';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentMembership, useCurrentUser } from 'pkg/identity';
import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';

import SectionTitle from 'components/SectionTitle';
import { ScrollableWrapper } from 'components/scrollable-wrapper';
import Label from 'components/label';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import DashboardEvent from 'components/event/dashboard-event';

export default function PendingEvents(): JSX.Element {
	const currentMembership = useCurrentMembership();
	const currentUser = useCurrentUser();
	const now = new DateTime(new Date()).getUnixTimestamp();

	const userId = currentMembership.targetUserId || currentUser.id;
	const fetchId = userId;

	const queryParams: { [key: string]: string } = {
		user_ids: [fetchId].toString(),
	};

	const { records: pendingEvents, refresh } = useCollection<models.event.Event>(
		endpoints.Events.ListPending(),
		{
			queryParams: new URLSearchParams(queryParams),
		}
	);

	const filteredEvents = pendingEvents.filter(
		(p) => p.rsvpBefore > now || !p.rsvpBefore
	);

	useEventListener('dashboard-refresh-pending-events', () => refresh());

	if (filteredEvents?.length === 0) {
		return null;
	}

	return (
		<Column>
			<Row autoColumns="max-content" align="center">
				<SectionTitle icon="notifications">
					{t('Pending invitations')}
				</SectionTitle>
				<Label color="darkblue">{filteredEvents.length}</Label>
			</Row>

			<ScrollableWrapper chevrons>
				{filteredEvents.map((event) => (
					<DashboardEvent
						key={event.id}
						event={event}
						isPending
						refresh={refresh}
						userId={userId}
					/>
				))}
			</ScrollableWrapper>
		</Column>
	);
}
