// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ICJ__SAA2zIck9m1ehh6 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./routes/group/report/activities/styles.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".groupName {\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\twhite-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export var groupName = `ICJ__SAA2zIck9m1ehh6`;
export default ___CSS_LOADER_EXPORT___;
