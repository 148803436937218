import { Fragment, useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import {
	useCurrentGroup,
	useCurrentOrganization,
	useCurrentMembership,
} from 'pkg/identity';
import * as actions from 'pkg/actions';
import { isApp } from 'pkg/platform';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { pushState, replaceState } from 'pkg/router/state';
import useConfirm from 'pkg/hooks/useConfirm';

import CollectionAddItem from 'containers/training/collection/AddItem';

import Heading from 'components/Heading';
import { RenderPrint, LargeScreen } from 'components/MediaQuery';
import Logo from 'components/Logo';
import { FormattedContent } from 'components/formatted-content';
import Tag from 'components/Tag';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import {
	PageAction,
	usePageActions,
} from 'components/navigation/header/small_screen/page_actions/Context';
import * as iconStyles from 'components/icon/styles.css';
import { GalleryModal } from 'components/attachment/gallery';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Attachment from 'components/attachment/View';
import Drawing from 'components/drawing/Drawing';
import { duplicationConfirmString } from 'components/training/exercise/utils';
import ExerciseDuplicationModal from 'components/training/exercise/ExerciseDuplicationModal';

import Button from 'design/button';

const Actions = styled.div`
	display: grid;
	align-items: center;
	justify-content: center;
	grid-auto-flow: column;
	border-top: 1px solid var(--palette-gray-400);
	padding-top: var(--spacing-5);
	max-width: 100%;
	min-width: 0;

	@media ${styles.breakpoint.large} {
		grid-gap: var(--spacing-3);
	}

	@media print {
		display: none;
	}
`;

const Author = styled.div`
	display: grid;
	grid-auto-flow: row;
	justify-items: center;
	justify-content: center;
	grid-gap: var(--spacing-6);

	margin-top: var(--spacing-6);
	color: var(--palette-gray-500);

	@media print {
		display: none;
	}

	${Logo} {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		padding: var(--spacing-3);
		background: var(--palette-black);
	}
`;

const AttachmentList = styled.div`
	display: grid;
	grid-gap: var(--spacing-6);
	justify-items: stretch;

	img {
		max-width: 100%;
		justify-self: center;
		cursor: pointer;
	}

	@media print {
		overflow: hidden;
		margin-bottom: 5mm;
	}
`;

const PrintLogo = styled.img`
	position: fixed;
	top: 1cm;
	right: 1cm;
	height: 1.5cm;
`;

const PrintNotes = styled.div`
	position: fixed;
	top: 4cm;
	right: 0;

	width: 30%;
	height: calc(100vh - 4cm);

	padding: 5mm;
	padding-bottom: 0;
	padding-right: 0;

	border: 1px solid var(--palette-gray-400);
	border-right: none;
	border-bottom: none;
	border-top-left-radius: 10px;

	color: var(--palette-gray-400);
	font-size: 9.5pt;
	font-weight: var(--font-weight-semibold);
`;

const Wrapper = styled.div`
	margin: var(--spacing-5) 0;
	max-width: 100%;
	display: grid;
	min-width: 0;
	grid-template-columns: 1fr;
	grid-auto-flow: row;
	grid-gap: var(--spacing-8);
	line-height: var(--font-line-height-xl);

	@media ${styles.breakpoint.small} {
		margin: var(--spacing-6) 0;
	}

	@media print {
		display: block;
		overflow: visible;
		width: 70%;
		padding: 1cm;
		margin: 0;
	}
`;

const Header = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-gap: var(--spacing-6);
`;

const Title = styled.h1`
	line-height: var(--font-line-height-4xl);

	@media ${styles.breakpoint.large} {
		padding-right: var(--spacing-9);
	}

	@media print {
		margin-bottom: 2mm;
	}
`;

const SubTitle = styled(Heading)`
	margin-bottom: var(--spacing-3);
	line-height: var(--font-line-height-xl);

	@media print {
		maring-bottom: 5mm;
	}
`;

const Tags = styled.div`
	margin-top: var(--spacing-3);
	display: flex;
	flex-flow: wrap;
	gap: var(--spacing-2);

	@media print {
		display: none;
	}
`;

const Data = styled.div`
	width: 100%;
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
	grid-gap: var(--spacing-8);
	background: var(--palette-gray-100);
	border: 1px solid var(--palette-gray-400);
	padding: var(--spacing-5);
	border-radius: var(--radius-3);

	@media ${styles.breakpoint.small} {
		grid-auto-flow: dense;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-gap: var(--spacing-6);
	}

	@media print {
		grid-row: 1;
		grid-auto-flow: column;
		grid-template-columns: repeat(4, auto);
		justify-content: center;
	}
`;

const DataItem = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
	font-weight: var(--font-weight-semibold);
	grid-gap: var(--spacing-3);

	.${iconStyles.icon} {
		transform: scale(1.3);
	}

	@media print {
		font-weight: var(--font-weight-semibold);

		.${iconStyles.icon} {
			transform: scale(1);
		}
	}
`;

const DataLabel = styled.span`
	@media print {
		font-size: 10pt;
	}
`;

const Introduction = styled.p`
	width: 100%;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-lg);
	line-height: var(--font-line-height-2xl);
	margin-bottom: var(--spacing-4);

	@media print {
		margin-bottom: 5mm;
		font-size: 12pt;
		line-height: var(--font-line-height-lg);
	}
`;

const MaterialRequirements = styled.div`
	width: 100%;

	@media print {
		margin-bottom: 5mm;
		font-size: 9.5pt;
		line-height: var(--font-line-height-sm);
	}
`;

const CoachingPoints = styled.div`
	background: var(--palette-blue-200);
	border-left: 5px solid var(--palette-blue-500);
	padding: var(--spacing-6);
	border-radius: var(--radius-3);

	${SubTitle} {
		color: var(--palette-blue-500);
	}
`;

const Group = styled.div`
	width: 100%;
`;

const Description = styled(Group)`
	@media print {
		margin-bottom: 5mm;
	}
`;

interface ContentProps {
	exerciseId: number;
	setPageTitle: (title: string) => void;
}

const Page = ({ exerciseId, setPageTitle }: ContentProps): JSX.Element => {
	const _context = 'exercises';
	const dispatch = useDispatch();

	const [activeCollectionModal, setActiveCollectionModal] = useState(false);
	const [showGalleryModal, setShowGalleryModal] = useState(false);
	const [activeId, setActiveId] = useState(0);
	const [isDuplicating, setIsDuplicating] = useState(false);
	const activeMembership = useCurrentMembership();
	const isAdminOrStaff = models.membership.isAdminOrStaff(activeMembership);

	const org = useCurrentOrganization();
	const group = useCurrentGroup();
	const { isLoading, record: exercise } = useEndpoint<models.exercise.Exercise>(
		endpoints.Exercises.Show(exerciseId)
	);

	useEffect(() => {
		if (exercise.title) {
			setPageTitle(exercise.title);
		}
	}, [exercise.title]);

	const handleEditAction = () => {
		pushState(routes.Exercise.Edit(org.id, exerciseId));
	};

	const handleDuplicateAction = useConfirm({
		hideOnBusy: true,
		message: duplicationConfirmString,
		onConfirm: async () => {
			setIsDuplicating(true);

			const newExercise = await actions.exercises.duplicateExercise(
				exercise.id
			);

			if (newExercise) {
				pushState(routes.Exercise.Edit(org.id, newExercise.id));
			}

			setIsDuplicating(false);
			return true;
		},
	});

	const handleOpenCollectionModal = () => {
		setActiveCollectionModal(true);
	};

	const handleCloseCollectionModal = () => {
		setActiveCollectionModal(false);
	};

	const handleOpenGallery = (event: ChangeEvent<any>) => {
		setActiveId(parseInt(event.currentTarget.dataset.id));
		setShowGalleryModal(true);
	};

	const handleCloseGallery = () => {
		setActiveId(0);
		setShowGalleryModal(false);
	};

	const handleToggleGallery = (event: ChangeEvent<any>) => {
		showGalleryModal ? handleCloseGallery() : handleOpenGallery(event);
	};

	const remove = async () => {
		const didConfirm = window.confirm(
			t(
				'Are you sure you want to delete this?\n\nThis action cannot be undone.',
				{ _context }
			)
		);

		if (didConfirm) {
			await actions.exercises.deleteExercise(exercise.id)(dispatch);
			replaceState(routes.TrainingLibrary.Index(org.id, group.id));
		}
	};

	const pageActions = [
		models.canEdit(exercise) && {
			label: t('Edit'),
			icon: 'edit',
			onClick: handleEditAction,
		},
		isAdminOrStaff && {
			label: t('Duplicate'),
			icon: 'file_copy',
			onClick: handleDuplicateAction,
		},
		isAdminOrStaff && {
			label: t('Add to'),
			icon: 'add',
			onClick: handleOpenCollectionModal,
		},
		isAdminOrStaff &&
			!isApp() && {
				label: t('Print'),
				icon: 'print',
				onClick: () => window.print(),
			},
		models.canDelete(exercise) && {
			label: t('Delete'),
			icon: 'delete',
			onClick: remove,
		},
	].filter(Boolean);

	usePageActions(pageActions as PageAction[], 'more_horiz');

	let tags = null;
	if (exercise.tags?.length > 0) {
		tags = (
			<Tags>
				{exercise.tags.map((tag) => (
					<Tag
						key={tag.id}
						href={`${routes.TrainingLibrary.Index(org.id, group.id)}?tags=${
							tag.name
						}`}
						small
						name={tag.name}
					/>
				))}
			</Tags>
		);
	}

	let duration = null;

	if (exercise.minTime && !exercise.maxTime) {
		duration = t('from {time}', { time: exercise.minTime, _context });
	} else if (!exercise.minTime && exercise.maxTime) {
		duration = t('to {time}', { time: exercise.maxTime, _context });
	} else if (exercise.minTime === exercise.maxTime) {
		duration = exercise.minTime;
	} else {
		duration = (
			<span>
				{exercise.minTime}–{exercise.maxTime}
			</span>
		);
	}

	let participants = null;

	if (exercise.minParticipants && !exercise.maxParticipants) {
		participants = t('min {num}', {
			num: exercise.minParticipants,
			_context,
		});
	} else if (!exercise.minParticipants && exercise.maxParticipants) {
		participants = t('max {num}', {
			num: exercise.maxParticipants,
			_context,
		});
	} else if (exercise.minParticipants === exercise.maxParticipants) {
		participants = exercise.minParticipants;
	} else {
		participants = (
			<span>
				{exercise.minParticipants}–{exercise.maxParticipants}
			</span>
		);
	}

	let ageSpan = null;

	if (exercise.minAge && !exercise.maxAge) {
		ageSpan = t('from {num}', {
			num: exercise.minAge,
			_context,
		});
	} else if (!exercise.minAge && exercise.maxAge) {
		ageSpan = t('up to {num}', {
			num: exercise.maxAge,
			_context,
		});
	} else {
		ageSpan = (
			<span>
				{exercise.minAge}–{exercise.maxAge}
			</span>
		);
	}

	let area = null;

	if (exercise.areaWidth && !exercise.areaLength) {
		area = t('W {num}', {
			num: exercise.areaWidth,
			_context,
		});
	} else if (!exercise.areaWidth && exercise.areaLength) {
		area = t('L {num}', {
			num: exercise.areaLength,
			_context,
		});
	} else {
		area = (
			<span>
				{exercise.areaWidth}×{exercise.areaLength}
			</span>
		);
	}

	let video = null;
	const videoMeta = exercise.meta?.find((n) => n.key === 'youtube-url');
	if (videoMeta) {
		video = (
			<Group>
				<FormattedContent raw={videoMeta.value} />
			</Group>
		);
	}

	let avatar = null;
	let exerciseUserName = '';

	if (exercise.user) {
		avatar = <Avatar size={42} user={exercise.user} />;
		exerciseUserName = models.user.fullName(exercise.user);
	} else if (exercise.author) {
		avatar = <Avatar size={42} account={exercise.author} />;
		exerciseUserName = models.account.fullName(exercise.author);
	}

	if (isLoading) {
		return null;
	}

	return (
		<Fragment>
			<Fragment>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					{' '}
					{tags}
					<Wrapper>
						<Header>
							<Title>{exercise.title}</Title>
							{exercise.introduction && (
								<Introduction>{exercise.introduction}</Introduction>
							)}

							{exercise &&
								[
									exercise.minTime,
									exercise.minParticipants,
									exercise.minAge,
									exercise.areaWidth,
								].some((item) => item > 0) && (
									<Data>
										{exercise.minTime > 0 && (
											<DataItem>
												<Icon name="nav-attendance" />
												<DataLabel>
													{duration} {t('min', { _context })}
												</DataLabel>
											</DataItem>
										)}
										{exercise.minParticipants > 0 && (
											<DataItem>
												<Icon name="nav-members" />
												<DataLabel>
													{participants} {t('p', { _context })}
												</DataLabel>
											</DataItem>
										)}
										{exercise.minAge > 0 && (
											<DataItem>
												<Icon name="age" />
												<DataLabel>
													{ageSpan} {t('yrs', { _context })}
												</DataLabel>
											</DataItem>
										)}
										{exercise.areaWidth > 0 && (
											<DataItem>
												<Icon name="area" />
												<DataLabel>{area}</DataLabel>
											</DataItem>
										)}
									</Data>
								)}
						</Header>
						{exercise.materialRequirements && (
							<MaterialRequirements>
								<SubTitle>{t('Material', { _context })}</SubTitle>
								<div>{exercise.materialRequirements}</div>
							</MaterialRequirements>
						)}
						{video}
						{exercise.exerciseDrawings?.map((drawing: any) => (
							<Drawing
								key={drawing.id}
								drawingId={drawing.id}
								drawing={JSON.parse(drawing.data)}
							/>
						))}
						<AttachmentList>
							{exercise.attachments?.map((a, i) =>
								a.type === 'image' ? (
									<img
										key={a.id}
										src={a.url}
										onClick={handleToggleGallery}
										alt={a.title}
										data-id={i}
										loading="lazy"
									/>
								) : (
									<Attachment link key={a.id} attachment={a} data-id={i} />
								)
							)}
						</AttachmentList>
						{exercise.description && (
							<Description>
								<SubTitle>{t('Description', { _context })}</SubTitle>
								<FormattedContent raw={exercise.description} />
							</Description>
						)}
						{exercise.keyPoints && (
							<CoachingPoints>
								<Group>
									<SubTitle>{t('Key coaching points', { _context })}</SubTitle>
									<FormattedContent raw={exercise.keyPoints} />
								</Group>
							</CoachingPoints>
						)}{' '}
						<LargeScreen>
							<Actions>
								{models.canEdit(exercise) && (
									<Button inline icon="edit" onClick={handleEditAction}>
										{t('Edit')}
									</Button>
								)}
								{isAdminOrStaff && (
									<Fragment>
										<Button
											inline
											icon="file_copy"
											onClick={handleDuplicateAction}>
											{t('Duplicate')}
										</Button>
										<Button
											inline
											icon="add"
											onClick={handleOpenCollectionModal}>
											{t('Add to', { _context: 'training_library' })}
										</Button>
										{!isApp() && (
											<Button
												inline
												icon="print"
												onClick={() => window.print()}>
												{t('Print')}
											</Button>
										)}
									</Fragment>
								)}
								{models.canDelete(exercise) && (
									<Button inline icon="delete" onClick={remove}>
										{t('Delete')}
									</Button>
								)}
							</Actions>
						</LargeScreen>
						{exerciseUserName && exercise.userCreated && (
							<Author>
								{avatar}
								{t('Created by {name}', {
									name: exerciseUserName,
									_context,
								})}
							</Author>
						)}
						{!exercise.userCreated && (
							<Author>
								<Logo />
								{t('Created by {name}', {
									name: '360Player',
									_context,
								})}
							</Author>
						)}
					</Wrapper>
				</LargeScreenContent.Inner>

				<RenderPrint>
					<PrintLogo src={`${window.TS.assetUrl}/img/logo_black.svg`} />
					<PrintNotes>
						{t('Notes', { _context: 'training_library' })}:
					</PrintNotes>
				</RenderPrint>

				{showGalleryModal && (
					<GalleryModal
						activeId={activeId}
						attachments={exercise.attachments}
						onClose={handleCloseGallery}
					/>
				)}
			</Fragment>

			{isDuplicating && <ExerciseDuplicationModal />}

			{activeCollectionModal && (
				<CollectionAddItem
					onClose={handleCloseCollectionModal}
					type="exercises"
					id={exercise.id}
				/>
			)}
		</Fragment>
	);
};
export default Page;
