import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

interface ActionsProps {
	group: models.group.Group;
	activeGroupId: number;
	showAddUserModal: () => void;
}

const Actions: React.FC<React.PropsWithChildren<ActionsProps>> = ({
	group,
	activeGroupId,
	showAddUserModal,
}) => {
	const currentMembership = useCurrentMembership();
	const org = useCurrentOrganization();

	const handleSetActive = async () => {
		pushState(routes.Group.Change(), {
			groupId: group.id.toString(),
			membershipData: JSON.stringify({
				userId: currentMembership.userId,
				role: currentMembership.role,
				capabilities: currentMembership.capabilities,
			}),
		});
	};

	const handleDelete = async () => {
		await actions.groups.deleteGroup(group.id);

		actions.groups.fetchGroup(group.parentGroupId);
	};

	const isRoot = activeGroupId === group.id;

	const contextActions = [
		<ContextMenu.LinkItem key="add_user" onClick={showAddUserModal}>
			<ContextMenu.ItemIcon name="person" />
			{t(`Add users to group`)}
		</ContextMenu.LinkItem>,
	];

	if (!isRoot) {
		contextActions.push(
			<ContextMenu.LinkItem key="go_to" onClick={handleSetActive}>
				<ContextMenu.ItemIcon name="arrow_forward" />
				{t(`Go to group`)}
			</ContextMenu.LinkItem>,
			<ContextMenu.LinkItem
				key="subgroup-settings"
				href={routes.Organization.SubGroup.Settings(org.id, group.id)}>
				<ContextMenu.ItemIcon name="edit" />
				{t(`Edit {groupName}`, {
					groupName: group.name,
				})}
			</ContextMenu.LinkItem>,
			<ContextMenu.LinkItem
				key="manage-users"
				href={routes.Group.Show(org.id, group.id)}>
				<ContextMenu.ItemIcon name="groups" />
				{t(`Manage users`)}
			</ContextMenu.LinkItem>
		);
	}

	if (!models.group.canShowChildren(group) && !isRoot) {
		contextActions.push(
			<ContextMenu.ConfirmItem
				key="delete"
				message={t(
					`This will delete the group {groupName}, and deactivate all it's users.`,
					{
						groupName: group.name,
					}
				)}
				caution
				onConfirm={handleDelete}>
				<ContextMenu.ItemIcon name="delete" />
				{t('Delete')}
			</ContextMenu.ConfirmItem>
		);
	}

	return (
		<ContextMenu.Menu
			toggleWith={
				<Button primary hasIcon block>
					{t(`Actions`)}
					<Icon name="chevron" rotate="90deg" />
				</Button>
			}>
			{contextActions}
		</ContextMenu.Menu>
	);
};

export default Actions;
