import { CSSProperties } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import Account from 'pkg/models/account';
import User from 'pkg/models/user';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import Icon from 'components/icon';

import * as css from './styles.css';

interface AvatarProps {
	user?: User | models.user.User;
	isEditable?: boolean;
	customImageUrl?: string;
	account?: Account | models.account.Account;
	size?: number;
	testid?: string;
	renderEmptyWith?: JSX.Element;
	className?: string;
	onClick?: () => void;
	style?: CSSProperties;
}

const Avatar = ({
	user,
	isEditable,
	customImageUrl = '',
	account,
	size = null,
	testid = 'avatar',
	renderEmptyWith = null,
	className,
	onClick,
	style,
}: AvatarProps): JSX.Element => {
	let initials;
	let imageUrl;

	if (account) {
		if (account instanceof Account) {
			initials = account.initials;
			imageUrl = account.profileImageUrl;
		} else {
			initials = models.account.initials(account);
			imageUrl = account.profileImageUrl;
		}
	}

	if (user) {
		if (user instanceof User) {
			initials = user.initials;
			imageUrl = user.profileImageUrl;
		} else {
			initials = models.user.initials(user);
			imageUrl = user.profileImageUrl;
		}
	}

	if (customImageUrl) {
		imageUrl = customImageUrl;
	}

	let avatar: JSX.Element;

	if (imageUrl?.length > 0) {
		// Avatar image
		avatar = (
			<img
				className={css.image}
				src={imageUrl}
				data-pin-nopin="true"
				loading="lazy"
			/>
		);
	} else if (!initials && !imageUrl) {
		// No user or account, show default icon
		avatar = (
			<div className={css.noUserOverlay}>
				<Icon
					name="nav-profile"
					size={size ? size * 0.04 : undefined}
					fill={styles.palette.blue[500]}
				/>
			</div>
		);
	} else {
		// Initials
		avatar = renderEmptyWith || (
			<svg className={css.initials} viewBox="0 0 100 100">
				<text x="50" y="64" textAnchor="middle">
					{initials}
				</text>
			</svg>
		);
	}

	// Show background only if no image URL is present
	const showBackgroundColor = !imageUrl;

	return (
		<div
			className={cssClasses(
				className,
				css.wrapper,
				showBackgroundColor && css.showBackgroundColor
			)}
			style={{
				width: size ? `${size}px` : undefined,
				...style,
			}}
			data-testid={testid}
			onClick={onClick}>
			<div className={css.container}>
				{avatar}
				{isEditable && (
					<div className={css.overlay}>
						<Icon size={size ? size * 0.04 : undefined} name="upload" />
					</div>
				)}
			</div>
		</div>
	);
};

export default styled(Avatar)``;
