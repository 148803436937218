import { t } from '@transifex/native';
import { ChangeEvent } from 'react';

import useMixedState from 'pkg/hooks/useMixedState';
import { FilterOperator } from 'pkg/filters';
import { FilterRow, NumberFilterSettings } from 'pkg/filters/use_filters';
import { BaseFilterProps } from 'pkg/filters/types';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

type NumberProps = BaseFilterProps;

export function NumberFilter({
	filter,
	setFilter,
	currentFilters,
}: NumberProps): JSX.Element {
	const operator = currentFilters?.[0]?.operator || filter.operator;

	const [filterState, setFilterState] = useMixedState({
		operator: operator || FilterOperator.Equals,
		value: currentFilters?.[0]?.values[0] || 0,
	});

	const settings = (filter.settings || {}) as NumberFilterSettings;
	// since the filter component has pre-filled values, insta set the filter when opening the filter.
	useComponentDidMount(() => {
		setFilter({ ...filter, operator: filterState.operator }, [
			filterState.value,
		]);
	});

	const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.validity.valid) {
			return;
		}

		const { value } = event.target;
		const floatValue = Number.parseFloat(value);

		setFilterState({
			value: settings.money ? floatValue * 100 : floatValue,
		});

		setFilter({ ...filter, operator: filterState.operator }, [
			settings.money ? floatValue * 100 : floatValue,
		]);
	};

	const handleChangeOperator = (event: ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;

		setFilterState({
			operator: value as FilterOperator,
		});

		setFilter({ ...filter, operator: value as FilterOperator }, [
			filterState.value,
		]);
	};

	return (
		<FilterRow>
			<Column>
				<Input.Field
					onChange={handleChangeValue}
					type="number"
					defaultValue={
						settings.money
							? (filterState.value / 100).toFixed(2)
							: filterState.value
					}
					step={settings.money && '.01'}
					pattern="[0-9.]+"
				/>
				<Input.Select
					defaultValue={filterState.operator}
					onChange={handleChangeOperator}>
					<option value={FilterOperator.Equals}>{t(`is equal to`)}</option>
					<option value={FilterOperator.GreaterThan}>
						{t(`is greater than`)}
					</option>
					<option value={FilterOperator.LessThan}>{t(`is less than`)}</option>
				</Input.Select>
			</Column>
		</FilterRow>
	);
}
