// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eXJ72KtAr4c2l5foUlnu {
	font-weight: var(--font-weight-semibold);
}

._4CtEzif1x0N4MPIVsSd {
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
}
`, "",{"version":3,"sources":["webpack://./routes/event/series/single/attendance/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAAwC;AACzC;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B","sourcesContent":[".title {\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.description {\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `eXJ72KtAr4c2l5foUlnu`;
export var description = `_4CtEzif1x0N4MPIVsSd`;
export default ___CSS_LOADER_EXPORT___;
