import { ReactNode } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import SectionTitle from 'components/SectionTitle';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: repeat(2, auto);
	row-gap: var(--spacing-5);
	max-width: 1800px;
	width: 100%;
	margin: 0 auto;
	align-items: center;
`;

const Container = styled.div`
	width: 100%;
	grid-area: 2 / 1 / 3 / 3;
	display: grid;
	grid-auto-rows: auto;
	row-gap: var(--spacing-6);
	align-items: stretch;

	@media ${styles.breakpoint.small} {
		grid-template-columns: 1fr;
		column-gap: var(--spacing-5);
		row-gap: var(--spacing-5);
	}

	&[data-context='video-library'] {
		@media ${styles.breakpoint.large} {
			grid-template-columns: repeat(3, 1fr);
			column-gap: var(--spacing-6);
		}

		@media all and (min-width: 1100px) {
			grid-template-columns: repeat(3, 1fr);
			column-gap: var(--spacing-6);
		}

		@media all and (min-width: 1400px) {
			grid-template-columns: repeat(4, 1fr);
			column-gap: var(--spacing-6);
		}

		@media all and (min-width: 1600px) {
			grid-template-columns: repeat(5, 1fr);
			column-gap: var(--spacing-8);
		}
	}

	&[data-context='training-library'] {
		@media ${styles.breakpoint.large} {
			grid-template-columns: repeat(2, 1fr);
			column-gap: var(--spacing-6);
		}

		@media ${styles.breakpoint.nolimit} {
			grid-template-columns: repeat(3, 1fr);
			column-gap: var(--spacing-8);
		}

		@media all and (min-width: 1600px) {
			grid-template-columns: repeat(4, 1fr);
			column-gap: var(--spacing-8);
		}
	}
`;

interface GroupProps {
	title: string;
	children: ReactNode;
	renderActionWith?: ReactNode;
	context?: 'video-library' | 'training-library';
	icon?: MaterialSymbolVariant;
}

export interface GroupWrapperProperties<T> {
	items: T[];
	title: string;
	hasNext?: boolean;
	isLoading?: boolean;
	fetchNext?: () => void;
	replaceRecord?: (record: T) => void;
	removeRecord?: (recordId: number) => void;
}

export default function Group({
	title,
	children,
	renderActionWith,
	context = 'video-library',
	icon,
}: GroupProps): JSX.Element {
	return (
		<Wrapper>
			<SectionTitle icon={icon}>{title}</SectionTitle>
			<div>{renderActionWith || <span />}</div>
			<Container data-context={context}>{children}</Container>
		</Wrapper>
	);
}
