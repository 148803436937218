import { ChangeEvent, useState } from 'react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { MembershipRole } from 'pkg/models/membership';

import useMixedState from 'pkg/hooks/useMixedState';
import Link from 'pkg/router/Link';
import { Account } from 'pkg/api/models/onboarding_info';
import * as actions from 'pkg/actions';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCurrentAccount } from 'pkg/identity';

import Field from 'routes/public/styles/inputs/field';
import { useOnboardingContext } from 'routes/public/onboarding';
import {
	useOnboardingAccountPayload,
	OnboardingAccountPayload,
	useOnboardingState,
} from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import FooterNote from 'routes/public/styles/FooterNote';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import Header from 'routes/public/no-memberships/styles/Header';
import Form from 'routes/public/styles/forms';

import Column from 'components/layout/column';

interface AccountDetailsPayload {
	email?: string;
	emailConfirmation?: string;
	password?: string;
	passwordConfirmation?: string;
}

export default function CreateChildAccountDetails(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const [isSaving, setIsSaving] = useState(false);

	const parentAccount = useCurrentAccount();
	const targetAccountFromState = onboardingState.get<Account>('targetAccount');

	const [payload, setPayload] = useMixedState<AccountDetailsPayload>({
		...targetAccountFromState,
		emailConfirmation: targetAccountFromState.email,
		passwordConfirmation: targetAccountFromState.password,
	});

	const getPayload = useOnboardingAccountPayload();

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPayload({ [event.target.name]: event.target.value });
	};

	const canContinue =
		payload.email &&
		payload.email === payload.emailConfirmation &&
		payload.password &&
		payload.password === payload.passwordConfirmation;

	const handleSave = async () => {
		setIsSaving(true);

		const account: Account = {
			...onboardingState.get('targetAccount'),
			email: payload.email,
			password: payload.password,
			countryId: parentAccount?.countryId,
		};

		await onboardingState.setTargetAccount(account);

		let canContinue: boolean;

		try {
			const [request, wardAccount] = await api.post<
				models.account.Account,
				OnboardingAccountPayload
			>(endpoints.Accounts.CreateChildAccountForAccount(parentAccount.id), {
				...getPayload(true),
				email: payload.email,
				password: payload.password,
			});

			if (!request.ok) {
				setIsSaving(false);

				throw new Error(`${request.status}`);
			}
			const groupCode = onboardingState.get<string>('groupCode');

			const [joinRequest] = await api
				.forAccount(wardAccount.id)
				.post(endpoints.Groups.JoinFromInviteCode(), {
					code: groupCode,
					role: MembershipRole.User,
				});

			if (!joinRequest.ok) {
				setIsSaving(false);

				throw new Error(`${joinRequest.status}`);
			}

			canContinue = true;
		} catch (error: unknown) {
			canContinue = false;

			const status = Number.parseInt((error as Error).message, 10);

			let title = t('Something went wrong');
			let message = t(
				`If you think this error isn't supposed to happen, please contact 360Player support.`
			);

			switch (status) {
				case 400:
					title = t('Could not create account');
					break;
				case 409:
					title = t('Email already in use');
					message = t('This email is already registered in 360Player');
					break;
			}

			actions.flashes.show({
				title: title,
				message: message,
			});
		} finally {
			if (!canContinue) {
				setIsSaving(false);
				return;
			}

			goTo('create-ward-account-created');
		}
	};

	return (
		<Column spacing={styles.spacing._8}>
			<Header />

			<Title title={t(`Please provide your child's account details`)} />

			<Form onSubmit={handleSave}>
				<Column spacing={styles.spacing._7}>
					<Column>
						<Field
							required
							type="email"
							name="email"
							placeholder={t(`Your child's email`)}
							errorMessage={t('Please enter a valid email')}
							value={payload.email}
							onChange={handleInputChange}
							tabIndex={1}
							inputMode="email"
						/>
						<Field
							required
							type="email"
							name="emailConfirmation"
							placeholder={t('Confirm email')}
							errorMessage={t('Emails do not match')}
							pattern={payload.email?.replace('+', '\\+')}
							value={payload.emailConfirmation}
							onChange={handleInputChange}
							tabIndex={2}
							inputMode="email"
						/>
						<Field
							required
							name="password"
							type="password"
							placeholder={t(`Your child's password`)}
							minLength={8}
							value={payload.password}
							onChange={handleInputChange}
							tabIndex={3}
						/>
						<Field
							required
							name="passwordConfirmation"
							type="password"
							placeholder={t('Confirm password')}
							errorMessage={t('Passwords do not match')}
							minLength={8}
							validateOnChange
							pattern={payload.password}
							value={payload.passwordConfirmation}
							onChange={handleInputChange}
							tabIndex={4}
						/>
					</Column>
					<Button
						primary
						busy={isSaving}
						disabled={!canContinue}
						label={t('Create account')}
						type="submit"
					/>
				</Column>
			</Form>

			<FooterNote>
				<T
					_str="By creating your child's account you agree to our {terms_and_conditions_link}"
					terms_and_conditions_link={
						<Link href="https://www.360player.com/terms-of-service">
							{t('terms and conditions')}
						</Link>
					}
				/>
			</FooterNote>

			<CancelButton disabled={isSaving} />
		</Column>
	);
}
