import { t } from '@transifex/native';
import { useState } from 'react';

import * as styles from 'pkg/config/styles';

import Account from 'pkg/models/account';

import { MembershipRole } from 'pkg/api/models/membership';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Group } from 'pkg/api/models/onboarding_info';

import {
	OnboardingAnimationDirection,
	useOnboardingContext,
} from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import CancelButton from 'routes/public/styles/CancelButton';
import TeamBadge from 'routes/public/styles/TeamBadge';
import Title from 'routes/public/styles/Title';

import Column from 'components/layout/column';

export default function ChildGroupConfirmation(): JSX.Element {
	const { goTo, resetStore } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const [isSaving, setIsSaving] = useState(false);

	const targetAccount = onboardingState.get<Account>('targetAccount');
	const groupCode = onboardingState.get<string>('groupCode');
	const group = onboardingState.get<Group>('group');

	const handleSave = async () => {
		setIsSaving(true);

		const [joinRequest] = await api
			.forAccount(targetAccount.id)
			.post(endpoints.Groups.JoinFromInviteCode(), {
				code: groupCode,
				role: MembershipRole.User,
			});

		if (!joinRequest.ok) {
			setIsSaving(false);
		} else {
			resetStore();
			window.history.pushState({}, '', '/');
			window.location.reload();
		}
	};

	const handleGoBack = () => {
		goTo('group-code', OnboardingAnimationDirection.Out);
	};

	return (
		<Column spacing={styles.spacing._8}>
			<Title
				title={t('You are about to add {name} as a player in:', {
					name: `${targetAccount.firstName} ${targetAccount.lastName}`,
				})}
			/>

			<TeamBadge group={group} />

			<Column>
				<Button
					primary
					label={t('Continue')}
					onClick={handleSave}
					busy={isSaving}
				/>
				<Button
					transparent
					label={t('Go back')}
					onClick={handleGoBack}
					disabled={isSaving}
				/>
			</Column>

			<CancelButton disabled={isSaving} />
		</Column>
	);
}
