import { Fragment, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import * as models from 'pkg/api/models';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import Curated from 'routes/training/library/Curated';
import Browse from 'routes/training/library/Browse';
import Group from 'routes/training/library/Group';
import Club from 'routes/training/library/Club';
import Account from 'routes/training/library/Account';
import Collection from 'routes/training/library/Collection';
import FilterMenu from 'routes/training/library/FilterMenu';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import CollectionModal from 'components/training-library/modals/Collection';
import * as ActionBar from 'components/layout/ActionBar';
import TagSearchInput from 'components/form/TagSearchInput';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const ContentWrapper = styled.div`
	padding: var(--spacing-8);
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-8);

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-5);
	}
`;

enum LibraryContentGroup {
	Browse = '',
	Team = 'team',
	Club = 'club',
	Your = 'your',
	Curated = 'curated',
}

interface LibraryProps {
	contentGroup: LibraryContentGroup;
	collectionId?: string;
	setPageActions?: (node: ReactNode) => void;
	setPageSubNavItems?: (items: any[]) => void;
}

export default function Library({
	contentGroup,
	collectionId,
	setPageActions,
	setPageSubNavItems,
}: LibraryProps): JSX.Element {
	const { groupId } = useCurrentRoute();
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();

	const [activeModal, setActiveModal] = useState<'' | 'collection'>('');

	const openCollectionModal = () => setActiveModal('collection');

	const closeModal = () => setActiveModal('');

	const createActions = (
		<Fragment>
			<ContextMenu.LinkItem href={routes.Exercise.Create(org.id)}>
				<ContextMenu.ItemIcon name="tactic" />
				{t('Exercise')}
			</ContextMenu.LinkItem>
			<ContextMenu.LinkItem href={routes.TrainingSession.Create(org.id)}>
				<ContextMenu.ItemIcon name="schedule" />
				{t('Session')}
			</ContextMenu.LinkItem>
			<ContextMenu.Item onClick={openCollectionModal}>
				<ContextMenu.ItemIcon name="folder" />
				{t('Collection')}
			</ContextMenu.Item>
		</Fragment>
	);

	const handleAfterCollectionSave = (
		collection: models.trainingCollection.TrainingCollection
	) => {
		pushState(
			routes.TrainingLibrary.Collection.Show(org.id, groupId, collection.id)
		);
	};

	useEffect(() => {
		if (models.membership.isParent(membership)) {
			setPageSubNavItems([]);
		} else {
			setPageActions(
				<ContextMenu.Menu toggleWith={<ButtonTrigger icon="add" />}>
					{createActions}
				</ContextMenu.Menu>
			);
		}
	}, [membership.id]);

	let viewComponent: ReactNode = <Browse />;

	switch (contentGroup) {
		case LibraryContentGroup.Team:
			viewComponent = <Group />;
			break;
		case LibraryContentGroup.Club:
			viewComponent = <Club />;
			break;
		case LibraryContentGroup.Your:
			viewComponent = <Account />;
			break;
		case LibraryContentGroup.Curated:
			viewComponent = <Curated />;
			break;
	}

	const modals = (
		<Fragment>
			{activeModal === 'collection' && (
				<CollectionModal
					onClose={closeModal}
					afterSave={handleAfterCollectionSave}
				/>
			)}
		</Fragment>
	);

	if (collectionId) {
		return (
			<Fragment>
				{modals}
				<Collection />
			</Fragment>
		);
	}

	return (
		<Fragment>
			{modals}
			<LargeScreen>
				<ActionBar.FilterBar>
					<ActionBar.PrimaryAction>
						<TagSearchInput />
					</ActionBar.PrimaryAction>
					<FilterMenu />
					<ContextMenu.Menu
						toggleWith={
							<Button primary icon="add">
								{t('Add content')}
							</Button>
						}>
						{createActions}
					</ContextMenu.Menu>
				</ActionBar.FilterBar>
			</LargeScreen>
			<SmallScreen>
				<ActionBar.FilterBar>
					<ActionBar.PrimaryAction>
						<TagSearchInput />
					</ActionBar.PrimaryAction>
					<FilterMenu />
				</ActionBar.FilterBar>
			</SmallScreen>
			<ContentWrapper>{viewComponent}</ContentWrapper>
		</Fragment>
	);
}
