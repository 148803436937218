// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qKpz6pUKXIUzl5hRBYWQ {
	cursor: pointer;
	border-radius: var(--radius-3);
	width: 37px;
	height: 37px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	padding: 0;
	background: var(--palette-blue-200);
	-webkit-transition: all 150ms ease-in-out;
	transition: all 150ms ease-in-out;
}
.qKpz6pUKXIUzl5hRBYWQ svg {
		color: var(--palette-blue-500);
		font-size: var(--font-size-xl);
	}
@media (hover: hover) {
		.qKpz6pUKXIUzl5hRBYWQ:hover {
			background: var(--palette-blue-300);
			color: var(--palette-blue-500);
			-webkit-box-shadow: var(--palette-blue-200) 0 0 0 4px;
			        box-shadow: var(--palette-blue-200) 0 0 0 4px;
		}

			.qKpz6pUKXIUzl5hRBYWQ:hover svg {
				color: var(--palette-blue-500);
			}
	}
`, "",{"version":3,"sources":["webpack://./components/add-button/styles.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,8BAA8B;CAC9B,WAAW;CACX,YAAY;CACZ,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,UAAU;CACV,mCAAmC;CACnC,yCAAiC;CAAjC,iCAAiC;AAkBlC;AAhBC;EACC,8BAA8B;EAC9B,8BAA8B;CAC/B;AAEA;EACC;GACC,mCAAmC;GACnC,8BAA8B;GAC9B,qDAA6C;WAA7C,6CAA6C;EAK9C;;GAHC;IACC,8BAA8B;GAC/B;CAEF","sourcesContent":[".button {\n\tcursor: pointer;\n\tborder-radius: var(--radius-3);\n\twidth: 37px;\n\theight: 37px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tpadding: 0;\n\tbackground: var(--palette-blue-200);\n\ttransition: all 150ms ease-in-out;\n\n\t& svg {\n\t\tcolor: var(--palette-blue-500);\n\t\tfont-size: var(--font-size-xl);\n\t}\n\n\t@media (hover: hover) {\n\t\t&:hover {\n\t\t\tbackground: var(--palette-blue-300);\n\t\t\tcolor: var(--palette-blue-500);\n\t\t\tbox-shadow: var(--palette-blue-200) 0 0 0 4px;\n\n\t\t\t& svg {\n\t\t\t\tcolor: var(--palette-blue-500);\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var button = `qKpz6pUKXIUzl5hRBYWQ`;
export default ___CSS_LOADER_EXPORT___;
