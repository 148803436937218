import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import { Capabilities, MembershipRole } from 'pkg/models/membership';

import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import { UserSex } from 'pkg/api/models/user';

import EditModal from 'routes/group/settings/tabs/group_links/EditModal';
import GroupLink from 'routes/group/settings/tabs/group_links/GroupLink';

import Icon from 'components/icon';
import MaterialSymbol from 'components/material-symbols';

import * as Sortable from 'components/dnd/sortable';
import Row from 'components/layout/row';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import Button from 'design/button';

import * as css from './styles.css';

interface GroupLinkFolderProps {
	groupId: number;
	folder: models.groupLinkFolder.GroupLinkFolder;
	refresh: () => void;
}
export interface Rule {
	allMustMatch?: boolean;
	displayInGroups?: number[];
	userAgeRange?: number[];
	userCapability?: Capabilities[];
	displayInClubLobby?: boolean;
	isParent?: boolean;
	userSex?: UserSex[];
	userGroupRole?: MembershipRole[];
}

interface Condition {
	id: string;
	type: string;
	value: any;

	allMustMatch?: string;
	from?: string;
	to?: string;
	ids?: string;
}

interface Conditions {
	[key: string]: Condition;
}

export default function GroupLinkFolder({
	folder,
	refresh,
	groupId,
}: GroupLinkFolderProps): JSX.Element {
	const theme: Sortable.Theme = {
		container: css.container,
		activeContainer: css.active,
		item: css.item,
		activeItem: css.active,
		siblingItem: css.sibling,
		overlayItem: css.overlay,
	};
	const [editModal, setEditModal] = useState(false);
	const [isEditingLink, setIsEditingLink] = useState<number>(0);

	const [rules, setRules] = useState<Conditions[]>([]);

	const hideEditModal = () => setEditModal(false);
	const openEditModal = () => setEditModal(true);

	const newLink = () => setIsEditingLink(-1);

	if (!folder) {
		return null;
	}

	const renderLink = (link: models.groupLink.GroupLink) => (
		<GroupLink
			groupId={groupId}
			link={link}
			refresh={refresh}
			handleDeleteRule={handleDeleteRule}
			isEditingLink={isEditingLink}
			setIsEditingLink={setIsEditingLink}
		/>
	);
	const handleLinkChange = async (links: models.groupLink.GroupLink[]) => {
		await Promise.all(
			links.map((link: models.groupLink.GroupLink, i) => {
				link.sortOrder = i + 1;
				return sdk.patch(endpoints.GroupLinks.Update(link.id), {}, link);
			})
		);
	};

	const handleDeleteRule = async (index: number) => {
		const updatedRules = rules.slice(0, index).concat(rules.slice(index + 1));
		setRules(updatedRules);
	};

	const content = (
		<div className={css.categoryWrapper}>
			<Row
				className={css.categoryHeader}
				spacing={spacing._3}
				autoColumns="max-content">
				<Icon name="drag-handle" size={1.4} />
				<MaterialSymbol variant={folder.iconName as MaterialSymbolVariant} />
				<div className={css.categoryTitle}>{folder.title}</div>
				<Icon className={css.editButton} onClick={openEditModal} name="edit" />
			</Row>
			<div className={css.linksWrapper}>
				{folder.links && (
					<Sortable.Container
						items={folder.links}
						renderWith={renderLink}
						theme={theme}
						onChange={handleLinkChange}
					/>
				)}
			</div>

			{isEditingLink === -1 ? (
				<GroupLink
					groupId={groupId}
					link={{
						id: -1,
						rules: [{ allMustMatch: true }],
						groupLinkFolderId: folder.id,
						title: '',
						url: '',
						sortOrder: 0,
					}}
					handleDeleteRule={handleDeleteRule}
					refresh={refresh}
					isEditingLink={isEditingLink}
					setIsEditingLink={setIsEditingLink}
					newLink={true}
				/>
			) : (
				<div>
					<Button icon="add" inline noPadding onClick={newLink}>
						{t('New link in folder')}
					</Button>
				</div>
			)}
		</div>
	);

	return (
		<Fragment>
			{isEditingLink ? (
				content
			) : (
				<Sortable.Item id={folder.id}>{content}</Sortable.Item>
			)}
			{editModal && (
				<EditModal
					folder={folder}
					groupId={groupId}
					hideModal={hideEditModal}
					refresh={refresh}
				/>
			)}
		</Fragment>
	);
}
