import { Fragment, FunctionComponent } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import * as models from 'pkg/api/models';

import AppearanceSettings from 'routes/group/settings/AppearanceSettings';
import DisplaySettings from 'routes/group/settings/DisplaySettings';
import CoreSettings from 'routes/group/settings/core_settings';
import InviteCodeSettings from 'routes/group/settings/GroupCodeSettings';
import Competitions from 'routes/settings/competitions';

import FogisSettings from 'containers/group/settings/Fogis';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import { ViewSettings } from 'components/layout/PageTemplates';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';

interface GroupSettingsProps extends ViewSettings {
	group: models.group.Group;
	currentTab: string;
	refresh: () => void;
	replaceRecord?: (record: models.group.Group) => void;
}

const TeamSettings: FunctionComponent<GroupSettingsProps> = ({
	group,
	currentTab,
	refresh,
	replaceRecord,
}): JSX.Element => {
	const renderTabViews = (currentTab: string) => {
		switch (currentTab) {
			case 'competitions':
				return <Competitions groupId={group.id} />;
			case 'import': {
				return <FogisSettings group={group} refresh={refresh} />;
			}
			default:
				return (
					<Column>
						<InviteCodeSettings group={group} refresh={refresh} />
						<CoreSettings
							group={group}
							replaceRecord={replaceRecord}
							refresh={refresh}
						/>
						<AppearanceSettings group={group} refresh={refresh} />
						<DisplaySettings group={group} />
					</Column>
				);
		}
	};

	const wideViews = ['user-management', 'organization'];

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Wrapper key={currentTab}>
					<LargeScreenContent.Inner
						maxWidth={
							wideViews.includes(currentTab)
								? PageWidths.WIDE
								: PageWidths.STANDARD
						}>
						{renderTabViews(currentTab)}
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<LargeScreenContent.Inner>
					{renderTabViews(currentTab)}
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
};

export default TeamSettings;
