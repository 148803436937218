import { Fragment, memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { List } from 'immutable';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { fetchSession, deleteSession } from 'pkg/actions/sessions';

import { replaceState } from 'pkg/router/state';
import * as selectors from 'pkg/selectors';
import { isApp } from 'pkg/platform';
import * as routes from 'pkg/router/routes';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import ExerciseItem from 'containers/training/library/SessionExerciseItem';
import CollectionAddItem from 'containers/training/collection/AddItem';
import ViewExerciseModal from 'containers/training/session/ViewExerciseModal';

import { LargeScreen } from 'components/MediaQuery';

import { usePageActions } from 'components/navigation/header/small_screen/page_actions/Context';
import Block from 'components/training/session/view/Block';
import SessionBlock from 'components/training/session/view/Session';
import DeletedExercise from 'components/training/session/DeletedExercise';
import UserSelectableListModal from 'components/user/SelectableListModal';
import DuplicateSessionModal from 'components/training/session/duplicate-session-modal';

import Button from 'design/button';

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-gap: 2rem;
`;

const Actions = styled.div`
	text-align: center;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 0.5rem;
	align-items: center;
	justify-content: center;

	@media print {
		display: none;
	}
`;

const Timeline = styled.div`
	margin-left: 2px;
	margin-top: 30px;
	margin-bottom: 2px;
	height: calc(100% + 27px);
	width: 0px;
	top: -27px;
	position: relative;
	border-left: var(--palette-gray-400) 4px dotted;

	${(props) =>
		props.hideTimeline &&
		css`
			opacity: 0;
		`}

	&::before {
		background: white;
		content: '';
		display: block;
		width: 17px;
		height: 17px;
		position: absolute;
		border-radius: 20px;
		transform: translateX(calc(-50% - 2px));
		box-shadow: 0 0 0 3px var(--palette-gray-400);
		position: sticky;
		top: 20px;
	}
`;

const BlockWrapper = styled.div`
	width: 100%;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 30px 1fr;
	align-items: stretch;
	justify-items: stretch;

	@media ${styles.breakpoint.small} {
		grid-template-columns: 20px 1fr;
		grid-gap: 15px;
	}
`;

const SessionBlockEntity = ({
	session,
	eventUsers,
	sessionBlock,
	onExerciseClick,
}) => {
	const exercises = useSelector((state) =>
		selectors.sessionBlocks.findAllExercisesOf(state, {
			sessionId: session.id,
			blockId: sessionBlock.id,
		})
	);

	const blockItems = useSelector((state) =>
		selectors.sessionItems.findAllByBlock(state, {
			sessionId: session.id,
			blockId: sessionBlock.id,
		})
	);

	return (
		<BlockWrapper key={sessionBlock.id}>
			<Timeline />
			<Block item={sessionBlock} exercises={exercises}>
				{exercises.map((exercise, n) => {
					const itemUserIds = blockItems.getIn([n, 'users']);

					const itemUsers = new List(
						itemUserIds.map((userId) =>
							eventUsers?.find((user) => user.id === userId)
						)
					);

					return exercise ? (
						<ExerciseItem
							key={n}
							id={exercise.id}
							onClick={() => onExerciseClick(exercise.id)}
							noTagSearch>
							{session.eventId && eventUsers.size > 0 && (
								<UserSelectableListModal
									users={itemUsers}
									selectedUserIds={itemUserIds}
									selectable={false}
									renderEmptyWith={<span />}
								/>
							)}
						</ExerciseItem>
					) : (
						<DeletedExercise key={n} />
					);
				})}
			</Block>
		</BlockWrapper>
	);
};

const View = memo(
	({
		sessionId,
		allowCollections = true,
		ignoreRedirect,
		afterDelete,
		inEventView = false,
		hasEventEditRights = false,
	}) => {
		const dispatch = useDispatch();
		const org = useCurrentOrganization();

		const [activeCollectionModal, setActiveCollectionModal] = useState(false);
		const [exerciseModal, setExerciseModal] = useState({
			id: 0,
			active: false,
		});
		const [modal, setModal] = useState('');

		const activeGroup = useCurrentGroup().id;

		const session = useSelector((state) =>
			selectors.sessions.find(state, {
				sessionId,
			})
		);

		const handleDeleteSession = async () => {
			if (
				!confirm(
					t('Are you sure you want to delete {title}', { title: session.title })
				)
			) {
				return;
			}

			await dispatch(deleteSession(org.id, sessionId));

			if (!ignoreRedirect) {
				replaceState(routes.TrainingLibrary.Index(org.id, activeGroup));
			}

			if (typeof afterDelete === 'function') {
				afterDelete();
			}
		};

		const handleOpenCollectionModal = () => setActiveCollectionModal(true);

		const handleCloseCollectionModal = () => {
			setActiveCollectionModal(false);
		};

		const handleOpenExerciseModal = (exerciseId) =>
			setExerciseModal({ id: exerciseId, active: true });

		const handleCloseExerciseModal = () =>
			setExerciseModal({ id: 0, active: false });

		const handleShowDuplicateModal = () => setModal('duplicate');
		const hideModal = () => setModal('');

		const canEdit = session.hasIn(['links', 'edit']);
		const canDelete = session.hasIn(['links', 'edit']);

		let showDuplicateAction = false;

		if (inEventView && hasEventEditRights) {
			showDuplicateAction = true;
		} else if (!inEventView) {
			showDuplicateAction = true;
		}

		const pageActions = [
			canEdit && {
				label: t('Edit'),
				icon: 'edit',
				href: routes.TrainingSession.Edit(org.id, session.id),
			},
			showDuplicateAction && {
				label: inEventView ? t('Add to library') : t('Duplicate'),
				icon: inEventView ? 'add' : 'file_copy',
				onClick: handleShowDuplicateModal,
			},
			allowCollections === true && {
				label: t('Add to'),
				icon: 'add',
				onClick: handleOpenCollectionModal,
			},
			!isApp() && {
				label: t('Print'),
				icon: 'print',
				href: routes.TrainingSession.Print(org.id, sessionId),
			},
			canDelete && {
				label: t('Delete'),
				icon: 'delete',
				onClick: handleDeleteSession,
			},
		].filter(Boolean);

		usePageActions(pageActions, 'more_horiz');

		const sessionDuration = useSelector((state) =>
			selectors.sessionBlocks.combinedDuration(state, {
				sessionId,
			})
		);

		const sessionBlocks = useSelector((state) =>
			selectors.sessionBlocks.findAll(state, {
				sessionId,
			})
		);

		const eventUsers = useSelector((state) =>
			selectors.eventUsers.findAllUsers(state, {
				eventId: session.eventId,
			})
		);

		const tags = useSelector((state) =>
			selectors.tags.findAllByIds(state, session.tags)
		);

		useEffect(() => {
			dispatch(fetchSession(org.id, sessionId));
		}, [dispatch, sessionId]);

		if (!session) {
			return null;
		}

		const blocks = () =>
			sessionBlocks?.map((sessionBlock) => (
				<SessionBlockEntity
					key={sessionBlock.id}
					session={session}
					eventUsers={eventUsers}
					sessionBlock={sessionBlock}
					onExerciseClick={handleOpenExerciseModal}
				/>
			));

		const actionsContent = () => (
			<LargeScreen>
				<Actions>
					{canEdit && (
						<Fragment>
							<Button
								icon="edit"
								href={routes.TrainingSession.Edit(org.id, session.id)}>
								{t('Edit')}
							</Button>
							{showDuplicateAction && (
								<Button
									icon={inEventView ? 'add' : 'file_copy'}
									onClick={handleShowDuplicateModal}>
									{inEventView ? t('Add to library') : t('Duplicate')}
								</Button>
							)}
						</Fragment>
					)}
					{allowCollections === true && (
						<Button icon="add" onClick={handleOpenCollectionModal}>
							{t('Add to')}
						</Button>
					)}
					{!isApp() && (
						<Button
							icon="print"
							href={routes.TrainingSession.Print(org.id, sessionId)}>
							{t('Print')}
						</Button>
					)}
					{canDelete && (
						<Button icon="delete" onClick={handleDeleteSession}>
							{t('Delete')}
						</Button>
					)}
				</Actions>
			</LargeScreen>
		);

		return (
			<Wrapper>
				{actionsContent()}
				{activeCollectionModal && (
					<CollectionAddItem
						onClose={handleCloseCollectionModal}
						onSave={handleCloseCollectionModal}
						type="sessions"
						id={session.id}
					/>
				)}
				<SessionBlock
					item={session}
					tags={tags}
					duration={sessionDuration}
					activeGroup={activeGroup}
				/>
				{blocks()}
				{exerciseModal.active && (
					<ViewExerciseModal
						exerciseId={exerciseModal.id}
						close={handleCloseExerciseModal}
					/>
				)}
				{modal === 'duplicate' && (
					<DuplicateSessionModal session={session} onClose={hideModal} />
				)}
			</Wrapper>
		);
	}
);

export default View;
