import Session from 'pkg/models/session';

import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

export interface BasicSessionsQueryParams {
	tags?: string[];
	page?: number;
	title?: string;
	limit?: number;
	sports?: number[];
}

export interface SessionsQueryParams extends BasicSessionsQueryParams {
	account_id?: number;
	user_id?: number;
	group_id?: number;
	no_collections?: number;
	curated?: number;
}

export interface UpdateSession {
	description?: string;
	eventId?: number;
	playerVisible?: boolean;
	tags?: string[];
	title?: string;
	userId?: number;
}

export const fetchSession = (
	organizationId: number,
	sessionId: number
): Promise<Response> =>
	sdk.get(endpoints.TrainingSessions.Show(organizationId, sessionId));

export const createSession = (
	organizationId: number,
	payload: Session
): Promise<Response> =>
	sdk.post(endpoints.TrainingSessions.Create(organizationId), {}, payload);

export const updateSession = (
	organizationId: number,
	sessionId: number,
	payload: UpdateSession
): Promise<Response> =>
	sdk.patch(
		endpoints.TrainingSessions.Update(organizationId, sessionId),
		{},
		payload
	);

export const deleteSession = (
	organizationId: number,
	sessionId: number
): Promise<Response> =>
	sdk.destroy(endpoints.TrainingSessions.Delete(organizationId, sessionId));

interface SessionBlockPayload {
	duration: number;
	id: string;
	order: number;
	trainingSessionId: string;
}

export const createSessionBlock = (
	organizationId: number,
	sessionId: number,
	payload: SessionBlockPayload
): Promise<Response> =>
	sdk.post(
		endpoints.TrainingSessions.AddBlock(organizationId, sessionId),
		{},
		payload
	);

interface UpdateSessionBlock {
	title?: string;
	description?: string;
	trainingSessionId: string;
}

export const updateSessionBlock = (
	organizationId: number,
	sessionBlockId: number,
	payload: UpdateSessionBlock
): Promise<Response> =>
	sdk.patch(
		endpoints.TrainingSessions.UpdateBlock(organizationId, sessionBlockId),
		{},
		payload
	);

export const deleteSessionBlock = (
	organizationId: number,
	sessionBlockId: number
): Promise<Response> =>
	sdk.destroy(
		endpoints.TrainingSessions.RemoveBlock(organizationId, sessionBlockId)
	);

interface CreateSessionItem {
	exerciseId: number;
	id: string;
	order: number;
	trainingSessionBlockId: string;
}

export const createSessionItem = (
	organizationId: number,
	sessionId: number,
	payload: CreateSessionItem
): Promise<Response> =>
	sdk.post(
		endpoints.TrainingSessions.AddBlockItem(organizationId, sessionId),
		{},
		payload
	);

interface UpdateSessionItem {
	exerciseId: number;
	defaultLanguage: string;
	description: string;
	id: number;
	order: number;
	trainingSessionBlockId: number;
	users: number[];
}

export const updateSessionItem = (
	organizationId: number,
	sessionItemId: number,
	payload: UpdateSessionItem
): Promise<Response> =>
	sdk.patch(
		endpoints.TrainingSessions.UpdateItem(organizationId, sessionItemId),
		{},
		payload
	);

export const deleteSessionItem = (
	organizationId: number,
	sessionItemId: number
): Promise<Response> =>
	sdk.destroy(
		endpoints.TrainingSessions.RemoveBlockItem(organizationId, sessionItemId)
	);

export const deleteSessionTag = (
	organizationId: number,
	sessionId: number,
	tagId: number
): Promise<Response> =>
	sdk.destroy(
		endpoints.TrainingSessions.RemoveTag(organizationId, sessionId, tagId)
	);

interface SessionItemUsers {
	users: number[];
}

export const setSessionItemUsers = (
	sessionItemId: number,
	payload: SessionItemUsers
): Promise<Response> =>
	sdk.put(endpoints.TrainingSessions.SetItemUsers1(sessionItemId), {}, payload);
