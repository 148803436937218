export function Cancel(): string {
	return `/v1/subscriptions/cancel`;
}

export function CreateBatch(): string {
	return `/v1/subscriptions/batch`;
}

export function ExportSubscriptions(): string {
	return `/v1/subscriptions/export`;
}

export function Index(): string {
	return `/v1/subscriptions`;
}

export function ListOrders(): string {
	return `/v1/accounts/self/subscriptions`;
}

export function Reactivate(ID: number): string {
	return `/v1/subscriptions/${ID}/reactivate`;
}

export function Send(subscriptionID: number): string {
	return `/v1/subscriptions/${subscriptionID}/send`;
}

export function Show(ID: number): string {
	return `/v1/subscriptions/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/subscriptions/${ID}`;
}
