import { t } from '@transifex/native';
import { Browser } from '@capacitor/browser';

import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useAuthenticator } from 'pkg/forms_service';
import { useCurrentAccount } from 'pkg/identity';
import { isApp } from 'pkg/platform';
import { pushState } from 'pkg/router/state';

import { Spinner } from 'components/loaders/spinner';

import * as css from './styles.css';
export default function LoginRedirect(): JSX.Element {
	const qs = useQueryState();
	const zIndex = useNewTopIndex();
	const authenticate = useAuthenticator();
	const account = useCurrentAccount();

	useComponentDidMount(async () => {
		if (qs.is('return', 'forms') && qs.has('guid')) {
			const returnUrl = await authenticate(account.id, {
				guid: qs.get('guid'),
			});

			if (isApp()) {
				Browser.open({ url: returnUrl });
				pushState('/');
			} else {
				window.location.assign(returnUrl);
			}
		} else {
			window.history.pushState({}, '', '/');
			window.location.reload();
		}
	});

	return (
		<div className={css.wrapper} style={{ zIndex }}>
			<Spinner />
			<span>{t('Redirecting back to forms...')}</span>
		</div>
	);
}
