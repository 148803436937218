// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uQCmsgDzfm4K2ahw4qht {
	width: 100%;
}

.Uqxv97vSFDxUGOPSvhjt {
	margin-top: var(--spacing-4);
}

.fN_7MzXuk7kkoCiFnnig {
	display: grid;
	grid-template-columns: auto 1fr;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	grid-gap: var(--spacing-4);
	gap: var(--spacing-4);
	color: var(--palette-black);
}

.lvHyPP6xlh5q8ZObxKaP {
	background-color: var(--palette-gray-200);
	border: 1px solid var(--palette-gray-400);
	padding: 0 var(--spacing-4);
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-base);
	border-radius: var(--radius-3);
	height: 40px;
	line-height: 38px;
	color: var(--palette-gray-700);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./routes/group/settings/single-sign-on/attribute-mapping/styles.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,4BAA4B;AAC7B;;AAEA;CACC,aAAa;CACb,+BAA+B;CAC/B,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,0BAAqB;CAArB,qBAAqB;CACrB,2BAA2B;AAC5B;;AAEA;CACC,yCAAyC;CACzC,yCAAyC;CACzC,2BAA2B;CAC3B,sCAAsC;CACtC,gCAAgC;CAChC,8BAA8B;CAC9B,YAAY;CACZ,iBAAiB;CACjB,8BAA8B;CAC9B,uBAAuB;CACvB,mBAAmB;CACnB,gBAAgB;AACjB","sourcesContent":[".attributeRow {\n\twidth: 100%;\n}\n\n.availableFields {\n\tmargin-top: var(--spacing-4);\n}\n\n.availableField {\n\tdisplay: grid;\n\tgrid-template-columns: auto 1fr;\n\talign-items: center;\n\tgap: var(--spacing-4);\n\tcolor: var(--palette-black);\n}\n\n.readonlyField {\n\tbackground-color: var(--palette-gray-200);\n\tborder: 1px solid var(--palette-gray-400);\n\tpadding: 0 var(--spacing-4);\n\tfont-weight: var(--font-weight-normal);\n\tfont-size: var(--font-size-base);\n\tborder-radius: var(--radius-3);\n\theight: 40px;\n\tline-height: 38px;\n\tcolor: var(--palette-gray-700);\n\ttext-overflow: ellipsis;\n\twhite-space: nowrap;\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export var attributeRow = `uQCmsgDzfm4K2ahw4qht`;
export var availableFields = `Uqxv97vSFDxUGOPSvhjt`;
export var availableField = `fN_7MzXuk7kkoCiFnnig`;
export var readonlyField = `lvHyPP6xlh5q8ZObxKaP`;
export default ___CSS_LOADER_EXPORT___;
