import { t } from '@transifex/native';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { palette } from 'pkg/config/styles';

import { Features } from 'pkg/models/group';

import * as endpoints from 'pkg/api/endpoints/auto';
import store from 'pkg/store/createStore';
import * as actions from 'pkg/actions';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';

import SideBar from 'routes/organization/contacts/single/sidebar';
import Overview from 'routes/organization/contacts/single/Overview';
import FormSubmissions from 'routes/organization/contacts/single/Submissions';
import ProductList from 'routes/organization/contacts/single/list/Product';
import OrderList from 'routes/payments/orders/list';
import SubscriptionTable from 'routes/payments/subscriptions/list';
import { FormStateData } from 'routes/payments/hooks/useContactForm';
import { generatePayload } from 'routes/payments/contacts/Create';
import UserProfileCalendar from 'routes/user/profile/calendar';

import SelectContact from 'containers/payment_platform/SelectContact';
import AssignProductModal from 'containers/payment_platform/contacts/AssignProductModal';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import CreateModal from 'components/payment_platform/contacts/CreateModal';
import * as SideBarLayout from 'components/layout/SideBarPage';
import InfoBox from 'components/form/info-box';
import { FormPayload } from 'components/form/Form';
import { Spinner } from 'components/loaders/spinner';

const ActivateContact = styled.span`
	text-decoration: underline;
	cursor: pointer;
`;

interface SingleContactProps {
	organizationId: number;
	userId: number;
	currentTab: string;
	setPageTitle: (title: string) => void;
	setPageSubNavItems?: (items: any[]) => void;
	setNoScroll: Dispatch<SetStateAction<boolean>>;
}

const SingleContact: React.FC<React.PropsWithChildren<SingleContactProps>> = ({
	organizationId,
	userId,
	currentTab,
	setPageTitle,
	setPageSubNavItems,
	setNoScroll,
}) => {
	const [modal, setModal] = useState<{ name: string; open: boolean }>({
		name: '',
		open: false,
	});

	const org = useCurrentOrganization();
	const group = useCurrentGroup();

	const { record: user, isLoading } = useEndpoint<models.user.User>(
		endpoints.Users.Show(userId),
		{
			queryParams: new URLSearchParams({
				include_user_products: 'true',
				include_billing_contact: 'true',
			}),
		}
	);

	const subNavItems = [
		{
			title: t(`Overview`),
			href: routes.Management.Contact.Show(org.id, userId, 'overview'),
		},
		{
			title: t('Calendar'),
			href: routes.Management.Contact.Show(org.id, userId, 'calendar'),
		},
		{
			title: t(`Products`),
			href: routes.Management.Contact.Show(org.id, userId, 'products'),
		},
		{
			title: t(`Invoices`),
			href: routes.Management.Contact.Show(org.id, userId, 'invoices'),
		},
		{
			title: t(`Subscriptions`),
			href: routes.Management.Contact.Show(org.id, userId, 'subscriptions'),
		},
	];

	if (models.group.hasFeature(group, Features.Forms)) {
		subNavItems.push({
			title: t(`Forms`),
			href: routes.Management.Contact.Show(org.id, userId, 'forms'),
		});
	}

	useEffect(() => {
		setPageSubNavItems(subNavItems);
	}, [organizationId]);

	useEffect(() => {
		if (user && user.firstName && user.lastName) {
			setPageTitle(models.user.fullName(user));
		}
	}, [user]);

	useEffect(() => {
		if (user.billingUserId) {
			actions.users.fetchUser(user.billingUserId)(store.dispatch);
		}
	}, [user.billingUserId]);

	useEffect(() => {
		actions.users.getUserMemberships(organizationId, userId)(store.dispatch);
	}, [organizationId, userId]);

	useEffect(() => {
		if (currentTab === 'calendar') {
			setNoScroll(true);
		} else {
			setNoScroll(false);
		}
	}, [currentTab]);

	const handleOpenModal = (name: string) => setModal({ name, open: true });

	const handleCloseModal = (): void => {
		setModal({ name: '', open: false });
	};

	const handleSelectBilling = (billingUserId: number): void => {
		actions.users.update(userId, { billingUserId })(store.dispatch);
	};

	const handleOpenCreateBilling = () => handleOpenModal('newContact');

	const handleCreateUser = async (
		data: FormPayload,
		contactData: FormStateData
	): Promise<void> => {
		const [, result] = await actions.users.createUser(
			generatePayload(data, contactData, organizationId)
		)(store.dispatch);
		actions.users.update(userId, { billingUserId: result.id })(store.dispatch);
	};

	const handleRestoreUser = () => {
		if (window.confirm(t(`Are you sure you want to restore this contact?`))) {
			actions.users.restoreUser(user.id);
		}
	};

	let currentView;
	switch (currentTab) {
		case 'products':
			currentView = (
				<SideBarLayout.Content key={currentTab}>
					<ProductList contact={user} handleOpenModal={handleOpenModal} />
				</SideBarLayout.Content>
			);
			break;
		case 'invoices':
			currentView = (
				<SideBarLayout.Table key={currentTab}>
					<OrderList
						organizationId={organizationId}
						userId={userId}
						disableNewInvoice={!!user.deletedAt}
					/>
				</SideBarLayout.Table>
			);
			break;
		case 'subscriptions':
			currentView = (
				<SideBarLayout.Table key={currentTab}>
					<SubscriptionTable
						organizationId={organizationId}
						userId={userId}
						disableNewSubscription={!!user.deletedAt}
					/>
				</SideBarLayout.Table>
			);
			break;
		case 'forms':
			currentView = (
				<SideBarLayout.Content key={currentTab}>
					<FormSubmissions groupId={organizationId} contactId={user.id} />
				</SideBarLayout.Content>
			);
			break;
		case 'calendar':
			currentView = (
				<SideBarLayout.Content
					key={currentTab}
					backgroundColor={palette.white}
					hideOverflow>
					<UserProfileCalendar userId={user.id} />
				</SideBarLayout.Content>
			);
			break;
		default:
			currentView = (
				<SideBarLayout.Content key={currentTab}>
					<Overview
						group={group}
						user={user}
						handleOpenModal={handleOpenModal}
					/>
				</SideBarLayout.Content>
			);
			break;
	}

	if (isLoading) {
		return <Spinner />;
	}

	if (!user.id) {
		return <Spinner />;
	}

	return (
		<Fragment>
			{!!user.deletedAt && (
				<InfoBox noRounding color="orange">
					<span>
						{t(`This contact is archived, to be able to edit -`)}{' '}
						<ActivateContact onClick={handleRestoreUser}>
							{t(`re-activate contact`)}
						</ActivateContact>
					</span>
				</InfoBox>
			)}
			<SideBarLayout.Layout>
				<LargeScreen>
					<SideBar
						contact={user}
						groupId={organizationId}
						handleOpenModal={handleOpenModal}
						handleRestoreUser={handleRestoreUser}
					/>
				</LargeScreen>
				<SmallScreen>
					{currentTab === 'overview' && (
						<SideBar
							contact={user}
							groupId={organizationId}
							handleOpenModal={handleOpenModal}
							handleRestoreUser={handleRestoreUser}
						/>
					)}
				</SmallScreen>

				{currentView}
			</SideBarLayout.Layout>
			{modal.open && modal.name === 'product' && (
				<AssignProductModal
					organizationId={organizationId}
					userIds={[userId]}
					onClose={handleCloseModal}
				/>
			)}
			{modal.open && modal.name === 'selectContact' && (
				<SelectContact
					groupId={organizationId}
					canCreateNew
					onConfirm={handleSelectBilling}
					onCreateNew={handleOpenCreateBilling}
					onClose={handleCloseModal}
				/>
			)}
			{modal.open && modal.name === 'newContact' && (
				<CreateModal
					groupId={organizationId}
					onConfirm={handleCreateUser}
					onClose={handleCloseModal}
				/>
			)}
		</Fragment>
	);
};

export default SingleContact;
