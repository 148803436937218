// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._4XDO7y9cEvT1jzIQ80Vw {
	margin-bottom: var(--spacing-3);
	cursor: -webkit-grab;
	cursor: grab;
}

._4XDO7y9cEvT1jzIQ80Vw.DbgG737lHTZqQB9mIKy1 {
	opacity: 0.4;
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

._4XDO7y9cEvT1jzIQ80Vw.iosl1MXxU9bEr01lLnQg:first-child {
	scale: 1.05;
	-webkit-box-shadow: var(--box-shadow-card-hover);
	        box-shadow: var(--box-shadow-card-hover);
}

.SbkG4LPJVYarNEsQANU9 {
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
}
`, "",{"version":3,"sources":["webpack://./routes/video/playlist/style.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,oBAAY;CAAZ,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,wBAAgB;CAAhB,gBAAgB;AACjB;;AAEA;CACC,WAAW;CACX,gDAAwC;SAAxC,wCAAwC;AACzC;;AAEA;CACC,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;AACpB","sourcesContent":[".item {\n\tmargin-bottom: var(--spacing-3);\n\tcursor: grab;\n}\n\n.item.active {\n\topacity: 0.4;\n\tcursor: grabbing;\n}\n\n.item.overlay:first-child {\n\tscale: 1.05;\n\tbox-shadow: var(--box-shadow-card-hover);\n}\n\n.handle {\n\tdisplay: grid;\n\tplace-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var item = `_4XDO7y9cEvT1jzIQ80Vw`;
export var active = `DbgG737lHTZqQB9mIKy1`;
export var overlay = `iosl1MXxU9bEr01lLnQg`;
export var handle = `SbkG4LPJVYarNEsQANU9`;
export default ___CSS_LOADER_EXPORT___;
