// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kiLGhvFjNuaC0Ll5vVGN {
	font-weight: var(--font-weight-semibold);
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/description/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAAwC;AACzC","sourcesContent":[".seriesLink {\n\tfont-weight: var(--font-weight-semibold);\n}\n"],"sourceRoot":""}]);
// Exports
export var seriesLink = `kiLGhvFjNuaC0Ll5vVGN`;
export default ___CSS_LOADER_EXPORT___;
