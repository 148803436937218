import { t } from '@transifex/native';
import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Map } from 'immutable';

import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import MatchEvent from 'pkg/models/match_event';

import * as actions from 'pkg/actions/index';

import uuid from 'pkg/uuid';
import { replaceState } from 'pkg/router/state';
import * as selectors from 'pkg/selectors';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import EditEvents from 'containers/match/edit/event/Events';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import EditResult from 'components/match/edit/Result';
import * as ActionBar from 'components/layout/ActionBar';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

import Button from 'design/button';

const EditWrapper = styled.div`
	display: grid;
	grid-row-gap: var(--spacing-5);

	@media ${styles.breakpoint.small} {
		margin-bottom: 70px;
	}
`;

interface MatchEditProps {
	matchId: number;
}

const MatchEdit: React.FC<React.PropsWithChildren<MatchEditProps>> = ({
	matchId,
}) => {
	const dispatch = useDispatch();
	const org = useCurrentOrganization();

	const [canSave, setCanSave] = useState(false);
	const [showSubstitionError, setShowSubstitionError] = useState(false);

	const match = useSelector((state) =>
		selectors.matches.getMatchById(state, { matchId })
	);
	const matchOpponent = useSelector((state) =>
		selectors.matchOpponents.find(state, match.matchOpponentId)
	);
	const modes = useSelector((state) =>
		selectors.matchEvents.getMatchEventModes(state)
	);
	const matchEvents = useSelector((state) =>
		selectors.matchEvents.getAllEventsForMatch(state, matchId)
	);

	useEffect(() => {
		window.addEventListener('beforeunload', handleLeavePage);

		return () => {
			actions.deprecatedMatchEvents.resetMatchEvents(matchId)(dispatch);
			window.removeEventListener('beforeunload', handleLeavePage);
		};
	}, [dispatch, matchId]);

	useEffect(() => {
		const dateNow = Math.round(new Date().getTime() / 1000);

		if (match.startsAt > dateNow) {
			return;
		}

		let valueChanged = false;

		if (match.goalCount === null) {
			valueChanged = true;
			actions.deprecatedMatchActions.updateMatchProperty(
				matchId,
				'goalCount',
				0
			)(dispatch);
		}

		if (match.goalCountHalfTime === null) {
			valueChanged = true;
			actions.deprecatedMatchActions.updateMatchProperty(
				matchId,
				'goalCountHalfTime',
				0
			)(dispatch);
		}

		if (match.opponentGoalCount === null) {
			valueChanged = true;
			actions.deprecatedMatchActions.updateMatchProperty(
				matchId,
				'opponentGoalCount',
				0
			)(dispatch);
		}

		if (match.opponentGoalCountHalfTime === null) {
			valueChanged = true;
			actions.deprecatedMatchActions.updateMatchProperty(
				matchId,
				'opponentGoalCountHalfTime',
				0
			)(dispatch);
		}

		if (valueChanged) {
			setCanSave(true);
		}
	}, [
		matchId,
		dispatch,
		match.startsAt,
		match.goalCount,
		match.goalCountHalfTime,
		match.opponentGoalCount,
		match.opponentGoalCountHalfTime,
	]);

	const handleLeavePage = (e: BeforeUnloadEvent) => e.returnValue;

	const unhandledEvents = modes.filter((mode: Map<string, boolean>) =>
		mode.get('newEntity') || !mode.get('persisted') || mode.get('handled')
			? true
			: false
	);

	useEffect(() => {
		unhandledEvents.size === 0 ? setCanSave(false) : setCanSave(true);
	}, [unhandledEvents.size]);

	const parseEvents = (
		events: { newEvents: MatchEvent[]; persistedEvents: MatchEvent[] },
		event: MatchEvent
	) => {
		const newEntity = modes.getIn([event.id, 'newEntity']);
		const handled = modes.getIn([event.id, 'handled']);

		if (newEntity) {
			events.newEvents.push(event);
		} else if (!newEntity && handled) {
			events.persistedEvents.push(event);
		}

		return events;
	};

	const createEvents = async (events: MatchEvent[]) => {
		if (events.length === 0) return [];

		const result: any = await actions.deprecatedMatchEvents.createMatchEvents(
			matchId,
			{
				events,
			}
		)(dispatch);

		actions.deprecatedMatchEvents.removeGuids(events)(dispatch);

		return result.events;
	};

	const removeGoalEvents = (amount: number, isOpponent: boolean) => {
		const deletableGoalEvents = matchEvents
			.filter((event: MatchEvent) => {
				if (
					event.type === 'goal' &&
					event.isOpponent === isOpponent &&
					!event.minute &&
					!event.userId &&
					!event.leadingMatchEventId
				) {
					return true;
				}

				return false;
			})
			.take(amount);

		if (deletableGoalEvents.size === 0) {
			return 0;
		}

		deletableGoalEvents.forEach((event: MatchEvent) =>
			actions.deprecatedMatchEvents.removeMatchEvent(event.id)(dispatch)
		);

		return deletableGoalEvents.size;
	};

	const updateGoalCountHalfTime = (value: number, isOpponent: boolean) => {
		const name = isOpponent ? 'opponentGoalCountHalfTime' : 'goalCountHalfTime';

		actions.deprecatedMatchActions.updateMatchProperty(
			matchId,
			name,
			value
		)(dispatch);
	};

	const updateEvents = (events: MatchEvent[], createdEvents: MatchEvent[]) => {
		const eventWithLeading = events.map((event: MatchEvent) => {
			const createdLeadingEvent = createdEvents.find(
				(n) => n.guid === event.leadingGuid
			);

			const leadingEvent = event.leadingGuid ? createdLeadingEvent : null;

			if (leadingEvent) {
				event = event.set('leadingMatchEventId', leadingEvent.id);
			}

			return event;
		});

		actions.deprecatedMatchEvents.updateMatchEvents(matchId, {
			events: eventWithLeading,
		})(dispatch);
	};

	const handleCreateAndUpdateEvents = async () => {
		const { newEvents, persistedEvents } = matchEvents.reduce(parseEvents, {
			newEvents: [],
			persistedEvents: [],
		});

		const createdEvents = await createEvents(newEvents);

		persistedEvents.length && updateEvents(persistedEvents, createdEvents);
	};

	const handleSave = async () => {
		const substitutionEventsWithoutMinute = matchEvents.filter(
			(e: MatchEvent) => e.type === 'substitution_in' && !e.minute
		);

		if (substitutionEventsWithoutMinute.size) {
			setShowSubstitionError(true);

			actions.flashes.show(
				{
					title: t('Could not save match events'),
					message: t(
						'You cannot add a substitution at 0 minutes. Please set a time for when the substitution occurred in order to continue.'
					),
				},
				400,
				'error'
			);

			return;
		} else {
			setShowSubstitionError(false);
		}

		handleCreateAndUpdateEvents();
		actions.deprecatedMatchActions.updateMatch(matchId)(dispatch);

		setCanSave(false);
	};

	const handleCancelEdit = async () => {
		if (
			!window.confirm(
				t(`Are you sure you want to leave?
 You have unsaved events`)
			)
		)
			return;

		replaceState(routes.Match.View(org.id, match.eventId, match.id, 'summary'));
	};

	const generateEvents = (numberOfEvents: number, isOpponent: boolean) => {
		const guids = [];
		for (let i = 0; i < numberOfEvents; i++) {
			const guid = uuid();

			guids.push(
				new MatchEvent({
					id: guid,
					placeholder: true,
					guid,
					type: 'goal',
					matchId,
					isOpponent,
				})
			);
		}

		dispatch(actions.deprecatedMatchEvents.addMatchEventGuids(matchId, guids));
	};

	const updateProperty = (
		name: string,
		value: number,
		forceScore: boolean,
		isAutoSave: boolean
	) => {
		const events = matchEvents.filter((e: MatchEvent) => {
			if (name === 'opponentGoalCount') {
				return e.type === 'goal' && e.isOpponent === true;
			}

			return e.type === 'goal' && e.isOpponent === false;
		});

		if (value < events.size && forceScore) {
			value = events.size;
		}

		actions.deprecatedMatchActions.updateMatchProperty(
			matchId,
			name,
			value
		)(dispatch);

		if (!isAutoSave) {
			setCanSave(true);
		}
	};

	const content = (
		<EditWrapper>
			<EditResult
				updateMatchProperty={updateProperty}
				match={match}
				matchOpponent={matchOpponent}
				createMatchEvents={generateEvents}
				removeEvents={removeGoalEvents}
				updateGoalCountHalfTime={updateGoalCountHalfTime}
			/>

			<EditEvents matchId={matchId} handleSave={handleSave} />
		</EditWrapper>
	);

	const substitutionErrorMessage = (
		<InfoBox color="red">
			{t(
				'You cannot add a substitution at 0 minutes. Please set a time for when the substitution occurred in order to continue.'
			)}
		</InfoBox>
	);

	return (
		<Fragment>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				<LargeScreen>
					<Button onClick={handleCancelEdit}>{t('Cancel')}</Button>
					<Button
						primary
						disabled={!canSave}
						onClick={canSave ? handleSave : undefined}
						testid="match_edit.save_button">
						{t('Save')}
					</Button>
				</LargeScreen>
				<SmallScreen>
					<Button large block onClick={handleCancelEdit}>
						{t('Cancel')}
					</Button>
					<Button
						large
						primary
						block
						disabled={!canSave}
						onClick={canSave ? handleSave : undefined}
						testid="match_edit.save_button">
						{t('Save')}
					</Button>
				</SmallScreen>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					<Column>
						{showSubstitionError && substitutionErrorMessage}
						{content}
					</Column>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>
					<Column>
						{showSubstitionError && substitutionErrorMessage}
						{content}
					</Column>
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
};

export default MatchEdit;
