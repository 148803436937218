// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q_jzZEoV3HQ3CCzfmJdk {
	display: block;

	--scale: 1;
	--base: 1em;

	width: var(--base);
	height: var(--base);
}
.Q_jzZEoV3HQ3CCzfmJdk svg {
		scale: var(--scale);
		width: 100%;
		height: 100%;
		fill: currentcolor;
	}
.Q_jzZEoV3HQ3CCzfmJdk.rlNjiGyuvmVsMfQ2dGp9 {
		width: calc(var(--base) * var(--scale));
		height: calc(var(--base) * var(--scale));
	}
.Q_jzZEoV3HQ3CCzfmJdk.rlNjiGyuvmVsMfQ2dGp9 svg {
			scale: 1;
		}
.Q_jzZEoV3HQ3CCzfmJdk.M2BMS3vamKI19xladGdl {
		cursor: pointer;
	}
`, "",{"version":3,"sources":["webpack://./components/material-symbols/styles.css"],"names":[],"mappings":"AAAA;CACC,cAAc;;CAEd,UAAU;CACV,WAAW;;CAEX,kBAAkB;CAClB,mBAAmB;AAqBpB;AAnBC;EACC,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;CACnB;AAEA;EACC,uCAAuC;EACvC,wCAAwC;CAKzC;AAHC;GACC,QAAQ;EACT;AAGD;EACC,eAAe;CAChB","sourcesContent":[".materialSymbol {\n\tdisplay: block;\n\n\t--scale: 1;\n\t--base: 1em;\n\n\twidth: var(--base);\n\theight: var(--base);\n\n\tsvg {\n\t\tscale: var(--scale);\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tfill: currentcolor;\n\t}\n\n\t&.actualSize {\n\t\twidth: calc(var(--base) * var(--scale));\n\t\theight: calc(var(--base) * var(--scale));\n\n\t\t& svg {\n\t\t\tscale: 1;\n\t\t}\n\t}\n\n\t&.hasClick {\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var materialSymbol = `Q_jzZEoV3HQ3CCzfmJdk`;
export var actualSize = `rlNjiGyuvmVsMfQ2dGp9`;
export var hasClick = `M2BMS3vamKI19xladGdl`;
export default ___CSS_LOADER_EXPORT___;
