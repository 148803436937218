import { Fragment } from 'react';

import * as models from 'pkg/api/models';

import CardWrapper, * as Card from 'components/Card';
import Modal from 'components/modal';

import * as UserList from 'components/user/List';

interface UsersModalProps {
	title: string;
	users: models.user.User[];
	onClose: () => void;
}

function UsersModal({ title, users, onClose }: UsersModalProps) {
	if (!users || users.length === 0) return null;

	return (
		<Modal thin onClose={onClose}>
			<CardWrapper>
				{title && (
					<Fragment>
						<Card.Header>
							<Card.Heading>{title}</Card.Heading>
						</Card.Header>
						<Card.Divider />
					</Fragment>
				)}
				<Card.Body $flex $noSpacing>
					<UserList.Base>
						{users.map((user) => (
							<UserList.Item key={user.id}>
								<UserList.Avatar user={user} />
								<UserList.Name>{models.user.fullName(user)}</UserList.Name>
							</UserList.Item>
						))}
					</UserList.Base>
				</Card.Body>
			</CardWrapper>
		</Modal>
	);
}

export default UsersModal;
