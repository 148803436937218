import { memo } from 'react';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import * as tagConfig from 'pkg/config/tags';

import Link from 'pkg/router/Link';
import rgba from 'pkg/rgba';

import Icon from 'components/icon';

export const Tags = styled.div`
	text-align: ${(props) => props.align || 'center'};
`;

export const TagColors = {
	tag: {
		idle: {
			bg: palette.green[500],
			fg: palette.white,
		},
		active: {
			bg: palette.green[700],
			fg: palette.white,
		},
	},
	session: {
		idle: {
			bg: palette.orange[500],
			fg: palette.white,
		},
		active: {
			bg: palette.orange[600],
			fg: palette.white,
		},
	},
	input: {
		idle: {
			bg: palette.blue[200],
			fg: palette.blue[500],
		},
		active: {
			bg: palette.blue[500],
			fg: palette.white,
		},
	},
	rest: {
		idle: {
			bg: rgba(palette.black, 0.7),
			fg: palette.white,
		},
		active: {
			bg: rgba(palette.black, 0.7),
			fg: palette.white,
		},
	},
};

const TagLink = styled(Link)`
	padding: 0.4rem 0.8rem 0.45rem;
	font-size: var(--font-size-base);
	border-radius: 100rem;
	display: inline-grid;
	grid-auto-flow: column;
	align-items: center;
	grid-gap: 0.3rem;
	cursor: default;

	&:last-child {
		margin: 0;
	}

	${({ type }) => css`
		background: ${TagColors[type].idle.bg};
		color: ${TagColors[type].idle.fg};

		@media (hover: hover) {
			&:hover {
				color: ${TagColors[type].idle.fg};
			}
		}
	`}

	${({ href, onClick, to, type }) =>
		(href || onClick || to) &&
		css`
			cursor: pointer;
			@media (hover: hover) {
				&:hover {
					background: ${TagColors[type].active.bg};
					color: ${TagColors[type].active.fg};
				}
			}
		`}

	${({ type, active }) =>
		active &&
		css`
			background: ${TagColors[type].active.bg};
			color: ${TagColors[type].active.fg};
		`}

	/* @NOTE Special case for hovering interactable tags */
	${({ type, removable, addable }) =>
		(removable || addable) &&
		css`
			@media (hover: hover) {
				&:hover {
					background: ${TagColors[type].active.bg};
					color: ${TagColors[type].active.fg};
				}
			}
		`}

	${({ type }) =>
		type === 'userGoal' &&
		css`
			border: 1px solid ${palette.blue[300]};
		`}


	${({ small }) =>
		small &&
		css`
			font-size: var(--font-size-xs);
			padding: 0.35rem 0.6rem 0.4rem;
		`}
`;

const TagLabel = styled.span`
	display: inline-block;
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

/**
 * @type {React.Element<any>}
 */

const Tag = memo((props) => {
	let { name, type, removable, addable } = props;

	type = type || 'tag';
	name = tagConfig.getName(name);

	const tagProps = { ...props };

	// temporary fix for tags that shouldn't be links
	if (!props.to && !props.href && !props.replace) {
		tagProps.as = 'div';
	}

	return (
		<TagLink {...tagProps} type={type} title={name}>
			{addable && !removable && <Icon name="add" />}
			<TagLabel>{name}</TagLabel>
			{!addable && removable && <Icon name="close" />}
		</TagLink>
	);
});

export default Tag;
