import styled from 'styled-components';
import { ChangeEvent, Fragment, ReactNode, useEffect, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import useMixedState from 'pkg/hooks/useMixedState';
import * as models from 'pkg/api/models';
import rgba from 'pkg/rgba';
import { useQueryState } from 'pkg/hooks/query-state';
import { eventTypeLabels, EventTypes } from 'pkg/api/models/event';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

import { getItemResourceIds } from 'routes/scheduling/utils';

import Icon from 'components/icon';

import GroupsTable from 'components/group/table';
import ResourcesTable from 'components/resources/table';
import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import { SelectableOptions } from 'components/resources/select_modal';
import SearchInput from 'components/form/SearchInput';
import LocationsTable from 'components/resources/locations/table';
import ResourceCategoriesTable from 'components/resources/categories/table';

import Button from 'design/button';
import * as Context from 'design/context_menu';

const Label = styled.div`
	font-weight: var(--font-weight-semibold);
`;

const ItemWrapper = styled.div`
	padding: var(--spacing-2) var(--spacing-5);
`;

const SelectText = styled.div`
	font-size: var(--font-size-sm);
	color: var(--palette-blue-500);
	font-weight: var(--font-weight-semibold);
	margin-bottom: var(--spacing-2);
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			color: var(--palette-blue-700);
		}
	}
`;

const TitleRow = styled(Context.Pane)<{ active?: boolean }>`
	cursor: pointer;
	color: ${({ active }) =>
		active
			? rgba(styles.palette.main.actionableLinkColor)
			: styles.palette.black};
`;

interface BookingItemResource {
	resourceId: number;
}
interface BookingItem {
	resourceId?: number;
	resources?: BookingItemResource[];
}

interface AmountOfBookingsProps {
	resource?: models.resource.Resource;

	items: BookingItem[];
}

const AmountOfBookings = ({ resource, items }: AmountOfBookingsProps) => {
	const bookings = items.filter(
		(i) =>
			i.resourceId === resource.id ||
			i.resources?.map((i) => i.resourceId).includes(resource.id)
	);

	return <span>{bookings.length || '-'}</span>;
};

interface GroupTableProps {
	group?: models.group.Group;
}

interface GroupTableBookingsProps extends GroupTableProps {
	bookings: models.booking.Booking[];
}

const GroupTableBookings = ({
	group,
	bookings = [],
}: GroupTableBookingsProps) => {
	const amountOfBookings = bookings.filter(
		(b) => b.forGroupId === group.id
	).length;

	return <span>{amountOfBookings || '-'}</span>;
};

interface GroupTableScheduleItemBookingsProps extends GroupTableProps {
	scheduleItems: models.scheduleItem.ScheduleItem[];
}

const GroupTableScheduleItemBookings = ({
	group,
	scheduleItems,
}: GroupTableScheduleItemBookingsProps) => {
	const amountOfBookings = scheduleItems.filter(
		(i) => i.groupId === group.id
	).length;

	return <span>{amountOfBookings || '-'}</span>;
};

interface InputGroupProps {
	label: string;
	children: ReactNode;
	clearFilter: () => void;
	skipPane?: boolean;
	active?: boolean;
}

const InputGroup = ({
	label,
	children,
	clearFilter,
	skipPane = false,
	active = false,
}: InputGroupProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<Fragment>
			<TitleRow onClick={toggleOpen} active={active}>
				<Row autoColumns="auto" align="center" justifyContent="space-between">
					<Label>{label}</Label>
					<Row align="center" autoColumns="auto">
						{active && (
							<Button onClick={clearFilter} label="Clear" secondary small />
						)}
						<Icon name={isOpen ? 'chevron-down' : 'chevron'} size={2} />
					</Row>
				</Row>
			</TitleRow>
			{isOpen && (
				<Fragment>
					{skipPane ? (
						children
					) : (
						<Context.Pane padding="0px var(--spacing-5) var(--spacing-4) var(--spacing-5)">
							{children}
						</Context.Pane>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

type FilterSourceTypes = 'resources' | 'groups' | 'locations' | 'categories';

export enum BookingTypes {
	Empty = '',
	Less = 'less',
	More = 'more',
	Equals = 'equals',
}

export type FilterState = {
	[key in FilterSourceTypes]: number[];
} & {
	conflicts: boolean;
	bookings: {
		type: BookingTypes;
		amount: string;
	};
	types: EventTypes[];
};

export const defaultFilters: FilterState = {
	conflicts: false,
	bookings: {
		type: BookingTypes.Empty,
		amount: '1',
	},
	groups: [],
	locations: [],
	resources: [],
	types: [],
	categories: [],
};

export const hasBeenFiltered = (queryFilters: FilterState) =>
	JSON.stringify(queryFilters) !== JSON.stringify(defaultFilters) &&
	!!queryFilters;

interface ScheduleFiltersProps {
	groupId: number;

	resources: models.resource.Resource[];
	groups: models.group.Group[];

	scheduleItems?: models.scheduleItem.ScheduleItem[];
	bookings?: models.booking.Booking[];
}

const ScheduleFilters = ({
	groupId,
	resources,
	groups,
	scheduleItems,
	bookings,
}: ScheduleFiltersProps) => {
	const { records: categories, refresh: refreshCategories } =
		useCollection<models.resourceCategory.ResourceCategory>(
			endpoints.ResourceCategory.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);
	const { records: locations } =
		useCollection<models.resourceLocation.ResourceLocation>(
			endpoints.ResourceLocation.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);
	const qs = useQueryState();

	const qsFilters = qs.get('schedulingFilters');
	const queryFilters =
		qsFilters !== 'undefined'
			? JSON.parse(qsFilters as string)
			: defaultFilters;

	const initState = (queryFilters as FilterState) || defaultFilters;
	const hasFiltered = hasBeenFiltered(queryFilters);
	const [filters, setFilters] = useMixedState<FilterState>(initState);
	const [search, setSearch] = useState('');

	const hasScheduleItems = scheduleItems?.length > 0;

	useEffect(() => {
		setFilters(queryFilters as FilterState);
	}, [queryFilters?.groups?.length]);

	const sourceData: {
		[key in FilterSourceTypes]: {
			withBookings: number[];
			withoutBookings: () => number[];
			withBookingsIsSelected: () => boolean;
			withoutBookingsIsSelected: () => boolean;
		};
	} = {
		groups: {
			withBookings: hasScheduleItems
				? [...new Set(scheduleItems.map((i) => i.groupId))].sort(
						(a, b) => a - b
					)
				: [...new Set(bookings?.map((b) => b.forGroupId))].sort(
						(a, b) => a - b
					),
			withoutBookings: function () {
				return groups
					.filter((g) => !this.withBookings.includes(g.id))
					.map((g) => g.id)
					.sort((a, b) => a - b);
			},
			withBookingsIsSelected: function () {
				return (
					JSON.stringify(
						filters.groups
							.filter((i) => !this.withoutBookings().includes(i))
							.sort((a, b) => a - b)
					) === JSON.stringify(this.withBookings)
				);
			},
			withoutBookingsIsSelected: function () {
				return (
					JSON.stringify(
						filters.groups
							.filter((i) => !this.withBookings.includes(i))
							.sort((a, b) => a - b)
					) === JSON.stringify(this.withoutBookings())
				);
			},
		},
		resources: {
			withBookings:
				scheduleItems?.length > 0
					? [
							...new Set(
								scheduleItems
									?.filter((s) => getItemResourceIds(s).length > 0)
									.map((s) => getItemResourceIds(s))
									.flat(1)
							),
						].sort((a, b) => a - b)
					: [
							...new Set(
								bookings
									?.filter((b) => getItemResourceIds(b).length > 0)
									.map((b) => getItemResourceIds(b))
									.flat(1)
							),
						].sort((a, b) => a - b),
			withoutBookings: function () {
				return resources
					.filter((r) => !this.withBookings.includes(r.id))
					.map((r) => r.id)
					.sort((a, b) => a - b);
			},
			withBookingsIsSelected: function () {
				return (
					this.withBookings.length > 0 &&
					JSON.stringify(
						filters.resources
							.filter((i) => !this.withoutBookings().includes(i))
							.sort((a, b) => a - b)
					) === JSON.stringify(this.withBookings)
				);
			},
			withoutBookingsIsSelected: function () {
				return (
					JSON.stringify(
						filters.resources
							.filter((i) => !this.withBookings.includes(i))
							.sort((a, b) => a - b)
					) === JSON.stringify(this.withoutBookings())
				);
			},
		},
		locations: {
			withBookings:
				scheduleItems?.length > 0
					? [
							...new Set(
								locations
									?.filter((l) =>
										scheduleItems.some(
											(s) =>
												s.resource?.resourceLocationId === l.id ||
												s.resources
													?.filter(
														(r) => r.resource?.resourceLocationId !== null
													)
													.map((r) => r.resource?.resourceLocationId)
													.includes(l.id)
										)
									)
									.map((l) => l.id)
							),
						].sort((a, b) => a - b)
					: [
							...new Set(
								locations
									?.filter((l) =>
										bookings?.some(
											(s) =>
												s.resource?.resourceLocationId === l.id ||
												s.resources
													?.filter(
														(r) => r.resource?.resourceLocationId !== null
													)
													.map((r) => r.resource?.resourceLocationId)
													.includes(l.id)
										)
									)
									.map((l) => l.id)
							),
						].sort((a, b) => a - b),
			withoutBookings: function () {
				return locations
					.filter((l) => !this.withBookings.includes(l.id))
					.map((l) => l.id)
					.sort((a, b) => a - b);
			},
			withBookingsIsSelected: function () {
				return (
					this.withBookings.length > 0 &&
					JSON.stringify(
						filters.locations
							.filter((i) => !this.withoutBookings().includes(i))
							.sort((a, b) => a - b)
					) === JSON.stringify(this.withBookings)
				);
			},
			withoutBookingsIsSelected: function () {
				return (
					JSON.stringify(
						filters.locations
							.filter((i) => !this.withBookings.includes(i))
							.sort((a, b) => a - b)
					) === JSON.stringify(this.withoutBookings())
				);
			},
		},
		categories: {
			withBookings:
				scheduleItems?.length > 0
					? [
							...new Set(
								categories
									?.filter((c) =>
										scheduleItems.some(
											(s) =>
												s.resource?.resourceCategoryId === c.id ||
												s.resources
													?.filter(
														(ir) => ir.resource?.resourceCategoryId !== null
													)
													.map((r) => r.resource?.resourceCategoryId)
													.includes(c.id)
										)
									)
									.map((c) => c.id)
							),
						].sort((a, b) => a - b)
					: [
							...new Set(
								categories
									?.filter((c) =>
										bookings?.some(
											(b) =>
												b.resource?.resourceCategoryId === c.id ||
												b.resources
													?.filter(
														(ir) => ir.resource?.resourceCategoryId !== null
													)
													.map((r) => r.resource?.resourceCategoryId)
													.includes(c.id)
										)
									)
									.map((c) => c.id)
							),
						].sort((a, b) => a - b),
			withoutBookings: function () {
				const c = categories
					.filter((c) => !this.withBookings.includes(c.id))
					.map((c) => c.id)
					.sort((a, b) => a - b);
				return c;
			},
			withBookingsIsSelected: function () {
				return (
					this.withBookings.length > 0 &&
					JSON.stringify(
						filters.categories
							.filter((i) => !this.withoutBookings().includes(i))
							.sort((a, b) => a - b)
					) === JSON.stringify(this.withBookings)
				);
			},
			withoutBookingsIsSelected: function () {
				return (
					JSON.stringify(
						filters.categories
							.filter((i) => !this.withBookings.includes(i))
							.sort((a, b) => a - b)
					) === JSON.stringify(this.withoutBookings())
				);
			},
		},
	};

	const groupsWithoutBookings = sourceData.groups.withoutBookings();
	const resourcesWithoutBookings = sourceData.resources.withoutBookings();
	const groupsWithBookingsIsSelected =
		sourceData.groups.withBookingsIsSelected();
	const resourcesWithBookingsIsSelected =
		sourceData.resources.withBookingsIsSelected();
	const locationsWithBookingsIsSelected =
		sourceData.locations.withBookingsIsSelected();
	const categoriesWithBookingsIsSelected =
		sourceData.categories.withBookingsIsSelected();
	const groupsWithoutBookingsIsSelected =
		sourceData.groups.withoutBookingsIsSelected();
	const resourcesWithoutBookingsIsSelected =
		sourceData.resources.withoutBookingsIsSelected();

	const handleClear = () => {
		setFilters(defaultFilters);

		qs.remove('schedulingFilters');
		qs.commit();
	};

	const handleApply = (
		close: (event?: React.MouseEvent<Element, MouseEvent>) => void
	) => {
		if (hasBeenFiltered(filters)) {
			qs.set('schedulingFilters', JSON.stringify({ ...filters }));
		} else {
			qs.remove('schedulingFilters');
		}
		qs.commit();

		close();
	};

	const handleSearch = (value: string) => {
		setSearch(value);
	};

	const onConflictsChange = () => {
		setFilters({
			conflicts: !filters.conflicts,
		});
	};

	const handleBookingChange = (
		e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
	) => {
		setFilters({
			bookings: { ...filters.bookings, [e.target.name]: e.target.value },
		});
	};

	const handleResourceSelect = (id: number) => {
		let resources = [...filters.resources];

		if (resources.includes(id)) {
			resources = resources.filter((i) => i !== id);
		} else {
			resources.push(id);
		}

		setFilters({
			resources,
		});
	};

	const handleGroupSelect = (id: number) => {
		let groups = [...filters.groups];

		if (groups.includes(id)) {
			groups = groups.filter((i) => i !== id);
		} else {
			groups.push(id);
		}
		setFilters({
			groups,
		});
	};

	const handleLocationSelect = (id: number) => {
		let locations = [...filters.locations];

		if (locations.includes(id)) {
			locations = locations.filter((l) => l !== id);
		} else {
			locations.push(id);
		}

		setFilters({
			locations,
		});
	};

	const handleCategorySelect = (id: number) => {
		let categories = [...filters.categories];

		if (categories.includes(id)) {
			categories = categories.filter((c) => c !== id);
		} else {
			categories.push(id);
		}

		setFilters({
			categories,
		});
	};

	const handleSelectAllLocations = () => {
		let filterLocations = [...filters.locations];

		if (locations.length === filterLocations.length) {
			filterLocations = [];
		} else {
			filterLocations = locations.map((l) => l.id);
		}

		setFilters({
			locations: filterLocations,
		});
	};

	const handleSelectAllCategories = () => {
		let filterCategories = [...filters.categories];

		if (categories.length === filterCategories.length) {
			filterCategories = [];
		} else {
			filterCategories = categories.map((c) => c.id);
		}

		setFilters({
			categories: filterCategories,
		});
	};

	const handleSelectAllWith = (source: FilterSourceTypes) => {
		const arr = [...filters[source]];

		if (sourceData[source].withBookingsIsSelected()) {
			return setFilters({
				[source]: arr.filter(
					(i) => !sourceData[source].withBookings.includes(i)
				),
			});
		}

		return setFilters({
			[source]: [...new Set([...arr, ...sourceData[source].withBookings])],
		});
	};

	const handleSelectAllWithout = (source: FilterSourceTypes) => {
		const arr = [...filters[source]];

		if (sourceData[source].withoutBookingsIsSelected()) {
			return setFilters({
				[source]: arr.filter(
					(i) => !sourceData[source].withoutBookings().includes(i)
				),
			});
		}

		return setFilters({
			[source]: [...new Set([...arr, ...sourceData[source].withoutBookings()])],
		});
	};

	const onEventTypeChange = (value: string) => {
		if (filters.types.includes(value as EventTypes)) {
			setFilters({
				types: filters.types.filter((i) => i !== value),
			});
		} else {
			setFilters({
				types: [...filters.types, value as EventTypes],
			});
		}
	};

	const activeFilters = [
		filters.conflicts,
		filters.bookings.type !== BookingTypes.Empty,
		filters.resources.length > 0,
		filters.groups.length > 0,
		filters.types.length > 0,
		filters.locations.length > 0,
		filters.categories.length > 0,
	].filter((i) => i === true);

	const clearConflicts = () => {
		setFilters({
			conflicts: false,
		});
	};

	const clearAmount = () => {
		setFilters({
			bookings: {
				type: BookingTypes.Empty,
				amount: '1',
			},
		});
	};

	const clearBookingTypes = () => {
		setFilters({
			types: [],
		});
	};

	const clearFilter = (filter: FilterSourceTypes) =>
		setFilters({
			[filter]: [],
		});

	const extraColumns = [
		{
			content: t(`Bookings`),
			component: <AmountOfBookings items={bookings || scheduleItems} />,
		},
	];

	const groupTableExtraColumns = [
		{
			content: t(`Bookings`),
			component: hasScheduleItems ? (
				<GroupTableScheduleItemBookings scheduleItems={scheduleItems} />
			) : (
				<GroupTableBookings bookings={bookings} />
			),
		},
	];

	return (
		<Context.Menu
			toggleWith={
				<Button
					label={
						activeFilters.length > 0 && hasFiltered
							? t('Filters ({amount})', { amount: activeFilters.length })
							: t('Filter')
					}
					icon="tune"
					active={activeFilters.length > 0 && hasFiltered}
				/>
			}
			width="650px">
			<Context.Heading>
				{t('Filters')}
				<Row spacing={styles.spacing._2}>
					<Context.MenuContext.Consumer>
						{({ close }) => (
							<Fragment>
								{queryFilters && <Button label="Clear" onClick={handleClear} />}
								<Button
									label={t('Apply')}
									primary
									onClick={() => handleApply(close)}
								/>
							</Fragment>
						)}
					</Context.MenuContext.Consumer>
				</Row>
			</Context.Heading>
			<InputGroup
				label={t(`Conflicts`)}
				active={filters.conflicts}
				clearFilter={clearConflicts}>
				<Input.Control
					name="conflicts"
					label={t(`Only show conflicts`)}
					type="checkbox"
					onChange={onConflictsChange}
					checked={filters.conflicts}
				/>
			</InputGroup>
			<Context.Divider tight />
			<InputGroup
				label={t(`Amount of bookings`)}
				active={filters.bookings.type !== BookingTypes.Empty}
				clearFilter={clearAmount}>
				<Column>
					<Input.Select
						name="type"
						value={filters.bookings.type}
						onChange={handleBookingChange}>
						<option value={BookingTypes.Empty}>{t('Select')}</option>
						<option value={BookingTypes.Less}>{t(`is less than`)}</option>
						<option value={BookingTypes.More}>{t(`is more than`)}</option>
						<option value={BookingTypes.Equals}>{t(`equals`)}</option>
					</Input.Select>
					<Input.Field
						name="amount"
						type="number"
						placeholder={t(`Amount`)}
						onChange={handleBookingChange}
						value={filters.bookings.amount}
					/>
				</Column>
			</InputGroup>
			<Context.Divider tight />
			<InputGroup
				label={t(`Booking types`)}
				clearFilter={clearBookingTypes}
				active={filters.types.length > 0}>
				<Column spacing={styles.spacing._3}>
					{eventTypeLabels.map((t) => (
						<Input.Control
							key={t}
							type="checkbox"
							label={eventTypeToTranslatedString(t)}
							onChange={onEventTypeChange}
							value={t}
							checked={filters.types.includes(t)}
						/>
					))}
				</Column>
			</InputGroup>
			<Context.Divider tight />
			<InputGroup
				label={t(`Select resources`)}
				skipPane
				active={filters.resources.length > 0}
				clearFilter={() => clearFilter('resources')}>
				<Fragment>
					<ItemWrapper>
						<SearchInput
							small
							placeholder={t(`Search resources`)}
							onChange={handleSearch}
						/>
					</ItemWrapper>
					{search === '' && (
						<Context.Item tight closeOnClick={false}>
							<Row autoColumns="max-content">
								{sourceData.resources.withBookings.length > 0 && (
									<SelectText onClick={() => handleSelectAllWith('resources')}>
										{resourcesWithBookingsIsSelected
											? `- ${t(`Deselect all with bookings`)}`
											: `+ ${t(`Select all with bookings`)}`}
									</SelectText>
								)}
								{resourcesWithoutBookings.length > 0 && (
									<SelectText
										onClick={() => handleSelectAllWithout('resources')}>
										{resourcesWithoutBookingsIsSelected
											? `- ${t(`Deselect all without bookings`)}`
											: `+ ${t(`Select all without bookings`)}`}
									</SelectText>
								)}
							</Row>
						</Context.Item>
					)}
					<ResourcesTable
						resources={resources}
						select={handleResourceSelect}
						hideContextColumn
						selected={filters.resources}
						selectable={SelectableOptions.multi}
						search={search}
						extraColumns={extraColumns}
						categories={categories}
						refreshCategories={refreshCategories}
					/>
				</Fragment>
			</InputGroup>
			<Context.Divider tight />
			<InputGroup
				label={t(`Select groups`)}
				skipPane
				active={filters.groups.length > 0}
				clearFilter={() => clearFilter('groups')}>
				<Fragment>
					<ItemWrapper>
						<SearchInput
							small
							placeholder={t(`Search groups`)}
							onChange={handleSearch}
						/>
					</ItemWrapper>
					{search === '' && (
						<Context.Item tight closeOnClick={false}>
							<Row autoColumns="max-content">
								{sourceData.groups.withBookings.length > 0 && (
									<SelectText onClick={() => handleSelectAllWith('groups')}>
										{groupsWithBookingsIsSelected
											? `- ${t(`Deselect all with bookings`)}`
											: `+ ${t(`Select all with bookings`)}`}
									</SelectText>
								)}
								{groupsWithoutBookings.length > 0 && (
									<SelectText onClick={() => handleSelectAllWithout('groups')}>
										{groupsWithoutBookingsIsSelected
											? `- ${t(`Deselect all without bookings`)}`
											: `+ ${t(`Select all without bookings`)}`}
									</SelectText>
								)}
							</Row>
						</Context.Item>
					)}
					<GroupsTable
						groups={groups}
						select={handleGroupSelect}
						selected={filters.groups}
						search={search}
						extraColumns={groupTableExtraColumns}
					/>
				</Fragment>
			</InputGroup>
			<Context.Divider tight />
			<InputGroup
				label={t(`Select location`)}
				skipPane
				active={filters.locations.length > 0}
				clearFilter={() => clearFilter('locations')}>
				<ItemWrapper>
					<SearchInput
						small
						placeholder={t(`Search locations`)}
						onChange={handleSearch}
					/>
				</ItemWrapper>
				{search === '' && (
					<Context.Item tight closeOnClick={false}>
						<Row autoColumns="max-content">
							{sourceData.locations.withBookings.length > 0 && (
								<SelectText onClick={() => handleSelectAllWith('locations')}>
									{locationsWithBookingsIsSelected
										? `- ${t(`Deselect all with bookings`)}`
										: `+ ${t(`Select all with bookings`)}`}
								</SelectText>
							)}
						</Row>
					</Context.Item>
				)}
				<LocationsTable
					search={search}
					locations={locations}
					selectable="multi"
					hideContextColumn={true}
					selectedLocations={filters.locations}
					select={handleLocationSelect}
					selectAll={handleSelectAllLocations}
				/>
			</InputGroup>
			<Context.Divider tight />
			<InputGroup
				label={t(`Select categories`)}
				skipPane
				active={filters.categories.length > 0}
				clearFilter={() => clearFilter('categories')}>
				<ItemWrapper>
					<SearchInput
						small
						placeholder={t(`Search categories`)}
						onChange={handleSearch}
					/>
				</ItemWrapper>
				{search === '' && (
					<Context.Item tight closeOnClick={false}>
						<Row autoColumns="max-content">
							{sourceData.categories.withBookings.length > 0 && (
								<SelectText onClick={() => handleSelectAllWith('categories')}>
									{categoriesWithBookingsIsSelected
										? `- ${t(`Deselect all with bookings`)}`
										: `+ ${t(`Select all with bookings`)}`}
								</SelectText>
							)}
						</Row>
					</Context.Item>
				)}
				<ResourceCategoriesTable
					search={search}
					categories={categories}
					selectAll={handleSelectAllCategories}
					selectedCategories={filters.categories}
					select={handleCategorySelect}
					selectable="multi"
				/>
			</InputGroup>
		</Context.Menu>
	);
};

export default ScheduleFilters;
