// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Introduction */

.ujW_3gOVi4AsAaHfNA9M {
	line-height: 1.5;
}

.S1UECEIaVOySSnYMZ245 img {
		border-radius: var(--radius-6);
		width: 100%;
		max-height: 50vh;
		-o-object-fit: contain;
		   object-fit: contain;
	}

/* Summary */

.SQmNkXKAyG1Y2j63AsHJ {
	text-align: right;
	font-weight: var(--font-weight-semibold);
}

.QrQSSgy0famMqONIO2vc {
	text-align: right;
}

.cNjlIlj8l_XSlnkJMtQ4 {
	font-size: var(--font-size-lg);
}

.lUbmzrQ6E1xcuXFcn5UB {
	text-align: right;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-lg);
}

.USJx8q3Qz5RJyf42bWxi {
	border: none;
	border-top: 1px solid var(--palette-gray-300);
}
`, "",{"version":3,"sources":["webpack://./routes/kitchen_sink/views/application/registrations/styles.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;CACC,gBAAgB;AACjB;;AAGC;EACC,8BAA8B;EAC9B,WAAW;EACX,gBAAgB;EAChB,sBAAmB;KAAnB,mBAAmB;CACpB;;AAGD,YAAY;;AAEZ;CACC,iBAAiB;CACjB,wCAAwC;AACzC;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,iBAAiB;CACjB,wCAAwC;CACxC,8BAA8B;AAC/B;;AAEA;CACC,YAAY;CACZ,6CAA6C;AAC9C","sourcesContent":["/* Introduction */\n\n.registrationDescription {\n\tline-height: 1.5;\n}\n\n.image {\n\t& img {\n\t\tborder-radius: var(--radius-6);\n\t\twidth: 100%;\n\t\tmax-height: 50vh;\n\t\tobject-fit: contain;\n\t}\n}\n\n/* Summary */\n\n.item {\n\ttext-align: right;\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.amount {\n\ttext-align: right;\n}\n\n.labelLarge {\n\tfont-size: var(--font-size-lg);\n}\n\n.amountLarge {\n\ttext-align: right;\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-lg);\n}\n\n.divider {\n\tborder: none;\n\tborder-top: 1px solid var(--palette-gray-300);\n}\n"],"sourceRoot":""}]);
// Exports
export var registrationDescription = `ujW_3gOVi4AsAaHfNA9M`;
export var image = `S1UECEIaVOySSnYMZ245`;
export var item = `SQmNkXKAyG1Y2j63AsHJ`;
export var amount = `QrQSSgy0famMqONIO2vc`;
export var labelLarge = `cNjlIlj8l_XSlnkJMtQ4`;
export var amountLarge = `lUbmzrQ6E1xcuXFcn5UB`;
export var divider = `USJx8q3Qz5RJyf42bWxi`;
export default ___CSS_LOADER_EXPORT___;
