// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j5FNVqwKRKffBiW46sCU {
	display: grid;
	grid-template-columns: 40px 1fr auto;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	grid-gap: var(--spacing-4);
	gap: var(--spacing-4);
	padding: var(--spacing-4);
	grid-template-areas: 'icon groupCode button';
}

@media (max-width: 768px) {

.j5FNVqwKRKffBiW46sCU {
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 40px 1fr;
		grid-template-areas: 'icon groupCode' 'button button';
}
	}

.SjXFTH9SmzYVIynXfWRe {
	font-weight: var(--font-weight-semibold);
}

.w1EnlC_hB9BqyMxdxeLL {
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-sm);
	padding-top: var(--spacing-1);
}

.CXd1edX4O06eytMHUj4G {
	grid-area: button;
}

.uJ1mbUZB48SuiK76PT43 {
	grid-area: groupCode;
}

.k3UnCq5wLThhzEmaoT4E {
	width: 40px;
	height: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-user-select: none;
	   -moz-user-select: none;
	            -ms-user-select: none;
	        user-select: none;
	border-radius: 1000rem;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	background-color: var(--palette-blue-200);
	align-items: center;
	justify-items: center;
	place-items: center;
	grid-area: icon;
}

.uXx2UY6ySm8RIdkHWy27 {
	grid-template-columns: auto 1fr auto;
}

.uXx2UY6ySm8RIdkHWy27 strong {
		font-weight: var(--font-weight-semibold);
		-webkit-font-feature-settings: 'ss02' 1;
		        font-feature-settings: 'ss02' 1;
		letter-spacing: 2px;
	}
`, "",{"version":3,"sources":["webpack://./routes/group/show/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,oCAAoC;CACpC,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,0BAAqB;CAArB,qBAAqB;CACrB,yBAAyB;CACzB,4CAA4C;AAO7C;;AALC;;AARD;EASE,2BAA2B;EAC3B,+BAA+B;EAC/B,qDAAqD;AAEvD;CADC;;AAGD;CACC,wCAAwC;AACzC;;AAEA;CACC,sCAAsC;CACtC,8BAA8B;CAC9B,6BAA6B;AAC9B;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,kBAAkB;CAClB,yBAAiB;IAAjB,sBAAiB;aAAjB,qBAAiB;SAAjB,iBAAiB;CACjB,sBAAsB;CACtB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,yCAAyC;CACzC,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,eAAe;AAChB;;AAEA;CACC,oCAAoC;AAOrC;;AALC;EACC,wCAAwC;EACxC,uCAA+B;UAA/B,+BAA+B;EAC/B,mBAAmB;CACpB","sourcesContent":[".inviteCard {\n\tdisplay: grid;\n\tgrid-template-columns: 40px 1fr auto;\n\talign-items: center;\n\tgap: var(--spacing-4);\n\tpadding: var(--spacing-4);\n\tgrid-template-areas: 'icon groupCode button';\n\n\t@media (--small-viewport) {\n\t\tgrid-template-rows: 1fr 1fr;\n\t\tgrid-template-columns: 40px 1fr;\n\t\tgrid-template-areas: 'icon groupCode' 'button button';\n\t}\n}\n\n.groupCode {\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.groupCodeInfo {\n\tfont-weight: var(--font-weight-normal);\n\tfont-size: var(--font-size-sm);\n\tpadding-top: var(--spacing-1);\n}\n\n.inviteButton {\n\tgrid-area: button;\n}\n\n.groupCodeContainer {\n\tgrid-area: groupCode;\n}\n\n.icon {\n\twidth: 40px;\n\theight: 40px;\n\tdisplay: flex;\n\tposition: relative;\n\tuser-select: none;\n\tborder-radius: 1000rem;\n\talign-items: center;\n\tjustify-content: center;\n\tbackground-color: var(--palette-blue-200);\n\tplace-items: center;\n\tgrid-area: icon;\n}\n\n.groupCodeItem {\n\tgrid-template-columns: auto 1fr auto;\n\n\tstrong {\n\t\tfont-weight: var(--font-weight-semibold);\n\t\tfont-feature-settings: 'ss02' 1;\n\t\tletter-spacing: 2px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var inviteCard = `j5FNVqwKRKffBiW46sCU`;
export var groupCode = `SjXFTH9SmzYVIynXfWRe`;
export var groupCodeInfo = `w1EnlC_hB9BqyMxdxeLL`;
export var inviteButton = `CXd1edX4O06eytMHUj4G`;
export var groupCodeContainer = `uJ1mbUZB48SuiK76PT43`;
export var icon = `k3UnCq5wLThhzEmaoT4E`;
export var groupCodeItem = `uXx2UY6ySm8RIdkHWy27`;
export default ___CSS_LOADER_EXPORT___;
