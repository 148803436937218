import { t } from '@transifex/native';

import { Capabilities } from 'pkg/models/membership';

import { Group } from 'pkg/api/models/group';
import { User } from 'pkg/api/models/user';
import { Record } from 'pkg/api/models/record';
import { Dateable } from 'pkg/api/models/dateable';

export enum MembershipRole {
	Unknown = 0,
	User = 1,
	Admin = 2,
	Bot = 4,
	Staff = 5,
}

export enum MembershipStatus {
	Pending = 0,
	Active = 1,
	Blocked = 2,
	Inactive = 3,
}

export interface Membership extends Record, Dateable {
	group?: Group;
	groupId: number;
	id: number;
	role: string | number;
	status: number;
	title: string;
	userId: number;
	lastRating: any;
	lastSelfRating: any;
	lastPerformanceReviewAt: number;
	isInherited: boolean;
	isLegalGuardian: boolean;
	isOrganizationMembership: boolean;
	targetUserId: number;
	capabilities: Capabilities[];
	user: User;
	targetUser: User;
}

export function roleToString(role: string | number): string {
	if (typeof role === 'string') {
		return role;
	}

	switch (role) {
		case MembershipRole.Admin:
			return 'admin';
		case MembershipRole.Staff:
			return 'staff';
		default:
			return 'user';
	}
}

export function stringToRole(value: string): MembershipRole {
	switch (value.toLowerCase()) {
		case 'admin':
			return MembershipRole.Admin;
		case 'staff':
			return MembershipRole.Staff;
		case 'user':
			return MembershipRole.User;
	}
}

export function isPlayer(membership: Membership) {
	if (!membership) {
		return false;
	}

	return (
		membership.role === MembershipRole.User ||
		membership.role === 'user' ||
		membership.role === MembershipRole.Bot ||
		membership.role === 'bot'
	);
}

export function isAdmin(membership: Membership) {
	if (!membership) {
		return false;
	}

	return (
		membership.role === MembershipRole.Admin || membership.role === 'admin'
	);
}

export function isStaff(membership: Membership) {
	return (
		membership.role === MembershipRole.Staff || membership.role === 'staff'
	);
}

export function isAdminOrStaff(membership: Membership) {
	if (!membership) {
		return false;
	}

	return (
		membership.role === MembershipRole.Admin ||
		membership.role === 'admin' ||
		membership.role === MembershipRole.Staff ||
		membership.role === 'staff'
	);
}

export function isParent(membership: Membership) {
	return !!membership.targetUserId || membership.isLegalGuardian;
}

export function isActive(membership: Membership) {
	return membership.status === 1;
}

export function isPending(membership: Membership) {
	return membership.status === 0;
}

export function valid(membership: Membership) {
	return !!membership.id && !membership.targetUserId && !isParent(membership);
}

export function roleString(membership: Membership) {
	if (isParent(membership)) {
		return 'parent';
	}

	return roleToString(membership.role);
}

export function roleToTranslatedString(role: MembershipRole) {
	switch (role) {
		case MembershipRole.Admin:
			return t('Administrator');
		case MembershipRole.Staff:
			return t('Staff');
		case MembershipRole.User:
			return t('Player');
	}
}

export function translatedRoleStringFromMembership(
	membership: Membership,
	skipParentRole: boolean = false
) {
	if (skipParentRole !== true && isParent(membership)) {
		return t('Parent');
	}

	return roleToTranslatedString(membership.role as MembershipRole);
}

export function hasCapability(membership: Membership, flag: Capabilities) {
	return membership.capabilities.includes(flag);
}

export function getUniqueMembershipStrings(memberships: Membership[]) {
	const filteredMemberships = memberships.filter((m) => m.role !== 0);

	const uniqueRoles = [
		...new Set(
			filteredMemberships.map((m) => translatedRoleStringFromMembership(m))
		),
	];

	return uniqueRoles.join(', ');
}

export function filterActiveMembership(
	memberships: Membership[],
	activeUserIds: number[],
	activeMembership: Membership
) {
	let contacts = memberships.filter(
		(membership) =>
			!activeUserIds.includes(membership.userId) && membership.user.accountId
	);

	if (activeMembership.targetUserId) {
		contacts = contacts.filter((membership) => membership.role !== 1);
	}

	if (isPlayer(activeMembership)) {
		contacts = contacts.filter((membership) => membership.role !== 0);
	}

	return contacts;
}

export interface MembershipGroups {
	adminOrStaff: Membership[];
	players: Membership[];
	parents: Membership[];
}

export function groupByRole(memberships: Membership[]): MembershipGroups {
	const adminOrStaff: Membership[] = [];
	const players: Membership[] = [];
	const parents: Membership[] = [];

	memberships.forEach((membership) => {
		if (!membership.role && membership.isLegalGuardian) {
			parents.push(membership);
		} else if (isAdminOrStaff(membership)) {
			adminOrStaff.push(membership);
		} else {
			players.push(membership);
		}
	});

	return {
		adminOrStaff,
		players,
		parents,
	};
}
