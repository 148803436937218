import { t } from '@transifex/native';

import { Dateable } from 'pkg/api/models/dateable';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import { formatToCurrency } from 'pkg/i18n/formatters';

export interface Discount extends Record, Dateable {
	id: number;
	groupId: number;
	userId: number;
	title: string;
	description: string;
	code: string;
	percentOff: number;
	amountOff: number;
	currency: string;
	inactivatedAt: number;
	maxUsageCount: number;
	currentUsageCount: number;
	maxRecurringCount: number;
	splitAmount: boolean;

	author: models.user.User;
	group: models.group.Group;
}

export function isActive(discount: Discount) {
	if (discount.inactivatedAt) {
		return false;
	}

	return true;
}

export function getStatus(discount: Discount) {
	if (!isActive(discount)) {
		return {
			text: t('inactive'),
			color: 'orange',
		};
	}

	return {
		text: t('active'),
		color: 'green',
	};
}

export function isPercentage(discount: Discount) {
	if (discount?.percentOff) {
		return true;
	}

	return false;
}

export function getDiscountType(discount: Discount) {
	if (isPercentage(discount)) {
		return t('Percentage');
	} else {
		return t('Amount');
	}
}

export function discountString(discount: Discount) {
	if (isPercentage(discount)) {
		return `${discount.percentOff}%`;
	}

	return formatToCurrency(
		-Math.abs(discount.amountOff) / 100,
		discount.currency
	);
}

export interface CreateDiscountPayload {
	groupId: number;
	title: string;
	description?: string;
	code: string;
	// One of amountOff and percentOff needs to be set
	amountOff?: number;
	percentOff?: number;
	isActive?: boolean;
	maxUsageCount?: number;
	maxRecurringCount?: number;
	splitAmount?: boolean;
}

export interface EditDiscountPayload {
	title?: string;
	description?: string;
	isActive?: boolean;
	maxUsageCount?: number;
	maxRecurringCount?: number;
}

export async function update(
	discount: Discount,
	payload: EditDiscountPayload
): Promise<[boolean, Discount]> {
	const request = await sdk.patch(
		endpoints.Discount.Show(discount.id),
		{},
		payload
	);

	if (!request.ok) {
		return [false, null];
	}

	const response: Discount = await request.json();

	return [true, response];
}

export async function remove(discount: Discount): Promise<boolean> {
	const request = await sdk.destroy(endpoints.Discount.Show(discount.id));

	if (request.ok) {
		return true;
	}

	return false;
}
