import { t } from '@transifex/native';
import { useEffect } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

import StatusScreen from 'components/status-screen';

import Button from 'design/button';

const Error = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const formSubmissionId = searchParams.get('formSubmissionId');

	const cancelFormSubmission = async () => {
		if (formSubmissionId) {
			const response = await sdk.post(
				endpoints.FormSubmissions.CancelFormSubmission(
					parseInt(formSubmissionId)
				)
			);
			if (!response.ok) {
				flashActions.show(
					{
						title: t('Something went wrong cancelling your submission.'),
					},
					response.status
				);
			}
		}
	};

	useEffect(() => {
		cancelFormSubmission();
	}, []);

	return (
		<StatusScreen
			fullscreen
			status="error"
			title={t('Something went wrong!')}
			description={t(
				'There was a problem processing your registration or payment'
			)}>
			<Button primary large href={routes.Home()}>
				{t('Go to home')}
			</Button>
		</StatusScreen>
	);
};

export default Error;
