import { t } from '@transifex/native';
import styled from 'styled-components';
import {
	CSSProperties,
	MouseEvent,
	PointerEvent,
	ReactNode,
	useRef,
} from 'react';

import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { AttachmentType } from 'pkg/api/models/attachment';
import { useCurrentOrganization } from 'pkg/identity';

import { useTrainingLibraryFilters } from 'routes/training/library/hooks';

import TextOverflow from 'components/TextOverflow';
import Tag from 'components/Tag';
import Icon from 'components/icon';

import GroupItem, {
	GroupItemAction,
	GroupItemType,
} from 'components/library/GroupItem';
import Column from 'components/layout/column';

const Wrapper = styled(GroupItem)`
	background-image: none;
	aspect-ratio: unset;
	display: flex;
	flex-flow: column;

	@supports not (aspect-ratio: 16 / 9) {
		&::before,
		&::after {
			display: none;
		}
	}
`;

const Container = styled(Column)`
	padding: var(--spacing-5);
	color: var(--palette-black);
	flex: 1 0 auto;
	grid-template-rows: auto 1fr auto;
`;

const Title = styled(TextOverflow)`
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);

	// Adjust for "new" label
	max-width: calc(100% - 60px);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
		line-height: var(--font-line-height-base);
	}
`;

const Description = styled(TextOverflow)`
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-base);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
		line-height: var(--font-line-height-sm);
	}
`;

const Hero = styled.div`
	aspect-ratio: 16 / 9;
	overflow: hidden;
	position: relative;
	background: var(--palette-gray-400);

	img {
		object-fit: contain;
		position: absolute;
		z-index: 10;
		top: 0;
	}

	@supports not (aspect-ratio: 16 / 9) {
		&::before {
			float: left;
			padding-top: calc(9 / 16 * 100%);
			content: '';
		}

		&::after {
			display: block;
			content: '';
			clear: both;
		}

		& > img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: var(--radius-5);
		}
	}
`;

const TagsContainer = styled.div`
	margin: 0 var(--spacing-4);
	width: calc(100% - (var(--spacing-4) * 2));
	position: absolute;
	left: 0;
	bottom: var(--spacing-4);
	z-index: 11;
	display: inline-flex;
	gap: var(--spacing-2);
`;

const MetaRow = styled.div`
	font-size: var(--font-size-sm);
	color: var(--palette-black);
	padding: var(--spacing-3) 0 var(--spacing-2) 0;

	&:empty {
		display: none;
	}

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}

	span {
		display: inline-block;

		&:not(:last-child) {
			margin-right: var(--spacing-4);
		}
	}
`;

interface ExerciseGroupItemProps {
	item: models.exercise.Exercise;
	actions?: GroupItemAction<models.exercise.Exercise>[];
	onClick?: (
		item: models.exercise.Exercise,
		event?: PointerEvent<HTMLDivElement>
	) => void;
}

export default function ExerciseGroupItem({
	item,
	actions,
	onClick,
}: ExerciseGroupItemProps): JSX.Element {
	const filters = useTrainingLibraryFilters();
	const attachment = models.exercise.coverImage(item);
	const attachmentRef = useRef<HTMLVideoElement>();
	const org = useCurrentOrganization();

	const handleTagClick = (event: MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();

		filters.reset();
		filters.addTag(event.currentTarget.getAttribute('name'));
	};

	let previewAttachment: ReactNode = null;

	const handleClick = (event: PointerEvent<HTMLDivElement>) => {
		if (onClick) {
			onClick(item, event);
		}
	};

	const handleMouseEnter = () => {
		if (attachmentRef.current && attachmentRef.current.paused) {
			attachmentRef.current.play();
		}
	};

	const handleMouseLeave = () => {
		if (attachmentRef.current && !attachmentRef.current.paused) {
			attachmentRef.current.pause();
			attachmentRef.current.currentTime = 0;
		}
	};

	if (item.thumbnail?.url) {
		previewAttachment = <img src={item.thumbnail?.url} loading="lazy" />;
	} else if (attachment?.type === AttachmentType.Image && attachment.url) {
		previewAttachment = <img src={attachment.url} loading="lazy" />;
	} else if (attachment?.type === AttachmentType.Video && attachment.url) {
		previewAttachment = (
			<video
				ref={attachmentRef}
				muted
				autoPlay={false}
				controls={false}
				preload="meta"
				src={attachment.url}
				width="160"
				height="90"
			/>
		);
	} else {
		previewAttachment = (
			<img
				src={window.TS.assetUrl + '/img/library-placeholder.svg'}
				loading="lazy"
			/>
		);
	}

	let account = item.author;

	if (item.userCreated === false) {
		account = models.account.getCuratorAccount();
	}

	const capTagsAt = 4;
	let tags = item.tags;
	let rest = '';

	if (tags?.length >= capTagsAt) {
		const restAmount = tags.length - capTagsAt;

		tags = tags.slice(0, 4);

		if (restAmount > 0) {
			rest = t(`+{restAmount} more`, { restAmount });
		}
	}

	return (
		<Wrapper
			item={item}
			itemType={GroupItemType.Exercise}
			href={onClick ? undefined : routes.Exercise.Show(org.id, item.id)}
			actions={actions}
			title={item.title}
			account={account}
			user={item.user}
			accountAsByline
			createdAt={item.createdAt}
			backdropColor={styles.palette.white}
			aspectRatio="auto"
			onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{ '--content-row': '1fr' } as CSSProperties}>
			<Hero>
				{tags?.length > 0 && (
					<TagsContainer>
						{tags.map((tag: models.tag.Tag) => (
							<Tag
								small
								key={tag.name}
								name={tag.name}
								onClick={handleTagClick}
							/>
						))}
						{rest && <Tag small type="rest" name={rest} />}
					</TagsContainer>
				)}
				{previewAttachment}
			</Hero>
			<Container spacing={styles.spacing._2}>
				<Title maxLines={2}>{item.title}</Title>
				{item.introduction && (
					<Description maxLines={3}>{item.introduction}</Description>
				)}
				<MetaRow>
					{item.minTime > 0 && (
						<span>
							<Icon name="duration" size={1.2} /> {item.minTime} {t(`minutes`)}
						</span>
					)}
					{item.minParticipants > 0 && (
						<span>
							<Icon name="nav-members" size={1.2} /> {item.minParticipants}
						</span>
					)}
					{item.minAge > 0 && (
						<span>
							<Icon name="age" size={1.2} />{' '}
							{[item.minAge, item.maxAge].join('–')}
						</span>
					)}
					{item.areaWidth > 0 && (
						<span>
							<Icon name="area" size={1.2} />{' '}
							{[item.areaWidth, item.areaLength].join('×')}
						</span>
					)}
				</MetaRow>
			</Container>
		</Wrapper>
	);
}
