import { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import { useCurrentAccountUserIds, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { replaceState } from 'pkg/router/state';
import useConfirm from 'pkg/hooks/useConfirm';

import { useChatContext } from 'routes/chat/view';

import AddChatUsersModal from 'containers/chat/modals/AddChatUsersModal';
import ChatUsersModal from 'containers/chat/modals/ChatUsersModal';
import ChatTitleModal from 'containers/chat/modals/ChatTitleModal';

import { LargeScreen } from 'components/MediaQuery';
import Toggle from 'components/Toggle';
import Icon from 'components/icon';

import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';

import * as ContextMenu from 'design/context_menu';

const FilterIcon = styled(Icon)`
	margin-left: 0.5rem;
`;

const ToggleItem = styled(ContextMenu.Item)`
	grid-template-columns: auto 1fr auto;
`;

interface SettingsProps {
	setPageActions: (item: JSX.Element) => void;

	chat: models.chat.Chat;
}

export default function Settings({ chat, setPageActions }: SettingsProps) {
	const chatContext = useChatContext();
	const org = useCurrentOrganization();

	const [showChatTitleModal, setShowChatTitleModal] = useState(false);
	const [showChatUsersModal, setShowChatUsersModal] = useState(false);
	const [showAddChatUsersModal, setShowAddChatUsersModal] = useState(false);
	const activeUserIds = useCurrentAccountUserIds();

	const myChatUser = models.chat.findMyChatUser(chat, activeUserIds);
	const chatTitle = models.chat.getChatTitle(chat, activeUserIds);

	const { isAdmin, hasMuted } = myChatUser;
	const isGroupChat = models.chat.isGroup(chat);
	const chatUsers = models.chatUser.filterDeleted(chat.users);
	const onlyAdmin =
		chatUsers.filter((cu) => cu.isAdmin).length === 1 && isAdmin;

	const archiveForChatUser = async () => {
		const [req, resp] = await models.chatUser.update(chat, myChatUser, {
			hasArchived: !myChatUser.hasArchived,
		});

		if (req) {
			chatContext.chatCollection.replaceInRecord(chat.id, 'users', resp);
		}
	};

	const handleToggleIsArchived = useCallback(async () => {
		const updatedChat = await models.chat.archive(chat, !chat.isArchived);

		if (updatedChat) {
			chatContext.chatCollection.replaceRecord(updatedChat);
		}
	}, [chat.id, chat.isArchived, chatTitle]);

	const confirmMessage = t('Are you sure you want to archive this chat?');
	const archiveConfirm = useConfirm({
		message: confirmMessage,
		onConfirm: archiveForChatUser,
	});
	const archiveGroupChat = useConfirm({
		message: chat.isArchived
			? t('Are you sure you want to unarchive "{title}"?"', {
					title: chatTitle,
				})
			: t(
					'Are you sure you want to archive "{title}"?" This will archive the chat for all group members.',
					{
						title: chatTitle,
					}
				),
		onConfirm: handleToggleIsArchived,
	});

	const toggleChatTitleModal = useCallback(
		() => setShowChatTitleModal(!showChatTitleModal),
		[setShowChatTitleModal, showChatTitleModal]
	);

	const toggleChatUsersModal = useCallback(
		() => setShowChatUsersModal(!showChatUsersModal),
		[setShowChatUsersModal, showChatUsersModal]
	);

	const toggleAddChatUsersModal = useCallback(
		() => setShowAddChatUsersModal(!showAddChatUsersModal),
		[setShowAddChatUsersModal, showAddChatUsersModal]
	);

	const handleToggleHasMuted = useCallback(
		() => models.chatUser.update(chat, myChatUser, { hasMuted: !hasMuted }),
		[chat.id, hasMuted]
	);

	let leaveChatMessage = t('Are you sure you want to leave "{title}"?"', {
		title: chatTitle,
	});

	if (chatUsers.length === 1) {
		leaveChatMessage = t(
			`You're the last member of this chat, if you leave this chat will be permanently deleted.`
		);
	}

	if (onlyAdmin) {
		leaveChatMessage = t(
			`You're the only admin in this chat, if you leave the person that has been in the chat for the longest time will become admin.`
		);
	}

	const handleLeaveChat = useCallback(async () => {
		if (chatUsers.length === 1) {
			const removed = models.chat.remove(chat);

			if (removed) {
				replaceState(routes.Chat.Index(org.id));
			}

			return;
		}

		if (onlyAdmin) {
			const firstChatMember = chatUsers
				.filter((cu) => cu.id !== myChatUser.id)
				.sort((a, b) => a.createdAt - b.createdAt)[0];

			models.chatUser.update(chat, firstChatMember, { isAdmin: true });
			const removed = await models.chatUser.remove(chat, myChatUser);

			if (removed) {
				replaceState(routes.Chat.Index(org.id));
			}

			return;
		}

		const removed = await models.chatUser.remove(chat, myChatUser);

		if (removed) {
			replaceState(routes.Chat.Index(org.id));
		}
	}, [chat.id, chatTitle, chatUsers.length]);

	const leaveChatConfirm = useConfirm({
		message: leaveChatMessage,
		onConfirm: handleLeaveChat,
	});

	const contextMenuItems = useMemo(() => {
		const isArchived = myChatUser.hasArchived || chat.isArchived;
		let showArchiveForChatUser = false;

		if (
			isArchived &&
			models.chat.canUnarchiveChat(chat, activeUserIds) &&
			!chat.isArchived
		) {
			showArchiveForChatUser = true;
		}
		if (!isArchived) {
			showArchiveForChatUser = true;
		}

		return [
			isGroupChat && isAdmin && (
				<ContextMenu.Item
					key="chat-users"
					onClick={toggleChatUsersModal}
					testid="chat.settings_users">
					<ContextMenu.ItemIcon name="groups" />
					{t('Members')}
				</ContextMenu.Item>
			),

			isGroupChat && isAdmin && (
				<ContextMenu.Item
					key="add-chat-users"
					onClick={toggleAddChatUsersModal}
					testid="chat.settings_add_users">
					<ContextMenu.ItemIcon name="person_add" />
					{t('Add members')}
				</ContextMenu.Item>
			),

			isGroupChat && isAdmin && (
				<ContextMenu.Item
					key="rename-chat"
					onClick={toggleChatTitleModal}
					testid="chat.settings_rename">
					<ContextMenu.ItemIcon name="edit" />
					{t('Rename')}
				</ContextMenu.Item>
			),

			<ToggleItem
				key="mute-chat"
				onClick={handleToggleHasMuted}
				testid="chat.settings_mute">
				<ContextMenu.ItemIcon name="speaker_notes_off" />
				{t('Mute')}
				<Toggle active={hasMuted} />
			</ToggleItem>,

			showArchiveForChatUser && (
				<ContextMenu.Item onClick={archiveConfirm}>
					<ContextMenu.ItemIcon name="archive" />
					{myChatUser.hasArchived
						? t('Unarchive')
						: isGroupChat
							? t('Archive only for me')
							: t('Archive')}
				</ContextMenu.Item>
			),

			isGroupChat && isAdmin && (
				<ContextMenu.Item onClick={archiveGroupChat}>
					<ContextMenu.ItemIcon name="archive" />
					{chat.isArchived
						? t('Unarchive group chat')
						: t('Archive entire group chat')}
				</ContextMenu.Item>
			),

			isGroupChat && (
				<ContextMenu.Item
					key="leave-chat"
					onClick={leaveChatConfirm}
					caution
					testid="chat.settings_leave">
					<ContextMenu.ItemIcon name="exit_to_app" />
					{t('Leave chat')}
				</ContextMenu.Item>
			),
		];
	}, [
		chat,
		isAdmin,
		hasMuted,
		toggleChatUsersModal,
		toggleAddChatUsersModal,
		toggleChatTitleModal,
		handleToggleHasMuted,
		handleToggleIsArchived,
		handleLeaveChat,
		chat.isArchived,
		myChatUser.hasArchived,
	]);

	useEffect(() => {
		if (setPageActions) {
			setPageActions(
				<ContextMenu.Menu toggleWith={<ButtonTrigger icon="more_horiz" />}>
					{contextMenuItems}
				</ContextMenu.Menu>
			);
		}
	}, [contextMenuItems]);

	return (
		<Fragment>
			<LargeScreen>
				<ContextMenu.Menu
					offsetVertical={30}
					offsetHorizontal={0}
					toggleWith={
						<Link testid="chat.settings_button">
							{t('Details')}
							<FilterIcon name="chevron" rotate="90deg" />
						</Link>
					}>
					{contextMenuItems}
				</ContextMenu.Menu>
			</LargeScreen>

			{showChatTitleModal && (
				<ChatTitleModal chat={chat} onClose={toggleChatTitleModal} />
			)}

			{showChatUsersModal && (
				<ChatUsersModal chat={chat} onClose={toggleChatUsersModal} />
			)}

			{showAddChatUsersModal && (
				<AddChatUsersModal chat={chat} onClose={toggleAddChatUsersModal} />
			)}
		</Fragment>
	);
}
