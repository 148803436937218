// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RMcmA8KdDgrxsXjcsfv5 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	gap: var(--spacing-2);
}
.RMcmA8KdDgrxsXjcsfv5 strong {
		display: block;
		word-break: break-word;
	}
`, "",{"version":3,"sources":["webpack://./routes/group/show/user_item/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,4BAAsB;CAAtB,6BAAsB;KAAtB,0BAAsB;SAAtB,sBAAsB;CACtB,uBAA2B;KAA3B,oBAA2B;SAA3B,2BAA2B;CAC3B,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,qBAAqB;AAMtB;AAJC;EACC,cAAc;EACd,sBAAsB;CACvB","sourcesContent":[".titleItemWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: flex-start;\n\talign-items: flex-start;\n\tgap: var(--spacing-2);\n\n\t& strong {\n\t\tdisplay: block;\n\t\tword-break: break-word;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var titleItemWrapper = `RMcmA8KdDgrxsXjcsfv5`;
export default ___CSS_LOADER_EXPORT___;
