// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.haJtlOqMB44wEGGlJwyZ {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	gap: var(--spacing-3);
}
`, "",{"version":3,"sources":["webpack://./routes/scheduling/templates/schedule_items/multi_day_select/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,mBAAe;KAAf,eAAe;CACf,qBAAqB;AACtB","sourcesContent":[".row {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tgap: var(--spacing-3);\n}\n"],"sourceRoot":""}]);
// Exports
export var row = `haJtlOqMB44wEGGlJwyZ`;
export default ___CSS_LOADER_EXPORT___;
