export function DeleteSplashScreenImage(groupID: number): string {
	return `/v1/organizations/${groupID}/splash-screen/image`;
}

export function ExportUsers(groupID: number): string {
	return `/v1/organizations/${groupID}/users/export`;
}

export function InvoiceStatistics(groupID: number): string {
	return `/v1/organizations/${groupID}/invoice-statistics`;
}

export function InvoiceSummary(groupID: number): string {
	return `/v1/organizations/${groupID}/invoice-summary`;
}

export function ListForms(groupID: number): string {
	return `/v1/organizations/${groupID}/forms`;
}

export function ListGroupPosts(groupID: number): string {
	return `/v1/organizations/${groupID}/group-posts`;
}

export function ListPayouts(groupID: number): string {
	return `/v1/organizations/${groupID}/payouts`;
}

export function ListUsers(groupID: number): string {
	return `/v1/organizations/${groupID}/users`;
}

export function ManagementDashboard(groupID: number): string {
	return `/v1/organizations/${groupID}/dashboards/management`;
}

export function PaymentDashboard(groupID: number): string {
	return `/v1/organizations/${groupID}/dashboards/payments`;
}

export function Show(groupID: number): string {
	return `/v1/organizations/${groupID}`;
}

export function UpdateLOKSettings(groupID: number): string {
	return `/v1/organizations/${groupID}/lok-settings`;
}

export function UpdateSplashScreen(groupID: number): string {
	return `/v1/organizations/${groupID}/splash-screen`;
}
