import * as styles from 'pkg/config/styles';

import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import { FilterOperator } from 'pkg/filters';

import { md } from 'routes/kitchen_sink/components/Markdown';

import Column from 'components/layout/column';
import * as ActionBar from 'components/layout/ActionBar';

import * as Context from 'design/context_menu';
import Button from 'design/button';

function ActionBarView() {
	const filterGroups: FilterGroups = {
		['Dummy Text Search']: {
			hidden: true,
			filters: {
				Title: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'title',
				},
			},
		},
		['Created At']: {
			filters: {
				Created: {
					type: 'date',
					property: 'createdAt',
					operator: FilterOperator.GreaterThan,
				},
			},
		},
	};

	const filters = useFilters({
		groups: filterGroups,
		filterBarMobileTrigger: true,
	});

	return (
		<Column spacing={styles.spacing._7}>
			{md`
				# Action bars

				---

				## Common variants
			`}

			<Column spacing={styles.spacing._3}>
				{md`
### Bar

Simple layout - This variant only shows \`PrimaryActions\` in \`LargeScreen\`
				`}

				<ActionBar.Bar>
					<Button label="Filter" icon="filter" />
					<Button label="Export" icon="download" />
					<Button label="Create" icon="add" primary />
				</ActionBar.Bar>
			</Column>

			<Column spacing={styles.spacing._3}>
				{md`
					### SaveBar

					This is the same as Bar in larger screens but is positioned in the bottom on smaller screens, should be combined with the \`hideTabBar\` middleware.
				`}
				<ActionBar.SaveBar>
					<Button label="Cancel" />
					<Button label="Save" primary />
				</ActionBar.SaveBar>
			</Column>

			<Column spacing={styles.spacing._3}>
				{md`
					### FilterBar

					FilterBar will add a toggle for stacked actions beneath the search input on small devices when there's more than one child.
				`}
				<ActionBar.FilterBar>
					<ActionBar.PrimaryAction>
						<ActionBar.Search placeholder="Search..." />
					</ActionBar.PrimaryAction>

					<Button label="Filter" icon="tune" />
					<Button label="Export" icon="download" />
					<Button label="Create" icon="add" primary />
				</ActionBar.FilterBar>
			</Column>

			<Column spacing={styles.spacing._3}>
				{md`
					### IntegratedFilterBar

					IntegratedFilterBar handles more logic and styling for filter buttons and actions. It will automatically render extra actions, and set page actions in mobile devices.
					It's made to interface directly with \`useFilters\`, with the caveat that the \`filterBarMobileTrigger\` option should be set.
					Prefer IntegratedFilterBar over FilterBar.
				`}
				<ActionBar.IntegratedFilterBar
					searchFilter={filterGroups['Dummy Text Search'].filters.Title}
					filters={filters}
					actions={[
						{
							label: 'A Context Menu',
							icon: 'create_new_folder',
							contextMenuItems: [
								<Context.Item key="1">A normal item</Context.Item>,
								<Context.LinkItem key="bing" href="https://bing.com">
									A link item
								</Context.LinkItem>,
							],
						},
						{
							label: 'Go to Google',
							href: 'https://google.com',
							type: 'primary',
							icon: 'add',
						},
					]}
				/>
			</Column>
		</Column>
	);
}

export default ActionBarView;
