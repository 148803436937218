// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d3qJQ6WtQLLDMhqz41i3 {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: var(--spacing-3);
}

.d3qJQ6WtQLLDMhqz41i3 button {
		height: 100%;
	}

.cx9KdhhtfiysRDAtIm7e {
	margin-bottom: var(--spacing-4);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-4);
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.TMC6xF5gBTLSW5B__GOA {
	padding-bottom: var(--spacing-1);
}
`, "",{"version":3,"sources":["webpack://./routes/forms/registration/form/products/discount/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,+BAA+B;CAC/B,0BAA0B;AAK3B;;AAHC;EACC,YAAY;CACb;;AAGD;CACC,+BAA+B;CAC/B,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB;;AAEA;CACC,gCAAgC;AACjC","sourcesContent":[".field_wrapper {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr auto;\n\tgrid-gap: var(--spacing-3);\n\n\tbutton {\n\t\theight: 100%;\n\t}\n}\n\n.discount_title_wrapper {\n\tmargin-bottom: var(--spacing-4);\n\tdisplay: flex;\n\tgap: var(--spacing-4);\n\talign-items: center;\n}\n\n.discount_title {\n\tpadding-bottom: var(--spacing-1);\n}\n"],"sourceRoot":""}]);
// Exports
export var field_wrapper = `d3qJQ6WtQLLDMhqz41i3`;
export var discount_title_wrapper = `cx9KdhhtfiysRDAtIm7e`;
export var discount_title = `TMC6xF5gBTLSW5B__GOA`;
export default ___CSS_LOADER_EXPORT___;
