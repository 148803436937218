import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useQueryState } from 'pkg/hooks/query-state';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import Browse from 'routes/video/library/Browse';
import Group from 'routes/video/library/Group';
import Club from 'routes/video/library/Club';
import Account from 'routes/video/library/Account';
import Collection from 'routes/video/library/Collection';
import Search from 'routes/video/library/Search';

import { useLargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import VideoModal from 'components/video-library/modals/Video';
import PlaylistModal from 'components/video-library/modals/Playlist';
import CollectionModal from 'components/video-library/modals/add-to-collection';
import * as ActionBar from 'components/layout/ActionBar';
import ContentFilter from 'components/library/ContentFilter';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const ContentWrapper = styled.div`
	padding: var(--spacing-8);
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-8);

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-5);
	}
`;

enum LibraryContentGroup {
	Browse = '',
	Team = 'team',
	Club = 'club',
	Your = 'your',
}

interface LibraryProps {
	contentGroup: LibraryContentGroup;
	collectionId?: string;
	setPageActions?: (node: ReactNode) => void;
	setPageSubNavItems?: (items: any[]) => void;
}

export default function Library({
	contentGroup,
	collectionId,
	setPageActions,
	setPageSubNavItems,
}: LibraryProps): JSX.Element {
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();

	const qs = useQueryState();
	const keyword = (qs.get('search', '') as string).toString();

	const [activeModal, setActiveModal] = useState<
		'' | 'video' | 'playlist' | 'collection'
	>('');

	const openVideoModal = () => setActiveModal('video');
	const openPlaylistModal = () => setActiveModal('playlist');
	const openCollectionModal = () => setActiveModal('collection');
	const closeModal = () => setActiveModal('');

	const searchInputRef = useRef<HTMLInputElement>();

	const isLargeScreen = useLargeScreen();

	const createActions = (
		<Fragment>
			<ContextMenu.Item onClick={openVideoModal}>
				<ContextMenu.ItemIcon name="smart_display" />
				{t('Add video')}
			</ContextMenu.Item>
			<ContextMenu.Item onClick={openPlaylistModal}>
				<ContextMenu.ItemIcon name="playlist_add" />
				{t('Add playlist')}
			</ContextMenu.Item>
			<ContextMenu.Item onClick={openCollectionModal}>
				<ContextMenu.ItemIcon name="create_new_folder" />
				{t('Add collection')}
			</ContextMenu.Item>
		</Fragment>
	);

	useEffect(() => {
		if (models.membership.isParent(membership)) {
			setPageSubNavItems([]);
		} else {
			setPageActions(
				<ContextMenu.Menu toggleWith={<ButtonTrigger icon="add" />}>
					{createActions}
				</ContextMenu.Menu>
			);
		}
	}, [membership.id]);

	useEffect(() => {
		if (searchInputRef.current) {
			searchInputRef.current.value = '';
		}
	}, [contentGroup]);

	let viewComponent: ReactNode = <Browse />;

	if (keyword?.length > 0) {
		viewComponent = <Search keyword={keyword} />;
	} else {
		switch (contentGroup) {
			case LibraryContentGroup.Team:
				viewComponent = <Group />;
				break;
			case LibraryContentGroup.Club:
				viewComponent = <Club />;
				break;
			case LibraryContentGroup.Your:
				viewComponent = <Account />;
				break;
		}
	}

	const handleAfterVideoSave = (video: models.video.Video) => {
		pushState(routes.Video.Show(org.id, video.id));
	};

	const handleAfterPlaylistSave = (
		playlist: models.videoPlaylist.VideoPlaylist
	) => {
		pushState(routes.VideoPlaylist.Show(org.id, playlist.id));
	};

	const modals = (
		<Fragment>
			{activeModal === 'video' && (
				<VideoModal onClose={closeModal} afterSave={handleAfterVideoSave} />
			)}
			{activeModal === 'playlist' && (
				<PlaylistModal
					onClose={closeModal}
					afterSave={handleAfterPlaylistSave}
				/>
			)}
			{activeModal === 'collection' && (
				<CollectionModal createOnly onClose={closeModal} />
			)}
		</Fragment>
	);

	if (collectionId) {
		return (
			<Fragment>
				{modals}
				<Collection />
			</Fragment>
		);
	}

	return (
		<Fragment>
			{modals}
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search
						value={qs.get('search') as string}
						placeholder={t('Search videos, playlists and collections…')}
					/>
				</ActionBar.PrimaryAction>
				<ContentFilter
					actions={[
						{
							label: t('Videos'),
							icon: (
								<Icon
									name="video-placeholder"
									style={{ transform: 'scale(1.2) translate(2px, 1px)' }}
								/>
							),
							type: 'videos',
						},
						{
							label: t('Playlists'),
							icon: <Icon name="list" />,
							type: 'playlists',
						},
						{
							label: t('Collections'),
							icon: (
								<Icon
									name="folder-list"
									size={1.4}
									style={{ translate: '2px' }}
								/>
							),
							type: 'collections',
						},
					]}
				/>
				{!models.membership.isParent(membership) && isLargeScreen && (
					<ContextMenu.Menu
						toggleWith={
							<Button primary icon="add">
								{t('Add content')}
							</Button>
						}>
						{createActions}
					</ContextMenu.Menu>
				)}
			</ActionBar.FilterBar>
			<ContentWrapper>{viewComponent}</ContentWrapper>
		</Fragment>
	);
}
