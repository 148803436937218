import { useState } from 'react';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import {
	PricePayload,
	IUpdatePricePayload,
} from 'pkg/actions/services/products';

import * as endpoints from 'pkg/api/endpoints/auto';
import { popState } from 'pkg/router/state';
import { decimalToCentsByCurrency } from 'pkg/utils';
import * as actions from 'pkg/actions';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import useMixedState from 'pkg/hooks/useMixedState';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import {
	AddProductContext,
	PriceOptionData,
	ProductMixedState,
} from 'routes/payments/products/create';
import AddForm from 'routes/payments/products/form';
import { validatePriceOptions } from 'routes/payments/products/form/price_option';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import ProductPreview from 'components/products/ProductPreview';
import * as ActionBar from 'components/layout/ActionBar';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

interface EditProps {
	productId: number;
	organizationId: number;
}

const Edit: React.FC<React.PropsWithChildren<EditProps>> = ({
	productId,
	organizationId,
}) => {
	const group = useCurrentGroup();
	const org = useCurrentOrganization();

	const [showPreviewModal, setShowPreviewModal] = useState(false);
	const [saving, setSaving] = useState<boolean>(false);

	const hidePreview = () => setShowPreviewModal(false);

	const [formState, setFormState] = useMixedState<ProductMixedState>({
		category: '',
		productTitle: '',
		description: '',
		isDefault: false,
		priceOptions: [],
		metadata: [],
	});

	const validProductInfo = !!formState.productTitle;

	const canSave =
		validatePriceOptions(formState.priceOptions).length ===
			formState.priceOptions.length &&
		validProductInfo &&
		!saving;

	const { record: product, isLoading: isLoadingProduct } =
		useEndpoint<models.product.Product>(
			endpoints.Products.Show(productId),
			{},
			(product) => {
				let priceOptions: PriceOptionData[] = [];

				if (product.prices.length) {
					priceOptions = product.prices
						.filter((price) => !price.archivedAt)
						.map((price) => {
							let billingCycle: models.providerSettings.RecurringOptionStrings;

							switch (price.recurringInterval) {
								case 'year': {
									if (price.recurringIntervalCount === 1) {
										billingCycle = 'annually';
										break;
									}
								}
								case 'month':
									{
										if (price.recurringIntervalCount === 12) {
											billingCycle = 'annually';
										} else if (price.recurringIntervalCount === 6) {
											billingCycle = 'semiAnnually';
										} else if (price.recurringIntervalCount === 3) {
											billingCycle = 'quarterly';
										} else if (price.recurringIntervalCount === 2) {
											billingCycle = 'bimonthly';
										} else if (price.recurringIntervalCount === 1) {
											billingCycle = 'monthly';
										} else {
											billingCycle = 'custom';
										}
									}
									break;
								case 'week':
									if (price.recurringIntervalCount === 1) {
										billingCycle = 'weekly';
									} else {
										billingCycle = 'custom';
									}
									break;
								case 'day':
									if (price.recurringIntervalCount === 1) {
										billingCycle = 'daily';
									} else {
										billingCycle = 'custom';
									}
									break;
								default: {
									billingCycle = 'custom';
								}
							}

							return {
								priceTitle: price.title,
								cost: price.cost,
								currency: price.currency,
								description: price.description,
								taxRateId: price.taxRateId ? price.taxRateId.toString() : '',
								taxRate: price.taxRateId
									? price.taxRate
									: ({} as models.taxRate.TaxRate),
								recurring: price.recurring,
								recurringIntervalCount: price.recurringIntervalCount,
								recurringInterval: price.recurringInterval,
								id: price.id,
								recurringOption: billingCycle,
							} as PriceOptionData;
						});
				}

				let category = '';

				if (product.productCategoryId) {
					category = product.productCategoryId.toString();
				}

				setFormState({
					productTitle: product.name,
					description: product.description,
					isDefault: product.isDefault,
					category,
					priceOptions,
					metadata: product.metadata || [],
				});
			}
		);

	const { records: productCategories, refresh: refreshCategories } =
		useCollection<models.productCategory.ProductCategory>(
			endpoints.ProductCategory.List(),
			{
				queryParams: new URLSearchParams({
					group_id: organizationId.toString(),
				}),
			}
		);

	const { records: taxRates } = useCollection<models.taxRate.TaxRate>(
		endpoints.TaxRates.Index1(group.id),
		{
			queryParams: new URLSearchParams({
				active: 'true',
			}),
		}
	);

	const { record: providerSettings, isLoading: isLoadingProviderSettings } =
		useEndpoint<models.providerSettings.ProviderSettings>(
			endpoints.Groups.PaymentProviderSettings(organizationId)
		);

	const { record: metadataKeys, isLoading: loadingProductMetadata } =
		useEndpoint<string[]>(endpoints.ProductMetadata.Index(), {
			queryParams: new URLSearchParams({
				group_id: organizationId.toString(),
			}),
		});

	const handleUpdate = async () => {
		setSaving(true);

		const productPayload: models.product.ProductUpdatePayload = {
			name: formState.productTitle,
			groupId: organizationId,
			isDefault: formState.isDefault,
		};

		productPayload.productCategoryId = formState.category
			? Number.parseInt(formState.category, 10)
			: null;

		if (formState.description.length > 0) {
			productPayload.description = formState.description;
		}

		if (formState.metadata.length) {
			productPayload.metadata = formState.metadata.map((m) => {
				return {
					key: m.key,
					value: m.value,
				};
			});
		}

		const [req] = await models.update<
			models.product.ProductUpdatePayload,
			models.product.Product
		>(product, productPayload);

		if (req.ok) {
			await handlePriceUpdate();

			actions.flashes.show(
				{
					title: t('Successful update'),
					message: t('Updated {product}', { product: product.name }),
				},
				200,
				'check-circle'
			);

			popState();
		}

		setSaving(false);
	};

	const handlePriceUpdate = async () => {
		for await (const item of formState.priceOptions) {
			if (item.id) {
				const payload: IUpdatePricePayload = {
					taxRateId: Number.parseInt(item.taxRateId, 10),
					title: item.priceTitle,
					description: '',
				};

				if (item.description.length > 0) {
					payload.description = item.description;
				}

				await actions.prices.update(item.id, payload);
			} else {
				const payload: PricePayload = {
					productId,
					cost: decimalToCentsByCurrency(item.cost, item.currency),
					currency: item.currency.toLowerCase(),
					recurring: item.recurring,
					taxRateId: Number.parseInt(item.taxRateId, 10),
					title: item.priceTitle,
					description: '',
					recurringInterval: item.recurringInterval,
					recurringIntervalCount: item.recurringIntervalCount,
				};

				if (item.description.length > 0) {
					payload.description = item.description;
				}

				await actions.prices.create(
					payload,
					item.recurringOption,
					providerSettings
				);
			}
		}
	};

	const content = (
		<AddForm
			productCategories={productCategories}
			refreshCategories={refreshCategories}
		/>
	);

	const isSmallScreen = useSmallScreen();

	if (isLoadingProduct || isLoadingProviderSettings || loadingProductMetadata) {
		return <Spinner />;
	}

	return (
		<AddProductContext.Provider
			value={{
				formState,
				setFormState,
				taxRates,
				providerSettings,
				metadataKeys,
			}}>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					href={routes.Products.Index(org.id)}>
					{t('Cancel')}
				</Button>

				<Button
					primary
					block={isSmallScreen}
					large={isSmallScreen}
					disabled={!canSave}
					onClick={handleUpdate}>
					{t('Save')}
				</Button>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD} spacious>
					{content}
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
			{showPreviewModal && <ProductPreview onClose={hidePreview} />}
		</AddProductContext.Provider>
	);
};

export default Edit;
