import styled, { css, DefaultTheme } from 'styled-components';

import * as styles from 'pkg/config/styles';

import { TabWrapper } from 'components/tab-view/variants/Base';

interface BaseProps {
	theme?: DefaultTheme;
	$noBorder?: boolean;
	$clickable?: boolean;
	$dark?: boolean;
}

export const Base = styled.div<BaseProps>`
	background-color: var(--palette-white);
	position: relative;
	border-radius: var(--radius-6);
	max-width: 100%;
	min-width: 0;
	transition: box-shadow 150ms ease-in-out;
	overflow: hidden;
	border: ${({ $noBorder: noBorder }) =>
		noBorder ? 'none' : '1px solid ' + styles.palette.gray[300]};

	${TabWrapper} {
		margin: 0;
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			background-color: var(--palette-gray-800);
			color: var(--palette-white);
			border: none;
		`}

	${({ $dark: dark }) =>
		dark &&
		css`
			background-color: var(--palette-gray-200);
		`}

	${({ $clickable: clickable }) =>
		clickable &&
		css`
			cursor: pointer;

			&:hover {
				box-shadow: ${styles.boxShadow.cardHover};
			}
		`}
`;

// @deprecated
// Move away from the default export, prefer using the named Base export
export default Base;

interface BodyProps {
	$spaciousBody?: boolean;
	$narrowBody?: boolean;
	$noSpacing?: boolean;
	$flex?: boolean;
}

export const Body = styled.div<BodyProps>`
	padding: ${(props) =>
		props.$spaciousBody
			? '40px 65px 70px 65px'
			: `var(--spacing-6) var(--spacing-5)`};
	line-height: 1.4;

	${(props) =>
		props.$narrowBody &&
		css`
			padding: var(--spacing-5);
		`}

	${(props) =>
		props.$noSpacing &&
		css`
			padding: 0 !important;
		`}

	@media ${styles.breakpoint.small} {
		padding: ${({ $spaciousBody: spaciousBody }) =>
			spaciousBody ? '30px' : styles.spacing._5};

		${({ $noSpacing: noSpacing }) =>
			noSpacing &&
			css`
				padding: 0 !important;
			`}
	}

	flex: ${(props) => (props.$flex ? 1 : '0 1 auto')};
`;

export const EmptyStateBody = styled(Body)`
	padding-top: 4rem;
	padding-bottom: 4rem;
	display: grid;
	grid-gap: 20px;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;

	@media ${styles.breakpoint.small} {
		padding: 3rem 16px;
		display: flex;
		flex-flow: column;
	}
`;

interface HeadingProps {
	$centered?: boolean;
	$large?: boolean;
}

export const Heading = styled.h3<HeadingProps>`
	display: flex;
	align-items: center;
	gap: var(--spacing-3);
	font-size: var(--font-size-base);
	color: var(--palette-black);
	font-weight: var(--font-weight-semibold);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			color: var(--palette-gray-300);
		`};

	${(props) =>
		props.$centered &&
		css`
			width: 100%;
			text-align: center;
		`};

	${({ $large: large }) =>
		large &&
		css`
			font-size: var(--font-size-lg);
		`};
`;

export const SubHeading = styled.h4`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-base);
	margin-bottom: var(--spacing-3);

	${({ theme }) =>
		theme.darkMode &&
		css`
			color: var(--palette-white);
		`}
`;

interface HeaderProps {
	$slim?: boolean;
}
export const Header = styled.header<HeaderProps>`
	padding: ${({ $slim: slim }) =>
		slim ? `var(--spacing-5) var(--spacing-5) 0` : styles.spacing._5};

	display: flex;
	flex-flow: row wrap;
	flex: 0 0 auto;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px 10px 0 0;
`;

interface FooterProps {
	$justify?: string;
	$columns?: string;
}

export const Footer = styled.footer<FooterProps>`
	margin: 0;
	padding: var(--spacing-5);
	background: var(--palette-gray-100);
	border-radius: 0 0 10px 10px;
	flex: 0 0 auto;

	${({ theme }) =>
		theme.darkMode &&
		css`
			background-color: var(--palette-gray-800);
		`}

	${(props) =>
		props.$justify &&
		css`
			justify-content: ${props.$justify};
		`}

	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: end;
	grid-gap: var(--spacing-3);
	grid-auto-columns: minmax(120px, auto);

	${(props) =>
		props.$columns &&
		css`
			grid-auto-columns: ${props.$columns};
		`}

	@media ${styles.breakpoint.small} {
		padding: 13px 16px 15px 16px;
		grid-auto-columns: 1fr;
		justify-content: stretch;
	}
`;

interface DividerProps {
	margin?: styles.spacing;
}

export const Divider = styled.hr<DividerProps>`
	width: 100%;
	height: 0px;
	border: none;
	border-bottom: var(--palette-gray-300) 1px solid;
	display: block;

	${({ theme }) =>
		theme.darkMode &&
		css`
			border-bottom-color: var(--palette-gray-700);
		`}

	${({ margin }) =>
		margin &&
		css`
			margin-top: ${margin};
			margin-bottom: ${margin};
		`}
`;
