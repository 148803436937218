import { Fragment, useContext, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import * as flashActions from 'pkg/actions/flashes';
import { UpdateEventPayload } from 'pkg/actions/events';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { popState } from 'pkg/router/state';
import { validateTimeZone } from 'pkg/timezone';
import { useCurrentOrganization } from 'pkg/identity';
import {
	EventTypes,
	rsvpIntervalMappedToGranularity,
} from 'pkg/api/models/event';
import DateTime from 'pkg/datetime';

import PhysicalStrain from 'routes/event/components/PhysicalStrain';
import MatchDetails from 'routes/event/create/MatchDetails';
import EventInformation from 'routes/event/create/EventInformation';
import UserList from 'routes/event/components/UserList';
import { IFormState } from 'routes/event/interfaces';
import { EventFormContext } from 'routes/event/create';
import * as css from 'routes/event/single/styles.css';
import { validateRSVPDate } from 'routes/event/components/rsvp/form-inputs';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';
import { useUserSelectModal } from 'components/user-select-modal';

import * as Input from 'components/form/inputs';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { FormActions } from 'components/form/FormActions';
import * as ActionBar from 'components/layout/ActionBar';
import { ColumnDivider } from 'components/form/Divider';
import Section, { Title } from 'components/form/Section';
import Column from 'components/layout/column';
import { UseAttachmentsResults } from 'components/attachment/hooks/useAttachments';
import Row from 'components/layout/row';

import Button from 'design/button';
import { useDialog } from 'design/dialog';

const validate = (
	state: IFormState,
	setState: (payload: Partial<IFormState>) => void
): boolean => {
	const errors = {
		title: false,
		location: false,
		type: false,
		timezone: false,
		kickOff: false,
		rsvpBefore: false,
	};

	if (!state.title) {
		errors.title = true;
	}

	if (!state.location) {
		errors.location = true;
	}

	if (state.timezone.length > 0 && !validateTimeZone(state.timezone)) {
		errors.timezone = true;
	}

	if (state.rsvpBefore) {
		const rsvpTimestamp = DateTime.fromTimestamp(state.startsAt).prev(
			rsvpIntervalMappedToGranularity[state.rsvpInterval],
			state.rsvpIntervalCount
		);

		if (!validateRSVPDate(rsvpTimestamp.getUnixTimestamp())) {
			errors.rsvpBefore = true;
		}
	}

	const hasErrors = Object.values(errors).some((item) => item === true);

	if (!hasErrors) {
		setState({ errors });
		return true;
	}

	setState({ errors });
	const errorMessages = [];

	if (errors.title) {
		errorMessages.push(t(`Event title`));
	}

	if (errors.location) {
		errorMessages.push(t(`Location`));
	}

	if (errors.timezone) {
		errorMessages.push(t(`Time zone`));
	}

	if (errors.rsvpBefore) {
		flashActions.show({
			title: t(`RSVP date can't be set before current time`),
			message: t('Please set a later RSVP date'),
		});
	} else {
		flashActions.show({
			title: t(`Mandatory fields missing`),
			message: errorMessages.join(', '),
		});
	}

	return false;
};

interface EditFormProps {
	event: models.event.Event;
	eventUsers: models.eventUser.EventUser[];
	useAttachments: UseAttachmentsResults;
}

const EditForm = ({
	event,
	eventUsers,
	useAttachments,
}: EditFormProps): JSX.Element => {
	const EventContext = useContext(EventFormContext);
	const org = useCurrentOrganization();

	const [saving, setSaving] = useState(false);

	const handleSetInvites = (_: any, target: HTMLInputElement) => {
		EventContext.setFormState({ sendInvites: target.checked });
	};

	const { selectedUsers, sendInvites } = EventContext.formState;

	const ignoredUserIds = selectedUsers.map((user: models.user.User) => user.id);

	const organizerUserIds = Object.keys(EventContext.formState.organizer).map(
		(id) => Number.parseInt(id, 10)
	);

	const organizers = selectedUsers.filter((user) =>
		organizerUserIds.includes(user.id)
	);

	const participants = selectedUsers.filter(
		(user) => !organizerUserIds.includes(user.id)
	);

	const numOrganizers = Object.keys(EventContext.formState.organizer).length;
	const numParticipants =
		EventContext.formState.selectedUsers.length - numOrganizers;

	const setSelectedOrganizers = (
		users: models.user.User[],
		mergePrevious: boolean = true
	) => {
		const next = mergePrevious ? [...selectedUsers, ...users] : users;
		const nextOrganizerUserIds = users.map((user) => user.id);

		const organizerIds: number[] = [
			...new Set([
				...nextOrganizerUserIds,
				...Object.keys(EventContext.formState.organizer).map((id) =>
					Number.parseInt(id, 10)
				),
			]),
		];

		const organizer = organizerIds.reduce(
			(memo, userId) => ({
				...memo,
				[userId]: true,
			}),
			{}
		);

		EventContext.setFormState({
			selectedUsers: next,
			organizer,
		});
	};

	const removeOrganizer = (userId: number) => {
		const next = selectedUsers.filter((user) => user.id !== userId);
		const nextOrganizer = { ...EventContext.formState.organizer };
		delete nextOrganizer[userId];

		EventContext.setFormState({
			selectedUsers: next,
			organizer: nextOrganizer,
		});
	};

	const setSelectedParticipants = (
		users: models.user.User[],
		mergePrevious: boolean = true
	) => {
		const next = mergePrevious ? [...selectedUsers, ...users] : users;

		EventContext.setFormState({
			selectedUsers: next,
		});
	};

	const removeParticipant = (userId: number) => {
		const next = selectedUsers.filter((user) => user.id !== userId);

		EventContext.setFormState({
			selectedUsers: next,
		});
	};

	const makeOrganizer = (userId: number) => {
		const nextOrganizer = {
			...EventContext.formState.organizer,
			[userId]: true,
		};

		EventContext.setFormState({
			organizer: nextOrganizer,
		});
	};

	const makeParticipant = (userId: number) => {
		const nextOrganizer = { ...EventContext.formState.organizer };
		delete nextOrganizer[userId];

		EventContext.setFormState({
			organizer: nextOrganizer,
		});
	};

	const organizersSelect = useUserSelectModal({
		title: t('Add organizers'),
		saveLabel: t('Add organizers'),
		ignoredUserIds,
		defaultActiveTabId: 'admin',
		onSave: (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedOrganizers(users);
			}
		},
	});

	const participantsSelect = useUserSelectModal({
		title: t('Add participants'),
		saveLabel: t('Add participants'),
		ignoredUserIds,
		defaultActiveTabId: 'user',
		onSave: (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedParticipants(users);
			}
		},
	});

	const handleCancel = () => popState();

	const handleSaveForm = async () => {
		setSaving(true);

		const validForm = validate(
			EventContext.formState,
			EventContext.setFormState
		);

		const updateEventPayload: UpdateEventPayload = {
			id: event.id,
			startsAt: EventContext.formState.startsAt,
			endsAt: EventContext.formState.endsAt,
			title: EventContext.formState.title,
			description: EventContext.formState.description,
			location: EventContext.formState.location,
			timezone: EventContext.formState.timezone,
			physicalStrainDefault: EventContext.formState.physicalStrainDefault,
			isPrivate: EventContext.formState.isPrivate,
			isPublic: EventContext.formState.isPublic,
			meetBeforeMinutes: Number.parseInt(
				EventContext.formState.meetBeforeMinutes,
				10
			),
			sendInvites: EventContext.formState.sendInvites,
			selectedUsers: EventContext.formState.selectedUsers,
			organizer: EventContext.formState.organizer,
			match: EventContext.formState.match,
			flags: EventContext.formState.flags,
		};

		if (EventContext.formState.rsvpBefore) {
			updateEventPayload.rsvpInterval = EventContext.formState.rsvpInterval;
			updateEventPayload.rsvpIntervalCount =
				EventContext.formState.rsvpIntervalCount;
		} else {
			updateEventPayload.rsvpInterval = '';
			updateEventPayload.rsvpIntervalCount = 0;
		}

		if (validForm) {
			await actions.events.updateEvent(
				event,
				org.id,
				updateEventPayload,
				useAttachments.attachments as models.attachment.Attachment[],
				eventUsers
			);
		}

		setSaving(false);

		return true;
	};

	const confirmSendInvites = useDialog(
		{
			wide: true,
			symbol: 'calendar_add_on',
			title: t('Send invites?'),
			confirmLabel: t('Finish'),
			onConfirm: handleSaveForm,
			onCancel: () => {
				EventContext.setFormState({ sendInvites: true });
			},
			message: (
				<Column>
					<span>
						{t(
							'If you send invites, participants will be notified to take action. Otherwise invited users will automatically be added as "Going".'
						)}
					</span>
					<label className={css.sendInvites}>
						<Row columns="auto 1fr" align="center" spacing={styles.spacing._3}>
							<Input.Control
								standalone
								type="checkbox"
								defaultChecked={sendInvites}
								onChange={handleSetInvites}
							/>
							<strong>{t('Yes, send invites')}</strong>
						</Row>
					</label>
				</Column>
			),
		},
		[EventContext.formState]
	);

	let handleSave = confirmSendInvites;

	if (models.event.hasEnded(event)) {
		handleSave = handleSaveForm;
	}

	const isSmallScreen = useSmallScreen();
	const isMatchEvent = event.type === EventTypes.Match;

	const content = (
		<Fragment>
			<div>
				<LargeScreen>
					<Title>{t(`Event information`)}</Title>
				</LargeScreen>
				<Column spacing={styles.spacing._7}>
					<EventInformation useAttachments={useAttachments} edit />
					{isMatchEvent && <MatchDetails />}
					{(isMatchEvent || event.type === EventTypes.Practice) && (
						<PhysicalStrain />
					)}
				</Column>
			</div>
			<LargeScreen>
				<ColumnDivider />
			</LargeScreen>

			<Column spacing={styles.spacing._8}>
				<Section
					title={t('Organizers ({num})', {
						num: numOrganizers,
					})}
					hideDivider>
					<Column spacing={styles.spacing._6}>
						{numOrganizers > 0 && (
							<UserList
								isEditing
								selectedUsers={organizers}
								onRemove={removeOrganizer}
								onMakeParticipant={makeParticipant}
							/>
						)}

						<Column spacing={styles.spacing._3}>
							<Button
								label={t('Add organizers')}
								secondary
								icon="add"
								onClick={organizersSelect.open}
								testid="events.invite.add_organizers"
							/>
						</Column>
					</Column>
				</Section>

				<Section
					title={t('Participants ({num})', {
						num: numParticipants,
					})}
					hideDivider>
					<Column spacing={styles.spacing._6}>
						{numParticipants > 0 && (
							<UserList
								isEditing
								selectedUsers={participants}
								onRemove={removeParticipant}
								onMakeOrganizer={makeOrganizer}
							/>
						)}

						<Column spacing={styles.spacing._3}>
							<Button
								label={t('Add participants')}
								secondary
								icon="add"
								onClick={participantsSelect.open}
								testid="events.invite.add_participants"
							/>
						</Column>
					</Column>
				</Section>
			</Column>
		</Fragment>
	);

	return (
		<Fragment>
			{organizersSelect.Component}
			{participantsSelect.Component}
			<ActionBar.SaveBar maxWidth={PageWidths.WIDE} smallScreenFullWidth>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					onClick={handleCancel}>
					{t('Cancel')}
				</Button>
				<Button
					primary
					onClick={handleSave}
					large={isSmallScreen}
					block={isSmallScreen}
					disabled={saving}
					testid="event.edit.save">
					{t('Save')}
				</Button>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner
					maxWidth={PageWidths.WIDE}
					columns={3}
					columnSpacing={styles.spacing._9}
					columnSizes="1fr 1px 0.6fr">
					{content}
				</LargeScreenContent.Inner>
				<LargeScreenContent.Inner maxWidth={PageWidths.WIDE} topSpacing={0}>
					<FormActions>
						<Button onClick={handleCancel}>{t('Cancel')}</Button>
						<Button primary onClick={handleSave} disabled={saving}>
							{t('Save')}
						</Button>
					</FormActions>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>
					<Column spacing={styles.spacing._7}>{content}</Column>
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
};

export default EditForm;
