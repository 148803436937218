// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dkp8zDjZKDitvbYWQWfw {
	margin: 0 auto;
	padding: var(--spacing-6);
	width: 100%;
	max-width: var(--page-width-extra-wide);
}

.kmqVMGueyWg7rNzSIYYe {
	font-size: var(--font-size-xl);
}
`, "",{"version":3,"sources":["webpack://./routes/payments/overview/styles.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,yBAAyB;CACzB,WAAW;CACX,uCAAuC;AACxC;;AAEA;CACC,8BAA8B;AAC/B","sourcesContent":[".wrapper {\n\tmargin: 0 auto;\n\tpadding: var(--spacing-6);\n\twidth: 100%;\n\tmax-width: var(--page-width-extra-wide);\n}\n\n.prominent_tooltip_title {\n\tfont-size: var(--font-size-xl);\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `Dkp8zDjZKDitvbYWQWfw`;
export var prominent_tooltip_title = `kmqVMGueyWg7rNzSIYYe`;
export default ___CSS_LOADER_EXPORT___;
